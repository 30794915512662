import { EXTERNAL_ROUTE_LINKS } from 'constants/Apis'
import React from 'react'
import { useLocation, useParams } from "react-router-dom";

const { HOME_WELCOME_PAGE_URL, ABOUT_URL, CONTACT_URL, COURSES, BLOGS, BLOG_DETAIL, TERMS } = EXTERNAL_ROUTE_LINKS;
const ExternalRouteMap = {
  '/': HOME_WELCOME_PAGE_URL,
  '/about': ABOUT_URL,
  '/contact': CONTACT_URL,
  '/blogs': BLOGS,
  '/terms-and-conditions': TERMS
}

const getPageLink = (path, paramId='') => {
  let link = '';
  if(ExternalRouteMap.hasOwnProperty(path)){
    link = ExternalRouteMap[path]
  }else if(path.indexOf('/courses') !== -1){
    link = COURSES + `/${paramId}`
  }else if(path.indexOf('/blog') !== -1){
    link = BLOG_DETAIL + `/${paramId}`
  }
  return link
}

const ExternalPage = () => {
  const location = useLocation();
  const params = useParams();
  const { pathname } = location;
  const { id: paramId } = params;
  return (
    <div>
      <iframe src={getPageLink(pathname, paramId)}  width={'100%'} height={'1000vh'}/>
    </div>
  )
}

export default ExternalPage
