import CardImage from "components/Card/CardImage";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { imagePath } from "utils/assetHelper";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import LDImage from "stories/Image";
import { Headder, Heading, LDParagraph } from "stories/Typography";
import Textarea from "stories/Textarea";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { Notifier } from "components/Notifier";
import { NumberWithFirstDecimal, RemoveCountryCode, UTCTimeConverter, getDateformat } from "utils";
import UpdateEmailForSession from "./UpdateEmailForSession";
import { useQuery } from "hooks/useQuery";
import { getItemFromStorage, removeItemFromStorage, setItemToStorage } from "utils/storage.helper";
import LoadingSpinner from "stories/Loader/LoadingSpinner";
import useIpEffect from "hooks/useIpEffect";
import { AllowedInstructorForWA, LIFEDEMY_WHATSAAP_URL } from "constants/app.constants";
import AboutYouModal from "./components/AboutYouModal";


const BookingButton = ({ children, className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

const Header = (props) => {
  const { children, className } = props;
  return (
    <h1 {...props} className={`${className}`}>
      {children}
    </h1>
  );
};

const TEMP_USER_TOKEN = "_isTempUserToken"

const BookingConfirmed = () => {

const location = useLocation()
const history = useHistory();
const query = useQuery()
const token =  query.get("_t") || location?.state?.data?.token
const isRescheduled = query.get("_r")


const {id: meeting_ID} = useParams()
const sessionData  = location?.state
const meetingId = sessionData?.data?.meeting_id || meeting_ID


// cancel meetinf data
const cancelMeeting = query.get("_c")
const mobileNumber = query.get("_mn")
const cancelMeetingData = location.state

const {bookedSessionDetails, loading} = useSelector((state)=> state?.instructor)
var loc = window.location;
var withoutQuery = loc.pathname;

const _pathnameWithQuery = `${ROUTE_CONSTANTS?.BOOKING_CONFIRMED}/${meetingId}${loc?.search}`
const _pathname = withoutQuery
const _isRouteNotified = _pathname === _pathnameWithQuery


const dispatch = useDispatch()

const [purpose ,setPurpose] = useState("")
const [error,setError] = useState({})
const [ externalFormNotified, setExternalFormNotified ] = useState(true)

const [textAreaChangeActive, setTextAreaChangeActive] = useState(false)

const [bookingData,setBookingData]= useState({})

const _getDayName = new Date(`${bookingData?.slotData?.date}`)
const _res = _getDayName.toLocaleString('en-us', {weekday: 'long'}) // TODO:  Move to seperate util function
const [openModal, setOpenModal] = useState("")
// const _isTempUserToken = JSON.parse(getItemFromStorage(TEMP_USER_TOKEN))
  let errorObj = {};

  const runValidation = () => {
    if (purpose.length === 0) {
      errorObj.purpose = "Please add query";
    }
  }

  
  // Booked details to get
  useIpEffect(()=>{
    if(meetingId){
      dispatch?.instructor?.getBookedSessionDetails({ meetingId })
    }
  },[meetingId])

  useIpEffect(()=>{
  if(bookedSessionDetails && Object?.values(bookedSessionDetails)?.length > 0){
   setBookingData(bookedSessionDetails)
  }
 },[bookedSessionDetails]) 

  // Booked details to get

  

  // useEffect(()=>{
  //   if(!!_isTempUserToken){
  //     setOpenModal(_isTempUserToken)
  //   }
  // },[_isTempUserToken, getItemFromStorage])

  const handleClick =()=>{
    runValidation()
    if(Object.keys(errorObj).length === 0){
      const payload = { 
        meeting_id : meetingId,
        purpose: purpose 
      }
      dispatch.instructor.updateQuery(payload, (meesage)=>{
        Notifier("200",meesage)
        setTextAreaChangeActive(false)
      })  

    }else{
      setError(errorObj)
      setTimeout(()=>{
        setError({})
      },2000)
  };
}

  const handleUpdateEmail = (email) => {
    const payload = {
      email,
      meeting_id: meetingId,
      token: token
    }
    dispatch.instructor.updateSessionEmail(payload, (data, status)=>{
      if(data && status === 200){  
        Notifier(201, data?.message)
        const newPath = `${_pathname}?_mn=${mobileNumber}`
        history.push(newPath)
        // setItemToStorage(TEMP_USER_TOKEN, false)
        setOpenModal(false)
      }
    });
  };


  useEffect(()=>{
    if(token){
      setOpenModal(true)
    }else{
      setOpenModal(false)
    }
  },[token, meetingId])

  //book another session
  const handleSessionClick = (pathname)=>{
    removeItemFromStorage(TEMP_USER_TOKEN)
    history.push({
      pathname: pathname,
      state: { _qPhoneNumber : RemoveCountryCode(mobileNumber)}
    })
  }

  const handleRedirect = (path)=>{
  window.open(path)
}

  return (
    <>
    {
      loading ? <>
      <div className="flex justify-center items-center min-h-screen">
          <LoadingSpinner />
        </div>
      </> : <>
      
    <div className="flex flex-col items-center min-h-screen">
      {openModal && (
        <UpdateEmailForSession
          openModal={openModal}
          handleUpdateEmail={handleUpdateEmail}
        />
      )}
      {/* later change to !openModal  => openModal*/}
      <div className="booking---container flex flex-col md:flex-row md:mt-[4.56rem] xl:mt-[7.5rem] mx-[12rem]">
        <div className="sessionDetails basis-2/4">
          <div>
            <div className="md:hidden flex flex-col justify-center items-center">
              {cancelMeeting ?
                  <CardImage
                  src={imagePath("/cancelcheck.svg")}
                  style={{
                    width: "67px",
                    height: "67px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    objectFit: "cover",
                  }}
                  className="cursor-pointer mt-[2rem] md:mt-[2rem]"
                />
                :  
                <CardImage
                src={imagePath("/confirm.svg")}
                style={{
                  width: "67px",
                  height: "67px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  objectFit: "cover",
                }}
                className="cursor-pointer mt-[2rem] md:mt-[2.438rem]"
              />
            }
              <Header className="text-[16px] font-bold md:text-[33px] text-greeny md:text-white mt-[14px]">
             {cancelMeeting ? "Session cancelled" :  "Session Booked"}
              </Header>
              <Header className="text-[12px] text-center md:text-[16px] font-normal my-2 text-[#b9b9b9] mt-[14px] mx-4 mb-[2rem]">
              { cancelMeeting ? " " :   <><h5 className="text-white"> We have sent the session details to your registered email address.</h5><h5 className="text-white">Please join 5 minutes before the session starts.</h5></>}

              </Header>
            </div>
          </div>
          <div className="instructor--data flex md:gap-4 md:justify-start md:px-[1.5rem] md:items-center md:rounded-lg md:bg-secondary md:w-[490px] md:h-[158px]">
            <CardImage
              src={bookingData?.instructor?.profile_image_url}
              avatar={true}
              style={{
                width: "96px",
                height: "96px",
                borderRadius: "50%",
                overflow: "hidden",
                objectFit: "cover",
              }}
              className="hidden md:flex mobile--image cursor-pointer mr-4 mb-4"
            />
            <CardImage
              src={bookingData?.instructor?.profile_image_url}
              avatar={true}
              style={{
                width: "28px",
                height: "28px",
                borderRadius: "50%",
                overflow: "hidden",
                objectFit: "cover",
              }}
              className="flex md:hidden mobile--image cursor-pointer mr-4 mb-4"
            />
            <Headder className="flex flex-col text-[16px] font-medium text-white">
              {bookingData?.instructor?.instructor_name}
              <span className="hidden md:flex text-[16px] text-greeny">
                {bookingData?.instructor?.designation}
              </span>
              <LDParagraph className="hidden md:flex items-center gap-2">
                <LDImage src={imagePath("/star.svg")} className="w-4 h-4" />{" "}
                {NumberWithFirstDecimal(bookingData?.instructor?.rating)}
              </LDParagraph>
            </Headder>
          </div>
          <Heading className="hidden md:flex mt-[2rem] mb-[1rem]">
            Session details
          </Heading>
          <Headder className="flex items-center mb-3 gap-2 text-[16px] text-white">
            <LDImage src={imagePath("/calendar.svg")} />
            { bookingData?.slotData?.start_time && getDateformat(bookingData?.slotData?.start_time)}
          </Headder>
          <Headder className="flex items-center gap-2 mb-[2rem] text-[16px] text-white">
            <LDImage src={imagePath("/clock.svg")} />
            {bookingData?.slotData?.start_time &&
              `${UTCTimeConverter(bookingData?.slotData?.start_time)} - ${UTCTimeConverter(bookingData?.slotData?.end_time  )}`}
          </Headder>
        {!cancelMeeting && <>
          <Heading className="flex mt-[1rem] text-[16px] font-normal mb-[16px]">
            Add your queries to this booking
          </Heading>
          <Textarea
            rows="6"
            value={purpose}
            handleChange={(e) => {
              setPurpose(e.target.value)
              setTextAreaChangeActive(true)
            }}
            className="block w-full mb-4 bg-secondary text-white outline-none rounded-lg p-2 resize-none"
          />
          {error?.purpose && (
            <div className="mt-2 text-remove"> {error.purpose}</div>
          )}
          {textAreaChangeActive && purpose?.length > 0 && (
            <BookingButton
              onClick={handleClick}
              className="block bg-greeny add--query--button px-[1rem] w-[200px] py-[0] border-0 mb-2 md:my-[2rem] hover:text-white hover:outline-none text-white h-[3rem] rounded-lg text-[16px] font-bold"
            >
              Submit
            </BookingButton>
          )}
     </>  }
        </div>
        <div className="border--booking hidden md:block border-[1px] border-gray-700 mx-[4rem]"></div>
        <div className="w-full basis-2/4">
          <div>
            <div className="session--booked hidden md:flex flex-col justify-center items-center">
             
             {cancelMeeting ? 
                <CardImage
                src={imagePath("/cancelcheck.svg")}
                style={{
                  width: "67px",
                  height: "67px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  objectFit: "cover",
                }}
                className="cursor-pointer mt-[2rem] md:mt-[2rem]"
              />
             :
             <CardImage
             src={imagePath("/confirm.svg")}
             style={{
               width: "67px",
               height: "67px",
               borderRadius: "50%",
               overflow: "hidden",
               objectFit: "cover",
             }}
             className="cursor-pointer mt-[2rem] md:mt-[2.438rem]"
           />
             }
              <Header className="text-[16px] text-center md:text-[33px] font-normal text-greeny md:text-white mt-[1.6rem]">
              {cancelMeeting ? "Session cancelled" : isRescheduled ? "Session Rescheduled" :  "Session Booked"}
              </Header>
              <Header className="text-[12px] text-center md:text-[16px] font-normal my-2 text-[#b9b9b9] mt-[1.6rem] xl:mx-4">
             { cancelMeeting ? " " : <><h5 className="text-white"> We have sent the session details to your registered email address.</h5><h5 className="text-white">Please join 5 minutes before the session starts.</h5></>}
              </Header>
            </div>
            <div className={`flex booked--buttons ${cancelMeeting ? "max-w-[90%]" : "max-w-[65%]"} basis-2/5 flex-col justify-center gap-2 mt-[1.43rem] mx-[auto]`}>
           {cancelMeeting ? 
           <BookingButton
                onClick={()=>handleSessionClick(`${ROUTE_CONSTANTS?.INSTRUCTORINFORMATION}/${cancelMeetingData?.data?.instructorsId}`)}
                className="bg-greeny mb-[10px] text-white h-[3rem] rounded-lg text-[16px] font-bold"
              >
                Book another session
              </BookingButton> :  
             AllowedInstructorForWA?.includes(bookedSessionDetails?.instructor_id) ? <BookingButton
              onClick={()=>handleRedirect(LIFEDEMY_WHATSAAP_URL)}
                // onClick={()=>handleSessionClick(`${ROUTE_CONSTANTS?.MY_SESSION}`)}
                className="join--button flex gap-2 justify-center items-center bg-transparent border-[2px] border-greeny !bg-greeny text-white h-[3rem] rounded-lg text-[16px] font-bold"
              >
                Join Whatsapp Channel  <LDImage src={imagePath("/whatsaap.svg")} className="w-[22px] h-[22px]" />
              </BookingButton> : <></>
              }
              <BookingButton
                // onClick={()=>handleSessionClick(ROUTE_CONSTANTS.HOME)}
                onClick={()=>handleSessionClick(`${ROUTE_CONSTANTS?.MY_SESSION}`)}
                className="bg-transparent border-[2px] border-greeny mb-[10px] text-white h-[3rem] rounded-lg text-[16px] font-bold"
              >
                My Sessions
              </BookingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
    }
    {_isRouteNotified && externalFormNotified && <AboutYouModal handleClose={()=>setExternalFormNotified(false)} show={externalFormNotified} setExternalFormNotified={setExternalFormNotified} meeting_ID={meeting_ID}/>}
    </>
  );
};

export default BookingConfirmed;
