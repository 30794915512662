import { profileEmailDoneBtnClickEvent, profileEmailVerifyBtnClickEvent } from "analytics/profile";
import { Col, Row } from "antd";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormButton from "stories/FormButton";
import LDImage from "stories/Image";
import { imagePath } from "utils/assetHelper";
import { LDParagraph, Title, LDSpan, Heading } from "stories/Typography";
import "./index.css"
import Input from 'stories/Input'
import { useEffect } from "react";
import { Notifier } from "components/Notifier";
import { EmailCheckRegEx } from "utils";
import { COLOR_CONSTANTS } from 'constants/color.constants';
const VerifyEmail = () => {
  const [valid, setValid] = useState(false);
  const [successMsg, setSuccessMessage] = useState({});
  const history = useHistory();
  const dispatch = useDispatch()
  const state = useSelector((state) => state?.profile?.profileDetails);
  const { email, provider } = state
  const [newEmail,setNewEmail] = useState("")
 
  useEffect(()=>{
    setNewEmail(email)
  },[email])

  const cb= ()=>{
    setValid(true);
    const message = "A link has been sent to your registered email address.";
    setSuccessMessage({ message: message }); 
  }

  const handleVerify = () => {
    profileEmailVerifyBtnClickEvent({event_label:email})
     if(email !== newEmail){
       if(EmailCheckRegEx(newEmail)){
            const data = {
              email: newEmail?.trim()?.toLowerCase()
            }
           dispatch?.profile?.requestUpdateProfile(data,()=>{
           dispatch?.profile?.requestAddEmail(data,cb)
        });
      }
      else{
        Notifier("DELETED","Please enter valid email address")
      }
    }
    else{
      if(email){
        if(EmailCheckRegEx(email)){
          const data ={
            email: email?.toLowerCase()?.trim()
          }
          dispatch?.profile?.requestAddEmail(data,cb)
        } 
      }
    }
  };
  const handleDone = () => {
    profileEmailDoneBtnClickEvent({event_label:email})
    setValid(false);
    setSuccessMessage({});
      history.push(`/${ROUTE_CONSTANTS?.PROFILE}`);
  };
  const handleCancel=()=>{
    history.push(`/${ROUTE_CONSTANTS?.PROFILE}`);
  }
  const handleChange=(val)=>{
    setNewEmail(val)
  }

  return (
    <div className="container mx-auto lg:my-10 mt-3 mb-10 min-h-screen">
      <div className="mb-9 text-center md: hidden lg:block ">
        <Heading className="text-white profile-heading-style">
          Profile Settings
        </Heading>
      </div>
      <Row justify="center">
        <Col
          lg={15}
          md={24}
          sm={24}
          xs={24}
          className="lg:bg-ternary px-3 md:px-16 md:pt-2 lg:pt-6 rounded-md  pb-[17rem]"
        >
          <Title className="w-12 sm:ml-8 md:ml-10 relative top-5 left-5">
           <LDSpan className="flex items-center  sm:text-[0.7rem] cursor-pointer md:block"  onClick={handleCancel}>
            <LDImage
              src={imagePath("/Banner/leftArrow.svg")}
              alt="rightArrow"
              className="h-4 pr-2 w-6 "
            />
           </LDSpan>
         </Title>
          <div className="text-center pb-4 xl:pb-4 md:pb-0">
              <Title className=" text-white verify-email-heading-style">
                 Verify Email Address
              </Title>
          </div>
          {" "}
          <div className="h-48 text-center">
            {/* <Title className="m-3 text-white text-xl font-modellicaBold md:block lg:block xl:block hidden">
              Verify Email Address
            </Title> */}
            <LDParagraph className="verify-email-heading-grey mt-6 mb-3 ">
              Your current email address is:
            </LDParagraph>
             {provider != "phone" || valid ?
                <LDParagraph className={` ${successMsg?.message !== undefined ? "mb-4 lg:mb-4 ":"mb-8 " } md:mb-0  xl:mb-8 break-all px-20 lg:leading-5 verify-on-email-style`}>
                   {newEmail}
                </LDParagraph> 
              :
              <Input
                placeholder="Update email address"
                type="email"
                value={newEmail}
                className="px-4 py-2 mx-1  lg:w-3/5 bg-logoColor h-10 text-[#eaeaea] opacity-90 text-md outline-none mt-2 mb-3 p-2  border-2 border-formBorder border-opacity-50 rounded-lg md:w-64 w-full mr-3"
                handleChange={handleChange} 
               />
             }
            <div className="m-3">
              {successMsg?.message !== undefined && (
                <LDParagraph className="verfiy-link-send my-2">
                  {successMsg?.message}
                </LDParagraph>
              )}
              {valid ? (
                <FormButton
                  className="verify-button-style hover:text-greeny bg-white px-32 md:px-20 lg:px-[8rem] xl:px-32 2xl:px-48"
                  onClick={handleDone}
                  backgroundColor={COLOR_CONSTANTS.WHITE}
                  color={COLOR_CONSTANTS.GREEN}
                >
                  Done{" "}
                </FormButton>
              ) : (
                <FormButton
                  className="verify-button-style hover:text-hover bg-white px-32 md:px-20 lg:px-[8rem] xl:px-32 2xl:px-48"
                  onClick={handleVerify}
                  backgroundColor={COLOR_CONSTANTS.WHITE}
                  color={COLOR_CONSTANTS.GREEN}
                >
                  Verify
                </FormButton>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyEmail;
