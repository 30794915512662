import MessagePopUp from "components/MessagePopUp";
import { useDispatch } from "react-redux";
import { removeToken } from "utils/token.helper";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
const LogoutModal = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const cancelLogout = () => {
    dispatch?.authModel?.toggleLogoutModal(false)
  }
  const logoutUser = () => {
    history.push(ROUTE_CONSTANTS?.HOME)
    removeToken();
  }
  return (
    <div>
      <MessagePopUp
        title="Are you sure you want to log out?"
        position="center"
        cancelLabel="No"
        approveLabel="Yes"
        bgBlur={true}
        cancelHandler={cancelLogout}
        confirmHandler={logoutUser}
        contentStyle={{padding: "2rem 3rem"}}
        titleStyle={{fontSize: "1rem", textAlign: "center", marginBottom: "2rem", marginTop: "1rem"}}
        cancel={cancelLogout}
      />
    </div>
  );
};

export default LogoutModal;
