import axios from "axios";
import { axiosGet, axiosGetV1, axiosPost, axiosPostV1, axiosPut } from "axiosRequests";
import { APPLY_COUPON_CODE_URL, GET_ALL_COUPON_LIST_URL, GET_PURCHASE_DETAILS_URL, GUEST_PAYMENT_CANCEL_URL, GUEST_PAYMENT_URL, INITIATE_PAYMENT_URL, INITIATE_PURCHASE_URL, TELECOM_SUPPORT_URL, VERIFY_PAYMENT_URL, PURCHASE_SUCCESS_EMAIL_URL, INITIATE_SESSION_PURCHASE, INITIATE_SESSION_PAYMENT_URL, GET_PURCHASE_ORDER_INFO, SESSION_VERIFY_PAYMENT, APPLY_SESSION_COUPON_CODE } from "constants/Apis";
import { STATUS } from "constants/status.constant";
import { partialStringMatch } from "utils/stringHelper";

const isEncrypt = true 
const headers = {}

const model = {
  state: {
    initialPucharse: {},
    purchaseDetails :{}, 
    all_coupon_list: [],
    active_coupon_list: [],
    originalCouponList: [],
    loading: false,
    initiatePayment:{},
    initiateSessionPurchase:{},
    sessionPurchaseOrderInfo:{}
  },

  reducers: {
    setInitialPurchaseDetails: (state, payload) => {
      return {
        ...state,
        initialPucharse: payload,
      };
    },
    setInitiateSessionPurchase: (state, payload) =>{
      return {
        ...state,
        initiateSessionPurchase: payload
      }
    },
    setSessionPurchaseOrderInfo:(state, payload) =>{
      return {
        ...state,
        sessionPurchaseOrderInfo: payload
      }
    },
    setPurchaseDetails :(state, payload) => {
       return {
         ...state,
        purchaseDetails:payload
       }
    },
    setActiveCouponsList: (state, payload) => {
      return {
        ...state,
        active_coupon_list: payload,
        originalCouponList: payload
      };
    },
    setFilterCouponList: (state, payload) => {
      return {
        ...state,
        active_coupon_list: payload
      }
    },
    setCouponListLoading: (state, loading) => {
      return {
        ...state,
        loading: loading
      }
    },
    setInitiatePayment : (state,payload) => {
      return {
        ...state,
        initiatePayment:payload
      }
    },
    setPaymentLoading:(state, loading) => {
      return {
      ...state,
         loading: loading
      }
    }
  },

  effects: (dispatch) => ({
    async requestInitialPurchase(payload,undefined,callback) {
      try{
        const response = await axiosPost(INITIATE_PURCHASE_URL,payload);
        const  { data,status } =response
        if(STATUS.includes(status)){
          dispatch?.payment?.setInitialPurchaseDetails(data);
          callback(data?.purchaseId)
        }
      }catch(err){
        console.log(err,"error in initail Purchase")
      }
    },

    async requestGetPurchaseDetails(purchaseId,undefined, cb =()=>{} ){
      try{
        const response =await axiosGet(`${GET_PURCHASE_DETAILS_URL}?purchase_id=${purchaseId}`)
        const { data ,status } = response
        if(STATUS?.includes(status)){
            dispatch?.payment?.setPurchaseDetails(data)
            cb(data)
        }else{
          cb(response)
        } 
      }
      catch(err){
        console.log(err,"error in get Purchase details")

      }
    },

    async requestActiveCoupon() {
      try{

        dispatch?.payment?.setCouponListLoading(true);
        const response = await axiosGet(GET_ALL_COUPON_LIST_URL);
        const { data } = response;
        dispatch?.payment?.setActiveCouponsList(data);
        dispatch?.payment?.setCouponListLoading(false);
      }
      catch(err){
        console.log(err,"error in activa coupon")
      }
    },

    async requestApplyCoupon(body, state,callback) {
      try{

        const response = await  axiosPut(APPLY_COUPON_CODE_URL,body)
        const {  data ,status  } = response
        if(STATUS.includes(status) && !Object.keys(data)?.includes("error")){
          callback(data?.isApplied,data)
          dispatch?.payment?.requestGetPurchaseDetails(body?.purchase_id)
         }
         else {
          const payload ={
            message:data?.error
          }
           callback(false,payload)
         }
      }
      catch(err){
        console.log(err,"error in apply coupon")
      }  
    },
    async requestRemoveCoupon(body){
      try{
        const response = await axiosPut(APPLY_COUPON_CODE_URL,body)
        const { data ,status } = response
        if(STATUS.includes(status)){
          dispatch?.payment?.requestGetPurchaseDetails(body?.purchase_id)
        }
      }
      catch(err){
        console.log(err,"error in remove coupon")
      }
    },
    async filterCouponlist(key, state) {
      const filteredData = state?.payment?.originalCouponList?.filter((_item) => {
        const { couponCode } = _item;
        return partialStringMatch(couponCode, key);
      });
      dispatch?.payment?.setFilterCouponList(filteredData);
    },
    async requestInitiatePurchaseCourse(payload,state,callback){
      try{
        const response = await axiosPost(INITIATE_PAYMENT_URL,payload)
        const { data ,status} = response
        if(STATUS.includes(status)){
          const email = state?.profile?.profileDetails?.email
          const updatedData = {
            email:email,
            ...data
          }
          callback(updatedData)
          // dispatch?.payment?.setInitiatePayment(data)
        }
      }
      catch(err){
        console.log(err,"error in initiate purchase course")
      }
    },
    async requestVerifyPayment(payload,undefined,callback){
      try{
        const response = await axiosPost(VERIFY_PAYMENT_URL,payload)
        const { data ,status } =response
        if(STATUS?.includes(status) && data?.status === "success" ){ 
            callback(true,data)
        } else{
          callback(false,data)
        }
      }
      catch(err){
        console.log(err,"err in verify payment")
      }
    },
    async requestGuestPayment(payload,undefined, callback ){
      try{  
          const response = await axiosPost(GUEST_PAYMENT_URL,payload, headers, isEncrypt)
          const {data, status }= response
          if(status == 409){
            callback({ err: true, status: 409})
          }
          else if(STATUS?.includes(status)){ 
            callback(data)
          }
      }
      catch(err){
        console.log(err,"ERROR")
      }
    },
    async requestGuestPaymentCancel(payload){
      const email = payload && payload.data && payload.data.email
      const phone = payload && payload.data && payload.data.phone

      const telecomPayload =   {
          "fields": {
            ...payload.data,
          },
          "actions": [
              {
                  "type": "SYSTEM_NOTE",
                  "text": "Lead Source: Lifedemy website"
              }
          ]
      }
      delete payload?.data?.['name']
      try{
        if((email || phone) && (email.length || phone.length) && (email !== "undefined" || phone !== "undefined")){
          const res = await axios.post(TELECOM_SUPPORT_URL, telecomPayload)
         }
      }
      catch(err){
        console.log("Failed to generate cancelation lead")
      }
      try{
        if((email || phone) && (email.length || phone.length) && (email !== "undefined" || phone !== "undefined")){
          const response = await axiosPost(GUEST_PAYMENT_CANCEL_URL, payload)
        }
      }
      catch(err){
        console.log("cancelation notification failed")
      }
    },
    async sendPurchaseInvoiceEmail(payload){
      try{  
          await axiosPost(PURCHASE_SUCCESS_EMAIL_URL, payload)
      }
      catch(err){
        console.log(err,"ERROR")
      }
    },

    
    // Instructor Payment  Start Here

    async requestInitiateSessionPurchase(payload, undefined, cb){
      try{
        this?.setPaymentLoading(true)
        const response = await axiosPostV1(INITIATE_SESSION_PURCHASE, payload, {}, isEncrypt)
        const { data , status, message } = response
        if(STATUS.includes(status)){
          cb(status, data, message)
           this.setInitiateSessionPurchase(data)
           this?.setPaymentLoading(false)
        }else{
          cb(status, data, message)
        }
      }
      catch(err){
        console.log(err,"ERROR")
      }
    },
    async requestApplySessionCouponCode (payload, undefined, cb){
      try{
        const response = await axiosPostV1(APPLY_SESSION_COUPON_CODE,payload, {}, isEncrypt)
        const  {data, status } = response
        if(STATUS.includes(status)){
          cb(status, data)
        }else{
          cb(status, data)
        }
      }
      catch(err){
        console.log(err,"ERROR")
      }
    },
    async requestInitiateSessionPayment(payload, undefined, cb){
      try{
        const response = await axiosPostV1(INITIATE_SESSION_PAYMENT_URL,payload, {}, isEncrypt)
        const { data, status } = response
        if(STATUS.includes(status)){
          cb(data)
        }
      }
      catch(err){
          console.log(err)
      } 
    },
    async requestGetPurchaseOrderInfo(payload, undfined, cb){
      try{
        const response = await axiosGetV1(`${GET_PURCHASE_ORDER_INFO}?purchase_id=${payload.purchase_id}&country_code=${payload.country_code}`, {}, isEncrypt)
        const { data, status } = response 
        if(STATUS.includes(status)){
           this.setSessionPurchaseOrderInfo(data)
           cb(true ,data)
        }else{
          
          cb(false, data)
        }
      }
      catch(err){
        console.log(err)
      }
    },
    async requestSessionVerifyPayment(payload, undefined, cb){
      try{
        const response = await axiosPostV1(SESSION_VERIFY_PAYMENT, payload, {}, isEncrypt)
        const { data, status} = response 
        if(STATUS?.includes(status) && data?.status === "success" ){ 
          cb(true,data)
        } else{
          cb(false,data)
        }
      }
      catch(err){
        cb(false,{})
        console.log(err,"ERROR")
      }
    }
  }),

};
export default model;
