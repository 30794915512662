import { useState, useEffect } from "react";
import { getAccountDetails } from "selectors";
import UserDetailsForm from "./UserDetailsForm";
import { useDispatch } from "react-redux";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { RAZORPAY_KEY } from "constants/razorpay.contants";
import { useHistory,useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "hooks/useQuery";
import { Heading } from "stories/Typography";
import Loader from "stories/Loader";
import { GuestPaymentPageLoaded,ContinueGuestPurchaseSuccess, IntiateGuestPayment ,ContinueGuestPurchaseError,GuestPaymentCancelled, GuestPaymentSuccess, GestPaymentFailed} from "analytics/payment";
import { imagePath } from 'utils/assetHelper';
import Image from "components/Image";
import { getSalesDetailsForRazorPay } from "utils";
import { EXCLUDE_KEYS } from "constants/app.constants";

const GuestPaymment = () => {
  const [purchasedErr, setPurchasedErr] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [coupon,setCoupon] = useState("");
  const [couponErr, setCouponErr] = useState(false);
  const [couponApplying, setCouponApplying] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const { jwt } = getAccountDetails();
  const dispatch = useDispatch();
  const query = useQuery();
  const courseId = query.get("courseId");
  const _purchaseID = query.get("purchaseId")
  const region = query?.get("region");
  const queryCoupon = query?.get("_cpn");
  const history = useHistory();
  const location = useLocation()
  const profileDetails = useSelector((state) => state?.profile?.profileDetails);
  const countryParam = region?.length ? `&region=${region}` : "";

  useEffect(() => {
    const profileAvailable = profileDetails && Object.keys(profileDetails)?.length
    if (courseId && (_purchaseID || profileAvailable)) {
      const payload = {
        course_id: courseId, 
        user_id: profileDetails?.id 
      };
  //     if(payload?.user_id && jwt){ // user is loggedIn and payload must have userId
  //        MakeAPICalls(payload);
  //     }
      if(_purchaseID && !jwt){ // when the user is not logged in and have purchaseId
        MakeAPICalls(payload);
      }
    }
  //   if(profileAvailable){
  //     const payload = {
  //       event_label: jwt ? `${profileDetails?.email} | ${profileDetails?.mobileNumber}` :  "-",
  //       course_name: courseId
  //     }
  //    GuestPaymentPageLoaded(payload)
  //   }
  }, [Object.keys(profileDetails)?.length > 0]);

  const clearState = () => {
    setLoading(false)
    setCoupon("")
    setCouponErr(false)
    setCouponApplied(false)
    setCouponApplying(false)
    return;
  }
  
  let currentPath;
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onClick = () => {
        resolve(true);
      };
      script.onerror = (error) => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  async function launchRazorPayModal(purchaseId, userDetails, itemDetails = {}) {
    if(userDetails || profileDetails?.email || profileDetails?.mobileNumber){
      const eventLabel = `${userDetails?.email} | ${userDetails?.mobileNumber}` || `${profileDetails?.email} | ${profileDetails?.mobileNumber}`
      const params = {
        event_label: eventLabel,
        course_name: courseId,
        email : userDetails?.email || profileDetails?.email,
        phone: userDetails?.mobileNumber || profileDetails?.mobileNumber
      }
      IntiateGuestPayment(params)
    }
    const payload = {
      purchase_id: purchaseId,
    };
     dispatch?.payment?.requestInitiatePurchaseCourse(payload, (obj) => {
      if (Object.keys(obj)?.length > 0) {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
        const email = `${userDetails?.email || profileDetails?.email || "-"}`
        const phone = ` ${userDetails?.mobileNumber || profileDetails?.mobileNumber}`
        const userId = obj?.updatePurchase?.user_id;
        var options = {
          key: RAZORPAY_KEY, 
          amount: obj?.sub?.amount,
          name: "Lifedemy",
          currency: obj?.sub?.currency,
          description: itemDetails?.courseTitle || "Lifedemy",
          order_id: obj?.sub?.id,
          handler: function (response) {
            dispatch?.payment?.requestVerifyPayment(response, (value, data) => {
              const { customerReceipt } = data;
              const eventParams ={
                course_name : courseId,
                event_label :`${email} | ${phone}`,
                email:email,
                phone: phone
              }
              if (value === true) {
                const redirectToPath = `${ROUTE_CONSTANTS.PAYMENT_SUCCESS}?_c=${courseId}&_p=${phone}&_f=guest&_a=${userDetails?.amount}&_o=${obj?.sub?.id}${countryParam}`
                GuestPaymentSuccess(eventParams)
                if (!userDetails?.isEmailUpdated) {
                  dispatch?.profile?.requestGuestToken(userId, (data) => {
                    history.push(
                      data?.token !== undefined ? `${redirectToPath}&_t=${data?.token}` : redirectToPath
                    );
                  });
                } else {
                  history.push(redirectToPath)
                }
              } else {
                GestPaymentFailed(eventParams)
                history.push({
                  pathname: `${ROUTE_CONSTANTS.PAYMENT_ERROR}`,
                  state: {
                    purchaseId: purchaseId,
                    courseId: courseId,
                    _e: email ,
                    activeRoute: "guest",
                  },
                });
              }
            });
          },
          modal: {
            ondismiss: function () {
              const searchParam = location?.search?.replace(`&purchaseId=${_purchaseID}`,'')
              const dismissPath = `${location.pathname}${searchParam}`
              setPhone("")
              setEmail("")
              history.push(dismissPath)
              setLoading(false)
              setCoupon("")
              setCouponErr(false)
              setCouponApplied(false)
              if (process.env.REACT_APP_ENV === "production") {
                const eventPayload = {
                  event_label :`${email} | ${phone}`,
                  course_name:courseId,
                  email,
                  phone
                }
                GuestPaymentCancelled(eventPayload)
                const name = Object.keys(profileDetails)?.length > 0  ? `${profileDetails?.firstName} ${profileDetails?.lastName}`:  "-"
                const _email = email === "-" ? `${phone.slice(-10)}@lifedemyuser.in` : email;
                const payload ={
                  data : { email: _email, phone, course: courseId, name }
                }
                dispatch?.payment?.requestGuestPaymentCancel(payload)
              }
            },
          },
          prefill: {
            name:  email,
            contact: phone,
          },
          notes: {
            address: "-",
            user_flow: "Landing Page",
            sale_details : JSON.stringify(getSalesDetailsForRazorPay(location, EXCLUDE_KEYS)),
            purchase_details: JSON.stringify({ course_id: parseInt(courseId), user_id: parseInt(userDetails?.userId) })
          },
          theme: {
            color: "#0E896A",
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        paymentObject.on('payment.failed', function(response){
          const eventParams ={
            course_name : courseId,
            event_label :`${email} | ${phone}`,
            email,
            phone
          }
          GestPaymentFailed(eventParams)})
      }
    });
  }

  const checkAndApplyCoupon = (purchaseID) => {
    if(couponApplied){
      const body = {
        purchase_id: purchaseID,
        coupon_code: "",
      };
      dispatch?.payment?.requestRemoveCoupon(body)
      setCouponApplied(false)
      setCoupon("")
      setCouponApplying(false);
      return
    }
    if (coupon?.length || queryCoupon?.length) {
      const body = {
        purchase_id: purchaseID,
        coupon_code: coupon || queryCoupon,
      };
      dispatch?.payment?.requestApplyCoupon(body, (value, discount) => {
        if (value === false && coupon?.length) {
          setCouponErr(true);
        }else if(value === true && coupon?.length){
          setCouponApplied(true);
        }
        setCouponApplying(false);
      });
    }
  }

  function MakeAPICalls(payload, applyCpn=false) {
    const mobileNumber = payload?.mobileNumber
    delete payload?.mobileNumber

    const otherAPiCalls = (purchaseID) => {
      if (applyCpn || (!coupon?.length && queryCoupon && !couponApplied)) {
        checkAndApplyCoupon(purchaseID)
        //return if applying coupon entered by user
        if(applyCpn){
          return;
        }
      }
      dispatch?.payment?.requestGetPurchaseDetails(purchaseID, (data) => {
        if (data?.data?.message && data?.status === 409) {
          if (jwt) {
            history.push(`/${ROUTE_CONSTANTS.COURSE}/${courseId}`)
          } else {
            history.push(`/guest-payment?courseId=${courseId}`)
          }
        } else {
          const userDetails = {
            email: data?.customerDetails?.customerEmail,
            mobileNumber: data?.customerDetails?.customerPhone || mobileNumber,
            amount: data?.purchaseDetails?.amountDetails?.totalOrderAmount,
            userId: data?.userId,
            isEmailUpdated: payload?.emailUpdated
          }
          const itemDetails = data?.purchaseDetails?.itemDetails?.[0] || {}
          launchRazorPayModal(purchaseID, userDetails, itemDetails);
        }
      });
    }

    if (_purchaseID) {
        otherAPiCalls(_purchaseID)
    } else {
      dispatch?.payment?.requestInitialPurchase(payload, (purchaseID) => {
        currentPath = `${location.pathname}${location.search}`
        history.push(`${currentPath}&purchaseId=${purchaseID}`)
        otherAPiCalls(purchaseID)
      });
    }
  }

  const handleStartPaymentProcess = (param, coupon = false) => {
    if (param?.mobileNumber && param?.email) {
      if (_purchaseID) {
        MakeAPICalls({}, coupon)
      } else {
        const finalParams = { ...param, courseId: parseInt(courseId), extraParams: getSalesDetailsForRazorPay(location, EXCLUDE_KEYS) }
        dispatch?.payment?.requestGuestPayment(finalParams, (data) => {
          const eventPayload = {
            event_label: param?.mobileNumber,
            course_name: courseId,
            email: param?.email
          }

          if (data?.err && data.status == 409) {
            setPurchasedErr(true)
            ContinueGuestPurchaseError(eventPayload)
            clearState()
          } else {
            ContinueGuestPurchaseSuccess(eventPayload)
            const payload = {
              course_id: courseId,
              user_id: data?.userId,
              mobileNumber: param?.mobileNumber,
              emailUpdated: data?.emailUpdated,
              email: param?.email
            };
            MakeAPICalls(payload, coupon);
          }
        });
      }
    }
  };
  const handleProcessPayment = (payload, coupon=false) => {
    if(coupon){
      setCouponApplying(true);
    }else{
      setLoading(true)
    }
    handleStartPaymentProcess(payload, coupon);
  };
  const closeCouponErrModal = () => {
    setCouponErr(false);
  }

  return (
    <div className="min-h-screen overflow-x-hidden"> 
    {loading && <Loader />}  
    <div className=" my-[12%] px-[5%] md:px-[15%] lg:px-[35%]  m-auto ">
      <div className="bg-ternary p-12 rounded-md shadow-lg relative">
        <Image src={imagePath("/lifedemyIcon.png")} icon={true} alt="logo" className="absolute h-12 top-[-1.6rem] left-[50%] "  style={{transform: "translate(-50%,0%)"}} />
        <div className="mb-6 text-center">
          <Heading>Payment Details</Heading>    
        </div>
        <UserDetailsForm 
          handleProcessPayment={handleProcessPayment} 
          purchasedErr={purchasedErr} 
          setPurchasedErr={setPurchasedErr} 
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
          coupon={coupon}
          setCoupon={setCoupon}
          couponErr={couponErr}
          closeCouponErrModal={closeCouponErrModal}
          purchaseInProgress={loading}
          couponApplied={couponApplied}
          couponApplying={couponApplying}
        />
      </div>
      </div>
    </div>
  );
};

export default GuestPaymment;
