import {  useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { notification } from "antd";
import { Col, Row } from "antd";

import VideoPlayer from "stories/VideoPlayer";
import VideoDescription from "pages/Course/Components/VideoDescription";
import HorizontalLine from "stories/HorizontalLine";
import CourseDescription from "pages/Course/Components/CourseDescription";
import { LDSpan, Title } from "stories/Typography";
import Button from "stories/Button";
import CourseList from "pages/Course/Components/CourseList";
import PlayerThumbnail from "stories/VideoPlayer/PlayerThumbnail";
import DownloadAll from "pages/Course/Components/DownloadAll";
import { APP_REVIEW_LIST_SIZE, MESSAGE_CONSTANTS } from "constants/app.constants";
import { getImgDimensionSrc, imagePath } from "utils/assetHelper";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import CourseReview from "./Components/CourseReviews";
import { useHistory } from "react-router-dom";
import { Helmet } from "components/Helmet";
import { courseLoadEvent, courseWishlistEvent,courseShareEvent ,courseShareOptionEvent, courseEpisodeListCardClickEvent, CoursePlayFirstEpisode, CoursePlaySecondEpisode, CoursePurchasedEpisodeClick, CoursePaidEpisodeClick} from "analytics/course";
import useAuthenticatedEffect from "hooks/useAuthenticatedEffect";
import { getRoundedVal } from "utils";
import HomeError from "pages/Home/components/Errors";
import { episodeShareOptionEvent } from "analytics/episode";
import "./index.css"
import DownloadApp from "components/DownloadApp";
import Image from "components/Image";
import PurchaseModal from "components/PurchaseModal";
import useGetWindowSize from "hooks/useGetWindowSize";
import { STATUS_CODE } from "constants/status.constant";
import { resetPlayer } from "stories/VideoPlayer/actions";
import useAuthenticatedIpEffect from "hooks/useAuthenticatedIpEffect";

const Course = () => {
  const course_details = useSelector((state) => state?.course?.course_details);
  const { episodeLoading ,reviewLoading  } = useSelector((state)=> state?.course)
  const [episodeName, setEpisodeName] = useState("")
  const episode_details = useSelector(
    (state) => state?.course?.episode_details
  );
  const review_details = useSelector((state) => state?.course?.review_details);
  const courseDescError  = useSelector((state) => state?.course?.courseDescError);
  const courseLoading = useSelector((state)=>state?.course?.courseLoading)
  const user_details = useSelector((state) => state?.profile?.profileDetails);
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const [isVideoError,setIsVideoError] = useState(false)
  const [show,setShow] =useState(false)
  const [showPurchase, setShowPurchase] = useState(false)
  const [status, setStatus] =useState(null);
  const history = useHistory()
  const dispatch = useDispatch();
  const params = useParams();
  const { PLAYER_THUMB_DIMENSION } = appConfig;
  const { childMode } = user_details;

  useEffect(()=>{
    resetPlayer();
  },[])
  const getCurrentEpisodeName = useCallback((name)=>{
    if(name){
      setEpisodeName(name)
    }
  },[])
  
  useAuthenticatedIpEffect(() => {
    if (params?.id) {
      const extraparams = {
        course_name:params?.id
      }
      courseLoadEvent(false, extraparams);
      dispatch?.course?.requestCourseDetails(params?.id, (value, data)=>{
        if(value ===true){
          if(data?.courseId){
            dispatch?.course?.clearReViews([]);
            dispatch?.course?.requestEpisodesDetails(data?.courseId);
            dispatch?.course?.requestReviewDetails(data?.courseId);
          }
        }else if(!value && data.notFound){
          setStatus(STATUS_CODE['notFound']);
        }
      });
      // Prafull get course_name from requestCourseDetails api response
    }
  // }, [params?.id]);
}, [params?.id, childMode]);

useAuthenticatedEffect(() => {
  if(course_details?.courseTitle){
    const extraparams = {
      course_name:course_details?.courseTitle
    }
    courseLoadEvent(true, extraparams);
  }
}, [course_details?.courseTitle]);


  let {
    trailerUrl,
    thumbnailUrl,
    isWishlisted,
    courseId,
    courseTitle,
    views,
    courseDescription,
    overallRating,
    isPurchased,
    mrp,
    firstEpisodeId,
    slug,
    streamUrl,
    offer_price,
    currencySymbol
  } = course_details;
  if(streamUrl && streamUrl.length){
    trailerUrl = streamUrl;
  }

  useEffect(()=>{
    if(!trailerUrl || trailerUrl === null || trailerUrl.length === 0){
     setIsVideoError(true)
    }else{
      setIsVideoError(false);
    }
  },[trailerUrl])
  const handleAddWishlist = (courseId,type) => {
    const params = {
      event_label: type, //${courseTitle} - 
      course_name: courseTitle
    }
    courseWishlistEvent(params)
    dispatch?.commonParams?.requestAddToWishlist({courseId: courseId, type,courseTitle:courseTitle },()=>{})
   };

  const handleDownload = (type) => {
    if(type === "download"){
      setShow(true)
    }else{
      setShowPurchase(true)
    }
  };

  
  const redirectURL=(purchaseId)=>{
    history.push(`/${ROUTE_CONSTANTS.PAYMENT}?purchaseId=${purchaseId}&courseId=${courseId}`);
  }
  // for mobile screen
  const handleSubscribe = () => {
    const payload={
      course_id:courseId,
      user_id : user_details?.id
    }    
    dispatch?.payment?.requestInitialPurchase(payload, redirectURL)
  };

  const fireEvent=()=>{   
    const params ={course_name :`${courseTitle}`}
    courseShareEvent(params) 
  }
  const fireEventOptions =(shareOption)=>{
    const params ={
      event_label : shareOption, //${courseTitle} -
      course_name: courseTitle
    }
    courseShareOptionEvent(params) 
    episodeShareOptionEvent(params)   
  }
  
  const handleFireEvent = useCallback((episodeName,index, obj)=>{
        const params = {
          event_label : episodeName , //${courseTitle} -
          course_name : courseTitle
      }
      if(!obj?.isFree && obj?.isPurchased ){
        CoursePurchasedEpisodeClick(params)
      }
      if(index === 1){
        CoursePlayFirstEpisode(params)
      }
      if(index === 2){
        CoursePlaySecondEpisode(params)
      }
      courseEpisodeListCardClickEvent(params)
  },[courseTitle, courseEpisodeListCardClickEvent])


  const onNextClick = () => {
    const firstEpisodeRoute = `/${ROUTE_CONSTANTS.EPISODE}/${params?.id}/${firstEpisodeId}`;
    history.push(firstEpisodeRoute);
  }
  const restProps = { onNextClick: firstEpisodeId && onNextClick };
  const showThumb = isVideoError;
  
  
  {/* Move this to config */}
  const { innerWidth } = useGetWindowSize()
  const setWidth = innerWidth >= 992 && innerWidth < 1190 ? 24 : 16 
  const styleToset =  !courseLoading && courseDescError ? "min-h-screen" :"lg:px-[4rem]  md:pt-5 min-h-screen"
  return (
    <div className={styleToset}>
      {courseTitle !== undefined && <Helmet title={courseTitle} />}
      {status == 404 ? <HomeError
         errType={404}
         msg={MESSAGE_CONSTANTS['COURSE_NOT_FOUND']}
        /> :
       ((!courseLoading && courseDescError)  ? (
        <HomeError
          action={() => {
            dispatch?.course?.setCourseLoading(true);
            setTimeout(() => {
              dispatch?.course?.requestCourseDetails(params?.id);
            }, 800);
          }}
        />
      ) : (
        <Row className="px-2 lg:px-0" gutter={1}>
          <Col lg={setWidth} md={24} sm={24} xs={24} className="lg:mr-8  lg:p-0 p-2 ">
            <PurchaseModal  handleGTMEventCapture={()=>{}} handleClose={()=>setShowPurchase(false)}  mrp={mrp} show={showPurchase} courseId={courseId} offer_price={offer_price} currencySymbol={currencySymbol} courseSlug={slug} title={episodeName ? episodeName : "Premium Content"}/>
            <DownloadApp show={show} handleClose={() => setShow(false)} />
            {/* hidden Image to load thumb image for video player poster. Poster will fail to load for 1st time */}
            {thumbnailUrl && thumbnailUrl.length && <div className="hidden"><Image src={thumbnailUrl} dimension={PLAYER_THUMB_DIMENSION} /></div>}
          
            <div>
              {!courseLoading && showThumb ? (
                <Image src={thumbnailUrl} dimension={PLAYER_THUMB_DIMENSION} className="w-full" />
              ) : (
                <VideoPlayer
                  url={trailerUrl}
                  light={false}
                  playIcon={
                    <PlayerThumbnail
                      thumbnail={thumbnailUrl}
                      isCourse={true}
                      courseTitle={courseTitle}
                    />
                  }
                  restProps={restProps}
                  courseTitle={courseTitle}
                  loading={courseLoading}
                  thumbnail={getImgDimensionSrc(thumbnailUrl, PLAYER_THUMB_DIMENSION)}
                />
              )}
              <VideoDescription
                isWishlisted={isWishlisted}
                title={courseTitle}
                views={views}
                courseId={courseId}
                rating={overallRating?.toFixed(1)}
                description={courseDescription}
                handleAddWishlist={handleAddWishlist}
                fireEvent={fireEvent}
                fireEventOptions={fireEventOptions}
              />
            </div>
            <HorizontalLine className="lg:block hidden opacity-50" />
            <div className=" lg:w-3/4 ">
              <div className="my-6">
                {/* {isPurchased && (
                  <LDSpan className={` bg-greeny py-2 px-7 text-white cursor-pointer font-bold rounded-sm`}>
                    Purchased
                  </LDSpan>
                )} */}
                <CourseDescription
                  description={course_details?.courseDescription}
                />
              </div>

              {/**Episodes for mobile Reviews*/}
              <div className="block lg:hidden">
                {/* for Mobile screen*/}
                <DownloadAll
                  handleDownload={handleDownload}
                  isPurchased={isPurchased}
                  totalEpisodes={episode_details?.length}
                />
                <CourseList
                  episodeLoading={episodeLoading}
                  episode_details={episode_details}
                  courseId={courseId}
                  isPurchased={isPurchased}
                  handleFireEvent={handleFireEvent}
                  mrp={mrp}
                  title={courseTitle}
                  courseSlug={slug}
                  getCurrentEpisodeName={getCurrentEpisodeName}
                  offer_price={offer_price}
                  currencySymbol={currencySymbol}
                />
              </div>
              <CourseReview
                user_details={user_details}
                reviewList={review_details}
                paramId={courseId}
                isPurchased={isPurchased}
                mrp={mrp}
                courseSlug={slug}
                courseTitle={courseTitle}
                reviewLoading={reviewLoading}
                offer_price={offer_price}
              />
            </div>
          </Col>
          <Col lg={7}  className="lg:block hidden">
            <div className="fixed w-[24.5%]">
              <Button
                type="primary"
                className="p-3 w-full font-semibold bg-greeny border-none rounded-md shadow-lg  shadow-black-500/50 text-lg text-white cursor-default"
                label={"EPISODES"}
                btnCls={"text-white"}
              />
              <CourseList
                episodeLoading={episodeLoading}
                episode_details={episode_details}
                courseId={courseId}
                cls="hidden md:block"
                mrp={mrp}
                isPurchased={isPurchased}
                title={courseTitle}
                handleFireEvent={handleFireEvent}
                courseSlug={slug}
                getCurrentEpisodeName={getCurrentEpisodeName}
                offer_price={offer_price}
                currencySymbol={currencySymbol}
              />
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default Course;
