import { Heading, LDLabel } from "stories/Typography";
import { LDSelect } from "stories/Form";
import { videoQualityOptions } from "constants/videoplayer.constants";

const MobileActionPopUp = ({
  options,
  handleOptionSelect,
  qualityAllowed,
  settingHeadRef,
  handleCloseModal,
  show,
  active,
}) => {
  return (
    <div className="mx-auto">
      {show && (
        <div className="fixed z-50 mr-20 my-36 left-[50%] w-full max-w-[22rem] px-[2.5rem] top-[20%] translate-x-[-50%] translate-y-[-50%] rounded-lg">
          <div className=" bg-secondary p-[1rem] w-full">
            <Heading className="py-2">Playback Speed</Heading>
            {qualityAllowed ? (
              <>
                {/* <LDLabel className="py-2">Video Quality</LDLabel>
                <LDSelect
                  items={videoQualityOptions}
                  value={active}
                  showDropdown={true}
                  handleChange={value => handleOptionSelect(value)}
                  className="w-full p-2 outline-none rounded-none bg-greeny text-white"
                /> */}
              </>
            ) : (
              ""
            )}
            <div className="mt-[0.5rem] mb-[0.4rem]">
              {/* <LDLabel className="py-2">Speed</LDLabel> */}
            </div>
            <LDSelect
              ref={settingHeadRef}
              items={options}
              value={active}
              handleChange={value => {
                handleOptionSelect(value);
              }}
              showDropdown={true}
              className="w-full p-2 outline-none rounded-none bg-greeny text-white"
            />
            <div className="flex justify-end mt-[2rem] mb-[1rem]">
              <LDLabel
                onClick={handleCloseModal}
              >
                OK
              </LDLabel>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MobileActionPopUp;
