import * as eventConstants from "./events.constants";
import courseEvents from "./CourseEvents";

const handleEvent  = (eventCode, extraParams={}) => {
  courseEvents.handle_analytics_event(eventCode, extraParams);
};

export const courseLoadEvent = (loadEnd = false, extraParams={}) => {
    const eventCode = loadEnd ? eventConstants?.COURSE_LOAD_END : eventConstants?.COURSE_LOAD_START;
    handleEvent(eventCode, extraParams);
}

export const courseWishlistEvent = (extraParams={}) => {
  const eventCode = eventConstants?.COURSE_WISHLIST_CLICK
  handleEvent(eventCode,extraParams)
}

export const courseShareEvent = (extraParams={}) => {
  const eventCode = eventConstants?.COURSE_SHARE_CLICK
  handleEvent(eventCode, extraParams)
}

export const courseShareOptionEvent = (extraParams={}) => {
  const eventCode = eventConstants?.COURSE_SHARE_OPTION_CLICK
  handleEvent(eventCode, extraParams)
}
export const courseReviewStarRatingEvent = (extraParams={}) =>{
  const eventCode = eventConstants?.COURSE_REVIEW_STAR_RATING_CLICK
  handleEvent(eventCode, extraParams)
}
export const courseWriteAReviewEvent = (extraParams={}) =>{
  const eventCode = eventConstants?.COURSE_WRITE_A_REVIEW_CLICK
  handleEvent(eventCode, extraParams)
}
export const courseAddReviewTextareaClickEvent = (extraParams={}) =>{
  const eventCode = eventConstants?.COURSE_ADD_REVIEW_TEXTAREA_CLICK
  handleEvent(eventCode, extraParams)
}
export const courseAddReviewClickEvent = (extraParams={}) =>{
  const eventCode = eventConstants?.COURSE_ADD_REVIEW_CLICK
  handleEvent(eventCode,extraParams)
}
export const courseReviewEditClickEvent = (extraParams={}) => {
  const eventCode = eventConstants?.COURSE_REVIEW_EDIT_CLICK
  handleEvent(eventCode, extraParams)
}
export const courseEditReviewTextareaClickEvent = (extraParams={})=>{
  const eventCode = eventConstants?.COURSE_EDIT_REVIEW_TEXTAREA_CLICK
  handleEvent(eventCode, extraParams)
}

export const courseEditReviewClickEvent = (extraParams={}) => {
  // edit button 
  const eventCode = eventConstants?.COURSE_EDIT_REVIEW_CLICK
  handleEvent(eventCode, extraParams)
}

export const courseReviewsShowMoreClickEvent = (extraParams={}) => {
  const eventCode = eventConstants?.COURSE_REVIWES_SHOW_MORE_CLICK
  handleEvent(eventCode, extraParams)
}

export const coursePurchaseCardClickEvent =(extraParams={})=>{
  const eventCode = eventConstants?.COURSE_PURCHASE_CARD_CLICK
  handleEvent(eventCode, extraParams)
}

export const courseEpisodeListCardClickEvent = (extraParams={}) => {
  const eventCode = eventConstants?.COURSE_EPISODE_LIST_CARD_CLICK
  handleEvent(eventCode, extraParams)
}


export const CoursePlayFirstEpisode =(extraParams = {}) => {
  const eventCode = eventConstants?.PLAY_FIRST_EPISODE
  handleEvent(eventCode,extraParams)
} 

export const CoursePlaySecondEpisode = (extraParams = {}) => {
  const eventCode = eventConstants?.PLAY_SECOND_EPISODE
  handleEvent(eventCode,extraParams)
}


export const CoursePaidEpisodeClick = (extraParams = {}) => {
  const eventCode = eventConstants?.PAID_EPISODE_CLICK
  handleEvent(eventCode,extraParams)
}

export const CoursePurchasedEpisodeClick = (extraParams = {}) =>{
   const eventCode = eventConstants?.PURCHASED_EPISODE_CLICK
   handleEvent(eventCode, extraParams)
}