import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { MysessionCard } from "components/Card/MysessionCard";
import { imagePath } from "utils/assetHelper";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import ProfileInfo from "components/Navbar/MobileSidebar/ProfileInfo";
import { CompareDates, formatDate, SESSION_CANCELLED_BY, SESSION_STATUS, SortDates, upcommingSessionIncludes, UTCTimeConverter } from "utils";
import { Notifier } from "components/Notifier";
import { EXTERNAL_ROUTE_LINKS } from "constants/Apis";
import useMedia from "hooks/deviceHelper/useMedia";
import MultiLoadingCards from "components/MultiLoadingCards";
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from 'constants/routes.constants';
import useIpEffect from "hooks/useIpEffect";

const { TabPane } = Tabs;

export const TAB_TYPE = {
  upcomming: "upcoming",
  completed: "completed",
  canceled:"cancelled"
};

const MySession = () => {
  const dispatch = useDispatch();
  const history = useHistory()

  const Header = ({ children, className }) => {
    return <h1 className={className}>{children}</h1>;
  };
  const isMobile = useMedia("(max-width: 600px)");
  const skeletonCount = isMobile ? 1 : 4

  //userID
  const profileInfo = useSelector((state) => state?.profile?.profileDetails); // to check first name last name are present or not
  useIpEffect(() => {
    dispatch?.profile?.requestProfileDetails();
  }, []);

  // mysessions with profileID
  const stateMySession = useSelector((state) => state?.instructor);
  const { mySession, loading } = stateMySession

  useIpEffect(() => {
    if (profileInfo?.id) {
      dispatch?.instructor?.getMySession(profileInfo?.id);
    }
  }, [profileInfo?.id]);

  const result =
    mySession &&
    mySession?.map((data, idx) => {
      return {
        id: data?.id,
        instructorsId:data?.instructors?.id,
        instructor_name: data?.instructors?.instructor_name,
        instructor_designation: data?.instructors?.designation,
        date: formatDate(data?.time_slot?.start_time),
        _sortedDateAndTime: new Date(
          `${formatDate(data?.time_slot?.start_time)} ${UTCTimeConverter(
            data?.time_slot?.start_time
          )}`
        ),
        start_time: data?.time_slot?.start_time,
        end_time: data?.time_slot?.end_time,
        instructor_image: data?.instructors?.profile_image_url,
        sessionStatus: data?.status,
        meeting_id: data?.external_meeting_id,
        attendee_id: data?.attendeeList?.[1]?.AttendeeId,
        timeSlotId:data?.time_slot?.id,
        old_slot_id:data?.old_slot_id,
        ...data
      };
    });

  //upcomming sessions
  const _sessionDate =
    result &&
    result.sort((a, b) => a._sortedDateAndTime - b._sortedDateAndTime);
  const _todayDate = formatDate(new Date());

  const upcommingSession =
    _sessionDate &&
    _sessionDate.filter((data, idx) => {
      const sessionToShow = upcommingSessionIncludes?.includes(data?.sessionStatus)
      return data?.start_time >= _todayDate && sessionToShow});

  //cancelled sessions
  const cancelledSession =
    result &&
    result?.filter((data, idx) => {
      return data?.sessionStatus === "cancelled";
    });
  //completed session
  const completedSession =
    result &&
    result?.filter((data, idx) => {
        return data?.sessionStatus === SESSION_STATUS?.SESSION_COMPLETE || data?.sessionStatus === SESSION_STATUS?.SESSION_EXPIRED;
      });

  const joinMeetingForUpcommingSession = (payload) => {
    dispatch?.instructor?.crateMeetingSignatureToken(
      payload,
      (status, data) => {
        if (status) {
          window.open(
            `${EXTERNAL_ROUTE_LINKS?.LIFEDEMY_MEETING}?token=${data?.token_signature}&_u=${profileInfo?.id}`,
            "_self"
          );
        } else {
          Notifier("101", data.message);
        }
      }
    );
  };
  
  const handleCompletedSession =(payload)=>{
    history.push(`${ROUTE_CONSTANTS.INSTRUCTORINFORMATION}/${payload.instructorsId}`)
  }


  const SkeletonLoadingComponent = () => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6 md:pl-[64px] px-[1rem]">
        <MultiLoadingCards count={skeletonCount} />
      </div>
    );
  };
  return (
    <div className="min-h-screen">
      <div className="session---cards">
        <Header className="text-white mt-[1rem] md:mt-[3rem] text-[27.5px] font-bold text-center">
          My Sessions
        </Header>
        <div className="meetingcards pl-[4rem] pr-[2rem]">
          <Tabs
            className="text-white text-[16px]"
            tabBarStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TabPane tab="Upcoming" key="1">
              {loading ? <SkeletonLoadingComponent /> : (<>
                {upcommingSession && upcommingSession?.length > 0 ? (
                <div className="mymetting 2px-[1rem] gap-[1.75rem]">
                  {upcommingSession?.map((data, idx) => {
                    return (
                      <MysessionCard
                        data={data}
                        handleButtonClick={joinMeetingForUpcommingSession}
                        tabType={TAB_TYPE.upcomming}
                        loading={stateMySession?.loading}
                        currentTab={"1"}
                      />
                    );
                  })}
                </div>
              ) : (
                <Header className="flex justify-center items-center text-center text-white font-bold text-[25px] m-[5rem] mx-[3rem] md:mx-[10rem] my-[10rem] md:my-[15rem]">
                  No upcoming session!
                </Header>
              )}
              </>)}
           
            </TabPane>
            <TabPane tab="Completed" key="2">
               {loading ? <SkeletonLoadingComponent /> : (<>
                {completedSession && completedSession?.length > 0 ? (
                <div className="mymetting 2px-[1rem] gap-[1.75rem]">
                  {completedSession?.map((data, idx) => {
                    return <MysessionCard data={data} 
                    currentTab={"2"}
                    handleButtonClick={()=>handleCompletedSession(data, TAB_TYPE.completed)}
                    tabType={TAB_TYPE.completed}
                    />;
                  })}
                </div>
              ) : (
                <Header className="flex justify-center items-center text-center text-white font-bold text-[25px] m-[5rem] mx-[3rem] md:mx-[10rem] my-[10rem] md:my-[15rem]">
                  No completed session!
                </Header>
              )}
               </>)}
            </TabPane>
            <TabPane tab="Cancelled" key="3">
            {loading && <SkeletonLoadingComponent /> }
              {cancelledSession && cancelledSession?.length > 0 ? (
                <div className="mymetting 2px-[1rem] gap-[1.75rem]">
                  {cancelledSession?.map((data, idx) => {
                    return <MysessionCard 
                             data={data} 
                             currentTab={"3"}
                             handleButtonClick={()=>handleCompletedSession(data, TAB_TYPE.canceled)}
                             tabType={TAB_TYPE.completed} //this will work when click on book again on cancel tab 
                    />
                  })}
                </div>
              ) : (
                <Header className="flex justify-center items-center text-center text-white font-bold md:text-[25px] mx-[3rem] md:mx-[10rem] my-[10rem] md:my-[15rem]">
                  No cancelled session!
                </Header>
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default MySession;
