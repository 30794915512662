import { Fragment } from "react";
import CommentsLoader from "stories/SkeletonLoader/CommentsLoader";

const MultiLoadingComments = ({count}) => {
  return <Fragment>{[...Array(count)]?.map((item, key) => {
      return <CommentsLoader key={key} />
  })}</Fragment>;
};

MultiLoadingComments.defaultProps = {
    count: 1
}

export default MultiLoadingComments;
