import { LDAnalytics } from "utils/analyticsHelper.js";

class VideoPlayerEvents extends LDAnalytics {
  event_Watch_Trailer_Click() {
    return {
      event: {
        module_name: "Course",
        feature_name: "Video Player",
        event_name: "Watch_Trailer_Click",
        event_label: "Watch Trailer",
      },
    };
  }
  event_Watch_Episode_Click() {
    return {
      event: {
        module_name: "Episode",
        feature_name: "Video Player",
        event_name: "Watch_Episode_Click",
        event_label: "Watch Episode",
      },
    };
  }
  event_Video_Forward_Backward() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player",
        event_name: "Video_Forward_Backward",
        event_label: "Forward/Backward",
      },
    };
  }
  event_Video_Seek_30s() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player",
        event_name: "Video_Seek_30s",
        event_label: "Video Seek 30s",
      },
    };
  }
  event_Video_Play() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player",
        event_name: "Video_Play",
        event_label: "Play Video",
      },
    };
  }
  event_Video_Pause() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player",
        event_name: "Video_Pause",
        event_label: "Pause Video",
      },
    };
  }
  event_Video_Seek() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player",
        event_name: "Video_Seek",
        event_label: "Seek Video",
      },
    };
  }
  event_Video_Speed_Icon_Click(){
    return {
      event:{
        module_name: "Course | Episode",
        feature_name: "Video Player - Settings",
        event_name: "Video_Speed_Icon_Click",
        event_label: "Video Playback Speed",
      }
    }
  }
  event_Video_Quality_Option_Select(){
    return {
      event:{
        module_name: "Course | Episode",
        feature_name: "Video Player - Settings",
        event_name: "Video_Quality_Option_Select",
        event_label: "Video Playback Speed",
      }
    } 
  } 
  event_Video_Settings_Icon_Click() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player - Settings",
        event_name: "Video_Settings_Icon_Click",
        event_label: "Video Settings",
      },
    };
  }
  event_Video_PlayBack_Speed_Select() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player - Settings",
        event_name: "Video_PlayBack_Speed_Select",
        event_label: "Playback Speed",
      },
    };
  }
  event_Video_Full_Screen_Toggle() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player - Settings",
        event_name: "Video_Full_Screen_Toggle",
        event_label: "Full Screen",
      },
    };
  }
  event_Video_Completed(){
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player",
        event_name: "Video_Completed",
        event_label: "Video_Completed",
      },
    }
  }
  event_Video_Volume_Update() {
    return {
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player - Settings",
        event_name: "Video_Volume_Update",
        event_label: "Video Volume Update",
      },
    };
  }
  event_Video_Replay(){
    return{
      event: {
        module_name: "Course | Episode",
        feature_name: "Video Player-Overlay",
        event_name: "Video_Replay",
        event_label: "Video Replay",
      },
    } 
  }
  event_Video_Next_Episode(){
    return{
      event: {
        module_name: "Episode",
        feature_name: "Video Player-Overlay",
        event_name: "Video_Next_Episode",
        event_label: "Video Next Episode",
      },
    } 
  }
  event_Video_Feedback_Option_Select(){
    return{
      event: {
        module_name: "Episode",
        feature_name: "Video Player-Overlay",
        event_name: "Video_Feedback_Option_Select",
        event_label: "",
      },
    }
  }
 event_Video_Play_First_Episode(){
  return{
    event: {
      module_name: "Course",
      feature_name: "Video Player-Overlay",
      event_name: "Video_Play_First_Episode",
      event_label: "Video_Play_First_Episode",
    },
  }
 }
}

const videoPlayerEvents = new VideoPlayerEvents();
export default videoPlayerEvents;
