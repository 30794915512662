import { axiosGet } from "axiosRequests";
import { SEARCH_URL } from "constants/Apis";
import { STATUS } from "constants/status.constant";
const model = {
  state: {
    results: [],
    loading: false
  },
  reducers: {
    setSearchResult: (state, payload) => {
      return {
        ...state,
        results: payload
      }
    },
    setSearchLoading: (state, loading) => {
      return {
        ...state,
        loading: loading
      }
    }
  },
  effects: (dispatch) => ({
    async requestSearch(input,undfined, cb) {
      dispatch?.search?.setSearchLoading(true);
      try{
        const url = `${SEARCH_URL}?${input}&pagination[pageSize]=100&pagination[page]=1`;
        const response = await axiosGet(url);
        const {data ,status } = response
        if(STATUS.includes(status)) {
          cb.fun(data?.results?.length)
          dispatch?.search?.setSearchLoading(false);   
          if(cb.searchKey.includes(" ")){
            dispatch.search.setSearchResult(data?.results)
          }
          else{
            let finalResult = []
            data?.results?.filter((_items)=>{         
              const newArray = _items?.courseTitle?.split(" ")
              let matchfound= false
                newArray?.map((val)=>{
                  if(val?.toLowerCase().startsWith(cb.searchKey)){
                        matchfound=true
                  }        
              })
            if(matchfound ) {
              finalResult.push(_items)
            } 
            })
           dispatch.search.setSearchResult(finalResult)
          }
        }else{
          cb.fun(0)
        }
      }
      catch(err){
        cb.fun(0)
        console.log(err)
      }
    },
  }),
};

export default model;
