import Image from "components/Image";
import { password_rule_list } from "constants/Content/rules.constant";
import { LDParagraph } from "stories/Typography";
import { imagePath } from "utils/assetHelper";

const PasswordRules = (password="") => {
    password = password?.password || "";
    
    return (
        <div className="p-4">
            <LDParagraph>Your password must have:</LDParagraph>
            {password_rule_list.map((_rule,index) => {
                const { text, validate } = _rule;
                const isValid = validate(password);
                const cls = isValid ? "text-greeny" : "text-grayColor";
                const checkPath = isValid ? "/circle_check_green.svg" : "/circle_check.svg";
                return (
                    <LDParagraph key={index} className={`text-[0.8rem] flex mt-2 ${cls}`}>
                        <Image className="mr-2 h-4" src={imagePath(checkPath)}/>{text}
                    </LDParagraph>
                )
            })}
        </div>
    )
}

export default PasswordRules;