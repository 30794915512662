import { paymentCouponCodeInputClickEvent } from "analytics/payment";
import { COLOR_CONSTANTS } from "constants/color.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import { useState ,useEffect} from "react";
import FormButton from "stories/FormButton";
import Input from "stories/Input";
import { LDSpan } from "stories/Typography";
import "./index.css"

const AddCoupon = (props) => {
  const {
    handleApplyCoupon,
    isCouponValid,
    handleRemoveCode,
    changeBtn,
    setAppliedCoupon,
    appliedCoupon,
    notValid,
    setNotValid,
    title,
    setIsCouponValid,
    couponCode:resCouponCode
  } = props;
  const [couponCode, setCouponCode] = useState("");
  const isMobile = useMedia()

  const handleChange = (val) => {
    setCouponCode(val);
    setAppliedCoupon(val);
  };
  useEffect(()=>{
    if(resCouponCode){
      setAppliedCoupon(resCouponCode)
      setIsCouponValid(true)
    }
  },[resCouponCode])

  const handleRemove = () => {
    setCouponCode("");
    handleRemoveCode(couponCode);
    setNotValid(false)
  };

  const disabled = couponCode?.length === 0 ? true : false; 
  const buttonStyle = changeBtn
    ? "bg-secondary hover:bg-secondary rounded-md lg:bg-sidebarSelected lg:hover:bg-sidebarSelected text-remove hover:text-remove h-9"
    : "bg-secondary hover:bg-secondary rounded-md lg:bg-sidebarSelected lg:hover:bg-sidebarSelected text-greeny hover:text-greeny h-10 purchase-button-apply-style mt-5";
  const borderStyle = isCouponValid ? "border-2 border-greeny border-dashed" : "border-2 border-remove border-dashed";
  return (
    <div className={`flex  ${isCouponValid && "items-center"} mb-3`}>
      <div className="w-full  mt-5 md:mr-2">
        <Input
          value={ isCouponValid ? `'${appliedCoupon}’ applied!` : appliedCoupon}
          disabled={isCouponValid === true}
          onClick={()=>paymentCouponCodeInputClickEvent({event_label:title})}
          handleChange={isCouponValid ? null : handleChange}
          className={` ${changeBtn ? borderStyle : 'lg:bg-sidebarSelected border-2 border-formBorder '} w-full bg-secondary purchase-course-input-style rounded-md p-[.65rem] outline-none`}
          placeholder="Enter referral coupon code"
        />  
        {notValid && <LDSpan className="error">This coupon is not valid!</LDSpan>} 
      </div>
      {isCouponValid ? (
        <FormButton className={`bg-sidebarSelected mt-5 md:mt-0 hover:bg-sidebarSelected h-8 ${buttonStyle}`} 
          style={{ background: isMobile ? COLOR_CONSTANTS?.SECONDARY_COLOR :  COLOR_CONSTANTS.SIDEBAR_SELECTED,color:COLOR_CONSTANTS.REMOVE}}
          onClick={handleRemove}>
          Remove
        </FormButton>
      ) : (
        <FormButton
          disabled={disabled}
          style={{ background: isMobile ? COLOR_CONSTANTS?.SECONDARY_COLOR : COLOR_CONSTANTS.SIDEBAR_SELECTED, color:COLOR_CONSTANTS.GREEN}}
          className={buttonStyle}
          onClick={()=>{ handleApplyCoupon(couponCode)
          setNotValid(false)
          }}
        >
          Apply
        </FormButton>
      )}
    </div>
  );
};

export default AddCoupon;
