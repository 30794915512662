import useMedia from "hooks/deviceHelper/useMedia";

const VideoPlayerLoader = () => {
  const isMobile = useMedia();
  const containerHeightCls = isMobile ? "min-h-[23vh]" : "h-[470px]";
  return (
    <>
      <div className="rounded-md max-h-md h-full">
        <div className={`flex space-x-4 animate-pulse h-full ${containerHeightCls}`}>
          <div className="flex-1 space-y-4">
            <div className="w-full h-full bg-gray-500"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPlayerLoader;
