import { COLOR_CONSTANTS } from 'constants/color.constants'
import Modal from "components/Modal";
import { useState } from 'react';
import { Button } from 'antd';
import { MESSAGE_CONSTANTS } from 'constants/app.constants';
import { Notifier } from 'components/Notifier';
import { useDispatch } from 'react-redux';
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import "./index.css"
import { Heading, LDParagraph, LDSmall, Title } from 'stories/Typography';
import { imagePath } from 'utils/assetHelper';
import useMedia from 'hooks/deviceHelper/useMedia';
import { useRef } from 'react';
import { useEffect } from 'react';
import LoadingSpinner from 'stories/Loader/LoadingSpinner';
import FormButton from "stories/FormButton";
import { ArrowUpOutlined ,ArrowDownOutlined,ArrowLeftOutlined,ArrowRightOutlined,PlusOutlined,MinusOutlined } from '@ant-design/icons'

const CropImageBeforeUpload = (props) => {
  const { show, src , handleClose} = props
  const  dispatch =  useDispatch()
  const [cropData, setCropData] = useState("#");
  const isMobile = useMedia()
  const [cropper, setCropper] = useState()
  const [loading, setLoading] = useState(false)

  const cropperRef = useRef()

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setLoading(true)
      const canvas = cropper.getCroppedCanvas()
      canvas.toBlob(function(blob){
        const fd = new FormData()
        const file = new File([blob],"filename.png")
        fd.append('files',file)
        const callback= () => {
            setLoading(false)
            handleClose()
            Notifier(200, MESSAGE_CONSTANTS.UPDATE_PROFILE_PIC)
          }
          dispatch?.profile?.requestUpdateProfilePic(fd, callback)
      })
    }
  };
  const style = isMobile ?  {top:"100px"} : {top:"60px"}
  const height = isMobile ? 300 : 400
  const handleScaleIncre =()=>{
    cropper.zoom(0.1)
  }
  const handleScaleDescr =()=>{
    cropper.zoom(-0.1)
  }
  const handleMovement =(params)=>{
     if(params === "left"){
      cropper.move(-10,0)
     }
     else if(params === "right"){
      cropper.move(10,0)
     }
     else if(params ===  "up"){
      cropper.move(0,-10)
     }
     else{
      cropper.move(0,10)
     }
  }
  const controllerCls = "text-greeny cursor-pointer font-bold bg-white px-1 mb-2 rounded"
  const iconSize = {fontSize:"20px",padding:" 0 1px"} 

  return (  
    <Modal
      show={show}
      closable={false}
      style={style}
      bodyStyle={{
        padding: "0px",
        backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
        boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.4)",
        borderRadius: "5px"
      }}
      width={400}
      height={height}
      wrapClassName="bg-[#272d3533] backdrop-blur-sm"
      maskStyle={{
        backdropFilter: "blur(3px)",
        "-webkit-backdrop-filter": "blur(3px)"
      }}
      footer={null}
    >
      <div className='p-3 flex items-center justify-between'>
      <Title className="">Drag a image to adjust </Title>

      <div className="">
          <LDSmall
            className="underline"
          >
            <img src={imagePath("/cross.svg")} alt="crossImg" 
                className="cursor-pointer"
                onClick={handleClose}
            />
          </LDSmall>
        </div>
      </div>

      <div className='relative'>
        <Cropper
          style={{height: height, width: '100%',backgroundColor:"white"}}
          preview=".img-preview"
          zoomTo={0.5}
          ref={cropperRef}
          cropBoxResizable={false}
          minCropBoxHeight={20}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          dragMode={"none"}
          autoCropArea={1}
          initialAspectRatio={1}
          src={src}
          viewMode={1}
          zoomable={true}
          guides={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
        {loading  &&
        <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
          <LoadingSpinner />
        </div> }
        <div className='absolute top-[10%] lg:top-[30%] right-4'>
          <LDParagraph className={`${controllerCls} ld-controller-style`} onClick={handleScaleIncre}>
          <PlusOutlined style={iconSize} />
          </LDParagraph>
          <LDParagraph className={`${controllerCls} ld-controller-style`} onClick={handleScaleDescr}>
          <MinusOutlined style={iconSize}  />
          </LDParagraph>
        </div>
        
        <div className='absolute top-[40%] lg:top-[45%] right-4'>
          <LDParagraph className={`${controllerCls}  ld-controller-style`}  onClick={()=>handleMovement("left")}>
          <ArrowLeftOutlined style={iconSize} />
          </LDParagraph>
          <LDParagraph className={` ${controllerCls} ld-controller-style `} onClick={()=>handleMovement("right")}>
          <ArrowRightOutlined style={iconSize}/>
          </LDParagraph>
          <LDParagraph className={`${controllerCls} ld-controller-style`} onClick={()=>handleMovement("up")}>
            <ArrowUpOutlined style={iconSize}/>
          </LDParagraph>
          <LDParagraph className={`${controllerCls} ld-controller-style`} onClick={()=>handleMovement("down")}>
          <ArrowDownOutlined style={iconSize} />
          </LDParagraph>
        </div>
      </div>
      <div className='text-center mt-2'>
          <FormButton
            onClick={getCropData}
            style={{ backgroundColor: COLOR_CONSTANTS.GREEN ,color:"white"}}
            className={`bg-greeny auth-button-text-style mt-1 mb-4 rounded mx-auto`}
          >
          Upload
        </FormButton>
      </div>
    </Modal>
  )
}
CropImageBeforeUpload.defaultProps ={
  show:false
}

export default CropImageBeforeUpload
