import MultiTags from "components/MultiTags";
import Banner from "components/Banner";
import ContinueWatching from "pages/Home/components/ContinueWatching";
import WatchNow from "pages/Home/components/WatchNow";
import { useSelector, useDispatch } from "react-redux";
import { Heading, Title } from "stories/Typography";
import HorizontalLine from "stories/HorizontalLine";
import { imagePath } from "utils/assetHelper";
import LDImage from "stories/Image";
import MultiLoadingTags from "components/MultiLoadingTags";
import useAuthenticatedEffect from "hooks/useAuthenticatedEffect";
import MultiLoadingBanner from "components/MultiLoadingBanner";
import { Helmet } from "components/Helmet";
import { HELMET_CONSTANT } from "constants/helmet.constants";
import { homeLoadEvent } from "analytics/home";
import { homeWatchnowWishlistEvent } from "analytics/home";
import { useState, useRef, useEffect } from "react";
import useScrollTop from "hooks/useScrollTop";
import useMedia from "hooks/deviceHelper/useMedia";
import InstructorList from "./components/InstrcutorList";
import MysessionAlert from "./components/MysessionAlert";
import { UTCTimeConverter, changeDateToUTCFormat, dateEndTime, dateStartTime, formatDate } from "utils";
import MultiLoadingCards from "components/MultiLoadingCards";
import { functSortTodayDateTimeForSession } from "utils/validateDate";

const Home = () => {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const isMobile = useMedia("(max-width: 600px)");
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const tagListData = useSelector((state) => state?.home?.tags);
  const { tagsLoading, bannerLoading, watchNowLoading } = useSelector(
    (state) => state?.home
  );
  const bannerList = useSelector((state) => state?.home?.banner);
  const { data: searchList } = useSelector((state) => state);
  const watchNowList = useSelector((state) => state?.home?.watchNowList);
  const continueWatchList = useSelector(
    (state) => state?.home?.continueWatchList
  );
  const {instructorList, loading} = useSelector(
    (state) => state?.instructor
  );

  const getTodayMeeting = useSelector(
    (state) => state?.instructor?.todayMeeting
  );


  const profile = useSelector((state) => state?.profile?.profileDetails);
  const { childMode } = profile;
  useScrollTop();
  const { data } = tagListData;
  const isSmallScreenBanner = useMedia("(max-width: 600px)");

  useAuthenticatedEffect(() => {
    /**load start event */
    homeLoadEvent();
    dispatch?.home?.requestTagsList();
    dispatch?.home?.requestBanner(!isSmallScreenBanner);
    dispatch?.home?.requestContinueWatching();

    /**load end event */
    homeLoadEvent(true);
  }, [childMode]);

  useEffect(()=>{
    if(profile?.id){
      dispatch?.instructor?.getInstructorList();
      const isTodays = true 
      const _startDate = dateStartTime(new Date, isTodays) 
      const _endDate = dateEndTime(new Date)
      const payload = {
        user_id : profile?.id,
        startDate : changeDateToUTCFormat(_startDate) ,
        endDate : changeDateToUTCFormat(_endDate), 
      }
      dispatch?.instructor?.getTodayMeeting(payload);
    }
  },[profile?.id])


  const handleWishList = (id, title, type) => {
    dispatch?.commonParams?.requestAddToWishlist(
      { courseId: id, type, courseTitle: title },
      () => {}
    );
    const params = {
      event_label: type, //${title} -
      course_name: title,
    };
    homeWatchnowWishlistEvent(params);
  };

  const getRating = (rating) => {
    console.log(rating);
  };
  const handleScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  
  return (
    <div className="min-h-screen mx-2 lg:m-0">
      {<Helmet title={HELMET_CONSTANT?.HOME} show={false} />}
      {/* hidden the below filter div  */}
      <div className="hidden">
        <HorizontalLine className="opacity-30" />
        <div
          className={
            data === undefined
              ? `w-80 py-6 flex ml-10`
              : `w-full py-6 lg:pl-16 2xl:px-16 flex justify-between overflow-auto`
          }
        >
          {(tagsLoading && data?.length === 0) ||
            (data === undefined && <MultiLoadingTags count={3} />)}
          {data !== undefined && Object.keys(data).length > 0 && (
            <MultiTags
              tagList={data}
              isAppliedFilter={isAppliedFilter}
              setIsAppliedFilter={setIsAppliedFilter}
              handleScroll={handleScroll}
            />
          )}
          {/* <img
              src={imagePath("/Home/filter.svg")}
              alt="filter"
              className="cursor-pointer hidden lg:block"
            /> */}
        </div>
        <HorizontalLine className="opacity-30" />
      </div>
      {/* Main Wrapper*/}
      <div className="mt-2 mb-20 lg:mr-2 ">
        <div className="lg:pl-16 lg:pr-12 2xl:px-16 lg:my-3 lg:block hidden">
          {bannerList?.length !== 0 && (
            <Banner
              isCarouselActive={true}
              bannerImg={imagePath("/card-img.png")}
              bannerList={bannerList}
            />
          )}
          {bannerLoading && bannerList?.length === 0 && (
            <MultiLoadingBanner count={1} />
          )}
        </div>
        {/* for mobile screen */}
        <div className="lg:hidden">
          {bannerList?.length !== 0 && (
            <div className="">
              <Banner isCarouselActive={true} bannerList={bannerList} />
            </div>
          )}
          {bannerLoading && <MultiLoadingBanner count={1} />}
          {/* <div className="bg-primary w-full mt-2 mb-[-14px]"> */}
            {/* <Heading className="tracking-wide mb-2">Filters</Heading> */}
            {/* <div className="flex xs:overflow-x-auto xs:whitespace-nowrap justify-between lg:border-t-2  lg:border-b-2  border-gray-500 "> */}
            {/* {data !== undefined && Object.keys(data).length > 0 && (
              <MultiTags
                tagList={data}
                className=""
                isCarousel={true}
                isAppliedFilter={isAppliedFilter}
                setIsAppliedFilter={setIsAppliedFilter}
                handleScroll={handleScroll}
              />
            )} */}
            {/* {tagsLoading && (
              <div className="w-80 flex">
                {" "}
                <MultiLoadingTags count={3} />{" "}
              </div>
            )} */}
            {/* <LDImage
              src={imagePath("/Home/filter.svg")}
              alt="filter"
              className="cursor-pointer hidden lg:block "
            /> */}
            {/* </div> */}
          {/* </div> */}
        </div>

        {<MysessionAlert {...functSortTodayDateTimeForSession(getTodayMeeting)} />}
        {
          loading   && instructorList?.length == 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6 lg:pl-[4rem] mt-8 lg:pr-[3rem]">
              <MultiLoadingCards count={isMobile ? 1 : 4} />
          </div>
          )
        }
        {instructorList !== undefined && (
          <div className="mb-4">
            <InstructorList instructorList={instructorList} instructorLoading={loading}/>
          </div>
        )}
        

        <div className="lg:pl-16 lg:pr-12 2xl:px-16">
          {!isAppliedFilter && (
            <>
              {continueWatchList !== undefined && (
                <ContinueWatching
                  getRating={getRating}
                  size="text-lg"
                  isEpisodes={false}
                  isStarRatingActive={false}
                  continueWatchList={continueWatchList}
                />
              )}
            </>
          )}
          <div ref={scrollRef}>
            {watchNowList !== undefined && (
              <WatchNow
                getRating={getRating}
                isStarRatingActive={true}
                isEpisodes={true}
                size="text-xl"
                handleWishList={handleWishList}
                watchNowList={watchNowList}
                watchNowLoading={watchNowLoading}
                isWatchNowCard={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
