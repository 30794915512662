import { referralLoadEvent } from "analytics/referral";
import { Col, Row } from "antd";
import { Helmet } from "components/Helmet";
import { HELMET_CONSTANT } from "constants/helmet.constants";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Heading, LDParagraph, Title } from "stories/Typography";
import HowItworks from "./HowItWorks";
import Redeem from "./Redeem";
import "./index.css"
import { STRINGS } from "constants/app.constants";
import useAuthenticatedIpEffect from "hooks/useAuthenticatedIpEffect";

const ReferFriend = () => {
  const state = useSelector((state) => state?.profile?.profileDetails);
  const referralDesc= useSelector((state)=>state?.referral?.referralDesc)
  const { referralCode } = state
  const { id, attributes} = referralDesc
  const dispatch = useDispatch();
  
  useAuthenticatedIpEffect(() => {
    referralLoadEvent()
    dispatch?.referral?.requestReferralPage()
    referralLoadEvent(true)
  }, []);
  return (
    <div className="container  mx-auto lg:px-[7rem]  p-2 min-h-screen">
      {<Helmet  title={HELMET_CONSTANT?.REFERRAL}/> }
      <div className="lg:mt-10 text-center lg:hidden">
        <Heading>{STRINGS.REFER_A_FRIEND}</Heading>
      </div>
      <div className="text-center lg:block hidden mt-[2.8rem] mb-[2rem]">
        <h2 className="text-white refer-heading-style">
        {attributes?.heading}
        </h2>

        <LDParagraph className="mt-5 refer-heading-attribute-style">
         {attributes?.desc}
        </LDParagraph>
      </div>
      <div className=" pt-1 mx-4"> 

      <Row gutter={50}>
        <Col lg={13} md={24} sm={24} xs={24} style={{paddingLeft:"9px",paddingRight:"9px"}}>
          <Redeem referCode={referralCode } discount={attributes?.discountValue} desc={attributes?.desc}  isPercentage={attributes?.isPct} currencySymbol={attributes?.currencySymbol}/>
        </Col>
        <Col lg={11} md={24} sm={24} xs={24} style={{paddingLeft:"9px",paddingRight:"9px"}}>
          <HowItworks referCode={referralCode} discount={attributes?.discountValue} isPercentage={attributes?.isPct} currencySymbol={attributes?.currencySymbol} />
        </Col>
      </Row>
      </div>

    </div>
  );
};

export default ReferFriend;
