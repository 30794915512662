import { imagePath } from "utils/assetHelper";
import "./index.css";
import LDImage from "stories/Image";
import CardImage from "../CardImage";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import { useState } from "react";
import DownloadApp from "components/DownloadApp";
import { SESSION_STATUS, UTCTimeConverter, enableModifyButton, enabledBookAgainButton, formatDate, getDateformat, reverseDate, sessionStartTime15MinsAfter, sessionStartTime15MinsBefore } from "utils";
import { TAB_TYPE } from "pages/Instructors/components/MySessions";
import MultiLoadingCards from "components/MultiLoadingCards";
import RescheduleModal from "pages/Instructors/components/MySessions/RescheduleModal";
import Modal from "components/Modal";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { useDispatch, useSelector } from "react-redux";
import { Notifier } from "components/Notifier";
import SessionConcent from "./SessionConcent";
import { countDownTimer } from "utils/countDownTimer";
import { rescheduleStartClickEvent } from "analytics/mysessions";
import RefundModal from "pages/Instructors/components/MySessions/RefundModal";

export const MysessionCard = ({ data, handleButtonClick, tabType ,currentTab}) => {
  const isSmallScreenOnPotraitView = useMedia("(max-width:760px)");
  const isSmallScreenOnLandScapView = useMedia("(max-width:950px)");
  const screenOrientation = window?.screen?.orientation;
  const isSmallScreen = (isSmallScreenOnPotraitView || isSmallScreenOnLandScapView && screenOrientation?.type === "landscape-primary")

  const dispatch = useDispatch()
  const [isAlertModal, setIsAlertModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false)
  const [isJoinConcent, setIsJoinConcent] = useState(false)
  const profileInfo = useSelector((state) => state?.profile?.profileDetails); 
  const [openReasonModal,  setOpenReasonModal]  = useState(false)
  const [refundModal, setRefundModal] = useState(false)
  const {
    id,
    instructor_name,
    instructor_designation,
    date,
    start_time,
    end_time,
    instructor_image,
    sessionStatus,
    meeting_id,
    attendee_id,
    old_slot_id,
    cancellation_reason,
    is_reschedulled
  } = data;
  const Heading = ({ children, className }) => {
    return <h1 className={className}>{children}</h1>;
  };

  const JoinButton = ({ children, className, onClick, disabled }) => {
    return (
      <button onClick={onClick} className={className} disabled={disabled}>
        {children}
      </button>
    );
  };

  const BgcolorMap = {
    completed: "bg-greeny text-white",
    upcoming: "bg-white text-greeny",
    cancelled: "bg-red-400 text-white",
    postponed: "bg-yellow-400 text-white",
    "on hold": "bg-orange-400 text-white",
    rescheduled:"bg-white text-greeny",
    expired:"bg-white text-red-400",
    ongoing:"bg-greeny text-white"
  };

  const funCallMap = {
    [TAB_TYPE.upcomming]: (payload) => {
      handleButtonClick(payload);
    },
    [TAB_TYPE.completed]:()=>{
      handleButtonClick()
    }
  };

  const history = useHistory();
  const handleClick = () => {
    if (isSmallScreen && currentTab == 1) {
      setIsAlertModal(true);
    } else {
      const payload = {
        meeting_id: id,
        userType: 2,
      };
      funCallMap[tabType](payload);
    }
  };

  const handleInvoice = () => {
      window.open(`/session/invoice/${id}`, `_blank`);
  };

  const handleReasonModal =()=>{
    setOpenReasonModal(!openReasonModal)
  }

const handleRefund =(data)=>{
  
    const payload = {
      meeting_id : data?.id
    }
    dispatch?.instructor?.requestRefund(payload,(status, _data)=>{
      if(status === 200){
        Notifier(status, (_data?.message))
        setRefundModal(false)
        setTimeout(()=>{
          history.push({
            pathname: `${ROUTE_CONSTANTS?.BOOKING_CONFIRMED}/${data?.id}`,
            search: "?_c=true",
            state: { data, profileInfo },
          });
        },[1000])
      }else{
        Notifier(status, (_data?.message || "Something went wrong."))
      }
    })
  }

  const rescheduleStartEvent = () =>{
    const PreviousSlots = {
      StartDate: formatDate(data?.date,"DDMMYYYY"),
      StartTime : UTCTimeConverter(data?.start_time)
    }
    const extraParams = {
      event_label : profileInfo?.mobileNumber || profileInfo?.email,
      instructor_name :data?.instructor_name ||  data?.instructorsId ,
      session_info : JSON.stringify(PreviousSlots)
    }
    rescheduleStartClickEvent(extraParams)

    history.push({
      pathname: `${ROUTE_CONSTANTS?.INSTRUCTORINFORMATION}/${data?.instructorsId}`,
      search: `?_rs=true&_m=${data?.id}`,
      state: { data, profileInfo },
    })
   }

//session startTime and endTime 
const sessionStartTime = new Date(start_time)
const sessionEndTime = new Date(end_time)
const _sessionStartTime = sessionStartTime15MinsBefore(sessionStartTime)
const _sessionEndTime = sessionStartTime15MinsAfter(sessionEndTime)
const _compareSessionStartAndEndTime =new Date() >= new Date(_sessionStartTime) && new Date() <= new Date(_sessionEndTime)
const _isSessionStarted = new Date() >= new Date(sessionStartTime)
   
   const _today = new Date()
   const today_date = formatDate(_today)
   const session_date = formatDate(new Date(start_time))
   const _timerSetFortoday = today_date === session_date && _compareSessionStartAndEndTime

    // ******************* DONOT REMOVE ********************//
    //countdown timer
    // const _ishourLessThanone =_compareSessionStartAndEndTime &&  countDownTimer(start_time)?.[0] 
    // const _isMinutesLessThan15 =_compareSessionStartAndEndTime && countDownTimer(start_time)?.[1] 
    // const _getSeconds =_compareSessionStartAndEndTime && countDownTimer(start_time)?.[2]

    //function for disabling the button
     const makeButtonDisable =(status )=>{
       if( status === SESSION_STATUS?.SESSION_UPCOMING && !_timerSetFortoday){
        return true 
       }else if(status === SESSION_STATUS?.SESSION_RESCHEDULED && !_timerSetFortoday){
        return true 
       }else if(status === SESSION_STATUS?.SESSION_ONGOING && !_timerSetFortoday){
        return true
       }else{
        return false
       }
     }
    
      // ******************* DONOT REMOVE ********************//
    //function for enabling the button to join the session 
    // const makeButtonEnable = (status, _isDisabled)=>{
    //   if(status === SESSION_STATUS?.SESSION_UPCOMING && !_isDisabled && _isSessionStarted ){
    //     return "Join now" //for joining the session
    //   }else if(status === SESSION_STATUS?.SESSION_RESCHEDULED && !_isDisabled && _isSessionStarted ){
    //     return "Join now" //for joining the session
    //   }else if(status === SESSION_STATUS?.SESSION_ONGOING && !_isDisabled && _isSessionStarted ){
    //     return "Join now" //for joining the session
    //   }else if(new Date() > new Date(_sessionEndTime) && sessionStatus === SESSION_STATUS?.SESSION_UPCOMING){
    //     return "Session expired"
    //   }else if(new Date() > new Date(_sessionEndTime) && sessionStatus === SESSION_STATUS?.SESSION_RESCHEDULED){
    //     return "Session expired"
    //   }else if(new Date() > new Date(_sessionEndTime) && sessionStatus === SESSION_STATUS?.SESSION_ONGOING){
    //     return "Session expired"
    //   }
    // }

    // ******************* DONOT REMOVE ********************//
    //function for enabling join button before 15mins and timer for 10 mins
    // const buttonWithTimer = (status, _isDisabled) =>{
    //   if(status === SESSION_STATUS?.SESSION_UPCOMING && status !== SESSION_STATUS?.SESSION_COMPLETE   && _timerSetFortoday && _isMinutesLessThan15 >=0 ){
    //     if(_isMinutesLessThan15>0){
    //       return `Starts in ${_isMinutesLessThan15}m ${_getSeconds}s`
    //     }else if(_isMinutesLessThan15<=0){
    //       return `Starts in ${_getSeconds}s`
    //     }
    //   }else if(status === SESSION_STATUS?.SESSION_RESCHEDULED && status !== SESSION_STATUS?.SESSION_COMPLETE && _timerSetFortoday && _isMinutesLessThan15 >=0 ){
    //     if(_isMinutesLessThan15>0){
    //       return `Starts in ${_isMinutesLessThan15}m ${_getSeconds}s`
    //     }else if(_isMinutesLessThan15<=0){
    //       return `Starts in ${_getSeconds}s`
    //     }
    //   }else if( _isDisabled && !_isSessionStarted ){ 
    //     return "Join now"
    //   }else if(status === SESSION_STATUS?.SESSION_ONGOING && _isMinutesLessThan15 >=0 && new Date(_sessionEndTime) > new Date()){
    //     return "Join now"
    //   }
    // }

    //TimerDisableCondition
    const _isDisabled =  makeButtonDisable(sessionStatus) // sessionStatus === SESSION_STATUS?.SESSION_UPCOMING || sessionStatus === SESSION_STATUS?.SESSION_RESCHEDULED  && !_timerSetFortoday ? true : false //TODO:fix required
    const enableButtonOnDev = process.env.REACT_APP_ENV !== "production" ? false : _isDisabled //disabling join buttons only on production

  return (
    <>
      {/* <div className="border-[1px] border-[#434752] rounded-lg relative"> */}
        <div className={`p-[1rem] border-[1px] border-[#434752] rounded-lg relative my--session--container ${sessionStatus}`}>
          <div className="text-right">
            <Heading
              className={`${BgcolorMap[sessionStatus]} shadow inline-block px-[8px] py-[2px] text-right mb-[28px] text-[12px] font-bold rounded-sm`}
            >
             {sessionStatus === SESSION_STATUS?.SESSION_EXPIRED ? `SESSION ${sessionStatus.toUpperCase()}` : sessionStatus.toUpperCase()}
            </Heading>
          </div>
          <div className="flex gap-[1rem] ">
            <div className="flex flex-col basis-full">
              <Heading className="flex text-[13px] text-white font-medium mb-[15px] items-center gap-4 font-[BWModelica-Medium]">
                <LDImage
                  className="w-[20px]"
                  src={imagePath("/calendar.svg")}
                />
                {getDateformat(start_time)}
              </Heading>
              <Heading className="flex text-[12px] text-white font-medium mb-[15px] items-center text-center gap-4 font-[BWModelica-Medium]">
                <LDImage className="w-[20px]" src={imagePath("/clock.svg")} />
                {UTCTimeConverter(start_time)} - {UTCTimeConverter(end_time)}
              </Heading>

              
              <Heading className="flex text-[12px] text-white font-medium gap-4">
                <LDImage className="w-[20px]" src={imagePath("/profile.svg")} />
                <div className="flex flex-col basis-full font-[BWModelica-Medium]">
                  {instructor_name}
                  <span className="text-[#d9d9d9] h-[50px]">
                    {instructor_designation || " "}
                  </span>
                </div>
              </Heading>
            
            </div>
          </div>
        
          <div className="instructor--profile">
            <CardImage
              style={{
                // width: "64px",
                // height: "64px",
                borderRadius: "50%",
                overflow: "hidden",
                objectFit: "cover",
              }}
              src={instructor_image || imagePath("/instructors.svg")}
              className=" mx-auto w-[55px] h-[55px] md:w-[64px] md:h-[64px]"
            />
          </div>
              {/* timer */}
          {/*  */}
          <div>
       {/* { sessionStatus === SESSION_STATUS?.SESSION_ONHOLD &&<p className="text-greeny cursor-pointer max-w-fit mb-[10px] underline" onClick={handleReasonModal}> View reason </p>  } */}
              { sessionStatus === SESSION_STATUS?.SESSION_ONHOLD &&<p className="text-red-400 max-w-fit mb-[10px] text-[12px]" > This session was cancelled due to unavoidable reason. Please select one of the below options. </p>  }

          {data?.refund_status && sessionStatus === SESSION_STATUS?.SESSION_CANCELLED && // Fix this 
               <Heading className="flex text-[12px] font-medium items-center gap-4 mb-[20px] text-greeny">
              <LDImage className="w-[20px]" src={imagePath("/refund.svg")} /> <span className="text-white"> REFUND :</span>{data?.refund_status} 
              </Heading>}
          </div>
          <div className="buttons---block flex flex-col gap-[12px] ">
            { sessionStatus !== SESSION_STATUS.SESSION_ONHOLD &&
            <JoinButton
              onClick={ sessionStatus === SESSION_STATUS?.SESSION_UPCOMING && !isSmallScreen  || sessionStatus === SESSION_STATUS?.SESSION_RESCHEDULED && !isSmallScreen ? ()=>setIsJoinConcent(true): handleClick }
              className={`bg-greeny text-white px-[2rem] md:py-[.6rem] py-[.8rem] rounded-lg text-[16px] font-medium text-center font-[BWModelica-Medium] ${enableButtonOnDev && "opacity-50 cursor-not-allowed"}`}
              disabled={enableButtonOnDev}
            > 
            {/* *************** DONOT REMOVE ANYTHING ********************** */}
            {/* enable join button while session time is going on */}
              {/* {makeButtonEnable(sessionStatus, _isDisabled)} */}

              {/* enable join button if session time is before 15min */}
              {/* {buttonWithTimer(sessionStatus, _isDisabled)} */}

              {/* when session status isNot upcomming or postponed  */}
              {enabledBookAgainButton?.includes(sessionStatus) ? "Book again" : "Join now"}
            </JoinButton>
            }
            {sessionStatus && enableModifyButton?.includes(sessionStatus) && (
              <>
              <JoinButton
                className="bg-transparent text-white px-[2rem] py-[.8rem] md:py-[.6rem] rounded-lg text-[16px] font-medium font-[BWModelica-Medium] text-center border-[1px]"
                onClick={()=>setRescheduleModal(true)}
              >
                Modify Session
              </JoinButton>
              </>   
            )} 
            {
              sessionStatus === SESSION_STATUS.SESSION_ONHOLD  ?
              <>
              {/* <div className="flex justify-between mt-10">
              <p className="text-greeny cursor-pointer max-w-fit" onClick={handleReasonModal}> View reason </p>  
              {data?.refund_status && 
               <Heading className="flex text-[12px] text-white font-medium items-center gap-4">
                {data?.refund_status}
              </Heading>}
              </div> */}
             
             <JoinButton
                className="bg-greeny text-white font-['BWModelica-Medium'] px-[2rem] md:py-[.6rem] py-[.6rem] w-full rounded-lg text-[14px] font-medium text-center"
                onClick={() => rescheduleStartEvent()}
              >
                Reschedule
              </JoinButton>
              <JoinButton
                className="bg-transparent text-white px-[2rem] py-[.8rem] md:py-[.6rem] rounded-lg text-[16px] font-medium text-center border-[1px]"
                // onClick={()=>handleRefund(data)}
                onClick={()=>setRefundModal(true)}
              >
              Get refund
            </JoinButton>
            
              </>
              :
              <>
             {/* {data?.refund_status && 
               <Heading className="flex text-[12px] text-white font-medium items-center gap-4">
                {data?.refund_status}
              </Heading>} */}
              </>
            }
            {
              sessionStatus && sessionStatus === SESSION_STATUS?.SESSION_COMPLETE && (
                <JoinButton
                className="bg-transparent text-white px-[2rem] py-[.8rem] md:py-[.6rem] rounded-lg text-[16px] font-medium text-center border-[1px]"
                onClick={handleInvoice}
              >
                  View Invoice
              </JoinButton>
              )
            }
          </div>
          {refundModal && <RefundModal handleClose={()=>setRefundModal(false)} show={refundModal} onOk={()=>handleRefund(data)}/>}
          {rescheduleModal && <RescheduleModal tabType={tabType} handleClose={()=>setRescheduleModal(false)} show={rescheduleModal} data={data}/>}
          {isJoinConcent && <SessionConcent show={isJoinConcent} handleClose={()=>setIsJoinConcent(false)} onOk={handleClick} />}
          {isAlertModal && (
            <DownloadApp
              handleClose={() => setIsAlertModal(false)}
              show={isAlertModal}
              message={
                "Get Lifedemy App to Join the Session Download the App Now"
              }
            />
          )}
          {/* do not remove */}
          {/* {
            openReasonModal &&
            <Modal
              show={openReasonModal}
              closable={true}
               centered
               clas
               width={400}
               maskClosable={false}
               bodyStyle={{
                 backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
               }}
               maskStyle={{
                 backgroundColor: COLOR_CONSTANTS.MODAL_COLOR,
                 opacity: "0.8",
               }}
               footer={null}
             onCancel={handleReasonModal}
            >
              <Heading
              className="flex text-[26px] text-white font-medium mb-[15px] items-center gap-4">
              {cancellation_reason}
              </Heading>
            </Modal>
          } */}
        </div>
      {/* </div> */}
    </>
  );
};
