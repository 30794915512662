import * as eventConstants from "./events.constants";
import profileEvents from "./ProfileEvents";

const handleEvent = (eventCode, extraParams = {}) => {
  profileEvents.handle_analytics_event(eventCode, extraParams);
};

export const profileEditClickEvent = (extraParams) => {  // replace in GTM Profile_Edit_Click to Profile_Pic_Edit_Click 
  const eventCode = eventConstants?.PROFILE_EDIT_CLICK;
  handleEvent(eventCode, extraParams);
};

export const profilePersonalDetailsEditClickEvent = (extraParams ) => {
  const eventCode = eventConstants?.PROFILE_PERSONAL_DETAILS_EDIT_CLICK;
  handleEvent(eventCode, extraParams);
};
export const profilePersonalDetailsInputClickEVent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PERSONAL_DETAILS_INPUT_CLICK;
  handleEvent(eventCode, extraParams);
};
export const profilePersonalDetailsDobClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PERSONAL_DETAILS_DOB_CLICK;
  handleEvent(eventCode, extraParams);
};
export const profilePersonalDetailsSaveBtnClickEvent = () => {
  const eventCode = eventConstants?.PROFILE_PERSONAL_DETAILS_SAVE_BTN_CLICK;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};
export const profileEmailVerifyClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_EMAIL_VERIFY_CLICK;
  handleEvent(eventCode, extraParams);
};
export const profileEmailVerifyBtnClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_EMAIL_VERIFY_BTN_CLICK;
  handleEvent(eventCode, extraParams);
};
export const profileEmailDoneBtnClickEvent = (extraParams ) => {
  const evenCode = eventConstants?.PROFILE_EMAIL_DONE_BTN_CLICK;
  handleEvent(evenCode, extraParams);
};

export const profilePhoneVerifyIconClickEvent = (extraParams) => {
  const evenCode = eventConstants?.PROFILE_PHONE_VERIFY_ICON_CLICK;
  handleEvent(evenCode, extraParams);
};

export const profilePhoneCancelBtnClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PHONE_CANCEL_BTN_CLICK;
  handleEvent(eventCode, extraParams);
};

export const profilePhoneInputClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PHONE_INPUT_CLICK;
  handleEvent(eventCode, extraParams);
};

export const profilePhoneSelectCountryCodeEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PHONE_SELECT_COUNTRY_CODE;
  handleEvent(eventCode, extraParams);
};
export const profilePhoneGenerateOtpBtnClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PHONE_GENERATE_OTP_BTN_CLICK;
  handleEvent(eventCode, extraParams);
};

export const profilePhoneOtpInputClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PHONE_OTP_INPUT_CLICK;
  handleEvent(eventCode, extraParams);
};

export const profilePhoneResendOtpClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PHONE_RESED_OTP_CLICK;
  handleEvent(eventCode, extraParams);
};
export const profilePhoneOtpVerifyBtnClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PROFILE_PHONE_OTP_VERIFY_BTN_CLICK;
  handleEvent(eventCode, extraParams);
};

export const profileLogoutBtnClickEvent =(extraParams)=>{
  const eventCode = eventConstants?.PROFILE_LOGOUT_BTN_CLICK
  handleEvent(eventCode, extraParams)
}