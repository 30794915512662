
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function useIpEffect(cb, dependencyArr=[]){
    const ipFetched = useSelector((state)=> state?.commonParams?.ipInfo?.fetched)
    
    useEffect(() => {
      if(ipFetched){
        cb()
      }
    } ,[ipFetched,...dependencyArr])
}