export const HELMET_CONSTANT = {
    HOME: "Lifedemy.in - Unlock Your Potential with Expert-Led Life Skills Courses",
    WISHLIST: "wishlist",
    MYCOURSE: "mycourse",
    REFERRAL:"referral",
    SUGGESTIONS:"suggestions",
    OFER_AND_DISCOUNT :"offers_and_discount",
    SETTINGS:"settings",
    PURCHASE:"purchases",
    HELP:"help",
    privacy:"privacy",
    PAYMENT : "payment"
  };
  