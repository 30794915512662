import React, { useEffect } from "react";
import LDImage from "stories/Image";
import { Heading, LDParagraph, LDSpan } from "stories/Typography";
import { Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import FormButton from "stories/FormButton";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useHistory } from "react-router-dom";
import { UTCTimeConverter, UtcTimeConverted, formatDate, getDateFromatWithMonthAndYear } from "utils";
import Image from "components/Image";
import { useSelector } from "react-redux";
import { bookASessionClickEvent } from "analytics/instructor";
import useMedia from "hooks/deviceHelper/useMedia";

const InstructorCard = (props) => {
  const {
    profile_image_url,
    instructor_name,
    designation,
    rating,
    session_completed,
    next_available_slot,
    nextAvailableSlot = false,
    iw = true,
    instructor_id,
    isInstructorPage,
    tags
  } = props;
  const history = useHistory();
  const applyRoundImage = iw
    ? "h-[20vh] md:h-[24vh] 2xl:h-[12vh]"
    : "h-[20vh] md:h-[24vh] 2xl:h-[12vh]";

    //   ${!isInstructorPage && applyRoundImage}

    const isMobile =  useMedia("(max-width:800px)")
    const tagCount = isMobile ? 2 : 3
    const instructorTags = tags?.split(",").slice(0,tagCount)

    const appConfig = useSelector((state)=> state?.commonParams?.app_breakpoint_configs);
    const profile = useSelector((state) => state?.profile?.profileDetails);

    const { INSTRUCTOR_CARD_DIMENSION } = appConfig;
  
    const handleCardClick = () => {
      const event_label = Object.keys(profile).length !== 0 ? `${profile?.mobileNumber || ''} | ${profile?.email || ''}` : 'Guest User'
      const eventPayload = {
        instructor_name: instructor_name,
        event_label:event_label
      }
      bookASessionClickEvent(eventPayload, !isInstructorPage)
      history.push(
        `${ROUTE_CONSTANTS?.INSTRUCTORINFORMATION}/${instructor_id}`
      );
    }

  return (
    <div className={`flex flex-col justify-between`}>
      <div
        className={`instructor instructor--details bg-[#1F252D] ${!isInstructorPage && "mx-1"}`}
        onClick={handleCardClick}
      >
        <Image
          src={profile_image_url}
          // {...imgRestProps}
          className={`object-cover object-top w-full rounded-[.7rem] ${isInstructorPage && "w-full" }`}
          dimension={INSTRUCTOR_CARD_DIMENSION}
        />
        <div className={``} >
          <div className={`list--content text-center`}>
            <Heading className="instructor--card--heading break-all text-[12px] md:text-[12px] text-white mt-2 line-clamp-1 ">
              {instructor_name}
            </Heading>
            <LDParagraph className="text-greeny font-normal my-[.25rem] line-clamp">
              {designation}
            </LDParagraph>
          </div>
          {isInstructorPage && instructorTags?.length > 0 &&                     
            <div className="instructor__tags list__instructor__tags !bg-[#242c36] !mt-0 !rounded-[4px] !px-[.2rem] lg:!px-[.4rem] max-[800px]:!px-[.5rem]  max-[800px]:!max-w-[100%]">
              {instructorTags.map((item)=>(
                 <div className="list__tags tags_content !px-[5px]"> {item} </div>
              ))}
            </div>
          }
          {/* <Title className="rating--all flex my-2 gap-2 items-center justify-center">
          {" "}
          <div className="flex items-center gap-1">
            <LDImage src={imagePath("/star.svg")} className="w-4 h-4" />
            {rating}{" "}
          </div>
          <span className="flex flex-col text-[#abadb2] text-[9px] md:text-[12px] md:gap-1 font-thin">
            ({session_completed} Sessions)
          </span>
        </Title> */}
         {isInstructorPage &&
        <div className="flex justify-center">
           <div className="text-white font-normal my-[.25rem] text-[0.65rem] next__available">
            <span className="font-[BWModelica-Regular] text-[#808080] text-[10px]">Next available slot :</span> {next_available_slot ? `${formatDate(next_available_slot?.start_time, "DDMMYYYY")}, ${UTCTimeConverter(next_available_slot?.start_time)}`  : "No slot available"}
          </div>
        </div>
        }
          <FormButton
            className={`buttonAdjust cursor-pointer  w-full lg:mx-[.7rem] rounded-lg hover:text-white text-[12px] lg:mb-0 text-white`}
          >
            Book a Session
          </FormButton>
        </div>
      </div>
    </div>
  );
};

export default InstructorCard;
