export const STATUS = ["OK", 200];

export const STATUS_CODE = {
  successful: 200,
  unAuthorized: 401,
  badRequest: 400,
  notFound: 404,
  tooManyRequest: 429
};

export const PROVIDER = ["phone","facebook","google"]
