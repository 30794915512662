import * as eventConstants from "./events.constants";
import paymentEvents from "./PaymentEvents";

const handleEvent = (eventCode, extraParams = {}) => {
  paymentEvents.handle_analytics_event(eventCode, extraParams);
};

export const paymentInitiatePurchaseClickEvent = (extraParams) => {
  const eventCode = eventConstants?.INITIATE_PURCHASE_CLICK;
  handleEvent(eventCode, extraParams);
};

export const paymentLoadEvent = (loadEnd = false,extraParams) => {
  const eventCode = loadEnd
    ? eventConstants?.PAYMENT_LOAD_END
    : eventConstants?.PAYMENT_LOAD_START;
  handleEvent(eventCode, extraParams);
};

export const paymentCouponCodeInputClickEvent = (extraParams) => {
  const eventCode = eventConstants?.PAYMENT_COUPON_CODE_INPUT_CLICK;
  handleEvent(eventCode, extraParams);
};

export const paymentCouponCodeApplyBtnClickEvent = (extraParams ) => {
  const eventCode = eventConstants?.PAYMENT_COUPON_CODE_APPLY_BTN_CLICK;
  handleEvent(eventCode, extraParams);
};

export const paymentUseCouponCodeClickEvent=(extraParams)=>{
  const eventCode = eventConstants?.PAYMENT_USE_COUPON_CODE_CLICK
  handleEvent(eventCode, extraParams)
}

export const paymentCouponCodeModalCancelClickEvent=(extraParams)=>{
  const eventCode = eventConstants?.PAYMENT_COUPON_CODE_MODAL_CANCEL_CLICK
  handleEvent(eventCode, extraParams)
}

export const paymentCouponCodeModalApplyClickEvent =(extraParams)=>{
  const eventCode = eventConstants?.PAYMENT_COUPON_CODE_MODAL_APPLY_CLICK
  handleEvent(eventCode, extraParams)
}

export const paymentContinuePurchaseBtnClickEvent=(extraParams)=>{
  const eventCode = eventConstants?.PAYMENT_CONTINUE_PURCHASE_BTN_CLICK
  handleEvent(eventCode, extraParams)
}
export const paymentStatusLoadEvent=(extraParams)=>{
  const eventCode = eventConstants?.PAYMENT_STATUS_LOAD
  
  const purchaseEventCode = eventConstants?.PURCHASE_EVENT
  const purchaseEventParams = extraParams?.purchaseEventParams;
  delete extraParams['purchaseEventParams']

  handleEvent(eventCode, extraParams)

  // Payment success conversion 
  handleEvent(purchaseEventCode, purchaseEventParams)
}
export const paymentGotoCourseEvent=(extraParams)=>{
  const eventCode = eventConstants?.PAYMENT_GO_TO_COURSE_BTN_CLICK
  handleEvent(eventCode,extraParams)
}
export const PaymentStatusTryAgainBtnClickEvent=(extraParams)=>{
  const eventCode = eventConstants?.PAYMENT_STATUS_TRY_AGAIN_BTN_CLICK
  handleEvent(eventCode,extraParams)
}

export const PaymentUpdateEmailSuccess =(extraParams = {})=>{
  const eventCode = eventConstants?.PAYMENT_UPDATE_EMAIL_SUCCESS
  handleEvent(eventCode,extraParams)
}

export const PaymentSuccessEvent = (extraParams = {}) =>{
  const eventCode = eventConstants?.PAYMENT_SUCCESS
  handleEvent(eventCode, extraParams)
} 
export const PaymentFailedEvent = (extraParams = {}) =>{
  const eventCode = eventConstants?.PAYMENT_FAILED
  handleEvent(eventCode, extraParams)
}


// Guest Payment events

export const IntiateGuestPayment =(extraParams = {}) =>{
  const eventcode = eventConstants?.INITIATE_GUEST_PAYMENT
  handleEvent(eventcode, extraParams)
}

export const GuestPaymentPageLoaded =(extraParams = { }) =>{
  const eventCode = eventConstants.GUEST_PAYMENT_PAGE_LOADED
  handleEvent(eventCode, extraParams)
}

export const ContinueGuestPurchaseSuccess =(extraParams = {})=>{
    const eventCode = eventConstants?.CONTINUE_GUEST_PURCHANSE_SUCCESS
    handleEvent(eventCode, extraParams)
}

export const ContinueGuestPurchaseError =(extraParams= {}) =>{
  const eventCode = eventConstants?.CONTINUE_GUEST_PURCHANSE_ERROR
  handleEvent(eventCode, extraParams)
}
export const GuestPaymentCancelled = (extraParams ={})=>{
    const eventCode = eventConstants.GUEST_PAYMENT_CANCELLED
    handleEvent(eventCode, extraParams)
}

export const GuestPaymentSuccess = (extraParams = {}) =>{
  const eventCode = eventConstants?.GUEST_PAYMENT_SUCCESS
  handleEvent(eventCode, extraParams)
} 
export const GestPaymentFailed = (extraParams = {}) =>{
  const eventCode = eventConstants?.GUEST_PAYMENT_FAILED
  handleEvent(eventCode, extraParams)
}
