import { videoPlaybackSpeedUpdate, videoSettingsClickEvent, videoVolumeUpdateEvent } from "analytics/videoPlayer";
import { Slider } from "antd";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { playbackRateOptions, videoQualityOptions } from "constants/videoplayer.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import { useState } from "react";
import { LDLabel, LDSpan } from "stories/Typography";
import useVideoPlayerState from "stories/VideoPlayer/hooks/useVideoPlayerState";
import { imagePath } from "utils/assetHelper";
import PlayerActionPopUp from "../PlayerActionPopUp";
import "./index.css";

const BottomControls = props => {
  const {
    playing,
    handlePlayPause,
    handlePlayerSeeking,
    onSeekMouseUp,
    toggleFullScreen,
    elapsedTime,
    totalDuration,
    settingsRef,
    handleQualityChange,
    playerDuration,
    setPlayerDuration,
    episodeTitle,
    courseTitle,
    isfullScreen,
    isIntro
  } = props;
  const {
    volume,
    setVolume,
    mute,
    setMute,
    setPlaybackRate,
    playbackRate,
    quality,
    qualityAllowed
  } = useVideoPlayerState();

  const [activeActionId, setActiveActionId] = useState(-1);
  function formatter(value) {
    return `${value}%`;
  }
  const progress = Number(playerDuration * 100).toFixed(2);
  const handleSeek = value => {
    handlePlayerSeeking(true);
    setPlayerDuration(value / 100);
  };

  const handleVolumeChange = value => {
    const updatedVolume = value / 100;
    setVolume(updatedVolume);
    if (mute) {
      setMute(false);
    }
    const event_param = { event_label: `${volume} | ${updatedVolume}`,course_name:courseTitle,episode_name:episodeTitle};
    videoVolumeUpdateEvent(event_param);
  };

  const toggleMute = () => {
    setMute(!mute);
  };

  const handlePlaybackRateChange = rate => {
    setPlaybackRate(rate);
    const event_param = { event_label: `${playbackRate}x | ${rate}x`,course_name:courseTitle,episode_name:episodeTitle};
    videoPlaybackSpeedUpdate(event_param);
  };

  const handleActionToggle = (actionId) => {
    if(activeActionId === actionId){
      setActiveActionId(-1);
    }else{
      setActiveActionId(actionId);
    }
  }
  const isMobile = useMedia();
  const wrapperExtraCls = isMobile ? "px-[0.5rem]" : "px-[1rem]";
  return (
    <div>
      <div className="player-progressbar-wrapper">
      {/* <Progress percent={progress} strokeColor="green" strokeWidth="4px" showInfo={false} /> */}
        <Slider
          step={0.1}
          handleStyle={{ backgroundColor: COLOR_CONSTANTS['GREEN'] }}
          tipFormatter={formatter}
          trackStyle={{ backgroundColor: COLOR_CONSTANTS['GREEN'] }}
          defaultValue={progress}
          value={progress}
          onChange={handleSeek}
          onAfterChange={onSeekMouseUp}
          tooltipVisible={false}
        />
      </div>

      <div className={`flex items-center justify-between ${wrapperExtraCls}`}>
        {/* Right side*/}
        <div className="flex items-center">
          {!isMobile && (
            <div className="p-2 cursor-pointer w-[2.25rem]" onClick={handlePlayPause}>
              <img
                src={imagePath(
                  playing
                    ? "/VideoControls/pause.svg"
                    : "/VideoControls/play.svg"
                )}
                className="h-[3vh]"
                alt="play"
              />
            </div>
          )}

          {!isMobile && (
            <div className="p-2 flex items-center cursor-pointer">
              <img
                onClick={toggleMute}
                className="h-[3vh]"
                src={imagePath(
                  mute
                    ? "/VideoControls/muted.svg"
                    : "/VideoControls/volume.svg"
                )}
                alt="volume"
              />
              <span className="w-16 pl-1">
                <Slider
                  handleStyle={{ backgroundColor: COLOR_CONSTANTS['GREEN'] }}
                  trackStyle={{ backgroundColor: COLOR_CONSTANTS['GREEN'] }}
                  value={mute ? 0 : volume * 100}
                  onChange={handleVolumeChange}
                />
              </span>
            </div>
          )}
          <div className="flex items-center cursor-pointer">
            <LDLabel>{elapsedTime}</LDLabel>
            <LDSpan className="px-[2px]">/</LDSpan> <LDLabel>{totalDuration}</LDLabel>
          </div>
        </div>
        {/* left side */}
        <div className="flex items-center">
          <div className="p-2 cursor-pointer">
            <PlayerActionPopUp
              options={playbackRateOptions}
              handlerFn={handlePlaybackRateChange}
              active={playbackRate}
              settingsRef={settingsRef}
              action="speed"
              actionVisible={activeActionId === 2}
              toggleActionVisibility={() => {
                handleActionToggle(2);
              }}
              qualityAllowed={qualityAllowed}
              episodeTitle={episodeTitle}
              courseTitle={courseTitle}
            />
          </div>
          {!isMobile && qualityAllowed && (
            <div className="p-2 cursor-pointer">
              <PlayerActionPopUp
                options={videoQualityOptions}
                handlerFn={handleQualityChange}
                active={quality}
                settingsRef={settingsRef}
                action="quality"
                actionVisible={activeActionId === 1}
                toggleActionVisibility={() => {
                  handleActionToggle(1);
                }}
                qualityAllowed={qualityAllowed}
                episodeTitle={episodeTitle}
                courseTitle={courseTitle}
              />
            </div>
          )}
          {/* <div className="p-2 cursor-pointer">
              <img
                src={imagePath("/VideoControls/setting.svg")}
                alt="backward30"
              />
            </div> */}
          {/* <div className="p-2 cursor-pointer">
              <img src={imagePath("/VideoControls/badge-fill.svg")} alt="badge" />
            </div> */}
          {!isIntro && (
            <div className="p-2 cursor-pointer" onClick={toggleFullScreen}>
              <img
                src={imagePath(`${!isfullScreen ? '/VideoControls/full-screen.svg' : '/VideoControls/fullscreen-exit.svg'}`)}
                alt="badge"
                className="h-4"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomControls;
