import LDImage from "stories/Image";
import LinkTag from "stories/LinkTag";
import { LDParagraph, LDSpan ,Title} from "stories/Typography";
import { getRoundedVal } from "utils";
import { imagePath } from "utils/assetHelper";
import { ROUTE_CONSTANTS } from 'constants/routes.constants';
import "./index.css"
const Redeem = ({ referCode, discount="" ,desc,isPercentage, currencySymbol=""}) => {
  return (
    <div className="my-6 lg:my-0">
      <div className="flex justify-between bg-referColor lg:p-3 p-2 drop-shadow-lg rounded-md">
        <div className="">
          <Title className="font-size-title">
            Follow the steps to redeem this <br/>coupon!
          </Title>
          <div className="lg:my-4 my-3">
          <LDSpan className="refer-border-style rounded-sm px-8 py-[7px]">
            {referCode}
          </LDSpan>
          </div>

          <div className="flex text-white text-md items-center my-2">
              <LDSpan className="mr-1 font-style-normal">Get up to</LDSpan>
              {" "}{ isPercentage ? null : <LDSpan className="mr-1 font-style-normal">{currencySymbol}</LDSpan> }              
              <LDSpan className="font-style-normal">{`${discount && getRoundedVal(discount)} ${isPercentage?"%":""}`} off</LDSpan>
          </div>
        </div>
        <LDImage src={imagePath("/ReferFriend/referLfd.svg")} alt="referImg" />
      </div>
      <LDParagraph className="text-justify text-grayColor mt-6 hidden lg:block referal-desc-style">
        {desc}
         <LDSpan className="text-greeny">
        {/* <LinkTag route={ROUTE_CONSTANTS.TERMS_AND_CONDITIONS} >  */}
            {/* Terms and Conditions{" "}         */}
         {/* </LinkTag> */}
        </LDSpan>
       
        <LDSpan className="text-greeny">
           {/* <LinkTag route={ROUTE_CONSTANTS.PRIVACY} > privacy policy </LinkTag> */}
        </LDSpan>{" "}
      </LDParagraph>
    </div>
  );
};

export default Redeem;
