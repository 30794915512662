import { Helmet as ReactHelmet } from "react-helmet";

export const Helmet = (props) => {
  const { show = true, title } = props;
  const titleShow = title?.[0]?.toUpperCase() + title?.slice(1);
  return (
    <ReactHelmet>
      <meta charSet="utf-8" />
      <title>
        {titleShow}
        {show ? "-Lifedemy" : ""}
      </title>
    </ReactHelmet>
  );
};
