import CardImage from 'components/Card/CardImage'
import React from 'react'
import StarRating from 'stories/StarRating'
import { Heading } from 'stories/Typography'
import { imagePath } from 'utils/assetHelper'
import './style.css'
import Textarea from 'stories/Textarea'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Notifier } from 'components/Notifier'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'hooks/useQuery'
import { ROUTE_CONSTANTS } from 'constants/routes.constants'


const BookingButton = ({ children, className, onClick }) => {
    return (
        <button className={className} onClick={onClick}>{children}</button>
    )
}

const AddReview = () => {
    const history = useHistory()
    const query = useQuery();
    const profileInfo = useSelector((state) => state?.profile?.profileDetails); // to check first name last name are present or not  
    const routeAddReview = query.get("_r")
    const _token = query.get("_token");
    const instructorId = query.get("_i");
    const [feedBack, setFeedback] = useState(false)
    const [addRate,setAddRate] = useState({
        rating :"",
        review :""
    })
    const dispatch = useDispatch()
    const [error,setError] = useState({})

    let errorObj = {}

    const Title = ({children, className})=>{
        return(
            <h1 className={className}>{children}</h1>
        )
    }
    const addStarRating = useSelector((state) => state?.instructor?.addStarRating);
  
    const handleChange = (e) =>{
        const {name,value} = e.target
        setAddRate({...addRate,[name]:value})
    }
    
    const getRating = (rating) => {
          setAddRate({...addRate,rating})
      };

    const runValidation=()=>{
      if(addRate?.review?.length === 0 ){
        errorObj.review = "Please add your review"
      }
      if(addRate?.rating?.length === 0 ){
        errorObj.rating = "Please select star rating"
      }
    }
  
    const handleClick =()=>{
      runValidation()
      if(Object.keys(errorObj).length === 0){
        const payload = { 
            token_signature: _token ?? '',
            review:addRate?.review,
            rating:addRate?.rating
        }
        if(!Object.values(payload).includes("")){
            dispatch.instructor.addFeedback(payload, (status ,response)=>{
                if(status){
                    Notifier(201, response?.message)
                    setTimeout(()=>{
                        history.push(`?_r=${true}&_i=${response?.data?.instructor_id}`)
                    },800)
                }else{
                    Notifier("101","something went wrong!")
                }
            })  
            setAddRate({
                review: "",
                rating: ""
            }) 
            setFeedback(true) 
        }
        else{
            Notifier(303, "Something went wrong")
        }
      }else{
        setError(errorObj)
        setTimeout(()=>{
          setError({})
        },2000)
      }
    }

    return (
        <>
           {!routeAddReview ? (
            <div className='rating--section pr-[2rem] pl-[2rem] mt-[3rem] min-h-screen'>
            <div className='heading--content text-center mb-[3rem]'>
                <CardImage
                    src={imagePath("/confirm.svg")}
                    style={{
                        width: "55px",
                        height: "55px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        objectFit: "cover",
                    }}
                    className="cursor-pointer m-auto"
                />
                <h1 className="text-[16px] md:text-[33px] font-normal text-greeny md:text-white mt-3">
                    Session Completed
                </h1>
            </div>
            <div className='review--block flex-wrap items-center max-w-[830px] m-auto'>
                <div className='add--review basis-3/5 pr-[3rem]'>
                    <Heading className=" text-lg mb-[1rem]">
                    How would you like to rate the session ?
                    </Heading>
                    <div className="star--rating mb-[1.5rem]">
                        <StarRating
                            isStarRatingActive={true}
                            disabled={true}
                            size={`12`}
                            getRating={getRating}
                            rating={Number(addRate?.rating)}
                            // name="rating"
                            // value={addRate?.rating}
                            className="star"
                            style={{ width: "40px", height: "40px" }}
                        />
                    </div>
                    {error?.rating && <div className="mt-2 mb-2 text-remove" > {error.rating}</div>}
                    <Textarea
                        value={addRate?.review}
                        name="review"
                        onChange={handleChange}
                        rows="6"
                        placeholder={"Add a comment"}
                        className="md:mx-[5rem] w-[80%] md:w-[48%] bg-secondary text-white outline-none rounded-lg p-2 resize-none"
                    />
                     {error?.review && <div className="mt-2 text-remove" > {error.review}</div>}
                    <BookingButton
                        onClick={handleClick}
                        className="bg-greeny px-[1rem] w-[200px] py-[0] border-0 mt-[2rem] hover:text-white hover:outline-none text-white h-[3rem] rounded-lg text-[16px] font-bold"
                    >
                        Submit
                    </BookingButton>
                </div>
            </div>
        </div>
           ) :(
            <>
            <div className='min-h-screen flex flex-col justify-center items-center mx-4'>
            <CardImage
                    src={imagePath("/confirm.svg")}
                    style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        objectFit: "cover",
                    }}
                    className="cursor-pointer m-auto"
                />
            <Title className="text-[20px] md:text-[33px] mt-[2rem] text-white font-semibold">
            Thank you for your feedback!
                </Title>
                    <div className='flex flex-col mt-[1.5rem] md:mt-[2rem] gap-[1.5rem]'>
                    <BookingButton
                        onClick={() => history.push(`${ROUTE_CONSTANTS?.INSTRUCTORINFORMATION}/${instructorId}`)}
                        className="bg-greeny px-[1rem] w-[245px] md:w-[400px] py-[0] border-0 hover:text-white hover:outline-none text-white h-[3rem] rounded-lg text-[16px] font-bold"
                    >
                        Book another Session
                    </BookingButton>
                    <BookingButton
                        onClick={() => history.push(ROUTE_CONSTANTS?.HOME)}
                        className="bg-transparent px-[1rem] md:w-[400px] py-[0] border-2  text-white h-[3rem] rounded-lg text-[16px] font-bold"
                    >
                        Home Page
                    </BookingButton>
                    </div>
                </div>
             
            </>
           )
            }
        
        </>

    )
}

export default AddReview
