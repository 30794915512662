import { Fragment } from "react";
import ProfileLoader from "stories/SkeletonLoader/ProfileLoader";

const MultipleProfileLoader = ({count}) => {
  return <Fragment>{[...Array(count)]?.map((item, key) => {
      return <ProfileLoader key={key} />
  })}</Fragment>;
};

MultipleProfileLoader.defaultProps = {
    count: 1
}

export default MultipleProfileLoader;
