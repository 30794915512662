import React,{useEffect} from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FormButton from "stories/FormButton";
import LDImage from "stories/Image";
import { Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { Notifier } from "components/Notifier";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import "./index.css"
import { COLOR_CONSTANTS } from 'constants/color.constants';
import { ROUTE_CONSTANTS } from "constants/routes.constants";

const EmailVerifyResponse = () => {
  const history = useHistory();
  const params = useParams();


  const hanldeRedirect = () => {
    history.push(ROUTE_CONSTANTS?.HOME);
  };
  return (
    <div className="container mx-auto min-h-screen">
      {params?.msg === "success" ? (
        <div className="text-center">
          <LDImage
            src={imagePath("/Payment/Paymentsuccess.svg")}
            alt="paymentSuccess"
            className="m-auto block mt-12 h-[8vh]"
          />
          <Title className="text-white mt-[1rem] email-success-message">
            Thank you, your email has been verified!
          </Title>
          <FormButton
            className="text-greeny hover:text-greeny bg-white px-12 mt-8"
            onClick={hanldeRedirect}
            backgroundColor={COLOR_CONSTANTS.WHITE}
            color={COLOR_CONSTANTS.GREEN}
          >
            Explore more courses
          </FormButton>
        </div>
      ) : (
        <div className="text-center">
          <LDImage
            src={imagePath("/Payment/paymenterror.svg")}
            alt="paymentSuccess"
            className="m-auto block mt-12 h-[8vh]"
          />
          {/* <Title className="text-white mt-[1rem] email-success-message text-bold">Email verification failed</Title> */}
          <Title className="text-white mt-[1rem]  email-success-message">Either the link is expired or is invalid.</Title>
          <FormButton
            className="text-greeny hover:text-greeny bg-white px-24 mt-8"
            onClick={() => history.push("/profile")}
            backgroundColor={COLOR_CONSTANTS.WHITE}
            color={COLOR_CONSTANTS.GREEN}
          >
            Try again
          </FormButton>
        </div>
      )}
    </div>
  );
};

export default EmailVerifyResponse;
