import * as eventConstants from "./events.constants";
import settingsEvents from "./SettingsEvents";

const handleEvent = (eventCode, extraParams = {}) => {
  settingsEvents.handle_analytics_event(eventCode, extraParams);
};

export const settingsPushNotificationClickEvent = (extraParams) => {
  const eventCode = eventConstants?.SETTINGS_PUSH_NOTIFICATION_CLICK;
  handleEvent(eventCode, extraParams);
};

export const settingsVideoQualityClickEvent = (extraParams) => {
  const eventCode = eventConstants?.SETTINGS_VIDEO_QUALITY_CLICK;
  handleEvent(eventCode, extraParams);
};

export const settingsLanguagePreferencesClickEvent = (extraParams) => {
  const eventCode = eventConstants?.SETTINGS_LANGUAGE_PREFERENCES_CLICK;
  handleEvent(eventCode, extraParams);
};

export const settingsChildModeClickEvent = (extraParams) => {
  const eventCode = eventConstants?.SETTINGS_CHILD_MODE_CLICK;
  handleEvent(eventCode, extraParams);
};

export const settingBackClickEvent = (extraParams) => {
  const eventCode = eventConstants?.SETTINGS_BACK_CLICK;
  handleEvent(eventCode, extraParams);
};

export const settingLanguagePreferenceOptionClickEvent = (extraParams) => {
  const eventCode = eventConstants?.SETTINGS_LANGUAGE_PREFERENCES_OPTION_CLICK;
  handleEvent(eventCode, extraParams);
};
export const settingLanguagePreferenceApplyClickEvent = (extraParams) => {
  const eventCode = eventConstants?.SETTINGS_LANGUAGE_PREFERENCES_APPLY_CLICK;
  handleEvent(eventCode, extraParams);
};

export const settingVideoQualityOptionClick = (extraParams ) => {
  const eventCode = eventConstants?.SETTINGS_VIDEO_QUALITY_OPTION_CLICK;
  handleEvent(eventCode, extraParams);
};
