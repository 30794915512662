import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeObservable from "utils/observable";

const initPlayerState = {
  playing: false,
  muted: false,
  controlsAllowed: false,
  volume: 0.5,
  playbackRate: 1,
  playerInitialised: false,
  quality: -1,
  qualityAllowed: false
}

const videoPlayerLocalStore = makeObservable(initPlayerState);

function useVideoPlayerState() {

  const playerStorageData = useSelector((state) => state?.storageData?.playerData);

  const [playerState, setPlayerState] = useState({ ...videoPlayerLocalStore.get(), ...playerStorageData });

  const dispatch = useDispatch();
  
  useEffect(() => {
    return videoPlayerLocalStore.subscribe(setPlayerState);
  }, []);

  const updatePlayerState = (payload, clear = false,persist=false) => {
    if(clear){
      setPlayerState(videoPlayerLocalStore.get());
      videoPlayerLocalStore.set(initPlayerState);
      return;
    }
    videoPlayerLocalStore.set({ ...playerState, ...payload });
    if(persist){
      dispatch?.storageData?.updatePlayerData(payload)
    }
  };

  const actions = useMemo(() => {
    return {
      playVideo: payload => {
        if (!playerInitialised) {
          payload = {
            ...payload,
            controlsAllowed: true,
            playerInitialised: true,
          };
        }
        updatePlayerState({ playing: true, ...payload });
      },
      pauseVideo: payload => {
        updatePlayerState({ playing: false, ...payload });
      },
      setDuration: duration => {
        updatePlayerState({ duration: duration });
      },
      setVolume: volume => {
        updatePlayerState({ volume: volume },false,true);
      },
      setMute: mute => {
        updatePlayerState({ mute: mute },false,true);
      },
      setPlaybackRate: rate => {
        updatePlayerState({ playbackRate: rate },false,true);
      },
      clearPlayerState: () => {
        updatePlayerState(null, true);
      },
      setQuality: quality => {
        updatePlayerState({ quality: quality },false,true);
      },
      setPlayerData: (payload) => {
        updatePlayerState(payload)
      }
    };
  }, [playerState]);

  const { playing, controlsAllowed, duration, volume, mute, playbackRate, playerInitialised, quality, qualityAllowed } =
    playerState;

  const {
    playVideo,
    pauseVideo,
    setDuration,
    setVolume,
    setMute,
    setPlaybackRate,
    clearPlayerState,
    setQuality,
    setPlayerData
  } = actions;

  return {
    playing,
    playVideo,
    pauseVideo,
    controlsAllowed,
    duration,
    setDuration,
    volume,
    setVolume,
    mute,
    setMute,
    playbackRate,
    setPlaybackRate,
    playerInitialised,
    clearPlayerState,
    quality,
    setQuality,
    qualityAllowed,
    setPlayerData
  };
}

export default useVideoPlayerState;
