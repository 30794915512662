import { LDAnalytics } from "utils/analyticsHelper.js";

class SearchEvents extends LDAnalytics {
  event_Search_Result() {
    return {
      event: {
        module_name: "Search",
        feature_name: "Search",
        event_name: "Search_Result",
        event_label: "",
      },
    };
  }
  event_Search_Course_Card_Click() {
    return {
      event: {
        module_name: "Search",
        feature_name: "Search",
        event_name: "Search_Course_Card_Click",
        event_label: "Search Course Card Click",
      },
    };
  }
}

const searchEvents = new SearchEvents();
export default searchEvents;
