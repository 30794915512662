import { Menu, Dropdown, Popover, Switch } from "antd";

import LinkTag from "stories/LinkTag";
import CardImage from "components/Card/CardImage";
import Search from "components/Search";

import { imagePath } from "utils/assetHelper";
import { useHistory } from "react-router-dom";
import Notification from "pages/Notification";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import "./index.css";
import LDImage from "stories/Image";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "utils/token.helper";
import { getInitial } from "utils";
import { LDParagraph, LDSpan } from "stories/Typography";
import { isEmpty } from "ramda";
import { navbarLogoClickEvent, navbarNotificationIconClickEvent, navbarProfilePicClickEvent, navbarProfilePicLogoutOptionClickEvent, navbarProfilePicProfileOptionClickEvent } from "analytics/navbar";
import { settingsChildModeClickEvent } from "analytics/settings";
import { getAccountDetails } from "selectors";
import { useEffect, useRef, useState } from "react";
import "./index.css"
import useMedia from "hooks/deviceHelper/useMedia";
import LDTooltip from "stories/LDTooltip";
const menu = (handleLogout, setNavActionActive) => (
  <Menu className="bg-ternary mr-3">
    <Menu.Item className="text-white hover:bg-ternary font-modellicaBold mr-10">
      <LinkTag route={`/${ROUTE_CONSTANTS.PROFILE}`} onClick={()=>{navbarProfilePicProfileOptionClickEvent(), setNavActionActive(false)}}>
        <LDParagraph className="flex items-center justify-between ">
          <img src={imagePath("/Group.svg")} className="mr-7"/>
          <LDSpan className=" text-white profile-menu-style py-[4px]">
          Profile
          </LDSpan>
        </LDParagraph>
      </LinkTag>
    </Menu.Item>
    <hr className="opacity-10"/>
    <Menu.Item className="text-white hover:bg-ternary font-modellicaBold">
      <LDParagraph onClick={handleLogout}>
        <LDSpan className="flex items-center">
          <img src={imagePath("/Profile/logout.svg")} className="mr-7"/>
          <LDSpan className=" text-white profile-menu-style py-[4px]">
            Log Out
          </LDSpan>
        </LDSpan>
      </LDParagraph>
    </Menu.Item>
  </Menu>
);

const Navbar = ({ toggleSidebar, sidebarActive, toggleHideSidebar, hideSidebar ,...props}) => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [navActionActive, setNavActionActive] = useState(false);
  const [childModalActive, setChildModalActive] = useState(false);
  const notificationsInfo = useSelector((state)=> state?.notification?.paginationMeta?.pagination)
  const list = useSelector((state)=> state?.notification?.notiticationList)
  const notificationRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch()
  const { jwt } = getAccountDetails()

  useEffect(()=>{
    const handleOutsideClick = (event) => {
      if(notificationVisible && !(notificationRef?.current?.contains(event.target))){
        setNotificationVisible(false);
      }
    }
    if(notificationVisible){
      window?.addEventListener('click', handleOutsideClick);
    }
    return () => {
      window?.removeEventListener('click',handleOutsideClick);
    }
  },[notificationVisible, notificationRef])

  const isSmallScreenBanner = useMedia("(max-width: 600px)")

  const notificationList = useSelector((state)=> state?.notification?.notiticationList)

  const profile_details = useSelector((state) => state?.profile?.profileDetails);
  const childAuthModalActive = useSelector((state) => state?.profile?.childAuthModalActive)
  const {
    firstName,
    lastName,
    displayPicture,
    readNotification,
    childMode
  } = profile_details
  const fName = firstName && firstName !== null && firstName.length !== 0 ? firstName : " "
  const lName = lastName && lastName !== null && lastName.length !== 0 ? lastName: " "
  let fullName = "";
  if(fName.length){
    fullName = fName
  }
  if(lName.length){
    fullName += " " + lName
  }
  const handleSearchMobile = () => {
    history.push("/search");
  };

  const handleChildModeMobile = () => {
    history.push("/settings");
  };
  
  const handleNotificationRoute =()=>{
    history.push('/notification')
  }
  const handleLogout = () => {
    navbarProfilePicLogoutOptionClickEvent()
    dispatch?.authModel?.toggleLogoutModal(true)
    setNavActionActive(false);
  };
  const openSidebar = () => {
    if (hideSidebar) {
      toggleHideSidebar();
    } else {
      toggleSidebar();
    }
  };
  const handleUpdateNotification=(currentLength)=>{
    if(notificationsInfo?.total > readNotification){
      const body={
        readNotification: notificationsInfo?.total 
      }
      const cb=()=>{
      }
      dispatch.profile?.requestUpdateProfile(body,cb)
    }
  }
  const handleChildMode = (mode) => {
    setChildModalActive(mode);
    const body = {
      childMode: mode,
    };
    if (mode) {
      dispatch?.profile?.updateChildMode(body);
    } else {
      dispatch?.profile?.setChildModalStatus(true);
    }
  };

  useEffect(() => {
    setChildModalActive(childMode);
  }, [childMode, childAuthModalActive]);
 
  return (
    <header className={`flex items-center justify-between pt-5 pb-4 p-[4px]  bg-secondary sticky top-0 z-50 ${sidebarActive ? " pl-3  " : "lg:pl-4 2xl:pl-8"} `}>
      {/* Logo */}
      <div className={`items-center justify-between hidden lg:flex lg:items-center ${sidebarActive ? " pl-4 " : "pl-4"} `}>
        <LDImage
          src={imagePath("/Hamburger.svg")}
          alt="hamburger"
          onClick={openSidebar}
          className="cursor-pointer h-4"
        />
        <LinkTag className={`cursor-pointer ${sidebarActive ? " pl-4 ": "pl-16"}`} to={ROUTE_CONSTANTS?.HOME} onClick={()=> navbarLogoClickEvent() }>
          <LDImage src={imagePath("/Logo.svg")} alt="logo" className="h-7" />
        </LinkTag>
      </div>

      {/* Mobile screen Hamburger */}
      <div className="flex items-center justify-between lg:hidden pl-1">
        <LDImage
          src={imagePath("/Hamburger.svg")}
          alt="hamburger"
          onClick={toggleSidebar}
          className="cursor-pointer"
        />
      </div>
      {/* Mobile screen Logo */}
      <div className="lg:hidden">
        <LinkTag to={ROUTE_CONSTANTS?.HOME}>
          <LDImage src={imagePath("/Logo.svg")} className="block ml-[15px] h-8" alt="logo" />
        </LinkTag>
      </div>

      {/* Mobile screen Search */}
        <div className="flex items-center ">
          {/* Mobile screen Parental Control */}
          <div className="flex items-center lg:hidden pr-3 h-9 w-10">
            <LDImage 
              src={ childModalActive ? imagePath("/setting/shield-lock-green.svg"): imagePath("/setting/shield-lock.svg")} 
              alt="parental control"
              className="cursor-pointer childmode-mobile-tour-cls"
              onClick={handleChildModeMobile}
            />
          </div>
          <div className="flex lg:hidden pr-3 h-9 w-9">
            <LDImage
              src={imagePath("/searchIcon.svg")}
              alt="search img"
              onClick={handleSearchMobile}
              className=" cursor-pointer h-full w-full "
            />
          </div>
          <div className="lg:hidden h-9 w-10 last:mr-[-7px]"  onClick={handleNotificationRoute}>
                { notificationsInfo?.total > readNotification || list?.length > readNotification   ? 
                <LDImage
                  src={imagePath("/Home/notificationWithRed.svg")}
                  alt="notification "
                  className=" cursor-pointer"
                  onClick={()=>
                    handleUpdateNotification(list?.length)
                    }
                />
                :
                <LDImage
                  src={imagePath("/Home/notification.svg")}
                  alt="notification "
                  className=" cursor-pointer"
                />
               }
          </div>
      </div>

      
      {/* End Mobile screen design */}

      {/*Search*/}
        <div className={`hidden lg:block w-[65%] lg:pr-16 md:mb-0`}>
          <Search iconStyle={"left-3 top-2"} />
        </div>

      {/*Child Mode Toggle Switch */}
      <div className="hidden lg:block ml-8">
        <LDTooltip title={childMode ? "Deactivate child mode" : "Activate child mode"}>
          <Switch
            checked={childModalActive}
            onClick={()=>settingsChildModeClickEvent({event_label : childModalActive ? "Child Mode Deactivated" : "Child Mode Activated"})}
            onChange={(e) => { handleChildMode(e) }}
            className={`${childModalActive ? "bg-greeny" : "bg-red-400"} childmode-web-tour-cls`} 
            checkedChildren="18+"
            unCheckedChildren="U"
          />
        </LDTooltip>
      </div>

      {/*right nav bar */}
      { jwt !== undefined && jwt?.length > 0 ? (
        <div className="lg:grid grid-cols-2 gap-x-4 pr-8 hidden items-center">
          <Popover
            ref={notificationRef}
            overlayClassName="abc"
            placement="bottom"
            content={<Notification notificationVisible={notificationVisible} setNotificationVisible={setNotificationVisible} />}
            overlayStyle={{ minWidth: "500px", position:"fixed"}}
            color={COLOR_CONSTANTS.SIDEBAR_SELECTED }
            trigger="click"
            onClick={()=> {
              navbarNotificationIconClickEvent({event_label:notificationList?.length})
            }}
            onVisibleChange={(newVisible)=> setNotificationVisible(newVisible)}
            visible={notificationVisible}
          >
            { notificationsInfo?.total > readNotification || list?.length > readNotification   ? 
                <LDImage
                  src={imagePath("/Home/notificationWithRed.svg")}
                  alt="notification "
                  className=" cursor-pointer"
                  onClick={()=>{
                    handleUpdateNotification(list?.length)
                    setNotificationVisible(!notificationVisible)}}
                />
                :
                <LDImage
                  src={imagePath("/Home/notification.svg")}
                  alt="notification "
                  className=" cursor-pointer"
                  onClick={()=>{
                    setNotificationVisible(!notificationVisible)}}
                />
             }
          </Popover>
          <Dropdown visible={navActionActive} 
             overlay={menu(handleLogout, setNavActionActive)} trigger={["click"]}
             overlayStyle={{  minWidth: "45px",position: 'fixed'}} 
             onVisibleChange={(prevVal)=>setNavActionActive(prevVal)}
             onClick={()=>{
              navbarProfilePicClickEvent()
            }} 
            placement="bottomRight">
            { displayPicture && !isEmpty(displayPicture) ?
                <div onClick={()=>{setNavActionActive(!navActionActive)}} {...props}>
                  <CardImage 
                    src={displayPicture}
                    avatar={true}
                    className="cursor-pointer rounded-full h-10 w-10 object-contain"
                    cache={false}
                  />
                </div>
               :
              <div onClick={()=>{setNavActionActive(!navActionActive)}} {...props}>
                <div className="w-10 h-10 md:h-8 md:w-8 2xl:w-14 2xl:h-14  rounded-full flex justify-center items-center bg-white cursor-pointer">
                  <LDSpan className="text-black "> {fullName?.trim()?.length > 0 && getInitial(fullName)}</LDSpan>
                </div>
              </div>
            }
          </Dropdown>
        </div>
      ) : (
        <div className="hidden mr-10 lg:block">
          <CardImage
            avatar={true}
            src={imagePath("/Home/profile.svg")}
            icon={true}
            className="cursor-pointer rounded-full h-10 w-10 object-contain"
          />
        </div>
      )}
    </header>
  );
};
export default Navbar;
