import { Title } from "stories/Typography";

const ComingSoon = () => {
  return (
    <div className='min-h-screen relative'>
       <Title className="absolute top-[30%] left-[40%]">Coming Soon</Title> 
     </div>
  )
}

export default ComingSoon