import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import useVideoPlayerState from "./hooks/useVideoPlayerState";
import PlayerControls from "./PlayerControls";
import FeedbackOverlay from "./FeedbackOverlay";
import { getPlayerFormattedTime, getRoundedDuration } from "./utils";
import { VideoCompletedEvent, videoForwardBackwardEvent, videoFullScreenEvent, VideoNextEpisodeEvent, videoPauseEvent, videoPlayEvent, VideoPlayFirstEpisodeEvent, VideoReplayEvent, videoSeekEvent, watchEpisodeEvent, watchTrailerEvent } from "analytics/videoPlayer";
import { updateLastWatched } from "./actions/api";
import { LAST_WACTHED_SEEK_MIN_GAP, PLAYER_INIT_CONTROLS_HIDE_TIME, STATUS_UPDATE_INTERVAL } from "constants/app.constants";
import { useDispatch, useSelector } from "react-redux";
import VideoPlayerLoader from "stories/SkeletonLoader/VideoPlayerLoader";
import { useLocation } from "react-router-dom";
import useMedia from "hooks/deviceHelper/useMedia";
import LoadingSpinner from "stories/Loader/LoadingSpinner";
import PlayerThumbnail from "./PlayerThumbnail";
import { pause, play } from "./actions";
import { getMobileOperatingSystem } from "utils/deviceHelper";

const VideoPlayer = props => {
  let { is_episode = false, restProps = {}, url, courseTitle,episodeTitle, loading: playerLoading, thumbnail ,episodeNumber,courseId, isIntro=false  } = props;
  const [playerLocalState, setPlayerLocalState] = useState({});
  const [feedbackOverlay, setFeedbackOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [playerDuration, setPlayerDuration] = useState(0);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [isfullScreen, setIsFullScreen] = useState(false);
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const [localFullScreen, setLocalFullScreen] = useState(false)

  const { episodeId = undefined, lastWatchedLocation = 0, onNextClick = undefined, duration: videoLength } = restProps;

  const userProfileDetails = useSelector((state) => state?.profile?.profileDetails);
  const location  = useLocation();

  const dispatch = useDispatch();

  const { VIDEO_PLAYER_HEIGHT } = appConfig;
  const { pathname: activeRoute } = location;
  const {
    // playing,
    // playVideo,
    // pauseVideo,
    volume,
    mute,
    playbackRate,
    duration: lastPlayedDuration,
    // playerInitialised,
    clearPlayerState,
    quality,
    setQuality,
    setPlayerData,
    controlsAllowed
  } = useVideoPlayerState();

  const { playing, playerInitialised } = useSelector((state) => state?.videoPlayer);
 
  const playerProps = {
    width: "100%",
    height: "100%",
    controls: true,
    muted: mute,
    playing: playing,
    ...props
  };

  const playerRef = useRef();
  const playerContainerRef = useRef();

  const currentPlayer = playerRef?.current;

  const hlsPlayer = currentPlayer?.getInternalPlayer("hls");

  const lastWatchedHandlerFn = useCallback((completed=false) => {
    if( url && url.length && episodeId){
      const watchedDuration = getRoundedDuration(currentPlayer?.getCurrentTime());
      let lastWatchedObj = {
        user_id: userProfileDetails?.id || null,
        episode_id: episodeId,
        last_known_timestamp: watchedDuration,
      };
      if(completed){
        lastWatchedObj["last_known_timestamp"] = videoLength  || currentPlayer?.getDuration();
        lastWatchedObj["completed"] = true;
      }
      updateLastWatched(lastWatchedObj);
    }
  }, [currentPlayer, episodeId, userProfileDetails]);

  const handlePlayerSeeking = seeking => {
    if (playerLocalState && playerLocalState?.seeking !== seeking) {
      setPlayerLocalState({
        ...playerLocalState,
        seeking: seeking,
      });
    }
  };

  const handlePlayPauseEvent = (is_pause=false) => {
    const event_params={
      course_name:courseTitle,
      episode_name:episodeTitle
    }
    is_pause ? videoPauseEvent(event_params) : videoPlayEvent(event_params);
  }

  const handlePlayPause = (params = {}, source="") => {
    if(!playerInitialised){
      params =  { playerInitialised: true }; 
    }
    if(playing){
      pause();
      handlePlayPauseEvent(true);
    }else{
      play(params);
      if(source === "thumb"){
        is_episode ? watchEpisodeEvent(params) : watchTrailerEvent(params);
      }else{
        handlePlayPauseEvent();
      }
    }
    if(is_episode){
      lastWatchedHandlerFn();
    }
  };

  const handleExternalPlayPause = useCallback((is_pause=false) => {
    if(is_pause){
      pause();
      handlePlayPauseEvent(true);
    }else{
      play();
      handlePlayPauseEvent();
    }
  },[play, pause])
  
  const handleMuted = () => {};

  const handleProgress = changeState => {
    const { played } = changeState;
    if (!playerLocalState?.seeking) {
      setPlayerDuration(played);
    }
  };

  const onSeekMouseUp = value => {
    const newDuration = value / 100;
    handlePlayerSeeking(false);
    currentPlayer?.seekTo(newDuration);
    const event_param = { event_label: `${lastPlayedDuration} | ${newDuration}`,course_name:courseTitle,episode_name:episodeTitle };
    if(is_episode){
      lastWatchedHandlerFn();
    }
    videoSeekEvent(event_param);
  };

  useEffect(()=>{
    const handleFullScreenChange = () => {
      setIsFullScreen(prev => !prev)
    }
    document.addEventListener('fullscreenchange', handleFullScreenChange);
  },[])

  const toggleFullScreen = () => {
      if(isIntro){
        return; //fullscreen not allowed for intro videos
      }
      if(getMobileOperatingSystem() === "iOS"){
        setLocalFullScreen(!localFullScreen)
        setIsFullScreen(!isfullScreen)
      }else{
        screenfull?.toggle(playerContainerRef?.current);
      }
      const event_params= {
        course_name:courseTitle,
        episode_name:episodeTitle
      }
      videoFullScreenEvent(event_params);
  };

  const handleForwardSeconds = (secs = 10) => {
    const event_params = { event_label: `+${secs}`,course_name: courseTitle ,episode_name:episodeTitle};
    videoForwardBackwardEvent(event_params);
    currentPlayer?.seekTo(currentPlayer?.getCurrentTime() + secs);
  };

  const handleFastForward = () => {
    currentPlayer?.seekTo(currentPlayer?.getCurrentTime() + 10);
  };

  const handleBackwardSeconds = (secs = 10) => {
    const event_params = { event_label: `-${secs}`,course_name: courseTitle ,episode_name:episodeTitle };
    videoForwardBackwardEvent(event_params);
    currentPlayer?.seekTo(currentPlayer?.getCurrentTime() - secs);
  };
  const handleFastBackward = () => {
    currentPlayer?.seekTo(currentPlayer?.getCurrentTime() - 10);
  };

  const handleVidoEnded = () => {
    pause();
    const event_params={
      course_name:courseTitle,
      episode_name:episodeTitle,
      event_label: is_episode ? "Episode- Video Completed " :"Course - Video Completed"
    }
    VideoCompletedEvent(event_params)
    setFeedbackOverlay(true);
    if(is_episode){
      lastWatchedHandlerFn(true);
    }
    if(buffering) setBuffering(false)
  };

  const currentTime = currentPlayer
    ? currentPlayer?.getCurrentTime()
    : "00:00";

  const elapsedTime = getPlayerFormattedTime(currentTime);
  const totalDuration = getPlayerFormattedTime(videoLength || currentPlayer?.getDuration());

  useEffect(()=>{
    setPlayerDuration(0);
    setVideoStarted(false);
    if(!is_episode || isMobile){
      setBuffering(false);
      clearPlayerState();
      setVideoStarted(false);
    } else if(is_episode){
      if(playerInitialised && controlsAllowed){
        setBuffering(true);
        setControlsVisible(true);
      }
    }
    if(playing){
      handlePlayPauseEvent();
    }
  },[url])

  useEffect(()=>{
    setLoading(playerLoading)
  },[playerLoading])

  useEffect(() => {
    if(is_episode && episodeId && videoStarted){
      const statusTimer = setInterval(() => {
        if(playing){
          lastWatchedHandlerFn();
        }
      }, STATUS_UPDATE_INTERVAL);
      return () => {
        clearInterval(statusTimer);
      };
    }
  }, [is_episode, episodeId, playing, videoStarted]);
  useEffect(() => {
    if(lastWatchedLocation){
      const player = currentPlayer;
      const seekToLastPlayedAllowed = videoLength - lastWatchedLocation > LAST_WACTHED_SEEK_MIN_GAP;
      if(player && seekToLastPlayedAllowed && episodeId && playerInitialised){
        player?.seekTo(lastWatchedLocation, 'seconds');
      }
    }
  },[episodeId, currentPlayer, playerInitialised, lastWatchedLocation]);

  useEffect(()=>{
    if(feedbackOverlay){
      setFeedbackOverlay(false);
    }
    if(hlsPlayer){
      setPlayerData({qualityAllowed: hlsPlayer})
    }
  },[url]);

  useEffect(()=>{
    if(hlsPlayer){
      setPlayerData({qualityAllowed: true})
    }
  },[hlsPlayer]);

  const handleNext = () => {
    if(is_episode){
      const event_params ={
        course_name:courseTitle,
        episode_name:episodeTitle
      }
      VideoNextEpisodeEvent(event_params)
    }
    else{
      const event_params ={
         course_name:courseTitle,
         event_label: "Play First Episode" 
      }
      VideoPlayFirstEpisodeEvent(event_params) 
    }
    onNextClick && onNextClick();
    setFeedbackOverlay(false);
    play();
  }

  const handleReplay = () => {
    if(is_episode){
      const event_params ={
        course_name:courseTitle,
        episode_name:episodeTitle
      }
      VideoReplayEvent(event_params)
    }else{      
      const event_params ={
        course_name:courseTitle,
      }
      VideoReplayEvent(event_params)
    }
    currentPlayer?.seekTo(0, 'seconds');
    play();
    setFeedbackOverlay(false);
  }
  const playerVisibleCls = loading ? "hidden" : "";
  const isMobile = useMedia();
  const containerHeightCls = isMobile ? "min-h-[23vh]" : VIDEO_PLAYER_HEIGHT;

  const handlePlayerError = (error) => {
    console.log('---Player err: ',JSON.stringify(error, ["message", "arguments", "type", "name"]));
    if(is_episode){
      // add episode video err here
      // dispatch?.episodes?.setErr({episodeDescError:true})
    }else{
      // commenting it due to play blocked by browser
      // dispatch?.course?.setErr({courseDescError:true});
    }
  }
  const handleQualityChange = (qualityIdx) => {
    setQuality(qualityIdx);
    if (currentPlayer) {
        if (hlsPlayer) {
            hlsPlayer.currentLevel = qualityIdx;
        }
    }
  }

  const handleOnStart = () => {
    setTimeout(()=>{
      setBuffering(false);
      setVideoStarted(true);
      setControlsVisible(false);
    },PLAYER_INIT_CONTROLS_HIDE_TIME)
  }

  const playerConfig = {
    file: {
      // forceHLS: true,
      // forceVideo: true,
      hlsOptions: {
        // autoStartLoad: false,
        currentLevel: quality,
        startLevel: -1,
        maxBufferSize: 10
      }
      // attributes: { poster: playerInitialised ? "" : thumbnail }
    },
  };
  const videoTitle = isfullScreen ? is_episode ? `EPISODE  ${episodeNumber || ''} - ${episodeTitle || ''} ` : `${courseTitle || 'Lifedemy'} - Trailer` : "" 
  return (
    <div className={`${containerHeightCls} bg-black ${localFullScreen && `fixed top-0 left-0 w-full  h-full z-50`} `}>
      {loading ? (
        <VideoPlayerLoader />
      ) : (
        <div
          ref={playerContainerRef}
          className={`relative h-full w-full min-h-full ${playerVisibleCls}`}
        >
          {buffering && (
            <div className="absolute top-1/2 left-1/2" style={{transform: "translate(-50%,-50%)"}}>
              <LoadingSpinner />
            </div>
          )}
          {!playerInitialised && !loading && <PlayerThumbnail thumbnail={thumbnail} action={handlePlayPause} episode={is_episode} isIntro={isIntro} />}
          <ReactPlayer
            ref={playerRef}
            progressInterval={100}
            onProgress={handleProgress}
            {...playerProps}
            controls={false}
            volume={mute ? 0 : volume}
            onEnded={handleVidoEnded}
            playbackRate={playbackRate}
            playsinline
            config={playerConfig}
            onBuffer={() => {
              setBuffering(true);
            }}
            onBufferEnd={() => {
              setBuffering(false);
            }}
            onError={handlePlayerError}
            onStart={handleOnStart}
            onPlay={()=>{ handleExternalPlayPause() }}
            onPause={()=>{ handleExternalPlayPause(true) }}
          />
          {feedbackOverlay ? (
            <FeedbackOverlay
              nextAllowed={onNextClick}
              handleNext={handleNext}
              handleReplay={handleReplay}
              feedbackAllowed={is_episode}
              nextBtnLabel={is_episode ? "Next Episode" : "Play 1st Episode"}
              episodeTitle={episodeTitle}
              courseTitle={courseTitle}
              isfullScreen={isfullScreen}
              courseId={courseId}
              episodeId={episodeId}
            />
          ) : (
            <PlayerControls
              playing={playing}
              handlePlayPause={handlePlayPause}
              hanldeMuted={handleMuted}
              handlePlayerSeeking={handlePlayerSeeking}
              onSeekMouseUp={onSeekMouseUp}
              toggleFullScreen={toggleFullScreen}
              handleForwardSeconds={handleForwardSeconds}
              handleFastForward={handleFastForward}
              handleBackwardSeconds={handleBackwardSeconds}
              handleFastBackward={handleFastBackward}
              elapsedTime={elapsedTime}
              totalDuration={totalDuration}
              handleQualityChange={handleQualityChange}
              playerDuration={playerDuration}
              setPlayerDuration={setPlayerDuration}
              courseTitle={courseTitle}
              episodeTitle={episodeTitle}
              controlsVisible={controlsVisible}
              setControlsVisible={setControlsVisible}
              isfullScreen={isfullScreen}
              videoTitle={videoTitle}
              localFullScreen={localFullScreen}
              isIntro={isIntro}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
