import { courseReviewEditClickEvent, courseReviewsShowMoreClickEvent } from "analytics/course";
import { APP_REVIEW_LIST_SIZE, LIST_LENGTH_TO_COMPARE } from "constants/app.constants";
import { Fragment, useState } from "react";
import { useDispatch ,useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FormButton from "stories/FormButton";
import LDImage from "stories/Image";
import { LDParagraph } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import Reviews from "../../Reviews";
import FormComponent from "../AddReview/FormComponent";

const ListReview = (props) => {
  const { List ,hide,paramId,handleHideAddComponent,handeRemoveAddComponent,courseTitle ,isPurchased,setList,offer_price} = props;
  const dispatch = useDispatch()
  const { pagination } = useSelector((state)=> state?.course?.pagination)
  const courseCurrentPage = useSelector((state)=> state?.course?.currentPage)
  const currentUser = useSelector((state) => state?.profile?.profileDetails);
  const [editObj, setEditObj] = useState({});
  const handleShowMore = () => {
    dispatch?.course?.requestReviewDetails(paramId,courseCurrentPage + 1);
    const params = {course_name : courseTitle}
    courseReviewsShowMoreClickEvent(params)
  };
 const handleReviewEdit=(review)=>{
  const result = List?.find((_list) => {
    return _list?.isEditable === true;
  });
  setEditObj(result);
  handleHideAddComponent()
  const params = {
    event_label : review, //${courseTitle} -
    course_name : courseTitle
  }
  courseReviewEditClickEvent(params)
 }
 const getReviewObject = (review, rating) => {
  const body = {
    review: review,
    rating:rating,
    courseId:Number(paramId)
  };
  dispatch?.course?.requestAddReviews(body);
  handeRemoveAddComponent()
};
  const handleCancel =()=>{
    handeRemoveAddComponent()
  }
return (
    <div>
      {!List.length && <LDParagraph style={{fontSize: "1rem"}}>No reviews yet.</LDParagraph> }
      {Object.keys(editObj)?.length !== 0 && hide &&(
        <FormComponent 
          open={true}
          rating={editObj?.rating}
          review={editObj?.reviewBody} 
          courseTitle={courseTitle}
          isPurchased={isPurchased}
          paramId={paramId}
          offer_price={offer_price}
          handleCancel={handleCancel}
          getReviewObject={getReviewObject} />
      )}
      {List?.map((_items, i) => {
        const { author, reviewBody, rating, createdAt, updatedAt ,isEditable} = _items;
        return (
          <Fragment key={i}>
            <Reviews
              size="15px"
              isEditable={isEditable}
              name={author}
              review={reviewBody}
              rating={rating}
              // lastReviewed={createdAt}
              lastReviewed={updatedAt}
              paramId={paramId}
              handleReviewEdit={handleReviewEdit}
            />
          </Fragment>
        );
      })}
      {List?.length >= LIST_LENGTH_TO_COMPARE  &&   //pagination?.pageSize !== courseCurrentPage
      <div className="text-center my-10">
          <FormButton
            onClick={handleShowMore}
            className=" bg-greeny text-white  hover:bg-greeny hover:text-white"
          >
            Show More
            <LDImage
              src={imagePath("/Course/downArrow.svg")}
              alt="downarrow"
              className="inline ml-2 h-2"
            />
          </FormButton>
      </div>   
     } 
    </div>
  )
}

export default ListReview