import { Button, Modal } from "antd";
import Image from "components/Image";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { imagePath } from "utils/assetHelper";


const CouponModal = (props) => {
  const { coupon, handleClose, handleProceed, show, children } = props;
  if (!show) {
    return null;
  }
  return (
    <Modal visible={show} 
    onCancel={()=>{}}
    closable={false}
     {...props}
    bodyStyle={{
        padding: "0px",
        backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
        boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.4)",
        borderRadius: "5px"
    }}
    wrapClassName="bg-[#272d3533] backdrop-blur-sm"
    width={400}
    height={800}
    maskStyle={{
        backgroundColor: COLOR_CONSTANTS.MODAL_COLOR,
        opacity: "0.8",
    }}
    footer={null}
    className="mt-[7rem]"
    >
        <div className="text-white text-center py-[2rem] px-[2rem]">
        {children}
        <Image className="w-[50px] h-[50px] mx-[auto]" src={imagePath('/invalid.svg')}/>
      <h5 className="text-red-400 text-[16px] mt-[1rem]"> Coupon <span>{coupon}</span> is invalid or has expired</h5>
      <Button onClick={handleProceed} className="bg-greeny border-[0] text-white  h-[50px] text-[16px] my-[1.5rem] hover:bg-greeny hover:text-white rounded-md">
        Continue without coupon
      </Button>
      <h6 className="text-greeny cursor-pointer underline decoration-[#0E896A] text-[16px]" onClick={handleClose}>
        Try another coupon
        </h6>
        </div>
    </Modal>
  );
};
export default CouponModal;
