export const EPISODE_LOAD_START = "Episode_Load_Start";
export const EPISODE_LOAD_END = "Episode_Load_End";

export const  EPISODE_LIKE_CLICK = "Episode_Like_Click";
export const  EPISODE_DISLIKE_CLICK = "Episode_Dislike_Click";
export const  EPISODE_SHARE_CLICK = "Episode_Share_Click";
export const  EPISODE_SHARE_OPTION_CLICK = "Episode_Share_Option_Click"

export const  EPISODE_POST_COMMENT_TEXTAREA_CLICK  = "Episode_Post_Comment_Textarea_Click";
export const  EPISODE_POST_COMMENT_CLICK = "Episode_Post_Comment_Click"
export const  EPISODE_COMMENT_REPLY_CLICK = "Episode_Comment_Reply_Click"
export const  EPISODE_COMMENT_EDIT_CLICK ="Episode_Comment_Edit_Click"
export const  EPISODE_COMMENT_DELETE_CLICK = "Episode_Comment_Delete_Click"


export const EPISODE_COMMENT_REPLY_POST_CLICK = "Episode_Comment_Reply_Post_Click";
export const EPISODE_EDIT_CANCEL_CLICK ="Episode_Edit_Cancel_Click"
export const EPSIODE_COMMENT_REPLIES_CLICK = "Episode_Comment_Replies_Click"

export const EPISODE_LIST_CARD_CLICK = "Episode_List_Card_Click";

export const  PLAY_FIRST_EPISODE  = "Play_First_Episode"
export const  PLAY_SECOND_EPISODE = "Play_Second_Episode"
export const  PAID_EPISODE_CLICK  = "Paid_Episode_Click"
export const  PURCHASED_EPISODE_CLICK  = "Purchased_Episode_Click"