import { LDAnalytics } from "utils/analyticsHelper.js";

class RefferalEvents extends LDAnalytics {
  event_Refer_A_Friend_Load_Start() {
    return {
      event: {
        module_name: "Refer A Friend",
        feature_name: "Referral",
        event_name: "Refer_A_Friend_Load_Start",
        event_label: "Refer A Friend Load",
      },
    };
  }

  event_Refer_A_Friend_Load_End() {
    return {
      event: {
        module_name: "Refer A Friend",
        feature_name: "Referral",
        event_name: "Refer_A_Friend_Load_End",
        event_label: "Refer A Friend Load",
      },
    };
  }
  event_Refer_A_Friend_Copy_Coupon_Code() {
    return {
      event: {
        module_name: "Refer A Friend",
        feature_name: "Referral",
        event_name: "Refer_A_Friend_Copy_Coupon_Code",
        event_label: "Copy Coupon",
      },
    };
  }
  event_Refer_A_Friend_Share_Click() {
    return {
      event: {
        module_name: "Refer A Friend",
        feature_name: "Referral",
        event_name: "Refer_A_Friend_Share_Click",
        event_label: "share",
      },
    };
  }
  event_Refer_A_Friend_Share_Option_Click() {
    return {
      event: {
        module_name: "Refer A Friend",
        feature_name: "Referral",
        event_name: "Refer_A_Friend_Share_Option_Click",
        event_label: "Share",
      },
    };
  }
  event_Refer_A_Friend_Input_Click() {
    return {
      event: {
        module_name: "Refer A Friend",
        feature_name: "Referral",
        event_name: "Refer_A_Friend_Input_Click",
        event_label: "Refer",
      },
    };
  }
  event_Refer_A_Friend_Button_Click() {
    return {
      event: {
        module_name: "Refer A Friend",
        feature_name: "Referral",
        event_name: "Refer_A_Friend_Button_Click",
        event_label: "Refer",
      },
    };
  }
  event_Refer_A_Friend_Country_Code_Select() {
    return {
      event: {
        module: "Refer A Friend",
        feature_name: "Referral",
        event_name: "Refer_A_Friend_Country_Code_Select",
        event_label: "Refer",
      },
    };
  }
}

const refferalEvents = new RefferalEvents();
export default refferalEvents;
