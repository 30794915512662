import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./index.css";
import useMedia from "hooks/deviceHelper/useMedia";
import { imagePath } from "utils/assetHelper";
import LDImage from "stories/Image";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <>
      <LDImage
        src={imagePath("/Banner/rightArrow.svg")}
        className={className}
        style={{
          ...style,
          display: "block",
        }}
        onClick={onClick}
        alt="leftArrow"
      />
    </>
  );
};
const PrevArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <>
      <LDImage
        src={imagePath("/Banner/leftArrow.svg")}
        alt="rightArrow"
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    </>
  );
};

const Carousel = (props) => {
  const { children, slidesToShow, arrowsEnabled=false,slidesToScroll, dots=false, ...restProps } = props;
  const isMobile = useMedia();

  const settings = {
    dots: dots,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    arrows: arrowsEnabled,
    useTransform: false,
    ...restProps
  };

  return (
    <div>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

Carousel.defaultProps = {
  children: null,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default Carousel;
