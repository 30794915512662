const model = {
  state: {
    playerData: {},
    appBanner: false 
  },
  reducers: {
    setPlayerData: (state, payload) => {
      return {
        ...state,
        playerData: payload
      };
    },
    setAppBannerData:(state, payload) =>{
      return {
        ...state,
        appBanner:payload
      }
    }
  },
  effects: dispatch => ({
    async updatePlayerData(data, undefined){
      dispatch?.storageData?.setPlayerData(data);
    },
    async updateAppBanner(data,undefined){
      dispatch?.storageData?.setAppBannerData(data)
    }
  }),
};
export default model;
