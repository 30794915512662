import Modal from "components/Modal";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { Title } from "stories/Typography";
import { useState } from "react";
import Input from "stories/Input";
import FormButton from "stories/FormButton";

const UpdatedEmail = ({ title, subTitle, btnTitle, show, setShow ,handleStartPurchase,isPurchase, isPurchaseSuccess, updateEmailClickAction, customTitle, skip }) => {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
 
  const handleClose=()=>{
     setShow()
     handleStartPurchase(email)
  };

  return (
    <Modal
      show={show}
      bodyStyle={{
        padding: "0px",
        backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
      }}
      onCancel={()=>setShow()}
      maskClosable={false}
      width={400}
      height={800}
      closable={!skip || isPurchaseSuccess ? false : true}
      maskStyle={{
        backdropFilter: "blur(3px)",
        "-webkit-backdrop-filter": "blur(3px)"
      }}
      footer={null}
    >
      <div className="flex flex-col p-14 text-center">
        {customTitle ? customTitle : ''}
        {isPurchaseSuccess && <p className="text-greeny text-xl font-semibold mb-4">Payment Success!</p>}
        {title && <Title className={ isPurchase ? "text-greeny" : "" }>{title}</Title>}
        {subTitle && <Title className={ isPurchase ? "text-greeny" : "" }>{subTitle}</Title>}
        <Input
          placeholder="Email ID"
          type="email"
          value={email}
          className="lg:bg-sidebarSelected bg-logoColor h-12 text-white text-md outline-none my-4 p-2 border border-gray-500 rounded-lg w-full mr-3"
          onChange={handleChange}
        />

        <FormButton
          backgroundColor={COLOR_CONSTANTS.WHITE}
          color={COLOR_CONSTANTS.GREEN} 
          onClick={() => updateEmailClickAction(email)}
          className=" w-full h-10 my-2 font-bold rounded-lg  bg-white lg:bg-white"
          disabled={!email || email.length === 0}
        >
          {btnTitle}
        </FormButton>
        {isPurchase && 
          <FormButton
            backgroundColor={COLOR_CONSTANTS.WHITE}
            color={COLOR_CONSTANTS.REMOVE} 
            onClick={handleClose}
            className=" w-full font-bold h-10 my-2 rounded-lg bg-white lg:bg-white"
          >
            Continue without Email
          </FormButton>
        }
      </div>
    </Modal>
  );
};

UpdatedEmail.defaultProps = {
  isPurchase : false,
  isPurchaseSuccess: false,
  title: "",
  subTitle: "",
  btnTitle: "Submit",
  setShow: ()=>{},
  skip: true
}

export default UpdatedEmail;
