import { Input } from "antd";
import LDImage from "stories/Image";
import { imagePath } from "utils/assetHelper";
import "./index.css"
export const LDInput = (props) => {
    const { className } = props
    return <Input {...props} className={`text-white  border border-formBorder bg-secondary rounded ${className}`} />;
};

LDInput.Password = Input.Password;

export const LDSelect = ({ className ,handleChange ,showDropdown,...props}) => {
    return (
      <div className="relative">
        <select
          {...props}
          onChange={e => handleChange(e.target.value)}
          className={`${showDropdown ? "show-dropdown-select" : "hide-dropdown-select" } ${className}`}
        >
          {props?.items?.map((item, index) => {
            return (
              <option
                key={index + 1}
                value={item?.value}
                className="text-white outline-none border-none"
              >
                {item?.label || item?.value}
              </option>
            );
          })}
        </select>
        {/* <div className="absolute top-[50%] right-[1rem] translate-x-[-50%] translate-y-[-50%]">
          <LDImage src={imagePath("/Course/downArrow.svg")} alt="downarrow" style={{dis}} />
        </div> */}
      </div>
    );
}
LDSelect.defaultProps ={
   showDropdown:false // this is for to show dropdown in select
}