import { LDAnalytics } from "utils/analyticsHelper.js";

class HomeEvents extends LDAnalytics {
  event_Home_Load_Start() { 
    return {
      event: {
        module_name: "Home",
        feature_name: "Home Page",
        event_name: "Home_Load_Start",
        event_label: "Home Load",
      },
    };
  }

  event_Home_Load_End() { 
    return {
      event: {
        module_name: "Home",
        feature_name: "Home Page",
        event_name: "Home_Load_End",
        event_label: "Home Load",
      },
    };
  }
  event_Home_Filter_Heading_Click(){
    return {
      event :{
        module_name :"Home",
        feature_name: "Home Filter",
        event_name: "Home_Filter_Heading_Click",
        event_label: "",
      }
    };
  }
  event_Home_Filter_Options_Click(){ 
    return {
      event :{
        module_name :"Home",
        feature_name: "Home Filter",
        event_name: "Home_Filter_Options_Click",
        event_label: "Home Filter Options Click", 
      }
    }
  }
  event_Home_Filter_Clear_All_Click(){ 
    return {
      event :{
        module_name :"Home",
        feature_name: "Home Filter",
        event_name: "Home_Filter_Clear_All_Click",
        event_label: "Filter Clear All", 
      }
    }
  }
  event_Home_Filter_Apply_Click(){
    return {
      event :{
        module_name :"Home",
        feature_name: "Home Filter",
        event_name: "Home_Filter_Apply_Click",
        event_label: "Home Filter Apply Click", 
      }
    }
  }
  event_Home_Banner_Click(){
    return {
      event :{
        module_name :"Home",
        feature_name: "Home Banner",
        event_name: "Home_Banner_Click",
      }
    }
  }
  event_Home_Continue_Watching_Card_Click(){
    return {
      event :{
        module_name :"Home",
        feature_name: "Home Continue Watching ",
        event_name: "Home_Continue_Watching_Card_Click",
      }
    }
  }
  event_Home_Watch_Now_Card_Click(){
    return {
      event :{
        module_name :"Home",
        feature_name: "Home Watch Now",
        event_name: "Home_Watch_Now_Card_Click",
      }
    }
  }
  event_Home_Watch_Now_Wishlist_Click(){
    return {
      event : {
        module_name :"Home",
        feature_name: "Home Watch Now",
        event_name: "Home_Watch_Now_Wishlist_Click",
        event_label: "",
      }
    }
  }
  event_Home_Continue_Watching_See_All_Click(){
    return {
      event : {
        module_name :"Home",
        feature_name: "Home Continue Watching",
        event_name: "Home_Continue_Watching_See_All_Click",
        event_label: "",
      }
    }
  }
  event_Home_Continue_Watching_See_Less_Click(){
    return {
      event : {
        module_name :"Home",
        feature_name: "Home Continue Watching",
        event_name: "Home_Continue_Watching_See_Less_Click",
        event_label: "",
      }
    }
  }

}

const homeEvents = new HomeEvents();
export default homeEvents;
