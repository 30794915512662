import { LDParagraph } from "stories/Typography";

const Duration = (props) => {
  const { hours, minutes, seconds, className } = props;
  return (
    <LDParagraph className={`text-white ${className}`}>
      {hours && `${hours}h`} {minutes && `${minutes}m` }{" "} {seconds && `${seconds}` }{" "}
    </LDParagraph>
  );
};

Duration.defaultProps = {
  hours: "",
  minutes: "",
};
export default Duration;
