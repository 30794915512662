import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAccountDetails } from "selectors";

export default function useAuthenticatedIpEffect(cb, dependencyArr = [], ipRestricted) {
    const { jwt } = getAccountDetails()
    const ipFetched = useSelector((state) => state?.commonParams?.ipInfo?.fetched)

    useEffect(() => {
        if (jwt && ipFetched) {
            cb()
        }
    }, [jwt, ipFetched, ...dependencyArr])
}