import * as eventConstants from "./events.constants";
import signupLoginEvents from "./SignupLoginEvents";

const handleEvent  = (eventCode, extraParams={}) => {
  signupLoginEvents.handle_analytics_event(eventCode, extraParams);
};

export const signupLoginEvent = (extraParams) => {
  const eventCode = eventConstants.SIGNUP_LOGIN_OPTION_CLICK
  handleEvent(eventCode, extraParams);
}

export const signupEmailInputClickEvent =(extraParams)=>{
    const eventCode = eventConstants?.SIGNUP_EMAIL_INPUT_CLICK
    handleEvent(eventCode, extraParams)
}
export const signupCancelClickEvent =()=>{
    const eventCode  = eventConstants?.SIGNUP_CANCEL_CLICK
    const extraParams  = {}
    handleEvent(eventCode, extraParams)
}

export const signupEmailSigninBtnClickEvent = (extraParams)=>{
    const eventCode = eventConstants?.SIGNUP_EMAIL_SIGNIN_BTN_CLICK
    handleEvent(eventCode, extraParams)
}

export const signupEmailResetPwdClickEvent =()=>{
   const eventCode = eventConstants?.SIGNUP_EMAIL_RESET_PWD_CLICK 
   const extraParams  = {}
   handleEvent(eventCode, extraParams)
}

export const signupCreateAccountNameInputClickEvent=(extraParams)=>{
    const eventCode = eventConstants?.SIGNUP_CREATEACCOUNT_NAME_INPUT_CLICK
    handleEvent(eventCode, extraParams)
}
export const signupCreateAccountDobInputClickEvent = (extraParams)=>{
    const eventCode  = eventConstants?.SIGNUP_CREATEACCOUNT_DOB_INPUT_CLICK
    handleEvent(eventCode, extraParams)
}
export const signupSaveBtnClickEvent = (extraParams)=>{
    const eventCode = eventConstants?.SIGNUP_SAVE_BTN_CLICK 
    handleEvent(eventCode, extraParams)
}
export const signupCountryCodeSelectEvent =(extraParams)=>{
    const eventCode  = eventConstants?.SIGNUP_COUNTRY_CODE_SELECT
    handleEvent(eventCode, extraParams)
}

export const signupWithPhoneInputClickEvent = ()=>{
    const eventCode = eventConstants?.SIGNUP_WITH_PHONE_INPUT_CLICK
    const extraParams  = {}
    handleEvent(eventCode, extraParams)
}

export const signupWithPhoneGenerateBtnClickEvent = (extraParams)=>{ // change GTM  event name to SIGNUP_WITH_PHONE_GENERATE_BTN_CLICK - this Signup_With_Phone_Generate_Otp_Btn_Click
    const eventCode = eventConstants?.SIGNUP_WITH_PHONE_GENERATE_BTN_CLICK
    handleEvent(eventCode, extraParams)
}
export const signupWithPhoneOtpInputClickEvent=(extraParams)=>{ 
    const eventCode = eventConstants?.SIGNUP_WITH_PHONE_OTP_INPUT_CLICK
    handleEvent(eventCode, extraParams)
}
export const signupWithPhoneVerifyBtnClickEvent =(extraParams)=>{  //Change GTM  event name to SIGNUP_WITH_PHONE_SAVE_BTN_CLICK-  this SIGNUP_WITH_PHONE_Verify_BTN_CLICK
    const eventCode = eventConstants?.SIGNUP_WITH_PHONE_SAVE_BTN_CLICK
    handleEvent(eventCode, extraParams)
}

export const signupWithPhoneResendOtpClickEvent =(extraParams)=>{
    const eventCode = eventConstants?.SIGNUP_WITH_PHONE_RESEND_OTP_CLICK
    handleEvent(eventCode, extraParams)
}

export const forgotPwdInputClickEvent=()=>{
    const eventCode = eventConstants?.FORGOT_PWD_INPUT_CLICK
    const extraParams ={}
    handleEvent(eventCode, extraParams)
}

export const forgotPwdSaveBtnClickEvent=(extraParams)=>{ // change GTM event name t0  FORGOT_PWD_SAVE_BTN_CLICK - Forgot_Pwd_Reset_Password_Btn_Click
    const eventCode = eventConstants?.FORGOT_PWD_SAVE_BTN_CLICK
    handleEvent(eventCode, extraParams)
}

export const  ResetPwdInputClickEvent =()=>{
    const eventCode = eventConstants?.RESET_PWD_INPUT_CLICK
    const extraParams = {}
    handleEvent(eventCode, extraParams)
}
export const resetPwdSaveBtnClickEvent =()=>{
    const eventCode = eventConstants?.RESET_PWD_SAVE_BTN_CLICK
    const extraParams = {}
    handleEvent(eventCode, extraParams)
}


export const UserSignupSuccess =(extraParams = {} )=>{
    const eventCode = eventConstants?.USER_SIGNUP_SUCCESS
    handleEvent(eventCode, extraParams)
}

export const SignupScreenLoaded =(extraParams = {} )=>{
    const eventCode = eventConstants?.SIGNUP_SCREEN_LOADED
    handleEvent(eventCode, extraParams)
}