import { password_rule_list } from "constants/Content/rules.constant";

export const partialStringMatch = (val1, val2) => {
    const _val1 = val1?.toString()?.toLowerCase();
    const _val2 = val2?.toString()?.toLowerCase();
    return _val1?.indexOf(_val2) > -1;
}

export const validatePasswordRules = (password="") => {
    let valid = true;
    for(let key in password_rule_list){
        const _rule = password_rule_list[key];
        const { validate } = _rule;
        const isValid = validate(password);
        if(!isValid){
            valid = false;
        }
    }
    return valid;
}