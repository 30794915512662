import { useEffect, useState } from "react";
import LDImage from "stories/Image";
import { LDSpan } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import "./index.css"
const Episodes = (props) => {
  const {
    episodes,
    isEpisodes,
    episodesNumber,
    totalEpisodes,
    handleAddWishlist,
    className,
    courseId,
    wishlistSelected,
    isWishlisted,
    courseTitle
  } = props;
  const [change,setChange] =useState()
  
  useEffect(()=>{
    setChange(isWishlisted)
  },[isWishlisted])

  const removeHeartIcon=(courseId)=>{
    setChange(false)
    handleAddWishlist(courseId,courseTitle,"remove")
  }
  const addHeartIcon = (courseId)=>{
    setChange(true)
    handleAddWishlist(courseId,courseTitle,"add")
  }

  return (
    <div>
      {isEpisodes ? (
        <div className="flex items-center justify-between bg-secondary p-3 ">
          <LDSpan className={`text-white font-modellicaBold pl-1  mycourse-episode ${className}`}>
            {episodes} episodes
          </LDSpan>
          {change ? (
            <LDImage
              src={imagePath("/Home/filledHeart.svg")}
              alt="asve"
              className="cursor-pointer pr-1"
              onClick={() => removeHeartIcon(courseId)}
            />
          ) : (
            <LDImage
              src={imagePath("/Sidebar/whishlist.svg")}
              alt="asve"
              className="cursor-pointer pr-1"
              onClick={() => addHeartIcon(courseId)}
            />
          )}
        </div>
      ) : (
        <LDSpan className="text-greeny font-modellicaBold text-sm lg:text-xs py-2 ">
          Episode {episodesNumber} of {totalEpisodes}{" "}
        </LDSpan>
      )}
    </div>
  );
};

Episodes.defaultProps = {
  handleClick: () => {},
  episodes: "",
  isEpisodes: false,
  totalEpisodes: "",
  episodesNumber: "",
  className: "",
};

export default Episodes;
