import CardImage from "components/Card/CardImage";
import Episodes from "components/Card/Episodes";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "stories/Button";
import Input from "stories/Input";
import { Heading, Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import LDTooltip from "stories/LDTooltip";
import { notification, Row ,Col} from "antd";
import useMedia from "hooks/deviceHelper/useMedia";
import MultiLoadingCards from "components/MultiLoadingCards";
import { Helmet } from "components/Helmet";
import { HELMET_CONSTANT } from "constants/helmet.constants";
import { mycoursesLoadEvent, mycoursesPlayClick, mycoursesPurchaseClick, mycoursesViewInvoiceClick, mycoursesWishlistClick } from "analytics/mycourses";
import { paymentInitiatePurchaseClickEvent } from "analytics/payment";
import ViewInVoice from "./ViewInVoice";
import { Notifier } from "components/Notifier";
import { getRoundedVal } from "utils";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { useQuery } from "hooks/useQuery";
import LDImage from "stories/Image";
import "./index.css"
import useIpEffect from "hooks/useIpEffect";

const MyCourses = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile =useMedia()

  const state = useSelector((state) => state?.profile?.profileDetails);
  const { mycourseList, loading } = useSelector((state) => state?.mycourse);
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const profile = useSelector((state) => state?.profile?.profileDetails);
  const [invoiceViewActive, setInvoiceViewActive] = useState(false)
  const [invoiceUrl,setInvoiceUrl] = useState("")
  const query = useQuery();

  const { WISHLIST_MY_COURSES_THUMB_DIMENSION } = appConfig;
  const { childMode } = profile;

  useEffect(()=>{
    const invoicePath = query?.get("invoice");
    if(invoicePath && invoicePath.length){
      setInvoiceViewActive(true)
      setInvoiceUrl(invoicePath)
    }else if(invoiceUrl.length || invoiceViewActive){
      setInvoiceViewActive(false);
      setInvoiceUrl('');
    }
  },[query]);
  useIpEffect(() => {
    mycoursesLoadEvent()
    dispatch?.mycourse?.requestMycourseListDetails();
    mycoursesLoadEvent(true)
  }, [childMode]);

  const handleClick = (courseId,courseTitle,slug) => {
    const payload={
      course_id:courseId,
      user_id : state?.id
    }
    const redirectURL=(purchaseId)=>{
      const params ={event_label : courseTitle}
      paymentInitiatePurchaseClickEvent(params)
      history.push(`/${ROUTE_CONSTANTS.PAYMENT}?purchaseId=${purchaseId}&courseId=${courseId}&course=${slug}`);
    }
    dispatch?.payment?.requestInitialPurchase(payload,redirectURL)
  };
  const handleChange = (val) => {
    dispatch?.mycourse?.filterMyCourselist(val);
  };
  const handlePlayCourse = (courseId) => {
    history?.push(`${ROUTE_CONSTANTS?.COURSE}/${courseId}`);
  };
  const success = () => {
    notification["success"]({
      message: "success",
      description: "Added to wishlist",
    });
  };
  const successMsg=(key,param)=>{
    notification[key]({
      message: key,
      description: param,
    });
  }
  const handleAddWishlist = (id,courseTitle,type) => {
    const params = {
      event_label : type, //${courseTitle} -
      course_name: courseTitle
    }
    mycoursesWishlistClick(params)
    
    dispatch?.commonParams?.requestAddToWishlist({courseId: id, type ,courseTitle:courseTitle},()=>{})
  };
  
  const handleViewInvoce=(customerReceipt,courseTitle)=>{
    if(customerReceipt?.length !== 0 && customerReceipt){
      const params = {course_name :`${courseTitle}`}
      mycoursesViewInvoiceClick(params) 
      history.push(`/mycourses?invoice=${customerReceipt}`);
    }else{
      Notifier("DELETED",MESSAGE_CONSTANTS.INVOICE_MSG)

    }
  }
  const closeViewInvoice=()=>{
    history.push(ROUTE_CONSTANTS['MY_COURSES']);
  }

  return (
    <div className="container mx-auto lg:my-10 my-2  min-h-screen ">
      {<Helmet  title={HELMET_CONSTANT?.MYCOURSE}/> }
          <div className="flex justify-between items-center lg:w-[55%] w-[70%]">
           <div className="cursor-pointer lg:ml-12 ml-6 pt-0" onClick={closeViewInvoice}>
            {invoiceViewActive &&
             <LDImage
                src={imagePath("/Banner/leftArrow.svg")}
                alt="rightArrow"
              className="lg:h-6 h-4  pr-2"
              />}
            </div>  
            <div className="mt-1 lg:mt-5 mb-10">
              <Heading className="heading-font-style">My Courses</Heading>
            </div>
          </div>
        <div className="lg:mx-0 mx-3">
         {invoiceViewActive ? 
            <ViewInVoice pdf={invoiceUrl} />
         :
         <>
         {/* <div className="my-10 mx-14 lg:mx-40 md:mx-20 ">
          <Input
            icon={true}
            type="search"
            src={imagePath("/searchIcon.svg")}
            placeholder="Search in my courses"
            iconStyle={"left-6 top-4 "}
            normalStyle={
              "bg-secondary h-12 text-sm outline-none px-16 rounded-full w-full"
            }
            handleChange={handleChange}
          />
        </div> */}
        <div className="text-center relative top-[12rem] lg:top-[14rem] md:top-[22rem]" >
          {mycourseList?.length === 0 && !loading && 
           <div  className="">
              <Heading>Nothing in My Courses.</Heading>
           </div>
          }  
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 2xl:m-20  text-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-x-8 gap-y-4 xl:ml-8 xl:mr-8 lg:ml-12 lg:mr-9">
          {loading && mycourseList?.length === 0 &&<MultiLoadingCards count={3} />}
  
          {mycourseList?.map((_wishlist, i) => {
            const {
              courseId,
              courseRating,
              isWishlisted,
              publishDate,
              courseTitle,
              customerReceipt,
              isPurchased,
              totalEpisodeCount,
              mrp,
              thumbnailUrl,
              slug,
              offer_price,
              currencySymbol
            } = _wishlist;
            return (
              <div className="card bg-card rounded-md overflow-hidden" key={i}>
                <div className="w-full overflow-hidden relative">
                  <CardImage
                    src={thumbnailUrl}
                    imageCls={`lg:w-full h-[40%] lg:h-[8.375rem] ${window?.innerWidth >= 2400 && "lg:h-[10.375rem]"}`}
                    className=" w-full lg:h-full h-18v"
                    dimension={WISHLIST_MY_COURSES_THUMB_DIMENSION}
                  />
                  {/* {isPurchased && (
                    <div className="absolute right-4 top-4 bg-greeny opacity-80 inline-block p-1 rounded text-white font-modellicaBold shadow-lg">
                      Purchased
                    </div>
                  )} */}
                  {/*card-badge */}
                </div>
                <div className="lg:py-[.6rem] lg:px-[.9rem] p-4 text-left">
                  {/*card-body  */}
                  <LDTooltip title={courseTitle}>
                   {isMobile ?
                   <Title className="mb-4 mycourse-Title-style">{courseTitle}</Title>:
                   <Title className="h-10 line-clamp mycourse-Title-style">{courseTitle}</Title>
                   }
                  </LDTooltip>
                  <Button
                    type="primary"
                    className={
                      "mb-2 mt-2 lg:h-7 h-9 rounded-md bg-white shadow-lg font-modellicaMedium shadow-black-500/50 text-lg text-greeny flex items-center justify-center mycourse-button-play-coursestyle"
                    }
                    onClick={() => {
                      const params = {course_name :`${courseTitle}`}
                      handlePlayCourse(courseId)
                      mycoursesPlayClick(params)
                    }}
                    label={"Play Course"}
                    src={imagePath("/Mycourse/playicon.svg")}
                    icon={true}
                  />
                  {isPurchased ? (
                    <Button
                      disabled ={customerReceipt ===  null || customerReceipt === undefined}
                      className={
                      "lg:h-7 h-9 mb-2 outline-none  rounded-md bg-greeny font-modellicaMedium text-md text-white flex items-center justify-center"
                      }
                      btnCls={"mycourse-button-style"}
                      label={"View Invoice"}
                      onClick={() => handleViewInvoce(customerReceipt,courseTitle)}
                      src={imagePath("/Mycourse/viewInvoice.svg")}
                      icon={true}
                    />
                  ) : (
                    <Button
                      className={
                        "lg:h-7 h-9 mb-2 outline-none rounded-md bg-greeny text-sm text-white flex items-center justify-center"
                      }
                      onClick={()=> {
                        handleClick(courseId,courseTitle,slug)
                        const params = {course_name : `${courseTitle}`}
                        mycoursesPurchaseClick(params)
                      }}
                      offer_price={offer_price}
                      price={`${mrp && getRoundedVal(mrp)}`}
                      isRupee={true}
                      currencySymbol={currencySymbol}
                      btnCls="text-white mr-2 mycourse-button-style"
                      label={`Purchase for`}
                      src={imagePath("/rupee.svg")}
                      icon={false}
                    />
                  )}
                </div>
                <div className="">
                  {/*card-footer*/}
                  <Episodes
                    isEpisodes={true}
                    episodes={totalEpisodeCount}
                    isWishlisted={isWishlisted}
                    courseId={courseId}
                    courseTitle={courseTitle}
                    handleAddWishlist={handleAddWishlist}
                  />
                </div>
              </div>
            );
          })}
        </div>
        </>
       }
        </div>
    </div>
  );
};

export default MyCourses;
