import { LDAnalytics } from "utils/analyticsHelper.js";

class WishlistEvents extends LDAnalytics {
  event_Wishlist_Load_Start() {
    return {
      event: {
        module_name: "Wishlist",
        feature_name: "Wishlist Page",
        event_name: "Wishlist_Load_Start",
        event_label: "Wishlist Load",
      },
    };
  }

  event_Wishlist_Load_End() {
    return {
      event: {
        module_name: "Wishlist",
        feature_name: "Wishlist Page",
        event_name: "Wishlist_Load_End",
        event_label: "Wishlist Load",
      },
    };
  }
  event_Wishlist_Filter_Change(){
    return {
      event :{
        module_name :"Wishlist",
        feature_name:"Wishlist Filter",
        event_name:"Wishlist_Filter_Change",
        event_label:""
      }
    }
  }
  event_Wishlist_Course_Card_Click(){
    return {
      event:{
        module_name :"Wishlist",
        feature_name:"Wishlist Course Card",
        event_name:"Wishlist_Course_Card_Click",
      }
    } 
  }
  event_Wishlist_Course_Card_Wishlist_Click(){
    return {
      event:{
        module_name :"Wishlist",
        feature_name:"Wishlist Course Wishlist",
        event_name:"Wishlist_Course_Card_Wishlist_Click",
        event_label:""
      }
    }
  }
}

const wishlistEvents = new WishlistEvents();
export default wishlistEvents;
