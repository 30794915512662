import * as eventConstants from "./events.constants";
import episodeEvents from "./EpisodeEvents";

const handleEvent  = (eventCode, extraParams={}) => {
  episodeEvents.handle_analytics_event(eventCode, extraParams);
};

export const episodeLoadEvent = (loadEnd = false, extraParams={}) => {
  const eventCode = loadEnd ? eventConstants?.EPISODE_LOAD_END : eventConstants?.EPISODE_LOAD_START;
  handleEvent(eventCode, extraParams);
}

export const episodeLikeDislikeClickEvent =(params,extraParams={})=>{
  const eventCode = params ? eventConstants?.EPISODE_DISLIKE_CLICK : eventConstants?.EPISODE_LIKE_CLICK
  handleEvent(eventCode, extraParams) 
}

export const episodeShareEvent = (extraParams={}) =>{
  const eventCode = eventConstants?.EPISODE_SHARE_CLICK
  handleEvent(eventCode, extraParams)
}
export const episodeShareOptionEvent =(extraParams={})=>{
  const eventCode = eventConstants?.EPISODE_SHARE_OPTION_CLICK
  handleEvent(eventCode, extraParams)
}

export const episodeTextareaClickEvent=(extraParams={})=>{
  const eventCode = eventConstants?.EPISODE_POST_COMMENT_TEXTAREA_CLICK
  handleEvent(eventCode, extraParams)
}

export const episodePostCommentClickEvent =(extraParams={})=>{
    const eventCode = eventConstants?.EPISODE_POST_COMMENT_CLICK
    handleEvent(eventCode, extraParams)
}

export const episodeCommentReplyClickEvent =(extraParams={})=>{
    const eventCode = eventConstants?.EPISODE_COMMENT_REPLY_CLICK
    handleEvent(eventCode, extraParams)
}
export const episodeCommentEditClickEvent=(extraParams={})=>{
  const eventCode =  eventConstants?.EPISODE_COMMENT_EDIT_CLICK
  handleEvent(eventCode, extraParams)
}

export const episodeCommentDeleteClickEvent=(extraParams={})=>{
  const eventCode = eventConstants?.EPISODE_COMMENT_DELETE_CLICK
  handleEvent(eventCode, extraParams)
}
export const episodeEditCancelClickEvent =(extraParams={})=>{
  const eventConde = eventConstants?.EPISODE_EDIT_CANCEL_CLICK
  handleEvent(eventConde, extraParams)
}
export const episodeCommentRepliesClick=(extraParams={})=>{
  const eventCode = eventConstants?.EPSIODE_COMMENT_REPLIES_CLICK 
  handleEvent(eventCode, extraParams)
}

export const episodeListCardClickEvent  = (extraParams={}) => {
  const eventCode = eventConstants?.EPISODE_LIST_CARD_CLICK
  handleEvent(eventCode, extraParams)
}



export const EpisodePlayFirstEpisode =(extraParams = {}) => {
  const eventCode = eventConstants?.PLAY_FIRST_EPISODE
  handleEvent(eventCode,extraParams)
} 

export const EpisodePlaySecondEpisode = (extraParams = {}) => {
  const eventCode = eventConstants?.PLAY_SECOND_EPISODE
  handleEvent(eventCode,extraParams)
}


export const EpisodePaidEpisodeClick = (extraParams = {}) => {
  const eventCode = eventConstants?.PAID_EPISODE_CLICK
  handleEvent(eventCode,extraParams)
}

export const EpisodePurchasedEpisodeClick = (extraParams = {}) =>{
   const eventCode = eventConstants?.PURCHASED_EPISODE_CLICK
   handleEvent(eventCode, extraParams)
}