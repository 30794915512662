
import React from "react";
import { Title } from "stories/Typography";
import { LDSpan } from 'stories/Typography/index';

const ExternalLink = (props) => {
  const {resourceLink, resourceLinkTitle="",  episodeRestricted, setPurchaseModalActive} = props;

  const OpenLink = () => {
    if(!episodeRestricted){
    window.open(resourceLink, "_blank");
    }else{
        setPurchaseModalActive(true);
    }
  }

  return (
    <div className="mt-5 mb-7">
      <div style={{display:"flex", alignItems : "center"}}>
         <Title className=" font-light text-greeny">
             Resource - <LDSpan className="underline cursor-pointer" onClick={OpenLink}>
              {resourceLinkTitle}
            </LDSpan>
          </Title>
      </div>
    </div>
  );
};

export default ExternalLink;
