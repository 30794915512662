import React,{useState, useEffect} from "react";
import { Title,Subtitle } from "stories/Typography";
import { Button } from "stories/Button";
import Modal from "components/Modal";
import { COLOR_CONSTANTS } from "constants/color.constants";
import Image from "components/Image";
import "./index.css"
import { useDispatch } from 'react-redux';
import { getMobileOperatingSystem } from "utils/deviceHelper";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "constants/Apis";
import { setCookie } from "utils/storage.helper";
import { useOrientation } from "hooks/useOrientation";

const MobileScreenBanner = ({show}) => {
  const dispatch = useDispatch()
  const mobileDeviceType = getMobileOperatingSystem()
  const [islandscape,setIsLandscape] = useState(false)
  const isLandscapeValid =  useOrientation()

  const handleRedirect = () => {
    if(mobileDeviceType === "iOS"){
      window.open(APP_STORE_LINK)
    }
    else{
      window.open(PLAY_STORE_LINK)
    }
    dispatch?.storageData?.updateAppBanner(true) 
    setCookie("isappBannerPrompt", true, 10);
  };

  const handleClose=()=>{
    dispatch?.storageData?.updateAppBanner(true)  
    setCookie("isappBannerPrompt", true, 10);
  }
  useEffect(()=>{
    if (isLandscapeValid) {
      setIsLandscape(true)
   }else{
      setIsLandscape(false)
   }
  },[isLandscapeValid])
 const scrollerStyle = islandscape ? "h-[300px] overflow-x-auto" : ""
  return (
    <div className="min-h-screen">
      <Modal
            show={show}
            closable={false}
            bodyStyle={{
            padding: "0px",
              backgroundColor: COLOR_CONSTANTS.MOBILE_BANNER_SCRN_COLOR,
              overflowY: 'scroll'
            }}
            centered
            width={2500}
            maskStyle={{
            backgroundColor: COLOR_CONSTANTS.MOBILE_BANNER_SCRN_COLOR,
            opacity: "1",
            }}
            footer={null}
      >
        <div className={scrollerStyle}>
          <div className="text-center">
            <Title className="mb-6">
              {" "}Lifedemy - Life lessons for a happy Life!
              <br /> Download the App Now
            </Title>
          </div>
          <div className="text-center h-[400px]">
            <Image  
               src="https://d177hg5e90tx7k.cloudfront.net/mobileAppBanner.png"
              className="w-auto  h-full mx-auto"
            />
          </div>

          <div className="flex items-center justify-center py-10">
            <div className="w-[11rem]">
                <Button
                    type="primary"
                    className={
                    "px-4 py-1 font-modellicaBold outline-none  rounded-md bg-greeny text-md text-white flex items-center justify-center"
                    }
                    btnCls="text-white"
                    label={"Get the App Now"}
                   onClick={handleRedirect}
                />
            </div>
            <Subtitle onClick={handleClose} className="mx-4 cursor-pointer">Continue on web </Subtitle>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MobileScreenBanner;

//  Font size- reduce
// increate button size