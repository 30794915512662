import React from 'react'
import { Modal } from "antd";
import { COLOR_CONSTANTS } from 'constants/color.constants';
import Textarea from 'stories/Textarea';
import { Description } from 'stories/Typography';

const RefundModal = (props) => {
    const { handleClose, show, onOk } = props;

    const JoinButton = ({ children, className, onClick }) => {
        return (
          <button onClick={onClick} className={className}>
            {children}
          </button>
        );
      };

  return (
    <div>
      <Modal
        className=""
        visible={show}
        closable={true}
        onCancel={handleClose}
        centered
        width={400}
        footer={null}
        bodyStyle={{
          backgroundColor: COLOR_CONSTANTS?.SIDEBAR_SELECTED,
          borderRadius: "10px",
        }}
        maskStyle={{
          backdropFilter: "blur(3px)",
          "-webkit-backdrop-filter": "blur(3px)",
        }}
      >
          <>
          <div className="text-left">
             <Description className="text-white font-['BWModelica-Medium'] font-medium	 text-left text-[1.1rem] mb-[1rem] pt-[1.5rem] leading-[1.6]	">
             Are you sure want to get refund and cancel the session?
            </Description>
            </div>
            <div className="flex items-center gap-[1rem]">
            <JoinButton
              className="bg-greeny w-[100%] font-['BWModelica-Medium'] font-medium mt-4 text-white px-[2rem] md:py-[.6rem] py-[.6rem] rounded-lg text-[14px] text-center"
              onClick={handleClose}
            >
              No
            </JoinButton>

                <JoinButton
                className="bg-red-400 w-[100%] font-['BWModelica-Medium'] font-medium mt-4 text-white px-[2rem] md:py-[.6rem] py-[.6rem] rounded-lg text-[14px] text-center"
                onClick={onOk}
                >
                Yes
                </JoinButton>
            </div>
            </>
      </Modal>
    </div>
  )
}

export default RefundModal
