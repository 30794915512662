import React from 'react'
import { Heading } from 'stories/Typography'

const DeleteAccount = () => {
    const instructionsToDeleteAccount = [
        {
            id:1,
            title:"To delete your Lifedemy account:",
            key:"deleteAccount",
            list:
            [
            "Open the Lifedemy App",
            "Tap on hamburger menu -> Profile -> Delete Account -> Delete Account"
            ]
        },
        {
            id:2,
            title:"Deleting your account will:",
            key:"causeOfDeletingAccount",
            list:
            [
                "Remove your profile, subscription and data from all Lifedemy services.",
                "Deactivate and permanently delete your account immediately.",
                "Retain certain information as required by law."
            ]
        },
        
    ]

  return (
    <div className='flex flex-col min-h-screen m-[1.2rem] md:m-0 lg:ml-10'>
     {instructionsToDeleteAccount && instructionsToDeleteAccount?.map((data,idx)=>{
        return(
            <div key={data?.key}>
            <Heading className="mt-8">{data?.title}</Heading>
            <ul className='mt-4 ml-4 flex flex-col gap-[0.2rem] list-decimal'>
                    {
                        data?.list?.map((data)=>{
                            return(
                                <li className="text-white text-[14px] md:text-[16px]">{data}</li>
                            )
                        })
                    }
            </ul>
            </div>
        )
     }) }
    </div>
  )
}

export default DeleteAccount
