import { Col, Row } from "antd";
import { Heading, LDParagraph, Subtitle, Title} from "stories/Typography";
import { Switch } from "antd";
import { useEffect, useState } from "react";
import Image from "components/Image";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useHistory } from "react-router-dom";
import HorizontalLine from "stories/HorizontalLine";
import { imagePath } from "utils/assetHelper";
import { Helmet } from "components/Helmet";
import { HELMET_CONSTANT } from "constants/helmet.constants";
import { settingsLanguagePreferencesClickEvent, settingsPushNotificationClickEvent, settingsVideoQualityClickEvent, settingsChildModeClickEvent } from "analytics/settings";
import { useSelector, useDispatch } from "react-redux";
import { getItemFromStorage } from "utils/storage.helper";
import "./index.css"
import useMedia from "hooks/deviceHelper/useMedia";

const Settings = () => {
  const history =  useHistory()
  const profile = useSelector((state)=> state?.profile?.profileDetails)
  const pushNotification = useSelector((state)=> state?.pushNotification.pushNotificationDbData)
  const childAuthModalActive = useSelector((state) => state?.profile?.childAuthModalActive)
  const { notifyUser } = pushNotification;
  const { childMode } = profile;
  const dispatch = useDispatch();
  const isMobile = useMedia();
  const [childModalActive, setChildModalActive] = useState(false);

  const handleLanguage=()=>{
    const params = {event_label :"Select Language" }
    settingsLanguagePreferencesClickEvent(params)
    history.push(`/${ROUTE_CONSTANTS.LANGUAGE_PREFERENCE}`)
  }
  const handleVideoQuality=()=>{
    const params = {event_label :"Select Video Quality"}
    settingsVideoQualityClickEvent(params)
    history.push(`/${ROUTE_CONSTANTS.VIDEO_QUALITY}`)
  }
  const handleNotification = e => {
    const fcm = getItemFromStorage('fcm_token')
    const body = {
      fcmToken: fcm,
      notifyUser: e,
      userId: profile?.id,
      is_update: true
    };
    dispatch?.pushNotification?.requestAddPushNotification(body);
  };
  const handleChildMode = (mode) => {
    setChildModalActive(mode);
    const body = {
      childMode: mode,
    };
    if (mode) {
      dispatch?.profile?.updateChildMode(body);
    } else {
      dispatch?.profile?.setChildModalStatus(true);
    }
  };

  useEffect(() => {
    setChildModalActive(childMode);
  }, [childMode, childAuthModalActive]);

  return (
    <div className="container mx-auto  min-h-screen">
      {<Helmet  title={HELMET_CONSTANT?.SETTINGS}/> }
      <div className="lg:mt-7 lg:mb-10 mt-4 mb-5 text-center">
        <Heading className="setting-heading-font-style"> Settings</Heading>
      </div>
      <Row justify="center">
        <Col
          lg={15}
          md={24}
          sm={24}
          xs={24}
          className="lg:bg-ternary rounded-lg lg:px-11 px-3 md:px-6 lg:pt-12 pb-[8rem]"
        >
          {!isMobile && (
            <>
              <Subtitle className="mb-4 setting-options-font-style">Notification</Subtitle>
              <div className="lg:bg-sidebarSelected bg-secondary flex justify-between items-center py-4 px-4 rounded-lg">
                <div className="flex items-center">
                  <Image src={imagePath("/setting/push-notifcation.svg")} icon={true} className="h-6" />
                  <LDParagraph className="setting-options-font-style ml-2 sm:text-lg md:text-md">Push Notifications</LDParagraph>
                </div>
                <Switch
                  checked={notifyUser}
                  onClick={()=>settingsPushNotificationClickEvent({event_label : notifyUser ? "No" : "Yes"})}
                  onChange={(e)=>{ handleNotification(e) }}
                  className={`${notifyUser ? "bg-greeny" : "bg-grayColor"}`}     // commented Temporarily
                />
              </div>
            </>
          )}
          <Subtitle className="mb-4 mt-4 setting-options-font-style">Content preferences</Subtitle>
          <div className="my-2">
            <div className="flex justify-between items-center  cursor-pointer bg-secondary lg:bg-sidebarSelected py-4 px-4 rounded-t-lg" onClick={handleLanguage}>
              <div className="flex items-center">
                <Image src={imagePath("/setting/language.svg")} icon={true} />
                <LDParagraph className="ml-2 sm:text-lg md:text-md setting-options-font-style">Course Languages </LDParagraph>
              </div>
              <Image
                src={imagePath("/Banner/rightArrow.svg")}
                icon={true}
                className="h-4 cursor-pointer"
              />
            </div>
            <HorizontalLine className="opacity-10" />
            <div className="flex justify-between items-center cursor-pointer bg-secondary rounded-b-lg lg:bg-sidebarSelected  py-4 px-4" onClick={handleVideoQuality}>
              <div className="flex items-center">
                <Image src={imagePath("/setting/video-quality.svg")} icon={true} />
                <LDParagraph className="ml-2 sm:text-lg md:text-md setting-options-font-style">Video Quality</LDParagraph>
              </div>
              <Image
                src={imagePath("/Banner/rightArrow.svg")}
                icon={true}
                className="h-4 cursor-pointer"
              />
            </div>
          </div>
          <Subtitle className="mb-4 mt-4 setting-options-font-style">Parental Control</Subtitle>
          <div className="flex justify-between items-center bg-secondary rounded-lg lg:bg-sidebarSelected  py-4 px-4">
            <div className="flex items-center">
              <Image src={imagePath("/setting/shield-lock.svg")} icon={true} />
              <LDParagraph className="ml-2 sm:text-lg md:text-md setting-options-font-style">Child Mode</LDParagraph>
            </div>
            <Switch
              checked={childModalActive}
              onClick={()=>settingsChildModeClickEvent({event_label : childModalActive ? "Child Mode Deactivated" : "Child Mode Activated"})}
              onChange={(e) => { handleChildMode(e) }}
              className={`${childModalActive ? "bg-greeny" : "bg-grayColor"}`}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
