

import { Heading, LDSpan, Title } from "stories/Typography";
import StarRating from "stories/StarRating";
import { imagePath } from "utils/assetHelper";
import Share from "components/Share";
import LDImage from "stories/Image";
import { useEffect, useState } from "react";
import {  getRoundAndFloatVal, getUrlEncode } from "utils";
import { useParams } from 'react-router-dom';
import "./index.css"
import { LIFEDEMY_APP_URL } from "constants/app.constants";
const VideoDescription = (props) => {
  const {
    title,
    views,
    isWishlisted,
    rating,
    description,
    activeEpisode,
    minutes,
    episodeNumber,
    date,
    totalLikesCount,
    likeStatus,
    courseId,
    handleAddWishlist,
    handleRemoveWishlist,
    handleLike,
    fireEvent,
    fireEventOptions,
    courseTitle,
    IsLiked
  } = props;
  const [change,setChange] = useState(false)
  const params = useParams()
  const likeSrc = IsLiked?.toLowerCase() === "like" ? imagePath("/Episodes/filledLike.svg") : imagePath("/Episodes/like.svg") 
  const dislikeSrc = IsLiked?.toLowerCase() === "dislike" ? imagePath("/Episodes/filledDislike.png") :imagePath("/Episodes/dislike.svg") 
  useEffect(()=>{ 
    setChange(isWishlisted)
  },[isWishlisted])

  const removeHeartIcon=(courseId)=>{
    setChange(false)
    handleAddWishlist(courseId,"remove")
  }
  const addHeartIcon =(courseId)=>{
    setChange(true)
    handleAddWishlist(courseId,"add")
  }

  const onClickHandler=()=>{
     fireEvent()
  }

// let url = `Now watch ${ params?.episodeId ? courseTitle : title} exclusively on Lifedemy!\nWebsite: ${window.location.host}/course/${params?.courseId || params?.id}\n`; //To be send when the when the APP link is available  "App Link: preprod.lifedemy.in"
// const shareCopyUrl = `${window.location.host}/course/${params?.courseId || params?.id}`


let url = `Hey, I found this super interesting.\nCheck out this masterclass - *${courseTitle || title }* exclusively on Lifedemy app!\n\nDownload the app at:${LIFEDEMY_APP_URL}`

// const shareCopyUrl = `${params?.episodeId 
//                              ? 
//                              `${window.location.host}/episode/${params?.courseId || params?.id}/${params?.episodeId}` 
//                             : `${window.location.host}/course/${params?.courseId || params?.id}`}`
// let url = `Now watch ${ params?.episodeId ? courseTitle : title} exclusively on Lifedemy!\nWebsite: ${shareCopyUrl}\n`; //To be send when the when the APP link is available  "App Link: preprod.lifedemy.in"

const shareOnFacebook = getUrlEncode(LIFEDEMY_APP_URL) // only works on staging and preprod
return (
    <div className="">
      <Heading className="lg:my-6 sm:mt-6 mt-4 mr-4 mb-4 setting-heading-style">{title}</Heading>

      {/* <Title className="lg:text-white font-modellicaBold text-white text-3xl">
        {title}
      </Title> */}
       {activeEpisode && (
          <>
             <div className="items-center flex lg:my-[-10px]">
              <LDSpan className="text-greeny simple-duration-style">{minutes}</LDSpan>
               <span className="w-[2px] h-[2px] rounded-full bg-white mx-1"></span>
              <LDSpan className="simple-text-size-episode">Episode {episodeNumber}</LDSpan>
             </div>
            <div className="flex justify-between items-center mb-5 lg:mb-2 mt-[-19px]">
              <LDSpan className=" simple-text-size-episode-grey">
                {date}
              </LDSpan>
            {activeEpisode && (
              <div className="flex items-center lg:mb-2">
                    <div className="flex lg:mr-6 mr-4 items-center cursor-pointer"
                        onClick ={IsLiked?.toLowerCase() === "like" ? ()=>handleLike("NEUTRAL") : ()=>handleLike("LIKE")}
                    >
                      <LDImage
                        src={likeSrc}
                        alt="whishList"
                        className="pr-2"
                      />
                      <LDSpan className="simple-text-size-episode">Like</LDSpan>
                      {/* {totalLikesCount ? <LDSpan className="simple-text-size-episode"> {totalLikesCount} </LDSpan> : <LDSpan className="simple-text-size-episode">Like</LDSpan>} */}
                    </div>
                    <div className="flex lg:mr-6 mr-4 items-center  cursor-pointer  "
                        onClick={IsLiked?.toLowerCase() === "dislike" ? () => handleLike("NEUTRAL") : ()=>handleLike("DISLIKE")}
                    >
                      <LDImage
                        src={dislikeSrc}
                        alt="whishList"
                        className=" pr-2 h-3.5"
                      />
                      <LDSpan className="simple-text-size-episode"> Dislike </LDSpan>
                    </div>
                    <Share onClickHandler={onClickHandler} url={getUrlEncode(url)} shareCopyUrl={url} shareOnFacebook={shareOnFacebook}fireEventOptions={fireEventOptions}/>
              </div>
              )}
              </div>
          </>
        )}
      <div className="">
        
        {/* -------------- TODO LATER : Uncomment  -------------------*/}
        {/* <div className="text-white font-modellicaMedium justify-between flex items-center"> */}
          {/* <div>
          <LDImage src={imagePath("/Course/eye.svg")} alt="eye" className="h-2" />
          <LDSpan className="ml-1">{views=== 1 ? `${views} View` : `${views} Views` }</LDSpan>
          </div> */}
          {/* {activeEpisode && (
          <div className="flex  justify-end">
                <div className="flex lg:mr-6 mr-4 items-center cursor-pointer"
                    onClick ={ likeStatus?.type?.toLowerCase() === "like" ? ()=>handleLike("NEUTRAL") : ()=>handleLike("LIKE")}
                >
                  <LDImage
                    src={likeSrc}
                    alt="whishList"
                    className="pr-2"
                  />
                  {totalLikesCount ? <LDSpan> {totalLikesCount} </LDSpan> : <LDSpan>Like</LDSpan>}
                </div>
                <div className="flex lg:mr-6 mr-4 items-center  cursor-pointer "
                    onClick={() => handleLike("DISLIKE")}
                >
                  <LDImage
                    src={dislikeSrc}
                    alt="whishList"
                    className=" pr-2 h-3.5"
                  />
                  <LDSpan> Dislike </LDSpan>
                </div>
                <Share onClickHandler={onClickHandler} url={getUrlEncode(url)} shareCopyUrl={shareCopyUrl} shareOnFacebook={shareOnFacebook}fireEventOptions={fireEventOptions}/>
          </div>
          )} */}
        {/* </div> */}
        <div className="flex justify-between items-center text-white lg:mb-[-12px] my-[-15px] ">
          <div className="font-modellicaMedium text-md pointer-events-none">
            {!episodeNumber ? 
            <div className="flex items-center">
             <LDSpan className="mr-1 course-star-rating-number-style mt-1">{(!rating || rating == '0.0') ? '' : rating}
            </LDSpan>
            <StarRating
              isStarRatingActive={true}
              rating={Number(rating)}
              disabled={true}
              size="13px"
              // marginRight="video_desc_rating"
            />
            </div> : <></>}
          </div>
          <div className="flex items-center ">
            {/** for  Episode */}
            {!activeEpisode && (
              < >
                {change ? (
                  <div className="flex lg:mr-6 mr-4 items-center cursor-pointer"
                  onClick={() => removeHeartIcon(courseId)}
                  >
                  <LDImage
                    src={imagePath("/Home/filledHeart.svg")}
                    alt="whishList"
                    className="pr-1 h-3"
                  />
                  <LDSpan className="simple-text-size">Added to wishlist</LDSpan>
                  </div>
                ) : (
                  <div className="flex lg:mr-6 mr-4 items-center cursor-pointer"
                    onClick={() => addHeartIcon(courseId)}          
                  >
                  <LDImage
                    src={imagePath("/Home/heart.svg")}
                    alt="whishList"
                    className=" pr-1 h-3"
                  />
                  <LDSpan className="simple-text-size">Add to wishlist</LDSpan>
                  </div>
                )}
                 <Share onClickHandler={onClickHandler} url={getUrlEncode(url)} shareCopyUrl={url} shareOnFacebook={shareOnFacebook}fireEventOptions={fireEventOptions}/>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

VideoDescription.defaultProps = {
  title: "",
  views: "",
  handleShare: () => {},
  rating: "3.4",
  description: "",
};
export default VideoDescription;
