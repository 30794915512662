import { LDAnalytics } from "utils/analyticsHelper.js";

class CourseEvents extends LDAnalytics {
  event_Course_Load_Start() {
    return {
      event: {
        module_name: "Course",
        feature_name: "Course Load",
        event_name: "Course_Load_Start",
      },
    };
  }

  event_Course_Load_End() {
    return {
      event: {
        module_name: "Course",
        feature_name: "Course Load",
        event_name: "Course_Load_End",
      },
    };
  }
  event_Course_Wishlist_Click() {
    return {
      event: {
        module_name: "Course",
        feature_name: "Course Details",
        event_name: "Course_Wishlist_Click",
        event_label: "",
      },
    };
  }
  event_Course_Share_Click(){
    return {
      event: {
        module_name: "Course",
        feature_name: "Course Details",
        event_name: "Course_Share_Click",
      }
    }
  }
  event_Course_Share_Option_Click(){
    return {
        event: {
          module_name: "Course",
          feature_name: "Course Details",
          event_name: "Course_Share_Option_Click",
          event_label: "",
        }
    }
  }
   event_Course_Review_Star_Rating_Click(){
    return {
      event: {
        module_name: "Course",
        feature_name: "Course review",
        event_name: "Course_Review_Star_Rating_Click",
        event_label: "",
      }
     }
   }
   event_Course_Write_A_Review_Click(){
     return {
        event: {
          module_name: "Course",
          feature_name: "Course review",
          event_name: "Course_Write_A_Review_Click",
        }
     }
   }
   event_Course_Add_Review_Textarea_Click(){
    return {
      event: {
        module_name: "Course",
        feature_name: "Course review",
        event_name: "Course_Add_Review_Textarea_Click",
      }
    }
   }
   event_Course_Add_Review_Click(){
     return{
      event: {
        module_name: "Course",
        feature_name: "Course review",
        event_name: "Course_Add_Review_Click",
        event_label: "",
      }
     }
   }
   event_Course_Review_Edit_Click(){
    return{
        event: {
          module_name: "Course",
          feature_name: "Course review",
          event_name: "Course_Review_Edit_Click",
          event_label: "",
        }
     }
   }
   event_Course_Edit_Review_Textarea_Click(){
    return{
        event: {
          module_name: "Course",
          feature_name: "Course review",
          event_name: "Course_Edit_Review_Textarea_Click",
          event_label: "",
        }
     }
   }
   event_Course_Edit_Review_Click(){
      return{
        event: {
          module_name: "Course",
          feature_name: "Course review",
          event_name: "Course_Edit_Review_Click",
          event_label: "",
        }
     }
   }
   event_Course_Reviews_Show_More_Click(){
      return{
        event: {
          module_name: "Course",
          feature_name: "Course review",
          event_name: "Course_Reviews_Show_More_Click",
        }
     }
   }
   event_Course_Purchase_Card_Click(){
    return{
      event: {
        module_name: "Course",
        feature_name: "Course",
        event_name: "Course_Purchase_Card_Click",
      }
    }
   }
   event_Course_Episode_List_Card_Click(){
    return{
      event: {
        module_name: "Course",
        feature_name: "Course Episode List",
        event_name: "Course_Episode_List_Card_Click",
        event_label: "",
      }
    }
   }
   event_Play_First_Episode(){
    return{
      event: {
        module_name: "Course",
        feature_name: "Course Episode List",
        event_name: "Play_First_Episode",
        event_label: "",
      }
    }
   }
   event_Play_Second_Episode(){
    return{
      event: {
        module_name: "Course",
        feature_name: "Course Episode List",
        event_name: "Play_Second_Episode",
        event_label: "",
      }
    }
   }
   event_Paid_Episode_Click(){
    return {
      event: {
        module_name: "Course",
        feature_name: "Course Episode List",
        event_name: "Paid_Episode_Click",
        event_label: "",
      }
    }
   }
   event_Purchased_Episode_Click(){
    return{
      event: {
          module_name:"Course",
          feature_name:"Course Episode List",
          event_name:"Purchased_Episode_Click",
          event_label:"",
        }
     }
   }
}

const courseEvents = new CourseEvents();
export default courseEvents;
