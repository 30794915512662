const model = {
  state: {
    playing: false,
    playerInitialised: false
  },

  reducers: {
    setPlayerData: (state, payload) => {
      return {
        ...state,
        ...payload
      };
    },
  },

  effects: (dispatch) => ({
    async playPauseVideo(body) {
        dispatch?.videoPlayer?.setPlayerData(body);
    },
  }),
};

export default model;
