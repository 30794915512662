import { settingBackClickEvent, settingLanguagePreferenceApplyClickEvent, settingLanguagePreferenceOptionClickEvent } from "analytics/settings";
import { Col, notification, Row } from "antd";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormButton from "stories/FormButton";
import LDImage from "stories/Image";
import { Heading, LDParagraph, LDSpan, Subtitle, Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import "./index.css"

const LanguagePreferences = () => {

  const state = useSelector((state) => state?.settings?.languageOptions);
  const profile = useSelector((state) => state?.profile?.profileDetails);
  const { userData } = profile  
  const { data } = state;

  const dispatch = useDispatch();
  const history = useHistory()
  const [langOptions, setLangOptions] = useState([]);
  const [selected, setSelected] = useState("");

  const Message = (key, param) => {
    notification[key]({
      message: param,
    });
  };

  // remove useEffect later  
  useEffect(()=>{
      const payload = {
        userData: {
          langPref: ["en"],
        },
      };
      dispatch?.settings?.requestUpdateLanguagePreferenence(payload,()=>{});
      const params = {event_label:"en"}
     settingLanguagePreferenceOptionClickEvent(params) // remove this later
  },[])

  useEffect(()=>{
    if(userData?.langPref){
       const newString = userData?.langPref.toString()
       const newArray =newString.split(",")
      setSelected(newArray.toString() || "en")
    }
  },[userData])

  useEffect(() => {
    dispatch?.profile?.requestProfileDetails();
  }, []);

  useEffect(() => {
    if (data?.attributes?.languageOptions?.length > 0) {
      setLangOptions(data?.attributes?.languageOptions);
    }
  }, [data?.attributes]);

  useEffect(() => {
    dispatch?.settings?.requestLanguageList(Message);
  }, []);

  const handleSelect = (value) => {
    const param ={event_label : value}
    settingLanguagePreferenceOptionClickEvent(param)
    setSelected(value)
  };
  const handleBack=()=>{
    const params = {event_label: "Language Back"}
    settingBackClickEvent(params)
    history.push(ROUTE_CONSTANTS.SETTINGS)
  }


  // required : for future 
  // const submitLanguage = () => {
  // const params ={event_label :selected}
  // settingLanguagePreferenceApplyClickEvent()
  //   if(selected?.length >0){
  //     const newString = selected.toString();
  //     const payload = {
  //       userData: {
  //         langPref: [newString],
  //       },
  //     };
  //     console.log(payload)
  //     dispatch?.settings?.requestUpdateLanguagePreferenence(payload, Message);
  //   }
  // };
  const filterListStyle =
    "setting-lang-select border border-white text-center rounded cursor-pointer p-2";

  const selectedStyle =
    "setting-lang-select border border-greeny text-center rounded bg-greeny cursor-pointer p-2";
  return (
    <div className="container mx-auto min-h-screen">
     <Title className="relative top-12 left-10 w-12 block lg:hidden">
           <LDSpan className="flex items-center cursor-pointer" onClick={handleBack}>
            <LDImage
              src={imagePath("/Banner/leftArrow.svg")}
              alt="rightArrow"
              className="h-4 pr-2 w-6"
            />
           </LDSpan>
         </Title>
       <div className="lg:mt-7 mt-4 lg:mb-10 mb-7  text-center">
        <Heading className="setting-heading-lang-style"> Course Languages</Heading>
       </div>
      <Row justify="center">
        <Col
          lg={15}
          md={24}
          sm={24}
          xs={24}
          className="lg:bg-ternary px-3 py-2 lg:px-11 lg:pt-12 rounded-lg  pb-[8rem]"
        >
          <div className="flex items-center justify-between mb-6 mx-2">

          <Subtitle className="text-white setting-course-lang-font-style relative">
            Select languages
          </Subtitle>

         <Title className="w-12 hidden lg:block">
           <LDSpan className="flex items-center cursor-pointer setting-course-lang-font-style" onClick={handleBack}>
            <LDImage
              src={imagePath("/Banner/leftArrow.svg")}
              alt="rightArrow"
              className="h-4 pr-2 w-6 "
            />
            Back
           </LDSpan>
         </Title>         
          </div>
          <div className="grid grid-cols-3 gap-3 text-left mt-2 h-[15rem] mx-2">
            {langOptions !== undefined &&
              langOptions.length > 0 &&
              langOptions.map((_items) => {
                const { value, id, label } = _items;
                const applyStyle = selected === value
                  ? selectedStyle
                  : filterListStyle;
                return (
                  <div key={id}>
                    {selected.includes(value) && label ? (
                      <LDParagraph
                        className={applyStyle}
                      >
                        {label}
                      </LDParagraph>
                    ) : (
                      <LDParagraph
                        className={applyStyle}
                        onClick={() => handleSelect(value)}
                      >
                        {label}
                      </LDParagraph>
                    )}
                  </div>
                );
              })}
          </div>     
          {/* <FormButton  required for future
            type="submit"
            className="bg-greeny hover:bg-greeny text-white m-0 mt-4 rounded "
            onClick={submitLanguage}
          >
            Apply
          </FormButton> */}
        </Col>
      </Row>
    </div>
  );
};

export default LanguagePreferences;
