
import MultiLoadingReviewList from "components/MultiLoadingReviewList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector  } from "react-redux";
import { Heading } from "stories/Typography";
import AddReview from "./AddReview"
import ListReview from "./ListReview";
import HomeError from "pages/Home/components/Errors";

const CourseReview =(props)=>{
    const { reviewList, paramId,courseTitle, user_details ,mrp, isPurchased,reviewLoading,courseSlug,offer_price} = props;
    const [list, setList] =useState([])
    const [hide,setHide]=useState(false)
    const dispatch = useDispatch()
    const reviewError  = useSelector((state) => state?.course?.reviewError);

    useEffect(()=>{
        setList(reviewList)
    },[reviewList])


    const SubmitOnlyRating = (rating) => {
        const body = {
              courseId: Number(paramId),
              rating: rating,
        }
      dispatch?.course?.requestAddReviews(body);
    }

    //function for to only submit Review and rating
    const SubmitReviewRating=(review,rating)=>{
        const body={
              courseId: Number(paramId),
              review: review
            }
     dispatch?.course?.requestAddReviews(body);
    }

    //Hide the component when we click on edit
    const  handleHideAddComponent =()=>{
       setHide(true) 
    }
    const handeRemoveAddComponent=()=>{
        setHide(false)
    }
    return (
        <div>
            {!reviewLoading && reviewError && <HomeError action={()=>{
                dispatch?.course?.setReviewLoading(true);
                setTimeout(()=>{
                    dispatch?.course?.requestReviewDetails(paramId); 
                },800);
            }} />}
            {!reviewError && 
            <>
            {!hide && <AddReview  
                SubmitOnlyRating ={SubmitOnlyRating} 
                SubmitReviewRating ={SubmitReviewRating}
                isPurchased={isPurchased}
                mrp={mrp}
                paramId={paramId}
                courseSlug={courseSlug}
                courseTitle={courseTitle}
                offer_price={offer_price}
            /> }
            {reviewLoading && user_details?.length === 0 && <MultiLoadingReviewList count={3} /> }
            <div className="text-center">  
            </div>
            <ListReview
                userDetails={user_details}
                userId={user_details?.id}
                List={list}
                isPurchased={isPurchased}
                handleHideAddComponent={handleHideAddComponent}
                handeRemoveAddComponent={handeRemoveAddComponent}
                hide={hide}
                courseTitle={courseTitle}
                paramId={paramId}
                setList={setList}
                offer_price={offer_price}
            />
            </>}

        </div>
    )
}
export default CourseReview

