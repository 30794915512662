import { axiosGet, axiosGetV1, axiosPost, axiosPostV1, axiosPut, axiosPutV1 } from "axiosRequests";
import {
  INSTRUCTORS_URL,
  INSTRUCTOR_DETAILS,
  RATINGS_URL,
  ALL_MEETING_LIST,
  TIME_SLOT_LIST,
  CREATE_MEETING,
  UPDATE_QUERY,
  ADD_FEEDBACK_REVIEW,
  MY_TODAY_MEETINGS,
  POST_SESSION_EMAIL,
  CREATE_MEETING_SIGNATURE_TOKEN,
  SESSION_PAYMENT_INVOICE,
  GET_BOOKED_SESSION_DETAILS,
  RELEASE_TIME_SLOT,
  CANCEL_MEETING,
  RESCHEDULE_MEETING,
  SESSION_HOLDSLOT,
  SESSION_REFUND_URL
} from "constants/Apis";
import { STATUS } from "constants/status.constant";
import { SESSION_STATUS, changeDateToUTCFormat, currentTimeZone } from "utils";

const headers = {}
const isEncrypt = true 
const model = {
  state: {
    instructorList: [],
    ratings: [],
    instructorDetails: {},
    mySession: [],
    myUpcommingSessions: [],
    timeSlotList: [],
    createMeeting: {},
    todayMeeting: [],
    loading: false,
    instructorDetailsLoading:true,
    instructorRatingLoading:true,
    instructorTimeSlotsLoading:true,
    instructorTimeSlotsErr:false,
    instructorErr:false,
    instructorRatingErr:false,
    sessionPaymentInvoice:{},
    error: "",
    addStarRating: {},
    bookedSessionDetails: {},
    cancelMeeting:{},
    rescheduleMeeting:{}
  },
  reducers: {
    setInstructorList: (state, payload) => {
      return {
        ...state,
        instructorList: payload,
      };
    },
    setInstructorDetails: (state, payload) => {
      return {
        ...state,
        instructorDetails: payload,
      };
    },
    setRatings: (state, payload) => {
      return {
        ...state,
        ratings: payload,
      };
    },
    setMySession: (state, payload) => {
      return {
        ...state,
        mySession: payload,
      };
    },
    setMyUpcommingSession: (state, payload) => {
      return {
        ...state,
        myUpcommingSessions: payload,
      };
    },
    setTimeSlotList: (state, payload) => {
      return {
        ...state,
        timeSlotList: payload,
      };
    },
    setCreateMeeting: (state, payload) => {
      return {
        ...state,
        createMeeting: payload,
      };
    },
    setTodayMeeting: (state, payload) => {
      return {
        ...state,
        todayMeeting: payload,
      };
    },
    setLoading: (state, payload) => {
      return {
        ...state,
        loading: payload,
      };
    },
    setInstructorLoading:(state,payload)=>{
      return{
        ...state,
        instructorDetailsLoading:payload
      }
    },
    setInstructorDetailsErr:(state,payload)=>{
      return{
        ...state,
        instructorErr:payload
      }
    },
    setInstructorRatingLoading:(state,payload)=>{
      return{
        ...state,
        instructorRatingLoading:payload
      }
    },
    setInstructorRatingErr:(state,payload)=>{
      return{
        ...state,
        instructorRatingErr:payload
      }
    },
    setInstructorTimeSlotsLoading:(state,payload)=>{
      return{
        ...state,
        instructorTimeSlotsLoading:payload
      }
    },
    setInstructorTimeSlotsErr:(state,payload)=>{
      return{
        ...state,
        instructorTimeSlotsErr:payload
      }
    },
    setSessionPaymentInvoice:(state,payload)=>{
      return{
        ...state,
        sessionPaymentInvoice:payload
      }
    },
    setError: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    setAddStarRating: (state, payload) => {
      return {
        ...state,
        addStarRating: payload,
      };
    },
    setBookedSessionDetails :(state, payload) =>{
      return {
        ...state,
        bookedSessionDetails: payload
      }
    },
    setCancelMeeting:()=>{
      return {
        ...state,
        cancelMeeting: payload
      }
    },
    setRescheduleMeeting:()=>{
      return {
        ...state,
        rescheduleMeeting: payload
      }
    }
  },
  effects: (dispatch) => ({
    async getMySession(id) {
      try {
        this.setLoading(true);
        const response = await axiosGetV1(`${ALL_MEETING_LIST}?user_id=${id}`,
         headers, 
         isEncrypt
         );
        const { data, status } = response;
        if (STATUS.includes(status)) {
          this.setLoading(false);
          this.setMySession(data?.data);
        }
      } catch (error) {
        this.setError(error);
        console.log(error);
      }
    },
    async getInstructorList() {
      try {
        this.setLoading(true);
        const response = await axiosGetV1(`${INSTRUCTORS_URL}&date=${changeDateToUTCFormat(new Date())}`,headers, isEncrypt);
        const { data, status } = response;
        if (STATUS.includes(status)) {
          this.setLoading(false);
          this.setInstructorList(data?.data);
        } else {
          this.setLoading(false);
        }
      } catch (error) {
        this.setError(error);
        this.setLoading(false);
        console.log(error);
      }
    },
    async getInstructorDetails(id) {
      try {
        const response = await axiosGetV1(
          `${INSTRUCTOR_DETAILS}/getById?id=${id}&date=${changeDateToUTCFormat(new Date())}`,
          headers, 
          isEncrypt
        );
        this.setLoading(true);
        const { data, status } = response;
        if (STATUS.includes(status)) {
          this.setInstructorDetails(data?.data);
          this.setInstructorDetailsErr({instructorErr:false})
          this.setInstructorLoading(false)
        } else {
          this.setInstructorDetailsErr({instructorErr:true})
          this.setInstructorLoading(true);
        }
      } catch (error) {
        this.setError(error);
        this.setInstructorLoading(true)
        this.setInstructorDetailsErr({instructorErr:true})
        console.log(error);
      }
    },
    async getRatings(id) {
      try {
        const response = await axiosGetV1(`${RATINGS_URL}?instructor_id=${id}`, headers, isEncrypt);
        this.setLoading(true);
        const { data, status } = response;
        if (STATUS.includes(status)) {
          const list = data?.data === "No data found" ? [] : data?.data?.reviewList
          this.setRatings(list);
          this.setInstructorRatingLoading(false)
          this.setInstructorRatingErr({instructorRatingErr:false})
        } else {
          this.setInstructorRatingLoading(true)
        }
      } catch (error) {
        this.setError(error);
        this.setInstructorRatingLoading(true)
        this.setInstructorRatingErr({instructorRatingErr:true})
        console.log(error);
      }
    },
    async getMyUpcommingSession(id) {
      try {
        const response = await axiosGetV1(
          `${MY_SESSION_DETAILS}list?user_id=${id}`,
          headers, 
          isEncrypt
        );
        this.setLoading(true);
        const { data, status } = response;
        const upcommingSessionList = data.filter(
          (data) => data?.sessionStatus === "upcomming"
        );
        if (STATUS.includes(status)) {
          this.setLoading(false);
          this.setMyUpcommingSession(upcommingSessionList);
        }
      } catch (error) {
        this.setError(error);
        console.log(error);
      }
    },
    async getTimeSlotList(payload) {
      const { InstructorId, startDate, endDate } = payload;
      try {
        const response = await axiosGetV1(
          `${TIME_SLOT_LIST}getListForuser?instructor_id=${InstructorId}&startDate=${startDate}&endDate=${endDate}&timeZone=${currentTimeZone}`,
            headers,
            isEncrypt
          );
        this.setLoading(true);
        const { data, status } = response;
        if (STATUS.includes(status)) {
          this.setTimeSlotList(data?.data);
          this.setInstructorTimeSlotsLoading(false)
          this.setInstructorTimeSlotsErr({instructorTimeSlotsErr:false})
        } else {
          this.setInstructorTimeSlotsLoading(true)
          this.setInstructorTimeSlotsErr({instructorTimeSlotsErr:true})

        }
      } catch (error) {
        this.setError(error);
        this.setInstructorTimeSlotsLoading(true)
        this.setInstructorTimeSlotsErr({instructorTimeSlotsErr:true})
        console.log(error);
      }
    },
    async getCreateMeeting(payload, undefined, cb){ // Not in use Please remove later 
      try {
        this.setLoading(true);
        const response = await axiosPostV1(CREATE_MEETING, payload, headers, isEncrypt);
        const { data, status } = response;
        if (STATUS.includes(status)) {
          this.setCreateMeeting(data?.data);
          cb(data);
        }
      } catch (error) {
        this.setError(error);
      }
    },
    async updateQuery(payload, undefined, cb) {
      try {
        const response = await axiosPutV1(UPDATE_QUERY, payload, headers, isEncrypt);
        const { data, status } = response;
        if (STATUS.includes(status)) {
          cb(data.message);
        }
      } catch (err) {
        this.setError(err);
        console.log(err);
      }
    },
    async getBookedSessionDetails(payload, undefined, cb) {
      try {
        this.setLoading(true);
        const response = await axiosGetV1(`${GET_BOOKED_SESSION_DETAILS}?meeting_id=${payload.meetingId}`,
        headers, 
        isEncrypt
        );
        const { data, status } = response;
        if (STATUS.includes(status)) {
          this.setLoading(false);
          if(cb){
            cb(data?.data)
          }else{
            this.setBookedSessionDetails(data?.data)
          }
        }else{
          console.log(response,"Error")
        }
      } catch (err) {
        this.setError(err);
        console.log(err);
      }
    },
    async updateSessionEmail(payload, undefined, cb) {
      try {
        const response = await axiosPostV1(POST_SESSION_EMAIL, payload,
          headers, 
          isEncrypt
        );
        const { data, status } = response;
        if (STATUS.includes(status)) {
          cb(data, status);
        }
      } catch (err) {
        this.setError(err);
        console.log(err);
      }
    },
    async addFeedback(payload, undefined, cb){
      try{
        const response = await axiosPostV1(ADD_FEEDBACK_REVIEW,payload, headers, isEncrypt);
        const { data, status}  = response
        if(STATUS.includes(status)){
          this.setAddStarRating(data?.data)
          cb(true, data)
        }else{
          cb(false, data)
        }
      }
      catch(err){
        this.setError(err);
        console.log(err);
      }
    },
    async getTodayMeeting(payload) {
      try {
        const response = await axiosGetV1(`${MY_TODAY_MEETINGS}?user_id=${payload?.user_id}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`, headers, isEncrypt);
        this.setLoading(true);
        const { data, status } = response;
        const upcommingSessionList =data?.data && data?.data?.filter(
          (data) => data?.status === SESSION_STATUS?.SESSION_UPCOMING || data?.status === SESSION_STATUS?.SESSION_RESCHEDULED
        );
        if (STATUS.includes(status)) {
          this.setLoading(true);
          this.setTodayMeeting(upcommingSessionList);
        } else {
          this.setLoading(false);
        }
      } catch (error) {
        this.setError(error);
        console.log(error);
      }
    },
    async crateMeetingSignatureToken(payload, undefined, cb){
     try{
      const response =  await axiosPostV1(CREATE_MEETING_SIGNATURE_TOKEN, payload, headers, isEncrypt)
      const { data, status} = response
      if(STATUS.includes(status)){
        cb(true, data)
      }
      else{
        cb(false, { "message": "Meeting not available", "error": false,}) // TODO : change this later 
      }
     }
     catch(err){
       console.log(err)
     }
    },
    async getInvoiceForTheSession(id){
      try {
        this.setLoading(true)
        const response = await axiosGetV1(`${SESSION_PAYMENT_INVOICE}?meeting_id=${id}`, headers, isEncrypt)
        const {data, status} = response;
        if(STATUS.includes(status)){
          this.setLoading(false)
          this.setSessionPaymentInvoice(data)
        }
      } catch (error) {
        this.setError(error)
        this.setLoading(true)
      }
    },
    async requestReleaseTimeSlots(payload,state, cb){
      try {
        this.setLoading(true)
        const response = await axiosPutV1(`${RELEASE_TIME_SLOT}`, payload)
        const {data, status} = response;
        if(STATUS.includes(status)){
          cb()
        }
      } catch (error) {
        console.log(error,"ERROR")
      }
    },
    async requestCancelMeeting(payload, undefined, cb) {
      try {
        const response = await axiosPutV1(CANCEL_MEETING, payload, headers, isEncrypt);
        const {data, status, message} = response;
        if(STATUS?.includes(status)){
          cb(status, data, message)
        }else{
          cb(status, data, message)
        }
      } catch (error) {
        this.setError(error);
        console.log(error);
      }
    },
    async requestRescheduleMeeting(payload, undefines, cb) {
      try {
        const response = await axiosPutV1(RESCHEDULE_MEETING, payload, headers, isEncrypt);
        const {data, status, message} = response;
        if(STATUS?.includes(status)){
          cb(data,status,message)
        }else{
          cb(data, status, message)
        }
      } catch (error) {
        this.setError(error);
        console.log(error);
      }
    },
    async requestHoldSlot(payload, undefined, cb) {
      try {
        const response = await axiosPutV1(SESSION_HOLDSLOT, payload);
        const {data, status} = response;
        if(STATUS?.includes(status)){
          cb(data)
        }
      } catch (error) {
        this.setError(error);
        console.log(error);
      }
    },
    async requestRefund(payload, undefined, cb) {
      try {
        const response = await axiosPutV1(SESSION_REFUND_URL, payload, headers, isEncrypt);
        const {data, status} = response;
        if(STATUS?.includes(status)){
          cb(status, data)
        }else{
          cb(status, data)
        }
      } catch (error) {
        this.setError(error);
      }
    }
  }),
};

export default model;
