import { LDParagraph, LDSpan } from "stories/Typography";
import "./index.css"
import { useState } from 'react';
const CourseDescription = (props) => {
  //TextContent this component can be removed
  const { name, description } = props;
  const [toShow,setToShow] = useState(340)
  let min = 340

  return (
    <LDParagraph className="font-modellicaMedium text-md text-justify mb-5 lg:mb-0 episode-desc-text-size">
      {description.slice(0,toShow)}
      {description?.length > min &&
      <>
        {toShow === min ?
          <LDSpan className="text-greeny cursor-pointer episode-desc-text-more-less" onClick={()=>setToShow(description?.length)}>...More</LDSpan>:
          <p><LDSpan className="text-greeny cursor-pointer episode-desc-text-more-less" onClick={()=>setToShow(340)}>Less</LDSpan></p>}
      </>}
    </LDParagraph>
  );
};

CourseDescription.defaultProps = {
  name: "",
  description: "",
};

export default CourseDescription;
