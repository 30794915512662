import CouponList from "components/CouponList";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Heading, Title} from "stories/Typography";
import { copyTextToClipboard } from "utils";
import { Helmet } from "components/Helmet";
import { HELMET_CONSTANT } from "constants/helmet.constants";
import { offerAndDiscountLoadEvent, offersAndDiscountCopyBtnClickEvent, offersAndDiscountFilterClickEvent } from "analytics/offersAndDiscount";
import { Notifier } from "components/Notifier";
import "./index.css"
import useAuthenticatedEffect from "hooks/useAuthenticatedEffect";

const OffersAndDiscount = () => {
  //remove it later: when we get the terms list from backend or it will present the offerAndDiscountList
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");

  const termAndConditionList = [
    { id: 1, title: "Applicable once per user." },
    { id: 1, title: "Valid on Mon and Tue" },
    { id: 1, title: "Valid on SBI Credit cards." },
    { id: 1, title: "Other T&C apply." },
    { id: 1, title: "Offer valid till Mar 29, 2022 11:59 PM. " },
  ];

  //used it from now,Change it later when we have api
  useAuthenticatedEffect(() => {
    offerAndDiscountLoadEvent()
    dispatch?.payment?.requestActiveCoupon();
    offerAndDiscountLoadEvent(true)
  }, []);


  const handleCopyClick = (copyText, id) => {
    const params ={event_label:copyText}
    offersAndDiscountCopyBtnClickEvent(params)
    copyTextToClipboard(copyText)
      .then(() => {
        setSelected(id);
        setTimeout(() => {
          setSelected("");
        }, 5000);
        Notifier( 200 ,'Coupon code is copied')
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleFilterCouponList = (value) => {
  //   dispatch?.payment?.filterCouponlist(value);
  // };
  const state = useSelector((state) => state?.payment);
  const { active_coupon_list, loading } = state;
  return (
    <div className="container mx-auto my-10 min-h-screen">
      {<Helmet  title={HELMET_CONSTANT?.OFER_AND_DISCOUNT}/> }
      <div className="text-center">
        <div className="mt-5">
          <Heading className="heading-font-style"> Offers</Heading>
        </div>
        <div className="w-2/3 mx-auto my-4">
          {/* <Input
            icon={true}
            src={imagePath("/searchIcon.svg")}
            placeholder="Search coupon"
            iconStyle={"left-6 top-4 "}
            onClick ={()=>offersAndDiscountFilterClickEvent()}
            normalStyle={
              "bg-secondary h-12 text-sm outline-none px-16 rounded-full w-full"
            }
            handleChange={handleFilterCouponList}
          /> */}
        </div>
      </div>
      {active_coupon_list?.length === 0 ? (
        <Title className="text-center">No coupons available </Title>
      ) : (
        <CouponList
          loading={loading}
          _list={active_coupon_list}
          isApply={false}
          termAndConditionList={termAndConditionList}
          isCopy={true}
          selected={selected}
          handleCopyClick={handleCopyClick}
          cls="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-9 lg:mx-10 mx-6"
        />
      )}
    </div>
  );
};

export default OffersAndDiscount;
