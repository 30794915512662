import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { Heading, LDParagraph, LDSpan } from "stories/Typography";
import Input from "stories/Input";
import Textarea from "stories/Textarea";
import FormButton from "stories/FormButton";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "components/Helmet";
import { HELMET_CONSTANT } from "constants/helmet.constants";
import { suggestionPageInputClickEvent, suggestionPageSubmitBtnClickEvent, suggestionPageTextareaClickEvent, SupportPageLoaded } from "analytics/suggestion";
import { Notifier } from 'components/Notifier/index';
import UpdatedEmail from "components/UpdatedEmail";
import "./index.css"
import { EmailCheckRegEx } from "utils";
import { PaymentUpdateEmailSuccess } from "analytics/payment";
const Suggestion = () => {
  
  const userDetail = useSelector((state) => state?.profile?.profileDetails);

  const loading = useSelector((state) => state?.suggestions?.loading)
  const course_details = useSelector((state) => state?.course?.course_details);
  const dispatch = useDispatch()
  const firstName = userDetail?.firstName?.length === 0 || userDetail?.firstName === undefined || userDetail?.firstName === null ? "": userDetail?.firstName  
  const lastName = userDetail?.lastName?.length  === 0 || userDetail?.lastName === undefined || userDetail?.lastName === null ? "": userDetail?.lastName 
  const email  = userDetail?.email?.length === 0 || userDetail?.email === undefined || userDetail?.email === null ? ""  : userDetail?.email
  const mobile = userDetail?.mobileNumber === null ? "N/A" : userDetail?.mobileNumber
  const [formField, setformField] = useState({
    name: `${firstName} ${lastName}`,
    email: `${userDetail?.email}`,
    feedback: "",
    mobile: `${userDetail?.mobileNumber}`,
  });

  const { courseTitle } = course_details;
  
  const [error,setError]=useState({feedback:""})
  const [showMsg, setShowMsg] = useState(false);
  const [show, setShow ] = useState(false)
  const isDisabled = !userDetail?.email  ? false : true

  useEffect(() => {
    dispatch?.profile?.requestProfileDetails();
  }, []); 
   

  useEffect(()=>{
    const params = {
        event_label:`${firstName} ${lastName} | ${email}`
    }
    SupportPageLoaded(params)
    setformField({ name: `${firstName} ${lastName}`, email, mobile, feedback: ""})  
  },[userDetail])
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setformField((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  };

  const callback = () => {
    setShowMsg(true)
    setTimeout(() => {
     setShowMsg(false) 
    },3000)
  }
  const runValidation=()=>{
   if(formField?.feedback?.trim()?.length > 150){
      setError({"feedback":"Feedback must be less than 150 character letter..!"})
      setTimeout(()=>{
         setError({})
      },[3000])
   }
  } 

  //  useEffect(()=>{
  //   if(email?.length === 0){
  //     setShow(true)
  //   }  
  //  },[email])

  const handleSubmit = () => {
    const params ={event_label : `${formField.name} ${formField.email} ${formField.feedback}`}
    suggestionPageSubmitBtnClickEvent(params)
    runValidation()
    if (
      formField?.email?.length !== 0 &&
      formField?.feedback?.length !==0 &&
      formField?.name?.length !==0 
    ) {
      const body = {
        data:{
          personName:formField?.name,
          email:formField?.email,
          message:formField?.feedback,
          phone: formField?.mobile, 
        }
      }
      dispatch?.suggestions?.requestSendSuggestions(body,callback)
      setformField({...formField, feedback: ""})
    }
  };
  const btnColor = formField?.feedback?.length > 0 
    ? " hover:bg-greeny bg-greeny "
    : " lg:hover:bg-grayColor bg-greeny lg:bg-grayColor ";
   const  disabledVal = formField?.email?.trim()?.length === 0 
   const diableFeedback = formField?.feedback?.trim()?.length === 0 
   const diableFeedback1= formField?.feedback?.trim()?.length > 150
   const handleInputClick = ()=>{
    suggestionPageInputClickEvent({event_label:formField?.name})
   }
  const handleEmailClick =() => {
     suggestionPageInputClickEvent({event_label:formField?.email})
     setShow(true)
  }

  const handleSuggestionClick =(e)=>{
    e.preventDefault();
    setShow(true)
    suggestionPageTextareaClickEvent({event_label:"Suggestion Message"})
  }

  const handleUpdateEmail = (email) => {
    if(email?.length ===0){
       return Notifier("DELETED","Please enter email")
    }
    if(email && EmailCheckRegEx(email)) {
      const data = {
        email: email
      };      
      const cb=()=>{
        setShow()
        //commented since it is payment success event is not rquired here
        //  const params = {
        //   event_label: courseTitle
        // }
        // PaymentUpdateEmailSuccess(params)
        // handleStartPurchase(email)
      }
      dispatch?.profile?.requestUpdateProfile(data,cb);
    } else {
      Notifier("DELETED", "Please enter a valid email");
    }
  };

  return (
    <div className="container mx-auto mt-2 mb-10 lg:my-10 min-h-screen">
      {<Helmet  title={HELMET_CONSTANT?.SUGGESTIONS}/> }
      {email?.length === 0 && 
        <UpdatedEmail 
          title="Please update your email" 
          btnTitle="Update Email" 
          show={show} 
          setShow={()=>setShow(false)} 
          isPurchase={false} 
          // handleStartPurchase={()=>{}} 
          updateEmailClickAction={handleUpdateEmail}
        />
      }
      <div className="mt-5 mb-10 text-center">
        <Heading className="feedback-heading-style">Support</Heading>
      </div>
      <Row justify="center">
        <Col
          lg={16}
          md={24}
          sm={24}
          xs={24}
          className="lg:bg-ternary px-2 lg:px-4 lg:pt-12 pb-20 rounded-lg"
        >
          <div className="">
            {/* {email?.trim().length === 0 ?
               <div className="lg:text-left text-center">
               <LDSpan  className="error-color text-[3.3vw] md:text-[3.3vw]  p-1 relative bottom-2"> Please update your Email ID to serve you better</LDSpan><br/> */}
               {/* <LDSpan  className=" error-color text-[3.2vw] md:text-[3.3vw] p-1 relative bottom-2 underline cursor-pointer" onClick={()=>setShow(true)}>Click here to update.</LDSpan>  */}
               {/* </div> : ""
            } */}
            <Input
              placeholder="Enter name"
              name="name"
              type="name"
              disabled={true}
              onClick ={handleInputClick}
              value={formField.name?.trim()}
              className="lg:bg-sidebarSelected bg-logoColor h-12 feedback-heading-field-style outline-none my-2 p-2 border-2  border-formBorder rounded-lg w-full mr-3"
              onChange={handleChange}
            />
            <Input
              placeholder={!formField.email?.trim() ?"Update email" :"Enter email"}
              name="email"
              type="email"
              autocomplete="off"
              disabled={isDisabled}
              onClick ={handleEmailClick}
              value={formField.email?.trim()}
              className="lg:bg-sidebarSelected bg-logoColor  h-12 feedback-heading-field-style  outline-none my-2 p-2  border-2 border-formBorder rounded-lg w-full mr-3"
              onChange={handleChange}
            />
            <Textarea
              placeholder="Please reach out to us here"
              name="feedback"
              value={formField.feedback}
              handleChange={handleChange}
              onClick={ handleSuggestionClick}
              rows={5}
              className="w-full outline-none placeholder-opacity-25 placeholder-italic  bg-logoColor lg:bg-sidebarSelected  border-2 border-formBorder rounded-lg feedback-heading-field-style p-3 my-2"
            ></Textarea>
            {error?.feedback?.length > 0 && <LDSpan className="text-remove text-left">{error?.feedback}</LDSpan>}
          </div>
          <div className="lg:w-36">
            <FormButton
              onClick={handleSubmit}
              loading={loading}
              disabled={diableFeedback || disabledVal || diableFeedback1}
              className={`${btnColor} h-12 lg:w-24 mt-2 w-full rounded-lg hover:text-white text-white`}
            >
              Submit
            </FormButton>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Suggestion;
