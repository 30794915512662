export const COURSE_LOAD_START = "Course_Load_Start";
export const COURSE_LOAD_END = "Course_Load_End";
export const COURSE_WISHLIST_CLICK = "Course_Wishlist_Click";
export const COURSE_SHARE_CLICK = "Course_Share_Click";
export const COURSE_SHARE_OPTION_CLICK = "Course_Share_Option_Click";
export const COURSE_REVIEW_STAR_RATING_CLICK = "Course_Review_Star_Rating_Click";
export const COURSE_WRITE_A_REVIEW_CLICK = "Course_Write_A_Review_Click";
export const COURSE_ADD_REVIEW_TEXTAREA_CLICK = "Course_Add_Review_Textarea_Click";
export const COURSE_ADD_REVIEW_CLICK = "Course_Add_Review_Click";
export const COURSE_REVIEW_EDIT_CLICK = "Course_Review_Edit_Click";
export const COURSE_EDIT_REVIEW_TEXTAREA_CLICK = "Course_Edit_Review_Textarea_Click";
export const COURSE_EDIT_REVIEW_CLICK = "Course_Edit_Review_Click";
export const COURSE_REVIWES_SHOW_MORE_CLICK = "Course_Reviews_Show_More_Click";
export const COURSE_PURCHASE_CARD_CLICK = "Course_Purchase_Card_Click";
export const COURSE_EPISODE_LIST_CARD_CLICK = "Course_Episode_List_Card_Click";

export const  PLAY_FIRST_EPISODE  = "Play_First_Episode"
export const  PLAY_SECOND_EPISODE = "Play_Second_Episode"
export const  PAID_EPISODE_CLICK  = "Paid_Episode_Click"
export const  PURCHASED_EPISODE_CLICK  = "Purchased_Episode_Click"