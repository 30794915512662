import {sidebarMenuItemClickEvent } from "analytics/sidebar";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import HorizontalLine from "stories/HorizontalLine";
import useMedia from "hooks/deviceHelper/useMedia";
import LDImage from "stories/Image";
import { LDParagraph,LDSmall } from "stories/Typography";
const SidebarMenuItem = (props) => {
  const { src, filledSrc, title, route, sidebarActive,onClick, navName,index ,mobileStyle,mobileStyleGreen, ignoreHighlight = false } = props;
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMedia();
  const activeLink = location.pathname;
  const className =
    !ignoreHighlight && activeLink == route
      ? `bg-sidebarSelected text-greeny lg:text-[1.1vw] ${mobileStyle}`
      : `text-highLight text-normal opacity-90 lg:text-[1.1vw] ${mobileStyleGreen}`;
  const changeLogo = activeLink == route ? filledSrc : src;

  const styleSiderbarMenu = sidebarActive
    ? "flex items-center mb-4 pl-3 "
    : "flex flex-col justify-center items-center mb-8";
  const showLine = index && index === 3 && !isMobile && <HorizontalLine className="bg-white  my-4 opacity-50" />
  return (
    <>
      {showLine !== 0 && showLine}
    <div
      className={`mx-auto ${styleSiderbarMenu}`}
      onClick={() => {
        const params ={event_label:route} 
        sidebarMenuItemClickEvent(params)
        history.push(`${route}`)
      }}
    >
      {changeLogo?.trim()?.length !== 0 &&
        <LDImage
          src={changeLogo}
          alt=""
          className={`h-5 w-5 cursor-pointer mx-4 block ${sidebarActive ? "lg:mr-7" : "lg:mr-3" }`}
        />
      }
      {sidebarActive ? (
        <LDParagraph
          className={`w-full py-2 pl-4 rounded-md cursor-pointer ${className}`}
          onClick={onClick}
        >
          {title}
        </LDParagraph>
      ) : (
        <LDSmall className=" text-highLight">
          {navName}
        </LDSmall>
      )}
    </div>
    </>

  );
};
SidebarMenuItem.defaultProps ={
  mobileStyle : "",
  mobileStyleGreen : "" 
}
export default SidebarMenuItem;
