import * as eventConstants from "./events.constants";
import offerAndDiscountEvents from "./OfferAndDiscountEvents";

const handleEvent = (eventCode, extraParams = {}) => {
  offerAndDiscountEvents.handle_analytics_event(eventCode, extraParams);
};

export const offerAndDiscountLoadEvent = (loadEnd = false) => {
  const eventCode = loadEnd
    ? eventConstants?.OFFERS_AND_DISCOUNT_LOAD_END
    : eventConstants?.OFFERS_AND_DISCOUNT_LOAD_START;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};

export const offersAndDiscountFilterClickEvent = () => { //Removed 
  const eventCode = eventConstants?.OFFERS_AND_DISCOUNT_FILTER_CLICK;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};

export const offersAndDiscountCopyBtnClickEvent = (extraParams) => {
  const eventCode = eventConstants?.OFFERS_AND_DISCOUNT_COPY_BTN_CLICK;
  handleEvent(eventCode, extraParams);
};
