import { useState, useEffect } from "react";

import Modal from "components/Modal";
import Email from "components/Authentication/Email";
import Phone from "components/Authentication/Phone";
import Welcome from "components/Authentication/Welcome";

import { COLOR_CONSTANTS } from "constants/color.constants";
import "./index.css";
import CreateProfile from "./CreateProfile";
import { useSelector } from "react-redux";
import { useQuery } from "hooks/useQuery";
const Authentication = ({isCreateProfile}) => {
  const query = useQuery();
  const routeAuthType = query?.get("authType");
  const [authType, setAuthType] = useState("2");
  
  useEffect(() => {
    if (routeAuthType && routeAuthType === "1") {
      setAuthType(routeAuthType)
    }
  }, [authType]);

  return (
    <>
      <Modal
        show={true}
        closable={false}
        bodyStyle={{
          padding: "0px",
          backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
          boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.4)",
          borderRadius: "5px"
        }}
        wrapClassName="bg-[#272d3533] backdrop-blur-sm"
        width={400}
        height={800}
        maskStyle={{
          backgroundColor: COLOR_CONSTANTS.MODAL_COLOR,
          opacity: "0.8",
        }}
        footer={null}
      >
        {isCreateProfile ? (
          <CreateProfile />
        ) : (
          <>
            {/* {authType === "" && <Welcome setAuthType={setAuthType} />} */}
            {authType === "1" && <Email setAuthType={setAuthType}/>}
            {authType === "2" && <Phone setAuthType={setAuthType} />}
          </>
        )}
      </Modal>
    </>
  );
};

export default Authentication;