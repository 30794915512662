import { axiosGet, axiosPut } from "axiosRequests";
import { GET_PROFILE_DETAILS, LANGUAGE_PREFERENCES_URL} from "constants/Apis";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { STATUS } from "constants/status.constant";
const model = {
  state: {
    languageOptions:{},
  },
  reducers: {
    setLanguageOptions: (state, payload) => {
      return {
        ...state,
        languageOptions: payload
      }
    }
  },
  effects: (dispatch) => ({

    async requestLanguageList(callback) {
      try{
        const param = `?populate=*`    
        const response = await axiosGet(`${LANGUAGE_PREFERENCES_URL}${param}`)
        const { data, status } =response
        if(STATUS.includes(status)){
          dispatch?.settings?.setLanguageOptions(data)
        }
        else{
           callback("error",data?.error?.message)
        }
      }
      catch(err){
        console.log(err,"error in list language ")
      }
    },
    async requestUpdateLanguagePreferenence(payload,undefined,callback){
      try{
        const response =await axiosPut( GET_PROFILE_DETAILS,payload)
        const { data ,status }  = response
        if(STATUS.includes(status)){
          dispatch?.profile?.setProfileDetails(data);
          callback("success",MESSAGE_CONSTANTS.UPDATE_LANGUAGE_SUPPORT_MSG)
        }  
      }
      catch(err){
        console.log(err,"error is updated Language")
      }
    }
  }),
};

export default model;
