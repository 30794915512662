import PropTypes from "prop-types";
import { LDSpan, Title } from "stories/Typography";
import "./index.css";
export const Button = ({
  type,
  icon,
  src,
  label,
  isRupee = false,
  className,
  price,
  btnCls,
  iconSize,
  offer_price,
  style,
  currencySymbol,
  ...props
}) => {
  return (
    <button type="button" className={`${type} ${className}`} {...props}>
      {isRupee ? (
        <>
        {offer_price > 0  
          ?
          <>
          <h1 className={` ${btnCls ? btnCls : "text-greeny mr-2 flex"  }`}>{label}</h1>
            <p className="flex items-center line-through decoration-greeny decoration-2 ml-[-4px]">     {/*    line-through decoration-4 decoration-red-600*/}
              <h1 className={`${btnCls ? btnCls : "text-greeny"}`}>  {currencySymbol}{price}</h1>
            </p>  
            <p className="flex items-center ml-[-4px]">
              <h1 className={`${btnCls ? btnCls : ""}`} style={{color:"black"}}> {currencySymbol}{offer_price}</h1>
            </p>    
          </>
          :
          <>
          <Title className={` ${btnCls ? btnCls : "text-greeny mr-2"  }`}>{label}</Title>
          {/* <img src={src} alt="rupees" className={`h-[${iconSize}]`} /> */}
          <Title className={`${btnCls ? btnCls : "text-greeny"}`}>{currencySymbol} {price}</Title>
          </>
         }
        </>
      ) : (
        <>
          {icon && <img src={src} alt="iconimage" className="m-2" />}
          <h1 className={` ${btnCls ? btnCls : "text-greeny"}`} style={{fontSize:"13px",fontWeight:"900",fontFamily:" BWModelica-Bold",...style }}>{label}</h1>
        </>
      )}
    </button>
  );
};

Button.propTypes = {
  primary: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: "primary",
  onClick: () => { },
  label: "",
  className: "",
  icon: false,
  src: null,
  isRupee: false,
  price: "",
  iconSize:"11px",
  offer_price: 0 ,
  style:''
};
export default Button;
