import { message } from "antd";
import { axiosGet, axiosPut } from "axiosRequests";
import { GET_WISHLIST_URL, WHISHLIST_URL } from "constants/Apis";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { STATUS } from "constants/status.constant";
import { partialStringMatch } from "utils/stringHelper";

const model = {
  state: {
    originalWishlist: [],
    wishlistData: [],
    loading: false,
  },

  reducers: {
    setWishlistDetails: (state, payload) => {
      return {
        ...state,
        originalWishlist: payload,
        wishlistData: payload,
      };
    },
    setFilteredWishlist: (state, payload) => {
      return {
        ...state,
        wishlistData: payload,
      };
    },
    setWishlistLoading: (state, loading) => {
      return {
        ...state,
        loading: loading,
      };
    },
  },

  effects: (dispatch) => ({
    async requestWishlistDetails() {
      try{
        const response = await axiosGet(GET_WISHLIST_URL);
        dispatch?.wishlist?.setWishlistLoading(true);
        const { data,status } = response;
        if(STATUS.includes(status)){
          dispatch?.wishlist?.setWishlistDetails(data?.results);
          dispatch?.wishlist?.setWishlistLoading(false);
        }else{
          message.error(data?.error?.message)
          dispatch?.wishlist?.setWishlistLoading(false);
        }
      }
      catch(err){
        console.log(err,"error")
      }
    },
    filterWishlist(key, state) {
      const filteredData = state?.wishlist?.originalWishlist?.filter(
        (_item) => {
          const { courseTitle } = _item;
          return partialStringMatch(courseTitle, key);
        }
      );
      dispatch?.wishlist?.setFilteredWishlist(filteredData);
    },

    async removeFromWishlist(courseId, state) {
        const index=state?.wishlist?.wishlistData?.findIndex((_items)=>{
            return _items?.courseId === courseId
        })
        state?.wishlist?.wishlistData.splice(index, 1);
        const newArray = state?.wishlist?.wishlistData;
        dispatch?.wishlist?.setWishlistDetails(newArray);
       }  
  }),
};
export default model;
