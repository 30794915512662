import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useQuery } from "hooks/useQuery";
import { useSelector } from "react-redux";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { PLAY_STORE_LINK } from 'constants/Apis';
import { LDParagraph, LDSpan } from "stories/Typography";
import LinkTag from "stories/LinkTag";
import Button from "stories/Button";
import Modal from "components/Modal";
import { stringSlice } from "utils";
import { removeToken } from "utils/token.helper";
import { imagePath } from 'utils/assetHelper';
import FormButton from '../../../stories/FormButton/index';

const CoursePurchasedModal = ({ phoneNumber, handleClose }) => {
    const history = useHistory();
    const currentPath = history?.location;
    const query = useQuery();
    const courseId = query.get("courseId");
    const userProfile = useSelector(state => state?.profile?.profileDetails);
    const [anotherPhoneLinkClick, setAnotherPhoneLinkClick] = useState(true);

    const redirectToCoursePage = () => {
        history.push({
            pathname: `/course/${courseId}`,
            state: { phoneNumber }
          })
    }

    const handleGoToCourse = () => {
        const userMobile = userProfile?.mobileNumber
        if (userMobile) {
            const slicedMobile = stringSlice(userMobile, 3)
            if (phoneNumber && phoneNumber === slicedMobile) {
                redirectToCoursePage()
            } else {
                redirectToCoursePage()
                removeToken();
            }
        } else {
            redirectToCoursePage()
        }
    }

    const handleAnotherPhone = () => {
        setAnotherPhoneLinkClick(false)
        handleClose(false)
    }
  
    return (
        <Modal
            show={anotherPhoneLinkClick}
            closable={false}
            bodyStyle={{
                padding: "0px",
                backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
                boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.4)",
                borderRadius: "5px"
            }}
            wrapClassName="bg-[#272d3533] backdrop-blur-sm"
            width={400}
            height={800}
            maskStyle={{
                backgroundColor: COLOR_CONSTANTS.MODAL_COLOR,
                opacity: "0.8",
            }}
            footer={null}
        >
            <div className="py-4 px-6 lg:py-8 lg:px-16">
                 <div className='flex justify-center items-center mt-9 mb-5 '>
                    <img src={imagePath("/call.svg")} alt="call" style={{height:"3.7rem",width:'3.8rem',background:'#262C35', borderRadius:'46%',padding:'10px'}} />
                </div>   
                <LDParagraph className="m-4 lg:m-0 payment-success-message-style">
                   <p className="text-xl font-modellicaMedium mb-4">Course Already Purchased </p>
                   <p className='text-md font-bold'> You have already purchased this course,<br/> please login using this mobile number: <br/></p>
                   
                   <p className='mt-3'>
                   <span className="text-greeny font-modellicaBold text-xl">+91&nbsp;{phoneNumber}</span>
                   </p>
                    <LinkTag size="text-white"  route={{pathname:PLAY_STORE_LINK}} target="_blank" >
                    <FormButton
                        type="submit"
                        className="bg-greeny auth-button-text-style h-10  lg:w-full px-3 mt-4  mb-2 rounded mx-auto w-[100%]">
                            View course on the app 
                    </FormButton>
                    </LinkTag>
                    <Button
                        type="primary"
                        className="p-1 h-10 mt-4 mb-2 rounded payment-success-btn-style bg-white border-none font-modellicaMedium"
                        label={"View course on the web"}
                        onClick={handleGoToCourse}
                        style={{fontSize:'15.12px'}}
                    />
                    <p className="underline text-greeny font-modellicaMedium m-4">
                        <span className="cursor-pointer" onClick={handleAnotherPhone}>
                            Try another number
                        </span>
                    </p>
                    {/* <Button
                        type="primary"
                        className="p-1 lg:h-10 mt-4 lg:mb-2 mb-10 rounded payment-success-btn-style bg-greeny border-none font-modellicaMedium"
                        btnCls="text-white"
                        label={"Try with another phone"}
                        onClick={handleAnotherPhone}
                    /> */}
                </LDParagraph>
            </div>
        </Modal>
    )
}

export default CoursePurchasedModal