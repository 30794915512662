import { useDispatch, useSelector } from "react-redux";
import useAuthenticatedEffect from "./useAuthenticatedEffect";
import { getItemFromStorage, setItemToStorage } from "utils/storage.helper";
import { useState } from "react";
import { isSupported } from "firebase/messaging";
import { checkNotificationPromise } from "utils/deviceHelper";
import { getMessaging, getToken } from "firebase/messaging";
import { app } from "firebaseinit";
import useMedia from "./deviceHelper/useMedia";

export default function usePushNotification() {
  const [deviceFcm, setDeviceFcm] = useState("");
  const profile = useSelector(state => state?.profile?.profileDetails);
  const dispatch = useDispatch();
  const localFcm_token = getItemFromStorage("fcm_token");
  const isMobile = useMedia();

  useAuthenticatedEffect(async () => {
    const isFirebaseSupported = await isSupported();
    if (!isMobile && checkNotificationPromise() && isFirebaseSupported) {
      const messaging = getMessaging(app);
      getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
        .then(currentToken => {
          if (currentToken) {
            setDeviceFcm(currentToken);
            if(localFcm_token != currentToken){
              setItemToStorage("fcm_token", currentToken);
            }
          }
        })
        .catch(err => {
          console.log("Push notification error");
        });
    }
  }, [checkNotificationPromise, isSupported, isMobile]);

  useAuthenticatedEffect(() => {
    //get notification pref from db
    if (!isMobile && profile?.id && deviceFcm) {
      const prefBody = { fcm: deviceFcm, user: profile?.id };
      dispatch?.pushNotification?.getPushNotificationPreference(prefBody);
    }
  }, [profile, deviceFcm, isMobile]);
}
