import { Col, Row } from 'antd'
import { Notifier } from 'components/Notifier'
import { ROUTE_CONSTANTS } from 'constants/routes.constants'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FormButton from 'stories/FormButton'
import LDImage from 'stories/Image'
import Input from 'stories/Input'
import {  Heading, LDParagraph, LDSpan, Subtitle, Title } from 'stories/Typography'
import { imagePath } from 'utils/assetHelper'
import { EmailCheckRegEx } from 'utils';
import { COLOR_CONSTANTS } from 'constants/color.constants'

const AddEmail = () => {
  const [email,setEmail]=useState("")
  const [msg,setMsg]=useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleChange=(val)=>{
    setEmail(val)
  }

  const cb =()=>{    
    const data ={
      email: email?.toLowerCase()?.trim()
    }
    dispatch?.profile?.requestAddEmail(data,()=>{
       setMsg(true)   
    })
  }
  const handleAddEmail=()=>{
      if(email && EmailCheckRegEx(email)){
        const data ={
          email: email?.toLowerCase()?.trim()
        }
       dispatch?.profile?.requestUpdateProfile(data,cb);
      }
      else{
        Notifier("DELETED","Please enter valid email address")
      }
  }
  const handleResendLink = ()=>{
    if(email.length >6){
      const data ={
        email: email
      }
      // dispatch?.profile?.requestAddEmail(data,cb)
    }else{
      Notifier("DELETED","Please enter email")
    }
  }
  const handleCancel=()=>{
      history.push(`/${ROUTE_CONSTANTS?.PROFILE}`);
  }
  return (
    <div className="container mx-auto min-h-screen">
    <div className="my-5 text-center">
      <Heading className="text-white text-3xl font-modellicaBold md:hidden lg:block xl:block hidden">
        Profile Settings
      </Heading>
    </div>
    <Row justify="center">
      <Col
        lg={15}
        md={24}
        sm={24}
        xs={24}
        className="lg:bg-ternary rounded-md px-4 lg:px-16 pt-0 lg:pt-8 pb-[17rem]"
      >
        <div>
         <Title className=" mb-0 relative top-5 w-12">
          <LDSpan className=" cursor-pointer  flex items-center md:block" onClick={handleCancel}>
          <LDImage
              src={imagePath("/Banner/leftArrow.svg")}
              alt="rightArrow"
              className="h-4 pr-4 w-6 "
            />
            </LDSpan>
         </Title>

         <div className="text-center pb-4 xl:pb-4 md:pb-0">
              <Title className=" text-white lg:text-[1.2vw] sm:text-[0.7rem] font-modellicaBold md:text-[0.6rem]">
              Add Email Address
              </Title>
          </div>
            <div className='mr-auto ml-auto w-full lg:w-3/4 text-center'>
              <Input
                placeholder="Add email address"
                type="email"
                value={email}
                className="px-4 py-2 mx-1  lg:w-3/5 bg-logoColor h-10 text-[#eaeaea] opacity-90 text-md outline-none my-5 p-2  border-2 border-formBorder border-opacity-50 rounded-lg md:w-64 w-full mr-3"
                handleChange={handleChange}
               />
               {msg &&(
                  <>
                    {/* <LDParagraph className="text-greeny cursor-pointer" onClick={handleResendLink}>Resend Link</LDParagraph> */}
                    <LDParagraph className="text-greeny 2xl:text-sm">A link has been sent at your registered email address.</LDParagraph>
                   </>
                ) }
                <div className='lg:w-1/2 mx-auto'>
                {msg ?
                 <FormButton onClick={handleCancel} className="hover:bg-white md:w-8 lg:w-40 w-32 my-2  rounded-md hover:text-greeny text-greeny bg-white lg:bg-white pl-[7rem] pr-[9rem] lg:px-0"
                  backgroundColor={COLOR_CONSTANTS.WHITE}
                  color={COLOR_CONSTANTS.GREEN}
                 >
                   <span className='relative'>Done</span>
                </FormButton>
                :
                <FormButton onClick={handleAddEmail} className="hover:bg-white md:w-8 lg:w-40 w-32 my-2 rounded-md hover:text-greeny text-greeny bg-white lg:bg-white pl-[7rem] pr-[9rem] lg:px-0"
                  backgroundColor={COLOR_CONSTANTS.WHITE}
                  color={COLOR_CONSTANTS.GREEN}
                >
                <span className='relative'>Verify</span>
                </FormButton>
               }
                </div>

            </div>
        </div>
      </Col>
    </Row>
  </div>
  )
}

export default AddEmail