import { useSelector } from "react-redux";
import store from "store";

export  const getUserDetails =()=>{
    const { commonParams } = store.getState()?.commonParams 
     return commonParams
}

export const getUserProfile =()=>{
    const result = store.getState()?.registerWithEmail?.account_details?.user || {}
    return result 
}

export const getAccountDetails =()=>{
    const result = store.getState()?.registerWithEmail?.account_details || {}
    return result
}

export const getIPAddress = ()=>{
    const  result = store?.getState()?.commonParams?.ipInfo?.ip || ""
    return result
}

export const getCountryCode = ()=>{
    const  result = store?.getState()?.commonParams?.ipInfo?.country || ""
    return result
}