import Error from "components/Error";

const HomeError = ({errType=500, msg= '', action = undefined}) => {
    return (
        <div style={{ textAlign: "center",padding:"15% 0"}}>
            <Error errType={errType} action={action} msg={msg} />
        </div>
    )
}

export default HomeError;