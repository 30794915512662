import CardImage from "components/Card/CardImage";
import { useEffect, useState } from "react";
import StarRating from "stories/StarRating";
import { LDParagraph, LDSpan, Subtitle, Title, Heading } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import FormButton from "stories/FormButton";
import { Col, Row } from "antd";
import { path } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import PaymentSuccess from "./PaymentSuccess";
import PurchaseCourse from "./PurchaseCourse";
import LDImage from "stories/Image";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useHistory } from "react-router-dom";
import { useQuery } from "hooks/useQuery";
import { RAZORPAY_KEY } from "constants/razorpay.contants";
import { Helmet } from "components/Helmet";
import { HELMET_CONSTANT } from "constants/helmet.constants";
import { paymentContinuePurchaseBtnClickEvent, paymentLoadEvent,PaymentSuccessEvent,PaymentFailedEvent, PaymentUpdateEmailSuccess  } from "analytics/payment";
import { EmailCheckRegEx, getRoundedVal } from "utils";
import Loader from "stories/Loader";
import UpdatedEmail from "components/UpdatedEmail";
import "./index.css"
import Image from "components/Image";
import CourseDescription from "pages/Course/Components/CourseDescription";
import { Notifier } from "components/Notifier";
import useIpEffect from "hooks/useIpEffect";

const Payment = (props) => {
  const { episodes, price } = props;
  const query = useQuery()
  const dispatch = useDispatch();
  const history = useHistory()
  const profile_details = useSelector((state)=> state?.profile?.profileDetails) 
  const {  email  } =profile_details
  const [show,setShow] = useState(false)
  const purchaseId = query.get("purchaseId");
  const courseId = query.get("courseId")
  const appCongif = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const { PAYMENT_THUMB_DIMENSION } = appCongif;

  const course_details = useSelector((state) => state?.course?.course_details);
  const { initialPucharse } = useSelector((state) => state?.payment);
  const { purchaseDetails } = useSelector((state) => state?.payment);
  const referralDesc= useSelector((state)=>state?.referral?.referralDesc)
  const { id, attributes} = referralDesc

  const couponList = useSelector((state) => state?.payment?.active_coupon_list);
  const state = useSelector((state) => state?.profile?.profileDetails);


  const [purchaseData, setPuchaseData] = useState({});

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  
  const totalOrderAmount = path(["purchaseDetails", "amountDetails","totalOrderAmount"], purchaseData) || "0";
  const offerPrice =  path(["purchaseDetails", "amountDetails","offerPrice"], purchaseData) || "0";
  const originalOrderAmount =  path(["purchaseDetails", "amountDetails","originalOrderAmount"], purchaseData) || "0";
  const currencySymbol =  path(["purchaseDetails", "amountDetails","currencySymbol"], purchaseData) || "";
  const [inProgress, setInProgress] = useState(false);
  // OnReload 
  useIpEffect(()=>{
    const params = {event_label :course_details?.courseTitle}
      paymentLoadEvent(false,params)
      dispatch?.payment?.requestGetPurchaseDetails(purchaseId);
      dispatch?.course?.requestCourseDetails(courseId);
      paymentLoadEvent(true,params)
   },[])

  useEffect(() => {
    if (purchaseDetails) {
      setPuchaseData(purchaseDetails);
    }
  }, [purchaseDetails]);

  useEffect(() => {
    if (initialPucharse?.purchaseId !== undefined) {
      dispatch?.payment?.requestGetPurchaseDetails(initialPucharse?.purchaseId);
    }
  }, [initialPucharse?.purchaseId]);

  useEffect(() => {
    dispatch?.referral?.requestReferralPage()
    dispatch?.profile?.requestProfileDetails();
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onClick = () => {
        resolve(true);
      };
      script.onerror = (error) => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };  

  const updatedEmail=()=>{  
     setShow(true)
   }

   const handleStartPurchase= async (email)=>{
      setInProgress(true);
      const params ={event_label :course_details?.courseTitle} 
      paymentContinuePurchaseBtnClickEvent(params)
      const payload = {
        purchase_id: purchaseId,
      };
      dispatch?.payment?.requestInitiatePurchaseCourse(payload,(obj)=>{
        if(Object.keys(obj)?.length > 0){
          loadScript("https://checkout.razorpay.com/v1/checkout.js")
          var options = {
            key: RAZORPAY_KEY, //working
            amount: obj?.sub?.amount, 
            name: "Lifedemy",
            currency:obj?.sub?.currencyCode,
            description: course_details?.courseTitle,
            order_id:obj?.sub?.id,
            handler: function(response) {
              dispatch?.payment?.requestVerifyPayment(response,(value, data)=>{
                const { customerReceipt } = data;
                const eventParams ={
                  course_name: `${course_details?.courseTitle} | ${courseId} ` 
                }
                if(value===true){
                  PaymentSuccessEvent(eventParams)
                  history.push(
                    (state?.email || email) ? 
                    `${ROUTE_CONSTANTS.PAYMENT_SUCCESS}?_c=${courseId}&_e=${state?.email || email}&_p=${state?.mobileNumber}&_a=${totalOrderAmount}&_o=${obj?.sub?.id}` 
                    : 
                    `${ROUTE_CONSTANTS.PAYMENT_SUCCESS}?_c=${courseId}&_p=${state?.mobileNumber}&_a=${totalOrderAmount}&_o=${obj?.sub?.id}`)
                }else{
                  PaymentFailedEvent(eventParams)
                  history.push( { 
                    pathname:`${ROUTE_CONSTANTS.PAYMENT_ERROR}`,
                    state: { purchaseId:purchaseId,courseId:courseId,_e:state?.email || email}
                    })
                }
                setInProgress(false);
              })
            },
            modal: {
              ondismiss: function(){
                  setInProgress(false);
              }
            },
            prefill:{
                name:`${state?.firstName} ${state?.lastName}`,
                email:state?.email || obj?.email,
                contact: state?.mobileNumber,
            },
            notes: {
              address: "-",
              user_flow: "Normal Flow",
              purchase_details: JSON.stringify({ course_id: parseInt(courseId), user_id: parseInt(profile_details?.id) })
            },
            theme: {
              color: "#0E896A"
            }
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          paymentObject.on('payment.failed', function(response){
            const eventParams ={
              course_name: `${course_details?.courseTitle} | ${courseId}` 
            }
            PaymentFailedEvent(eventParams)
          })
        }
      });
   }

  const continuePurchase = async () => {
      if(!email){
        updatedEmail()
      }
      else{
        handleStartPurchase()
       }
  };

  const handleUpdateEmail = (email) => {
    if(email?.length ===0){
       return Notifier("DELETED","Please enter email")
    }
    if(email && EmailCheckRegEx(email)) {
      const data = {
        email: email
      };      
      const cb=()=>{
        setShow()
         const params = {
          event_label: course_details?.courseTitle
        }
        PaymentUpdateEmailSuccess(params)
        handleStartPurchase(email)
      }
      dispatch?.profile?.requestUpdateProfile(data,cb);
    } else {
      Notifier("DELETED", "Please enter a valid email");
    }
  };

  const getRating = () => {};
  return (
    <div className="container mx-auto min-h-screen h-full w-full px-2 lg:mt-28 mt-6 xl:px-[20rem] lg:px-[6rem] ">
      <UpdatedEmail 
         title="Please update your email to recieve purchase details"
         btnTitle="Update Email"
         show={show} 
         setShow={()=>setShow(false)} 
         handleStartPurchase={handleStartPurchase}
        //  courseTitle={course_details?.courseTitle}
         updateEmailClickAction={handleUpdateEmail}
      />
      {<Helmet  title={HELMET_CONSTANT?.PAYMENT}/> }
      {inProgress && (<Loader />) }
      {/* <div className="text-center lg:block hidden lg:mt-[3rem] lg:mb-[3rem] mt-6">
        <h2 className="payment-heading-style">
        {attributes?.heading}
        </h2>
        <LDParagraph className="payment-heading-attribute-style mt-6 inline-block tracking-wider leading-4">
         {attributes?.desc}
        </LDParagraph>
      </div> */}

      <Row gutter={50} className="block lg:flex">
        <Col lg={11}  md={24} sm={24} className="lg:ml-20">
          <LDParagraph
            className={`text-white my-2 lg:hidden ${
              isPaymentSuccess && "hidden"
            }`}
          >
            <Heading className="lg:mb-4">Purchase this course</Heading> {/*TODO - check for when payment is success*/}
          </LDParagraph>
          <div
            className={`bg-secondary p-2 lg:p-0 rounded lg:block mt-5  lg:mt-0  ${
              isPaymentSuccess && "hidden"
            }`}
          >   
          <div className="lg:block hidden">
            <Row gutter={3} className="">
              <Col lg={11} md={10} >
               <div className="pl-5 pt-6 object-contain">
                  <Image
                    src={course_details?.thumbnailUrl}
                    className="rounded-md h-full w-full"
                    dimension={PAYMENT_THUMB_DIMENSION}
                  />
               </div>
              </Col>
              <Col lg={13} md={14}>
              <div className="lg:pt-6 px-2 ">
                <Title className="payment-card-heading-style line-clamp ">
                  {course_details?.courseTitle}
                </Title>
                <LDParagraph className="payment-card-text-style mt-1">
                  {course_details?.totalEpisodeCount} episodes
                </LDParagraph>
                <div className="pointer-events-none">
                  <StarRating
                    isStarRatingActive={true}
                    getRating={getRating}
                    disabled={true}
                    rating={course_details?.overallRating}
                  />
                </div>
                
              </div>
              </Col>
            </Row>
          </div>

            <div className="flex mt-4 lg:hidden">
              <div className="w-1/2  xs:p-1 md:p-0 lg:hidden">
                <Image
                  src={course_details?.thumbnailUrl}
                  className="lg:rounded-t-md rounded-md h-full w-full"
                  imageCls={"w-full"}
                  dimension={PAYMENT_THUMB_DIMENSION}
                />
              </div>
              <div className="h-1/2 w-1/2 ml-4 lg:hidden">
                <Title className="payment-card-heading-style line-clamp mb-1 ">
                  {course_details?.courseTitle}
                </Title>
                <LDParagraph className="text-highLight">
                  {course_details?.totalEpisodeCount} episodes
                </LDParagraph>
                <div className="pointer-events-none">
                <StarRating
                  isStarRatingActive={true}
                  getRating={getRating}
                  disabled={true}
                  rating={course_details?.overallRating}
                />
                </div>
                
              </div>
            </div>

            <div className="text-white flex justify-end py-3 pr-4 ">
               <LDSpan className="bg-greeny flex items-center px-2 py-2 rounded ">
                {Number(offerPrice) > 0
                  ?
                    <>
                      <p className="flex items-center line-through decoration-greeny decoration-2">   
                         {currencySymbol}{originalOrderAmount}
                      </p>  
                      <p className="flex items-center pl-2 text-black">   
                        {currencySymbol}{offerPrice}
                      </p>                        
                  </>
                  :
                  <>
                    <p> {currencySymbol} {totalOrderAmount} </p>
                  </>
                }
              </LDSpan>
            </div>
          </div>

          <div className="my-8">
            <LDParagraph className="text-justify payment-card-text-gray-small hidden lg:block">
              <CourseDescription description={course_details?.courseDescription} />
            </LDParagraph>
          </div>
        </Col>

        <Col lg={10} md={24} sm={24} xs={24}>
          <div className={` lg:bg-card rounded-md`}>
            {/* {isPaymentSuccess ? (
              <PaymentSuccess email={state?.email} courseId={courseId}  />
            ) : (  */}
              <PurchaseCourse
                handlePurchase={continuePurchase}
                purchaseData={purchaseData}
                episodes={episodes}
                price={price}
                courseId={courseId}
                couponList={couponList}
                title={course_details?.courseTitle}
              />
            {/* )} */}
          </div>
        </Col>
      </Row>

      <div className="lg:hidden block">
        <FormButton
          onClick={continuePurchase}
          className={`outline-none rounded w-full bg-white  text-green-700 ${
            isPaymentSuccess && "hidden"
          }`}
        >
          Continue Purchase
        </FormButton>
      </div>

    </div>
  );
};

export default Payment;
