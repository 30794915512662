import CardImage from "components/Card/CardImage";
import { Subtitle, Title } from "stories/Typography";
import LinkTag from "stories/LinkTag";
import LDTooltip from "stories/LDTooltip";
import "globalStyle/globalStyle.css"
import "./index.css"
import MobileCard from "./MobileCard";
import useMedia from "hooks/deviceHelper/useMedia";

const Card = (props) => {
  const {
    cardImg,
    title,
    subtitle,
    content,
    action = undefined,
    noBg = false,
    route = undefined,
    className,
    playIcon,
    playStyle,
    twoColumn = false,
    imageCardCls,
    widthSet,
    handleClick,
    imageCls,
    cardCls,
    isWatchNowCard,
    thumbDimension = undefined,
  } = props;

  const cardContentCls = noBg
    ? "pt-2 w-full "
    : "bg-card lg:w-full lg:h-[8rem] xl:h-[12.125rem] lg:px-3 pl-5 lg:pt-5 lg:pb-3  xl:h-[6.7rem] 2xl:h-[7.4rem]";
  // const cardCls = twoColumn ? "flex bg-ternary p-3" : "";
  const rounded = twoColumn
    ? "lg:rounded-t-md lg:rounded-b-none rounded-md"
    : "lg:rounded-md rounded-md";
  const isMobile = useMedia();

  return (
    <div className={`border-1 border-secondary rounded-xl ${className}`}>
      <div className="md:block my-2">
        {isMobile ? (
          <MobileCard
            src={cardImg}
            isWatchNowCard={isWatchNowCard}
            playIcon={playIcon}
            action={action}
            title={title}
            content={content}
            dimension={thumbDimension}
            route={route}
            subtitle={subtitle}
            handleClick={() => handleClick(title)}
          />
        ) : (
          <>
          {/*TODO:Below refactor required*/}
            <LinkTag route={route} onClick={() => handleClick(title)}>
              <div
                className={`${cardCls ? cardCls : ""}${
                  twoColumn
                    ? "flex bg-ternary px-4 pt-2 pb-3 rounded-t-lg lg:block lg:p-0 "
                    : ""
                } `}
              >
                <div
                  className={`sm:w-4/5  ${
                    widthSet ? "md:w-full" : "w-[44%]"
                  } lg:w-full h-full`}
                >
                  <CardImage
                    src={cardImg}
                    className={`object-cover w-full h-full ${imageCardCls}  sm:rounded-md ${
                      playIcon && "lg:rounded-lg"
                    } ${rounded} `}
                    playIcon={playIcon}
                    playStyle={playStyle}
                    imageCls={imageCls}
                    dimension={thumbDimension}
                  />
                </div>

                <div className={cardContentCls}>
                  <LDTooltip title={title}>
                    <Title
                      className={`line-clamp ${
                        noBg ? "w-[7rem]" : "w-[9.1rem]"
                      }  lg:w-full card-Title`}
                    >
                      {title}
                    </Title>
                  </LDTooltip>
                  <Subtitle className="card-Subtitle lg:my-1 mt-1 ">
                    {subtitle}
                  </Subtitle>
                  <div className="lg:hidden block">
                    {" "}
                    {/*small screen */}
                    {content}
                  </div>
                  <div
                    className={` ${
                      noBg ? "lg:block hidden" : "lg:block hidden rounded-b-md"
                    }`}
                  >
                    {content}
                  </div>
                </div>
              </div>
            </LinkTag>
            {action && (
              <div className="flex items-center justify-between rounded-b-md bg-secondary lg:py-4 lg:pr-4 lg:pl-3 p-4 ">
                {action}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
