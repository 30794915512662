import React, { useEffect } from "react";
import "./style.css";
import CardImage from "components/Card/CardImage";
import { imagePath } from "utils/assetHelper";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { INVOICE_RELATED_DATE } from "constants/app.constants";
import LoadingSpinner from "stories/Loader/LoadingSpinner";
import { readableDateFormate } from "utils";
import useIpEffect from "hooks/useIpEffect";

const Invoice = () => {
  const dispatch = useDispatch();
  const { id: meetingId } = useParams();
  const {sessionPaymentInvoice, loading} = useSelector(
    (state) => state?.instructor
  );
  useIpEffect(() => {
    if (meetingId) {
      dispatch?.instructor?.getInvoiceForTheSession(meetingId);
    }
  }, [meetingId]);
  const transactionDetails = [
    {
      label: `Session With : ${
        (sessionPaymentInvoice?.instructor_name &&
          sessionPaymentInvoice?.instructor_name) ||
        "Lifedemy Instructor"
      }`,
      amount: ``,
    },
    {
      label: `${sessionPaymentInvoice?.total_order_amout && "MRP Amount" || ""}`,
      amount: `${sessionPaymentInvoice?.total_order_amout && `${sessionPaymentInvoice?.currency_symbol} ${sessionPaymentInvoice?.total_order_amout}` || ""}`,
    },
    {
      label: sessionPaymentInvoice?.country==="IN" &&`${
        (sessionPaymentInvoice?.country === "IN" && "Taxable Amount") ||
        "Discounted Amount"
      }`,
      amount: `${sessionPaymentInvoice?.order_amount && `${sessionPaymentInvoice?.currency_symbol} ${(sessionPaymentInvoice?.order_amount - sessionPaymentInvoice?.igst)}` || ""}`,
    },
    {
      label: `${
        (sessionPaymentInvoice?.country === "IN" && "GST Amount") || ""
      }`,
      amount: `${
        (sessionPaymentInvoice?.country === "IN" &&
          `${sessionPaymentInvoice?.currency_symbol} ${sessionPaymentInvoice?.igst}`) || ""}`,
    },
    {
      label: `Discount`,
      amount: `${
        (sessionPaymentInvoice?.total_saving &&
          `${sessionPaymentInvoice?.currency_symbol} ${sessionPaymentInvoice?.total_saving}`) ||
        "-----"
      }`,
    },
  ];

    const taxSummary = [
        {
        label:`${sessionPaymentInvoice?.cgst && "CGST" || ""}`,
        percentage:`9%`,
        amount:`${sessionPaymentInvoice?.cgst && sessionPaymentInvoice?.cgst || "" }`
    },
    {
        label:`${sessionPaymentInvoice?.sgst && "SGST" || ""}`,
        percentage:`9%`,
        amount:`${sessionPaymentInvoice?.sgst && sessionPaymentInvoice?.sgst || "" }`
    },
    {
        label:`IGST`,
        percentage:` `,
        amount:` `
    }
]

  return (
    <>
    {
      loading ? 
      <>
      <div className="flex justify-center items-center min-h-screen">
          <LoadingSpinner />
        </div>
      </> : <div className="invoice--container min-h-screen">
      <div className="content--wrapper">
        <div className="inovice--details">
          <div className="invoice--header flex flex-wrap justify-between">
            <div className="logo--block invoice--split">
              <CardImage src={imagePath("/Invoicelogo.svg")} />
            </div>
            <div className="invocie--number--date text-right">
              <h5 className="font-bold text-[16] mb-[5px]">
                {(sessionPaymentInvoice?.country === "IN" && "Tax Invoice") ||
                  "Invoice"}
              </h5>
              <ul className="font-medium text-[11px] text-[#676565]">
                <li>
                  Invocie No: <span>{sessionPaymentInvoice?.invoice_id}</span>
                </li>
                <li>
                  Order No: <span>{sessionPaymentInvoice?.id}</span>
                </li>
                <li>
                  Order Date: <span>{readableDateFormate(sessionPaymentInvoice?.created_at)}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="invoice--body flex flex-wrap justify-between">
            <div className="supplier--block ">
              <h5 className="font-bold text-[16] mb-[5px]">Address:</h5>
              <h6 className="max-w-[200px] text-[11px] font-medium text-[#676565]">
                TSMC Creations India Pvt.Ltd. Chennai,Tamil Nadu - 600096
                India
              </h6>
              <h6 className="max-w-[250px] text-[11px] font-medium text-[#676565]">
                {INVOICE_RELATED_DATE.GSTIN_NO}
              </h6>
            </div>
            <div className="reciepent--details invoice--split text-right">
              <h5 className="font-bold text-[16] mb-[5px]">
                Customer Details :
              </h5>
              <ul className="text-right font-medium text-[11px] text-[#676565]">
                <li>
                  Phone No:{" "}
                  <span>{sessionPaymentInvoice?.customer_phone}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="invoice--data">
            <table className="mt-[2rem]">
              <thead>
                <tr className="text-[13px] bg-[#F8F8F8]">
                  <th className="pl-[5px] p-[10px] text-left">Description</th>
                  <th className="pl-[5px] p-[10px] text-right">Amount</th>
                </tr>
              </thead>
              <tbody className="p-[2px]">
                {transactionDetails?.map((item) => (
                  <tr className="text-[12px]">
                    <td className="p-[2px]">{item.label}</td>
                    <td className="text-right">{item.amount}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="text-[12px] bg-[#F8F8F8] text-right">
                  <td className="pl-[5px] p-[10px]">TOTAL AMOUNT</td>
                  <td className="pr-[5px] p-[10px]">
                    {(sessionPaymentInvoice?.discounted_order_amount &&
                      `
                    ${sessionPaymentInvoice?.currency_symbol}
                    ${sessionPaymentInvoice?.discounted_order_amount}
                  `) ||
                      "-----"}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          {(sessionPaymentInvoice?.country === "IN" && (
            <div className="tax--summary mt-[2rem]">
              <div className="text-[12px] bg-[#F8F8F8] text-center tax--heading ">
                <h5 className="pl-[5px] text-[15px] font-bold p-[10px]">
                  Tax Summary
                </h5>
              </div>
              <table className="mt-[0rem]">
                <thead>
                  <tr className="text-[13px] bg-[#F8F8F8]">
                    <th className="pl-[5px] p-[10px] text-left">GST Type</th>
                    <th className="pl-[5px] p-[10px] text-right">
                      Percentage
                    </th>
                    <th className="pl-[5px] p-[10px] text-right">
                      Amount (₹)
                    </th>
                  </tr>
                </thead>
                <tbody className="p-[2px]  invoice--data">
                  {taxSummary?.map((item) => (
                    <tr className="text-[12px]">
                      <td className="p-[2px]">{item.label}</td>
                      <td className="text-right">{item.percentage}</td>
                      <td className="text-right">{item.amount}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  {/* <tr className="text-[12px] bg-[#F8F8F8] text-right">
                    <td className="pl-[5px] p-[10px]"></td>
                    <td className="pl-[5px] p-[10px]">TOTAL AMOUNT</td>
                    <td className="pr-[5px] p-[10px]">{sessionPaymentInvoice?.currency_symbol} {sessionPaymentInvoice?.total_item_amount}</td>
                  </tr> */}
                </tfoot>
              </table>
            </div>
          )) || <div className="h-[11.68rem] max-h-[11.68rem]"></div>}
          <div className=" signature--container flex flex-wrap justify-between items-end">
            <h4 className="text-[18px] font-medium auto--generate">
              This receipt is automatically generated
            </h4>
            <div className="signaturre--block">
              <CardImage src={imagePath("/authorsignature.png")} />
              <h4 className="text-[15px] font-medium mt-[1rem]">
                Authorised Signatory
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
      
    </>
  );
};

export default Invoice;
