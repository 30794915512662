import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import "./input.css"

import FormButton from "stories/FormButton";
import Input from "stories/Input";
import Image from "components/Image";
import { Input as LDNewInput } from "antd";

import { Heading, LDSmall, LDSpan, Title, LDParagraph } from "stories/Typography";
import { EmailCheckRegEx } from "utils";
import { debounce } from "utils/debounce";
import { imagePath } from "utils/assetHelper";
import LDImage from "stories/Image";
import { COLOR_CONSTANTS } from "constants/color.constants";
import ForgotPassword from "components/ForgotPassword";
import "./input.css";
import {
  signupCancelClickEvent,
  signupEmailInputClickEvent,
  signupEmailResetPwdClickEvent,
  signupEmailSigninBtnClickEvent,
} from "analytics/signupLogin";
import LDTooltip from "stories/LDTooltip";
import PasswordRules from "./PasswordRules";
import CreateProfile from "./CreateProfile";

const Email = ({ setAuthType,handleEmail }) => {
  const loginError = useSelector(
    (state) => state?.registerWithEmail?.loginError
  );
  const signupError = useSelector(
    (state) => state?.registerWithEmail?.signupError
  );
  const authLoader = useSelector(
    (state) => state?.registerWithEmail?.authLoader
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignIn, setIsSignIn] = useState(true);
  const [emailDirty, setEmailDirty] = useState(false);
  const [resetToggle, setResetToggle] = useState(false);
  const [rulesVisible, setRulesVisible] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [checkUserExists,setCheckUserExists] =useState(null)
  const [payload,setPayload] =useState("")
  const [toggleCreateAccount,settoggleCreateAccount]= useState(false)
  const passwordRef = useRef();
  const formRef = useRef();
  const [loader,setLoader] =useState(false)
  const [rulesError, setRulesError] = useState([])
  const [ isEmailValid, setEmailValid ] = useState(false);
  
  const dispatch = useDispatch();
  const actionBtnDisabled = !(
    !emailDirty &&
    email?.length &&
    EmailCheckRegEx(email) &&
    password?.length
  );
  const checkEmailExists = (val) => {
    setEmail(val);

    if (val && EmailCheckRegEx(val)) { 
       setLoader(true)
      dispatch?.registerWithEmail?.requestCheckUserExist(val, (exists) => {
        setIsSignIn(exists);
        setCheckUserExists(exists)
        setEmailValid(true);
        setLoader(false)
      });
      setEmailDirty(false);
    }else {
      setEmailDirty(false);
      setEmailValid(false);
    }
  }
  const handleEmailChange = useCallback(debounce(checkEmailExists, 800), [
    debounce,
    checkEmailExists,
  ]);

  //To refresh the error state once the user changes either email of password
  useEffect(() => {
    dispatch?.registerWithEmail?.setloginError({});
    dispatch?.registerWithEmail?.setSignUpError({});
    dispatch?.registerWithEmail?.setAuthLoader(false);
  }, [email, password]);

  useEffect(() => {
    if (passwordRef?.current?.input) {
      const focusHandler = () => {
        setRulesVisible(true);
      };
      const blurHandler = () => {
        setRulesVisible(false);
      }
      passwordRef?.current?.input?.addEventListener("focus", focusHandler);
      passwordRef?.current?.input?.addEventListener("blur", blurHandler);
      return () => {
        passwordRef?.current?.input?.removeEventListener("focus", focusHandler);
        passwordRef?.current?.input?.removeEventListener("blur", blurHandler);
      };  
    }
  }, [passwordRef]);

  const redirectCallback = () => {
    dispatch?.registerWithEmail?.setAuthLoader(false);
    dispatch?.authModel?.isLoggedIn(false);
    // location.reload();
  };

  const createAccount = () => {
    dispatch?.authModel?.isNotLoggedIn(false);
  };

  const onFormSubmit = (values) => {
    dispatch?.registerWithEmail?.setAuthLoader(true);
    let payload;
    if (isSignIn) {
        payload = {
          identifier: values.email?.toLowerCase()?.trim(),
          password: password
        };
        dispatch?.registerWithEmail?.requestEmailLogin(payload, redirectCallback);
    } else {
      const payload = {
        username: values?.email?.split('@')[0], // For genearting username
        email: values.email?.toLowerCase()?.trim()
      };
      settoggleCreateAccount(true)
      setPayload(payload)

      // dispatch?.registerWithEmail?.requestEmailSignUp(payload, createAccount);
    }
  };
  
  const handleResetPassword = () => {
    signupEmailResetPwdClickEvent();
    setResetToggle(true);
  };
  const handleKeyPress = (value, event) =>{
    event.preventDefault();
    if(event.key === 'Enter'){
      if(value === 'email'){
        passwordRef?.current?.input.focus()
      }else if(value === 'password'){
        formRef?.current.submit()
      }else{
        return
      }
    }
  }
  const onClickPasswordIcon = () =>{
    setPasswordShown(!passwordShown);
  }
  const renderNextLabel = (
    <LDSpan className="absolute" style={{transform: "translate(-50%,-50%)"}} >
      <LDSpan className="flex items-center auth-button-text-style">
        Next
      <Image 
      src={imagePath("/Banner/rightArrow.svg")}
      className="h-3 ml-2" alt=""/>
      </LDSpan>
    </LDSpan>
  )
  const renderImage = passwordShown ?  imagePath("/show.svg") : imagePath("/hide.svg") 
  return (
    <>
      {
      resetToggle ? (
        <ForgotPassword handleClick={() => setAuthType("")} />
      ) : (
        <>
        {toggleCreateAccount ?
          <CreateProfile payload={payload} setAuthType={setAuthType} />
        : <div className="p-4">
          {/* <LDSmall
            className="flex justify-end"
          >
            <span className="cursor-pointer underline"  onClick={() => {
              setAuthType("");
              signupCancelClickEvent();
            }}>
            <img src={imagePath("/cross.svg")} alt="crossImg" />
            </span>
          </LDSmall> */}
          <div className="flex flex-col pb-20">
            {/* <Heading className="text-center auth-modal-heading-style">Welcome</Heading> */}
            <div className="justify-center items-center flex pt-12">
              <LDImage src={imagePath("/logoLarge.svg")} alt="logo" className=""/>
            </div>
            <Title className="auth-modal-heading-style pt-10">Welcome!</Title>
            {/* <LDSmall className="opacity-50 mt-2 text-center">
              Login using email
            </LDSmall> */}
            <Form className="mt-6 text-center" ref={formRef} onFinish={(values) => onFormSubmit(values)}>
              {loginError ? (
                <LDSpan className="error-message-style">{loginError?.message}</LDSpan>
              ) : (
                ""
              )}
              {signupError ? (
                <LDSpan className="error-message-style">{signupError?.message}</LDSpan>
              ) : (
                ""
              )}
              <Form.Item
                name="email"
                className="mb-3 text-white"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                  { required: true, message: "Email Required" },
                ]}
              >
                <LDNewInput
                  type="email"
                  name="email"
                  style={{color:"white"}}
                  onPressEnter={(event) => handleKeyPress('email', event)}
                  value={email?.trim()}
                  onClick={() => signupEmailInputClickEvent()}
                  placeholder="Email"
                  autoFocus
                  onChange={(e) => {
                    setEmailDirty(true);
                    const val = e?.target?.value;
                    handleEmailChange(val?.toLowerCase()?.trim());
                  }}
                  className="bg-secondary rounded px-4 py-3 border border-formBorder w-3/4 auth-input-text-style"
                />
              </Form.Item>
              {checkUserExists && isEmailValid && 
                <LDTooltip title={<PasswordRules password={password} />} visible={!isSignIn && rulesVisible} placement="bottom">
              <Form.Item
                name="password"
              >
                <LDNewInput
                  ref={passwordRef}
                  type={passwordShown ? "text" : "password"}
                  onClick={() => signupEmailInputClickEvent()}
                  style={{ backgroundColor: COLOR_CONSTANTS.SECONDARY_COLOR }}
                  onPressEnter={(event) => handleKeyPress('password', event)}
                  placeholder="Password"
                  onChange={(e) => {
                    const val = e?.target?.value;
                    setPassword(val);
                  }}
                  className="bg-secondary relative rounded px- py-3 border border-formBorder w-3/4 auth-input-text-style"
                />
                <Image
                  src={renderImage}
                  icon={false}
                   onClick={() => setPasswordShown(!passwordShown)}
                  alt=""
                  className="h-6 w-6 cursor-pointer absolute  top-2 right-14"
                />
              </Form.Item>
              {/* {rulesError.map((_rules)=>{
                return <LDParagraph className="password-rules-error-style">{_rules.text}</LDParagraph>
              })} */}
              </LDTooltip>}

              {isSignIn ? (
                <div className="flex flex-col">
                  <FormButton
                    htmlType="submit"
                    className="bg-greeny relative hover:bg-greeny focus:bg-greeny active:bg-greeny active:text-white focus:text-white hover:text-white auth-button-text-style w-3/4 rounded mx-auto"
                    // loading={loader}
                    disabled={actionBtnDisabled}
                    onClick={() => signupEmailSigninBtnClickEvent({event_label:email})}
                  >
                   {isEmailValid ? "Sign In" : renderNextLabel }   
                  </FormButton>
                  <div className="mt-2">
                    <LDSmall className="auth-small-text-style-grey mr-2">
                      Forgot Password?
                    </LDSmall>
                    <LDSpan
                      className="cursor-pointer"
                      onClick={handleResetPassword}
                    >
                      Reset
                    </LDSpan>
                  </div>
                </div>
              ) : (
                <FormButton
                  htmlType="submit"
                  className="bg-greeny relative hover:bg-greeny focus:bg-greeny active:bg-greeny active:text-white focus:text-white hover:text-white auth-button-text-style w-3/4 rounded mx-auto"
                  // loading={loader}
                  onClick={() => signupEmailSigninBtnClickEvent({event_label:email})}
                  disabled={!isEmailValid}
                >
                  {isEmailValid ? "Create account" : renderNextLabel} 
                </FormButton>
              )}
            </Form>
          </div>
         </div>
          }
        </>
       )
      
      }
    </>
  );
};

export default Email;