import axios from "axios";
import { PRIVACY_POLICY_S3_URL } from "constants/Apis";
import { useEffect, useState } from "react";
import { Heading } from "stories/Typography";
import "./index.css";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState('');
  useEffect(async ()=>{
    const res = await axios.get(PRIVACY_POLICY_S3_URL);
    if(res && res.data){
      setPolicy(res.data);
    }
  },[])
  return (
    <div className="container mx-auto mb-5 min-h-screen">
      <div>
        <Heading className="text-center mt-[2rem]" style={{fontSize: "1.85rem"}}>Privacy Policy</Heading>
      </div>
     <div className="break-words lg:p-[2rem] lg:pt-[0.5rem] text-[5px] privacy-font-size" dangerouslySetInnerHTML={{__html: policy }}></div>
    </div>

  );
};

export default PrivacyPolicy;
