export const Watch_Trailer_Click = "Watch_Trailer_Click";
export const Watch_Episode_Click = "Watch_Episode_Click";
export const Video_Forward_Backward = "Video_Forward_Backward";
export const Video_Seek_30s = "Video_Seek_30s";
export const Video_Play = "Video_Play";
export const Video_Pause = "Video_Pause";
export const Video_Seek = "Video_Seek";
export const Video_Settings_Icon_Click = "Video_Settings_Icon_Click";
export const Video_PlayBack_Speed_Select = "Video_PlayBack_Speed_Select";
export const Video_Full_Screen_Toggle = "Video_Full_Screen_Toggle";
export const Video_Volume_Update = "Video_Volume_Update";
export const Video_Speed_Icon_Click= "Video_Speed_Icon_Click"; // (new) add to GTM event
export const Video_Quality_Option_Select = "Video_Quality_Option_Select" //(new) add to GTM event
export const Video_Completed= "Video_Completed" // (new) add to GTM event
//Video Overlay
export const Video_Play_First_Episode="Video_Play_First_Episode"; // (new) add to GTM event
export const Video_Replay = "Video_Replay" // (new ) add to GTM event
export const Video_Next_Episode="Video_Next_Episode"
export const Video_Feedback_Option_Select = "Video_Feedback_Option_Select"