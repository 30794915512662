const { LDAnalytics } = require("utils/analyticsHelper");

class SessionEvents extends LDAnalytics {
    event_Session_Reschedule_Start() { 
        return {
          event: {
            module_name: "My Session",
            feature_name: "My Session",
            event_name: "Session_Reschedule_Start",
          },
        };
      }

      event_Session_Reschedule_End() { 
        return {
          event: {
            module_name: "My Session",
            feature_name: "Instructor Session Reschedule",
            event_name: "Session_Reschedule_End",
          },
        };
      }
      event_Session_Cancel_Start() { 
        return {
          event: {
            module_name: "My Session",
            feature_name: "My Session",
            event_name: "Session_Cancel_Start",
          },
        };
      }
      event_Session_Cancel_End() { 
        return {
          event: {
            module_name: "My Session",
            feature_name: "My Session",
            event_name: "Session_Cancel_End",
          },
        };
      }
}


const sessionEvents = new SessionEvents();
export default sessionEvents;