import { rescheduleEndClickEvent } from "analytics/mysessions";
import { Modal } from "antd";
import { Notifier } from "components/Notifier";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { STATUS } from "constants/status.constant";
import { useQuery } from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LDImage from "stories/Image";
import { Heading } from "stories/Typography";
import { UTCTimeConverter, formatDate, getTimeDifference } from "utils";
import { imagePath } from "utils/assetHelper";

const ConfirmReschedulemodal = (props) => {
  const { handleClose, show, selectedSlotId, dataForReschedule, dataForMeeting, handleError, formatedSelectedDate} = props;
  const history = useHistory();
  const dispatch = useDispatch()
  const query = useQuery()
  const _meetingId = query?.get("_m")
  var { id: InstructorId} = useParams();
  const Headder = ({ children, className }) => {
    return (
      <h1
        className={`${className} text-center text-[1.4rem] font-bold `}
      >
        {children}
      </h1>
    );
  };

  const JoinButton = ({ children, className, onClick }) => {
    return (
      <button onClick={onClick} className={className}>
        {children}
      </button>
    );
  };

  const profileInfo = useSelector((state) => state?.profile?.profileDetails); 
  const instructorDetails = useSelector((state) => state?.instructor?.instructorDetails)
  useEffect(() => {
    dispatch?.instructor?.getInstructorDetails(InstructorId);
  }, [InstructorId]);

  const scheduledAndRescheduledTimeSlots = [
    {
    Heading:"Previous slots",
    StartDate:formatDate(dataForReschedule?.data?.start_time,"DDMMYYYY"),
    StartTime:UTCTimeConverter(dataForReschedule?.data?.start_time),
  },
  {
    Heading:"Current slots",
    StartDate:formatDate(formatedSelectedDate?.selectedSlot,"DDMMYYYY"),
    StartTime:UTCTimeConverter(formatedSelectedDate?.selectedSlot),
  }]
  
  const rescheduleEndEvent = () =>{
    const extraParams = {
      event_label : profileInfo?.mobileNumber || profileInfo?.email,
      instructor_name : dataForReschedule?.data?.instructor_name || dataForReschedule?.data?.instructorsId,
      session_info : JSON.stringify(scheduledAndRescheduledTimeSlots)
    }
  
    rescheduleEndClickEvent(extraParams)
  }

    // handle reschedule
    const handleReschedule = async (selectedSlotId) =>{
      const payloadForReschedule = {
        meeting_id:Number(_meetingId),
        slot_id:selectedSlotId
      }
        dispatch?.instructor?.requestRescheduleMeeting(payloadForReschedule,(data, status ,message)=>{
          if(STATUS?.includes(status)){
            rescheduleEndEvent()
            history.push(`${ROUTE_CONSTANTS?.BOOKING_CONFIRMED}/${_meetingId}/?_r=true&_i=${InstructorId}`)
          }else{
            Notifier(status, message)
            handleClose()
            const payload = {
              slot_id: selectedSlotId
            }
            dispatch?.instructor?.requestReleaseTimeSlots(payload,()=>{})
          }
        })    
      }

  return (
    <div className="">
      <Modal
        className=""
        visible={show}
        closable={false}
        onCancel={handleClose}
        centered
        width={400}
        footer={null}
        maskClosable={false}
        bodyStyle={{
          backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
          borderRadius: "10px",
        }}
        maskStyle={{
          backdropFilter: "blur(3px)",
          "-webkit-backdrop-filter": "blur(3px)",
        }}
      >
        <div className="">
            <Headder>
             <div className="text-white text-left font-['BWModelica-Medium'] leading-[1.6] mb-[2rem] text-[20px] ">Are you sure want to reschedule the session with <span className="text-greeny"> {dataForReschedule?.data?.instructor_name || instructorDetails?.[0]?.instructor_name}</span> ?</div>
             {/* {formatedSelectedDate?.selectedSlot && 
             <div className="flex justify-between">
              {scheduledAndRescheduledTimeSlots.map((data,idx)=>{
                return(
                  <div className="flex flex-col text-white text-[14px] mt-5">
                  <h6 className={data?.BgColor}> {data?.Heading} </h6> 
                  <div className="flex items-center gap-1 font-['BWModelica-Medium'] leading-tight my-[.6rem]"><LDImage className="w-[1.5rem]" src={imagePath("/calendar.svg")} /> {data?.Date}</div>
                  <div className="flex items-center gap-1 font-['BWModelica-Medium'] leading-tight"><LDImage className="w-[1.5rem]" src={imagePath("/clock.svg")} /> {data?.Time}</div>
                  </div>
                )
              })}
             </div>
              } */}
            </Headder>

            <div className="flex justify-between gap-[1rem] mt-5">
              <JoinButton
                className="bg-greeny text-white px-[2rem] w-full md:py-[.6rem] font-['BWModelica-Medium'] leading-tight py-[.8rem] rounded-lg text-[14px] font-medium text-center"
                onClick={handleClose}
              >
                No 
              </JoinButton>
              <JoinButton
                className=" bg-red-400 text-white w-full px-[2rem] md:py-[.6rem] font-['BWModelica-Medium'] leading-tight py-[.8rem] rounded-lg text-[14px] font-medium text-center"
                onClick={()=>handleReschedule(selectedSlotId)}                
              >
                Reschedule 
              </JoinButton>
            </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmReschedulemodal;
