import { breakpoint_configs } from "constants/config"

export const getWidthConfig=(width)=>{
    const widthArray = Object.keys(breakpoint_configs)
    for(let i=0; i< widthArray?.length;i++){
        if(width >= widthArray[i] && width <= widthArray[i+1]){
            return breakpoint_configs[widthArray[i+1]]    
        }else if(i === widthArray.length - 1){
            return breakpoint_configs[widthArray[i]] 
        }
    }
}

