import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { COLOR_CONSTANTS } from 'constants/color.constants';

const antIcon = (fontSize)=>(
  <LoadingOutlined
    style={{
      fontSize: fontSize ? fontSize :"4rem",
      color: COLOR_CONSTANTS["GREEN"],
      zIndex: 100,
    }}
    spin
  />
);

const LoadingSpinner = (props) => {
  const  { fontSize } =props
  return (
    <div>
      <Spin indicator={antIcon(fontSize)} {...props} />
    </div>
  );
};

export default LoadingSpinner;
