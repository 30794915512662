import { episodePostCommentClickEvent, episodeTextareaClickEvent } from "analytics/episode";
import React, {  useState } from "react";
import Textarea from "stories/Textarea";
import { LDSpan } from "stories/Typography";
import { getInitial } from "utils";

const AddComment = (props) => {
  const { handleSubmitComment,username="",id,text: eText,handleCancel,myRef,title, courseTitle} = props;
  const [text, setText] = useState(eText ? eText :"");
  const disabled = text.length === 0 ;
  const handleAddComment = () => {
    
    if(id){
      const body = {
         content:text,
         threadOf:id
      };
      const params ={event_label:text,course_name:courseTitle,episode_name:title }
      handleSubmitComment(body) //reply level0
      episodePostCommentClickEvent(params)
    }
    else{
      const body={
        content:text 
      }

      if(eText){
        const params ={event_label:`L1 - ${eText}`,course_name:courseTitle,episode_name:title }
        episodePostCommentClickEvent(params)
      }
     handleSubmitComment(body);
    }
    setText("");
  };
  const handleChange = (e) => {
    const attr = e.target.value;
    setText(attr);
  };
  const handleTextareaClick=()=>{
    if(eText){
      const params ={ event_label: `L1-edit-${eText}`, course_name:courseTitle ,episode_name: title}
      episodeTextareaClickEvent(params) // pass parameter for edit level 0
    } 
    else{
      const params ={ event_label:eText,  course_name:courseTitle ,episode_name: title}
      episodeTextareaClickEvent(params)
    }
  }
  const isPostEnabled = text && text.length;
  return (
    <div className="flex items-center my-4 lg:w-3/4" ref={myRef}>
      <div className="">
        <div className="w-10 h-10 md:h-10 md:w-10 lg:w-10 lg:h-10 2xl:w-14 2xl:h-14 rounded-full flex justify-center items-center bg-white">
          <LDSpan className=" text-black ">
            {getInitial(username)}
          </LDSpan>
        </div>
        
      </div>
      <div className="w-full">
      <div  className="relative w-full ml-4 border-2 border-formBorder rounded-lg  bg-[#272d35]">
        <Textarea
          placeholder="Post your comment"
          rows={1}
          style={{minWidth:'calc(100% - 6rem)', overflow :"hidden"}}
          value={text}
          onClick={handleTextareaClick}
          onChange={handleChange}
          className="relative outline-none placeholder-opacity-25 placeholder-italic text-white bg-[#272d35] opacity-[90] p-4 rounded-lg"
        ></Textarea>
        <button
          onClick={handleAddComment}
          disabled={disabled}
          className={`absolute bottom-4 rounded-sm text-sm py-2 px-[16px] ${isPostEnabled ? "bg-greeny text-white cursor-pointer" : "bg-greeny opacity-50 text-highLight" } right-3 px-2 py-1 rounded `}
        >
          Post
        </button>
      </div>
      {eText && <span onClick={()=>handleCancel(eText)} className="cursor-pointer text-white underline comments-options-comment-style ml-4 mt-1">Cancel</span>} 
      </div>
    </div>
  );
};
AddComment.defaultProps = {
  getInitial: () => {},
};

export default AddComment;
