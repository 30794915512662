import { LDAnalytics } from "utils/analyticsHelper.js";

class ProfileEvents extends LDAnalytics {
  event_Profile_Pic_Edit_Click() {
    return {
      event: {
        module_name: "Profile",
        feature_name: "Profile - Personal Details",
        event_name: "Profile_Pic_Edit_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Personal_Details_Edit_Click() {
    return {
      event: {
        module_name: "Profile",
        feature_name: "Profile - Personal Details",
        event_name: "Profile_Personal_Details_Edit_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Personal_Details_Input_Click() {
    return {
      event: {
        module_name: "Profile",
        feature_name: "Profile - Personal Details",
        event_name: "Profile_Personal_Details_Input_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Personal_Details_Dob_Click() {
    return {
      event: {
        module_name: "Profile",
        feature_name: "Profile - Personal Details",
        event_name: "Profile_Personal_Details_Dob_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Personal_Details_Save_Btn_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Personal Details",
        event_name: "Profile_Personal_Details_Save_Btn_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Email_Verify_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Email",
        event_name: "Profile_Email_Verify_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Email_Verify_Btn_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Email",
        event_name: "Profile_Email_Verify_Btn_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Email_Done_Btn_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Email",
        event_name: "Profile_Email_Done_Btn_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Phone_Verify_Icon_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Email",
        event_name: "Profile_Phone_Verify_Icon_Click",
        event_label: "Verify Phone",
      },
    };
  }
  event_Profile_Phone_Cancel_Btn_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Phone",
        event_name: "Profile_Phone_Cancel_Btn_Click",
        event_label: "Cancel Phone Verify",
      },
    };
  }
  event_Profile_Phone_Input_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Phone",
        event_name: "Profile_Phone_Input_Click",
        event_label: "Phone Input Click",
      },
    };
  }
  event_Profile_Phone_Select_Country_Code() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Phone",
        event_name: "Profile_Phone_Select_Country_Code",
        event_label: "",
      },
    };
  }
  event_Profile_Phone_Generate_Otp_Btn_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Phone",
        event_name: "Profile_Phone_Generate_Otp_Btn_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Phone_Otp_Input_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Phone",
        event_name: "Profile_Phone_Otp_Input_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Phone_Resend_Otp_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Phone",
        event_name: "Profile_Phone_Resend_Otp_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Phone_Otp_Verify_Btn_Click() {
    return {
      event: {
        module: "Profile",
        feature_name: "Profile - Verify Phone",
        event_name: "Profile_Phone_Otp_Verify_Btn_Click",
        event_label: "",
      },
    };
  }
  event_Profile_Logout_Btn_Click(){
    return {
      event:{
        module:"Profile",
        feature_name:"Profile Logout",
        event_name:"Profile_Logout_Btn_Click",
        event_label:""
      }
    }
  }
}

const profileEvents = new ProfileEvents();
export default profileEvents;
