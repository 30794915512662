const CardLoader = () => {
  return (
    <>
      <div className="max-w-sm rounded-md max-h-md">
        <div className="flex space-x-4 animate-pulse">
          <div className="flex-1 py-1 space-y-4">
            <div className="w-full h-40 bg-gray-500 rounded"></div>
            <div className="space-y-2">
              <div className="w-3/5 h-4 bg-gray-500 rounded"></div>
              <div className="w-2/5 h-4 bg-gray-500 rounded"></div>
              <div className="w-1/5 h-4 bg-gray-500 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardLoader;
