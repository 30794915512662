import { Modal } from "antd";


const AboutInstructorModal = (props) => {
  const { handleClose, show, children } = props;
  if (!show) {
    return null;
  }
  return (
    <Modal className="about--instructor" visible={show} onCancel={handleClose} {...props} >
      {children}
    </Modal>
  );
};
export default AboutInstructorModal;
