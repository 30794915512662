import { Radio } from "antd";
import { Fragment } from "react";
import HorizontalLine from "stories/HorizontalLine";
import { LDParagraph } from "stories/Typography";
import "./index.css"

const LDRadio = (props) => {
  const { selected, onChange, grpCls, options, className ,handleEventFire} = props;
  return (
    <Radio.Group onChange={onChange} value={selected} className={grpCls}>
      {options?.map((_item,index) => {
        const applyBorderRadius = index === 0 ? "rounded-t-lg" :""
        const applyBorderRadiusLast = options?.length === index + 1 ? "rounded-b-lg" : ""
        return (
          <Fragment key={_item?.id} >
            <div className={`${className} ${applyBorderRadius} ${applyBorderRadiusLast}`}  key={_item.id} onClick={()=>handleEventFire(_item?.value || "0")} >
              <LDParagraph className="setting-video-option-font-style">{_item?.label}</LDParagraph>
              <Radio value={_item?.value || "0" }></Radio>
            </div>
            {options?.length !== index + 1 && <HorizontalLine className="opacity-10" /> }
          </Fragment>
        );
      })}
    </Radio.Group>
  );
};

export default LDRadio;
