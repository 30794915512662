const CryptoJS = require('crypto-js');

const secretKey = 'kOVH6sdmkjWjRRkitc7rdxs01lwHzfr1';

// Function to encrypt data
function encryptPayload(text) {
  const cipherText = CryptoJS.AES.encrypt(text, secretKey).toString();
  return cipherText;
}

// Function to decrypt data
function decryptPayload(cipherText) {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedText;
}

const encryptBody = (body) => {
  return encryptPayload(JSON.stringify(body))
}

const decryptResponseData =(data)=>{
    return  JSON.parse(decryptPayload(data))
}

module.exports = { encryptBody, decryptResponseData }