export const SESSION_PAYMENT_START = "Session_Payment_Start";
export const SESSION_PAYMENT_SUCCESS = "Session_Payment_Success";
export const SESSION_PAYMENT_FAIL = "Session_Payment_Failed";
export const SESSION_PAYMENT_CANCEL = "Session_Payment_Cancel";
export const SESSION_GUEST_PAYMENT_START = "Session_Guest_Payment_Start";
export const SESSION_GUEST_PAYMENT_SUCCESS = "Session_Guest_Payment_Success";
export const SESSION_GUEST_PAYMENT_FAIL = "Session_Guest_Payment_Failed";
export const SESSION_GUEST_PAYMENT_CANCEL = "Session_Guest_Payment_Cancel";
export const HOME_BOOK_A_SESSION_CLICK = "Home_Book_A_Session_Click";
export const INSTRUCTORS_BOOK_A_SESSION_CLICK = "Instructors_Book_A_Session_Click";
