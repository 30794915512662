const model = {
  state: {
    show: false,
    showResetPassword:false,
    logoutModActive: false
  },

  reducers: {
    setNotLoggedIn: (state, payload) => {
      return {
        ...state,
        show: payload, //true
      };
    },
    setLoggedIn: (state, payload) => {
      return {
        ...state,
        show: payload, //false
      };
    },
    setResetPassword :( state,payload) =>{
      return {
        ...state,
        showResetPassword:payload
      }
    },
    setLogoutMod : (state, payload) => {
      return {
        ...state,
        logoutModActive: payload
      }
    }
  },

  effects: (dispatch) => ({
    async isNotLoggedIn(payload) {
      dispatch?.authModel?.setNotLoggedIn(payload);
    },
    async isLoggedIn(payload) {
      dispatch?.authModel?.setLoggedIn(payload);
    },
    async resetPassword(payload){
      dispatch?.authModel?.setResetPassword(payload)
    },
    async toggleLogoutModal(payload){
      dispatch?.authModel?.setLogoutMod(payload)
    }
  }),
};
export default model;
