
import { useEffect } from "react";
import LoadingSpinner from "stories/Loader/LoadingSpinner";

import StarRating from "stories/StarRating";
import { LDParagraph, LDSpan, Title ,LDSmall} from "stories/Typography";
import { getReadableDate } from "utils";
import "./index.css"
const Reviews = (props) => {
  const {
    lastReviewed,
    name,
    isEditable,
    review,
    size,
    handleReviewEdit,
    rating,
    id,
  } = props;
  const updatetime = getReadableDate(lastReviewed) ?  getReadableDate(lastReviewed) : <LoadingSpinner fontSize="1rem" className="pl-5 pr-5" />  
  return (
    <div className="lg:my-4 my-4 md:mb-4">
      <Title className="text-white text-lg review-name-text-style">
        {name?.trim() ? name  :"No name"}
      </Title>
      <div className="my-1 pointer-events-none">
      {rating > 0 && (
        <StarRating
          isStarRatingActive={true}
          disabled={true}
          size={`13px`}
          rating={rating}
          // marginRight="rating_"
        />
      )}
      </div>
      <LDParagraph className=" mt-1 text-highLight text-justify review-title-style ">
        {review}
      </LDParagraph>
      <LDSmall className="m-0 text-highLight italic opacity-50 flex items-center">
        Reviewed  <span className="ml-1"> {updatetime} </span> 
        {review && isEditable && (  
        <LDSmall
          onClick={()=>handleReviewEdit(review)}
          className="italic text-highLight m-0 pl-2 cursor-pointer underline mt-1"
        >
          Edit
        </LDSmall>
      )}
      </LDSmall>
     
    </div>
  );
};
Reviews.defaultProps = {
  lastReviewed: "",
  name: "",
  review: "",
  size: "",
  isLastAdded: false,
  handleReviewEdit: () => {},
};
export default Reviews;
