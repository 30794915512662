import * as eventConstants from "./events.constants";
import instructorEvents from "./InstructorEvents"

const handleEvent  = (eventCode, extraParams={}) => {
    instructorEvents.handle_analytics_event(eventCode, extraParams);
};

export const sessionPaymentStartEvent = (extraParams ={}) =>{
    const eventCode = eventConstants?.SESSION_PAYMENT_START
    handleEvent(eventCode, extraParams) 
}


export const sessionPaymentSuccessEvent = (extraParams ={}) =>{
    const eventCode = eventConstants?.SESSION_PAYMENT_SUCCESS
    handleEvent(eventCode, extraParams) 
}

export const sessionPaymentCancelEvent = (extraParams ={}) =>{
    const eventCode = eventConstants?.SESSION_PAYMENT_CANCEL
    handleEvent(eventCode, extraParams) 
}

export const sessionPaymentFailEvent = (extraParams ={}) =>{
    const eventCode = eventConstants?.SESSION_PAYMENT_FAIL
    handleEvent(eventCode, extraParams) 
}


export const guestSessionPaymentStartEvent = (extraParams ={}) =>{
    const eventCode = eventConstants?.SESSION_GUEST_PAYMENT_START
    handleEvent(eventCode, extraParams) 
}


export const guestSessionPaymentSuccessEvent = (extraParams ={}) =>{
    const eventCode = eventConstants?.SESSION_GUEST_PAYMENT_SUCCESS
    handleEvent(eventCode, extraParams) 
}

export const guestSessionPaymentFailEvent = (extraParams ={}) =>{
    const eventCode = eventConstants?.SESSION_GUEST_PAYMENT_FAIL
    handleEvent(eventCode, extraParams) 
}


export const guestSessionPaymentCancelEvent = (extraParams ={}) =>{
    const eventCode = eventConstants?.SESSION_GUEST_PAYMENT_CANCEL
    handleEvent(eventCode, extraParams) 
}

export const bookASessionClickEvent = (extraParams ={}, home=false) =>{
    const eventCode = home ?  eventConstants?.HOME_BOOK_A_SESSION_CLICK : eventConstants?.INSTRUCTORS_BOOK_A_SESSION_CLICK
    handleEvent(eventCode, extraParams) 
}
