import { profileEmailVerifyClickEvent, profilePhoneVerifyIconClickEvent } from "analytics/profile";
import Image from "components/Image";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useHistory } from "react-router-dom";
import HorizontalLine from "stories/HorizontalLine";
import { LDParagraph, LDSmall } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import "../index.css"

const ContactDetails = (props) => {
  const { email, phone, confirmed, isEmailVerified } = props;

  const history = useHistory();
  const handleClick = () => {
    profileEmailVerifyClickEvent({ event_label: email })
    history.push({
      pathname: `/${ROUTE_CONSTANTS.PROFILE_VERIFY_EMAIL}`
    });
  };
  const handlePhoneNumber = () => {
    profilePhoneVerifyIconClickEvent()
    history.push(`/${ROUTE_CONSTANTS.PROFILE_VERIFY_PHONE}`);
  };
  const handleEmailClick = () => {
    history.push(`/${ROUTE_CONSTANTS.PROFILE_ADD_EMAIL}`)
  }

  return (
    <div className="">
      <LDSmall className="profile-personal-details-style">Contact Details</LDSmall>
      <div className="my-2 border-2  border-none rounded-large">
          {email ?
            <div className={`flex justify-between items-center xs:bg-logoColor sm:bg-ternary lg:bg-sidebarSelected  py-3 px-2 rounded-t-lg ${!isEmailVerified && "cursor-pointer" } `}
              onClick={!isEmailVerified && handleClick}
             >
              <div className="flex items-center">
                <Image src={imagePath("/Profile/email.svg")} icon={true} className="ml-2"/>
                <LDParagraph className="ml-2 profile-contact-style">{email}</LDParagraph>
              </div>
              <div
                className="flex items-center cursor-pointer"
              >
                {
                  isEmailVerified ? "" :
                    <>
                      <LDParagraph className="text-greeny mr-3  profile-contant-verify-style">Verify</LDParagraph>
                      <Image src={imagePath("/Banner/rightArrow.svg")} icon={true} className="h-4 mr-2" />
                    </>
                }
              </div>
            </div>
            :
            <div className={`flex justify-between items-center xs:bg-logoColor sm:bg-ternary lg:bg-sidebarSelected  py-3 px-2 rounded-t-lg cursor-pointer`}
              onClick={handleEmailClick}
            >
             <div className="flex items-center">
                <Image src={imagePath("/Profile/email.svg")} icon={true} className="ml-2" />
                <LDParagraph className="ml-2 profile-contact-style">Email address</LDParagraph>
              </div>
              <div
                className="flex items-center cursor-pointer"
              >
                <Image
                  src={imagePath("/Banner/rightArrow.svg")}
                  icon={true}
                  className="h-4 mr-2"
                />
              </div> 
             </div>
          }
        <HorizontalLine className="opacity-10" />
          {phone ?
              <div
                className={`flex justify-between items-center xs:border:2 xs:rounded-lg xs:bg-logoColor sm:bg-ternary lg:bg-sidebarSelected  py-3 px-2 rounded-b-lg`}
              >
                <div className="flex items-center">
                  <Image src={imagePath("/Profile/phone.svg")} icon={true} className="ml-2" />
                  <LDParagraph className="ml-2 ">{phone}</LDParagraph>
                </div>
              </div>
            :
            <div
                className={`flex justify-between items-center xs:border:2 xs:rounded-lg xs:bg-logoColor sm:bg-ternary lg:bg-sidebarSelected  py-3 px-2 rounded-b-lg cursor-pointer`}
                onClick={handlePhoneNumber}
             >

              <div className="flex items-center">
                <Image src={imagePath("/Profile/phone.svg")} icon={true} className="ml-2" />
                <LDParagraph className="ml-2 profile-contact-style">Phone number</LDParagraph>
              </div>
              <Image
                src={imagePath("/Banner/rightArrow.svg")}
                icon={true}
                className="h-4 cursor-pointer mr-2"
              />
            </div>
            }
        </div> 
      </div>
  );
};

export default ContactDetails;
