import * as eventConstants from "./events.constants";
import videoPlayerEvents from "./VideoPlayerEvents";

const handleEvent = (eventCode, extraParams = {}) => {
  videoPlayerEvents.handle_analytics_event(eventCode, extraParams);
};

export const watchTrailerEvent = (extraParams) => {
  const eventCode = eventConstants?.Watch_Trailer_Click;
  handleEvent(eventCode, extraParams);
};

export const watchEpisodeEvent = (extraParams) => {
  const eventCode = eventConstants?.Watch_Episode_Click;
  handleEvent(eventCode, extraParams);
};

export const videoForwardBackwardEvent = (extraParams={}) => {
  const eventCode = eventConstants?.Video_Forward_Backward;
  handleEvent(eventCode, extraParams);
};

export const videoSeek30sEvent = (extraParams={}) => {
  const eventCode = eventConstants?.Video_Seek_30s;
  handleEvent(eventCode, extraParams);
};

export const videoPlayEvent = (extraParams={}) => {
  const eventCode = eventConstants?.Video_Play;
  handleEvent(eventCode, extraParams);
};

export const videoPauseEvent = (extraParams={}) => {
  const eventCode = eventConstants?.Video_Pause;
  handleEvent(eventCode, extraParams);
};

export const videoSeekEvent = (extraParams={}) => {
  const eventCode = eventConstants?.Video_Seek;
  handleEvent(eventCode, extraParams);
};
// Started from here
export const VideoSpeedIconEvent =(extraParams)=>{
  const eventCode = eventConstants?.Video_Speed_Icon_Click
  handleEvent(eventCode,extraParams)
}

export const VideoQualityOptionSelectEvent=(extraParams)=>{
  const eventCode = eventConstants?.Video_Quality_Option_Select
  handleEvent(eventCode,extraParams)
}
export const VideoCompletedEvent=(extraParams)=>{
  const eventCode = eventConstants?.Video_Completed
  handleEvent(eventCode,extraParams)
}
//End here
export const videoSettingsClickEvent = (extraParams={}) => {
  const eventCode = eventConstants?.Video_Settings_Icon_Click;
  handleEvent(eventCode, extraParams);
};

export const videoPlaybackSpeedUpdate = (extraParams={}) => {
  const eventCode = eventConstants?.Video_PlayBack_Speed_Select;
  handleEvent(eventCode, extraParams);
};

export const videoFullScreenEvent = (extraParams={}) => {
  const eventCode = eventConstants?.Video_Full_Screen_Toggle;
  handleEvent(eventCode, extraParams);
};

export const videoVolumeUpdateEvent = (extraParams={}) => {
  const eventCode = eventConstants?.Video_Volume_Update;
  handleEvent(eventCode, extraParams);
};

//Video Overlay
export const  VideoPlayFirstEpisodeEvent =(extraParams)=>{
  const eventCode = eventConstants?.Video_Play_First_Episode;
  handleEvent(eventCode, extraParams);
}

export const VideoReplayEvent =(extraParams)=>{
  const eventCode = eventConstants?.Video_Replay;
  handleEvent(eventCode, extraParams);
}

export const VideoNextEpisodeEvent =(extraParams)=>{
  const eventCode = eventConstants?.Video_Next_Episode;
  handleEvent(eventCode, extraParams);
}
export const VideoFeedbackOptionSelectEvent=(extraParams)=>{
  const eventCode = eventConstants?.Video_Feedback_Option_Select;
  handleEvent(eventCode, extraParams);
}