import FormComponent from "./FormComponent"
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const AddReview = (props) => {
  
  const { SubmitOnlyRating,SubmitReviewRating,isPurchased, mrp ,paramId,courseTitle,courseSlug,offer_price}=props
  const [isRatingValueObj,setIsRatingValueObj]=useState({})

  const params = useParams();
  const dispatch = useDispatch();

  const review_details = useSelector((state) => state?.course?.review_details);

  useEffect(()=>{
    const ratingObj = review_details?.find((_items)=>{
      return _items?.isEditable === true
    })  
    setIsRatingValueObj(ratingObj)
  },[review_details])

  const handleRatingSubmit = (value)=>{
    SubmitOnlyRating(value)
  }  

  //Function for use select write a review
  const getReviewObject=(review,rating)=>{
    SubmitReviewRating(review,rating)  
  } 
  return (
    <div>
        <FormComponent handleRatingSubmit ={handleRatingSubmit} courseSlug={courseSlug} paramId={paramId} ratingValue={isRatingValueObj?.rating} offer_price={offer_price} getReviewObject={getReviewObject} isPurchased={isPurchased} mrp={mrp} courseTitle={courseTitle}/>
    </div>
  )
}

export default AddReview