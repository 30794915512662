import { VideoFeedbackOptionSelectEvent } from "analytics/videoPlayer";
import Image from "components/Image";
import { useQuery } from "hooks/useQuery";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import FormButton from "stories/FormButton";
import { Heading, Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { requestFeedbackOnEpisodes } from "../actions/api";
import NextButton from "./NextButton";
import ReplayButton from "./ReplayButton";

const FeedbackOverlay = ({ nextAllowed, handleNext, handleReplay, feedbackAllowed = true,episodeTitle,courseTitle, nextBtnLabel ,isfullScreen,episodeId,courseId}) => {
  const [selected,setSelected] = useState("")

  const [isFeedbackGiven, setIsFeedbackGive] = useState(false)

  const params = useParams()
  const dispatch = useDispatch()

  const [feedbackList,setFeedbackList] = useState([
    { id: 1, src: imagePath("/Feedback/sad.svg") ,value:"bad" },
    { id: 2, src: imagePath("/Feedback/neutral.svg") ,value:"neutral" },
    { id: 3, src: imagePath("/Feedback/happy.svg") ,value:"good"},
  ])
  
  const cb=(status)=>{
    setIsFeedbackGive(true)
  }
  const handleSelect=(id,value)=>{
    setSelected(id)
    const payload={
      courseId : courseId,
      epsiodeId : episodeId,
      feedback:value
    }
    const event_params ={
      course_name:courseTitle,
      episode_name:episodeTitle,
      event_label:value
    }
    VideoFeedbackOptionSelectEvent(event_params)
    requestFeedbackOnEpisodes(payload,cb)
  }
  
  const chageImage=(id)=>{
      let src=""
      if(id === 1){
          src = imagePath("/Feedback/sadFilled.svg")
      }
      else if(id === 2){
          src = imagePath("/Feedback/neutralFilled.svg")
      }
      else{
          src = imagePath("/Feedback/happyFilled.svg")
      }
      return src
  }
  const backgroundCls = feedbackAllowed ? "backdrop-blur bg-black/70" : "";
  const  buttonAligment = isfullScreen ? "top-[40%]" : "left-0 right-0 lg:bottom-[30%] md:bottom-[25%] bottom-[10%] mx-auto"
  
  return (
    <div
      className={`absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25 ${backgroundCls}`}
    >
      {feedbackAllowed && (
        <>
        {isFeedbackGiven
         ?
          <div className="text-center mt-2 lg:mt-20">
            <Heading className="text-center pt-12 md:pt-40 lg:pt-20">
              Thank you for your feedback.
            </Heading>
          </div>
         :
         <div>
          <Heading className="text-center pt-8 md:pt-40 lg:pt-20">
            Give us your feedback!
          </Heading>
          <div className="text-center mt-2 lg:mt-20">
            {feedbackList?.map(_items => {
              const result =
                selected == _items?.id ? chageImage(_items?.id) : _items.src;
              return (
                <Image
                  src={result}
                  icon={false}
                  alt=""
                  className={`cursor-pointer mx-4 md:mx:6 lg:mx-8 inline-block  ${ selected != _items?.id && "last:mt-[-7px] last:h-12"} `}
                  onClick={() => handleSelect(_items.id, _items.value)}
                />
              );
            })}
          </div>
        </div>
        }
      </>
      )}
      <div className={`absolute w-full ${buttonAligment}`} >
        <div className=" flex justify-center">
            <div className="lg:mr-[16rem] mr-[4rem] ml-2">
              <ReplayButton handleReplay={handleReplay} />
            </div>
            <div className="lg:ml-[2rem] ml-[1.3rem]">
              {nextAllowed && <NextButton handleNext={handleNext} nextBtnLabel={nextBtnLabel} />}
            </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackOverlay;
