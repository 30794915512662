import { Fragment } from "react";
import CardLoader from "stories/SkeletonLoader/CardLoader";

const MultiLoadingCards = ({count}) => {
  return <Fragment>{[...Array(count)]?.map((item, key) => {
      return <CardLoader key={key} />
  })}</Fragment>;
};

MultiLoadingCards.defaultProps = {
    count: 1
}

export default MultiLoadingCards;
