import { Fragment,  useCallback,  useState, useEffect } from "react";

import EpisodesCard from "../EpisdoesCard";
import useMedia from "hooks/deviceHelper/useMedia";
import { getMinutes, getSeconds } from "utils";
import "globalStyle/globalStyle.css";

import PurchaseModal from "components/PurchaseModal";
import MultiLoadingCardList from "components/MultiLoadingCardList";
import { paymentInitiatePurchaseClickEvent } from "analytics/payment";
import HomeError from "pages/Home/components/Errors";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CoursePaidEpisodeClick } from "analytics/course";
import { EpisodePurchasedEpisodeClick } from "analytics/episode";

const CourseList = (props) => {
  const { episode_details, mrp,courseId, cls ,title,episodeLoading , isPurchased,handleFireEvent,courseSlug,
    getCurrentEpisodeName,offer_price,fromEpisode, currencySymbol } = props;
  const [show, setShow] = useState(false);
  const [currentEpisodeName, setCurrentEpisodeName] = useState("")
  const episodeListError  = useSelector((state) => state?.course?.episodeListError);
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const dispatch =useDispatch()
  const isMobile = useMedia()
  useEffect(()=>{
    getCurrentEpisodeName(currentEpisodeName)
  },[currentEpisodeName])
  const { EPISODE_LISTING_THUMB_DIMENSION } = appConfig;
  const handleShow = useCallback((name,isFree) => { 
    const params = {
      event_label:name,
      course_name:title,
    }
    const fireEvent =(func)=>{
        if(!isFree && !isPurchased){
          ()=> func()
        }
    }
    if(fromEpisode){  
      fireEvent(EpisodePurchasedEpisodeClick(params))
    } 
    else{
      fireEvent(CoursePaidEpisodeClick(params))
    }
    setCurrentEpisodeName(name)
    setShow(true);

  },[setShow])
  const handleClose = () => {
    setShow(false);
  };
  const scrollerHide = !isMobile && "overflow-scroll hide-scrollbar-for-episodeList"
  return (
    <div
      className={`my-2  lg:mt-6 overflow-y-auto ${scrollerHide} lg:block`}
    >
        {!episodeLoading && episodeListError && <HomeError action={()=>{
                dispatch?.course?.setEpisodeLoading(true);
                setTimeout(()=>{
                dispatch?.course?.requestEpisodesDetails(courseId);
                },800);
            }} />}

      { episodeLoading && episode_details?.length === 0 && <MultiLoadingCardList count={4} /> }

      {episode_details?.map((_list, i, episodes) => {
        const { duration, episodeId, episodeNumber, isFree, episodeUrl, thumbnailUrl, title,slug, lastWatchedLocation } = _list;
        const watched = Math.round((lastWatchedLocation / duration) * 100) // %
        return (
          <Fragment key={i}>
            <EpisodesCard
              src={thumbnailUrl}
              episodeUrl={episodeUrl}
              episodeId={episodeId}
              courseId={courseId}
              title={title}
              isPurchased={isPurchased}
              minutes={
                duration < 60
                  ? `${duration}s`
                  : getMinutes(duration)+"m"+" "+getSeconds(duration)
              }
              index={i + 1}
              isFree={isFree}
              handleShow={handleShow}
              handleFireEvent={handleFireEvent}
              courseSlug={courseSlug}
              episodeSlug={slug}
              episodeLength={episodes?.length}
              watched={watched}
              thumbDimension={EPISODE_LISTING_THUMB_DIMENSION}
            />
          </Fragment>
        );
      })}
      <PurchaseModal show={show} handleClose={handleClose} offer_price={offer_price} courseId={courseId} mrp={mrp} currencySymbol={currencySymbol} courseSlug={courseSlug} title={currentEpisodeName}  handleGTMEventCapture={()=>paymentInitiatePurchaseClickEvent({event_label:title})}/>
    </div>
  );
};

export default CourseList;