import { Fragment, useEffect } from "react";
import { Heading, LDSpan} from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import Input from "stories/Input";
import Card from "components/Card";
import StarRating from "stories/StarRating";
import Duration from "components/Card/Duration";
import { getHours, getMinutes, getRoundedVal, getSeconds } from "utils";
import { useDispatch, useSelector } from "react-redux";
import MultiLoadingCards from "components/MultiLoadingCards";
import { notification } from "antd";
import LDImage from "stories/Image";
import useMedia from "hooks/deviceHelper/useMedia";
import { Helmet } from "components/Helmet";
import { HELMET_CONSTANT } from "constants/helmet.constants";
import { wishlistCourseCardClickEvent, wishlistCourseCardWishlistClickEvent, wishlistLoadEvent, wishlistFilterChangeEvent } from "analytics/wishlist";
import "globalStyle/globalStyle.css"

const Whishlist = () => {
  const dispatch = useDispatch();
  const { wishlistData, loading } = useSelector((state) => state?.wishlist);
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const profile = useSelector((state) => state?.profile?.profileDetails);

  const { WISHLIST_MY_COURSES_THUMB_DIMENSION } = appConfig;
  const { childMode } = profile;
  const Mobile = useMedia();
  useEffect(() => {
    wishlistLoadEvent()
    dispatch?.wishlist?.requestWishlistDetails();
    wishlistLoadEvent(true)
  }, [childMode]); 

  const successMsg=(key,param)=>{
    notification[key]({
      message: key,
      description: param,
    });
  }
  const getRating = () => {};
  const handleWishList = (courseId,courseTitle) => {
    const params = {
      event_label : `Remove`, //${courseTitle} -
      course_name: courseTitle
    }
    wishlistCourseCardWishlistClickEvent(params)

    dispatch?.commonParams?.requestAddToWishlist({courseId: courseId, type:"remove",courseTitle:courseTitle },()=>{
       dispatch?.wishlist?.removeFromWishlist(courseId)
    })
  };
  const handleFilterWishlist = (value) => {
    handleSearchClick(value)
    dispatch?.wishlist?.filterWishlist(value);
  };
  const handleSearchClick=(value)=>{
    const params ={event_label:`${value}`}
    wishlistFilterChangeEvent(params)
  }
  const handleClick=(val)=>{
    const params ={course_name : `${val}`}
    wishlistCourseCardClickEvent(params)
   }
  return (
    <div className="container mx-auto mt-2 min-h-screen">
      {<Helmet  title={HELMET_CONSTANT?.WISHLIST}/> }
      <div className="text-center">
        <div className="lg:mt-[3.8rem] mt-3 lg:mb-10 mb-3">
          <Heading className="heading-font-style">Wishlist</Heading>
        </div>
        {/* <div className=" my-4 lg:my-10 mx-10 lg:mx-40 md:mx-20">
          <Input
            icon={true}
            type="search"
            src={imagePath("/searchIcon.svg")}
            placeholder="Search in wishlisted courses"
            iconStyle={"left-6 top-4 "}
            normalStyle={
              "bg-secondary h-12 text-sm outline-none px-16 rounded-md lg:rounded-full w-full"
            }
            handleChange={handleFilterWishlist}
            onClick={handleSearchClick}
            disabled={loading}
          />
          
        </div> */}
        {wishlistData?.length === 0 && !loading && 
           <div  className=" absolute top-[30%] left-[30%] lg:top-[40%] lg:left-[43%] scale-x-{-50%}, and scale-y-{-50%}">
             <Heading>Nothing in Wishlist.</Heading>
           </div>
         }

      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  xl:grid-cols-6 2xl:grid-cols-6 gap-x-8  lg:gap-y-8 xl:ml-8 xl:mr-8 lg:ml-16 lg:mr-12 mx-1">
      {/* Left and Right side spacing */}
        {loading && wishlistData?.length ? <MultiLoadingCards count={3} /> : ""}
        {wishlistData?.map((_list) => {
          const {
            courseDescription,
            thumbnailUrl,
            courseId,
            courseTitle,
            courseRating,
            totalEpisodeDuration,
            mrp,
            totalEpisodeCount,
            trailerUrl,
            views,
          } = _list;
          const content = (
            <div className="pointer-events-none">
            <StarRating
              isStarRatingActive={true}
              getRating={getRating}
              disabled={true}
              rating={courseRating}
            />
            </div>
          );
          const action = (
            <Fragment>
              <LDSpan className={`text-white font-modellicaBold card-Subtitle`}>
                {totalEpisodeCount} episodes
              </LDSpan>
              <LDImage
                src={imagePath("/Home/filledHeart.svg")}
                alt="asve"
                className="cursor-pointer"
                onClick={()=> handleWishList(courseId,courseTitle)}
              />
            </Fragment>
          );
          return (
            <Fragment key={courseId}>
            <Card
              cardImg={thumbnailUrl || imagePath("/Mycourse/mycourseCard.png")}
              title={courseTitle || ""}
              content={content}
              noBg={false}
              subtitle={
                <Duration
                  hours={ totalEpisodeDuration && getHours(totalEpisodeDuration)}
                  minutes={ totalEpisodeDuration && getMinutes(totalEpisodeDuration)}
                  seconds={totalEpisodeDuration && getSeconds(totalEpisodeDuration)}
                  className="font-modellicaBold text-[10px] font-[900]"
                />
              }
              route={`/course/${courseId}`}
              action={action}
              widthSet={true}
              twoColumn={true}
              cardCls={"flex md:flex md:bg-ternary md:p-3 lg:p-0 lg:block"}
              // imageCardCls="lg:h-20v h-10v"
              imageCardCls="h-full"
              imageCls={`w-full sm:h-[6rem] xs:h-[5rem] md:h-[5rem] lg:h-[8.375rem] ${window?.innerWidth >= 2400 && "lg:h-[10.375rem]"} `}
              handleClick={handleClick}
              thumbDimension={WISHLIST_MY_COURSES_THUMB_DIMENSION}
              isWatchNowCard={true}
            />
        </Fragment> 
          );
        })}
      </div>
    </div>
  );
};

export default Whishlist;
