import React from "react";
import HorizontalLine from "stories/HorizontalLine";
import LDImage from "stories/Image";
import { LDParagraph, LDSpan } from "stories/Typography";
import { getRoundedVal } from "utils";
import { imagePath } from "utils/assetHelper";
import "./index.css"

const PurchaseDetails = ({ discountPrice, totalPrice, purchasePrice ,offerPrice , originalOrderAmount , isCouponValid, currencySymbol, purchase_value }) => {
  return (
    <div className="bg-secondary lg:bg-sidebarSelected px-2 py-3 my-4 rounded">
      <div className={`flex justify-between text-white my-2`}>
      <LDParagraph className="purchase-details-Medium">{purchase_value || "Course value"}</LDParagraph>
        <div className="flex items-center ">
          {Number(offerPrice) > 0 ?
          <>
             <p className="flex items-center  purchase-details line-through decoration-greeny decoration-2 ">   
                {currencySymbol}{originalOrderAmount}
              </p>  
              <p className="flex items-center  purchase-details pl-2">   
              {currencySymbol}{purchasePrice}
              </p>  
          </>
            :
            <>
              {/* <LDImage src={imagePath("/rupee.svg")} alt="" className="lg:h-2"/> */}
              {currencySymbol}
              <LDSpan className="ml-1 purchase-details">{`${purchasePrice && purchasePrice}`}</LDSpan>
            </>
        }
        </div>
      </div>
      <div className={`flex justify-between text-white my-2`}>
        <LDParagraph className="purchase-details-Medium">Coupon value</LDParagraph>
        <div className="flex items-center">
            {isCouponValid &&<LDSpan className="mr-2">- </LDSpan>  }
          {/* <LDImage src={imagePath("/rupee.svg")} alt="" className="lg:h-2" /> */}
          {currencySymbol}
          <LDSpan className="ml-1 purchase-details"> {`${discountPrice && discountPrice}`}</LDSpan>
        </div>
      </div>

      <HorizontalLine className="opacity-50" />
      <div className={`flex justify-between items-center font-bold text-white mt-5`}>
        <LDParagraph className="purchase-details-total">Total</LDParagraph>
        <div className="flex items-center">
          {/* <LDImage src={imagePath("/rupee.svg")} alt="" className="lg:h-2" />  */}
          {currencySymbol}<LDSpan className="ml-1 font-bold purchase-details-total-amount">{`${totalPrice && totalPrice}`}</LDSpan> 
        </div>
      </div>
    </div>
  );
};

export default PurchaseDetails;
