
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getAccountDetails } from "selectors";

export default function useAuthenticatedEffect(cb, dependencyArr = []) {
  const { jwt } = getAccountDetails()

  useEffect(() => {
    if (jwt) {
      cb()
    }
  }, [jwt, ...dependencyArr])
}