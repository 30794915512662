import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useQuery() {
    const location = useLocation();
    const queryParams = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location]);

    return queryParams;
}
