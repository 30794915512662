import { axiosGet } from "axiosRequests";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { STATUS } from "constants/status.constant";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function useSocialLogin(URL, provider, search) {
  const dispatch = useDispatch();

  const [message, setMessage] = useState("Logging in, please wait...");
  useEffect(() => {
    setTimeout(async () => {
      const response = await axiosGet(URL);
      const { data, status } = response;
      if (STATUS.includes(status)) {

        localStorage.setItem("data", JSON.stringify(data));
        dispatch?.registerWithEmail?.setCreateAccountDetails(data);

        setMessage(MESSAGE_CONSTANTS.GOOGLE_REDIRECT_MESSAGE);
        if (!data?.user?.firstName && data?.user?.username) {
          const firstName = data?.user?.email?.split('@')[0]
          const profileData = { firstName: firstName?.trim() };
          dispatch?.profile?.requestUpdateProfile(profileData, () => {
            setTimeout(() => {
              window.close();
            }, 1500);
          });
        } else {
          setTimeout(() => {
            window.close();
          }, 1500);
        }
      } else {
        setMessage(data?.error?.message)
        // setMessage(MESSAGE_CONSTANTS.GOOGLE_ERROR_MESSAGE);
        setTimeout(()=>{
          window.close()
        },1500)
      }
    }, 1500);
  }, [provider, search]);
  return { message };
}
