import { forgotPwdInputClickEvent, forgotPwdSaveBtnClickEvent, signupCancelClickEvent } from "analytics/signupLogin";
import { Form, notification } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import FormButton from "stories/FormButton";
import Input from "stories/Input";
import { LDSmall, Title } from "stories/Typography";
import { useHistory } from 'react-router-dom';
import { imagePath } from "utils/assetHelper";
import "../Authentication/index.css"

const ForgotPassword = ({handleClick}) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const cb=()=>{
    handleClick()
  }
  
  const handleSubmit = (e) => {
    forgotPwdSaveBtnClickEvent({event_label:email})
    e.preventDefault();
    const payload = {
      email: email,
    };
    dispatch?.registerWithEmail?.requestForgotPassword(payload,cb);
  };

  return (
    <div className="p-4">
      <LDSmall
        className="flex justify-end"
      >
        <span className="cursor-pointer underline" onClick={()=>{ 
            signupCancelClickEvent()
            handleClick()
          }}>
        <img src={imagePath("/cross.svg")} alt="crossImg" />
        </span>
      </LDSmall>
      <div className="flex flex-col text-center py-14">
        <Title className="auth-modal-heading-style">Welcome</Title>
        <LDSmall className= "auth-small-text-style-grey mt-2">
          Enter your registered email address <br/> to reset your password.
         </LDSmall>
        <Form className="mt-7" onFinish={(values) => onFormSubmit(values)}>
          <Form.Item
            name="email"
            // getValueProps={(value) => console.log(value)}
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              }
            ]}
          >
            <Input
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e?.target?.value);
              }}
              onClick={()=>forgotPwdInputClickEvent()}
              className="bg-secondary rounded px-4 py-3 border border-formBorder w-3/4 auth-input-text-style"
            />
          </Form.Item>
          <FormButton
            htmlType="submit"
            className="bg-greeny auth-button-text-style w-3/4 rounded mx-auto"
            onClick={handleSubmit}
          >
            Reset Password
          </FormButton>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
