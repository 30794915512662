import React, { memo, useEffect, useLayoutEffect, useRef } from "react";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref?.current;
};

const SingleInputComponent = ({ focus, autoFocus, ...rest }) => {
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef?.current) {
      if (focus && autoFocus) {
        inputRef?.current?.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef?.current?.focus();
        inputRef?.current?.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);
  return (
    <input
      ref={inputRef}
      inputMode="numeric"
      pattern="[0-9]*"
      title="Non-negative integral number"
      {...rest}
      type="number"
    />
  );
};

const SingleOTPInput = memo(SingleInputComponent);
export default SingleOTPInput;
