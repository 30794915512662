import { Notifier } from "components/Notifier";
import UpdatedEmail from "components/UpdatedEmail";
import { useDispatch, useSelector } from "react-redux";
import { Heading, Subtitle } from "stories/Typography";
import { EmailCheckRegEx } from "utils";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { useLocation } from "react-router-dom";
import { isProfileUpdateRouteValid } from "utils/navigationHelper";

const ProfileUpdate = () => {
    const profile = useSelector(state => state?.profile?.profileDetails);
    const { email } = profile;
    const location = useLocation();
    const isRouteValid = isProfileUpdateRouteValid(location)
    const emailUpdateReq = isRouteValid && profile?.data && Object.keys(profile?.data).length > 0 && (!email || !(email && email.length));
    const dispatch = useDispatch()

    const getModalTitle = () => {
        return (
            <>
                <Heading className="text-greeny mb-2">Update your Email ID</Heading>
                <Subtitle>We request your Email ID to share the webinar link for the Expert Talk sessions</Subtitle>
            </>
        )
    }
    const updateEmail = (email) => {
        if(email?.length ===0){
           return Notifier("DELETED",MESSAGE_CONSTANTS['EMAIL_REQUIRED_MSG'])
        }
        if(email && EmailCheckRegEx(email)) {
          const data = {
            email: email
          };
          const successCb = () => {
            Notifier(200,MESSAGE_CONSTANTS['EMAIL_UPDATE_MSG'])
          }
          dispatch?.profile?.requestUpdateProfile(data,successCb);
        } else {
          Notifier("DELETED", MESSAGE_CONSTANTS['INVALID_EMAIL_MSG']);
        }
      };
    return (
        <div>
            {emailUpdateReq !== 0  && emailUpdateReq && (
                <UpdatedEmail
                    customTitle={getModalTitle()}
                    btnTitle="Update lamlsd"
                    show={true}
                    updateEmailClickAction={updateEmail}
                    skip={false}
                />
            )}
        </div>
    )
}

export default ProfileUpdate;