import { UTCTimeConverter, formatDate, sessionEndTime60MinsAfter, sessionStartTime15MinsAfter, sessionStartTime15MinsBefore } from "utils";

export const validateDate = (input)=>{
    var datePart = input.match(/\d+/g),
    year = datePart[0], // get only two digits
    month = datePart[1], day = datePart[2];
    let updatedDate =`${day}/${month}/${year}`
    // var input = '01/01/1997'; Date formate must be in this form     
    let pattern =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
     if(pattern.test(updatedDate)){
        return true
     }
     else{
        return false
     }
}   


// get nearest date according to user session settings
  //sorting toays sessions according to date and time
export const functSortTodayDateTimeForSession = (_getTodayMeeting) => {
   const _sessionAlertWithTime =
     _getTodayMeeting &&
     _getTodayMeeting.map((data, idx) => data);

   // Sorting Data
   const sortMyUpCommingSessions =
     _sessionAlertWithTime &&
     _sessionAlertWithTime.sort(
       (a, b) => a.time_slot?.start_time - b.time_slot?.start_time
     );

     const _filteredArray = sortMyUpCommingSessions && sortMyUpCommingSessions.filter((data,idx)=>sessionEndTime60MinsAfter(new Date(data?.time_slot?.start_time)) >= new Date())
     const result = _filteredArray && _filteredArray?.[0];
     return result;
 };
