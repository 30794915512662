export const Heading = (props) => {
  const { children, className } = props;
  return (
    <h1
      {...props}
      className={`font-modellicaBold text-white text-headingSizeMob sm:text-headingSize md:text-headingSize 2xl:text-headingSize ${className}`}
    >
      {children}
    </h1>
  );
};
export const Title = (props) => {
  const { children, className } = props;
  return (
    <h1
      {...props}
      className={`font-modellicaBold text-white text-titleSizeMob sm:text-titleSize md:text-titleSize lg:text-lg-titleSize ${className}`}
    >
      {children}
    </h1>
  );
};

export const Headder = (props) => {
  const { children, className } = props;
  return (
    <h1
      {...props}
      className={className}
    >
      {children}
    </h1>
  );
};

export const Description = (props) => {
  const { children, className } = props;
  return (
    <h1
      {...props}
      className={`${className}`}
    >
      {children}
    </h1>
  );
};

export const Subtitle = (props) => {
  const { children, className } = props;
  return (
    <h2 {...props} className={`font-modellicaBold text-subtitleSizeMob sm:text-subtitleSize md:text-subtitleSize lg:text-lg-subtitleSize text-white 2xl:text-xl ${className}`}>
      {children}
    </h2>
  );
};

export const LDParagraph = (props) => {
  const { children, className } = props;
  return (
    <p {...props} className={`font-modellicaMedium text-otherTextSizeMob sm:text-otherTextSize md:text-otherTextSize lg:text-lg-otherTextSize text-white 2xl:text-sm ${className}`}> {/* for large screen font-size:1.2vh*/}
      {children}
    </p>
  );
};

export const LDSpan = (props) => {
  const { children, className } = props;
  return (
    <span {...props} className={`font-modellicaMedium text-otherTextSizeMob sm:text-otherTextSize md:text-otherTextSize lg:lg:text-lg-otherTextSize text-white 2xl:text-sm  ${className}`}>
      {children}
    </span>
  );
};

export const LDSmall = (props) => {
  const { children, className } = props;
  return (
    <small {...props} className={`font-modellicaMedium text-white ${className}`}>
      {children}
    </small>
  );
};

export const LDLabel = (props) => {
  const { children, className } = props;
  return (
    <label {...props} className={`font-modellicaMedium text-white ${className}`}>
      {children}
    </label>
  );
};
