import { Form } from "antd";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FormButton from "stories/FormButton";
import { Heading, LDSmall, Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";

const PasswordResetSuccess = ({ handleClick }) => {
  const history =useHistory()
  const location = useLocation()

  const dispatch = useDispatch()
  const currentPath = location?.pathname;
  const isUserResetPasswordPath = currentPath === "/reset-password";

  const handleSubmit = () => {
    if (isUserResetPasswordPath) {
      dispatch?.authModel?.isLoggedIn(true);
      dispatch?.authModel?.resetPassword(false);
      history.push("/?authType=1");
    } else {
      history.push(ROUTE_CONSTANTS?.HOME);
      dispatch?.profile?.setChildModalStatus(true);
    }
  };

  return (
    <div className="p-4 pb-[100px]">
          <LDSmall
            className="cursor-pointer underline flex justify-end"
            onClick={handleClick}
          >
            <img src={imagePath("/cross.svg")} alt="crossImg" />
          </LDSmall>  
      <div className="flex flex-col text-center py-14 pb-20	">
        <Heading className="auth-modal-heading-style">Password Set!</Heading>
        <LDSmall className="auth-small-text-style-grey mt-2 mb-12">
          {isUserResetPasswordPath ? "You are all set. Click here to Log in." : "You are all set. Click here to deactivate child mode."}
        </LDSmall>
        <Form>
          <FormButton
            htmlType="submit"
            className="bg-greeny hover:bg-greeny focus:bg-greeny active:bg-greeny active:text-white focus:text-white hover:text-white auth-button-text-style w-3/4 rounded mx-auto"
            onClick={handleSubmit}
          >
            {isUserResetPasswordPath ? "Sign In" : "Deactivate Child Mode"}
          </FormButton>
        </Form>
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
