import axios from "axios";
import { STATUS_CODE } from "constants/status.constant";
import { getAccountDetails ,getCountryCode,getIPAddress } from "selectors";
import { currentTimeZone, tooManyAttemptAlert } from "utils";
import { isApiIpRestricted } from "utils/apiHelper";
import { decryptResponseData, encryptBody } from "utils/encrptyHelper";
import { removeToken } from "utils/token.helper";

const unAuthorizedLogout = (error) => {
  if(error?.response?.status == STATUS_CODE.unAuthorized){
    removeToken();
  }
  if(error?.response?.status === STATUS_CODE.tooManyRequest){
    tooManyAttemptAlert()
  }
}

const getOptions = (url, data, headers, enc) => {
  const { jwt } = getAccountDetails()
  const clientIp = getIPAddress()
  const countryCode = getCountryCode()
  let authorizationHeader = {};
  let ipInfo = {};
  let timeZone = {
    timezone:currentTimeZone,
    country:countryCode
  }
  let body = data;
  if (jwt) {
    authorizationHeader = {
      Authorization: `Bearer ${jwt}`
    };
  }
  if(isApiIpRestricted(url) && clientIp?.length){
    ipInfo = {
      ip: clientIp
    }
  }
  if(enc){
      const ecrpyt = encryptBody(data)
      body = { 
        data: ecrpyt
      }
  }
  const options = {
    url: url,
    headers: {
      "content-type": "application/JSON",
      ...authorizationHeader,
      ...headers,
      ...ipInfo,
      ...timeZone
    },
    data: body,
  };
  return options;
};

const getDecryptedResponse = (response, isError=false) => {
  const _data = decryptResponseData(response?.data);
  const data = isError && typeof(_data) === "object" ? _data : { data: _data }
  const _response = {
    ...response,
    ...data
  };
  return _response;
};

const axiosGetHandler = (url, headers, enc= false, v1=false) => {
  const options = getOptions(url, null, headers, enc );
  return axios({
    method: "GET",
    ...options,
  })
  .then((response)=>{  
    if(enc){
      return getDecryptedResponse(response)
    }
    return response
  }).catch((error) => {
    unAuthorizedLogout(error);
    if(enc && v1){
      return getDecryptedResponse(error.response, true)
    }
    return error.response;
  })
}

const axiosPostHandler = (url, data, headers, enc, v1=false) => {
  const options = getOptions(url, data, headers, enc);
  return axios({
    method: "POST",
    ...options,
  }).then((response)=>{  
    if(enc){
      return getDecryptedResponse(response)
    }
    return response
  })
  .catch((error) => {
    unAuthorizedLogout(error);
    if(enc && v1){
      return getDecryptedResponse(error.response, true)
    }
    return error.response;
  });
}

const axiosPutHandler = (url, data, headers, enc =false, v1=false) => {
  const options = getOptions(url, data, headers, enc);
  return axios({
    method: "PUT",
    ...options,
  }).then((response)=>{
    if(enc){
      return getDecryptedResponse(response)
    }
    return response
  })
  .catch((error) => {
    if(enc && v1){
      return getDecryptedResponse(error.response, true)
    }
    unAuthorizedLogout(error);
    return error.response;
  });
}

export const axiosGet = (url, headers, enc= false) => {
  return axiosGetHandler(url, headers, enc)
};

export const axiosGetV1 = (url, headers, enc= false) => {
  return axiosGetHandler(url, headers, enc, true)
};

export const axiosPost = (url, data, headers, enc = false) => {
  return axiosPostHandler(url, data, headers, enc)
};

export const axiosPostV1 = (url, data, headers, enc = false) => {
  return axiosPostHandler(url, data, headers, enc, true)
};

export const axiosPut = (url, data, headers, enc =false ) => {
  return axiosPutHandler(url, data, headers, enc)
};

export const axiosPutV1 = (url, data, headers, enc =false ) => {
  return axiosPutHandler(url, data, headers, enc, true)
};

export const axiosDelete =(url, data, headers, enc = false) => {
  const options =getOptions(url, data, headers, enc);
  return axios({
    method: "DELETE",
    ...options,
  }).catch((error)=>{
    unAuthorizedLogout(error);
    return error.response
  })
}