const TagsLoader = () => {
  return (
    <>
      <div className="shadow rounded-md max-w-sm mr-4">
        <div className="animate-pulse">
          <div className="space-y-3">
            <div className="w-24">
              <div className="h-4 bg-slate-200 rounded col-span-2"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagsLoader;
