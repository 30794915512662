

export const APP_MIN_MOBILE_WIDTH = "1159px";
export const APP_LARGE_SCREEN_WIDTH = 1536;

export const APP_REVIEW_LIST_SIZE = 3;

export const hideSidebarRoutes = ["/", "about","contact", "courses", "blogs", "blog", "terms-and-conditions", "course", "episode", "payment", "search", "email-verify","landing","guest-payment","instructor-details","booking-details","booking-confirmed","session-feedback","session", "instructor-calendar"];
export const hideNavBarRoutes = ["/", "about", "contact", "courses", "blogs", "blog", "terms-and-conditions", 'guest-payment', 'landing',"session", "instructor-calendar"]

export const hideFooter = ["/", "about","contact", "courses", "blogs", "blog", "terms-and-conditions"]

//Using this to check the create account and login flow during email login. Add your valid accounts here to signin
export const dummyAccountsArray = ["apollo@gmail.com", ""];
// apollo@gmail.com
// Rohit@11

export const phoneCodes = [
  { value: "+91" },
  // { value: "+42" },
  // { value: "+384" },
  // { value: "+92" },
  // { value: "+93" },
];

export const APP_DEFAULT_LOCALE = "en";

export const PAGE_SIZE = 8
export const PAGE = 1

export const STATUS_UPDATE_INTERVAL = 5000;

export const MESSAGE_CONSTANTS = {
  REMOVED_FROM_WISHLIST : "was removed from Wishlist",
  ADDED_TO_WISHLIST : "added to Wishlist",
  GOOGLE_REDIRECT_MESSAGE: "You have been successfully logged in. You will be redirected in a few seconds...",
  GOOGLE_ERROR_MESSAGE :"An error occurred,Email is Already taken",
  ADD_COMMENT : "Added comment successfully",
  ADD_COMMENT_ERR_MSG : "Some error occurred while submitting Comment. Please Try Again.",
  REPLY_COMMENT : "Replied Successfully",
  DELETE_COMMENT : "Deleted Successfully",
  EDIT_COMMENT :"Updated Successfully",
  CREATE_ACCOUNT_MSG : "Account created successfully",
  LOGIN_MSG : "Logged in successfully",
  UPDATE_PHONE_MSG : "Phone Number Added Successfully",
  UPDATE_PHONE_ERR_MSG : "Some error occurred while adding Phone Number. Please Try Again.",
  UPDATE_LANGUAGE_SUPPORT_MSG :"updated successfully",
  UPDATE_PROFILE_PIC : "Profile image updated successfully",
  UPDATE_PROFILE_DELETED_PIC : "Profile image deleted successfully",
  UPDATE_PROFILE_PIC_ERR_MSG : "Some error occurred while adding profile image. Please Try Again.",
  UPDATE_PERSONAL_DETAILS_MSG : "Profile details updated successfully",
  UPDATE_PERSONAL_DETAILS_ERR_MSG : "Some error occurred while updating Profile. Please Try Again.",
  FEEDBACK_MSG :"Thank you for your feedback!",
  INVOICE_MSG:"Could not load invoice.Please try again later",
  WISHLIST_ERROR_MESSAGE: "Some error occurred while adding course to Wishlist. Please Try Again.",
  REVIEW_COURSE: "Thank you for Reviewing the Course.",
  RATING_COURSE:"Thank you for Rating the Course.",
  REVIEW_COURSE_ERROR_MESSAGE: "Some error occurred while Reviewing. Please Try Again.",
  RATING_COURSE_ERROR_MESSAGE: "Some error occurred while Rating. Please Try Again.",
  REVIEW_ERROR_MESSAGE:"Some error occurred while submitting review. Please Try Again.",
  ADD_EMAIL_PROFILE: "Email ID Added and Verified Successfully",
  ADD_EMAIL_PROFILE_ERR_MSG: "Some error occurred while adding Email ID. Please Try Again.",
  GIVING_FEEDBACK: "Thank you for reaching out to us, we will reach you soon to serve you better!",
  GIVING_FEEDBACK_ERR_MSG: "Some error occurred while submitting your Feedback. Please Try Again.",
  RESET_PWD_MSG: "Password Reset Successfully",
  RESET_PWD_ERR_MSG: "Some error occurred while resetting Password. Please Try Again.",
  REFER_FRD_MSG: "Referral Email/SMS Sent",
  REFER_FRD_ERR_MSG: "Some error occurred while trying to send Referral Email/SMS. Please Try Again.",
  GENERIC_ERR_MSG: "Some error occured",
  NOTIFICATION_PREF_UPDATED: "Notification preference updated successfully",
  COURSE_NOT_FOUND: "Either the course was deleted or does not exists",
  EPISODE_NOT_FOUND: "Either the episode was deleted or does not exists",
  EMAIL_UPDATE_MSG : "Email updated successfully",
  CHILD_MODE_ACTIVATE_MSG: "Child mode activated successfully",
  CHILD_MODE_DEACTIVATE_MSG: "Child mode deactivated successfully",
  EMAIL_REQUIRED_MSG: "Email ID is required",
  INVALID_EMAIL_MSG: "Please enter a valid email",
  INSTRUCTOR_NOT_FOUND:"Either the link was deleted or does not exists",
}

export const LAST_WACTHED_SEEK_MIN_GAP = 10; //in secs

export const PLAYER_INIT_CONTROLS_HIDE_TIME = 2500;

export const LIST_LENGTH_TO_COMPARE = 12

export const assetBucketUrl = process.env.REACT_APP_S3_BUCKET;

export const STRINGS = {
  REFER_A_FRIEND :"Refer a friend"
}

export const genderOptions = [
  { label: "Female", value: "female" },
  { label: "Male", value: "male"},
  { label: "Other", value: "other" },
]

export const ratingValList = [{
  min: 0.01,
  max: 0.5,
  value: 0.5
},{
  min: 0.5,
  max: 1,
  value: 1
},{
  min: 1,
  max: 1.5,
  value: 1.5
},{
  min: 1.5,
  max: 2,
  value: 2
},{
  min: 2,
  max: 2.5,
  value: 2.5
},{
  min: 2.5,
  max: 3,
  value: 3
},{
  min: 3,
  max: 3.5,
  value: 3.5
},{
  min: 3.5,
  max: 4,
  value: 4
},{
  min: 4,
  max: 4.5,
  value: 4.5
},{
  min: 4.5,
  max: 5,
  value: 5
}];

export const PROTOCOL_TYPE = "http" || 'https'

export const LIFEDEMY_APP_URL = ` https://play.google.com/store/apps/details?id=com.lifedemy`

export const LIFEDEMY_WHATSAAP_URL = `https://www.whatsapp.com/channel/0029VaF4Kik8vd1Ycck2KC3u`

export const APP_COOKIES = {
  TOUR_COOKIE: '_trc'
};

export const ANDROID_OS_KEY = "Android";

export const ANDROID_ALLOWED_COUNTRIES = ["IN"]

export const EXCLUDE_KEYS = {
  courseId: "courseId",
  purchaseId: "purchaseId"
}

export const INVOICE_RELATED_DATE = {
  GSTIN_NO: "33AAJCT3867G1Z4"
}

export const AllowedInstructorForWA = [17, 20]

export const ABOUT_YOURSELF_ON_BOOKING = "https://4if4tq0jy33.typeform.com/to/yIK2AoMA?typeform-source=www.google.com"