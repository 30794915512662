import * as eventConstants from "./events.constants";
import wishlistEvents from "./WishlistEvents";

const handleEvent  = (eventCode, extraParams={}) => {
    wishlistEvents.handle_analytics_event(eventCode, extraParams);
};

export const wishlistLoadEvent = (loadEnd = false, extraParams) => {
  const eventCode = loadEnd ? eventConstants?.WISHLIST_LOAD_END : eventConstants?.WISHLIST_LOAD_START;
//   const extraParams = {};
  handleEvent(eventCode, extraParams);
}
export const wishlistFilterChangeEvent =(extraParams)=>{
    const eventCode = eventConstants?.WISHLIST_FILTER_CHANGE
    handleEvent(eventCode, extraParams)
}
export const wishlistCourseCardClickEvent =(extraParams)=>{
    const eventCode = eventConstants?.WISHLIST_COURSE_CARD_CLICK
    handleEvent(eventCode, extraParams)
}
export const wishlistCourseCardWishlistClickEvent =(extraParams)=>{
    const eventCode = eventConstants?.WISHLIST_COURSE_CARD_WISHLIST_CLICK
    handleEvent(eventCode, extraParams)
}   