export const IP_RESTRICTED_APIS = [
    "getCourseDetails",
    "referral-page",
    "initiatePurchase",
    "getPurchaseObject",
    "payments/initiate",
    "payments/verify",
    "myCourses",
    "initiatePurchase",
    "initiateGuestPayment"
];