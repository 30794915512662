import { notification } from "antd";

export const Notifier = (status,message,interval)=>{
    if(status >= 200 && status < 300){
        notification["success"]({
            message: "",
            top: 70,
            duration: interval ? interval : 2,
            description:message
        })
    }
    else{
        notification["error"]({
            message: "",
            duration: interval ? interval : 2,
            top: 70,
            description:message
        })
    }
}

export const clearNotification = () =>{
    notification.destroy()
}