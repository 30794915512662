import Error from "components/Error"
import { Title } from "stories/Typography"

const PageNotFound = () => {
  const imgStyle = {
    height: "35vh"
  };
  return (
    <div className='flex justify-center items-center h-screen'>
      <Error errType={404} errImgStyle={imgStyle} /> 
    </div>
  )
}

export default PageNotFound
