import { LDAnalytics } from "utils/analyticsHelper.js";

class NavbarEvents extends LDAnalytics {
  event_Navbar_Hamburger_Icon_Click() {
    return {
      event: {
        module_name: "Navbar",
        feature_name: "Navbar Hamburger Icon",
        event_name: "Navbar_Hamburger_Icon_Click",
        event_label: "-",
      },
    };
  }
  event_Navbar_Logo_Click() {
    return {
      event: {
        module_name: "Navbar",
        feature_name: "App Logo",
        event_name: "Navbar_Logo_Click",
        event_label: "-",
      },
    };
  }
  event_Navbar_Search_Input_Click() {
    return {
      event: {
        module_name: "Navbar",
        feature_name: "Search",
        event_name: "Navbar_Search_Input_Click",
        event_label: "-",
      },
    };
  }
  event_Navbar_Notification_Icon_Click() {
    return {
      event: {
        module_name: "Navbar",
        feature_name: " View Notification",
        event_name: "Navbar_Notification_Icon_Click",
        event_label: "",
      },
    };
  }
  event_Navbar_Profile_Pic_Click() {
    return {
      event: {
        module_name: "Navbar",
        feature_name: "Profile Dropdown",
        event_name: "Navbar_Profile_Pic_Click",
        event_label: "-",
      },
    };
  }
  event_Navbar_Profile_Pic_Profile_Option_Click() {
    return {
      event: {
        module_name: "Navbar",
        feature_name: "Profile Page",
        event_name: "Navbar_Profile_Pic_Profile_Option_Click",
        event_label: "Profile",
      },
    };
  }
  event_Navbar_Profile_Pic_Logout_Option_Click() {
    return {
      event: {
        module_name: "Navbar",
        feature_name: "Logout",
        event_name: "Navbar_Profile_Pic_Logout_Option_Click",
        event_label: "Logout",
      },
    };
  }
}

const navbarEvents = new NavbarEvents();
export default navbarEvents;
