import Image from "components/Image";
import useMedia from "hooks/deviceHelper/useMedia";
import { useSelector } from "react-redux";
import { imagePath } from "utils/assetHelper";

const PlayerThumbnail = (props) => {
  const { action, episode, thumbnail, isIntro=false } = props;
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)

  const handlePlayClick = () => {
    action({},"thumb");
  }
  const { VIDEO_PLAYER_HEIGHT } = appConfig;  
  const isMobile = useMedia();
  const thumbCls = isMobile ? "min-h-[23vh]" : VIDEO_PLAYER_HEIGHT;
  const playIcon = isIntro ? imagePath("/Instructor/watchIntro.svg") : episode ? imagePath("/Episodes/watchEpisode.svg") : imagePath("/Course/watch.svg");
  return (
    <div className={`absolute ${thumbCls} h-full w-full`}>
      <div className={`opacity-50 absolute h-full w-full bg-black`}></div>
      <Image src={thumbnail} large={true} className="h-full w-full" />
      <Image className="absolute top-1/2 left-1/2 z-[10] cursor-pointer" style={{transform: "translate(-50%,-50%)"}} onClick={handlePlayClick} src={playIcon} icon={true} />
    </div>
  );
};

export default PlayerThumbnail;
