import { hideFooter } from "constants/app.constants";
import { notification_route_config } from "constants/config";
import { PROFILE_UPDATE_INVALID_ROUTES, ROUTE_CONSTANTS, publicRoutes } from "constants/routes.constants";

export const routeUpdateSearch = (key, val) => {
  const pathname = window?.location?.pathname;
  const searchParams = new URLSearchParams();
  searchParams.append(key, val);
  const updatedPath = `${pathname}?${searchParams}`;
  window.history.replaceState(null, "", updatedPath);
};


export const getAleartConfigRoute = (body, type) => {
  const types = Object.keys(notification_route_config);
  let newRoute = "";
  switch (type) {
    case "COURSE_PUBLISHED":
      newRoute = `${notification_route_config[type].route}/${body?.slug}`;
      break;
    case "PROFILE_UPDATED":
      newRoute = notification_route_config["PROFILE_PIC_UPDATED"].route;
      break;
    case "PROFILE_PIC_UPDATED":
      newRoute = notification_route_config["PROFILE_PIC_UPDATED"].route;
      break;
    case "COURSE_ADDED_TO_WISHLIST":
      newRoute = notification_route_config["COURSE_ADDED_TO_WISHLIST"].route;
      break;
    case "PHONE_NUMBER_ADDED":
      newRoute = notification_route_config["PROFILE_PIC_UPDATED"].route;
      break;
    case "WELCOME_ONBOARD":
      newRoute = ROUTE_CONSTANTS?.HOME;
      break;
    case "EMAIL_VERIFICATION_SUCCESS":
      newRoute = notification_route_config["PROFILE_PIC_UPDATED"].route;
      break;
    case "EPISODE_COMMENT_ACTIVITY":
      const courseSlug =
        body?.courseSlug && body.courseSlug.length
          ? body.courseSlug
          : body.courseId;
      const episodeSlug =
        body?.episodeSlug && body.episodeSlug.length
          ? body.episodeSlug
          : body.episodeId;
      newRoute = `/episode/${courseSlug}/${episodeSlug}`;
      break;
    default:
      newRoute = undefined;
      break;
  }
  return newRoute;
};
export const getRouterPath = (path)=>  path === "/" ? [" ","/"] : path?.split('/') || []

export const checkPublicRoute = (location={}) => {
  const path = location?.pathname || "";
  const routePathArr = getRouterPath(path);
  if(path === "/mycourses" && location?.search?.indexOf("?invoice") !== -1){
    return true;
  }else if(publicRoutes.includes(routePathArr[1])){
    return true;
  }
  return false;
}

export const isProfileUpdateRouteValid = (location={}) => {
  const path = location?.pathname || "";
  const routePathArr = getRouterPath(path);
  return !PROFILE_UPDATE_INVALID_ROUTES.includes(routePathArr[1])
}

export const removeFooterHandler = (location)=>{
  const path = location?.pathname || ""
  const routePathArr = path && getRouterPath(path)
  return !hideFooter?.includes(routePathArr?.[1])
}
