import { getTimeProps } from "antd/lib/date-picker/generatePicker";
import Notifications from "components/Notifications";
import { useSelector,useDispatch } from 'react-redux';
import { getYesterdayDate } from "utils"
import { useEffect ,useState} from 'react';
 
const Notification = ({notificationVisible,setNotificationVisible,mobileNotification}) => {
  const list = useSelector((state)=> state?.notification?.notiticationList)
  const [alerts,setAlerts] =useState([])
  const dispatch = useDispatch()
  useEffect(()=>{
    // const updatedList = [...list, ...alerts]
    // dispatch?.notification?.setNotificationLength(updatedList?.length)
    setAlerts(list) // using list to fix notification position
  },[list])
  
  let key ="id"
  let arrayUniqueByKey = [...new Map(alerts?.map(item =>
    [item[key], item])).values()
  ];

  let todaysNotification = [];
  let yesterdayNotification = [];
  let olderNotification = [];
  
  for(let key in arrayUniqueByKey){
    const alertItem = arrayUniqueByKey[key];
    if(new Date(alertItem?.createdAt).toDateString() === new Date().toDateString()){
      todaysNotification.push(alertItem);
    }else if(new Date(alertItem?.createdAt).toDateString() === getYesterdayDate()){
      yesterdayNotification.push(alertItem);
    }else{
      olderNotification.push(alertItem)
    }
  }
const data = [
    {
       day: "Today",
       notifiList: todaysNotification,
    },
    {
      day:"Yesterday",
      notifiList:yesterdayNotification
    },
    {
      day:"Older",
      notifiList: olderNotification
    }
  ];
  const applystyle = mobileNotification ? "lg:px-[16rem] md:px-[12rem]"  : "max-w-[23rem]" 
  return (
    <div className={applystyle}>
      <Notifications notificationVisible={notificationVisible} notificationList={data} setNotificationVisible={setNotificationVisible} mobileNotification={mobileNotification}/>
    </div>
  );
};

Notification.defaultProps ={
  mobileNotification:false
}
export default Notification;
