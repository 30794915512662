import * as eventConstants from "./events.constants";
import suggestionEvents from "./SuggestionEvents";

const handleEvent  = (eventCode, extraParams={}) => {
    suggestionEvents.handle_analytics_event(eventCode, extraParams);
};

export const suggestionPageInputClickEvent =(extraParams = {})=>{
    const eventCode = eventConstants?.SUGGESTION_PAGE_INPUT_CLICK
    handleEvent(eventCode, extraParams)
}   

export const suggestionPageTextareaClickEvent =(extraParams = {})=>{
    const eventCode = eventConstants?.SUGGESTION_PAGE_TEXTAREA_CLICK
    handleEvent(eventCode, extraParams)
}

export const suggestionPageSubmitBtnClickEvent=(extraParams = {})=>{
    const eventCode = eventConstants?.SUGGESTION_PAGE_SUBMIT_BTN_CLICK
    handleEvent(eventCode, extraParams)
}


export const SupportPageLoaded=(extraParams = {})=>{
    const eventCode = eventConstants?.SUPPORT_PAGE_LOADED
    handleEvent(eventCode, extraParams)
}