import { Rate } from "antd";
import { StarOutlined, StarFilled, StarTwoTone, setTwoToneColor } from '@ant-design/icons'
import { imagePath } from "utils/assetHelper";
import "./index.css";
import useMedia from "hooks/deviceHelper/useMedia";
import { LDSpan ,LDSmall} from "stories/Typography";
import Rating from 'react-rating';
import { generateRatingValue } from "utils";

const StarRating = (props) => {
  const {
    isStarRatingActive,
    getRating,
    rating,
    size,
    marginRight,
    disabled,
    readOnly,
    quiet,
    fontSize
  } = props;
  const isMobile = useMedia();
  
  return (
    <>
      {isStarRatingActive ? (
        <>
        <Rating
          emptySymbol={<StarOutlined className={`text-greeny ${marginRight}`} style={{fontSize:fontSize}}/>}
          fullSymbol={<StarFilled className={`text-greeny ${marginRight}`} style={{fontSize:fontSize}}/>}
          initialRating={rating && generateRatingValue(rating)}
          value={rating}
          onChange={(value) => {
            getRating(value);
          }}
          quiet={quiet}
          readOnly={readOnly}
        />
        </>
      ) : (
        <div className="flex items-center">
          <img className="h-3" src={imagePath("/star.svg")} alt="rating" />
          <LDSmall className="pl-1 text-xs text-white  home-star-rating-number-style lg:text-[.7rem] font-modellicaMedium">
            ({rating.toFixed(1)})
          </LDSmall>
        </div>
      )}
    </>
  );
};

StarRating.defaultProps = {
  isStarRatingActive: false,
  rating: "",
  size: "",
  readOnly: true,
  quiet:true,
  fontSize:"14px",
  marginRight:"mr-2",
  getRating:()=>{ return },
};

export default StarRating;
