import React from "react";
import LDImage from "stories/Image";
import { imagePath } from "utils/assetHelper";

const ProfileLoader = () => {
  return (
    <div className="md:w-[30rem]">
      <div
        role="status"
        className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
      >
        <div className="flex rounded-full items-center justify-center w-[10rem] h-[10rem] bg-gray-300 sm:w-[16rem] dark:bg-gray-700">
          <LDImage
            src={imagePath("/ProfileSkeleton.svg")}
            className="w-10 h-10"
          />
        </div>
        <div className="w-full">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
      <div className="my-5">
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
      </div>
    </div>
  );
};

export default ProfileLoader;
