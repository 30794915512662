import { signupCreateAccountDobInputClickEvent, signupCreateAccountNameInputClickEvent, signupSaveBtnClickEvent, UserSignupSuccess } from "analytics/signupLogin";
import { Form } from "antd";
import { Notifier } from "components/Notifier";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { useState ,useRef,useEffect} from "react";
import { useDispatch ,useSelector} from "react-redux";
import FormButton from "stories/FormButton";
import Input from "stories/Input";
import { LDSmall, LDSpan, Title, LDParagraph } from "stories/Typography";
import { validateDate } from "utils/validateDate";
import { AlphaCheckRegEx, checkPasswordIsValid, enableDateBeforeToday, readableDateFormate } from "utils";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import Image from "components/Image";
import { Input as LDNewInput } from "antd";
import LDTooltip from "stories/LDTooltip";
import PasswordRules from "components/Authentication/PasswordRules";
import "./input.css"
import { imagePath } from "utils/assetHelper";
import { PROVIDER } from "constants/status.constant";
import { validatePasswordRules } from "utils/stringHelper";
import useMedia from "hooks/deviceHelper/useMedia";
import LDDateInput from "stories/LDDateInput";
import { genderOptions } from "constants/app.constants";
import { Radio } from 'antd';


const CreateProfile = (props) => {
  const accountDetails = useSelector((state)=> state?.registerWithEmail?.account_details)
  const isCreateAccount = useSelector((state)=> state.registerWithEmail?.isCreateProfile)

  const { user }   = accountDetails 
  const { handleClick, showCancel,payload ,setAuthType} = props;
  const [name, setName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [password,setPassword] =useState("")
  const [confmPassword,setConfmPassword] =useState("")
  const [error,setError] = useState({date:""})
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [rulesVisible, setRulesVisible] = useState(false);
  const [rulesPassed, setRulesPassed] = useState(true);
  const [rulesError, setRulesError] = useState({})
  let errorToShow = {}
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => state?.profile?.profileDetails);
  const passwordRef = useRef();
  const isMobile = useMedia();
  const [dobtype, setDobType] = useState("text");
  const [genderSelect, setGenderSelect] = useState("")

useEffect(() => {
  if (passwordRef?.current?.input) {
    const focusHandler = () => {
      setRulesVisible(true);
    };
    const blurHandler = () => {
      setRulesVisible(false);
    }
    passwordRef?.current?.input?.addEventListener("focus", focusHandler);
    passwordRef?.current?.input?.addEventListener("blur", blurHandler);
    return () => {
      passwordRef?.current?.input?.removeEventListener("focus", focusHandler);
      passwordRef?.current?.input?.removeEventListener("blur", blurHandler);
    };  
  }
}, [passwordRef]);

useEffect(()=>{
  if(password.length){
    const valid = validatePasswordRules(password);
    setRulesPassed(valid);
  }else{
    setRulesPassed(false);
  }
},[password]);
  const handleSubmit = (values) => {
    if(isCreateAccount || PROVIDER.includes(user?.provider)){
        if(name.trim().length === 0 || dateOfBirth.trim().length === 0 || genderSelect.trim().length === 0 ){
          return Notifier("DELETED","All fields are required")
        }
    } 
    // Account details not getting when login with email
    if(!accountDetails){
      if(name.trim().length === 0 || dateOfBirth.trim().length === 0 || password?.length === 0 || confmPassword?.length === 0  ){       
        return Notifier("DELETED","All fields are required")
      }
    }
    else{
       const fullName =  name.trim() || "";
       const nameArr = fullName?.split(" ") || [];
       if(nameArr?.length === 0 || name?.length === 0){
           errorToShow.nameError = "Name is required"
       }
       if(error?.date?.trim()?.length > 0 || dateOfBirth?.length === 0 ){
           errorToShow.dateError = "Date of Birth is required"
       }
       if(password?.length === 0 ){
          errorToShow.pwdEmptyError = "Enter a valid Password"
       }
       if(genderSelect.trim().length ===0){
          errorToShow.genderEmpty = "Gender selection required"
       }
       let firstName = "";
       let lastName = "";
       if (nameArr?.length === 1) {
         firstName = fullName;
       } else {
         const nameArr = fullName?.split(" ");
         const firstNameArr = [...nameArr];
         firstNameArr?.splice(nameArr.length - 1, 1);
         firstName = firstNameArr?.join(" ");
         lastName = nameArr[nameArr.length - 1];
       }
       const payloadCreateAccount={
        ...payload,
        password:confmPassword,
       }
       const cb=()=>{
        dispatch?.authModel?.isNotLoggedIn(false); // closing the First modal Modal 
        const data = { 
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: dateOfBirth,
        };
        dispatch?.profile?.requestUpdateProfile(data,()=>{
          dispatch?.authModel?.isNotLoggedIn(false); // closing the First modal Modal 
           Notifier(200, MESSAGE_CONSTANTS?.CREATE_ACCOUNT_MSG) 
           setName("")
           setPassword("")
           setConfmPassword("")
           setDateOfBirth("")
        }) 
      }
      if(isCreateAccount || PROVIDER.includes(user?.provider)){
        const data = { 
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: dateOfBirth,
          gender: genderSelect
        };
        dispatch?.profile?.requestUpdateProfile(data,()=>{
          dispatch?.authModel?.isNotLoggedIn(false); // closing the First modal Modal 
           Notifier(200, MESSAGE_CONSTANTS?.CREATE_ACCOUNT_MSG)
           setName("")
           setPassword("")
           setConfmPassword("")
           setDateOfBirth("") 
           setGenderSelect("")
        })
      }
      else{
        if(Object.keys(errorToShow)?.length === 0){
           const result = checkPasswordIsValid(password)
           if(result){
            if(password !== confmPassword ){
              return setRulesError({passwordError:"Passwords do not match."})
            }else{
              setRulesError({})
              dispatch?.registerWithEmail?.requestEmailSignUp(payloadCreateAccount,cb);  
            }
          }else{
            setRulesError({error:"Enter a Valid Password"})
            setTimeout(()=>{
              setRulesError({})
            },5000)
          }
        }
        else{
          setRulesError(errorToShow)
        }
      }
    }
  };
  const handleChange=(val)=>{
    // const value =e.target.value
    const dateFormate = val.split("-").join("/")
    if(!validateDate(dateFormate)){
       setRulesError({dateError: "Date of Birth is required"})
    }else{
      setDateOfBirth(val)
      setError({date:""})
    }
  }
  const handleNameChange=(val)=>{
      let value = AlphaCheckRegEx(val.target.value)
      setName(value);
  }
  const handleFireEvent =()=>{
    const emailProvider =  !PROVIDER.includes(user?.provider)
    const params = {
      event_label: emailProvider ? "email" : "phone"
    }
    if(emailProvider){
        UserSignupSuccess(params)    
     }
    else{
       UserSignupSuccess(params)
    }
    signupSaveBtnClickEvent({event_label:`${name}-${dateOfBirth}`})
  }

  const handleGenderChange=(e)=>{
    if(genderSelect !== e.target.value){
      setGenderSelect(e.target.value)
    }
  }
  return (
    <div className="p-4">
        <div className="p-4">
          <LDSmall
            className="underline flex justify-end"
          >
            <img src={imagePath("/cross.svg")} alt="crossImg" 
                className="cursor-pointer"
                onClick={() => {
                  setAuthType("");
                  signupCancelClickEvent();
                }}
            />
          </LDSmall>
          </div>

      <div className="flex flex-col text-center pb-4">
        <Title className="auth-modal-heading-style">Create New Profile</Title>
        <LDSmall className="auth-small-text-style-grey mt-2 mb-4">
          Fill in your profile details.
        </LDSmall>
        <Form 
        className="mt-3"
        onFinish={(values)=> handleSubmit(values)}
        >
          {/* <Form.Item name="name"> */}
             <Input
              type= "text"
              error={rulesError.nameError}
              onClick={()=>signupCreateAccountNameInputClickEvent({event_label: profileInfo?.email})} //replace this later for gtm
              placeholder="Enter Full Name"
              className={`bg-secondary rounded px-4 py-[0.65rem] border w-3/4 auth-input-text-style ${rulesError?.nameError ? "border-2 border-remove" :"border-formBorder"}`}
              onChange={handleNameChange}
              value={name}
              />
            <LDDateInput
              type="date"
              onClick={()=>signupCreateAccountDobInputClickEvent({event_label:profileInfo?.email})} // replace this later for gtm
              placeholder="Date of Birth"
              handleChange={handleChange}
              max = {enableDateBeforeToday()}
              icon={true}
              error={rulesError.dateError}
              iconStyle={"top-5 right-[3.75rem] cursor-pointer open-button "}
              src={imagePath("/Profile/calendar.svg")}
              className={`bg-secondary rounded px-4 py-[0.65rem] border w-3/4 auth-input-text-style mt-3 ${rulesError?.dateError ? "border-2 border-remove" :"border-formBorder"} `}
              value={readableDateFormate(dateOfBirth)}
              style={{minWidth: "70%", textAlign: "left"}}
              ldForm="create-profile"
            />

          <div className="flex items-center justify-center  ml-4 mt-4 ">
              <LDSpan className="mr-3"> Gender :</LDSpan>
              <Radio.Group onChange={handleGenderChange} value={genderSelect} >
                  {genderOptions.map((_options)=>{
                    return <Radio value={_options?.value} checked={genderSelect === _options?.value}><LDSpan>{_options?.label}</LDSpan></Radio>
                  })}
              </Radio.Group>
            </div>
            
           <input type="email" value={payload?.email} className="hidden"/>

            <LDSpan className="text-remove">{error.date?.length > 0  &&  error.date}</LDSpan>
             {!isCreateAccount && !PROVIDER.includes(user?.provider) &&
             <>
              <LDTooltip
                  title={<PasswordRules password={password} />}
                  open={!rulesPassed && rulesVisible}
                  placement="bottom"
                >
              <Form.Item name="password" className="mt-3">
                <LDNewInput
                  name="password"
                  ref={passwordRef}
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter Password"
                  value={password}
                  style={{border: Object.keys(rulesError)?.length > 0 && "1px solid #FF7373" }}
                  onChange={e => {
                    setPassword(e?.target?.value);
                  }}
                  className={`bg-secondary rounded px-4 py-[0.65rem] border border-formBorder  w-3/4 auth-input-text-style`}
                />
                <Image
                  src={passwordShown ? imagePath("/show.svg") :imagePath("/hide.svg") }
                  onClick={() => setPasswordShown(!passwordShown)}
                  alt=""
                  className="h-6 w-6 cursor-pointer absolute top-2 right-14"
                />
                {rulesError?.pwdEmptyError  &&
                  <LDParagraph className="password-rules-error-style text-left ml-12 mt-1">{rulesError.pwdEmptyError}</LDParagraph>
                }
                {Object.keys(rulesError)?.length > 0 &&
                  <LDParagraph className="password-rules-error-style text-left ml-12 mt-1">{rulesError.error}</LDParagraph>
                }
              </Form.Item>
              </LDTooltip>
              
              <Form.Item name="password" className="mt-2">
                <LDNewInput
                  name="password"
                  type={confirmPasswordShown ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confmPassword}
                  style={{border: Object.keys(rulesError)?.length > 0 && "1px solid #FF7373" }}
                  onChange={e => {
                    setConfmPassword(e?.target?.value);
                  }}
                  className={`bg-secondary rounded px-4 py-[0.65rem] border border-formBorder  w-3/4 auth-input-text-style`}
                />
                <Image
                  src={confirmPasswordShown ? imagePath("/show.svg") :imagePath("/hide.svg") }
                  onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
                  alt=""
                  className="h-6 w-6 cursor-pointer absolute  top-2 right-14"
                />
                 {Object.keys(rulesError)?.length > 0 &&
                   <LDParagraph className="password-rules-error-style text-left ml-12 mt-1">{rulesError.error}</LDParagraph>
                 }
                 {Object.keys(rulesError)?.length > 0 &&
                   <LDParagraph className="password-rules-error-style text-left ml-12 mt-1">{rulesError.passwordError}</LDParagraph>
                 }
              </Form.Item>
             </>
            }
            <div className={isCreateAccount ||  PROVIDER.includes(user?.provider)  ? "mt-4" :""}>
              <FormButton
                htmlType="submit"
                onClick={handleFireEvent}
                style={{ backgroundColor: COLOR_CONSTANTS.GREEN ,color:"white"}}
                className={`bg-greeny auth-button-text-style w-3/4 mt-1 rounded mx-auto`}
              >
                Save
              </FormButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateProfile;