import { LDAnalytics } from "utils/analyticsHelper.js";

class MycoursesEvents extends LDAnalytics {
  event_MyCourses_Load_Start() {
    return {
      event: {
        module_name: "Mycourses",
        feature_name: "MyCourses Page",
        event_name: "MyCourses_Load_Start",
        event_label: "Mycourses Load",
      },
    };
  }

  event_MyCourses_Load_End() {
    return {
      event: {
        module_name: "Mycourses",
        feature_name: "MyCourses Page",
        event_name: "MyCourses_Load_End",
        event_label: "Mycourses Load",
      },
    };
  }
  event_MyCourses_Course_Play_click() {
    return {
      event: {
        module_name: "MyCourses",
        feature_name: "MyCourses  Course Play",
        event_name: "MyCourses_Course_Play_click",
      },
    };
  }
  event_MyCourses_Course_View_Invoice_Click() {
    return {
      event: {
        module_name: "Mycourse",
        feature_name: "MyCourses Course Invoice View",
        event_name: "MyCourses_Course_View_Invoice_Click",
      },
    };
  }
  event_MyCourses_Course_Purchase_Click() {
    return {
      event: {
        module_name: "Mycourses",
        feature_name: "MyCourses Course Purchase",
        event_name: "MyCourses_Course_Purchase_Click",
      },
    };
  }
  event_MyCourses_Course_Wishlist_Click(){
    return {
      event: {
        module_name: "Mycourses",
        feature_name: "MyCourses Course  Wishlist",
        event_name: "MyCourses_Course_Wishlist_Click",
        event_label: "",
      },
    };
  }
}

const mycoursesEvents = new MycoursesEvents();
export default mycoursesEvents;
