import Input from "stories/Input";
import { enableDateBeforeToday } from "utils";

const LDDateInput = props => {
const { type, handleChange, ldForm } = props;
const style = ldForm === "create-profile" ? { left: "13%", width: "74%" } : {};
  return (
    <div>
      <div className="relative">
        <Input {...props} type={type === "date" ? "text" : type } />
        {type === "date" && (
          <input
            onChange={e => {
              handleChange(e.target.value);
            }}
            className="absolute left-0 top-[20%] w-full opacity-0"
            type="date"
            required
            max = {enableDateBeforeToday()}
            style={style}
          />
        )}
      </div>
    </div>
  );
};

LDDateInput.defaultProps = {
    type: "text",
    ldForm: ""
}

export default LDDateInput;
