import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "stories/Carousel";
import Tags from "stories/Tags";
import { getQueryString } from "utils/getQueryString";
import {
  homeFilterApplyEvent,
  homeFilterClearAllEvent,
  homeFilterHeadingEvent,
  homeFilterOptionsEvent,
} from "analytics/home";
import PopOverMenu from "./Components/PopOverMenu";
import { cloneDeep } from "utils/objectHelper";
import { LDSpan } from "stories/Typography";
import equals from "ramda/src/equals"
import  useMedia  from 'hooks/deviceHelper/useMedia';
import useInfiniteScroll from "hooks/useInfiniteScroll";
import NewTags from "./NewTags";
import { Col, Row } from "antd";


const MultiTags = props => {
  const { tagList,setIsAppliedFilter,isAppliedFilter ,handleScroll} = props;
  const { attributes } = tagList;
  const [show,setShow] =useState(true)

  const dispatch = useDispatch();

  const [activeExactFilter, setActiveExactFilter] = useState(0);
  const [activeRangeFilter, setActiveRangeFilter] = useState(-1);
  const [exactFilterObj, setExactFilterObj] = useState({});
  const [rangeFilterObj, setRangeFilterObj] = useState({});
  const [appliedExactFilter, setAppliedExactFilter] = useState({});
  const [appliedRangeFilter, setAppliedRangeFilter] = useState({});
  const [selectedLabel,setSelectedLabel] =useState("")

  const { exactFilters, rangeFilters } = attributes?.watchNowFilters;


  const filterApplied = Object.keys(appliedExactFilter)?.length || Object.keys(appliedRangeFilter)?.length;

  const isMobile = useMedia()

  const onTagSelect = (type, index,label) => { // Heading
    let selectedFilter = {};
    setSelectedLabel(label)
    if (type === "exact") {
      setActiveRangeFilter(-1);
      setActiveExactFilter(index);
      selectedFilter = exactFilters[index];
    } else {
      setActiveExactFilter(0);
      setActiveRangeFilter(index);
      selectedFilter = rangeFilters[index];
    }
    const eventParams = {
      event_label : selectedFilter?.filterLabel || ""
    }
    setShow(true)
    homeFilterHeadingEvent(eventParams);
  };
  
  const handleOptionSelect = optionIdx => { // Option select
    const activeFilter =
      activeExactFilter !== -1 ? activeExactFilter : activeRangeFilter;
    let tempFilterObj =
      activeExactFilter !== -1 ? cloneDeep(exactFilterObj) : cloneDeep(rangeFilterObj);
    const singleSelect =
      activeRangeFilter !== -1 && !rangeFilters[activeFilter]?.isChainable;
    let selected = tempFilterObj[activeFilter] || [];
    if (!tempFilterObj.hasOwnProperty(activeFilter) || singleSelect) {
      tempFilterObj[activeFilter] = [optionIdx];
    } else {
      if (selected.includes(optionIdx)) {
        const itemIndex = selected.indexOf(optionIdx);
        selected.splice(itemIndex, 1);
      } else {
        selected.push(optionIdx);
      }
    }
    if (activeExactFilter !== -1) {
        setExactFilterObj(tempFilterObj);
        const clear = tempFilterObj && tempFilterObj[0] && !tempFilterObj[0].length
        handleApplyFilter(clear, tempFilterObj)
    } else {
      setRangeFilterObj(tempFilterObj);
    }
  };

  const handleClear = () => {
    const activeFilter =
      activeExactFilter !== -1 ? activeExactFilter : activeRangeFilter;
    let tempFilterObj =
      activeExactFilter !== -1 ? cloneDeep(exactFilterObj) : cloneDeep(rangeFilterObj);
    delete tempFilterObj[activeFilter];
    if (activeExactFilter !== -1) {
      setExactFilterObj(tempFilterObj);
    } else {
      setRangeFilterObj(tempFilterObj);
    }
  };

  const filterPaginationMeta  = useSelector(
    (state) => state?.home?.filterPaginationMeta?.pagination
  );

  const filterFullDataLoaded = filterPaginationMeta && filterPaginationMeta?.page >= filterPaginationMeta?.pageCount;

  const getFilterData = () => {
    // const options = { filters: finalFilterObj };
    // const result = getQueryString(options);
    // homeFilterApplyEvent();

    dispatch?.home?.requestApplyFilterOnWatchNow(null);
  };

  // useInfiniteScroll(getFilterData,filterFullDataLoaded, filterApplied);
 // Apply
  const handleApplyFilter = (clear = false, appliedFilter) => {
    // let newAppliedExactFilter = { ...exactFilterObj };
    let newAppliedExactFilter = appliedFilter;
    let newAppliedRangeFilter = { ...rangeFilterObj };
    if (clear) {
      newAppliedExactFilter = {};
      newAppliedRangeFilter = {};
      dispatch?.home?.requestClearFilterOnWatchNow();
      setExactFilterObj({});
      setRangeFilterObj({});
      handleScroll()
      setIsAppliedFilter(false)
      homeFilterClearAllEvent();
    } else {
      let finalFilterObj = {};
      let finalEventLabelArr = [];
      // const exactFilterIdxs = Object.keys(exactFilterObj);
      const exactFilterIdxs = Object.keys(appliedFilter);
      if (exactFilterIdxs.length) {
        for (let key in exactFilterIdxs) {
          const item = exactFilterIdxs[key];
          const _filterObj = exactFilters[item];
          const selected = appliedFilter[item];
          let finalSelected = [];
          let selectedLabels = [];
          for (let optKey in selected) {
            const optIdx = selected[optKey];
            const selectedOption = _filterObj?.options?.[optIdx];
            finalSelected.push(selectedOption?.value);
            selectedLabels.push(selectedOption?.label);
          }
          finalEventLabelArr.push(`${_filterObj?.filterLabel} - ${selectedLabels.join(',')}`)
          finalFilterObj[_filterObj?.targetAttribute] = {
            id: {
              $in: finalSelected,
            },
          };
        }
      }
      const rangeFilterIdxs = Object.keys(rangeFilterObj);
      if (rangeFilterIdxs.length) {
        for (let key in rangeFilterIdxs) {
          const item = rangeFilterIdxs[key];
          const _filterObj = rangeFilters[item];
          const selected = rangeFilterObj[item];
          const isChainable = _filterObj?.isChainable;
          let selectedLabels = [];
          if (isChainable) {
            let chainableArr = [];
            for (let optKey in selected) {
              const optIdx = selected[optKey];
              const selectedObj = _filterObj?.options?.[optIdx];
              const { op, value, label: rangeObjLabel } = selectedObj;
              const val = op === "between" ? value?.split(",") : value;
              selectedLabels.push(rangeObjLabel);
              chainableArr.push({
                [_filterObj?.targetAttribute]: {
                  [`$${op}`]: val,
                },
              });
            }
            finalFilterObj[`$${_filterObj?.combineUsing}`] = chainableArr;
          } else {
            const selectedOptObj = _filterObj?.options[selected[0]] || {};
            const { op, value, label: rangeObjLabel } = selectedOptObj;
            const valArr = value?.split(",");
            selectedLabels.push(rangeObjLabel);
            finalFilterObj[_filterObj?.targetAttribute] = {
              [`$${op}`]: valArr,
            };
          }
          finalEventLabelArr.push(`${_filterObj?.filterLabel} - ${selectedLabels.join(',')}`)
        }
      }
      const options = { filters: finalFilterObj };
      const result = getQueryString(options);
      const eventParams = {
        event_label : finalEventLabelArr.join(' | ') || ""
      }
      homeFilterApplyEvent(eventParams);

      dispatch?.home?.requestApplyFilterOnWatchNow(result,()=>{
        setIsAppliedFilter(true)
      });
    }

    //on apply
    // setActiveExactFilter(-1);
    setActiveExactFilter(0)  // LATER : comment this and uncomment above one 
    setActiveRangeFilter(-1);
    setAppliedExactFilter(newAppliedExactFilter);
    setAppliedRangeFilter(newAppliedRangeFilter);
  };

  const handleCancel = () => {
    setActiveExactFilter(-1);
    setActiveRangeFilter(-1);
    setExactFilterObj({...appliedExactFilter});
    setRangeFilterObj({...appliedRangeFilter});
  };

  let activeFilterOptions = [];
  
  let activeFilterSelected = [];
  if (activeExactFilter !== -1) {
    activeFilterOptions = exactFilters?.[activeExactFilter]?.options;
    activeFilterSelected = exactFilterObj[activeExactFilter] || [];
  } else if(activeRangeFilter !== -1) {
    activeFilterSelected = rangeFilterObj[activeRangeFilter] || [];
    activeFilterOptions = rangeFilters?.[activeRangeFilter]?.options;
  }

  const isApplyDisabled = equals(exactFilterObj,appliedExactFilter) && equals(rangeFilterObj,appliedRangeFilter)
  
  {/* -------------------- DON'T DELETE ---------------------- */}

  // const renderFilters = () => {
  //   return (
  //     <>
  //     <div className="py-1 flex justify-end lg:justify-between items-center">
  //       <div>
  //         {attributes !== undefined &&
  //           exactFilters?.map((_tags, idx) => {
  //             const { name, options, filterLabel } = _tags;
  //             let selected = activeExactFilter === idx;
  //             if((appliedExactFilter.hasOwnProperty(idx) && appliedExactFilter[idx]?.length)  || (exactFilterObj?.hasOwnProperty(idx) && exactFilterObj[idx]?.length)){
  //               selected = true;
  //             }
  //             return (
  //               <Tags
  //                 key={idx}
  //                 isSelected={selected}
  //                 id={name}
  //                 handleClick={() => onTagSelect("exact", idx,filterLabel)}
  //                 list={options}
  //               >
  //                 {filterLabel}
  //               </Tags>
  //             );
  //           })}
  //         {attributes !== undefined &&
  //           rangeFilters?.map((_tags, idx) => {
  //             const { name, options, filterLabel } = _tags;
  //             let selected = idx === activeRangeFilter;
  //             if((appliedRangeFilter.hasOwnProperty(idx) && appliedRangeFilter[idx]?.length) || (rangeFilterObj?.hasOwnProperty(idx) && rangeFilterObj[idx]?.length)){
  //               selected = true;
  //             }
  //             return (
  //               <Tags
  //                 key={idx}
  //                 isSelected={selected}
  //                 id={name}
  //                 handleClick={() => onTagSelect("range", idx,filterLabel)}
  //                 list={options}
  //               >
  //                 {filterLabel}
  //               </Tags>
  //             );
  //           })}
  //       </div>
  //       {filterApplied ? <LDSpan className={`cursor-pointer lg:mr-14`} onClick={ ()=> { handleApplyFilter(true) }}>Clear All</LDSpan> : ""}
  //     </div>
  //   </>
  //   )
  // }
  return (
    <div className="w-full relative">
      {/* -------------------- DON'T DELETE ---------------------- */}
        {/* {isCarousel ? (
          <Carousel variableWidth={true}>{renderFilters()}</Carousel>
        ): renderFilters()} */}
      {/* {(activeExactFilter !== -1 || activeRangeFilter !== -1) && (
        <PopOverMenu
          options={activeFilterOptions}
          handleOptionSelect={handleOptionSelect}
          selected={activeFilterSelected}
          handleCancel={handleCancel}
          handleApplyFilter={handleApplyFilter}
          handleClear={handleClear}
          show={show}
          setShow={()=>setShow(false)}
          selectedLabel={selectedLabel}
          isApplyDisabled={isApplyDisabled}
        />
      )} */}

      {isMobile && filterApplied ? <LDSpan className="absolute top-[-28px] right-0" onClick={ ()=> { handleApplyFilter(true) }}>Clear</LDSpan> : ""}
        {(activeExactFilter !== -1 ) && 
          <Row>
          <Col span={isMobile ? 24 : 22}>
            {/* <Carousel variableWidth={true} dots={false}> */}

                  <NewTags options={activeFilterOptions} handleOptionSelect={handleOptionSelect}
                    selected={activeFilterSelected} handleApplyFilter={handleApplyFilter}
                  />
            {/* </Carousel> */}
          </Col>
          {!isMobile && (
          <Col span={2}>
            {filterApplied ? <LDSpan className={`cursor-pointer lg:mr-14`} onClick={ ()=> { handleApplyFilter(true) }}>Clear</LDSpan> : ""}
          </Col>
          )}
        </Row>
       }
    </div>
  );
};

export default MultiTags;
