import { homeContinueWatchingCardEvent } from "analytics/home";
import Card from "components/Card";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import { Fragment, useState ,useEffect } from "react";
import Carousel from "stories/Carousel";
import StarRating from "stories/StarRating";
import { Heading, LDParagraph, LDSpan,LDSmall } from "stories/Typography";
import { homeContinueWatchingSeeAllEvent, homeContinueWatchingSeeLessEvent } from "analytics/home";
import { getRoundedVal } from "utils";
import { useSelector } from 'react-redux';
import "../../index.css"

const ContinueWatching = (props) => {
  const {
    getRating,
    isStarRatingActive,
    continueWatchList,
  } = props;
  const appCongif = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)

  const isMobile = useMedia();
  const [itemsToShow, setItemsToShow] = useState(0);
  const [toggle, setToggle] = useState(true);
  const { CONTINUE_WATCHING_THUMB_DIMENSION, CAROUSEL_CARD_SHOW_LENGTH } = appCongif;
  useEffect(()=>{
    if(appCongif){
      setItemsToShow(appCongif?.CONTINUE_WATCH_NOW_INIT_LIST_LENGTH)
    }
  },[appCongif])

  const handleSeeAll = () => {
    const continue_watching_episode_count = continueWatchList?.length
    if (toggle) {
      setItemsToShow(continueWatchList?.length);
      const extraParams = {
        event_label: continue_watching_episode_count
      }
      homeContinueWatchingSeeAllEvent(extraParams)
      setToggle(false);
    } else {
      setItemsToShow(appCongif?.CONTINUE_WATCH_NOW_INIT_LIST_LENGTH);      
      const extraParams = {
        event_label: continue_watching_episode_count
      }
      homeContinueWatchingSeeLessEvent(extraParams)
      setToggle(true);
    }
  };
  const handleClick=(title, courseTitle)=>{
    const params ={ 
      course_name : courseTitle,
      episode_name : title
    }
    homeContinueWatchingCardEvent(params)
  }
  return (
    <>
      {/* continue watching mobile*/}
      <div className="flex justify-between items-center">
        {continueWatchList?.length !==0 && <Heading className="tracking-wide heading-style-home">Continue Watching</Heading>}
        { continueWatchList?.length > appCongif?.CONTINUE_WATCH_NOW_INIT_LIST_LENGTH &&
          <LDParagraph
            onClick={handleSeeAll}
            className="hidden lg:block text-sm lg:text-md  cursor-pointer text-greeny"
          >
           { toggle ? "See all" :"See less"}
          </LDParagraph>
      }
      </div>
      
      {isMobile ? (
        <Carousel slidesToShow={CAROUSEL_CARD_SHOW_LENGTH} className="slick-width" arrowsEnabled={true}>
          {continueWatchList?.map((_list, i) => {
            const {
              title, 
              courseRating, 
              courseId, 
              duration,
              episodeNumber,
              totalEpisodeCount, 
              thumbnailUrl,
              lastWatchedLocation,
              episodeId,
              courseTitle,
              courseSlug,
              episodeSlug
            } = _list;
            const content = (
              <StarRating
                isStarRatingActive={isStarRatingActive}
                getRating={getRating}
                rating={getRoundedVal(courseRating)}
                // marginRight="rating"
                size={`10px`}   
              />
            );
            return (
              <Fragment key={i}>
                <Card
                  className=""
                  title={title}
                  subtitle={
                    <LDSpan className="text-greeny py-2 home-episode-text-green-bold">
                      Episode {episodeNumber } of{" "}
                      {totalEpisodeCount}
                    </LDSpan>
                  }
                  content={content}
                  noBg={true}
                  playIcon={true}
                  route={`/${ROUTE_CONSTANTS?.EPISODE}/${courseSlug}/${episodeSlug}`}
                  cardImg={thumbnailUrl}
                  // imageCardCls="lg:h-20v md:h-[10vh] h-[13vh] w-full"
                  imageCardCls="h-full"
                  imageCls={"w-full sm:h-[6.2rem] xs:h-[5rem] md:h-[5rem] lg:h-32"}
                  widthSet={true}
                  handleClick ={()=>handleClick(title, courseTitle)}
                  thumbDimension={CONTINUE_WATCHING_THUMB_DIMENSION}
                />
              </Fragment>
            );
          })}
        </Carousel>
      ) : (
        <div className="md:grid md:grid-cols-3 lg:whitespace-normal lg:grid lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-10 gap-y-5">
          {/* continue watching Desktop*/}
          {continueWatchList?.slice(0,itemsToShow)?.map((_list, i) => {
            const {
              title, 
              courseRating, 
              courseId, 
              episodeNumber,
              duration,
              totalEpisodeCount, 
              thumbnailUrl,
              lastWatchedLocation,
              episodeId,
              courseTitle,
              courseSlug,
              episodeSlug
            } = _list;
            const content = (
              <StarRating
                isStarRatingActive={isStarRatingActive}
                getRating={getRating}
                rating={courseRating}
              />
            );
            return (
              <Fragment key={i}>
                <Card
                  title={title}
                  subtitle={
                    <LDSmall className="text-greeny text-xs lg:text-[.7rem] home-episode-text-green-bold  py-2">
                      Episode {episodeNumber } of{" "}
                      {totalEpisodeCount}
                    </LDSmall>
                  }
                  content={content}
                  noBg={true}
                  route={`/${ROUTE_CONSTANTS.EPISODE}/${courseSlug}/${episodeSlug}`}
                  cardImg={thumbnailUrl}
                  playIcon={true}
                  // playStyle="absolute"
                  handleClick ={()=>handleClick(title, courseTitle)}
                  // imageCardCls="lg:h-[18vh]  2xl:h-[12vh] h-10v"
                  imageCardCls="h-full"
                  imageCls={"w-full sm:h-[6rem] xs:h-[4.5rem] md:h-[5rem] lg:h-[8.438rem] xl:h-full"}   //xl:h-[10rem]
                  thumbDimension={CONTINUE_WATCHING_THUMB_DIMENSION}
                />
              </Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ContinueWatching;
