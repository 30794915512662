import * as eventConstants from "./events.constants";
import navbarEvents from "./NavbarEvents";

const handleEvent = (eventCode, extraParams = {}) => {
  navbarEvents.handle_analytics_event(eventCode, extraParams);
};

export const navbarHamburgerMenuClickEvent = () => {
  const eventCode = eventConstants?.NAVBAR_HAMBURGER_ICON_CLICK;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};

export const navbarLogoClickEvent = () => {
  const eventCode = eventConstants?.NAVBAR_LOGO_CLICK;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};

export const navbarSearchInputClickEvent = () => {
  const eventCode = eventConstants?.NAVBAR_SEARCH_INPUT_CLICK;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};

export const navbarNotificationIconClickEvent = (extraParams) => {
  const eventCode = eventConstants?.NAVBAR_NOTIFICATION_ICON_CLICK;
  handleEvent(eventCode, extraParams);
};
export const navbarProfilePicClickEvent = (extraParams) => {
  const eventCode = eventConstants?.NAVBAR_PROFILE_PIC_CLICK;
  handleEvent(eventCode, extraParams);
};

export const navbarProfilePicProfileOptionClickEvent = () => {
  const eventCode = eventConstants?.NAVBAR_PROFILE_PIC_PPOFILE_OPTION_CLICK;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};
export const navbarProfilePicLogoutOptionClickEvent = () => {
  const eventCode = eventConstants?.NAVBAR_PROFILE_PIC_LOGOUT_OPTION_CLICK;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};
