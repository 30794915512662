import MessagePopUp from "components/MessagePopUp";
import { useDispatch, useSelector } from "react-redux";
import { getItemFromStorage } from "utils/storage.helper";

const PushNotification = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state?.profile?.profileDetails);
  const token = getItemFromStorage("fcm_token");

  const updateFcmToDb = (notify) => {
    const body = {
      fcmToken: token,
      notifyUser: notify,
      userId: profile?.id,
    };
    dispatch?.pushNotification?.requestAddPushNotification(body);
  };

  const updateNotificationPref = (allowed = false) => {
    dispatch?.pushNotification?.setNotificationStateData({
      requestActive: false,
    });
    if (token && profile?.id) {
      updateFcmToDb(allowed);
    }
  };

  const allowNotification = () => {
    if (!("Notification" in window)) {
      return console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          updateNotificationPref(true);
        } else {
          updateNotificationPref(false);
        }
      });
    }
  };

  const denyNotification = () => {
    updateNotificationPref(false);
  };
  return (
    <MessagePopUp
      title='Let us in!'
      desc='Allow us to share updates that will make your life better everyday.'
      confirmHandler={allowNotification}
      cancelHandler={denyNotification}
    />
  );
};

export default PushNotification;
