import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { imagePath } from "utils/assetHelper";

export const sidebarRoutes = [
  {
    id: 1,
    title: "Home",
    to: ROUTE_CONSTANTS?.HOME,
    navName: "Home",
    src: imagePath("/Sidebar/home.svg"),
    filledSrc: imagePath("/ActiveLogo/homeActive.svg"),
  },
  {
    id: 2,
    title: "Instructors",
    to: "/instructors",
    navName: "Instructors",
    src: imagePath("/Sidebar/instructorsUser.svg"),
    filledSrc: imagePath("/ActiveLogo/instructorsUser.svg"),
  },
  {
    id: 3,
    title: "My Sessions",
    to: "/my-session",
    navName: "My Sessions",
    src: imagePath("/Sidebar/mySession.svg"),
    filledSrc: imagePath("/ActiveLogo/mySessionActive.svg"),
  },
  {
    id: 4,
    title: "My Courses",
    to: "/mycourses",
    navName: "My Courses",
    src: imagePath("/Sidebar/course.svg"),
    filledSrc: imagePath("/ActiveLogo/mycourseActive.svg"),
  },
  {
    id: 5,
    title: "Wishlist",
    to: "/wishlist",
    navName: "Wishlist",
    src: imagePath("/Sidebar/whishlist.svg"),
    filledSrc: imagePath("/ActiveLogo/wishlistActive.svg"),
  },


];

export const hiddenRoutes = [
  {
    id: 1,
    title: "Refer a friend",
    to: "/referral",
    src: imagePath("/Sidebar/refer.svg"),
    filledSrc: imagePath("/ActiveLogo/referfriendActive.svg"),
  },
  {
    id: 2,
    title: "Support",
    to: "/suggestions",
    src: imagePath("/Sidebar/user.svg"),
    filledSrc: imagePath("/ActiveLogo/userActive.svg"),
  },
  // {
  //   id: 4,
  //   title: "Offers",
  //   to: "/offers_and_discount",
  //   src: imagePath("/Sidebar/offer.svg"),
  //   filledSrc: imagePath("/ActiveLogo/offerActive.svg"),
  // },
  {
    id: 3,
    title: "Child mode",
    to: "/settings",
    src: imagePath("/setting/shield-lock.svg"),
    filledSrc: imagePath("/setting/shield-lock.svg"),
    ignoreHighlight: true
  },
  {
    id: 5,
    title: "Settings",
    to: "/settings",
    src: imagePath("/Sidebar/setting.svg"),
    filledSrc: imagePath("/ActiveLogo/settingActive.svg"),
  },
  // {
  //   id: 7,
  //   title: "Help and Feedback",
  //   to: "/help",
  //   src: imagePath("/Sidebar/help.svg"),
  //   filledSrc: imagePath("/ActiveLogo/helpActive.svg"),
  // },
  {
    id: 8,
    title: "Privacy Policy",
    to: "/privacy",
    src: imagePath("/Sidebar/privacy.svg"),
    filledSrc: imagePath("/ActiveLogo/privacyActive.svg"),
  },
];

export const mobileRoutes = [
  {
    id: 1,
    title: "Home",
    to: ROUTE_CONSTANTS?.HOME,
    navName: "Home",
    src: imagePath("/Sidebar/home.svg"),
    filledSrc: imagePath("/ActiveLogo/homeActive.svg"),
  },
  {
    id: 2,
    title: "Instructors",
    to: "/instructors",
    navName: "Instructors",
    src: imagePath("/Sidebar/instructorsUser.svg"),
    filledSrc: imagePath("/ActiveLogo/instructorsUser.svg"),
  },
  {
    id: 3,
    title: "My Sessions",
    to: "/my-session",
    navName: "My Sessions",
    src: imagePath("/Sidebar/mySession.svg"),
    filledSrc: imagePath("/ActiveLogo/mySessionActive.svg"),
  },
  {
    id: 4,
    title: "My Courses",
    to: "/mycourses",
    navName: "My Courses",
    src: imagePath("/Sidebar/course.svg"),
    filledSrc: imagePath("/ActiveLogo/mycourseActive.svg"),
  },
  {
    id: 5,
    title: "Wishlist",
    to: "/wishlist",
    navName: "Wishlist",
    src: imagePath("/Sidebar/whishlist.svg"),
    filledSrc: imagePath("/ActiveLogo/wishlistActive.svg"),
  },
  {
    id: 6,
    title: "Refer a friend",
    to: "/referral",
    src: imagePath("/Sidebar/refer.svg"),
    filledSrc: imagePath("/ActiveLogo/referfriendActive.svg"),
  },
  {
    id: 7,
    title: "Support",
    to: "/suggestions",
    src: imagePath("/Sidebar/user.svg"),
    filledSrc: imagePath("/ActiveLogo/userActive.svg"),
  },
  // {
  //   id: 8,
  //   title: "Offers",
  //   to: "/offers_and_discount",
  //   src: imagePath("/Sidebar/offer.svg"),
  //   filledSrc: imagePath("/ActiveLogo/offerActive.svg"),
  // },
  {
    id: 9,
    title: "Child mode",
    to: "/settings",
    src: imagePath("/setting/shield-lock.svg"),
    filledSrc: imagePath("/setting/shield-lock.svg"),
    ignoreHighlight: true
  },
  {
    id: 10,
    title: "Settings",
    to: "/settings",
    src: imagePath("/Sidebar/setting.svg"),
    filledSrc: imagePath("/ActiveLogo/settingActive.svg"),
  },
  // {
  //   id: 11,
  //   title: "Help and Feedback",
  //   to: "/help",
  //   src: imagePath("/Sidebar/help.svg"),
  //   filledSrc: imagePath("/ActiveLogo/helpActive.svg"),
  // },
  {
    id: 12,
    title: "Privacy Policy",
    to: "/privacy",
    src: imagePath("/Sidebar/privacy.svg"),
    filledSrc: imagePath("/ActiveLogo/privacyActive.svg"),
  },
  {
    id: 13,
    title: "Profile",
    to: "/profile",
    src:imagePath("/profile.svg"),
    filledSrc: imagePath("/filledProfile.svg"),
  },
  {
    id: 14,
    title: "Log Out",
    to: "#",
    src: imagePath("/Profile/logout.svg"),
    filledSrc: imagePath("/Profile/logout.svg"),
    onClick: (dispatch) => {
      dispatch?.authModel?.toggleLogoutModal(true);
    },
  },
];
