import { message } from "antd";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "axiosRequests";
import { GET_COMMENTS_LIST, GET_EPISODE_DETAIL, LIKE_DISLIKE_URL, LOG_EPISODE_COMMENT } from "constants/Apis";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { STATUS } from "constants/status.constant";
import { Notifier } from "components/Notifier";

const model = {
  state: {
    comments: [],
    episodeDetail:{},
    commentsLoading:true,
    episodeCommentsError:false,
    episodesDetailsLoading:true,
    episodeDescError:false
  },
  reducers: {
    setComments: (state, payload) => {
      return {
        ...state,
        comments: payload,
      };
    },
    setCommentsLoading :(state, payload) => {
      return {
        ...state,
        commentsLoading:payload
      }
    },
    setEpisodesDetailsLoading :(state, payload) => {
      return {
        ...state,
        episodesDetailsLoading:payload
      }
    },
    setEpisodeDetails:(state,payload)=>{
      return {
        ...state,
        episodeDetail:payload
      }
    },
    setErr: (state, payload) => {
      return {
        ...state,
        ...payload
      }
    }
  },
  effects: (dispatch) => ({
    async requestComments(episodeId) {
      try{
        const response = await axiosGet(`${GET_COMMENTS_LIST}:${episodeId}?sort=createdAt:desc`);
        dispatch?.episodes?.setCommentsLoading(true)
        const { data ,status} = response;
        if(response === undefined){
          return dispatch?.episodes?.setCommentsLoading(true)
        }
        if(STATUS.includes(status)){
          dispatch?.episodes?.setCommentsLoading(false)
          dispatch?.episodes?.setComments(data);
          dispatch?.episodes?.setErr({episodeCommentsError : false})
        }
        else{
          dispatch?.episodes?.setCommentsLoading(false)
        }
      }
      catch(err){
        dispatch?.episodes?.setErr({episodeCommentsError : true})
        dispatch?.episodes?.setCommentsLoading(false)
        console.log(err,"err in comment section")
      } 
    },
    async requestAddComments(body ,undefined,success){
      try{
        const response = await axiosPost(`${GET_COMMENTS_LIST}:${body?.episodeId}`,body?.content)
        const {data, status } = response;
        if(STATUS.includes(status)){
          dispatch?.episodes?.requestComments(body?.episodeId)
          Notifier(status, MESSAGE_CONSTANTS. ADD_COMMENT)
        }else{
          Notifier(status, MESSAGE_CONSTANTS.ADD_COMMENT_ERR_MSG)
        }
      }
      catch(err){
        Notifier('Error', MESSAGE_CONSTANTS.ADD_COMMENT_ERR_MSG)
        console.log(err,"err in add comment")
      }
    },
    async requestSendReply(body,state,success){
      try{
          const payload={
            content:body?.content,
            threadOf:body?.threadOf
          }
          const episodeId=Number(body?.episodeId)
          const response = await axiosPost(`${GET_COMMENTS_LIST}:${episodeId}`,payload)
          const { data ,status} = response;
          if(STATUS.includes(status)){
            dispatch?.episodes?.requestComments(episodeId)
            success(MESSAGE_CONSTANTS.REPLY_COMMENT)
          }
      }
      catch(err){
        console.log(err,"err in send reply")
      }
    },
    async requestDeleteComment(body,undefined,success){
      try{
        const episodeId=body?.episodeId
        const commentId=body?.commentId
        const response=await axiosDelete(`${GET_COMMENTS_LIST}:${episodeId}/comment/${commentId}?authorId=${body?.authorId}`)
        const {data, status}=response
        if(STATUS.includes(status)){
           dispatch?.episodes?.requestComments(episodeId)
           success(MESSAGE_CONSTANTS.DELETE_COMMENT)
        }else{
          message.error(data?.error?.message)
        }
      }
      catch(err){
        console.log(err,"err Delete Msg")
      }
    },
    async requestEditComments(body,undefined,success){
      try{
        const payload = {
          content:body?.content
        }
        const episodeId = body?.episodeId
        const commentId = body?.commentId
        const response = await axiosPut(`${GET_COMMENTS_LIST}:${episodeId}/comment/${commentId}`,payload)      
        const {data, status} = response
        if(STATUS.includes(status)){
          dispatch?.episodes?.requestComments(episodeId)
          success(MESSAGE_CONSTANTS.EDIT_COMMENT)
        }else{
           message.error(data?.error?.message)
        }
      }
      catch(err){
        console.log(err,"error is Edit comment")
      }
    },
    async requestEpisodeDetails(obj,state, cb){
      try{
        dispatch?.episodes?.setEpisodesDetailsLoading(true)
        const EPISODE = "episode"
        const response = await axiosGet(`${GET_EPISODE_DETAIL}/${obj?.courseId}/${EPISODE}/${obj?.episodeId}`)
        const { data, status } = response 
        dispatch?.episodes?.setEpisodesDetailsLoading(false)
        dispatch?.episodes?.setEpisodeDetails(data) 
        cb(data, status);
        dispatch?.episodes?.setErr({episodeDescError : false})
      }
      catch(err){
        dispatch?.episodes?.setErr({episodeDescError:true})
        dispatch?.episodes?.setEpisodesDetailsLoading(false)
        console.log(err,"err in epsiode details")
      } 
    },
    async requestEpisodesLikeDisLike(payload){
      try{
          const body = {
           episodeId :payload?.episodeId,
              type :payload?.type
          }
          const obj = { courseId: payload?.courseId, episodeId: payload?.episodeId }
          const response = await axiosPost(LIKE_DISLIKE_URL,body)
          const  {data ,status} =response
          if(STATUS.includes(status)){
            // dispatch?.episodes?.requestEpisodeDetails(obj,()=>{}) 
          }
      }
      catch(err){
        console.log(err,"Error in Like and dislike")
      }
    },
    async requestLogComment(payload){
      try {
        await axiosPost(LOG_EPISODE_COMMENT,payload)
      } catch (error) {
        console.log('Failed to log comment');
      }
    }
  }),
};
export default model;
 