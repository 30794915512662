import * as eventConstants from "./events.constants";
import sidebarEvents from "./SidebarEvents";

const handleEvent = (eventCode, extraParams = {}) => {
  sidebarEvents.handle_analytics_event(eventCode, extraParams);
};

export const sidebarMenuItemClickEvent = (extraParams) => {
  const eventCode = eventConstants?.SIDEBAR_MENU_ITEM_CLICK;
  handleEvent(eventCode, extraParams);
};