import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Row } from "antd";

import Navbar from "components/Navbar";
import SideBar from "components/Navbar/Sidebar";
import Home from "pages/Home";
import Course from "pages/Course";
import Whishlist from "pages/Whishlist";
import Footer from "components/Footer";
import useMedia from "hooks/deviceHelper/useMedia";

import MyCourses from "pages/MyCourses";
import Authentication from "components/Authentication";
import Payment from "pages/Payment";
import { hideNavBarRoutes, hideSidebarRoutes } from "constants/app.constants";
import useScrollTop from "hooks/useScrollTop";
import Episodes from "pages/Episodes";
import OffersAndDiscount from "pages/OffersAndDiscount";
import Settings from "pages/Settings";
import ReferFriend from "pages/ReferFriend";
import SearchResults from "pages/SearchResults";
import Profile from "pages/Profile";
import VerifyEmail from "pages/Profile/VerifyEmail";
import VerifyPhone from "pages/Profile/VerifyPhone";
import AddEmail from "pages/Profile/AddEmail";
import Suggestion from "pages/Suggestion";
import ComingSoon from "components/ComingSoon";
import ResetModal from "pages/ResetPassword";
import PaymentError from "pages/Payment/PaymentError";
import LanguagePreferences from "pages/Settings/LanguagePreferences";
import VideoQuality from "pages/Settings/VideoQuality";
import SocialLogin from "pages/SocialLogin";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticatedEffect from "hooks/useAuthenticatedEffect";
import PageNotFound from "pages/PageNotFound";
import { navbarHamburgerMenuClickEvent } from "analytics/navbar";
import PrivacyPolicy from "pages/PrivacyPolicy";
import EmailVerifyResponse from "pages/EmailVerifyResponse";
import PaymentSuccess from "pages/Payment/PaymentSuccess";
import useBrowserBack from "hooks/useBrowserBack";
import MobileScreenBanner from "components/MobileScreenBanner/Index";
import useMobileAppBanner from 'hooks/useMobileAppBanner';
import useGetWindowSize from "hooks/useGetWindowSize";
import { getWidthConfig } from 'utils/getWidthConfig';
import "./index.css"
import { checkPublicRoute, getRouterPath, removeFooterHandler } from "utils/navigationHelper";
import LogoutModal from "components/Authentication/LogoutModal";
import MobileNotifications from "pages/MobileNotifications";
import { SignupScreenLoaded } from "analytics/signupLogin";
import LandingPage from 'pages/LandingPage';
import GuestPayment from "pages/LandingPage/GuestPayment";
import ChildModePopUp from "components/ChildModePopUp";
import ResetChildModal from "pages/ResetChildPassword";
import Tour from "components/Tour";
import ProfileUpdate from "components/Profile/UpdateEmail";
import Welcome from "pages/Welcome";
import Instructors from "pages/Instructors";
import InstructorInformation from "pages/Instructors/components/instructorInformation";
import CreateSession from "components/CreateSession";
import BookingConfirmed from "pages/Instructors/components/BookingConfirmed";
import BookingDetails from "pages/Instructors/components/BookingDetails";
import MySession from "pages/Instructors/components/MySessions";
import AddReview from "pages/Instructors/components/AddReview";
import Invoice from "pages/Instructors/components/Invoice";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { Redirect } from "react-router-dom";
import ExternalPage from "pages/ExternalPage";
import DeleteAccount from "pages/DeleteAccount";

const App = () => {
  const isMobile = useMedia();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [sidebarActive, setSidebarActive] = useState(!isMobile);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const REACT_APP_ENV = process.env.REACT_APP_ENV
  const [showNavbar, setShowNavbar] = useState(false)
  const accountDetails = useSelector((state)=> state?.registerWithEmail?.account_details) || {} // to check token is present or not 
  const isCreateProfile= useSelector((state)=> state?.registerWithEmail?.isCreateProfile)   // to Open modal 
  const profileInfo = useSelector((state) => state?.profile?.profileDetails); // to check first name last name are present or not  
  const authModalActive = useSelector((state) => state?.authModel?.show);
  const logoutModalActive = useSelector((state) => state?.authModel?.logoutModActive);
  const childAuthModalActive = useSelector((state) => state?.profile?.childAuthModalActive);


  const { firstName } = profileInfo
  const windowSize = useGetWindowSize()


  const publicRoute = checkPublicRoute(location || {});

  useEffect(()=>{ 
    const config = getWidthConfig(windowSize?.innerWidth)
    if(config){
      dispatch?.commonParams?.updateBreakPointWiseAppConfig(config)
    }
  },[windowSize])
  
  useEffect(()=>{
    if(REACT_APP_ENV !== "dev" && !isMobile ){
      document.addEventListener("contextmenu",(event)=>{
        event.preventDefault()
      })
    }
  },[REACT_APP_ENV])
  const showAppBanner  = useMobileAppBanner()
  useBrowserBack();
  useScrollTop();
  useEffect(() => {
    let path = getRouterPath(location?.pathname);
    let value = hideSidebarRoutes.includes(path[1]);
    let hideNav = hideNavBarRoutes.includes(path[1]);
    setHideSidebar(value);
    setShowNavbar(!hideNav)
    if (!value) setMobileSidebar(false);
  }, [location.pathname]);

  useEffect(()=>{ 
    if(firstName !== null){
      dispatch?.registerWithEmail?.requestCreateProfile(false)
    }
    else{
      dispatch?.registerWithEmail?.requestCreateProfile(true)
    }
},[profileInfo])
  
  // For Closing/Opening Create/Login Modal when we have Token 
  useEffect(()=>{
    if(accountDetails?.jwt?.length > 0){
      dispatch?.authModel?.isNotLoggedIn(false); // closing the First modal Modal 
    }
    else{
      dispatch?.authModel?.isNotLoggedIn(true);  // To Open First Modal
    }
   },[accountDetails])

  const toggleSidebar = () => {
    if (!isMobile) {
      navbarHamburgerMenuClickEvent()
      setSidebarActive(!sidebarActive);
    } else {
      navbarHamburgerMenuClickEvent()
      setSidebarActive(!sidebarActive);
      setMobileSidebar(!mobileSidebar);
    }
  };

  const toggleHideSidebar = () => {
    if (hideSidebar) {
      setMobileSidebar(!mobileSidebar);
    }
  };
  useEffect(()=>{  
    if(!publicRoute && authModalActive){
      SignupScreenLoaded()
    }
  },[authModalActive])
  const isHideFooter = removeFooterHandler(location)
  return (
    <>
      {!publicRoute && authModalActive && <Authentication isCreateProfile={isCreateProfile}/>}
      {isCreateProfile && <Authentication isCreateProfile={isCreateProfile}/>}
      {showAppBanner && isMobile && <MobileScreenBanner show={showAppBanner}/>}
      {logoutModalActive && <LogoutModal />}
      {childAuthModalActive && <ChildModePopUp />}
      {/* <Tour/> */}
      <ProfileUpdate />
      {showNavbar &&  
        <Navbar
          sidebarActive={sidebarActive}
          toggleSidebar={toggleSidebar}
          toggleHideSidebar={toggleHideSidebar}
          hideSidebar={hideSidebar}
        />
      }
      <Row wrap={false}>
        <Col
          xs={0}
          lg={hideSidebar ? 0 : sidebarActive ? 3 : 1}
          // xxl={hideSidebar ? 0 : sidebarActive ? 3 : 1 }
        >
          <SideBar
            sidebarActive={sidebarActive}
            mobileSidebar={mobileSidebar}
            toggleSidebar={toggleSidebar}
            hideSidebar={hideSidebar}
            toggleHideSidebar={toggleHideSidebar}
          />
        </Col>

        <Col
          xs={24}
          lg={hideSidebar ? 24 : sidebarActive ? 21 : 23 }
          // xxl={hideSidebar ? 24 : sidebarActive ? 21 : 23 }
        >
          <Route path ="/connect/:provider/redirect" component={SocialLogin} exact/>

          <Switch>

            {/* External Iframe Routes Start */}
            <Route path="/" component={ExternalPage} exact />
            <Route path="/about" component={ExternalPage} exact />
            <Route path="/contact" component={ExternalPage} exact />
            <Route path="/courses/:id" component={ExternalPage} exact />
            <Route path="/blogs" component={ExternalPage} exact />
            <Route path="/blog/:id" component={ExternalPage} exact />
            <Route path="/terms-and-conditions" component={ExternalPage} exact />
            {/* External Iframe Routes End */}

            <Route path="/home" component={Home} exact />
            <Route path="/course/:id" component={Course} exact />
            <Route path="/mycourses" component={MyCourses} exact />
            <Route path="/wishlist" component={Whishlist} exact />
            <Route
              path="/episode/:courseId/:episodeId"
              component={Episodes}
              exact
            />
            <Route path="/payment" component={Payment} exact />
            <Route
              path="/offers_and_discount"
              component={OffersAndDiscount}
              exact
            />
            <Route path="/settings" component={Settings} exact />
            <Route path="/notification" component={MobileNotifications} exact />
            <Route path="/referral" component={ReferFriend} exact />
            <Route path="/search" component={SearchResults} exact />
            <Route path="/profile" component={Profile} exact />
            <Route path="/profile/verify-email" component={VerifyEmail} exact />
            <Route path="/profile/verify-phone" component={VerifyPhone} exact />
            <Route path="/profile/add-email" component={AddEmail} exact />
            <Route path="/suggestions" component={Suggestion} exact />
            <Route path="/langagage" component={ComingSoon} exact/>
            <Route path="/help" component={ComingSoon} exact/>
            <Route path="/privacy" component={PrivacyPolicy} exact/>      
            <Route path ="/landing/:courseId/:courseName" component={LandingPage} exact/>
            <Route path="/guest-payment" component={GuestPayment} exact />
            <Route path="/reset-password" component={ResetModal} exact />
            <Route path="/reset-childmode-password" component={ResetChildModal} exact />
            <Route path ="/payment/success" component={PaymentSuccess} exact />
            <Route path ="/payment/error" component={PaymentError} exact />
            <Route path="/settings/language-preference" component={LanguagePreferences} exact/>
            <Route path="/settings/video-quality" component={VideoQuality} exact/>
            <Route path ="/email-verify/:msg" component={EmailVerifyResponse}  exact />
            <Route path ="/instructors" component={Instructors}  exact />
            <Route path="/instructor-details/:id" component={InstructorInformation} exact/>
            <Route path="/instructor-calendar/:id" component={InstructorInformation} exact/>
            <Route path ="/create-session" component={CreateSession}  exact />
            <Route path ="/booking-confirmed/:id" component={BookingConfirmed}  exact />
            {/* <Route path ="/booking-details/:id" component={BookingDetails} exact />  */}
            <Route path ="/my-session" component={MySession}  exact />   
            <Route path ="/session-feedback" component={AddReview}  exact />   
            <Route path ="/session/invoice/:id" component={Invoice}  exact /> 
            <Route path ="/delete-account" component={DeleteAccount}  exact />                               
            <Route path="*" component={PageNotFound} exact  />
          </Switch>
        </Col>
      </Row>
      <div className="">
        {isHideFooter && <Footer /> }
      </div>
    </>
  );
};

export default App;