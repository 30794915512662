import "./index.css";
import CardImage from "components/Card/CardImage";
import CreateSession from "components/CreateSession";
import AvailableTime from "components/CreateSession/AvailableTime";
import Ratings from "pages/Instructors/components/Ratings";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useLocation } from "react-router-dom";
import FormButton from "stories/FormButton";
import { Description, Heading, LDParagraph, LDSpan, Subtitle, Title } from "stories/Typography";
import { getImgDimensionSrc, imagePath } from "utils/assetHelper";
import CalenderModal from "./calenderModal";
import Paragraph from "antd/lib/typography/Paragraph";
import LDMarkdown from "stories/LDMarkdown";
import AboutInstructorModal from "./AboutInstructorModal";
import { NumberWithFirstDecimal, UTCTimeConverter, formatDate, getSlotsGropued } from "utils";
import HomeError from "pages/Home/components/Errors";
import VideoPlayerLoader from "stories/SkeletonLoader/VideoPlayerLoader";
import MultiLoadingCards from "components/MultiLoadingCards";
import MultiLoadingBanner from "components/MultiLoadingBanner";
import MultipleProfileLoader from "components/MultipleProfileLoader";
import { useQuery } from "hooks/useQuery";
import VideoPlayer from "stories/VideoPlayer";
import useMedia from "hooks/deviceHelper/useMedia";
import useIpEffect from "hooks/useIpEffect";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import LDSlideUp from "stories/LDSlideUP";
import Image from "components/Image";


const InstructorInformation = () => {
  const location = useLocation()
  const isMobile = useMedia("(max-width: 874px)")
  const [isShowMore, setIsShowMore] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  useEffect(() => {
    const fcEl = document?.getElementById("fc_frame")
    if (fcEl && isCalendarOpen) {
      fcEl.style.display = "none"
    }else if(fcEl && !isCalendarOpen){
      fcEl.style.display = "block"
    }
  }, [isCalendarOpen])

  const handleOpenModal = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  const dataForReschedule = location?.state

  // const handleCalender = () => {
  //   setCalenderModal(true);
  // };
  const query = useQuery()
  const value = query?.get("_rs")
  const sessionStatus = query?.get("status")

  const { id: InstructorId } = useParams();

  const dispatch = useDispatch();
  const {
    instructorDetails,
    instructorDetailsLoading,
    instructorRatingLoading,
    instructorErr,
    instructorRatingErr,
    instructorTimeSlotsErr,
    instructorTimeSlotsLoading,
  } = useSelector((state) => state?.instructor);
  
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const { PLAYER_THUMB_DIMENSION } = appConfig;

  useIpEffect(() => {
    if(InstructorId){
      dispatch?.instructor?.getInstructorDetails(InstructorId);
    }
  }, [InstructorId]);

  const instructorInfo = instructorDetails?.[0];
  const instructorTags = instructorInfo?.tags?.split(',')
  const offerPrice = instructorInfo?.price?.[60]

  const startEndTime =
  instructorInfo?.next_available_slot?.["start_time"] &&
  instructorInfo?.next_available_slot?.["end_time"]
      ? `${UTCTimeConverter(
        instructorInfo?.next_available_slot?.start_time
        )} - ${UTCTimeConverter(instructorInfo?.next_available_slot?.end_time)}`
      : "N/A";

      //when user want to book session straight from homepage(advertise page) by clicking BookSession button
      const guestBook = query?.get("_l")
      const _guestBook = Boolean(guestBook)

  return (
    <>
    <div className="min-h-screen max-h-screen overflow-scroll">
      {(instructorDetailsLoading && instructorErr && (
        // <HomeError
        //   msg={MESSAGE_CONSTANTS['INSTRUCTOR_NOT_FOUND']}
        //   action={() => {
        //     dispatch?.instructor?.setInstructorLoading(true);
        //     setTimeout(() => {
        //       dispatch?.instructor?.getInstructorDetails(InstructorId);
        //     }, 800);
        //   }}
        // />
        <HomeError
         errType={404}
         msg={MESSAGE_CONSTANTS['INSTRUCTOR_NOT_FOUND']}
        />
      )) || (
        <div>
          <div className={`${_guestBook ? "flex justify-center my-4" : "instructor-detail-section"}  min-h-screen overflow-y-visible ${isMobile && 'mobile__padding'}`}>
            {/* instructor description */}
          
           {instructorInfo && !_guestBook ? <div className="instructor--content">
             {(instructorDetailsLoading && 
              <div className="h-[23vh] md:h-[50vh]"><VideoPlayerLoader /></div>) || (
                <>
                <div className="profile--details">
                    <div className="flex flex-col content__block">
                      <div className="profile">
                        {/* <CardImage src={instructorInfo?.profile_image_url} /> */}
                        <div className="overflow-hidden object-cover md:rounded-none md:cursor-pointer md:mx-0 w-full md:h-full">
                        {instructorInfo?.intro_video 
                           ?
                          <VideoPlayer 
                            light={false} 
                            url={instructorInfo?.intro_video}
                            thumbnail={getImgDimensionSrc(instructorInfo?.intro_thumbnail, PLAYER_THUMB_DIMENSION)}
                            isIntro={true}
                            // className="replay__button"
                            />
                          :
                          <>
                            <Image src={instructorInfo?.intro_thumbnail}
                             className ="w-full md:h-full rounded-lg"
                            />
                          </>
                        }
                        </div>
                      </div>
                     <div className="instructor__update">
                      <div className="instructor__profile">
                        <CardImage src={instructorInfo?.profile_image_url} />
                      </div>
                     <div className="designation mt-[1rem]" onClick={toggleReadMoreLess}>
                        <div className="flex justify-center md:justify-start items-center sm:justify-start">

                        <Heading>
                         <span className="inline-block"> {instructorInfo?.instructor_name}</span>
                          {/* <span  className="inline-block align-text-top		ml-[.3rem]">
                          <CardImage
                          src={imagePath("/info.svg")}
                          style={{
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            objectFit: "cover",
                          }}
                          className="cursor-pointer info-icon md:hidden inline-block"
                          onClick={toggleReadMoreLess}
                        />
                          </span> */}
                          </Heading> 
                      
                        </div>
                        <Subtitle className="greeny">
                          {instructorInfo?.designation}
                        </Subtitle>
                        {/* <div className="md:hidden">
                          <LDMarkdown
                          content={instructorInfo?.instructor_description}
                          isActiveShowMore={true}
                          lengthToShow={isMobile ? 90 : 150}
                        />
                        </div> */}
                        {instructorInfo?.next_available_slot &&
                          instructorInfo?.next_available_slot !==
                          (
                            <>
                              <h6 className="text-white next--available mt-[.5rem]">
                                Next available slots
                              </h6>
                              <Paragraph className="next--available--slots text-white mb-[0]">
                                {` ${
                                  instructorInfo?.next_available_slot?.date
                                    ? formatDate(
                                      instructorInfo?.next_available_slot?.date
                                      )
                                    : "N/A"
                                },  ${startEndTime}`}
                              </Paragraph>
                            </>
                          )}
                      </div>
                       {/* new UI for instructor details starts ratings and experience */}
                      <div className="user--updates desktop--content mt-[2rem]">
                      <div className="session-rating-experiance inline-block text-center ">
                        <Title className="items-center mb-[.5rem]">
                          <CardImage
                            className="m-auto w-full"
                            src={imagePath("/star2.svg")}
                          />
                          {NumberWithFirstDecimal(instructorInfo?.rating) || "N/A"}
                        </Title>
                        <Subtitle className="text-[#c0c2c5]">Ratings</Subtitle>
                      </div>
                      {/* <div className="session-rating-experiance inline-block text-center ">
                        <Title className="items-center mb-[.5rem]">
                          <CardImage
                            className="m-auto w-full"
                            src={imagePath("/briefcase.svg")}
                          />
                          {"500+" || "N/A"}
                        </Title>
                        <Subtitle className="text-[#c0c2c5]">Sessions</Subtitle>
                      </div> */}
                      <div className="session-rating-experiance inline-block text-center">
                        <Title className="items-center mb-[.5rem]">
                          <CardImage
                            className="m-auto w-full"
                            src={imagePath("/briefcase.svg")}
                          />
                          {instructorInfo?.experience_years || "NA"} yrs
                        </Title>
                        <Subtitle className="text-[#c0c2c5]">Experience</Subtitle>
                      </div>

                      {/* new UI for instructor details ends ratings and experience */}
                  </div>
                     </div>
                     {instructorTags?.length > 0 &&                     
                     <div className="instructor__tags">
                        {instructorTags.map((item)=>{
                         return <div className="tags_content">{item}</div>
                        })}
                     </div>
                     }
                    </div>
                    {/* <div className="user--updates user--mobile--udpate">
                      <div className="session-rating-experiance inline-block text-center ">
                        <Title className="items-center mb-[.5rem]">
                          <CardImage
                            className="m-auto w-full"
                            src={imagePath("/star2.svg")}
                          />
                          {NumberWithFirstDecimal(instructorInfo?.rating) || "N/A"}
                        </Title>
                        <Subtitle className="text-[#c0c2c5]">Ratings</Subtitle>
                      </div>
                      <div className="session-rating-experiance inline-block text-center">
                        <Title className="items-center mb-[.5rem]">
                          <CardImage
                            className="m-auto w-full"
                            src={imagePath("/briefcase.svg")}
                          />
                          {instructorInfo?.experience_years || " "} yrs
                        </Title>
                        <Subtitle className="text-[#c0c2c5]">
                          Experience
                        </Subtitle>
                      </div>
                    </div> */}
                    {/* mobile book sesssion content */}
                    {/* <div className="mobile--offer--bar"> */}
                      {/* <div className="offer--text">
                        <Description className="text-[12px] text-white font-semibold">
                          Offers end soon!
                        </Description>
                       <Description className="text-[12px] font-medium	 text-white">
                          <s className="text-[#6d7079]">
                            {instructorInfo?.currency_symbol}
                            {instructorInfo?.mrp}
                          </s>{" "}
                          Rs.
                          {instructorInfo?.offer_price}
                        </Description>
                      </div> */}
                        {/* do not remove */}
                      {/* <div className="book--session--button">
                        <FormButton
                          onClick={toggleReadMoreLess}
                          className={`session--button text-[12px]`}
                        >
                          About Instruct
                        </FormButton>
                      </div> */}
                      {isShowMore && (
                        <AboutInstructorModal
                          handleClose={() => toggleReadMoreLess()}
                          show={isShowMore}
                          footer={null}
                          className="bg-ternary about--instructor pb-[0]"
                        >
                            <div className='text-center ld-center'>
                            <LDSpan className="">
                              {instructorInfo?.profile_image_url ? 
                                    <CardImage
                                      style={{width:"150px", height:"150px",borderRadius: "50%",overflow:"hidden",objectFit: "cover"}}
                                      src={instructorInfo?.profile_image_url }
                                      avatar={true}
                                      className="mx-auto"
                                    /> :  
                                  getInitial(instructorInfo?.instructor_name)
                                }
                            </LDSpan>
                            <LDParagraph className="ld-author-style mt-2">{instructorInfo?.instructor_name} </LDParagraph>
                            <LDParagraph className="ld-author-design-style">{instructorInfo?.designation}</LDParagraph>
                          </div>
                          <LDMarkdown
                            content={instructorInfo?.session_description}
                            isActiveShowMore={true}
                            lengthToShow={500}
                            textAlign={"text-justify"}
                          />
                        </AboutInstructorModal>
                      )}
                    {/* </div> */}
                    <div className="description--content">
                      <LDMarkdown
                        content={instructorInfo?.session_description}
                        isActiveShowMore={true}
                        onClickSeeMore={()=>{
                          setIsShowMore(true)
                        }}
                        onClickSeeLess ={()=>{
                          setIsShowMore(false)
                        }}
                        lengthToShow={isMobile ? 200 :500}
                      />
                    </div>
                    {
                      offerPrice
                      && 
                      <div className="offer__ends__soon">
                      <h5>Offers ends soon  <s className="mx-[.5rem] text-[#b4b6ba] decoration-[#fff]">  {instructorInfo?.currency_symbol}{offerPrice.mrp}</s>  {instructorInfo?.currency_symbol}{offerPrice.offer_price}</h5>
                    </div>
                    }
                   
                    {/* create session for mobile view */}
             {!isMobile && <div className={`mobile---calendar`}>
                      <CreateSession
                        instructorTimeSlotsErr={instructorTimeSlotsErr}
                        instructorTimeSlotsLoading={instructorTimeSlotsLoading}
                        instructorDetails={instructorDetails}
                        value={value}
                        dataForReschedule={dataForReschedule}
                        sessionStatus={sessionStatus}
                      />
                    </div>}
                   { isCalendarOpen &&  
                      <LDSlideUp open={isCalendarOpen} closable={handleOpenModal} handleClose={handleOpenModal}>
                        <div className={`mobile---calendar ${isCalendarOpen ? "active_calendar" :" in_active_calendar"}`}>
                          <CreateSession
                            instructorTimeSlotsErr={instructorTimeSlotsErr}
                            instructorTimeSlotsLoading={instructorTimeSlotsLoading}
                            instructorDetails={instructorDetails}
                            value={value}
                            dataForReschedule={dataForReschedule}
                            sessionStatus={sessionStatus}
                          />
                    </div>
                     </LDSlideUp>}
                    {!isCalendarOpen &&
      
                    <div className="next__available__container">
                    
                    <div className="next__slots">
                    <span className="font-[BWModelica-Bold]">Next available :</span>
                    {instructorInfo?.next_available_slot ? `${formatDate(instructorInfo?.next_available_slot?.start_time, "DDMMYYYY")}, ${UTCTimeConverter(instructorInfo?.next_available_slot?.start_time)}`  : "No slot available"}
                    </div>
                      <div className="next__check__button">
                        <FormButton
                        onClick={handleOpenModal}
                          className={`text-[12px]`}
                        >
                         Check availablity
                        </FormButton>
                      </div>
                   </div>              
                  }
                  
                  </div>
                </>
              )}
              {/* rating and reviews */}
              {(instructorRatingLoading && instructorRatingErr && (
                <HomeError
                  action={() => {
                    dispatch?.instructor?.setInstructorRatingLoading(true);
                    setTimeout(() => {
                      dispatch?.instructor?.getRatings(InstructorId);
                    }, 800);
                  }}
                />
              )) || (
                <Ratings instructorRatingLoading={instructorRatingLoading}/>
              )}
            </div> : <></>}
            {/* calender part */}
            <div className={`${_guestBook ? "" : "userCalender desktop---calendar"}`}>
              {(instructorTimeSlotsLoading && !_guestBook && (
                <div className="">
                  <MultiLoadingBanner count={1} />
                </div>
              )) || (
                <>
                  {" "}
                  <CreateSession
                    instructorTimeSlotsErr={instructorTimeSlotsErr}
                    instructorTimeSlotsLoading={instructorTimeSlotsLoading}
                    instructorDetails={instructorDetails}
                    value={value}
                    dataForReschedule={dataForReschedule}
                    sessionStatus={sessionStatus}
                    _guestBook={_guestBook}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>

     {/* :
    <Heading className="flex justify-center items-center min-h-screen">No instructors available.</Heading>
    }
      */}
    </>
  
  );
};

export default InstructorInformation;
