import { useEffect, useState, useRef } from "react";
import "./style.css";
import { imagePath } from "utils/assetHelper";
import {
  Slots,
  getDayName,
  months,
  weekDays,
  monthInNumber,
} from "constants/calendar.constants";
import { Description } from "stories/Typography";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BookNow from "./BookNow";
import { useQuery } from "hooks/useQuery";
import { SESSION_STATUS, UTCTimeConverter, getUTCDate, changeDateToUTCFormat, dateEndTime, dateStartTime, formatDate, getSessionDuration, getSlotsGropued, TIME_DURATION_MAP } from "utils";
import HomeError from "pages/Home/components/Errors";
import MultiLoadingBanner from "components/MultiLoadingBanner";
import LinkTag from "stories/LinkTag";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import { pause } from "stories/VideoPlayer/actions";
import TermsAndConditionsModal from "components/Card/MysessionCard/TermsAndConditionsModal";
import { TERMS_AND_CONDITIONS_URL } from "constants/Apis";
import axios from "axios";
import { groupBy } from "ramda";
import { useMemo } from "react";
import LDImage from "stories/Image";


const CreateSession = ( props ) => {
  const {
    instructorTimeSlotsLoading,
    instructorTimeSlotsErr,
    instructorDetails,
    value,
    dataForReschedule,
    sessionStatus,
    _guestBook
  } = props

  const date = new Date();
  const myRef = useRef(null)
  var { id: InstructorId} = useParams();

  const todayDate = date.getDate();
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [currentMonth, setCurrentMonth] = useState(date.getMonth());
  const [currentYear, setCurrentYear] = useState(date.getFullYear());

  const [slot, setSlots] = useState(Slots);
  const [selectedSlot, setSelectdSlot] = useState("");
  const [selectedSlotId, setSelectedSlotId] = useState("");
  const [selectedDayName, setSelectedDayName] = useState("");
  const [errorObject, setErrorObject] = useState({});
  const [timeDifference, setTimeDifference] = useState(null)
  const [selectedDuration, setSelectedDuration] = useState('')
  const [bookedSessionDuration, setSessionDuration] = useState()
  //T & C
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [terms, setTerms] = useState('');

  useEffect(async ()=>{
    const res = await axios.get(TERMS_AND_CONDITIONS_URL);
    if(res && res.data){
      setTerms(res.data);
    }
  },[])

  const query = useQuery();

  const purchaseId = query?.get("_p");
  const countryCode = query?.get("_c");
  const paramDate = query?.get("_d");
  const paramSlot = query.get("_s");
  const _isReschedule = query?.get("_rs") || false;
  const meeting_id = query?.get("_m") || -1;

  const availableSlots = useSelector((state) => state?.instructor?.timeSlotList);
  const getTimeSlot = useMemo(()=>{
    let dateWiseSlots = [];
    if(_isReschedule){
      availableSlots?.map((_date) => {
        const validSlots = _date?.timeSlots?.filter((_slot) => { return _slot.duration === bookedSessionDuration })
        if(validSlots?.length){
          dateWiseSlots.push({ ..._date, timeSlots: validSlots })
        }
      })
    }else{
      dateWiseSlots = availableSlots;
    }
    return dateWiseSlots;
  },[availableSlots, _isReschedule, bookedSessionDuration])

  const timeSlots = getTimeSlot?.[0]?.timeSlots;

  const [timeSlotsDates, setTimeSlotsDates] = useState([]);
  const [instructorData, setInstrucotrData] = useState({
    instructorname: "",
    coursename: "",
  });
  const [privMonth, setPrivMonth] = useState("");

  const selectedDates = new Date(
    selectedYear,
    months[selectedMonth],
    selectedDate
  );
  // profile
  const profileInfo = useSelector((state) => state?.profile?.profileDetails);
  const history = useHistory();

  // get Time Slots

  // const timeSlotStates = useSelector(
  //   (state) => state?.instructor?.timeSlotList
  // );

  useEffect(()=>{
    if(_isReschedule && meeting_id){
      dispatch?.instructor?.getBookedSessionDetails({ meetingId: meeting_id },(sessionDetails)=>{
        const { slotData: { start_time, end_time } } = sessionDetails
        const _duration = getSessionDuration(start_time, end_time)
        setSessionDuration(_duration)
      })
    }
  },[_isReschedule, meeting_id])

  useEffect(() => {
    if (getTimeSlot?.length && Object.keys(getTimeSlot).length > 0) {
      const resultData = getTimeSlot?.map(({ date, ...rest }) => {
      const _utcDate = new Date(getUTCDate(date)).getDate()
        return _utcDate
      });
      setTimeSlotsDates(resultData);
    } else {
      setTimeSlotsDates([]);
      setSelectedSlotId("")
    }
  }, [getTimeSlot]);

  // handle change for input values
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInstrucotrData({ ...instructorData, [name]: value });
  };

  useEffect(() => {
    if (paramDate && paramSlot) { 
      setSelectedDate(paramDate);
      const result = timeSlots?.find((items) => {
        return items?.id == paramSlot;
      });
      setSelectdSlot(result?.start_time);
    }
    return ()=>{
      setSelectedDate("")
      setSelectedSlotId("")
    }
  }, [paramDate, timeSlots]);

  // validation for inputs
  const errorMessage = {};
  const runValidation = () => {
    if (instructorData?.instructorname?.length === 0) {
      errorMessage.instructorname = "Instructor name required";
    }
    if (instructorData?.coursename?.length === 0) {
      errorMessage.coursename = "Course name required";
    }
  };
  const monthName = months[currentMonth];

  const routerStateValue = {
    instructorData,
    monthName,
    selectedDate,
    selectedDayName,
    selectedSlot,
  };

  // handlesubmit the instructor details
  const handlesubmit = (e) => {
    e.preventDefault();
    runValidation();
    if (Object.keys(errorMessage)?.length === 0) {
      setInstrucotrData({
        instructorname: "",
        coursename: "",
      });
      setSelectedYear("");
      setSelectdSlot("");
      setSelectedMonth("");
      setSelectedDayName("");
      setSelectedDate("");
    } else {
    }
  };

  const onSlotClick = (startTime, id, endTime) => {
    setSelectdSlot(startTime);
    setSelectedSlotId(id);
    pause()
    const timeDifference = getSessionDuration(startTime, endTime)
    setTimeDifference(timeDifference)
  };

  // for getting timeslots for the next month
  const getFirstAndLastDateOfTheMonth = (currentMonth, currentYear) => {
    const setNewDay = new Date(currentYear, currentMonth);
    const selectedMonthStartDate = new Date(
      setNewDay.getFullYear(),
      setNewDay.getMonth(),
      1
    );
    const selectedEndDate = new Date(
      selectedMonthStartDate.getFullYear(),
      selectedMonthStartDate.getMonth() + 1,
      0
    );
    const isCurrentMonthYear =  new Date().getMonth() === currentMonth && new Date()?.getFullYear() === currentYear
    const dateWithTimeStart = isCurrentMonthYear ? new Date() :  new Date(dateStartTime(selectedMonthStartDate))
    const dateWithTimeEnd = dateEndTime(selectedEndDate)

    const utcStartDate = changeDateToUTCFormat(dateWithTimeStart)
    const utcEndDate  = changeDateToUTCFormat(dateWithTimeEnd)

    return {
      startDate: utcStartDate,
      endDate: utcEndDate,
    };
  };

  const getTimeSlotDependsOnStartEndDate = (payloadForTimeSlots) => {

    // const startDate =  dateStartTime(payloadForTimeSlots?.startDate)
    // const endDate= dateEndTime(payloadForTimeSlots?.endDate)
    const payload = {
      InstructorId,
      ...payloadForTimeSlots
    };
    dispatch?.instructor?.getTimeSlotList(payload);
  };

  // enable scroll to slots only on small screen 
  const isSmallScreen = useMedia("(max-width: 874px)")
  const onDayPress = (day) => {
    setSelectedDuration("")
    setSelectdSlot("")
    const dayByname = getDayName(new Date(currentYear, currentMonth, day));
    setSelectedDate(day);
    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
    setSlots(slot);
    setSelectedDayName(dayByname);
    // setSelectedDuration(durationWiseSlotsObj)
    myRef?.current?.scrollIntoView({behavior: 'smooth'}) 
  };

  const onPressPreviousMonth = () => {
    setSelectdSlot("")
    setSelectedDayName("")
    setSelectedMonth("")
    setSelectedDate("")
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);

      const _currentMonth = privMonth === 1 ? 12 : currentMonth;
      const result = getFirstAndLastDateOfTheMonth(
        _currentMonth - 1,
        currentYear
      );
      result && getTimeSlotDependsOnStartEndDate(result);
    } else {
      const result = getFirstAndLastDateOfTheMonth(
        currentMonth - 1,
        currentYear
      );
      result && getTimeSlotDependsOnStartEndDate(result);
      setCurrentMonth(currentMonth - 1);
    }
  };

  const onPressNextMonth = () => {
    setSelectdSlot("")
    setSelectedDayName("")
    setSelectedMonth("")
    setSelectedDate("")
    // setPrivMonth(currentMonth);
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
      const result = getFirstAndLastDateOfTheMonth(0, currentYear + 1);
      result && getTimeSlotDependsOnStartEndDate(result);
    } else {
      setCurrentMonth(currentMonth + 1);
      const result = getFirstAndLastDateOfTheMonth(
        currentMonth + 1,
        currentYear && currentYear,
        1
      );
      result && getTimeSlotDependsOnStartEndDate(result);
    }
  };

  // get timeslots according selected date
  // const selected_date = `${selectedYear}-${monthInNumber[selectedMonth]}-${selectedDate}`;  //formatting selected date
  
  const selectedDateNew = new Date(selectedYear,selectedMonth, selectedDate)

  const formatedSelectedDate = formatDate(selectedDateNew);
  const timeSlotsFilter = getTimeSlot?.length>0 && getTimeSlot?.filter((data, idx) => data?.date === formatedSelectedDate);
  const timeSlotsAccoringToSelectedDate = timeSlotsFilter?.[0]?.timeSlots

  let durationWiseSlotsObj = {}
  if(timeSlotsAccoringToSelectedDate?.length){
    const getSlotsObjByDuration = groupBy((slots) => {
     return slots.duration
   })
   durationWiseSlotsObj = getSlotsObjByDuration(timeSlotsAccoringToSelectedDate);
  }

const availableDurations = Object?.keys(durationWiseSlotsObj)?.sort();

  //reschedule related function
 const rescheduleArr = []
 const isInstructorCancel = dataForReschedule?.data?.status === SESSION_STATUS?.SESSION_UPCOMING || dataForReschedule?.data?.status === SESSION_STATUS?.SESSION_RESCHEDULED

  const renderCalendar = () => {
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDay = new Date(currentYear, currentMonth, 1).getDay();
    let firstDayIndex;
    if (firstDay === 0) {
      firstDayIndex = 6;
    } else firstDayIndex = firstDay - 1;
    const days = [];
    for (let i = 0; i < firstDayIndex; i++) {
      days.push(<div key={`empty-${i}`} className={_guestBook ? "day2" : "day"} />);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      // setSelectdSlot
      const isToday =
        i === todayDate &&
        currentMonth === date.getMonth() &&
        currentYear === date.getFullYear();
      const isSelected =
        i === selectedDate &&
        selectedMonth === currentMonth &&
        selectedYear === currentYear;

      const availableDay = timeSlotsDates && timeSlotsDates?.includes(i)
        // (i >= todayDate &&
        //   currentMonth === date.getMonth() &&
        //   currentYear === date.getFullYear() &&
        //   timeSlotsDates.includes(i)) ||
        // (currentMonth > date.getMonth() 
        // && timeSlotsDates.includes(i) && 
        //   currentYear === date.getFullYear()) ||
        // (currentYear < date.getFullYear()); // TODO : Fix is required Date must be selected from current date
      days.push(
        <div
          className={`${_guestBook && "day2"} day ${isToday && "todayTextBg"} ${availableDay && "availableDay"} ${isSelected && availableDay && "selectedDay"}`}
          key={i}
          onClick={() => {onDayPress(i); pause(); setTimeDifference(null)}}
        >
          <div
            className={`${isSelected && availableDay && "selectedDayText"} ${
              isToday && "todayText"
            } ${availableDay ? "availableDayText" : "unvailableDay"}`}
          >
            {i}
          </div>
          <div className={`${_guestBook ? "todaydot2" : ""} ${isToday && "todaydot"}`}></div>
        </div>
      );
    }
    return days;
  };

  const dispatch = useDispatch();



  // NOTE : ************* Price is getting updated defending on time difference  only two time difference available 30 and 60 in minutes  *********************

  // get the instructorDetails
  let result = instructorDetails?.[0];

  const priceAccordingToSlot = result?.price[timeDifference]

  const updatedPriceDetails = {
        offer_price: priceAccordingToSlot?.offer_price,
        mrp: priceAccordingToSlot?.mrp 
  }

  result = {
    ...result,
    ...updatedPriceDetails
  }

  // NOTE : ************* Price is getting updated defending on time difference  only two time difference available 30 and 60 in minutes  *********************


  // createMeeting
  const dataForMeeting = {
    user_id: profileInfo?.id,
    instructor_id: InstructorId,
    slot_id: selectedSlotId,
  };

  useEffect(() => {
    let slotsFetchAllowed = false
    if(_isReschedule){
      if(InstructorId && bookedSessionDuration){
        slotsFetchAllowed = true;
      }
    }else if(!_isReschedule && InstructorId){
      slotsFetchAllowed = true
    }
    if (slotsFetchAllowed) {
      const result = getFirstAndLastDateOfTheMonth(currentMonth, currentYear);
      const isTodays = true 
      const startDate = dateStartTime(result.startDate, isTodays)
      const endDate =  dateEndTime(result?.endDate)
      const _result = {
        startDate: changeDateToUTCFormat(startDate),
        endDate : changeDateToUTCFormat(endDate)
      }
      getTimeSlotDependsOnStartEndDate(_result);
    }
  }, [InstructorId, _isReschedule, bookedSessionDuration]);
  const handleError = (Error) => {
    setErrorObject({ slotError: Error });
    setTimeout(() => {
      setErrorObject({});
    }, 2000);
  };

  const handleDurationSelect = (duration) => {
    setSelectedDuration(duration);
    setSelectdSlot("")
    setSelectedSlotId("")
  }

  const activeDuration = useMemo(()=>{
    return selectedDuration?.length ? selectedDuration : availableDurations[0]
  },[selectedDuration, availableDurations])

  return (
    <div>
      {(instructorTimeSlotsLoading && instructorTimeSlotsErr && (
        <>
          <HomeError
            action={() => {
              dispatch?.instructor?.setInstructorTimeSlotsLoading(true);
              setTimeout(() => {
                const _startDateAndEndDate = getFirstAndLastDateOfTheMonth(
                  currentMonth,
                  currentYear
                );
                const instructorTimeSlotsPayload = {
                  InstructorId,
                  ..._startDateAndEndDate,
                };
                dispatch?.instructor?.getTimeSlotList(
                  instructorTimeSlotsPayload
                );
              }, 800);
            }}
          />
        </>
      )) || (
        <div className="calender--flow">
      {_guestBook ? <LDImage src={imagePath("/Logo.svg")} alt="logo" className="h-10" /> : <></>}
          <div className={_guestBook ? `mt-8 lg:mt-[5rem]` : `main--section h-[100vh] md:h-full overflow-y-scroll overflow-x-hidden md:overflow-y-hidden mb-10 md:mb-0`}>
            <div className="contentwrapper">
              {(instructorTimeSlotsLoading && (
                <MultiLoadingBanner count={1} />
              )) || (
                <div className="session-container">
                  <div className={`${_guestBook ? "wrapper2" : "wrapper"}`}>
                   {((dataForReschedule?.data?.is_reschedulled === null && isInstructorCancel ) || Boolean(_isReschedule) && dataForReschedule?.data?.status !== SESSION_STATUS?.SESSION_ONHOLD)  && 
                   <>
                    <div className="bg-red-500 text-white font-bold p-[.5rem] text-[.9rem]">
                      {/* You are allowed to reschedule the session only once. */}
                      You are allowed to reschedule or cancel the session only once.
                    </div>
                   </>
                   }
                    <h2 className={"font-bold"}>{ _guestBook ? <h2>Pick a slot with <span className=" text-white text-[21px]">{result?.instructor_name}</span></h2>: "Pick a slot"}</h2>
                    <div className="cal-container">
                      <div className={`${_guestBook ? "hstack2" : "hstack"}`}>
                        <div
                          className="arrows back"
                          disabled={
                            currentMonth === date.getMonth() &&
                            currentYear === date.getFullYear()
                          }
                          onClick={onPressPreviousMonth}
                        >
                          <img
                            src={imagePath("/left-arrow.svg")}
                            alt="left-arrow"
                            style={{ width: "21px", height: "18px" }}
                          />
                        </div>
                        <div className="currnet-month-year">
                          {months[currentMonth]} {currentYear}
                        </div>
                        <div
                          className="arrows forward"
                          onClick={onPressNextMonth}
                        >
                          <img
                            src={imagePath("/right-arrow.svg")}
                            alt="left-arrow"
                            style={{ width: "21px", height: "18px" }}
                          />
                        </div>
                      </div>
                      <div className="weekdays">
                        {weekDays?.map((items, idx) => {
                          return <div className="weekday" key={idx}>{items}</div>;
                        })}
                      </div>
                     {isSmallScreen && <div ref={myRef}></div>}
                      <div className="days">{renderCalendar()}</div>
                    </div>
                  </div>
                  {selectedDate && (
                    <div className="calendar--mobile flex flex-col h-[85vh] md:h-full mb-10 md:mb-0 max-w-[25rem]">
                      <div className="container-slots h-full">
                        <div className="text-lg text-remove">
                          {errorObject?.slotError}{" "}
                        </div>

                        {selectedDayName && 
                        <h5>
                          {`${selectedDayName} ${months[selectedMonth]} ${selectedDate}, ${selectedYear}`} {selectedSlot && ` - ${UTCTimeConverter(selectedSlot)} (${TIME_DURATION_MAP?.[timeDifference]})`}
                          </h5>}
                        <h5 style={{ fontWeight: 600 }}>Duration</h5>
                        <h5 style={{ fontWeight: 600 }}>
                          {<>
                          {availableDurations?.length>0 && availableDurations?.map((data)=>{
                            return(
                             
                             <>
                            {TIME_DURATION_MAP[data] &&         
                              <div
                              onClick={()=>handleDurationSelect(data)}
                              className={`select-slots ${ activeDuration === data ? "selectedslot": "" }`}
                            >
                              {TIME_DURATION_MAP[data]}
                            </div>}
                            </>
                            )
                          })}
                              
                          </>
                          }
                        </h5>
                        <h5 style={{ fontWeight: 600 }}>Available slots</h5>
                        {/* <div className="max-w-[20rem]">
                          <h6>
                            {selectedSlot && `${UTCTimeConverter(selectedSlot)} (${TIME_DURATION_MAP?.[timeDifference]})`}
                          </h6>
                        </div> */}
                        {/* time slots */}
                        <div className="slots-role">
                          {/* for session booking slots */}
                          {durationWiseSlotsObj[activeDuration]?.map ((data, idx) => {
                              return (
                                <>
                                <div
                                  key={idx}
                                  onClick={() =>
                                    onSlotClick(data?.start_time, data?.id, data?.end_time)
                                  }
                                  className={`select-slots ${
                                    selectedSlot?.includes(data?.start_time)
                                      ? "selectedslot"
                                      : ""
                                  } `}
                                >
                                  {UTCTimeConverter(data?.start_time)}
                                </div>
                                </>
                              );
                            })}

                        </div>
                            <div className="text-green-700 cursor-pointer inline-block" onClick={()=>setTermsAndConditions(true)}>Terms & Conditions</div>
                            {termsAndConditions &&  <TermsAndConditionsModal show={termsAndConditions} handleClose={()=>setTermsAndConditions(false)} data={terms}/>}
                        {/* timeslots */}
                      </div>
                      <div className="flex flex-col justify-center gap-[4px] mt-[1rem]">
                     {value !=="true" &&<>
                     <Description className="flex justify-center leading-none mb-[9px] mt-3 text-[10px] md:text-[20px] text-white text-center md:text-left">
                          Offer ends soon!
                        </Description>
                    { result?.mrp && selectedSlot && <Description className="flex justify-center gap-[9px] text-[24px] text-white md:text-[25px] font-bold">
                          <s className="text-[#6d7079]">
                            {result?.currency_symbol}
                            {"  "}
                            {result?.mrp} {"  "}
                          </s>{" "}
                          {result?.currency_symbol}
                          {"  "}
                          {result?.offer_price}
                        </Description>}
                     </>  }
                        <BookNow
                          dataForMeeting={dataForMeeting}
                          selectedDayName={selectedDayName}
                          selectedMonth={selectedMonth}
                          selectedDate={selectedDate}
                          result={result}
                          profileInfo={profileInfo}
                          purchaseId={purchaseId}
                          countryCode={countryCode}
                          selectedSlot={selectedSlot}
                          handleError={handleError}
                          formatedSelectedDate={routerStateValue}
                          isReschedukedQueryParam={value}
                          dataForReschedule={dataForReschedule}
                          selectedSlotId={selectedSlotId}
                          _guestBook={_guestBook}
                          handleUpdate={()=>{
                            const result = getFirstAndLastDateOfTheMonth(currentMonth, currentYear);
                            if(result){
                              const payload = {
                                InstructorId: InstructorId,
                                ...result
                              }
                             dispatch?.instructor?.getTimeSlotList(payload);
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateSession;
