import { Title } from "stories/Typography";
import { LDParagraph } from "stories/Typography";
import FormButton from "stories/FormButton";
import { COLOR_CONSTANTS } from "constants/color.constants";
import Image from "components/Image";
import { imagePath } from "utils/assetHelper";

const MessagePopUp = ({title, desc, confirmHandler, cancelHandler, contentStyle, position, cancelLabel, approveLabel, bgBlur, titleStyle, cancel }) => {
  const positionObj = {
    "bottom-right": "md:right-[2rem] bottom-[2rem]",
    center: "translate-x-[-50%] and translate-y-[0] left-[50%] top-[35%] "
  }
  return (
    <div className={bgBlur ? "fixed z-[1040] backdrop-blur-[3px] bg-transparent w-full h-full" : ""}>
      <div className={`bg-sidebarSelected fixed z-[200] w-[22rem] md:w-96 px-[1rem] py-[0.85rem] mx-3 md:p-4 ${positionObj[position]} rounded-[8px]`} style={contentStyle}>
        {cancel && (<div onClick={cancel} className="absolute right-[15px] top-[1rem] cursor-pointer"><Image icon={true} src={imagePath("/cross.svg")} alt="crossImg" /></div>)}
        <Title className="text-white mb-2" style={titleStyle}>{title}</Title>
        <LDParagraph className="text-white mb-4">{desc}</LDParagraph>
        <div className="flex items-center">
            <FormButton
              className={`w-[50%] mr-8 rounded-lg`}
              // style={{ backgroundColor: COLOR_CONSTANTS.REMOVE, color: "white" }}
              backgroundColor={COLOR_CONSTANTS.REMOVE}
              color={COLOR_CONSTANTS.WHITE} 
              onClick={cancelHandler}
            >
              {cancelLabel}
            </FormButton>
            <FormButton
              className="w-[50%] rounded-lg"
              style={{background:COLOR_CONSTANTS.GREEN , color: "white"}}
              onClick={confirmHandler}
            >
              {approveLabel}
            </FormButton>
          </div>
      </div>
    </div>
  );
};

MessagePopUp.defaultProps = {
  title: "",
  desc: "",
  confirmHandler: () => {},
  cancelHandler: () => {},
  contentStyle: {},
  position: "bottom-right",
  cancelLabel: "Deny",
  approveLabel: "Allow",
  bgBlur: false,
  titleStyle: {},
  cancel: undefined
};

export default MessagePopUp;