import LDImage from "stories/Image";
import { Heading, LDParagraph, LDSpan, Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { getReadableDate } from 'utils';
import "globalStyle/globalStyle.css";
import useMedia from "hooks/deviceHelper/useMedia";
import { useHistory } from 'react-router-dom';
import { getAleartConfigRoute } from "utils/navigationHelper";
import { useRef } from 'react';
import { useDispatch ,useSelector} from "react-redux";
import LoadingSpinner from "stories/Loader/LoadingSpinner";
import Image from "components/Image";
import FormButton from "stories/FormButton";
import "./index.css"

const List = ({ notifiList ,handleClick}) => {
  return (
    <>
      {notifiList?.map((_notifiList,index) => {
        const { message, createdAt, type ,read,id,user_id,body} = _notifiList;
          const setTextColor =  "text-white indent line-clamp" //selected?.includes(id) ? "text-greeny" :
          const setImage = imagePath("/bullet.svg") //selected?.includes(id) ? imagePath("/bulletred.svg") :
          const date = getReadableDate(createdAt)
        return (
          <div className="flex bg-ternary py-5 px-1 rounded m-2"  onClick={()=>handleClick(id,body,type)}>
              <LDImage src={setImage} className="h-2 mr-3 mt-1"/>
            <div className=" pl-2 lg:w-[80%] cursor-pointer">
              <LDParagraph className={`${setTextColor} ld-notification`}>{message}</LDParagraph>
              <LDParagraph className="text-comment opacity-60 mt-1 ld-notification-duration">{date}</LDParagraph>
            </div>
          </div>
        );
      })}
    </>
  );
};

const Notifications = (props) => {
  const { notificationList,notificationVisible,setNotificationVisible,mobileNotification} = props;
  const loading = useSelector((state)=> state?.notification?.loading)
  const notifcationLegnth = useSelector((state)=> state?.notification?.notificationLength)
  const paginationMeta = useSelector((state)=> state?.notification?.paginationMeta?.pagination) || {}
  
  const  history = useHistory()
  const dispatch = useDispatch();
  const lastElementRef = useRef()
  const { page, pageCount } = paginationMeta;

  // const callbackFn=()=>{
  //   dispatch?.notification?.requestGetNotification() 
  // }

  // useInfiniteScroll(callbackFn,lastElementRef)  //TODO Later

  const handleClick=(id,body,type)=>{
    // const arr= [...selected,id]
    // setSelected(arr)
    if(body){
      const route =  getAleartConfigRoute(body, type)
      if(!route?.includes("undefined") && route?.length > 0){
          setNotificationVisible(false)
          history.push(route)
       }else{
        // setNotificationVisible(false)
       }
    }
  }
  const isMobile = useMedia()

   const handleShowMore=()=>{
     dispatch?.notification?.requestGetNotification() 
   }

  const scrollerHide = !mobileNotification && "overflow-y-auto overflow-scroll hide-scrollbar"
  const setStyle = notificationList?.[0]?.notifiList?.length === 0 && 
                   notificationList?.[1]?.notifiList?.length === 0 && 
                   notificationList?.[2]?.notifiList?.length === 0  ? " " : scrollerHide

  const message = notificationList?.[0]?.notifiList?.length === 0 && 
  notificationList?.[1]?.notifiList?.length === 0 && 
  notificationList?.[2]?.notifiList?.length === 0   && <Title className="text-center mt-10"> No New Notification</Title>
  return (
    <div className={setStyle}>
      {message }
      {notificationVisible &&  notificationList?.map((_list,index) => {
        const { day, notifiList } = _list;
        const renderTodayNotification = day.toLowerCase() === "today" && notifiList?.length !==0 && (
           <>
             <Heading className="text-white my-4 ml-2 text-lg">{day}</Heading>         
             <List notifiList={notifiList} handleClick={handleClick}  /> {/*selected={selected}*/}
           </>
        )
        const renderYesterday = day.toLowerCase() === "yesterday" && notifiList?.length  !==0 && (
          <>
             <Heading className="text-white my-4 ml-2 text-lg">{day}</Heading>         
             <List notifiList={notifiList} handleClick={handleClick} /> {/*selected={selected}*/} 
           </>
        )
        const renderOlder = day.toLowerCase() === "older" && notifiList?.length !==0 &&  (
          <>
            <Heading className="text-white my-4 ml-2 text-lg">{day}</Heading>         
            <List notifiList={notifiList} handleClick={handleClick}/> {/*selected={selected}*/} 
          </>
        )
        return (
          <div className="" key={index}> 
          {renderTodayNotification && renderTodayNotification }
          {renderYesterday && renderYesterday }
          {renderOlder && renderOlder }      
          </div>
        );
      })}
      {notificationVisible && (

      <div className={`text-center ${loading ? "mb-10": "my-5"}`}>
          {loading ?
            <div className="absolute lg:bottom-[2%] lg:right-[10%] right-0 left-0">
              <LoadingSpinner fontSize="2rem" />
            </div>
            :
            <>
              {pageCount > page &&
                  <FormButton
                    onClick={handleShowMore}
                    className=" bg-greeny  text-white  hover:bg-greeny hover:text-white"
                  >
                  Show More
                  <LDImage
                    src={imagePath("/Course/downArrow.svg")}
                    alt="downarrow"
                    className="inline ml-2 h-2"
                  />
                </FormButton> 
            }
            </>
            } 

      </div> 
      )}
      
    </div>
  );
};

export default Notifications;
