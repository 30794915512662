import { Notifier } from "components/Notifier";
import React, { memo, useCallback, useEffect, useState } from "react";
import SingleOTPInput from "./SingleOTPInput";

const OTPInputComponent = (props) => {
  const {
    onChangeOTP,
    length,
    className,
    resendOtp,
    type,
    inputClassName,
    passwordOtpClasaName,
    handleSubmitEnter,
    autoFocus,
    clearOtp,
    otpError,
    setOtpError,
    defaultVal = [],
    handleClick
  } = props;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array(length)?.fill(""));

  useEffect(() => {
    if (defaultVal?.length) {
      setOTPValues(defaultVal);
    }
  }, []);

  
  useEffect(()=>{
    if(clearOtp){
       setOTPValues(Array(length)?.fill(""))
    }
  },[clearOtp])
  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
      setOtpError()
    },
    [onChangeOTP]
  );
  const handleEnterChange=()=>{
    const otpsInput = otpValues?.join("")
    if(otpsInput?.length === 6 ){
      handleSubmitEnter(otpsInput)
    }else{
      Notifier("DELETE","Please enter OTP to continue")
    }
  }
  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e) => {
      const val = e.currentTarget.value;
      if (!val) {
        e.preventDefault();
        return;
      }
       if(val.length === 6){
          handleOnPaste(null,val)
          return;
       }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput]
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case "Enter":{
          e.preventDefault();
          handleEnterChange()
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e,otpVal = null ) => {
      e && e.preventDefault();
      const _otp = otpVal && otpVal.length ? otpVal : e.clipboardData.getData("text/plain")
      const pastedData = _otp
        .trim()
        .slice(0, length - activeInput)
        .split("");
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...pastedData];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = pastedData.shift() || val;
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        handleOtpChange(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, length, otpValues]
  );

  return (
    <div className="lds-otp--wrapper">
      <div className={className}>
        {Array(length) //Change to length
          ?.fill("")
          .map((_, index) => {
            return (
              <SingleOTPInput
                key={`SingleInput-${index}`}
                type={type}
                focus={activeInput === index}
                value={otpValues && otpValues[index]}
                autoFocus={autoFocus}
                className={resendOtp ? `${passwordOtpClasaName} ${otpError ? "border-2 border-remove " : ""} ` : inputClassName}
                onFocus={handleOnFocus(index)}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onBlur={onBlur}
                onClick={handleClick}
                onPaste={handleOnPaste}
              />
            );
          })}
      </div>
    </div>
  );
};
OTPInputComponent.defaultProps = {
  inputClassName:
    "outline-none w-3 h-3 generate-input-style bg-secondary text-center grow rounded-lg m-4 p-2 text-white font-400",
  className: "flex items-center w-full justify-center mt-[10px]",
  passwordOtpClasaName:
    "outline-none w-[2rem] sm:w-3  bg-secondary rounded-lg text-center m-2 text-white font-400 p-2 grow  border-2 border-formBorder border-opacity-50",
  onChangeOTP: () => {},
  autoFocus: false,
  length: 4,
  type: "text",
  otpError:false,
  resendOtp: false,
  handleClick:()=>{}
};

const OTPInput = memo(OTPInputComponent);
export default OTPInput;
