import * as eventConstants from "./events.constants";
import sessionEvents from "./SessionEvents";

const handleEvent  = (eventCode, extraParams={}) => {
    sessionEvents.handle_analytics_event(eventCode, extraParams);
  }

  export const rescheduleStartClickEvent = (extraParams={}) => {
    const eventCode =  eventConstants?.SESSION_RESCHEDULE_START
    handleEvent(eventCode,extraParams)
  }

  export const rescheduleEndClickEvent = (extraParams ={}) => {
    const eventCode = eventConstants?.SESSION_RESCHEDULE_END
    handleEvent(eventCode,extraParams)
  }

  export const sessionCanceStartClickEvent = (extraParams ={}) => {
    const eventCode = eventConstants?.SESSION_CANCEL_START
    handleEvent(eventCode,extraParams)
  }

  export const sessionCancelEndClickEvent = (extraParams ={}) => {
    const eventCode = eventConstants?.SESSION_CANCEL_END
    handleEvent(eventCode,extraParams)
  }