import * as eventConstants from "./events.constants";
import mycoursesEvents from "./MycoursesEvents";

const handleEvent = (eventCode, extraParams = {}) => {
  mycoursesEvents.handle_analytics_event(eventCode, extraParams);
};

export const mycoursesLoadEvent = (loadEnd = false) => {
  const eventCode = loadEnd
    ? eventConstants?.MYCOURSES_LOAD_END
    : eventConstants?.MYCOURSES_LOAD_START;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
};
export const mycoursesPlayClick = (extraParams={}) => {
  const eventCode = eventConstants?.MYCOURSES_COURSE_PLAY_CLICK;
  handleEvent(eventCode, extraParams);
};

export const mycoursesViewInvoiceClick = (extraParams={}) => {
  const eventCode = eventConstants?.MYCOURSES_COURSE_VIEW_INVOICE_CLICK;
  handleEvent(eventCode, extraParams);
};

export const mycoursesPurchaseClick = (extraParams={}) => {
  const eventCode = eventConstants?.MYCOURSES_COURSE_PURCHASE_CLICK;
  handleEvent(eventCode, extraParams);
};

export const mycoursesWishlistClick = (extraParams={}) => {
  const eventCode = eventConstants?.MYCOURSES_COURSE_WISHLIST_CLICK;
  handleEvent(eventCode, extraParams);
};
