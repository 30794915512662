import { ROUTE_CONSTANTS } from 'constants/routes.constants';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function useBrowserBack() {
    const history = useHistory();

    useEffect(() => {
        history.block((location, action) => {
            if(action === "POP" && location?.pathname?.indexOf('/payment/success') !== -1){
                setTimeout(()=>{
                    history.push(ROUTE_CONSTANTS?.HOME)
                },0);
            }
        });
        return () => {
            history.block(() => true);
        };
    }, [history]);
}
