import CardImage from "components/Card/CardImage";
import { LDParagraph, LDSpan } from "stories/Typography";
import LinkTag from "stories/LinkTag";
import { imagePath } from "utils/assetHelper";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import LDTooltip from "stories/LDTooltip";
import useMedia from "hooks/deviceHelper/useMedia";
import { useEffect, useState } from "react";
import LDImage from "stories/Image";
import "globalStyle/globalStyle.css";
import { useParams, useHistory } from "react-router-dom";
import { courseEpisodeListCardClickEvent } from "analytics/course";
import DownloadApp from "components/DownloadApp";
import ProgressBar from "stories/ProgressBar";
import "../../index.css";
import { play } from "stories/VideoPlayer/actions";
import { Col, Row } from "antd";
import Image from "components/Image";

const EpisodesCard = (props) => {
  const params = useParams();
  const [show, setShow] = useState(false);
  const { episodeId: routeEpisode } = params;
  const {
    src,
    title,
    minutes,
    episodeUrl,
    courseId,
    episodeId,
    index,
    lastIndex,
    handleShow,
    isFree,
    isPurchased,
    handleFireEvent,
    episodeSlug,
    courseSlug,
    episodeLength,
    watched = 0,
    thumbDimension = undefined,
  } = props;

  const [playingColor, setplayingColor] = useState("");
  const isMobile = useMedia();

  useEffect(() => {
    if (params?.episodeId) {
      const bgColor = "bg-greeny";
      setplayingColor(bgColor);
    }
  }, [params?.episodeId]);

  const handleOpenEpisode = () => {
    if (
      !isMobile &&
      (isFree || isPurchased) &&
      (!routeEpisode || parseInt(routeEpisode) !== episodeId)
    ) {
      play();
    }
    const obj ={
      isFree :isFree,
      isPurchased:isPurchased
    }
    handleFireEvent(title, index, obj);
  };

  const changeBgColor =
    Math.floor(index) % 2 === 0
      ? "bg-ternary   shadow-2xl"
      : "bg-secondary shadow-2lg";
  const PlayIcon = () => (
    <LDImage src={imagePath("/Episodes/play.svg")} alt="play" className="" />
  );

  const LockIcon = () => (
    <LDImage
      src={imagePath("/Course/lock.svg")}
      alt="lock"
      className="lg:block"
    />
  );
  const borderRadiusTop =  index === 1 && "rounded-t-lg"
  const borderRadiusBottom  = episodeLength == index  && "rounded-b-lg"
  const currentPlayingColor =  episodeSlug == params?.episodeId || episodeId == params?.episodeId  ? playingColor : changeBgColor
  const route = isFree || isPurchased ? `/${ROUTE_CONSTANTS.EPISODE}/${courseSlug}/${episodeSlug}` : "#"
  const durationColor =  episodeSlug == params?.episodeId || episodeId == params?.episodeId ? "text-white ld-text-size-mobile" : "simple-duration-style"
  const renderIcon = isFree || isPurchased ?  <PlayIcon style={{ verticalAlign: "middle"}} /> :  <LockIcon style={{ verticalAlign: "middle"}}/>
  const applyWidth = isMobile ? "w-[125px]" : window.innerWidth >= 1190 && window?.innerWidth <= 1360 ? "w-[100px]" : "w-[120px]"
  const style = window.innerWidth >= 993 && window.innerWidth <= 1159 && "flex items-center"
  const lgSpanCol1 = window.innerWidth >= 993 && window.innerWidth <= 1159 ? 4 : 10
  const lgSpanCol2  = window.innerWidth >= 993 & window.innerWidth <= 1159 ? 16 : 10
  const renderCardDetails = (route,handleShow) =>{
    return (
      <LinkTag to={route} onClick={()=>handleShow(title,isFree)}>
      <DownloadApp show={show} handleClose={() => setShow(false)} />
      <div className={`py-4 px-4 ${borderRadiusTop} ${borderRadiusBottom} ${currentPlayingColor} ${style}`}>
       <Row align="middle">
         <Col span={10} sm={{span:6}} md={{span:4}} lg={{span:lgSpanCol1}}>
          {/*Dimention are not added here */}
           <div >
            <Image src={src} dimension={thumbDimension}  className={`${applyWidth} relative rounded-sm`} />
               {episodeSlug == params?.episodeId ? (
                <></>
                ) : (
                 <div className={`absolute bottom-[-12px] left-0 ${applyWidth}`}>
                   <ProgressBar completed={watched} />
                 </div>
                )}
           </div>  
         </Col>
         <Col span={10} sm={{span:14}}  md={{span:16}} lg={{span:lgSpanCol2}}>
           <div
             className="flex"
             style={{
               verticalAlign: "middle",
             }}
           >
             <div className="text-white simple-text-size-mobile ">{index}.</div>
             <div className="pl-2">
               <LDTooltip title={title}>
               <span className=" text-white  line-clamp simple-text-size-mobile ">
                 {title}
               </span>
               </LDTooltip>  
               <div className={`${durationColor}`}>
                 {minutes}
               </div>
             </div>
           </div>
         </Col>
         <Col span={4} className="flex justify-end align-middle">
           {renderIcon}
         </Col>
       </Row>
     </div>
   </LinkTag>
    )
  }

  return (
    <>
      <div>
        {isFree || isPurchased 
          ?
           renderCardDetails(route,handleOpenEpisode)
          :
           renderCardDetails(route,handleShow)
         }
      </div>
    </>
  );
};
EpisodesCard.defaultProps = {
  src: null,
  title: "",
  minutes: "",
};

export default EpisodesCard;
