
import { axiosGet } from '../../axiosRequests/index';
import { NOTIFICATION_URL } from '../../constants/Apis/index';
import { STATUS } from '../../constants/status.constant';

const model = {
  state: {
    notiticationList: [],
    notificationLength: 0,
    currentPage :0,
    paginationMeta:{},
    loading : false
  },
  reducers: {
    setNotification: (state, payload) => {
      return {
        ...state,
        notiticationList: payload
      }
    },
    setNotificationLength:(state,payload)=>{
      return {
        ...state,
        notificationLength:payload
      }
    },
    setNexPage:(state,payload) =>{
      return {
        ...state,
        currentPage:payload
      }
    } ,
    setPaginationMeta:(state,payload)=>{
      return {
        ...state,
        paginationMeta:payload
      }
    },
    setLoading: (state,payload)=>{
      return {
        ...state,
        loading:payload
      }
    }
  },
  effects: (dispatch) => ({
    async requestAddNotification(payload,state) {
      try{  
        const prevData = state?.notification?.notiticationList
        const pagination_meta = state?.notification?.paginationMeta;
        const newArray = [payload,...prevData]
        dispatch?.notification?.setNotification(newArray)
        dispatch?.notification?.setNotificationLength(newArray?.length)
        dispatch?.notification?.setPaginationMeta({pagination: { ...pagination_meta?.pagination, total: parseInt(pagination_meta?.pagination?.total) + 1 } })
      }
      catch(err){
        console.log(err)
      }
    },
    async requestGetNotification(undefined,state){
      try{
        dispatch?.notification?.setLoading(true)
        const nextPage = state?.notification?.currentPage + 1
        const pagination = `pagination[pageSize]=${10}&pagination[page]=${nextPage}` //  if we want to show button reduce the pageSize
        const result = await axiosGet(`${NOTIFICATION_URL}?${pagination}`)
        const  { status ,data} = result
        if(STATUS.includes(status)){
          dispatch?.notification?.setNexPage(nextPage)
          dispatch?.notification?.setPaginationMeta(data?.meta)
          if(nextPage != 1){
            setTimeout(()=>{
              dispatch?.notification?.setLoading(false)
              dispatch?.notification?.setNotification(data?.result)
            },1500)
          }else{
            dispatch?.notification?.setLoading(false)
            dispatch?.notification?.setNotification(data?.result)
          }
        }
      }
      catch(err){

      }
    }
  }),
};

export default model;
