import { default as MainApp } from "app/index";
import usePosition from "hooks/usePosition";
import usePushNotification from "hooks/usePushNotification";
import { useDispatch, useSelector } from "react-redux";
import { getBrowserType, getDeviceType, getOsType } from "utils/deviceHelper";
import useSocket from './hooks/useSocket';
import useAuthenticatedEffect from './hooks/useAuthenticatedEffect';
import PushNotification from "components/Notifications/PushNotification";
import { useEffect } from "react";

 function App() {
  const notificationReqActive = useSelector((state)=> state?.pushNotification.requestActive)
  const ipFetched = useSelector((state)=> state?.commonParams?.ipInfo?.fetched)
  const dispatch = useDispatch()

  const location = "" //usePosition() location request removed due to multiple request calls
  const browser = getBrowserType()
  const os = getOsType()
  const device_type = getDeviceType()



  useAuthenticatedEffect(()=>{
    dispatch?.profile?.requestProfileDetails();
  },[])

  const userData = {
    os: os || "",
    device_type: device_type || "",
    browser: browser || "",
    location: location || ""
  }


  useEffect(async ()=>{
    if(!ipFetched){
      dispatch?.commonParams?.requestIPAddress()
    }
  },[ipFetched])

  useAuthenticatedEffect(()=>{
    // In app notification / alerts
    dispatch?.notification?.requestGetNotification()
  },[])

  useAuthenticatedEffect(() => {
    dispatch?.commonParams?.requestToSetCommonParams(userData)
  }, [userData])

  useSocket();

  usePushNotification()
  return (
    <>
      <MainApp />
      {notificationReqActive && (<PushNotification />)}
    </>
  );
}

export default App;
