import { axiosGet, axiosPost } from "axiosRequests";
import { Notifier } from "components/Notifier";
import {
  CHECK_USER_EXIST_URL,
  EMAIL_LOGIN_URL,
  EMAIL_REGISTER_URL,
  FORGOT_CHILD_PASSWORD_URL,
  FORGOT_PASSWORD_URL,
  RESET_CHILD_PASSWORD_URL,
  RESET_PASSWORD_URL,
} from "constants/Apis";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { STATUS } from "constants/status.constant";
// import { setTokenToStorage } from "utils/token.helper";

const model = {
  state: {
    logedIn_user: {},
    signupError: {},
    loginError: {},
    authLoader: false,
    jwt_token:"",
    account_details:{},
    isCreateProfile: false
  },

  reducers: {
    setUserDetails: (state, payload) => {
      return {
        ...state,
        logedIn_user: payload,
      };
    },
    setJwtToken :(state, payload) => {
      return {
        ...state,
        jwt_token:payload
      }
    },
    setSignUpError: (state, payload) => {
      return {
        ...state,
        signupError: payload,
      };
    },
    setloginError: (state, payload) => {
      return {
        ...state,
        loginError: payload,
      };
    },
    setAuthLoader: (state, payload) => {
      return {
        ...state,
        authLoader: payload,
      };
    },
    setCreateAccountDetails :(state,payload)=>{
      return {
        ...state,
        account_details:payload
      }
    },
    removeToken: (state) => {
      return {
        ...state,
        jwt_token: ""
      }
    },

    // for open create account/profile modal
     setIsCreateProfile :(state,payload)=>{
       return {
         ...state,
         isCreateProfile:payload
       }
     }
  },

  effects: (dispatch) => ({
    async requestEmailSignUp(body, undefined, redirectCallback) {
      try{

        const response = await axiosPost(EMAIL_REGISTER_URL, body);
        const { data, status } = response;
        if (STATUS.includes(status)) {
          dispatch?.registerWithEmail?.setCreateAccountDetails(data)
          dispatch?.authModel?.isNotLoggedIn(true);  // To Open Modal
          // setTokenToStorage(data?.jwt)
          redirectCallback()
        } else {
          dispatch?.registerWithEmail?.setSignUpError(data?.error);
          dispatch?.registerWithEmail?.setAuthLoader(false);
        }
      }
      catch(err){
        console.log(err,"error in email signup")
      }
    },

    // for opent create account/profile modal
    async requestCreateProfile(payload){
      try{
        dispatch?.registerWithEmail?.setIsCreateProfile(payload)
      }
      catch(err){

      }
    },

    async requestEmailLogin(body, undefined, redirectCallback) {
      try{
        const response = await axiosPost(EMAIL_LOGIN_URL, body, null);
        const {
          data: { jwt, user, error },
          status,
        } = response;
        if (STATUS.includes(status)) {
          dispatch?.registerWithEmail?.setCreateAccountDetails(response?.data)
          dispatch?.registerWithEmail?.setUserDetails(user);
          // dispatch?.registerWithEmail?.setJwtToken(jwt)
          redirectCallback();
        } else {
          dispatch?.registerWithEmail?.setloginError(error);
          dispatch?.registerWithEmail?.setAuthLoader(false);
        } 
      }
      catch(err){
        console.log(err,"error in email login")
      }
    },
    async requestCheckUserExist(email, undefined, callback) {
      try{

        const response = await axiosGet(`${CHECK_USER_EXIST_URL}?email=${email}`);
        const { data } = response;
        callback(data?.userExists)
      }
      catch(err){
        console.log(err,"error in check user")
      }
    },
    
    async requestForgotPassword(body,undefined,callback){
      try{
        const response = await axiosPost(FORGOT_PASSWORD_URL,body)
        const {data, status} = response
        if(STATUS.includes(status)){
          callback()
          Notifier(status,"Please check you email,we have sent to you link to reset your password")
        }
        else{
          Notifier("DELETED",data?.error?.message)
        }
      }
      catch(err){
        console.log(err,"error forgot password")
      }
    },
    async requestResetPassword(body, undefined, callback) {
      try{
        const response = await axiosPost(RESET_PASSWORD_URL, body)
        const { data, status } = response
        if (STATUS.includes(status)) {
          Notifier(status, MESSAGE_CONSTANTS.RESET_PWD_MSG)
          callback("success")
          // callback("success", "Password reset successfull")
        }
        else {
          Notifier(status, MESSAGE_CONSTANTS.RESET_PWD_ERR_MSG)
          callback("error")
        }
      }
      catch(err){
        Notifier('Error',MESSAGE_CONSTANTS.RESET_PWD_ERR_MSG)
        console.log(err,"error in reset password")
      }
    },
    async requestForgotChildPassword(){
      try{
        const response = await axiosGet(FORGOT_CHILD_PASSWORD_URL)
        const {data, status} = response
        if(STATUS.includes(status)){
          Notifier(status, "Reset link to update child mode password has been sent successfully to your email", 4)
        }
        else{
          Notifier("DELETED", data?.error?.message)
        }
      }
      catch(err){
        console.log(err, "error forgot password")
      }
    },
    async requestResetChildPassword(body, undefined, callback) {
      try{
        const response = await axiosPost(RESET_CHILD_PASSWORD_URL, body)
        const { data, status } = response
        if (STATUS.includes(status)) {
          Notifier(status, MESSAGE_CONSTANTS.RESET_PWD_MSG)
          callback("success")
        }
        else {
          Notifier(status, MESSAGE_CONSTANTS.RESET_PWD_ERR_MSG)
          callback("error")
        }
      }
      catch(err){
        Notifier('Error',MESSAGE_CONSTANTS.RESET_PWD_ERR_MSG)
        console.log(err,"error in reset password")
      }
    },
    async requestRemoveToken() {
      dispatch?.registerWithEmail?.removeToken()
      dispatch?.authModel?.isNotLoggedIn(true);
    }
  }),
};
export default model;
