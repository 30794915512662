import * as eventConstants from "./events.constants";
import homeEvents from "./HomeEvents";

const handleEvent  = (eventCode, extraParams={}) => {
  homeEvents.handle_analytics_event(eventCode, extraParams);
};

export const homeLoadEvent = (loadEnd = false) => {
  const eventCode = loadEnd ? eventConstants?.HOME_LOAD_END : eventConstants?.HOME_LOAD_START;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
}

export const homeFilterHeadingEvent = (extraParams ={}) => {
  const eventCode = eventConstants?.HOME_FILTER_HEADING_CLICK
  handleEvent(eventCode,extraParams)
}

export const homeFilterOptionsEvent = (extraParams ={}) =>{
  const eventCode = eventConstants?.HOME_FILTER_OPTIONS_CLICK
  handleEvent(eventCode, extraParams)
}

export const homeFilterClearAllEvent = (extraParams ={}) =>{
  const eventCode = eventConstants?.HOME_FILTER_CLEAR_ALL_CLICK
  handleEvent(eventCode, extraParams)
}

export const homeFilterApplyEvent = (extraParams={})=> {
  const eventCode = eventConstants?.HOME_FILTER_APPLY_CLICK
  handleEvent(eventCode, extraParams)
}

export const homeBannerEvent = (extraParams ={}) => {
  const eventCode = eventConstants?.HOME_BANNER_CLICK
  handleEvent(eventCode, extraParams)
}

export const homeContinueWatchingCardEvent = (extraParams={}) =>{
  const eventCode = eventConstants?.HOME_CONTINUE_WATCHING_CARD_CLICK
  handleEvent(eventCode, extraParams)
} 

export const homeWatchNowEvent = (extraParams={})=>{
  const eventCode = eventConstants?.HOME_WATCH_NOW_CARD_CLICK
  handleEvent(eventCode,  extraParams)
}

export const homeWatchnowWishlistEvent = (extraParams={}) => {
  const eventCode = eventConstants?.HOME_WATCH_NOW_WISHLIST_CLICK
  handleEvent(eventCode, extraParams)
}

export const homeContinueWatchingSeeAllEvent = (extraParams={}) => {
  const eventCode = eventConstants?.HOME_CONTINUE_WATCHING_SEE_ALL
  handleEvent(eventCode, extraParams)
}

export const homeContinueWatchingSeeLessEvent = (extraParams={}) => {
  const eventCode = eventConstants?.HOME_CONTINUE_WATCHING_SEE_LESS
  handleEvent(eventCode, extraParams)
}