import React from "react";
import FormButton from "stories/FormButton";

const NextButton = ({handleNext, nextBtnLabel = "Next Episode" }) => {
  return (
    <FormButton
      htmlType="submit"
      className="bg-greeny text-white rounded hover:bg-greeny hover:text-white right-[5%] lg:right-[18%]"
      onClick={handleNext}
    >
      {nextBtnLabel}
    </FormButton>
  );
};

export default NextButton;
