import React from "react";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";


const OffersAndDiscountMarkdown = (props) => {
    const {termsAndConditions} =props
  return (
    <div className="text-grayColor bg-ternary text-md mt-1 p-12 ">
      <ReactMarkdown
        components={{
          h3: ({ node, ...props }) => <h3 {...props} />,
          h5: ({ node, ...props }) => <h3 {...props} />,
          ul: ({ node, ...props }) => (
            <ul className="ml-6 mb-3">
              <li className="text-grayColor list-disc" {...props}></li>
            </ul>
          ),
          p: ({ node, ...props }) => (
            <p className="text-grayColor" {...props} />
          ),
          a: ({ node, ...props }) => <a {...props} />,
        }}
        children={termsAndConditions}
        remarkPlugins={[remarkGfm]}
      />
    </div>
  );
};

export default OffersAndDiscountMarkdown;
