import { message } from "antd";
import { axiosGet, axiosPost } from "axiosRequests";
import {
  ADD_REVIEW_URL,
  GET_ALL_REVIEWS_URL,
  GET_COURSE_EPISODES_URL,
  GET_COURSE_URL
} from "constants/Apis";
import { STATUS, STATUS_CODE } from "constants/status.constant";
import { Notifier } from "components/Notifier";
import { MESSAGE_CONSTANTS } from "constants/app.constants";

const model = {
  state: {
    course_details: {},
    episode_details: [],
    review_details: [],
    courseLoading:true,
    reviewLoading :true,
    episodeLoading:true,
    isUserReviewed :false,
    bypassCourseDetails: {
      isPurchaseBypassKey: false,
      courseId: null
    },
    reviewError: false,
    episodeListError:false,
    courseDescError:false,
    currentPage:0,
    pagination:{}
  },

  reducers: {
    setCourseDetails: (state, payload) => {
      return {
        ...state,
        course_details: payload,
      };
    },
    setEpisodesDetails: (state, payload) => {
      return {
        ...state,
        episode_details: payload,
      };
    },
    setEpisodeLoading : (state, payload) => {
      return {
        ...state,
        episodeLoading:payload
      }
    },
    setReviewDetails: (state, payload) => {
      return {
        ...state,
        review_details: payload,
      };
    },
    setReviewLoading :(state, payload) => {
      return {
        ...state,
        reviewLoading: payload
      }
    },
    setPurchaseByPassCourse: (state, bypassKey, courseId) => {
      return {
        ...state,
        bypassCourseDetails: {
          isPurchaseBypassKey: bypassKey,
          courseId: courseId
        }
      }
    },
    setIsReviewedByUser: (state, payload)=>{
      return {
        ...state,
        isUserReviewed: payload
      }
    },
    setErr: (state, payload) => {
      return {
        ...state,
        ...payload
      }
    },
    setCourseLoading :(state,payload)=>{
      return {
        ...state,
        courseLoading:payload
      }
    },
    setCurrentPage :(state,payload) =>{
      return {
        ...state,
          currentPage:payload
      }
    },
    setPagination:(state,payload)=>{
      return {
        ...state,
        pagination:payload
      }
    },
    clearReViews:(state,payload) => {
      return {
        ...state,
        review_details:payload
      }
    }
  },

  effects: (dispatch) => ({
    async requestCourseDetails(id,undefined,cb) {
      try{
        dispatch?.course?.setCourseLoading(true)
        const response = await axiosGet(`${GET_COURSE_URL}/${id}`);
        const {data,status } = response
        if(status == 200 ){
          dispatch?.course?.setErr({courseDescError : false})
          dispatch?.course?.setCourseLoading(false)
          dispatch.course.setCourseDetails(data);
          cb && cb(true, data)
        }else if(status == STATUS_CODE['notFound']){
          cb && cb(false, { notFound: true });
        }
      }
      catch(err){
        dispatch?.course?.setErr({courseDescError:true})
        dispatch?.course?.setCourseLoading(false)
      }
    },

    async requestEpisodesDetails(id) {
      try{
          const response = await axiosGet(`${GET_COURSE_EPISODES_URL}/${id}`);
          dispatch?.course?.setEpisodeLoading(true)
          const { data ,status} = response;
          if(response === undefined){
            return dispatch?.course?.setEpisodeLoading(true)
          }
          if(STATUS.includes(status)){
            dispatch?.course?.setEpisodeLoading(false)
            dispatch.course.setEpisodesDetails(data);
            dispatch?.course.setErr({episodeListError:false})
          }
          else{
            dispatch?.course?.setEpisodeLoading(false)
          }
      }
      catch(err){
        dispatch?.course?.setErr({episodeListError:true})
        dispatch?.course?.setEpisodeLoading(false)
      }
    },
    async requestReviewDetails(id,state,nextPage = 1) {
      try{
          dispatch?.course?.setCurrentPage(nextPage)
          const pagination = `pagination[pageSize]=${12}&pagination[page]=${nextPage}` //  if we want to show button reduce the pageSize
          const response = await axiosGet(`${GET_ALL_REVIEWS_URL}/${id}?${pagination}`); //
          dispatch?.course?.setReviewLoading(true)
          const { data, status } = response;
          const { meta } = data
          if(response === undefined ){  
                return  dispatch?.course?.setReviewLoading(true)
          }
          if (STATUS.includes(status)) {
            dispatch?.course?.setPagination(meta)
            // data is getting visible multiple times whn it use below code 
            const list = state?.course?.review_details
            const updated= [...list,...data?.results]
            const key="author"
            const result =  [...new Map(updated.map(item => [item[key], item])).values()]   
            dispatch?.course?.setIsReviewedByUser(meta?.isReviewedByUser)
            dispatch?.course?.setReviewLoading(false)
            dispatch?.course?.setErr({reviewError:false})
            dispatch?.course?.setReviewDetails(result);
          }
          else {
            dispatch?.course?.setReviewLoading(false)
            message.error(data?.error?.message)
          }
      }
      catch(err){
        dispatch.course.setErr({reviewError:true});
        dispatch?.course?.setReviewLoading(false);
      }
    },

    async requestAddReviews(body, state) {
      try{
        const response = await axiosPost(ADD_REVIEW_URL, body);
        const { data, status } = response;
        dispatch?.course?.requestReviewDetails(body?.courseId)
        if (STATUS.includes(status)) {
          const updated = state?.course?.review_details
          const currentUser= state?.profile?.profileDetails
          const  { response } = data
          const payload = {
            author:currentUser?.username,
            createdAt:response?.data?.attributes?.createdAt,
            rating:response?.data?.attributes?.rating,
            isEditable: true,
            reviewBody:response?.data?.attributes?.review
          }
           updated[0] = payload
           dispatch?.course?.setReviewDetails(updated);
           // start ****  update rating on watch now

           let watchnowList =  state?.home?.watchNowList
           const index = watchnowList?.findIndex((_items)=> _items?.courseId === Number(body?.courseId))
           if(index !== -1){
            const watchnowData = watchnowList[index]
            const watchnowPayload = {
             ...watchnowData,
             courseRating:parseFloat(data?.newCourseRating)
            }
            watchnowList[index] = watchnowPayload
           }
           // end *** update rating on watch now
          if(data && data.newCourseRating && parseInt(data.newCourseRating) !== state?.course?.course_details?.overallRating){
            if(index  !==-1){
              dispatch?.home?.setWatchNowList(watchnowList)
            }
            dispatch.course.setCourseDetails({...state?.course?.course_details, overallRating: parseFloat(data?.newCourseRating)});
          }
          if(body?.review){
          Notifier(status, MESSAGE_CONSTANTS.REVIEW_COURSE)
          }else{
            Notifier(status, MESSAGE_CONSTANTS.RATING_COURSE)
          }
        }else{
          if(body?.review){
            Notifier(status, MESSAGE_CONSTANTS.REVIEW_COURSE_ERROR_MESSAGE)
            }else{
              Notifier(status, MESSAGE_CONSTANTS.RATING_COURSE_ERROR_MESSAGE)
            }
        }
      } 
      catch(err){
        Notifier('Deleted', MESSAGE_CONSTANTS.REVIEW_ERROR_MESSAGE)
      } 
    },

  }),
};
export default model;
