import InstructorCard from "components/InstructorCard";
import MultiLoadingCards from "components/MultiLoadingCards";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import useIpEffect from "hooks/useIpEffect";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Headder, Heading } from "stories/Typography";

const Instructors = () => {
  const isMobile = useMedia("(max-width: 600px)");
  const history = useHistory();
  const dispatch = useDispatch();
  const { instructorList, loading } = useSelector((state) => state?.instructor);

  useIpEffect(() => {
    dispatch?.instructor?.getInstructorList();
  }, []);
  return (
    <div className="min-h-screen">
      {(loading && instructorList.length === 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6 md:pl-[64px] px-[1rem]">
            <MultiLoadingCards count={isMobile ? 1 : 4} />
        </div>
      )) || (
        <div className="md:pl-[64px] sm:pl-[1rem] px-[1rem] all__left__padding">
          <Heading className="inst--title mt-[2rem] text-center">
            Instructors
          </Heading>
       { instructorList?.length > 0 ?  
       <div className="all---instructors grid sm:gap-[1rem] sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-[2rem] md:gap-8 pt-10 mb-10">
            {instructorList &&
              instructorList?.length > 0 &&
              instructorList?.map((data, idx) => {
                return (
                  <div
                    className="all--list--content"
                  >
                    <InstructorCard
                      nextAvailableSlot={true}
                      iw={false}
                      {...data}
                      isInstructorPage= {true}
                    />
                  </div>
                );
              })
                }
          </div>
          : 
          <div className="flex justify-center items-center">
          <Heading className="inst--title mt-[2rem] text-center">
            No instructors available.
          </Heading>
          </div>
          }
        </div>
      )}
    </div>
  );
};

export default Instructors;
