import React ,{useState} from 'react'
import useAuthenticatedEffect  from 'hooks/useAuthenticatedEffect';
import { useSelector } from 'react-redux';
import  useMedia  from 'hooks/deviceHelper/useMedia';
import { getCookie } from 'utils/storage.helper';
import { getMobileOperatingSystem } from 'utils/deviceHelper';
import { ANDROID_ALLOWED_COUNTRIES, ANDROID_OS_KEY } from 'constants/app.constants';

const APP_BANNER_DELAY = 5000

const useMobileAppBanner = () => {
   const appBannerShown = useSelector((state)=> state?.storageData?.appBanner)    // Open a banner when
   const profileInfo = useSelector((state) => state?.profile?.profileDetails); // to check first name last name are present or not 
   const isAndroid = getMobileOperatingSystem() &&  getMobileOperatingSystem() === ANDROID_OS_KEY;
   let getcookies = getCookie("isappBannerPrompt");
   const { firstName, country } = profileInfo
   const [showBanner,setShowBanner] = useState(false) 
   const isMobile = useMedia()
   const allowedInUserCountry = country && ANDROID_ALLOWED_COUNTRIES.includes(country)
   
   useAuthenticatedEffect(()=>{
    if(isMobile && !appBannerShown && firstName && firstName !== null && allowedInUserCountry){
      setTimeout(()=>{
        if(getcookies){
        setShowBanner(false)
        }else{
          setShowBanner(true)
        }
      },APP_BANNER_DELAY) 
    }else{
      setShowBanner(false)
    }
   },[appBannerShown,profileInfo,isMobile])
  return showBanner
}

export default useMobileAppBanner
