import Image from "components/Image";
import { useRef ,useState} from "react";
import HorizontalLine from "stories/HorizontalLine";
import { LDParagraph } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import "./index.css";
import useMedia from "hooks/deviceHelper/useMedia";
import MobileActionPopUp from "../MobileActionPopUp";
import { VideoSpeedIconEvent ,videoSettingsClickEvent, VideoQualityOptionSelectEvent} from "analytics/videoPlayer";

const actionMap = {
  quality: {
    label: "Quality",
    iconSrc: "/VideoControls/setting.svg"
  },
  speed: {
    label: "Playback speed",
    iconSrc: "/VideoControls/speed.svg"
  },
};

const SettingsPopover = ({
  options,
  handleOptionSelect,
  active,
  settingsRef,
  settingHeadRef,
  settingObj
}) => {
  const { label } = settingObj;
  return (
    <div className="absolute bottom-[5rem] right-[2rem] min-w-[8rem]">
      <div className="bg-secondary" ref={settingsRef}>
        <LDParagraph
          ref={settingHeadRef}
          className="text-white hover:text-white hover:bg-black p-3"
        >
          {label}
        </LDParagraph>
        <HorizontalLine />
        {options?.map(_item => {
          const { value, label } = _item;
          const selectedCls = value === Number(active) ? "bg-greeny" : "";
          return (
            <div
              className={`text-white hover:text-black hover:bg-comment ${selectedCls} p-2`}
              onClick={() => handleOptionSelect(value,label)}
            >
              <LDParagraph
                className="flex items-center pl-2"
              >
                {label}
              </LDParagraph>
            </div>
          );
        })}
      </div>
    </div>
  );
};
  
const PlayerActionPopUp = props => {
  const isMobile = useMedia();
  const [show,setShow] =useState(true)

    const handleCloseModal =() =>{
    toggleActionVisibility();

    }
  const { options, handlerFn, active, settingsRef, action="", actionVisible=false, toggleActionVisibility,qualityAllowed ,episodeTitle,courseTitle } = props;
  const handleOptionSelect = (value,label)  => {
    const event_params={
      course_name:courseTitle,
      episode_name:episodeTitle,
      event_label:label
    }
    VideoQualityOptionSelectEvent(event_params)
    handlerFn(value);
    toggleActionVisibility();
  };
  const handleToogle=()=>{
    const event_params={
      course_name:courseTitle,
      episode_name:episodeTitle
    }
    videoSettingsClickEvent(event_params)
    VideoSpeedIconEvent(event_params)
    toggleActionVisibility();
  }
  const settingHeadRef = useRef();
  // useEffect(() => {
  //   const clickHandler = (event) => {
  //     console.log('ettingHeadRef?.current?.contains(event.target)',settingHeadRef?.current?.contains(event.target));
  //     if(settingHeadRef && (settingHeadRef?.current?.contains(event.target))){
  //       if(settingsVisible){
  //         setSettingsVisible(false);
  //       }
  //     }
  //   }
  //   window?.addEventListener('click',clickHandler);
  //   window?.removeEventListener('click',clickHandler);
  // },[settingsVisible, setSettingsVisible]);
  const settingObj = actionMap[action] || {};
  const { iconSrc } = settingObj;
  return (
    <div>
      <Image
        className="h-5"
        src={imagePath(iconSrc)}
        icon={true}
        alt="settings"
        onClick={()=>handleToogle()}
      />
      {actionVisible && (
        isMobile ? 

          <MobileActionPopUp 
              settingHeadRef={settingHeadRef}
              options={options}
              handleOptionSelect={handleOptionSelect}
              active={active}
              settingsRef={settingsRef}
              settingObj={settingObj}  
              handleCloseModal={handleCloseModal}
              show={show}
          /> 
          :
          <SettingsPopover
            settingHeadRef={settingHeadRef}
            options={options}
            handleOptionSelect={handleOptionSelect}
            active={active}
            settingsRef={settingsRef}
            settingObj={settingObj}
          />
      )}{" "}
    </div>
  );
};

export default PlayerActionPopUp;
