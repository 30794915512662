// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBJI2HiXcDC5-Sygx5zv1gCgTnCeM-J2-c",
    authDomain: "lifedemy-app-production.firebaseapp.com",
    projectId: "lifedemy-app-production",
    storageBucket: "lifedemy-app-production.appspot.com",
    messagingSenderId: "241606094604",
    appId: "1:241606094604:web:58fc0904ab3b8b1cb7e329",
    measurementId: "G-Q2846B2CFJ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);