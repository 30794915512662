import axios from "axios";
import { axiosGet, axiosPut } from "axiosRequests";
import { Notifier } from "components/Notifier";
import { IP_ADDRESS_URL, WHISHLIST_URL ,COUNTRY_CODE_URL } from "constants/Apis";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { STATUS } from "constants/status.constant";
import { getUserProfile } from "selectors";
const model = {
  state: {
    commonParams:{},
    app_breakpoint_configs: {},
    ipInfo: { fetched: false },
    countryCode:[]
  },
  reducers: {
    setCommonParams :(state,payload) =>{
     const updatedParams ={...state?.commonParams, ...payload}
      return {
        ...state,
         commonParams: updatedParams
      }
    },
    setCountryCode:(state, payload )=>{
      return {
        ...state,
        countryCode:payload
      }
    },
    setDeviceWidthConfig:(state,payload)=>{
      return {
        ...state,
        app_breakpoint_configs:payload
      }
    },
    setIPAddress:(state, payload)=>{
      return {
        ...state,
        ipInfo: { fetched: true, ...payload }
      }
    }
  },
  effects: (dispatch) => ({
    async requestToSetCommonParams(payload) {
      dispatch?.commonParams?.setCommonParams(payload)
    },
     async requestIPAddress(payload){
        try{
          const result =  await axios.get(IP_ADDRESS_URL)
          dispatch?.commonParams?.setIPAddress(result?.data)
        }
        catch(err){
          console.log(err)
        }
     },
    async requestAddToWishlist(data, state ,cb) {
      const { courseId, type, courseTitle } = data
      const { id } = getUserProfile()
      const body = {
        data: {
          course: courseId,
          users_permissions_user: id,
        },
      };
      try{
          
        /* start --- below code to handle wishlist from home */

          const updateHomeContent = (param)=>{
              const _list = state?.home?.watchNowList 
              const index = _list?.findIndex((_items)=> _items.courseId === courseId )
              const currentCourse = _list[index]
              const payload = {
                ...currentCourse,
                isWishlisted:param
              }
              _list[index] = payload
              dispatch.home.setWatchNowList(_list)
          }
          /* End---- */

          const response = await axiosPut(`${WHISHLIST_URL}/${courseId}/${type}`, body);
          const { data, status } = response;
          if (STATUS?.includes(status)) {
            cb()
            if(type === "add"){
              await updateHomeContent(true)
              Notifier(status,`${courseTitle} ${MESSAGE_CONSTANTS.ADDED_TO_WISHLIST}` )
            }
            else{
              await updateHomeContent(false)
              Notifier(status, `${courseTitle} ${MESSAGE_CONSTANTS.REMOVED_FROM_WISHLIST}`)
            }
          }
      }
      catch(err){
        Notifier('Error', MESSAGE_CONSTANTS.WISHLIST_ERROR_MESSAGE)
      }
    },

    async updateBreakPointWiseAppConfig(payload){
      dispatch?.commonParams?.setDeviceWidthConfig(payload)
    },

    async getCountryCodeSelect(){
        try{
          const result = await axios.get(COUNTRY_CODE_URL)
          const {data, status} = result
           if(STATUS?.includes(status)) {
            const resultCode = data.map((items)=>{
              const value = items?.dial_code
              delete items?.dial_code
              return {...items, value: value}
            })

            this.setCountryCode(resultCode)
          }
        }
        catch(err){
          console.log(err,"ERRPR Cought")
        }
    }
  }),
};
export default model;
