import { forwardRef } from "react";

const { imagePath } = require("utils/assetHelper");

const MiddleControls = forwardRef((props, ref) => {
  const {
    playing,
    handlePlayPause,
    handleForwardSeconds,
    handleBackwardSeconds,
    handleFastForward,
    handleFastBackward,
  } = props;

  return (
    <div ref={ref} className="flex caret-transparent flex-row gap-16 items-center">
      <div
        className="cursor-pointer caret-transparent"
        onClick={() => {
          handleBackwardSeconds(10);
        }}
      >
        <img
          src={imagePath("/VideoControls/round_backward_10.png")}
          alt="backward10 caret-transparent"
          className="h-10"
        />
      </div>
      {/*play and Pause button*/}
      {playing ? (
        <div className="cursor-pointer caret-transparent" onClick={handlePlayPause}>
          <img
            src={imagePath("/VideoControls/pause_circular_2x.png")}
            alt="pausebutton caret-transparent"
          className="h-10"
          />
        </div>
      ) : (
        <div className="cursor-pointer caret-transparent" onClick={handlePlayPause}>
          <img
            src={imagePath("/VideoControls/play_circular_2x.png")}
            alt="playbutton"
          className="h-10 caret-transparent"
          />
        </div>
      )}
      {/* forward 30 seconds*/}
      <div
        className="cursor-pointer caret-transparent"
        onClick={() => {
          handleForwardSeconds(10);
        }}
      >
        <img
          src={imagePath("/VideoControls/round_forward_10.png")}
          alt="backward10"
          className="h-10 caret-transparent"
        />
      </div>
    </div>
  );
});

export default MiddleControls;
