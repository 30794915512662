import React, { useEffect, useState } from 'react'
import Button from 'stories/Button'
import { Modal } from 'antd';
import { COLOR_CONSTANTS } from 'constants/color.constants';
import { ROUTE_CONSTANTS } from 'constants/routes.constants';
import Checkbox from 'stories/Checkbox';
import { TERMS_AND_CONDITIONS_URL } from 'constants/Apis';
import axios from "axios";
import TermsAndConditionsModal from './TermsAndConditionsModal';

const SessionConcent = (props) => {
    const { handleClose, show, onOk } = props;
    const [acceptedPolicy, setAcceptedPolicy] = useState(false)
    const [termsAndConditions, setTermsAndConditions] = useState(false)
    const [terms, setTerms] = useState('');

    useEffect(async ()=>{
      const res = await axios.get(TERMS_AND_CONDITIONS_URL);
      if(res && res.data){
        setTerms(res.data);
      }
    },[])

    const JoinButton = ({ children, className, onClick, disabled }) => {
        return (
          <button onClick={onClick} className={className} disabled={disabled}>
            {children}
          </button>
        );
      };

      const Headder = ({ children, className }) => {
        return (
          <h1
            className={`${className} font-['BWModelica-Medium'] text-center  font-medium`}
          >
            {children}
          </h1>
        );
      };
  return (
    <div>
      <Modal
      className=""
      visible={show}
      closable={true}
      onCancel={handleClose}
      centered
      width={400}
      footer={null}
      bodyStyle={{
        backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
        borderRadius: "10px",
      }}
      maskStyle={{
        backdropFilter: "blur(3px)",
        "-webkit-backdrop-filter": "blur(3px)",
      }}>
        <div>

        <Headder className="font-['BWModelica-Medium'] text-white text-[1.1rem] my-[1rem]">Please read the Terms & Conditions before joining the Session.</Headder>
          <div className='flex'>

        <Checkbox 
            handleChecked={(value)=>{
              setAcceptedPolicy(value)
            }} 
            label="I accept the"
            className="text-white cursor-pointer"
        />
              <p onClick={()=>setTermsAndConditions(true)} className='mb-6 text-greeny cursor-pointer underline'>terms & conditions</p>
            </div>

        </div>
            {termsAndConditions && <TermsAndConditionsModal show={termsAndConditions} handleClose={()=>setTermsAndConditions(false)} data={terms}/>}
        <div className='flex justify-between'>
            <JoinButton className={`${!acceptedPolicy ? 'bg-[#F5F5F5]' : 'bg-greeny' } ${!acceptedPolicy ? 'text-black' : 'text-white'} font-['BWModelica-Medium'] px-[2rem] md:py-[.6rem] py-[.6rem] rounded-lg text-[14px] font-medium text-center`} onClick={onOk} disabled={!acceptedPolicy}>Join</JoinButton>
            <JoinButton className="bg-red-400 text-white font-['BWModelica-Medium'] px-[2rem] md:py-[.6rem] py-[.6rem] rounded-lg text-[14px] font-medium text-center" onClick={handleClose}>Close</JoinButton>
        </div>
      </Modal>
    </div>
  )
}

export default SessionConcent
