export const base_url = process.env.REACT_APP_LIFEDEMY_URL;

export const sesion_base_url = process.env.REACT_APP_SESSIONS_API  

export const payment_base_url = process.env.REACT_APP_PURCHASE_URL;

const content_status_base_url = process.env.REACT_APP_CONTENT_STATUS_URL;


//Home Page
export const TAGS_URL = `${base_url}/categories`;

export const SEARCH_URL = `${base_url}/courses`;

export const WHISHLIST_URL = `${base_url}/my/wishlist`;

export const CONTINUE_WATCHING_URL = `${base_url}/continue-watchings`;// old 

export const CONTINUE_WATCHING_URL_NEW = `${base_url}/my/continue-watch-list`

export const CONTINUE_WATCHING_EPISODES_URL = `${base_url}/api/continue-watchings`;

export const BANNER_URL = `${base_url}/banners`;

export const WATCH_NOW_URL = `${base_url}/courses`;

export const TAGS_LIST_URL = `${base_url}/home-page`
//Course

export const GET_COURSE_URL = `${base_url}/getCourseDetails`;

export const GET_COURSE_EPISODES_URL = `${base_url}/get-course-episodeList`;

export const GET_ALL_REVIEWS_URL = `${base_url}/reviews/get-by-course`;

export const ADD_REVIEW_URL = `${base_url}/reviews`;

export const UPDATE_REVIEW_URL = `${base_url}/reviews`;

//Like and DisLike for episode

export const LIKE_DISLIKE_URL = `${base_url}/likes`
//Sign up with Email

export const EMAIL_REGISTER_URL = `${base_url}/auth/local/register`;

export const EMAIL_LOGIN_URL = `${base_url}/auth/local`;

export const CHECK_USER_EXIST_URL = `${base_url}/userCheck`

export const FORGOT_PASSWORD_URL = `${base_url}/auth/forgot-password`

export const RESET_PASSWORD_URL = `${base_url}/auth/reset-password`

export const FORGOT_CHILD_PASSWORD_URL = `${base_url}/user/forgot-childmode-password`

export const RESET_CHILD_PASSWORD_URL = `${base_url}/user/reset-childmode-password`

//Sign up with Phone

export const PHONE_REGISTER_URL = `${base_url}/v1/auth/initiatePhoneLogin`

export const VERIFY_PHONE_OTP_URL = `${base_url}/v1/auth/verifyPhoneOTP`

export const RESEND_LOGIN_OTP_URL = `${base_url}/v1/auth/resendPhoneOTP`
//Suggestions

export const SEND_SUGGESTIONS_URL = `${base_url}/suggestions`
//Wishlist

export const GET_WISHLIST_URL=`${base_url}/my/wishlist`

export const REMOVE_WISHLIST_URL = `${base_url}/wishlists`

//CouponList

export const GET_ALL_COUPON_LIST_URL=`${base_url}/coupons`


//My course 


export const GET_MY_COURSE_URL=`${base_url}/myCourses`


//Episode Detail
export const GET_EPISODE_DETAIL=`${base_url}/course` //  Episode details api

export const GET_COMMENTS_LIST = `${base_url}/comments/api::episode.episode`

export const LOG_EPISODE_COMMENT = `${base_url}/episodes/log-comment`;

//Profile

export const GET_PROFILE_DETAILS=`${base_url}/users/me`

export const CHANGE_PROFILE_PIC = `${base_url}/changeProfilePic`

export const PROFILE_VERIFY__PHONE_URL = `${base_url}/users-permissions/verifyPhoneNumber`

export const PROFILE_UPDATE_PHONE_URL  = `${base_url}/users-permissions/updatePhoneNumber`

export const PROFILE_PHONE_RESEND_OTP_URL   = `${base_url}/auth/resendPhoneOTP`

export const PROFILE_DELETE_PIC = `${base_url}/deleteDisplayPic`

export const PROFILE_ADD_UPDATE_EMAIL = `${base_url}/auth/send-email-confirmation`
//Referral

export const REFERRAL_URL =`${base_url}/referAFriend`
export const REFERRAL_PAGE = `${base_url}/referral-page`

//Purchase 

export const INITIATE_PURCHASE_URL = `${payment_base_url}/initiatePurchase`

export const GET_PURCHASE_DETAILS_URL = `${payment_base_url}/getPurchaseObject`

export const APPLY_COUPON_CODE_URL =` ${payment_base_url}/applyCoupon`

export const INITIATE_PAYMENT_URL = `${payment_base_url}/payments/initiate`

export const VERIFY_PAYMENT_URL = `${payment_base_url}/payments/verify`
//Settings
export const LANGUAGE_PREFERENCES_URL = `${base_url}/language-preference`

//Gmail Login 

export const GOOGLE_CONNECT_URL = `${base_url}/connect/google`

export const FACEBOOK_URL = `${base_url}/connect/facebook`

//episode content status update
export const CONTENT_STATUS_URL = `${content_status_base_url}/update-content-status`;

export const PRIVACY_POLICY_S3_URL = `https://resource.lifedemy.in/privacyPolicy.html`;

export const TERMS_AND_CONDITIONS_URL = `https://resource.lifedemy.in/sessionTerms.html`; 

// Notification 
export const NOTIFICATION_URL = `${base_url}/get-notification`

//Push notification 

export const PUSH_NOTIFICATION_URL = `${content_status_base_url}/add-notification-device`

export const GET_PUSH_NOTIFICATION_PREFERENCES = `${content_status_base_url}/get-device-notify-details`

// Mobile App store links

export const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.lifedemy"

export const APP_STORE_LINK = "https://apps.apple.com/in/app/lifedemy/id1636918706"

// Resize image api endpoint

export const GENERATE_RESIZED_IMG = `${content_status_base_url}/generate-resized-image`

export const VIDEO_FEEDBACK_URL = `${base_url}/episodes/course`


export const GUEST_PAYMENT_URL = `${base_url}/v1/initiateGuestPayment`

export const GUEST_PAYMENT_CANCEL_URL = `${base_url}/course/payment-cancelled`

const telecomKey = '63a021b6375f8b0008328abb'

export const TELECOM_SUPPORT_URL = `https://app.telecrm.in/api/b1/enterprise/${telecomKey}/autoupdatelead` 

export const GET_GUEST_UPDATE_URL = `${base_url}/requestGuestUpdate`

export const UPDATE_GUEST_EMAIL_URL = `${base_url}/updateGuest`

export const PURCHASE_SUCCESS_EMAIL_URL = `${payment_base_url}/sendPurchaseSuccessEmail`

// Child Mode URL
export const CHILD_MODE_URL = `${base_url}/users/updateChildMode`

export const IP_ADDRESS_URL = `${content_status_base_url}/my-ip`

export const COUNTRY_CODE_URL = 'https://resource.lifedemy.in/country_list_withFlag.json' 

// instructors
export const INSTRUCTORS_URL = `${sesion_base_url}/instructor/list?`

export const RATINGS_URL = `${sesion_base_url}/review/getRating`

export const INSTRUCTOR_DETAILS= `${sesion_base_url}/instructor`

export const ALL_MEETING_LIST= `${sesion_base_url}/user/meeting/list`

export const TIME_SLOT_LIST = `${sesion_base_url}/timeSlot/`

export const CREATE_MEETING = `${sesion_base_url}/meeting/create?stas`

export const UPDATE_QUERY= `${sesion_base_url}/meeting/addMeetingQuery`

export const GET_BOOKED_SESSION_DETAILS = `${sesion_base_url}/meeting/getById`

export const CREATE_MEETING_SIGNATURE_TOKEN = `${sesion_base_url}/meeting/getTokenSignature`

export const ADD_FEEDBACK_REVIEW= `${sesion_base_url}/review/create`

export const POST_SESSION_EMAIL =`${payment_base_url}/session-sendPurchaseEmail/v1`

export const INITIATE_SESSION_PURCHASE = `${payment_base_url}/initiateSessionPurchase/v1` // called

export const INITIATE_SESSION_PAYMENT_URL = `${payment_base_url}/initiate-sessionPayment/v1` // called


export const GET_PURCHASE_ORDER_INFO = `${payment_base_url}/get-purchaseOrderInfo/v1`

export const SESSION_VERIFY_PAYMENT = `${payment_base_url}/verify-payment/v1`

export const MY_TODAY_MEETINGS = `${sesion_base_url}/user/meeting`


export const EXTERNAL_ROUTE_LINKS = {
    LIFEDEMY_MEETING: process.env.REACT_APP_ENV === "production" ? '' : 'https://meeting-staging.lifedemy.in', // TODO: change this LATER
    HOME_WELCOME_PAGE_URL: "https://home.lifedemy.in",
    ABOUT_URL: 'https://home.lifedemy.in/About-us',
    CONTACT_URL: 'https://home.lifedemy.in/contact',
    COURSES: 'https://home.lifedemy.in/courses',
    BLOGS: 'https://home.lifedemy.in/blog/all-blogs',
    BLOG_DETAIL: 'https://home.lifedemy.in/blog',
    TERMS: 'https://home.lifedemy.in/Terms-and-conditions'
}

export const SESSION_PAYMENT_INVOICE = `${payment_base_url}/session-paymentInvoice/v1`

export const  RELEASE_TIME_SLOT = `${payment_base_url}/session-releaseTimeSlot` 

export const CANCEL_MEETING = `${sesion_base_url}/user/meeting/cancel`

export const RESCHEDULE_MEETING = `${sesion_base_url}/user/meeting/reschedule`

export const SESSION_HOLDSLOT = `${payment_base_url}/session-holdTimeSlot`

export const SESSION_REFUND_URL = `${sesion_base_url}/meeting/cancel-refund`


export const APPLY_SESSION_COUPON_CODE  = `${payment_base_url}/apply-sessionCoupon/v1`