import useMedia from "hooks/deviceHelper/useMedia";
import { Fragment, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { LDParagraph } from "stories/Typography";
import { debounce } from "utils/debounce";
import useKeyboardControls from "../hooks/useKeyboardControls";
import BottomControls from "./BottomControls";
import MiddleControls from "./MiddleControls";
import "./index.css"
import { imagePath } from "utils/assetHelper";


const PlayerControls = props => {
  const {
    handlePlayPause,
    handleBackwardSeconds,
    handleForwardSeconds,
    toggleFullScreen,
    controlsVisible,
    setControlsVisible,
    videoTitle,
    isfullScreen,
    localFullScreen
  } = props;

  const { playing, playerInitialised } = useSelector((state) => state?.videoPlayer);

  const middleControlRef = useRef();
  const bottomControlRef = useRef();
  const settingsRef = useRef();
  const isMobile = useMedia();
//Me
  const handleEscape =()=>{
    if(isfullScreen){
      toggleFullScreen()
    }
  }

  useKeyboardControls({
    onSpace: handlePlayPause,
    onArrowLeft: () => handleBackwardSeconds(5),
    onArrowRight: () => handleForwardSeconds(5),
    onArrowUp: "",
    onArrowDown: "",
    onFkey: toggleFullScreen,
    onEsc:() => handleEscape()
  });

  const callDebounced = debounce(fn => fn(), 2500);

  const handleMouseMove = useCallback(() => {
      if(playerInitialised){
        setControlsVisible(true);
        callDebounced(() => {
          setControlsVisible(false);
        });
      }
  }, [playerInitialised, setControlsVisible]);

  const handleMouseLeave = useCallback(() => {
    setControlsVisible(false);
  }, [setControlsVisible]);

  const handleScreenClick = (event) => {
    if(middleControlRef && bottomControlRef && !(middleControlRef?.current?.contains(event.target) || bottomControlRef?.current?.contains(event.target) || settingsRef?.current?.contains(event.target))){
      if(isMobile){
        !controlsVisible && handleMouseMove()
        setControlsVisible(!controlsVisible)
      }else{
        handlePlayPause();
      }
    }
  }
  const showControls = playerInitialised && (controlsVisible || !playing);
  const wrapperOpacity = showControls ? "opacity-1" : "opacity-0";
  const controlsDisplayCls = showControls ? "block" : "hidden"
  const topStyle = isfullScreen ? "" : `w-full ${isMobile ? "pt-[19%]" : "pt-[23%]" }`
  
  return (
      <div
        className={`absolute top-0 left-0 h-full w-full min-h-full flex flex-col justify-between px-2.5 bg-black bg-opacity-25 ${wrapperOpacity}`}
        onClick={handleScreenClick}
        onMouseMove={!isMobile && handleMouseMove} 
        onMouseLeave={!isMobile && handleMouseLeave}
      >
          <Fragment>
            {/* top */}
            {videoTitle?.length > 0 && 
            <div className={`text-white ${controlsDisplayCls}`}>
              <LDParagraph className=" top-0 left-0 mr-5 mt-3 video-title-style leading-5">{videoTitle}</LDParagraph>
              {localFullScreen &&
                <div className="top-0 right-0 mr-5 mt-5 absolute" onClick={toggleFullScreen}>
                      <img src={imagePath("/cross.svg")} alt="crossImg" className="cursor-pointer"/>
                </div>
              }
            </div>}

            {/* middle */}
            <div className={`flex justify-center ${topStyle} ${controlsDisplayCls}`}>
              <MiddleControls ref={middleControlRef} {...props} />
            </div>

            {/* end */}
            <div ref={bottomControlRef} className={controlsDisplayCls}>
              <BottomControls settingsRef={settingsRef} {...props} />
            </div>
          </Fragment>
      </div>
  );
};

export default PlayerControls;
