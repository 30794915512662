
import Carousel from "stories/Carousel";
import "./index.css";
import { imagePath } from "utils/assetHelper";
import Image from "components/Image";
import { homeBannerEvent } from "analytics/home";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import { PROTOCOL_TYPE } from "constants/app.constants";

const Banner = (props) => {
  const { bannerImg, isCarouselActive, bannerList } = props;  
  const history = useHistory()

  const handleClick=(targetUrl)=>{
    homeBannerEvent()
    if(targetUrl.includes(PROTOCOL_TYPE)){
        window.open(targetUrl)
    } else{
        history.push(targetUrl)
    } 
  }
  const appConfig = useSelector((state)=> state?.commonParams?.app_breakpoint_configs);
  let imgRestProps = {};
  if(appConfig){
    imgRestProps["dimension"] = appConfig?.HOME_BANNER_DIMENSION;
  }
  return (
    <>
      {isCarouselActive ? (
        <>
          <Carousel autoplay={true} infinite={true} arrowsEnabled={true} dots={true} >
            {bannerList?.length > 0 && bannerList?.map((_urls, i) => {
              const { data, bannerUrl ,targetUrl,targetScreen,id} = _urls;
              return (
                <div className="" key={i} onClick={()=>handleClick(targetUrl)}>
                  {targetUrl ? (
                    //  <LinkTag route={{pathname:targetUrl}} target="_blank">
                     <Image
                       src={bannerUrl || bannerImg} //`http://lifedemy-api-dev.ap-south-1.elasticbeanstalk.com${attributes?.url} later use
                       className="w-full lg:rounded-xl rounded-md"
                       alt="banner"
                       {...imgRestProps}
                     /> 
                  ) : ( 
                  <Image
                    src={bannerUrl || bannerImg} //`http://lifedemy-api-dev.ap-south-1.elasticbeanstalk.com${attributes?.url} later use
                    className="w-full lg:rounded-xl  rounded-md"
                    alt="banner"
                    {...imgRestProps} 
                  />)}                        
                </div>
              );
            })}
          </Carousel>
        </>
      ) : (
        <div className="px-6">
          <LDImage
            src={imagePath("/bannerImg.svg")}
            className="w-full"
            alt="banner"
          />
        </div>
      )}
    </>
  );
};

export default Banner;
