import { paymentStatusLoadEvent, PaymentStatusTryAgainBtnClickEvent } from "analytics/payment";
import { Col, Row } from "antd";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Button from "stories/Button";
import LDImage from "stories/Image";
import { LDParagraph, Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { useSelector, useDispatch } from 'react-redux';
import "./index.css"

const PaymentError = () => {
  const history = useHistory();
  const location = useLocation()
  const state = location?.state
  const course_details = useSelector((state) => state?.course?.course_details);
  const handleRedirect = () => {
    const params = {event_label : course_details?.courseTitle}
    PaymentStatusTryAgainBtnClickEvent(params)
    if(state?.activeRoute === "guest"){
       return history.push(`${ROUTE_CONSTANTS.GUEST_ROUTE}?courseId=${state?.courseId}`)
    }
    history.push(`/${ROUTE_CONSTANTS.PAYMENT}?purchaseId=${state?.purchaseId}&courseId=${state?.courseId}`);
  };
  useEffect(()=>paymentStatusLoadEvent({event_label:`${course_details?.courseTitle}-${"Failure"}`}),[])

  return (
    <div className="container mx-auto p-4 min-h-screen">
    <div className="bg-card mx-auto lg:w-1/2  mt-24">
      <div className="text-center p-8">
        <LDImage
          src={imagePath("/Payment/paymenterror.svg")}
          alt="paymentSuccess"
          className="m-auto block mt-12"
        />
        <Title className="text-white payment-failed-heading-style">Payment Failed</Title>
        <LDParagraph className="text-white payment-failed-message-style mt-6">
          Unfortunately we could not process your payment. Please take a moment
          to review your billing information.
        </LDParagraph>
        <Button
          type="primary"
          className="p-2 w-full mt-10 mb-12 rounded bg-white border-none text-xl payment-failed-btn-style"
          label={"Try again"}
          onClick={handleRedirect}
        />
      </div>
    </div>
    </div>
  );
};

export default PaymentError;
