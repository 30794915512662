import { LDAnalytics } from "utils/analyticsHelper.js";

class SettingsEvents extends LDAnalytics {
  event_Settings_Push_Notification_Click(){
    return {
      event :{
        module_name :"Settings",
        feature_name: "Push notification",
        event_name: "Settings_Push_Notification_Click",
        event_label: "Setting",
      }
    };
  }
  event_Settings_Video_Quality_Click(){
      return {
          event:{
            module_name :"Settings",
            feature_name: "Video Quality",
            event_name: "Settings_Video_Quality_Click",
            event_label: "Redirect to Video Quality",
          }
      }
  }
  event_Settings_Child_Mode_Click(){
    return {
      event :{
        module_name :"Settings",
        feature_name: "Parental Control",
        event_name: "Settings_Child_Mode_Click",
        event_label: "Setting",
      }
    };
  }
  event_Settings_Language_Preferences_Click(){
      return {
          event:{
            module_name :"Settings",
            feature_name: "Language Preferences",
            event_name: "Settings_Language_Preferences_Click",
            event_label: "Redirect to Language ",
          }
      }
  }
  event_Settings_Back_Click(){
      return {
          event:{
            module_name :"Settings",
            feature_name: "Settings Back",
            event_name: "Settings_Back_Click",
            event_label: "Language/Video back",
          }
      }
  }
  event_Settings_Language_Preferences_Option_Click(){
      return {
        event:{
            module_name :"Settings/Language-Preferences",
            feature_name: "Languge Preferences",
            event_name: "Settings_Language_Preferences_Option_Click",
            event_label: "Language preference",
          }
      }
  }
  event_Settings_Language_Preferences_Apply_Click(){
    return {
      event:{
          module_name :"Settings/Language-Preferences",
          feature_name: "Languge Preferences",
          event_name: "Settings_Language_Preferences_Apply_Click",
          event_label: "Language preference",
        }
    }
  }
  event_Settings_Video_Quality_Option_Click(){
      return {
          event:{
              module_name:"Settings/Video-Quality",
              feature_name:"Video Quality",
              event_name:"Settings_Video_Quality_Option_Click",
              event_label: "Language preference",
          }
      }  
  }
}

const settingsEvents = new SettingsEvents();
export default settingsEvents;
