import { assetBucketUrl } from "constants/app.constants";

export function imagePath(relativePath){
    return require(`assets/images${relativePath}`);
}

export const getImgUncachedPath = (src) => {
    return src + `?random=${parseInt(new Date().getTime()/1000)}`;
}

export const getImgDimensionSrc = (src="", dimension) => {
    let dimensionImgUrl = "";
    if(src && src.length){
        const imgS3Key = src.replace(`${assetBucketUrl}/`,'');
        dimensionImgUrl = `${assetBucketUrl}/${dimension}/${imgS3Key}`;
    }
    return dimensionImgUrl;
}
