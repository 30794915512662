import { Tooltip } from "antd";
const LDTooltip = props => {
  const { title, children } = props;
  return (
    <Tooltip title={title} {...props}>
      {children}
    </Tooltip>
  );
};
export default LDTooltip;
