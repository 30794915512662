import { profilePhoneOtpInputClickEvent, profilePhoneOtpVerifyBtnClickEvent, profilePhoneResendOtpClickEvent } from "analytics/profile";
import { notification} from "antd";
import OTPInput from "components/Authentication/OTPInput";
import Timer from "components/Timer";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FormButton from "stories/FormButton";
import { LDSmall, LDSpan, Title } from "stories/Typography";
import { useHistory } from 'react-router-dom';
import { Notifier } from 'components/Notifier';
import "./index.css"
import { COLOR_CONSTANTS } from "constants/color.constants";

const EnterOtp = (props) => {
  const { phoneNumber } =props
  const [otpValue, setOtpValue] = useState("");
  const [resendActive, setResendActive] = useState(false);
  const [clearOtp,setClearOtp] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const history = useHistory()
  const dispatch =useDispatch()
  
  const Message = (key,param) => {
    notification[key]({
      message: param,
    });
  }

  const handleOtpResend = () => {
    profilePhoneResendOtpClickEvent({event_label:phoneNumber})
    setClearOtp(true)
    const payload = {
      mobileNumber:phoneNumber,
      type : "mobileVerificationOTP"
    }
    setOtpValue("")
    setResendActive(false)
    dispatch?.profile?.resendOtpProfilePhoneNumber(payload,Message)
  };
  const handleOtpSubmit = () => {
    profilePhoneOtpVerifyBtnClickEvent({event_label:phoneNumber})
    const payload={
        mobileNumber : phoneNumber,
        verifyOTPFromUser : otpValue
    }
    dispatch?.profile?.updateProfilePhoneNumber(payload,(key,value)=>{
     if(key === "success"){
       Notifier(200,value)
       history.push(`/${ROUTE_CONSTANTS?.PROFILE}`);
       setOtpValue("")
     }
    })  
  };
  const handleSubmitEnter=(otps)=>{
    handleOtpSubmit()
  }
  return (
    <div className="mx-auto">
      <div className="lg:mx-32 md:mx-20 mt-2">
        <OTPInput
          length={6}
          autoFocus
          type={"text"}
          resendOtp={true}
          onChangeOTP={(otp) => setOtpValue(otp)}
          handleSubmitEnter={handleSubmitEnter}
          clearOtp={clearOtp}
          otpError={otpError}
          handleClick={()=>profilePhoneOtpInputClickEvent({event_label:`${phoneNumber}`})}
          setOtpError={()=>setOtpError("")}
        />
      </div>

      <div className="m-3">
        <FormButton onClick={handleOtpSubmit} className="text-greeny hover:text-greeny bg-white px-20 md:px-20 lg:px-14 xl:px-32 2xl:px-32"
          backgroundColor={COLOR_CONSTANTS.WHITE}
          color={COLOR_CONSTANTS.GREEN}
          disabled={otpValue?.length !== 6}
        >
          Verify
        </FormButton>
      </div>

      {!resendActive && (
        <LDSmall className="opacity-80 mt-3 verfiy-resent-otp-font-style">
          Resend the OTP in
          <LDSpan className="verfiy-resent-otp-countdown-style ml-1">
            <Timer
              onTimerEnd={() => {
                setResendActive(true);
              }}
            />
          </LDSpan>
        </LDSmall>
      )}
      {resendActive && (
        <LDSmall className=" verfiy-resent-otp-font-style mt-3">
          <LDSpan className=" mr-1 cursor-pointer verfiy-resent-otp-countdown-style" onClick={handleOtpResend}>
            Resend
          </LDSpan>
          OTP
        </LDSmall>
      )}
    </div>
  );
};

export default EnterOtp;
