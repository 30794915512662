import Button from "stories/Button";
import LDImage from "stories/Image";
import { LDParagraph, Subtitle, Title } from "stories/Typography";
import { getRoundedVal } from "utils";
import { imagePath } from "utils/assetHelper";
import "./index.css"

const PurchaseCard = (props) => {
  const { handleSubscribe, price, title,offer_price, currencySymbol } = props;

  return (
    <div className="bg-secondary rounded-md">
      <div className="text-center mb-3 px-[3rem]">
        <div className="flex justify-center pt-10">
          <LDImage src={imagePath("/Course/lock.svg")} alt="" />
        </div>
        {/* <LDParagraph className="text-greeny purchase-card-text-grey pb-[0.25rem] purchase-card-subheading">
          To view 
        </LDParagraph> */}
        <div className="text-white purchase-card-heading mt-[1rem] mb-[0.5rem]">{title}</div>
        <LDParagraph className="text-grayColor purchase-card-text-grey pb-[1rem] purchase-card-subheading">
         {title?.toLowerCase() === "Premium Content".toLowerCase() ? "Purchase to view this course" : "To view this episode purchase this course."} 
        </LDParagraph>
      </div>
      <div className="bg-greeny text-center px-14 pt-4 pb-7 w-full rounded-b-md ">
        <Button
          className={
            "h-10 mb-4 outline-none rounded-md  purchase-card-shadow flex items-center justify-center "
          }
          onClick={handleSubscribe}
          price={`${price}` }
          offer_price={offer_price}
          isRupee={true}
          label={`Purchase for`}
          src={imagePath("/Course/greenRupee.svg")}
          icon={false}
          iconSize="10"
          btnCls={"purchase-card-button mr-2 ml-1"}
          currencySymbol={currencySymbol}
        />
      </div>
    </div>
  );
};

PurchaseCard.defaultProps = {
  handleSubscribe: () => { },
  price: "",
};
export default PurchaseCard;
