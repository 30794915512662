import { Form } from "antd";
import { useQuery } from "hooks/useQuery";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FormButton from "stories/FormButton";
import { Heading, LDSmall, LDParagraph} from "stories/Typography";
import PasswordResetSuccess from "./PasswordResetSuccess";
import { imagePath } from "utils/assetHelper";
import { Notifier } from "components/Notifier";
import { ResetPwdInputClickEvent, resetPwdSaveBtnClickEvent } from "analytics/signupLogin";
import LDTooltip from "stories/LDTooltip";
import PasswordRules from "components/Authentication/PasswordRules";
import { Input as LDNewInput } from "antd";
import Image from "components/Image";
import "../Authentication/index.css"
import { validatePasswordRules } from "utils/stringHelper";
import { checkPasswordIsValid } from "utils";
import { ROUTE_CONSTANTS } from "constants/routes.constants";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [toggle, setToggle] = useState(false);
  const [rulesVisible, setRulesVisible] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [rulesPassed, setRulesPassed] = useState(true);
  const [rulesError, setRulesError] = useState({})

  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const code = query.get("code");
  const passwordRef = useRef();
  const dispatch = useDispatch();

  const currentPath = location?.pathname;
  const isUserResetPasswordPath = currentPath === "/reset-password";

  const handleClick = () => {
    dispatch?.authModel?.isLoggedIn(true);
    dispatch?.authModel?.resetPassword(false);
    history.push(ROUTE_CONSTANTS?.HOME);
  };

  useEffect(() => {
    if (passwordRef?.current?.input) {
      const focusHandler = () => {
        setRulesVisible(true);
      };
      const blurHandler = () => {
        setRulesVisible(false);
      }
      passwordRef?.current?.input?.addEventListener("focus", focusHandler);
      passwordRef?.current?.input?.addEventListener("blur", blurHandler);
      return () => {
        passwordRef?.current?.input?.removeEventListener("focus", focusHandler);
        passwordRef?.current?.input?.removeEventListener("blur", blurHandler);
      };  
    }
  }, [passwordRef]);

  useEffect(()=>{
    if(newPassword.length){
      const valid = validatePasswordRules(newPassword);
      setRulesPassed(valid);
    }else{
      setRulesPassed(false);
    }
  },[newPassword]);

  const Message = (key, param) => {
    if (key === "success") {
      setToggle(true);
    }else{
      setToggle(false);
    }
  };

  const handleUpdatedPassword = (e) => {
    e.preventDefault();
    resetPwdSaveBtnClickEvent() 
      const result = checkPasswordIsValid(newPassword)
      if(result){
        if(newPassword !== confirmPassword ){
          return setRulesError({passwordError:"Password do not match"})
        }
        if (code.length) {
          setRulesError([])
          const payload = {
            code: code,
            password: newPassword,
            passwordConfirmation: confirmPassword,
          };
          {isUserResetPasswordPath ?
            dispatch?.registerWithEmail?.requestResetPassword(payload, Message)
            :
            dispatch?.registerWithEmail?.requestResetChildPassword(payload, Message)
          }
        }
      }else{
        setRulesError({error:"Enter a Valid Password"})
        setTimeout(()=>{
         setRulesError([])
        },[2000] )
      }
  };
  const onClickPasswordIcon = () =>{
    setPasswordShown(!passwordShown);
  }
  const onClickConfirmPasswordIcon = () =>{
    setConfirmPasswordShown(!confirmPasswordShown);
  }
  return (
    <>
      {toggle ? (
        <>
          <PasswordResetSuccess handleClick={handleClick} />
        </>
      ) : (
        <div className="p-4 pb-[60px]">
            <LDSmall
            className=" flex justify-end"
          >
            <span className="cursor-pointer underline" onClick={handleClick}>
            <img src={imagePath("/cross.svg")} alt="crossImg" />
            </span>
          </LDSmall>
          <div className="flex flex-col text-center py-10">
            <Heading className="auth-modal-heading-style">
              {isUserResetPasswordPath ? "New Password" : "Child Mode Password"}
            </Heading>
            <LDSmall className="auth-small-text-style-grey mt-2">
              {isUserResetPasswordPath ? "Set a new password and confirm." : "Set a new child mode password and confirm."}
            </LDSmall>
            <Form className="mt-8" onFinish={values => onFormSubmit(values)}>
              <Form.Item name="email">
                <LDTooltip
                  title={<PasswordRules password={newPassword} />}
                  open={!rulesPassed && rulesVisible}
                  placement="bottom"
                >
                <LDNewInput
                  ref={passwordRef}
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  onClick={() => ResetPwdInputClickEvent()}
                  placeholder="New password"
                  value={newPassword}
                  style={{border: Object.keys(rulesError)?.length > 0 && "1px solid #FF7373" }}
                  onChange={e => {
                    setNewPassword(e?.target?.value);
                  }}
                  className={`bg-secondary rounded px-4 py-[0.65rem]  border border-formBorder w-3/4 auth-input-text-style`}
                />
                <Image
                  src={passwordShown ? imagePath("/show.svg") :imagePath("/hide.svg") }
                  icon={false}
                  onClick={() => onClickPasswordIcon()}
                  alt=""
                  className="h-6 w-6 cursor-pointer absolute  top-2 right-14"
                />
                {Object.keys(rulesError)?.length > 0 &&
                  <LDParagraph className="password-rules-error-style text-left ml-12 mt-1">{rulesError.error}</LDParagraph>
                 }
                </LDTooltip>
                </Form.Item>
                <Form.Item name="password">
                <LDNewInput
                  name="password"
                  type={confirmPasswordShown ? "text" : "password"}
                  onClick={() => ResetPwdInputClickEvent()}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  style={{border: Object.keys(rulesError)?.length > 0 && "1px solid #FF7373" }}
                  onChange={e => {
                    setconfirmPassword(e?.target?.value);
                  }}
                  className={`bg-secondary rounded px-4 py-[0.65rem] border border-formBorder w-3/4 auth-input-text-style`}
                />
                <Image
                  src={confirmPasswordShown ? imagePath("/show.svg") :imagePath("/hide.svg") }
                  icon={false}
                  onClick={() => onClickConfirmPasswordIcon()}
                  alt=""
                  className="h-6 w-6 cursor-pointer absolute top-2 right-14"
                />
                {Object.keys(rulesError)?.length > 0 &&
                  <LDParagraph className="password-rules-error-style text-left ml-12 mt-1">{rulesError.error}</LDParagraph>
                 }
                {Object.keys(rulesError)?.length > 0 &&
                  <LDParagraph className="password-rules-error-style text-left ml-12 mt-1">{rulesError.passwordError}</LDParagraph>
                 }
              </Form.Item>
              <FormButton
                htmlType="submit"
                className="auth-button-text-style bg-greeny text-white w-3/4 rounded mx-auto"
                onClick={handleUpdatedPassword}
              >
                Save
              </FormButton>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
