import Notification from 'pages/Notification'
import React from 'react'
import { useEffect } from 'react';
import { Heading } from 'stories/Typography';
import { useDispatch } from 'react-redux';
const MobileNotifications = (props) => {
  const  {notificationVisible, setNotificationVisible } = props
 
  const mobileNotification = true

  return (
    <div className='px-[8.5rem] xs:px-4 md:px-4 min-h-screen'>
       <div className="text-center">
        <div className="lg:mt-5 mt-10 lg:mb-10 mb-3">
          <Heading className="heading-font-style">Alerts</Heading>
        </div>
        </div>
      <Notification notificationVisible={notificationVisible} setNotificationVisible={setNotificationVisible} mobileNotification={mobileNotification}/>   
    </div>
  )
}

MobileNotifications.defaultProps={
  notificationVisible:true,
  setNotificationVisible : ()=>{},
}

export default MobileNotifications
