import { axiosGet, axiosPut } from "axiosRequests";
import { Notifier } from "components/Notifier";
import {
  BANNER_URL,
  CONTINUE_WATCHING_URL_NEW,
  TAGS_LIST_URL,
  WATCH_NOW_URL,
  WHISHLIST_URL,
} from "constants/Apis";
import { MESSAGE_CONSTANTS, PAGE, PAGE_SIZE } from "constants/app.constants";
import { STATUS } from "constants/status.constant";
import { uniq } from "ramda";

const model = {
  state: {
    tags: {},
    tagsLoading: true,
    banner: [],
    bannerLoading: true,
    watchNowList: [],
    watchNowLoading: true,
    watchNowErr: false,
    paginationMeta: {}, // for pagination
    filterPaginationMeta: {},
    currentPage: 0, // for pagination
    filterCurrentPage: 0,
    originalWatchNowList: [],
    filterQueryString: "",
    instructorList: [],
  },
  reducers: {
    setTagsList: (state, payload) => {
      return {
        ...state,
        tags: payload,
      };
    },
    setTagsLoading: (state, payload) => {
      return {
        ...state,
        tagsLoading: payload,
      };
    },
    setBanner: (state, payload) => {
      return {
        ...state,
        banner: payload,
      };
    },
    setBannerLoading: (state, payload) => {
      return {
        ...state,
        bannerLoading: payload,
      };
    },
    setWatchNowList: (state, payload) => {
      return {
        ...state,
        watchNowList: payload,
      };
    },
    setOriginalWatchNowList: (state, payload) => {
      return {
        ...state,
        originalWatchNowList: payload,
      };
    },
    setWatchNowLoading: (state, payload) => {
      return {
        ...state,
        watchNowLoading: payload,
      };
    },
    setContinueWatchingList: (state, payload) => {
      return {
        ...state,
        continueWatchList: payload,
      };
    },
    setPaginationMeta: (state, payload) => {
      return {
        ...state,
        paginationMeta: payload,
      };
    },
    setFilterPaginationMeta: (state, payload) => {
      return {
        ...state,
        filterPaginationMeta: payload,
      };
    },
    setErr: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },
    setNextPage: (state, payload) => {
      return {
        ...state,
        currentPage: payload,
      };
    },
    setFilterNextPage: (state, payload) => {
      return {
        ...state,
        filterCurrentPage: payload,
      };
    },
    setFilterQueryString: (state, payload) => {
      return {
        ...state,
        filterQueryString: payload,
      };
    },
  
  },
  effects: (dispatch) => ({
    async requestTagsList() {
      try {
        const response = await axiosGet(TAGS_LIST_URL);
        dispatch?.home?.setTagsLoading(true);
        const { data, status } = response;
        if (response === undefined) {
          return dispatch?.home?.setTagsLoading(true);
        }
        if (STATUS.includes(status)) {
          dispatch?.home?.setTagsLoading(false);
          dispatch.home.setTagsList(data);
        } else {
          dispatch?.home?.setTagsLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async requestBanner(largeScreen) {
      try {
        const response = await axiosGet(BANNER_URL);
        dispatch?.home?.setBannerLoading(true);
        const { data, status } = response;
        if (response === undefined) {
          return dispatch?.home?.setBannerLoading(true);
        }
        if (STATUS.includes(status)) {
          const filteredBannerData = data?.filter((item) => {
            return largeScreen ? item.large_screen : !item.large_screen;
          });
          dispatch?.home?.setBannerLoading(false);
          dispatch.home.setBanner(filteredBannerData);
        } else {
          dispatch?.home?.setBannerLoading(false);
        }
      } catch (err) {
        console.log(err, "err banner");
      }
    },

    async requestWatchNow(body, state) {
      /**Common pagination code starts */
      const paginationData = state?.home?.paginationMeta;
      const filterPaginationData = state?.home?.filterPaginationMeta;
      //removed pagination
      if (
        (Object.keys(paginationData).length === 0 ||
          paginationData?.pagination?.pageCount > state?.home?.currentPage) &&
        Object.keys(filterPaginationData).length === 0
      ) {
        try {
          // const appConfig = state?.commonParams?.app_breakpoint_configs;
          // const wathcNowPerPage = appConfig?.WATCH_NOW_SIZE_PER_ROW * 2;
          dispatch?.home?.setWatchNowLoading(true);
          const nextPage = state?.home?.currentPage + 1;
          const response = await axiosGet(
            `${WATCH_NOW_URL}?pagination[pageSize]=100&pagination[page]=1`
          );
          /**Common pagination code ends */
          const { data, status } = response;
          if (response === undefined) {
            return dispatch?.home?.setWatchNowLoading(true);
          }
          if (STATUS.includes(status)) {
            dispatch?.home?.setPaginationMeta(data?.meta);
            const newArray = await [
              ...state?.home?.watchNowList,
              ...data?.results,
            ];
            const result = uniq(newArray);
            dispatch?.home?.setErr({ watchNowErr: false });
            // dispatch?.home?.setWatchNowList(data?.results)
            // if(data?.results?.length ===0){
            //   dispatch?.home?.setWatchNowLoading(false)
            // }
            dispatch?.home?.setNextPage(nextPage);
            dispatch?.home?.setWatchNowList(result);
            dispatch?.home?.setWatchNowLoading(false);
          } else {
          }
        } catch (err) {
          dispatch?.home?.setWatchNowLoading(false);
          dispatch?.home?.setErr({ watchNowErr: true });
        }
      }
    },

    async requestContinueWatching() {
      try {
        const response = await axiosGet(CONTINUE_WATCHING_URL_NEW);
        const { data, status } = response;
        if (STATUS.includes(status)) {
          dispatch?.home?.setContinueWatchingList(data?.result);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async updateWatchNow(data, state) {
      const { courseId, courseObject } = data;
      const courseIndex = state?.home?.watchNowList?.findIndex((_item) => {
        return _item?.courseId === courseId;
      });
      let updatedCourseList = [...state?.home?.watchNowList];
      updatedCourseList[courseIndex] = courseObject;
      dispatch.home.setWatchNowList(updatedCourseList, false);
    },
    //Applying Filter on watch now API
    async requestApplyFilterOnWatchNow(queryString, state, cb) {
      if (queryString && queryString.length) {
        dispatch?.home?.setFilterNextPage(0);
        dispatch?.home?.setFilterPaginationMeta({});
        dispatch?.home?.setFilterQueryString(queryString);
      }
      const paginationData = state?.home?.filterPaginationMeta;
      if (
        queryString ||
        Object.keys(paginationData).length === 0 ||
        paginationData?.pagination?.pageCount > paginationData?.pagination?.page
      ) {
        queryString = queryString
          ? queryString
          : state?.home?.filterQueryString;
        try {
          const appConfig = state?.commonParams?.app_breakpoint_configs;
          const filterDataPerPage = appConfig?.WATCH_NOW_SIZE_PER_ROW * 2;
          dispatch?.home?.setWatchNowLoading(true);
          const filterCurrentPage = state?.home?.filterCurrentPage;
          const response = await axiosGet(
            `${WATCH_NOW_URL}?${queryString}&pagination[pageSize]=100&pagination[page]=1`
          );
          const { data, status } = response;
          if (STATUS.includes(status)) {
            if (filterCurrentPage === 0) {
              const originalList = state?.home?.watchNowList;
              dispatch.home.setOriginalWatchNowList(originalList);
              dispatch.home.setWatchNowList(data?.results);
            } else {
              dispatch.home.setWatchNowList([
                // ...state?.home?.watchNowList,
                ...data?.results,
              ]);
            }
            dispatch?.home?.setFilterPaginationMeta(data?.meta);
            dispatch?.home?.setFilterNextPage(filterCurrentPage + 1);
            dispatch?.home?.setWatchNowLoading(false);
            cb && cb();
          }
        } catch (err) {
          dispatch?.home?.setWatchNowLoading(false);
          console.log(err, "Err");
        }
      }
    },
    async requestClearFilterOnWatchNow(undefined, state) {
      try {
        const originalList = state?.home?.originalWatchNowList;
        dispatch.home.setWatchNowList(originalList);
        dispatch.home.setOriginalWatchNowList([]);
        dispatch?.home?.setFilterNextPage(0);
        dispatch?.home?.setFilterPaginationMeta({});
      } catch (err) {
        console.log(err, "Err");
      }
    },
    async clearWatchNowData() {
      try {
        dispatch?.home?.setWatchNowList([]);
        dispatch?.home?.setPaginationMeta({});
        dispatch?.home?.setFilterPaginationMeta({});
      } catch (error) {
        console.log(error, "Err");
      }
    },
 
  }),
};
export default model;
