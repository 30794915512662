import Modal from "components/Modal";
import { COLOR_CONSTANTS } from "constants/color.constants";
import useSocialLogin from "hooks/useSocialLogin";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Title } from "stories/Typography";

const backendUrl = process.env.REACT_APP_LIFEDEMY_URL;

const SocialLogin = () => {
  const location = useLocation();
  const params = useParams();
  const data = JSON.parse(localStorage.getItem("data"))
  window.onunload = function (e) {
    opener.CallParentfunction(data);
  };
  const GOOGLE_URL =`${backendUrl}/auth/${params?.provider}/callback${location.search}`
  const { message } = useSocialLogin(GOOGLE_URL, location.search, params.providerName)

  return (
    <Modal
      show={true}
      footer={null}
      width="100%"
      height="100%"
      zIndex={"9999"}
      closable={false}
      bodyStyle={{ height: 1000, backgroundColor: COLOR_CONSTANTS.MODAL_COLOR }}
      style={{ top: "0" }}
    >
      <div className="text-white">
        <Title className=""> {params?.provider.toUpperCase()} {message}</Title>
      </div>
    </Modal>
  );
};

export default SocialLogin;
