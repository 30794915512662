import { Link } from "react-router-dom";

const LinkTag = ({ children, route, size, onClick ,...props}) => {
  return (
    <Link
      to={route}
      className={` text-green-700  ${size}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </Link>
  );
};

export default LinkTag;
