import React from "react";
import {  Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { getOsType } from 'utils/deviceHelper';
import  useMedia  from 'hooks/deviceHelper/useMedia';

const ViewInVoice = (props) => {
  const { pdf } = props;
  const deviceType = getOsType()
  const isMobile = useMedia()
  const  applyWidth = deviceType === "MacOS" && isMobile && "overflow-x-auto"

  return (
     <div>
      <div className={`flex justify-center lg:w-full lg:px-10 ${applyWidth}`}>
        <iframe src={pdf} width={'100%'} height={900}></iframe>
      </div>
    </div>
  );
};

export default ViewInVoice;
