import { LDAnalytics } from "utils/analyticsHelper.js";

class SidebarEvents extends LDAnalytics {
  event_Sidebar_Menu_Item_Click(){
    return {
      event :{
        module_name :"Sidebar",
        feature_name: "Sidebar Menu",
        event_name: "Sidebar_Menu_Item_Click",
        event_label: "Sidebar",
      }
    };
  }
}

const sidebarEvents = new SidebarEvents();
export default sidebarEvents;
