export const SIGNUP_LOGIN_OPTION_CLICK = "Signup_Login_Option_Click";
export const SIGNUP_EMAIL_INPUT_CLICK = "Signup_Email_Input_Click";
export const SIGNUP_CANCEL_CLICK = "Signup_Cancel_Click";
export const SIGNUP_EMAIL_SIGNIN_BTN_CLICK = "Signup_Email_Signin_Btn_Click";
export const SIGNUP_EMAIL_RESET_PWD_CLICK = "Signup_Email_Reset_Pwd_Click";
export const SIGNUP_CREATEACCOUNT_NAME_INPUT_CLICK ="Signup_CreateAccount_Name_Input_Click";
export const SIGNUP_CREATEACCOUNT_DOB_INPUT_CLICK ="Signup_CreateAccount_Dob_Input_Click";
export const SIGNUP_SAVE_BTN_CLICK =  "Signup_Save_Btn_Click";
export const SIGNUP_COUNTRY_CODE_SELECT= "Signup_Country_Code_Select";
export const SIGNUP_WITH_PHONE_INPUT_CLICK = "Signup_With_Phone_Input_Click";
export const SIGNUP_WITH_PHONE_GENERATE_BTN_CLICK ="Signup_With_Phone_Generate_Btn_Click";
export const SIGNUP_WITH_PHONE_OTP_INPUT_CLICK ="Signup_With_Phone_Otp_Input_Click";
export const SIGNUP_WITH_PHONE_SAVE_BTN_CLICK ="Signup_With_Phone_Save_Btn_Click";
export const SIGNUP_WITH_PHONE_RESEND_OTP_CLICK= "Signup_With_Phone_Resend_Otp_Click";

export const FORGOT_PWD_INPUT_CLICK = "Forgot_Pwd_Input_Click";
export const FORGOT_PWD_SAVE_BTN_CLICK="Forgot_Pwd_Save_Btn_Click";
export const RESET_PWD_INPUT_CLICK ="Reset_Pwd_Input_Click";
export const RESET_PWD_SAVE_BTN_CLICK ="Reset_Pwd_Save_Btn_Click"


export const USER_SIGNUP_SUCCESS = "User_Signup_Success"
export const SIGNUP_SCREEN_LOADED  = "Signup_Screen_Loaded"