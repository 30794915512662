import { LDAnalytics } from "utils/analyticsHelper.js";

class PaymentEvents extends LDAnalytics {
  event_Initiate_Purchase_Click() {
    return {
      event: {
        module_name: "Payment",
        feature_name: "Initiate Purchase",
        event_name: "Initiate_Purchase_Click",
        event_label: "",
      },
    };
  }
  event_Payment_Load_Start() {
    return {
      event: {
        module_name: "Payment",
        feature_name: "Purchase Course",
        event_name: "Payment_Load_Start",
        event_label: "",
      },
    };
  }
  event_Payment_Load_End() {
    return {
      event: {
        module_name: "Payment",
        feature_name: "Purchase Course",
        event_name: "Payment_Load_End",
        event_label: "",
      },
    };
  }
  event_Payment_Coupon_Code_Input_Click() {
    return {
      event: {
        module_name: "Payment",
        feature_name: "Purchase Course",
        event_name: "Payment_Coupon_Code_Input_Click",
        event_label: "",
      },
    };
  }
  event_Payment_Coupon_Code_Apply_Btn_Click() {
    return {
      event: {
        module_name: "Payment",
        feature_name: "Purchase Course",
        event_name: "Payment_Coupon_Code_Apply_Btn_Click",
        event_label: "",
      },
    };
  }
  event_Payment_Use_Coupon_Code_Click(){
    return {
      event:{
        module_name: "Payment",
        feature_name: "Purchase Course",
        event_name:"Payment_Use_Coupon_Code_Click",
        event_label:""
      }
    }
  }
  event_Payment_Continue_Purchase_Btn_Click(){
    return{
      event:{
        module_name:"Payment",
        feature_name:"Purchase Course",
        event_name:"Payment_Continue_Purchase_Btn_Click",
        event_label:""
      }
    }
  }
  event_Payment_Coupon_Code_Modal_Cancel_Click(){
    return {
      event:{
        module_name:"Payment",
        feature_name:"Purchase Course",
        event_name:"Payment_Coupon_Code_Modal_Cancel_Click",
        event_label:""
      }
    }
  }
  event_Payment_Coupon_Code_Modal_Apply_Click(){
    return {
      event:{
        module_name:"Payment",
        feature_name:"Purchase Course",
        event_name:"Payment_Coupon_Code_Modal_Apply_Click",
        event_label:""
      }
    }
  }
  event_Payment_Status_Load(){
    return {
      event:{
        module_name:"Payment",
        feature_name:"Purchase Course",
        event_name:"Payment_Status_Load",
        event_label:""
      }
    }
  }
  event_Payment_Go_To_Course_Btn_Click(){
    return {
      event:{
        module_name:"Payment",
        feature_name:"Purchase Course",
        event_name:"Payment_Status_Load",
        event_label:""
      }
    }
  }
  event_Payment_Status_Try_Again_Btn_Click(){
    return {
      event:{
        module_name:"Payment",
        feature_name:"Purchase Course",
        event_name:"Payment_Status_Load",
        event_label:"" 
      }
    }
  } 
  event_Payment_Update_Email_Success(){
    return {
      event:{
        module_name:"Payment",
        feature_name:"Purchase Course",
        event_name:"Payment_Update_Email_Success",
        event_label:"" 
      }
    }
  }
  event_Initiate_Guest_Payment(){
    return {
      event:{
        module_name :"Payment",
        feature_name:"Purchase Course",
        event_name:"Initiate_Guest_Payment",
        event_label: ""
      }
    }
  } 
  event_Guest_Payment_Page_Loaded(){
    return {
      event:{
        module_name:"Guest Payment",
        feature_name:"Purchase Course",
        event_name:"Guest_Payment_Page_Loaded",
        event_label:""
      }
    }
  }
  event_Continue_Guest_Purchase_Success(){
    return {
      event:{
        module_name:"Guest Payment",
        feature_name:"Purchase Course",
        event_name:"Continue_Guest_Purchase_Success",
        event_label:""
      }
    }
  }
  event_Continue_Guest_Purchase_Error(){
    return {
      event :{
        module_name:"Guest Payment",
        feature_name:"Purchase Course",
        event_name:"Continue_Guest_Purchase_Error",
        event_label:""
      }
    }
  }
  event_Guest_Payment_Cancelled(){
    return {
      event:{
        module_name:"Guest Payment",
        feature_name:"Purchase Course",
        event_name:"Guest_Payment_Cancelled",
        event_label:""
      }
    }
  }
  event_Guest_Payment_Success(){
    return {
      event: {
        module_name:"Guest Payment",
        feature_name:"Purchase Course",
        event_name: "Guest_Payment_Success",
        event_label:""
      }
    }
  }
  event_Guest_Payment_Failed(){
    return {
      event: {
        module_name:"Guest Payment",
        feature_name:"Purchase Course",
        event_name: "Guest_Payment_Failed",
        event_label: ""
      }
    }
  }
  event_Payment_Success(){
    return {
      event :{
        module_name: "Payment",
        feature_name:"Purchase Course",
        event_name: "Payment_Success",
        event_label: ""
      }
    }
  }
  event_Payment_Failed(){
    return {
      event: {
        module_name: "Payment",
        feature_name:"Purchase Course",
        event_name: "Payment_Failed",
        event_label: ""
      }
    }
  }
  event_Purchase(){
    return {
      event:{
        module_name: "Payment",
        feature_name:"Purchase",
        event_name: "purchase",
        event_label: "Purchase Conversion"
      }
    }
  }
}

const paymentEvents = new PaymentEvents();
export default paymentEvents;
