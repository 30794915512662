import { APP_COOKIES } from 'constants/app.constants';
import useAuthenticatedEffect from 'hooks/useAuthenticatedEffect';
import { Fragment, useState } from 'react';
import Joyride from 'react-joyride';
import FormButton from 'stories/FormButton';
import { getCookie, setCookie } from 'utils/storage.helper';

const APP_TOUR_EXPIRY = 40000 //DAYS

const MOBILE_TOUR_STEPS = [
    {
        target: '.childmode-mobile-tour-cls',
        content: 'Switch ON the Child Mode to hide the restricted courses for the children',
        disableBeacon: true
    }
];

const WEB_TOUR_STEPS = [
    {
        target: '.childmode-web-tour-cls',
        content: 'Switch ON the Child Mode to hide the restricted courses for the children',
        disableBeacon: true
    }
];

const Tour = () => {
    const [tourAllowed, setTourAllowed] = useState(false);

    useAuthenticatedEffect(() => {
        const tourCompleted = getCookie(APP_COOKIES.TOUR_COOKIE);
        if(!tourCompleted){
            setTourAllowed(true);
        }
    }, [])

    const finishTour = () => {
        setTourAllowed(false);
        setCookie(APP_COOKIES.TOUR_COOKIE,true,APP_TOUR_EXPIRY);
    }


    const TourStepMessages = ({ tooltipProps, closeProps }) => {
        return (
            <div className='bg-white py-3 rounded-md max-w-[18rem] text-center' {...tooltipProps}>
                <div>
                    Switch ON the Child Mode to hide the restricted courses for the children
                </div>
                <div className='pt-2'>
                    <FormButton className='rounded' {...closeProps} onClick={finishTour}>Got it</FormButton>
                </div>
            </div>
        )
    }

    if (!tourAllowed) {
        return '';
    }

    return (
        <Fragment>
            {/* Mobile Tour starts */}
            <Joyride
                steps={MOBILE_TOUR_STEPS}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                hideCloseButton={true}
                tooltipComponent={TourStepMessages}

            />
            {/* Mobile Tour ends */}

            {/* Web Tour starts */}
            <Joyride
                steps={WEB_TOUR_STEPS}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                hideCloseButton={true}
                tooltipComponent={TourStepMessages}

            />
            {/* Web Tour ends */}
        </Fragment>
    )
}

export default Tour;