import { APP_DEFAULT_LOCALE } from "constants/app.constants";
import { getUserDetails, getUserPorfile, getUserProfile } from "selectors";

export class LDAnalytics {
  getEventByCode(code) {
    let event = {};
    try {
      event = this[`event_${code?.replace(/-/g, "_")}`]();
    } catch (error) {
      console.warn(`event_${code?.replace(/-/g, "_")}`, "is not a function");
    }
    if (Object.keys(event)?.length <= 0) {
      return null;
    }
    return event;
  }

  buildCommonParamsObject(){ 
    const { location,os,device_type,browser} = getUserDetails() 
    const { id, dateOfBirth, email, mobileNumber } =  getUserProfile()
    let commonParamsOj = {
      locale: APP_DEFAULT_LOCALE || "en",
      source: "web",
      app_version: "1.0",
      timestamp: new Date().getTime(),
      location_lat: location?.lat || "",
      location_long: location?.long || "",
      os: os || "",
      user_id: id || "",
      browser: browser || "",
      device_type:device_type || "",
      dob:dateOfBirth || "NA",
      email: email || "NA",
      phone : mobileNumber || "NA"
    };
    return commonParamsOj;
  }

  buildBaseParamsObject(event_data) {
    const { event: { module_name, feature_name, event_name, event_label } } = event_data;
    let baseObj = {
      event: event_name || "",
      event_name: event_name || "",
      event_label: event_label,
      module_name: module_name || "",
      feature_name: feature_name || ""
    };
    return baseObj;
  }

  handle_analytics_event(event_code, params = {}) {
    const event_data = this.getEventByCode(event_code) || {};
    let commonParams = this.buildCommonParamsObject() || {};
    let extraParams = this.buildBaseParamsObject(event_data) || {};
    let event_obj = { ...commonParams, ...extraParams, ...params};
    window.eventDataLayer.push(event_obj);
  }
}
