import React, { useEffect, useState } from "react";
import { Form, notification } from "antd";

import { phoneCodes } from "constants/app.constants";

import { LDInput, LDSelect } from "stories/Form";
import { LDSmall, LDSpan, Title } from "stories/Typography";
import FormButton from "stories/FormButton";
import Otp from "./Otp";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { imagePath } from "utils/assetHelper";
import { signupCountryCodeSelectEvent, signupCancelClickEvent, signupWithPhoneInputClickEvent, signupWithPhoneGenerateBtnClickEvent } from "analytics/signupLogin";
import "./index.css"
import { COLOR_CONSTANTS } from "constants/color.constants";
import { countryCodeMatcher, IsPhoneNumberValid, MobileCheckRegEx } from "utils";
import LDImage from "stories/Image";
import { useSelector } from "react-redux";
import CustomSelector from "components/CustomSelector";
import { setItemToStorage } from "utils/storage.helper";

const Phone = ({ setAuthType }) => {
  const countryCode = useSelector((state)=> state?.commonParams?.countryCode)
  const location = useLocation();

  const { phoneNumber, _qPhoneNumber } = location.state || {}
  const [toggle, setToggle] = useState(false);
  const [code,setCode]=useState({code:"",countryInitials:""})
  const [phone,setPhone] = useState(phoneNumber ? phoneNumber : _qPhoneNumber ? _qPhoneNumber : "")
  const dispatch = useDispatch()
  const [errMsg,setErrMsg] =useState("")
  const isMobileValid = countryCodeMatcher(code?.code, phone)
  useEffect(()=>{
    dispatch?.commonParams?.getCountryCodeSelect()
  },[])
  const ipInfo = useSelector((state)=> state?.commonParams?.ipInfo)
  // START ****  Auto select Country depending on the IP Address 
  useEffect(()=>{
    if(countryCode?.length > 0 && ipInfo?.country){
      const result = countryCode?.find((items)=>{
        return items?.code === ipInfo?.country
      })
      setCode({
        code : result?.value || "+91",
        countryInitials:result?.code || "IN"
      })
    }
  },[ipInfo?.country, countryCode?.length])

//  END **** 

  const onFormSubmit = (values) => {
  };
  const handleChange=(val)=>{
    signupCountryCodeSelectEvent({event_label:val?.value})
    setCode({code: val?.value, countryInitials:val.code})
  }
  const success = (key,param) => {
    notification[key]({
      message: param,
    });
  };

  const handleGenerateOtp = () => {
    signupWithPhoneGenerateBtnClickEvent({ event_label: phone });
    if (phone?.length !== 0) {
      const validatePhoneNumber = `${code?.code}${phone}`;
      // const result = IsPhoneNumberValid(validatePhoneNumber)
      if (isMobileValid) {
        // let uri = `${code?.code}${phone}`;
        // let encoded = encodeURIComponent(uri);
        const payload ={
          mobileNumber : validatePhoneNumber,
          country: code?.countryInitials
        }
        dispatch?.registerWithPhone?.requestPhoneSignUp(payload, success);
        setToggle(true);
      }
      else {
        setErrMsg("Enter a valid phone number");
      }
    } else {
      setErrMsg("Enter a phone number");
    }
    setTimeout(() => {
      setErrMsg("");
    }, 3000);
  };

  return (
    <div className="p-4">
      {/* <LDSmall
       className ='flex justify-end' 
      >
        <span className="cursor-pointer underline" onClick={() =>{
          signupCancelClickEvent()
          setAuthType("")
        }}>
        <img src={imagePath("/cross.svg")} alt="crossImg" />
        </span>
      </LDSmall> */}
      {!toggle ? (
        <>
        <div className="flex flex-col text-center pb-20">
          <div className="justify-center items-center flex pt-12">
            <LDImage src={imagePath("/logoLarge.svg")} alt="logo" className=""/>
          </div>
          <Title className="auth-modal-heading-style pt-10">Welcome!</Title>
          {/* <LDSmall className="auth-small-text-style-grey mt-2">
            Log in using your phone number
          </LDSmall> */}
          <Form className="mt-6" onFinish={(values) => onFormSubmit(values)}>
            <div className="flex justify-center">
            {/* <Form.Item name="countryCode" noStyle> */}
               <CustomSelector
                  showArrow={true}
                  items={countryCode}
                  value={code}
                  handleChange={handleChange}
                  className="rounded-lg bg-logoColor border-2 outline-none  auth-input-text-style border-gray-600"
              />
              
              {/* <LDSelect items={countryCode} showArrow={true} disabled={false} value={code} handleChange={handleChange} className="p-2 outline-none bg-secondary rounded-lg auth-input-text-style w-20" /> */}
            {/* </Form.Item> */}
              <LDInput
                name="phone"
                type="text"
                value={phone}
                maxlength="15"
                inputMode="numeric"
                onClick={()=>signupWithPhoneInputClickEvent()}
                placeholder="Phone"
                pattern="[0-9]*"
                onChange={(e)=>setPhone((v) => (e.target.validity.valid ? e.target.value : v))}
                className="px-4 py-2 w-9/12 lg:w-[57%] ml-2 rounded-lg bg-logoColor border-2 outline-none  auth-input-text-style border-gray-600"                
              />
            </div>
             <div className="text-left ml-16 ">
                 <span className=" text-remove text-sm mt-2 pb-0">{errMsg && errMsg  }</span>
            </div>
            <FormButton
              type="submit"
              className="bg-greeny auth-button-text-style w-11/12 lg:w-[18rem] px-3 mt-4 rounded mx-auto"
              style={{backgroundColor:COLOR_CONSTANTS.GREEN,color:"white"}}
              onClick={handleGenerateOtp}
              // disabled={!MobileCheckRegEx(phone)}
            >
              Generate OTP
            </FormButton>
          </Form>
        </div>
        </>

      ) : (
        <Otp phone={code?.code+phone} toggleBack={setToggle} />
      )}
    </div>
  );
};

export default Phone;
