import { Drawer } from "antd";

import SidebarMenuItem from "./SidebarMenuItem";
import HiddenMenuItem from "./HiddenMenuItem";
import HorizontalLine from "stories/HorizontalLine";
import LinkTag from "stories/LinkTag";
import LDImage from "stories/Image";

import { hiddenRoutes, sidebarRoutes, mobileRoutes } from "../routes.constants";
import { imagePath } from 'utils/assetHelper';
import useMedia from "hooks/deviceHelper/useMedia";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "../index.css"
import "./index.css"
import { getAccountDetails } from "selectors";
import { ROUTE_CONSTANTS } from "constants/routes.constants";

const SideBar = (props) => {
  const {
    sidebarActive,
    mobileSidebar,
    toggleSidebar,
    hideSidebar,
    toggleHideSidebar,
  } = props;
  const dispatch = useDispatch()
  const history = useHistory()
  const onDrawerClose = () => {
    if (hideSidebar) {
      toggleHideSidebar();
    } else {
      toggleSidebar();
    }
  };
  const isMobile = useMedia("(max-width: 1158px)");
  const { jwt } = getAccountDetails();

  return (
    <>
      {/* Mobile Sidebar */}
      <Drawer
        title={""}
        placement="left"
        visible={mobileSidebar}
        onClose={onDrawerClose}
        closable={false}
        width={isMobile ? "250px":"250px"}
      >
        <div className="bg-secondary w-full h-full pt-4">
          <div className="items-center flex">
            <LDImage
              src={imagePath("/Hamburger.svg")}
              alt="hamburger"
              onClick={onDrawerClose}
              className="cursor-pointer  pl-8"
            />
            <LinkTag className="cursor-pointer pl-3" to={ROUTE_CONSTANTS?.HOME}>
              <LDImage src={imagePath("/Logo.svg")} alt="logo" />
            </LinkTag>
          </div>

          <div className="pt-6 pl-1">
            {mobileRoutes.map((_route,idx) => {
              const { id, src, filledSrc, title, to, navName, onClick = () => { }, ignoreHighlight=false } = _route;
              const mobileStyle = isMobile && "sidebar-mobile-style"
              const toShow = title === "Log Out" && !jwt ? "" : title 
              const toShowSrc =  title === "Log Out" && !jwt ? " " : src  
              return (
                <>
                  <SidebarMenuItem
                    key={id}
                    src={toShowSrc}
                    index={idx}
                    filledSrc={filledSrc}
                    onClick={ () => { onClick(dispatch); onDrawerClose() }}
                    title={toShow}
                    route={to}
                    navName={navName}
                    sidebarActive={true}
                    mobileStyle={ isMobile && "sidebar-mobile-style-selected"}
                    mobileStyleGreen={mobileStyle}
                    ignoreHighlight={ignoreHighlight}
                  />                 
                </>
              );
            })}
          </div>
        </div>

      </Drawer>
      {/* End Mobile Sidebar */}

      {hideSidebar ? (
        ""
      ) : (
        <div
          className={`hidden lg:block bg-secondary h-[100vh] overflow-y-scroll lg:mb-[10rem] pt-5 ${sidebarActive ? "pr-2 lg:pr-[2.7rem] large-screen-sidebar  px-1" : "px-2"
            } fixed z-10`}
        >
          {sidebarRoutes.map((_route) => {
            const { id, src, title, filledSrc, to, navName } = _route;
            return (
              <SidebarMenuItem
                key={id}
                src={src}
                filledSrc={filledSrc}
                title={title}
                route={to}
                navName={navName}
                sidebarActive={sidebarActive}
              />
            );
          })}

          <div className={`hidden lg:block position  absolute w-[98%] lg:pt-2`}>
            {sidebarActive && (
              <HorizontalLine className="bg-white opacity-20" />
            )}
          </div>
          {hiddenRoutes.map((_routes) => {
            const { id, src, filledSrc, title, to, ignoreHighlight=false } = _routes;
            return (
              <div className="sidebar--items">
              <HiddenMenuItem
                key={id}
                src={src}
                filledSrc={filledSrc}
                title={title}
                route={to}
                sidebarActive={sidebarActive}
                ignoreHighlight={ignoreHighlight}
              />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SideBar;
