import { axiosGet, axiosPost } from "axiosRequests";
import { REFERRAL_PAGE, REFERRAL_URL } from "constants/Apis";
import { STATUS } from "constants/status.constant";
import { Notifier } from "components/Notifier";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
const model = {
  state: {
    email:{},
    phone:{},
    referralDesc:{}
  },
  reducers: {
    setEmailResult: (state, payload) => {
      return {
        ...state,
        email: payload
      }
    },
    setPhoneResult: (state, payload) => {
      return {
          ...state,
          phone: payload,
      }
    },
    setReferralDesc:(state, payload)=>{
      return {
        ...state,
         referralDesc: payload
       }
    }
  },
  effects: (dispatch) => ({
    async requestInviteWithEmail(email,undefined,cb) { 
      try{
        const response = await axiosPost(`${REFERRAL_URL}?receipientEmail=${email}&method=EMAIL`)
        const { data, status } =response
        if(STATUS.includes(status)){
          cb(data)
          // Notifier(status, MESSAGE_CONSTANTS.REFER_FRD_MSG)
        }else{
          cb(data)
          Notifier(status, data?.statusMessage )
        }
      } 
      catch(err){
        Notifier('Error', MESSAGE_CONSTANTS.REFER_FRD_ERR_MSG)
      } 
    },
    async requestInviteWithPhone(phone,undefined,cb){    
      try{
        const response = await axiosPost(`${REFERRAL_URL}?receipientPhone=${phone}&method=SMS`)
        const {data,status} =response
        if(STATUS.includes(status)){
            // dispatch?.referral?.setPhoneResult(data)
            cb(data)
            // Notifier(status, data?.statusMessage)
        }else{
          // dispatch?.referral?.setPhoneResult(data)
          cb(data)
          Notifier(status, data?.statusMessage )
        }
      }
      catch(err){
        console.log(err,"Error in Invide with phone")
      }
    },
    async requestReferralPage(){
      try{
        const response = await axiosGet(REFERRAL_PAGE)
        const {data , status} =  response
         if(STATUS.includes(status)){
           dispatch?.referral?.setReferralDesc(data?.data)
         }
      }
      catch(err){
        console.log(err,"referal page")
      }
    }
  }),
};

export default model;
