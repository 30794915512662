import React, { useState } from 'react'
import { Checkbox as LDCheckbox } from 'antd';

const Checkbox = ({ label, handleChecked, className="" }) => {
  
    const [checked, setChecked] = useState()

  const onChange = (e) => {
    setChecked(e.target.checked)
    handleChecked(e.target.checked)
  };
  return (
    <div>
      <LDCheckbox value={checked}  onChange={onChange} className={className}>{label}</LDCheckbox>
    </div>
  )
}

export default Checkbox
