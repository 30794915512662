import { LDAnalytics } from 'utils/analyticsHelper.js';

class InstructorEvents extends LDAnalytics {
    event_Session_Payment_Start(){
        return {
            event : {
                module_name: "Instructor Details",
                feature_name: "Instructor Details",
                event_name: "Session_Payment_Start",
            },
        };
    }

    event_Session_Payment_Success(){
        return {
            event : {
                module_name: "Instructor Details",
                feature_name: "Instructor Details",
                event_name: "Session_Payment_Success",
            },
        };
    }

    event_Session_Payment_Failed(){
        return {
            event : {
                module_name: "Instructor Details",
                feature_name: "Instructor Details",
                event_name: "Session_Payment_Fail",
            },
        };
    }

    event_Session_Payment_Cancel(){
        return {
            event : {
                module_name: "Instructor Details",
                feature_name: "Instructor Details",
                event_name: "Session_Payment_Cancel",
            },
        };
    }

    event_Session_Guest_Payment_Start(){
        return {
            event : {
                module_name: "Instructor Details",
                feature_name: "Instructor Details",
                event_name: "Session_Guest_Payment_Start",
            },
        };
    }

    event_Session_Guest_Payment_Success(){
        return {
            event : {
                module_name: "Instructor Details",
                feature_name: "Instructor Details",
                event_name: "Session_Guest_Payment_Success",
            },
        };
    }

    event_Session_Guest_Payment_Failed(){
        return {
            event : {
                module_name: "Instructor Details",
                feature_name: "Instructor Details",
                event_name: "Session_Guest_Payment_Fail",
            },
        };
    }

    event_Session_Guest_Payment_Cancel(){
        return {
            event : {
                module_name: "Instructor Details",
                feature_name: "Instructor Details",
                event_name: "Session_Guest_Payment_Cancel",
            },
        };
    }

    event_Home_Book_A_Session_Click(){
        return {
            event : {
                module_name: "Home",
                feature_name: "One on One Session",
                event_name: "Home_Book_A_Session_Click",
            },
        };
    }

    event_Instructors_Book_A_Session_Click(){
        return {
            event : {
                module_name: "Instructors",
                feature_name: "One on One Session",
                event_name: "Instructors_Book_A_Session_Click",
            },
        };
    }
}

const instructorEvents = new InstructorEvents();
export default instructorEvents;