import { Button } from "antd";
import { COLOR_CONSTANTS } from "constants/color.constants";

const FormButton = (props) => {
  const { children, className, backgroundColor, color,style } = props
 
  return <Button {...props} className={`border-0 font-modellicaMedium ${className}`}
          style={{backgroundColor:backgroundColor, color:color, ...style}}>
    {children}
  </Button>
};

FormButton.defaultProps = {
  backgroundColor:COLOR_CONSTANTS.GREEN,
  color: COLOR_CONSTANTS.WHITE
}

export default FormButton;
