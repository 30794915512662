import { LDParagraph ,LDSmall} from "stories/Typography";

const Duration = (props) => {
  const { hours, minutes, seconds, className } = props;
  return (
    <LDSmall className={`text-white pb-2 text-[.7rem] ${className}`}>
      {hours && (
        <span>{hours}h </span>
      )}
      {minutes && (
        <span>{minutes}m{" "}</span>
      )}
       {seconds && (
        <span>{seconds}{" "}</span>
      )}
    </LDSmall>
  );
};

Duration.defaultProps = {
  hours: "",
  minutes: "",
};
export default Duration;
