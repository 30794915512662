import { courseAddReviewClickEvent, courseAddReviewTextareaClickEvent, courseEditReviewClickEvent, courseEditReviewTextareaClickEvent, coursePurchaseCardClickEvent, courseReviewStarRatingEvent, courseWriteAReviewEvent } from "analytics/course";
import PurchaseModal from "components/PurchaseModal";
import useMedia from "hooks/deviceHelper/useMedia";
import {  useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StarRating from "stories/StarRating";
import Textarea from "stories/Textarea";
import {  Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";

const FormComponent = (props) => {
  const { handleRatingSubmit,getReviewObject,paramId,review: eReview, ratingValue,rating:eRating, open,isPurchased,courseTitle,handleCancel, courseSlug,mrp,offer_price} = props  
  const [toggle, setToggle] = useState(open ? open : false)      
  const [review, setReview] = useState(eReview ? eReview : "");
  const [rating, setRating] = useState(eRating ? eRating : 0);
  const [show,setShow]=useState(false)
  const params = useParams()
  const handleClose=()=>{
    setShow(false)
  }
  const review_details = useSelector((state) => state?.course?.review_details);
  const isReviewedByUser = useSelector((state)=> state?.course?.isUserReviewed)
  const episodeList = useSelector((state)=> state?.course?.episode_details)
  const disabled = review.length === 0 ;
  const checkIsFree=()=>{
    const data = episodeList.find(item => item?.isFree === false) // find any course which is paid
     if(data === undefined){
      return false // course is free
     }   
     else{
      return true // course is paid
     }
  }
    useEffect(()=>{
      setRating(ratingValue)
    },[ratingValue])
  
  const getRating = (value) => {
      if(isPurchased || !checkIsFree()){ 
        if(!toggle){
          handleRatingSubmit(value)
        }
        setRating(value)
        const params = {
          event_label : value, //${courseTitle} -
          course_name : courseTitle
        }
        courseReviewStarRatingEvent(params)
      }
      else{
        setShow(true)
      }
  };
  const handleToggle = () => {
    if(isPurchased){ 
      setToggle(false);
      if(toggle == false){
        const params ={course_name :`${courseTitle}`}
        courseWriteAReviewEvent(params)  
      }
    }else{
      if(!checkIsFree()){
        setToggle(false)
      }else{
        setShow(true)
      }
    }
  };

  const handleReviewChange = (e) => {
    const attr = e.target.value;
    setReview(attr);
  };

  const handleSubmit = () => {
    getReviewObject(review,rating);
    setReview("");
    handleToggle();
    if(eReview){
      const params = {
        event_label : `${eReview} | ${review}`, //${courseTitle} - 
        course_name : courseTitle
      }
      courseEditReviewClickEvent(params)
    }
    else{
      const params = {
        event_label : ` ${review}- Rating ${rating}`, //${courseTitle} -
        course_name : courseTitle
      }
      courseAddReviewClickEvent(params)
    }
  };
  const handleTextareaClick=()=>{
    if(eReview){
      const params = {
        event_label : eReview, //${courseTitle} -
        course_name : courseTitle
      }
      courseEditReviewTextareaClickEvent(params)
    }
    else{
      const params = {course_name : `${courseTitle}`}
      courseAddReviewTextareaClickEvent(params)
    }
  }
  // const checkIsPurchased = isPurchased ? ratingValue > 0 ? ratingValue : rating : <PurchaseModal show={show} handleClose={handleClose} />
  const handleGTMEventCapture =()=>{
    const params = {course_name :courseTitle}
    coursePurchaseCardClickEvent(params)
  }
  const isMobile = useMedia()
  const sizeToApply = isMobile ? "20px":"26px"
  const ratingAndReviewAllowed = checkIsFree() && isPurchased || !checkIsFree() ;
  const userReview = review_details?.find((item) => { return item?.isEditable});
  return (
    <div className="mt-6">
      {show && !isPurchased && checkIsFree() && (
        <PurchaseModal
          show={show}
          handleClose={handleClose}
          mrp={mrp}
          courseId={paramId}
          courseSlug={courseSlug}
          title={courseTitle}
          offer_price={offer_price}
          handleGTMEventCapture={handleGTMEventCapture}
        />
      )}
      {ratingAndReviewAllowed && (
        <div className="lg:my-8 ">
          <Title className="text-white text-xl lg:text-[1.4vw] lg:mb-2 subtitle-course-page">
            Rate this course
          </Title>
          <StarRating
            isStarRatingActive={true}
            size={sizeToApply}
            marginRight="lg:mr-8  mr-5 mt-4 lg:mt-4"
            getRating={getRating}
            readOnly={false}
            quiet={false}
            className="ml-10"
            fontSize={"30px"}
            rating={rating}
            // disabled={userReview?.rating}
          />
        </div>
      )}
      <Title className="text-xl lg:mb-4 lg:mt-6 mt-4 mb-4 font-modellicaMedium lg:text-[1.4vw] sm:mt-4  subtitle-course-page ">
        Reviews
      </Title>
      {/* {((ratingAndReviewAllowed && !userReview?.reviewBody) ||
        (userReview?.reviewBody && userReview?.reviewBody.length === 0)) && (
        <Title
          onClick={handleToggle}
          className="text-white lg:text-[.85vw] lg:mt-4 lg:mb-8 sm:m-0"
        >
          Write a review
        </Title>
      )} */}
        <div className="">
            {eReview?.length >0 &&  
              <img 
                src={imagePath("/cross.svg")} alt="crossImg" 
                onClick={handleCancel}
                className="cursor-pointer absolute lg:right-[25%] right-0 lg:mt-2 mt-3 mr-3 lg:mr-2  z-50 "/>
            }
        </div>

        {((ratingAndReviewAllowed && !userReview?.reviewBody) ||
        (userReview?.reviewBody && userReview?.reviewBody.length === 0) || toggle) && <>
          <Textarea
            placeholder="Write a review"
            isButton={true}
            rows={5}
            value={review}
            onClick={handleTextareaClick}
            handleChange={handleReviewChange}
            className={`${
              review?.length === 0 ? "italic" : "regular"
            } h-24 w-full relative  outline-none placeholder-opacity-25 placeholder-italic  bg-secondary border-2 border-gray-600 rounded-lg text-white p-3`}
          ></Textarea>
          <div className="text-right m-2">
            <button
              disabled={disabled}
              onClick={handleSubmit}
              className={`${
                disabled ? "bg-greeny opacity-50" : "bg-greeny"
              } rounded-md text-white p-2 font-modellicaMedium`}
            >
              {eReview ? "Edit review" : "Submit review"}
            </button>
          </div>
        </>
        }
    </div>
  );
};

export default FormComponent;
