import { imagePath } from "utils/assetHelper";
import Image from "components/Image";
import { Subtitle, Title } from "stories/Typography";
import FormButton from "stories/FormButton";
import { useHistory } from "react-router-dom";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { ROUTE_CONSTANTS } from "constants/routes.constants";

const Error = ({ errType = 500, msg, errImgStyle = {}, btnLabel, action }) => {
    const history = useHistory();
  const baseBtnAction = () => {
    location?.reload();
  };
  const errorMap = {
    500: {
      errImg: "/Error/error_500.svg",
      errMsg: "Oops! Something went wrong.",
      btnText: "Try Again",
      btnAction: baseBtnAction,
    },
    404: {
        errImg: "/Error/error_404.svg",
        errMsg: "Page not found.",
        btnText: "Home",
        btnAction: ()=>{
            history.push(ROUTE_CONSTANTS?.HOME);
        },
      },
  };
  const err = errorMap[errType] || errorMap["500"];
  const { errImg, errMsg, btnText, btnAction } = err;
  return (
    <div className="text-center">
      <Image className="h-32 mx-auto" style={errImgStyle} src={imagePath(errImg)} icon={true} />
      <Title className="text-4xl mt-3.5 mx-auto">{msg || errMsg}</Title>
      <FormButton style={{backgroundColor:COLOR_CONSTANTS.GREEN,color:"white"}} onClick={action || btnAction} className="px-12 mt-6">
        {btnLabel || btnText}
      </FormButton>
    </div>
  );
};

export default Error;
