import { Modal } from 'antd';
import { ABOUT_YOURSELF_ON_BOOKING } from 'constants/app.constants';
import { COLOR_CONSTANTS } from 'constants/color.constants';
import { ROUTE_CONSTANTS } from 'constants/routes.constants';
import React from 'react'
import { useHistory } from 'react-router-dom';
import FormButton from 'stories/FormButton';
import { LDParagraph } from 'stories/Typography';

const AboutYouModal = ( props ) => {
    const { handleClose, show, setExternalFormNotified, meeting_ID } = props;
    const history = useHistory()

    const handleNavigation = ()=>{
        history?.push(`${ROUTE_CONSTANTS?.BOOKING_CONFIRMED}/${meeting_ID}?_n=${true}`)
        window.open(ABOUT_YOURSELF_ON_BOOKING)
        setExternalFormNotified(false)
    }
  return (
    <Modal
        className=""
        visible={show}
        closable={false}
        onCancel={handleClose}
        centered
        width={400}
        footer={null}
        bodyStyle={{
          backgroundColor: COLOR_CONSTANTS?.SIDEBAR_SELECTED,
          borderRadius: "10px",
        }}
        maskStyle={{
          backdropFilter: "blur(3px)",
          "-webkit-backdrop-filter": "blur(3px)",
        }}
        maskClosable={false}
      >
        <div className='flex flex-col justify-center items-center gap-4'>
            <LDParagraph className='text-center !text-[18px]'>Help us to know you a little better !</LDParagraph>
            <FormButton onClick={handleNavigation}>Click to start</FormButton>
        </div>
      </Modal>
  )
}

export default AboutYouModal
