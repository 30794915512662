import { Fragment } from "react";
import BannerLoader from "stories/SkeletonLoader/BannerLoader";
import CardLoader from "stories/SkeletonLoader/CardLoader";

const MultiLoadingBanner = ({count}) => {
  return <Fragment>{[...Array(count)]?.map((item, key) => {
      return <BannerLoader key={key} />
  })}</Fragment>;
};

MultiLoadingBanner.defaultProps = {
    count: 1
}

export default MultiLoadingBanner;
