import MultiLoadingCards from "components/MultiLoadingCards";
import { useState } from "react";
import Button from "stories/Button";
import FormInput from "stories/FormInput";
import { Heading, LDParagraph, LDSpan } from "stories/Typography";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import OffersAndDiscountMarkdown from './OffersAndDiscountMarkdown';
import Modal from "components/Modal";
import "./index.css"
import Image from "components/Image";
import { imagePath } from "utils/assetHelper";
const CouponList = (props) => {
  const {
    _list,
    isApply,
    handleApply,
    handleClose,
    handleCopyClick,
    selected,
    cls,
    loading,
  } = props;

  const [show, setShow] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [offerId,setOfferId] = useState("")
  const [ showOfferToggle, setShowOfferToggle] =useState(false)

  const handleToggle = (val) => {
    (!show || selectedCoupon == val) && setShow(!show);
    setSelectedCoupon(val);
  };
  const handleSelectId=(couponId)=>{
    setShowOfferToggle(true)
    setOfferId(couponId)
  }
  const handleOfferClose=()=>{
    setShowOfferToggle(false)
    setOfferId(" ")
  }

  return (
    <div
      className={`py-5 px-2 lg:px-6 xl:px-10  ${
        isApply ? "hide-scrollbar overflow-auto" : cls
      }`}
    >
      {isApply && (
        <LDParagraph
          className="cursor-pointer"
          onClick={handleClose}
          style={{display:'flex' ,justifyContent:"right" }}
        >
         <Image icon={true} src={imagePath("/cross.svg")} alt="crossImg" />
        </LDParagraph>
      )}
      {_list?.length === 0  &&
        <Title className="text-center">No coupons available </Title>
      }
      {loading ? <MultiLoadingCards count={4} /> : ""}

      {_list?.map((_list, i) => {
        const {
          couponCode,
          couponId,
          couponValue,
          couponHeader,
          couponDescription,
          isCouponValid,
          startDate,
          endDate,
          minPurchaseValue,
          termsAndConditions,
        } = _list;
        const _tcVisible = termsAndConditions ? "visible" : "invisible";
        return (
          <div className="my-3" key={i}>
            <div className={` bg-ternary rounded-md`} key={i}>
              {isApply ? (
                <div className="flex justify-between p-4">
                  <div>
                    <LDSpan className="text-white offers-border-style  px-4 py-2  rounded">
                      {couponCode}
                    </LDSpan>
                    <LDParagraph className=" offers-coupon-heading flex mt-6">
                      {couponHeader}
                    </LDParagraph>
                    <div className="offers-coupon-desc text-md my-2">
                      <ReactMarkdown>{couponDescription}</ReactMarkdown>
                    </div>
                    <LDParagraph
                      className=" offers-terms-condtions cursor-pointer"
                      onClick={() => handleToggle(i)}
                    >
                      Terms & Conditions apply{" "}
                    </LDParagraph>
                    {show && selectedCoupon === i && (
                      <ReactMarkdown
                        components={{
                          h3: ({ node, ...props }) => <h3 {...props} />,
                          h5: ({ node, ...props }) => <h3 {...props} />,
                          ul: ({ node, ...props }) => (
                            <ul className="ml-6 mb-3">
                              <li
                                className="text-grayColor list-disc"
                                {...props}
                              ></li>
                            </ul>
                          ),
                          p: ({ node, ...props }) => (
                            <p
                              className="text-grayColor"
                              {...props}
                            />
                          ),
                          a: ({ node, ...props }) => <a {...props} />,
                          b: ({ node, ...props }) => <b {...props} />,
                        }}
                        children={termsAndConditions}
                        remarkPlugins={[remarkGfm]}
                      />
                    )}

                    {/* {show && selectedCoupon === i && (
                      <ul className="ml-6">
                        {termAndConditionList?.map((_terms, i) => {
                          const { title } = _terms;
                          return (
                            <li key={i} className="text-grayColor list-disc">
                              {title}
                            </li>
                          );
                        })}
                      </ul>
                    )} */}
                  </div>
                  <LDParagraph
                    className="text-greeny text-lg cursor-pointer"
                    onClick={() => handleApply(couponCode)}
                  >
                    Apply
                  </LDParagraph>
                </div>
              ) : (
                <>
                  <div className="pt-5 px-3">
                    <FormInput
                      value={couponCode}
                      disabled
                      className="bg-ternary offers-coupon-style offers-border-style px-4 text-center py-2  w-full rounded"
                    />
                     <div className="h-[5rem]">
                    <div className=" text-white text-lg flex mt-4 mb-2">
                      <ReactMarkdown className="break-words line-clamp offers-coupon-heading">{couponHeader}</ReactMarkdown>
                    </div>
                    <div className="text-grayColor text-md ">
                      <ReactMarkdown className="break-words line-clamp offers-coupon-desc">{couponDescription}</ReactMarkdown>
                    </div>
                   </div>
                      {couponId === offerId &&  
                      <Modal
                       show={showOfferToggle}
                        closable={true}
                        onCancel={handleOfferClose}
                        handleClose={true} 
                        bodyStyle={{
                          padding: "0px",
                        }}
                        maskStyle={{
                          backdropFilter: "blur(3px)",
                          "-webkit-backdrop-filter": "blur(3px)"
                        }}
                       footer={null}
                    >
                          <OffersAndDiscountMarkdown
                            termsAndConditions={termsAndConditions}
                          />
                      </Modal>
                   }                      
                  </div>
                  <LDParagraph className={`offers-terms-condtions cursor-pointer p-[1rem] pb-[2rem] ${_tcVisible}`} onClick={()=>handleSelectId( couponId)} >
                      Terms & Conditions 
                    </LDParagraph>
                    
                  <Button
                    type="primary"
                    className={`p-2 w-full text-xl bg-secondary rounded-b-[5px]`}
                    label={"Copy"}
                    btnCls="offers-copy-coupon"
                    onClick={() => handleCopyClick(couponCode, couponId)}
                  />
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

CouponList.defaultProps = {
  termAndConditionList: [],
  _list: [],
  isCopy: false,
  isApply: false,
  handleClose: () => {},
};

export default CouponList;
