import useMedia from 'hooks/deviceHelper/useMedia';
import { LDParagraph } from 'stories/Typography';
import "./index.css"
const NewTags = (props) => {
  const isMobile = useMedia()
  const { options ,handleOptionSelect, selected } = props

  const mobileCls = isMobile ? "mr-3" : ""
  const commonStyle =
    "decoration-none text-white border border-white xs:rounded md:rounded rounded-md lg:mr-3 md:mr-3 whitespace-nowrap px-[4px] hover:text-black cursor-pointer  lg:px-[.8rem] py-1";

  return (
    <div className='flex overflow-auto whitespace-nowrap no-scrollbar'> {/* not used slick*/}
        {options.map((_items,index)=>{
            const { label } = _items;
            const style = selected?.includes(index)
                ? `${commonStyle} bg-[#eaeaeae6] opacity-90 text-[#2E333F] ${mobileCls}`
                : `${commonStyle} bg-[#f2f2f2b3] opacity-70 text-[#2E333F] ${mobileCls}`
            
             return <LDParagraph 
                        className={style}
                        onClick={ () => handleOptionSelect(index)}
                     >
                        {label}
                    </LDParagraph>
        })}
    </div>
  )
}

export default NewTags
