import { Drawer } from 'antd'
import React from 'react'


const LDSlideUp = (props) => {
    const {handleClose,  open, closable,placement="bottom"} = props
  return (
    <div className='slide__up__container'>
        <Drawer
        className='slide__up__container'
        placement={placement}
        closable={closable}
        onClose={handleClose}
        open={open}
        key={placement}
        >
        {props?.children}
      </Drawer>
    </div>
  )
}

export default LDSlideUp
