import React, { useEffect, useState } from "react";
import InstructorCard from "components/InstructorCard";
import { Heading } from "stories/Typography";
import { Link } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import Carousel from "stories/Carousel";
import useMedia from "hooks/deviceHelper/useMedia";
import useGetWindowSize from "hooks/useGetWindowSize";
import { getWidthConfig } from "utils/getWidthConfig";
import { useDispatch, useSelector } from "react-redux";
import CardImage from "components/Card/CardImage";
import { imagePath } from "utils/assetHelper";
import LDTooltip from "stories/LDTooltip";

const InstructorList = ({ instructorList }) => {
  const isMobile = useMedia();
  const instructorSlides = useMedia("(min-width:874px)");
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)  
  const INSTRUCTOR_CAROUSEL_CARD_LENGTH = appConfig?.INSTRUCTOR_CAROUSEL_CARD_LENGTH
  const dispatch = useDispatch()
  const windowSize = useGetWindowSize()

  useEffect(()=>{ 
    const config = getWidthConfig(windowSize?.innerWidth)
    if(config){
      dispatch?.commonParams?.updateBreakPointWiseAppConfig(config)
    }
  },[windowSize])
  
  const instructorLengthClassName = instructorList?.length <= 2 && 'two_list__cards' || instructorList?.length === 3 && "three_list__cards"

  return (
    <>
    {instructorList && instructorList.length !==0 &&
      <div className="lg:pl-[4rem] flex flex-col mt-8 lg:pr-[3rem]">
            <div className=" flex items-center justify-between gap-2">
         <div className="flex items-center gap-2">
         <Heading className="heading-style-instructor"> One on One Sessions   </Heading>
             <LDTooltip title={"Get personalized guidance that addresses your needs & challenges, directly from our experts."}>
              {/* <div>
             {instructorList &&
            instructorList.length > (instructorSlides ? 4 : 1) && (
              <Link
                to={ROUTE_CONSTANTS?.INSTRUCTORS}
                className="flex md:text-lg text-greeny hover:text-white md:mr-0 font-semibold"
              >
                See all
              </Link>
            )}
              </div> */}
              <span className="">
              <CardImage
             src={imagePath("/info.svg")}
             style={{
               width: "23px",
               height: "23px",
               borderRadius: "50%",
               overflow: "hidden",
               objectFit: "cover",
               marginTop:"5px"
             }}
             className="cursor-pointer"
             on
           />
                </span> 
              </LDTooltip> 
          </div>  
        <div>
        {instructorList &&
                    instructorList.length > (instructorSlides ? 4 : 1) && (
                      <Link
                        to={ROUTE_CONSTANTS?.INSTRUCTORS}
                        className="flex md:text-lg text-greeny hover:text-white md:mr-0 font-semibold"
                      >
                        See all
                      </Link>
                    )}
        </div>
            </div>
        <div className="top---bar flex justify-between items-center">
          <div className="heading---text text-[1rem] md:text-[1.75rem] text-white  font-light">
            {/* Our Instructors */}
          </div>
          {/* {instructorList &&
            instructorList.length > (instructorSlides ? 4 : 1) && (
              <Link
                to={ROUTE_CONSTANTS?.INSTRUCTORS}
                className="flex md:text-lg text-greeny hover:text-white md:mr-0 font-semibold"
              >
                See all
              </Link>
            )} */}
        </div>
        <div className="float hidden md:flex">
          <div className="instructor--mobilescroll overflow-x-scroll grid grid-cols-4 mt-2 mb-5 gap-4">
            {instructorList?.slice(0, appConfig?.INSTRUCTOR_CARDSIZE_PER_ROW)?.map((data, idx) => {
              return (
                <div
                  key={idx}
                  className="instructor--list rounded-[10px] pt-[10px] last:mr-0 grow-0 shrink-0 flex flex-col text-center"
                >
                  <InstructorCard {...data} />
                </div>
              );
            })}
          </div>
        </div>
          {isMobile ? (
            <div className={`md:hidden instructor--slides ${instructorLengthClassName}`}>
              <Carousel
                autoplay={false}
                infinite={instructorList?.length > INSTRUCTOR_CAROUSEL_CARD_LENGTH}
                arrowsEnabled={true}
                slidesToShow={INSTRUCTOR_CAROUSEL_CARD_LENGTH}
              >
                {instructorList?.map((data, idx) => {
                  return <InstructorCard {...data} />
                })}
              </Carousel>
            </div>
          ) : ''}
      </div>
    }
      
    </>
  );
};

export default InstructorList;
