import * as eventConstants from "./events.constants";
import refferalEvents from "./ReferralEvents";

const handleEvent  = (eventCode, extraParams={}) => {
    refferalEvents.handle_analytics_event(eventCode, extraParams);
};

export const referralLoadEvent = (loadEnd = false) => {
  const eventCode = loadEnd ? eventConstants?.REFER_A_FRIEND_LOAD_END : eventConstants?.REFER_A_FRIEND_LOAD_START;
  const extraParams = {};
  handleEvent(eventCode, extraParams);
}
export const referralCopyCouponCodeEvent =(extraParams ={})=>{
    const eventCode = eventConstants?.REFER_A_FRIEND_COPY_COUPON_CODE
    handleEvent(eventCode, extraParams)
}
export const referralShareClickEvent =(extraParams= {})=>{
    const eventCode = eventConstants?.REFER_A_FRIEND_SHARE_CLICK
    handleEvent(eventCode ,extraParams)
}
export const referralShareOptionsClickEvent =(extraParams = {})=>{
    const eventCode = eventConstants?.REFER_A_FRIEND_SHARE_OPTION_CLICK;
    handleEvent(eventCode, extraParams)    
}

export const referralReferAFriendInputClick =(extraParams = {})=>{
    const eventCode = eventConstants?.REFER_A_FRIEND_INPUT_CLICK
    handleEvent(eventCode, extraParams)
}

export const referralFriendButtonClickEvent=(extraParams ={})=>{
    const eventCode = eventConstants?.REFER_A_FRIEND_BUTTON_CLICK
    handleEvent(eventCode, extraParams)
}

export const referralCountryCodeSelectEvent =(extraParams ={})=>{
    const eventCode = eventConstants?.REFER_A_FRIEND_COUNTRY_CODE_SELECT;
    handleEvent(eventCode, extraParams)
}