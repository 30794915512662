import CardImage from "components/Card/CardImage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Description, Heading, LDSpan, Subtitle } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { useParams } from "react-router-dom";
import StarRating from "stories/StarRating";
import MultiLoadingCardList from "components/MultiLoadingCardList";
import ReviewLoader from "stories/SkeletonLoader/ReviewLoader";
import MultiLoadingReviewList from "components/MultiLoadingReviewList";
import useIpEffect from "hooks/useIpEffect";

const Ratings = ({ instructorRatingLoading }) => {
  const dispatch = useDispatch();
  const ratings = useSelector((state) => state?.instructor?.ratings);
  const [showMore, setShowMore] = useState(6)
  //showing only 10 reviews in initial render
  const handleShowMore =(alldataLength)=>{
    setShowMore(alldataLength)
  } 


  const { id: InstructorId } = useParams();
  useIpEffect(() => {
    if(InstructorId){
      dispatch?.instructor?.getRatings(InstructorId);
    }
  }, [InstructorId]);

  return (
    <>
      {(instructorRatingLoading && (
        <div className="w-[21.8rem]">
          <MultiLoadingReviewList count={4} />
        </div>
      )) || (
        <div className="ratings--lists">
          <Heading className="mb-6">Rating & Reviews</Heading>
          {ratings?.length !==0 ?  ratings?.slice(0, showMore).map((data, idx) => {
            return (
              <div className="flex flex-col gap-1 mt-7" key={idx}>
                <div className="rating--content flex items-center md:items-center gap-[16px]">
                  <Heading className="font-[BWModelica-Medium]">
                    {data?.users?.first_name} {data?.users?.last_name}
                    <div className="flex pointer-events-none">
                      <StarRating
                        isStarRatingActive={true}
                        disabled={true}
                        size={`13px`}
                        rating={data?.rating}
                        readOnly={true}
                      />
                    </div>
                  </Heading>
                </div>
                <Description className="text-white font-[BWModelica-Regular] text-[1rem] md:text-[1rem]">{data?.review}</Description>
              </div>
            );
          })
        :
          <Heading> No Reviews </Heading> 
        }
         {ratings?.length > 6 &&  <div className="text-greeny underline cursor-pointer font-semibold" onClick={ showMore == 6 ? ()=>handleShowMore(ratings?.length) : ()=>handleShowMore(6) }>{showMore == 6 &&  "Load more"}</div> }
        </div>
      )}
    </>
  );
};

export default Ratings;
