import { useState } from "react";

const Textarea = ({ children, handleChange, value ,...props}) => {
  return (
    <textarea onChange={handleChange} value={value} {...props}>
      {children}
    </textarea>
  );
};

Textarea.defaultProps = {
  children: null,
};

export default Textarea;
