import { axiosDelete, axiosGet, axiosPost, axiosPut } from "axiosRequests";
import { CHANGE_PROFILE_PIC, GET_PROFILE_DETAILS, PROFILE_ADD_UPDATE_EMAIL, PROFILE_DELETE_PIC, PROFILE_PHONE_RESEND_OTP_URL, PROFILE_UPDATE_PHONE_URL, PROFILE_VERIFY__PHONE_URL, GET_GUEST_UPDATE_URL, UPDATE_GUEST_EMAIL_URL, CHILD_MODE_URL } from "constants/Apis";
import { STATUS } from "constants/status.constant";
import { message } from 'antd';
import { Notifier } from "components/Notifier";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { getImgUncachedPath } from "utils/assetHelper";
const model = {
  state: {
    profileDetails: {},
    error: {},
    childAuthModalActive: false,
    childModeUpdateInProgress: false
  },
  reducers: {
    setProfileDetails: (state, payload) => {
      return {
        ...state,
        profileDetails: payload,
      };
    },
    setChildModalStatus: (state, payload) => {
      return {
        ...state,
        childAuthModalActive: payload
      }
    },
    setError: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    setChildModeUpdateStatus: (state, payload) => {
      return {
        ...state,
        childModeUpdateInProgress: payload
      }
    }
  },
  effects: (dispatch) => ({
    async requestProfileDetails() {
      try{
        const response = await axiosGet(GET_PROFILE_DETAILS);
        const { data } = response;
        dispatch?.profile?.setProfileDetails(data);
      }
      catch(err){
        console.log(err,"error in get profile detail")
      }
    },
    async requestUpdateProfile(body,state,cb) {
      try{
        const response = await axiosPut(GET_PROFILE_DETAILS,body);
        const { data,status } = response;      
        const prevData = state?.profile?.profileDetails || {};
        dispatch?.profile?.setProfileDetails({...prevData, ...data});
        if (STATUS?.includes(status)) {
           cb()
          //  Notifier(200, MESSAGE_CONSTANTS?.UPDATE_PERSONAL_DETAILS_MSG) 
        }else{
          Notifier(status, data?.error?.message)
        }
      }
      catch(err){
        Notifier('Deleted', MESSAGE_CONSTANTS.UPDATE_PERSONAL_DETAILS_ERR_MSG)
      }
    },
    async requestUpdateProfilePic(file,state,cb){  
      try{
        const response = await axiosPut(CHANGE_PROFILE_PIC,file)
        if(response == undefined){
         return message.error("File is too large,Please upload file below one 1MB")
        }
        const { data ,status} = response
        if(STATUS.includes(status)){
          let updatedPicture = data[0]?.url || data?.url
          updatedPicture = getImgUncachedPath(updatedPicture);
          const profileDetails ={
              ...state?.profile?.profileDetails,
               displayPicture:updatedPicture
          }
          const result  = {
            ...state?.profile,
            profileDetails:profileDetails
          }
          dispatch?.profile?.setProfileDetails(profileDetails);
          cb()
        }else{
          Notifier(status, MESSAGE_CONSTANTS.UPDATE_PROFILE_PIC_ERR_MSG)
        }
      }
      catch(err){
        Notifier('Error', MESSAGE_CONSTANTS.UPDATE_PROFILE_PIC_ERR_MSG)
        console.log(err,"eror in updated ProfilePic")
      }
      
    },
    async requestProfileVerfiyPhone(payload,undefined,callback){
      try{
        const response = await axiosPost(PROFILE_VERIFY__PHONE_URL,payload)
        const { data,status } =response
        if(STATUS.includes(status)){
            callback(data?.statusMessage)
        }
        else{
          callback(data?.error?.message,status)
        } 
      }
      catch(err){
        console.log(err,"error in phone verify")
      } 
    },
    async updateProfilePhoneNumber(payload,undefined,callback){
      try{
        const response = await axiosPost(PROFILE_UPDATE_PHONE_URL,payload)
        const {data,status} = response 
        if(STATUS.includes(status)){
          callback("success",MESSAGE_CONSTANTS.UPDATE_PHONE_MSG)
        }
        else{
          Notifier(status, MESSAGE_CONSTANTS.UPDATE_PHONE_ERR_MSG)
          // callback("error",data?.error?.message)
        }
      }
      catch(err){
        Notifier('Error', MESSAGE_CONSTANTS.UPDATE_PHONE_ERR_MSG)
        console.log(err,"error in update phone number")
      }
    },
    async resendOtpProfilePhoneNumber(payload,undefined,callback){
      try{

        const response =await axiosPost(PROFILE_PHONE_RESEND_OTP_URL,payload)
        const {data ,status } =response
        if(STATUS.includes(status)){
          callback("success",data?.statusMessage)
        }
      }
      catch(err){
        console.log(err,"error in resend Otp phone number")
      }
    },
    async requestDeleteProfile(){
       try{
          const response = await axiosDelete(PROFILE_DELETE_PIC) 
          const { data, status} = response
          if(STATUS.includes(status)){
           Notifier(status,MESSAGE_CONSTANTS.UPDATE_PROFILE_DELETED_PIC)
            dispatch?.profile?.requestProfileDetails()
          } 
          else {
          }
       }
       catch(err){
            console.log(err)
       }
    },
    async requestAddEmail(body,undefined,cb){
      try{
          const response = await axiosPost(PROFILE_ADD_UPDATE_EMAIL,body)
          const {data,status} = response
          if(STATUS.includes(status)){
            cb()
            // Notifier(status, MESSAGE_CONSTANTS.ADD_EMAIL_PROFILE)
          }
          else{
            Notifier(status, data?.error?.message)
          }
       }
       catch(err){
        Notifier('Error', MESSAGE_CONSTANTS.ADD_EMAIL_PROFILE_ERR_MSG)
       }
    },
    updateProfileLocally(body, state){
      dispatch?.profile?.setProfileDetails({ ...state?.profile?.profileDetails, ...body })
    },
    async requestGuestToken(userId, undefined, cb =()=>{} ){
      try{
        const response = await axiosGet(`${GET_GUEST_UPDATE_URL}?_u=${userId}`)
        const { data, status } = response
        if (STATUS?.includes(status)) {
            cb(data)
        } else {
          cb(response)
        } 
      }
      catch (err) {
        console.log(err,"error in update Guest Email")

      }
    },
    async updateGuestDetails(body, undefined, callback) {
      try{
        const response = await axiosPost(UPDATE_GUEST_EMAIL_URL, body);
        const  { data, status } = response
        if(status == 409){
          Notifier(status, "This email is already associated with an account. Please try with a different email.");
        }
        else if(STATUS.includes(status)){
          // if (STATUS.includes(409)) {
          //   Notifier(status, "Email already exists");
          // } else {
            Notifier(status, MESSAGE_CONSTANTS?.EMAIL_UPDATE_MSG);
          // }
          // const payload = {
          //   course_id: courseId,
          //   token: guestUpdateToken
          // }
          // dispatch?.payment?.sendPurchaseInvoiceEmail(payload, callback);
          callback(data)
        }
      }catch(err){
        console.log(err,"error in update Guest Email")
      }
    },
    async updateChildMode(body, state) {
      const childMode = body?.childMode
      try {
        dispatch?.profile?.setChildModeUpdateStatus(true)
        const response = await axiosPut(CHILD_MODE_URL, body)
        const { status, data } = response;
        if(STATUS.includes(status)) {
          dispatch?.home?.clearWatchNowData();
          const prevData = state?.profile?.profileDetails || {};
          dispatch?.profile?.setProfileDetails({...prevData,...body});
          if (childMode) {
            Notifier(status, MESSAGE_CONSTANTS?.CHILD_MODE_ACTIVATE_MSG);
          } else {
            Notifier(status, MESSAGE_CONSTANTS?.CHILD_MODE_DEACTIVATE_MSG);
          }
        } else {
          Notifier('Error', data?.error?.message);
          dispatch?.profile?.setChildModeUpdateStatus(false)
          dispatch?.profile?.setChildModalStatus(true);
          return;
        }
        dispatch?.profile?.setChildModalStatus(false)
        dispatch?.profile?.setChildModeUpdateStatus(false)
      } catch(err) {
        console.log(err);
        dispatch?.profile?.setChildModalStatus(false)
        dispatch?.profile?.setChildModeUpdateStatus(false)
        Notifier('Error', MESSAGE_CONSTANTS?.GENERIC_ERR_MSG);
      } 
    },
  }),
};
export default model;
