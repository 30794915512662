import "./index.css";
import PropTypes from "prop-types";
import { LDLabel ,LDParagraph} from "stories/Typography";

const Input = ({
  size = "medium",
  icon,
  src,
  iconStyle,
  normalStyle,
  label,
  inputValue,
  handleSearch,
  error,
  handleChange,labelFloating,value, ...props}) => {
 

  const handleKeyDown = e => {
    if (icon?.length !== 0) {
      handleSearch(e);
    }
  };
  const floatingLabelStyle = { position: "absolute" ,left: "10px", fontSize: "12px",background: "linear-gradient(to bottom, #262C35 50%, #2E333F 50%)" };
  const labelStyle = labelFloating ? floatingLabelStyle : {};
  return (
    <div className="container-icon ">
      {label && <LDLabel style={labelStyle} className="profile-personal-label-style" htmlFor="label">{label}</LDLabel>}
      <input
        id="label"
        className={`text-white outline-none ${icon && normalStyle}`}
        onKeyDown={handleKeyDown}
        onChange={e => {
          handleChange(e.target.value);
        }}
        value={value || ""} // Don't change/remove value={value} it will affect profile name and date of birth
        {...props}
      />
      {icon && (
        <img src={src} alt="search" className={`absolute ${iconStyle} `} />
      )}
      {error?.length > 0 &&
        <LDParagraph className="password-rules-error-style text-left ml-12 mt-1">{error}</LDParagraph>
      }
    </div>
  );
};

Input.propTypes = {
  icon: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.string.isRequired,
};

Input.defaultProps = {
  placeholder: "",
  icon: null,
  handleChange: () => {},
  size: "normalStyle",
  label: "",
  inputValue: "",
  handleSearch: () => {},
  normalStyle: "normalStyle",
  error: "",
  value: ""
};

export default Input;
