import { Modal as LoginModal } from "antd";


const Modal = (props) => {
  const { handleClose, show, children, popUpPadding } = props;
  if (!show) {
    return null;
  }
  return (
    <LoginModal visible={show} onCancel={handleClose} {...props} className={`${popUpPadding ? popUpPadding : ""}`}>
      {children}
    </LoginModal>
  );
};
export default Modal;
