import { io } from "socket.io-client";
import { getAccountDetails } from "selectors";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticatedEffect from "./useAuthenticatedEffect";

const base_url = process.env.REACT_APP_SOCKET_API;
const EMAIL_VERIFY_SUCCESS_SOCKET_TYPE = "EMAIL_VERIFICATION_SUCCESS";

const useSocket = () => {
  const { jwt } = getAccountDetails();
  const accountDetails = useSelector((state)=> state?.registerWithEmail?.account_details)

  const dispatch = useDispatch();

  const handleEmailVerified = (args) => {
    if(args?.type === EMAIL_VERIFY_SUCCESS_SOCKET_TYPE && !accountDetails.isEmailVerified){
      dispatch?.profile?.updateProfileLocally({ isEmailVerified: true })
    }
  }
  useAuthenticatedEffect(() => {
    io(`${base_url}`, { // need endpoint 
      auth: {
        token: jwt,
      },
      transports: ["polling", "websocket"],
    }).on("NOTIFY_USER",(args)=>{
      if(args && args.user_id && args.user_id === accountDetails?.user?.id){
        dispatch?.notification?.requestAddNotification(args)
        handleEmailVerified(args);
      }else if(!args?.user_id){
        dispatch?.notification?.requestAddNotification(args)
      }
    });
  }, []);
};

export default useSocket;

// For reference
// return io(`http://localhost:${socket_port}`, {
//     auth: {
//       token: jwt,
//     },
//     transports: ["polling", "websocket"],
// });
