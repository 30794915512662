import { Col, Row } from "antd";
import {  Heading, LDParagraph, LDSpan, Subtitle, Title } from "stories/Typography";
import { useState } from "react";
import "./index.css";
import LDRadio from "stories/LDRadio";
import { useHistory } from "react-router-dom";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { settingBackClickEvent, settingVideoQualityOptionClick } from "analytics/settings";
import LDImage from "stories/Image";
import { imagePath } from "utils/assetHelper";
import { videoQualityOptions } from "constants/videoplayer.constants";
import { useDispatch, useSelector } from "react-redux";
import "./index.css"

const VideoQuality = () => {
  const history = useHistory()
  const dispatch = useDispatch();

  const playerStorageData = useSelector((state) => state?.storageData?.playerData);
  const { quality=-1 } = playerStorageData;
  const handleBack=()=>{
    const params = {event_label :"Video Quality Back"}
    settingBackClickEvent(params)
    history.push(ROUTE_CONSTANTS.SETTINGS)
  }
  const onChange = (e) => {
    const value = e?.target?.value
    const quality = { quality:  value ||  Number(e) }
    dispatch?.storageData?.updatePlayerData(quality)
  };
  const activeQuality = quality === 0 ? "0" : quality;
  return (
    <div className="container mx-auto  min-h-screen">
         <Title className="relative top-12 left-10 w-12 block lg:hidden">
           <LDSpan className="flex items-center cursor-pointer" onClick={handleBack}>
            <LDImage
              src={imagePath("/Banner/leftArrow.svg")}
              alt="rightArrow"
              className="h-4 pr-2 w-6"
            />
           </LDSpan>
         </Title>
       <div className="lg:mt-7 mt-3  mb-5 lg:mb-10  text-center">
        <Heading className="setting-video-heading-font-style"> Video Quality</Heading>
       </div>
      <Row justify="center">
        <Col
          lg={15}
          md={24}
          sm={24}
          xs={24}
          className="lg:bg-ternary px-2 lg:px-11 lg:pt-12 rounded-lg pb-[8rem]"
        >
          <div className="flex items-center justify-between">

          <Subtitle className="setting-video-font-style my-4 mx-4">
            Select Video Quality
          </Subtitle> 

         <Title className="hidden lg:block lg:mr-4">
           <LDSpan className="flex items-center cursor-pointer setting-video-font-style" onClick={handleBack}>
            <LDImage
              src={imagePath("/Banner/leftArrow.svg")}
              alt="rightArrow"
              className="h-4 pr-2 w-6"
            />
            Back
           </LDSpan>
         </Title>
          </div>
          <div className="lg:mx-4 mx-2">
            <LDRadio
              options={videoQualityOptions} 
              selected={activeQuality}
              handleEventFire={(value)=>onChange(value)} 
              onChange={onChange}
              grpCls="w-full"
              className="flex justify-between items-center bg-ternary lg:bg-sidebarSelected py-3 px-4 cursor-pointer"
            />
          </div>

        </Col>
      </Row>
    </div>
  );
};

export default VideoQuality;
