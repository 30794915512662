import { Fragment } from "react";
import CardListLoader from "stories/SkeletonLoader/CardListLoader";

const MultiLoadingCardList = ({count}) => {
  return <Fragment>{[...Array(count)]?.map((item, key) => {
      return <CardListLoader key={key} />
  })}</Fragment>;
};

MultiLoadingCardList.defaultProps = {
    count: 1
}

export default MultiLoadingCardList;
