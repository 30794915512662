import LDImage from "stories/Image";
import { LDParagraph } from "stories/Typography";
import { imagePath } from "utils/assetHelper";

const DownloadAll = (props) => {
  const { totalEpisodes, handleDownload, isPurchased } = props;
  return (
    <div className="flex justify-between items-center text-white px-5 py-4 bg-ternary rounded-md" >
      <div>
        <LDParagraph className="my-1 text-lg font-modellicaBold card-Subtitle ">
          {totalEpisodes} Episodes
        </LDParagraph>
        <LDParagraph className="m-0 text-gray-500">Download All</LDParagraph>
      </div>
      {isPurchased ?  
      <LDImage
        src={imagePath("/Course/download.svg")}
        alt="downloadImg"
        className="cursor-pointer"
        onClick={()=>handleDownload("download")}
      /> :
      <LDImage
      src={imagePath("/Course/lock.svg")}
      alt="lockImg"
      className="cursor-pointer"
      onClick={()=>handleDownload("pruchase")}
    />
      }    
    </div>
  );
};

DownloadAll.defaultProps = {
  totalEpisodes: "",
  handleDownload: () => {},
};
export default DownloadAll;
