import Card from "components/Card";
import Duration from "components/Duration";
import MultiLoadingCards from "components/MultiLoadingCards";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import StarRating from "stories/StarRating";
import { Heading, Title } from "stories/Typography";
import { getHours, getMinutes, getSeconds ,getRoundedVal} from "utils";
import Action from "./Action";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticatedEffect from "hooks/useAuthenticatedEffect";
import { homeWatchNowEvent } from "analytics/home";
import { PAGE_SIZE } from "constants/app.constants";
import HomeError from "pages/Home/components/Errors";
import { commonParams } from 'models/CommonParams';
import LoadingSpinner from "stories/Loader/LoadingSpinner";
import "../../index.css"
import LDTooltip from "stories/LDTooltip";
import CardImage from "components/Card/CardImage";
import { imagePath } from "utils/assetHelper";

const WatchNow = (props) => {
  const {
    getRating,
    handleWishList,
    watchNowList,
    watchNowLoading,
    isWatchNowCard
  } = props;
  const appConfig = useSelector((state)=> state?.commonParams?.app_breakpoint_configs)
  const watchNowPaginationMeta  = useSelector(
    (state) => state?.home?.paginationMeta?.pagination
  );

  const filterPaginationMeta  = useSelector(
    (state) => state?.home?.filterPaginationMeta
  );

  const profile = useSelector((state) => state?.profile?.profileDetails);
  const { childMode } = profile;

  const getWatchNowData = () => {
    dispatch?.home?.requestWatchNow();
  }

  useAuthenticatedEffect(()=>{
    if(appConfig && Object.keys(appConfig).length && watchNowList.length === 0){
      getWatchNowData();
    }
  },[appConfig, watchNowList, childMode])

  const watchFullDataLoaded = Object.keys(filterPaginationMeta).length !== 0 || (watchNowPaginationMeta && watchNowPaginationMeta?.page === watchNowPaginationMeta?.pageCount);
  useInfiniteScroll(getWatchNowData,watchFullDataLoaded);
  
  const loaderCardLength = appConfig?.WATCH_NOW_SIZE_PER_ROW === undefined ? 4 : appConfig?.WATCH_NOW_SIZE_PER_ROW * 2
 
  const dispatch = useDispatch()


  const watchNowErr  = useSelector((state) => state?.home?.watchNowErr);
  
  const handleClick=(courseTitle)=>{
    const params = {course_name:courseTitle}
    homeWatchNowEvent(params)
  }
  const isMobile = useMedia("(max-width: 640px)");
  const { WATCH_NOW_THUMB_DIMENSION } = appConfig;
  const starSize = isMobile ? "12px" :"14px"
  const marginRight = isMobile ? "mr-[0.2rem]" :"mr-2"
  return (
    <div className="">
      <div className="flex items-center gap-2">
      <Heading className="mt-4 mb-2 heading-style-home">Our Courses</Heading>
      <LDTooltip title={"Discover your gateway to personal growth with our specially crafted courses"}>
        <span className="">
            <CardImage
             src={imagePath("/info.svg")}
             style={{
               width: "23px",
               height: "23px",
               borderRadius: "50%",
               overflow: "hidden",
               objectFit: "cover",
               marginTop:"3px"
             }}
             className="cursor-pointer"
             on
           />
        </span> 
      </LDTooltip> 
      </div>
    

      {!watchNowLoading && watchNowErr && (
        <HomeError
          action={() => {
            dispatch?.home?.setWatchNowLoading(true);
            setTimeout(fetchWatchNow, 800);
          }}
        />
      )}

      {!watchNowLoading && watchNowList?.length === 0 && !watchNowErr && (
        <Heading className="text-center">No courses found. </Heading>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 lg:gap-x-10 gap-x-5 gap-y-2 lg:gap-y-5">
        {/* {watchNowLoading && <MultiLoadingCards count={4} />} */}
        {watchNowList?.map((_result, i) => {
          const lastElement = watchNowList?.length === i + 1;
          const {
            courseId,
            courseTitle,
            courseRating,
            isPurchased,
            isWishlisted,
            publishDate,
            publishedAt,
            thumbnailUrl,
            totalEpisodeCount,
            totalEpisodeDuration,
            slug,
          } = _result;
          const content = (
            <div className="pointer-events-none">
            <StarRating
              isStarRatingActive={true}
              getRating={getRating}
              rating={Number(courseRating)}
              disabled={true}
              fontSize={starSize}
              // marginRight={marginRight} // Creating a issue with half rating thats way is commented 
            />
            </div>
          );
          const action = (
            <Action
              totalEpisodeCount={totalEpisodeCount}
              isWishlisted={isWishlisted}
              courseTitle={courseTitle}
              handleWishList={handleWishList}
              courseId={courseId}
            />
          );
          return (
            <Fragment key={i}>
              {totalEpisodeCount  !== 0 &&
               <Card
                title={courseTitle}
                subtitle={
                  <Duration
                    hours={getHours(totalEpisodeDuration)}
                    minutes={getMinutes(totalEpisodeDuration)}
                    seconds={getSeconds(totalEpisodeDuration)}
                    className="h-6 "
                  />
                }
                content={content}
                action={action}
                cardImg={thumbnailUrl}
                // imageCardCls="lg:h-[18vh] 2xl:h-[12vh] h-[13vh]"
                imageCardCls="h-full"
                route={
                  totalEpisodeCount > 0 && `/${ROUTE_CONSTANTS.COURSE}/${slug}`
                }
                twoColumn={"flex bg-ternary p-3  lg:block lg:p-0"}
                handleClick={() => handleClick(courseTitle)}
                imageCls={"w-full sm:h-[6rem] xs:h-[5rem] md:h-[5rem] lg:h-[8.438rem] xl:h-full"} //xl:h-[10rem]
                thumbDimension={WATCH_NOW_THUMB_DIMENSION}
                isWatchNowCard={isWatchNowCard}
                />
              }
            </Fragment>
          );
        })}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6">
        {!isMobile && watchNowLoading && (
          <MultiLoadingCards count={loaderCardLength} />
        )}
        {isMobile && watchNowList.length === 0 && (
          <MultiLoadingCards count={2} />
        )}
      </div>
      {watchNowLoading && (
        <div className="flex justify-center my-[1rem]">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default WatchNow;
