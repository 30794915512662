
import { digitRegx, lowerCaseRegx, checkPasswordIsValid, upperCaseRegx } from "utils";

export const password_rule_list = [{
    text: "at least 8 characters",
    validate: (data) => { return data?.length >= 8 }
},{
    text: "upper & lowercase letters",
    validate: (data) => { return lowerCaseRegx.test(data) && upperCaseRegx.test(data) }
},
// {
//     text: "Do not include any special character",
//     validate: (data) => { return checkPasswordIsValid(data)}
// },
{
    text: "at least one number",
    validate: (data) => { return digitRegx.test(data) }
}];