import { useCallback, useEffect } from "react";
import useAuthenticatedEffect from "../useAuthenticatedEffect";

const useInfiniteScroll = (callBackFn, removeInfiniteScroll = false, allowed=true) => {
  var throttleTimer;

  const throttle = (callback, time) => {
    if (throttleTimer) return;

    throttleTimer = true;

    setTimeout(() => {
      callback();
      throttleTimer = false;
    }, time);
  };

  const handleInfiniteScroll = useCallback(() => {
    throttle(() => {
      const endOfPage = window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
      if (endOfPage) {
        callBackFn();
      }
    }, 800);
  },[]);
  useAuthenticatedEffect(() => {
    if(allowed){
      window.addEventListener("scroll", handleInfiniteScroll);
      return () => {
        window.removeEventListener("scroll", handleInfiniteScroll);
      };
    }
  }, [allowed]);
  
  useAuthenticatedEffect(() => {
    if(removeInfiniteScroll){
      window.removeEventListener("scroll", handleInfiniteScroll);
    }
  },[removeInfiniteScroll])
};

export default useInfiniteScroll;
