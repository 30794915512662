import { removeItemFromStorage, setItemToStorage } from "./storage.helper";
import store from "store";

export const removeToken =()=>{
    const dispatch = store.dispatch;
    dispatch?.registerWithEmail?.requestRemoveToken()
    dispatch?.authModel?.isNotLoggedIn(true);
    removeItemFromStorage("token");
    dispatch({ type: 'RESET_APP' })
}

export const setTokenToStorage =(jwt) => {  //Not in used
    setItemToStorage("token",jwt) 
}


export const setTokenInApp = (dispatch,jwt )=>{ // not in used
    setTokenToStorage(jwt)
    dispatch?.registerWithEmail?.setJwtToken(jwt)
    dispatch?.authModel?.isNotLoggedIn(false);
}
