export const ROUTE_CONSTANTS = {
  PAYMENT: "payment",
  EPISODE: "episode",
  PROFILE: "profile",
  COURSE:"course",
  PROFILE_VERIFY_EMAIL:"profile/verify-email",
  PROFILE_VERIFY_PHONE:"profile/verify-phone",
  PROFILE_ADD_EMAIL:"profile/add-email",
  PAYMENT_SUCCESS:"/payment/success",
  PAYMENT_ERROR:"payment/error",
  PAYMENT:"payment",
  LANGUAGE_PREFERENCE:"settings/language-preference",
  VIDEO_QUALITY:"settings/video-quality",
  SETTINGS:"/settings",
  MY_COURSES: "/mycourses",
  PRIVACY:"/privacy",
  TERMS_AND_CONDITIONS:"/terms-and-conditions",
  GUEST_ROUTE:'/guest-payment',
  INSTRUCTORS:'/instructors',
  INSTRUCTORINFORMATION:"/instructor-details",
  BOOKSESSION:"/booking-details",
  BOOKING_CONFIRMED:"/booking-confirmed",
  MY_SESSION:"/my-session",
  HOME:"/home",
  INSTRUCTOR_CALENDER:"/instructor-calendar"
};

export const publicRoutes = ["/", "about", "contact", "courses", "blogs", "blog", "terms-and-conditions", "email-verify", "reset-password", "reset-childmode-password", "privacy", "landing","guest-payment","payment","instructors","instructor-details","booking-confirmed","session", "instructor-calendar", "delete-account"];

export const PROFILE_UPDATE_INVALID_ROUTES = ["email-verify", "reset-password", "reset-childmode-password", "privacy", "landing","guest-payment","payment","instructors","instructor-details","booking-confirmed","session"]
