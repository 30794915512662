import { paymentCouponCodeApplyBtnClickEvent } from "analytics/payment";
import { Notifier } from "components/Notifier";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { path } from "ramda";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FormButton from "stories/FormButton";
import LDImage from "stories/Image";
import { LDParagraph, LDSpan,  Title } from "stories/Typography";
import { getRoundedVal } from "utils";
import { imagePath } from "utils/assetHelper";
import AddCoupon from "../AddCoupon";
import PurchaseDetails from "../PurchaseDetails/PurchaseDetails";
import UseCoupon from "../UseCoupon/UseCoupon";
import "./index.css"

const PurchaseCourse = (props) => {
  const { handlePurchase, purchaseData, courseId, episodes, price, couponList,title } = props;
  const totalAmount = path(["purchaseDetails", "amountDetails","totalOrderAmount"], purchaseData) || "0";
  const totalSavings = path(["purchaseDetails", "amountDetails","totalSavings"], purchaseData) || "0";
  const offerPrice =  path(["purchaseDetails", "amountDetails","offerPrice"], purchaseData) || "0";
  const originalOrderAmount =  path(["purchaseDetails", "amountDetails","originalOrderAmount"], purchaseData) || "0";
  const calculatedPurchaseTotal = path(["purchaseDetails", "amountDetails","calculatedPurchaseTotal"], purchaseData) || "0";
  const couponCode = path(["purchaseDetails","couponDetails","couponCode"],purchaseData) || 0
  const currencySymbol =  path(["purchaseDetails", "amountDetails","currencySymbol"], purchaseData) || "";
  const dispatch = useDispatch(); 
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [changeBtn, setChangeBtn] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false)
  const [discountPrice, setDiscountPrice] =useState("")
  const [notValid,setNotValid] = useState(false)
  const handleApplyCoupon = (couponValue) => {
    const params = {event_label:`${title} - ${couponValue}`  }
    paymentCouponCodeApplyBtnClickEvent(params)
    let arr
    if (typeof couponValue === "string") {
      setAppliedCoupon(couponValue);
      arr = couponList?.filter(item => item?.couponCode?.toLowerCase()?.indexOf(couponValue?.toLowerCase()) !== -1)
    } else {
      arr = couponList?.filter(item => item?.couponCode?.toLowerCase()?.indexOf(appliedCoupon?.toLowerCase()) !== -1)
    }
    if (arr?.length === 1) {
      setChangeBtn(true);
      setIsCouponValid(true)
    } else {
      setChangeBtn(true);
      setIsCouponValid(false)
    }
    const body = {
      purchase_id: purchaseData?.purchaseId,
      coupon_code: couponValue,
    };
    dispatch?.payment?.requestApplyCoupon(body,(value,discount)=>{   
      if(value === false){
        setNotValid(true)
        Notifier("DELETED",discount?.message)
      }
      if(value=== true){
        setNotValid(false)
      }
        setIsCouponValid(value) //TODO : store to redux 
        setDiscountPrice(discount?.coupon?.coupon_value) //TODO :Store to redux 
    });
  };
  const handleRemoveCode = (couponCode) => {
    const confirm = window.confirm("Are you sure you want to Remove Coupon Code?");
    // const  params ={event_label : `${title} - ${couponCode}`}
    // paymentCouponCodeApplyBtnClickEvent(params)
    if (confirm) {
      const body = {
        purchase_id: purchaseData?.purchaseId,
        coupon_code: "",
      };
      dispatch?.payment?.requestRemoveCoupon(body)
      setAppliedCoupon("");
      setChangeBtn(false);
      setIsCouponValid(false)
    }
    if (confirm) {
      const body = {
        purchase_id: purchaseData?.purchaseId,
        coupon: appliedCoupon,
      };
      // dispatch?.payment?.requestDeleteCoupon(body);
    }
  };
  return (
    <div>
      <div className="lg:pt-6 lg:pb-14 lg:px-5">
        <Title className="purchase-course-heading-style lg:mb-6 hidden lg:block">Purchase this course</Title>
        {isCouponValid && (
           <>
           {totalSavings  && 
            <div className="bg-greeny mb-4 lg:mb-4 bg-opacity-40 p-2 rounded">
              <LDParagraph className="flex text-white text-md items-center">
                {/* <LDImage src={imagePath("/rupee.svg")} alt="" className="lg:h-2 mr-1 w-1.5" /> */}
                {currencySymbol}
                {`${totalSavings && getRoundedVal(totalSavings)}`}<LDSpan className="mx-1"> Total Savings </LDSpan> 
              </LDParagraph>
              <LDSpan className="text-white text-md">with {appliedCoupon}</LDSpan>
            </div>
           }
           </> 
        )}
        <AddCoupon
          changeBtn={changeBtn}
          appliedCoupon={appliedCoupon}
          // isCouponValid={initiatePurchase?.isCouponValid}
          isCouponValid={isCouponValid}
          setIsCouponValid={setIsCouponValid}
          couponCode={couponCode}
          setAppliedCoupon={setAppliedCoupon}
          handleApplyCoupon={handleApplyCoupon}
          handleRemoveCode={handleRemoveCode}
          notValid={notValid}
          setNotValid={setNotValid}
          title={title}
        />

        {/* <UseCoupon handleApplyCoupon={handleApplyCoupon} couponList={couponList} title={title} /> */}
        <PurchaseDetails
          purchasePrice={totalAmount}
          discountPrice={totalSavings}
          totalPrice={calculatedPurchaseTotal}
          offerPrice={offerPrice}
          isCouponValid={isCouponValid}
          originalOrderAmount={originalOrderAmount}
          currencySymbol={currencySymbol}
        />
      </div>
      <div className="bg-greeny text-center py-6 px-4 rounded-b-[4px] hidden lg:block ">
        <FormButton
          type="primary"
          style={{borderRadius: "4px" ,width:"100%"}}
          onClick={handlePurchase}
          backgroundColor={COLOR_CONSTANTS.WHITE}
          color ={COLOR_CONSTANTS.GREEN}
          className={" bg-[#0E896A] payment-continue-purchase shadow-md shadow-green-500/50"}
        >
          Continue Purchase
        </FormButton>
      </div>
    </div>
  );
};

export default PurchaseCourse;

