import Modal from "components/Modal";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { LDParagraph, LDSpan, Title } from "stories/Typography";
import { useState } from "react";
import FormButton from "stories/FormButton";
import UpdatedEmail from "components/UpdatedEmail";
import { useDispatch, useSelector } from "react-redux";
import { Notifier } from "components/Notifier";
import { Form } from "antd";
import { Input as LDNewInput } from "antd";
import Image from "components/Image";
import { imagePath } from "utils/assetHelper";
import { EmailCheckRegEx } from "utils";

const ChildModePopUp = () => {
  const [childPassword, setChildPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state?.profile?.profileDetails);
  const childAuthModalActive = useSelector((state) => state?.profile?.childAuthModalActive);
  const childModeUpdating = useSelector((state) => state?.profile?.childModeUpdateInProgress);
  const { email } = userProfile;

  const handleModalClose = () => {
    dispatch?.profile?.setChildModalStatus(false);
  }

  const handleChildPasswordUpdate = () => {
    const body = {
        childMode: false,
        password: childPassword
    };
    if (!childPassword) {
      Notifier("Error", "Password is required");
    } else {
      dispatch?.profile?.updateChildMode(body);
      dispatch?.profile?.setChildModalStatus(true);
    }
  }

  const handleChildResetPassword = () => {
    dispatch?.registerWithEmail?.requestForgotChildPassword();
  };

  const handleChildModeEmail = (email) => {
    if(email?.length ===0){
      return Notifier("DELETED","Please enter email")
   }
   if(email && EmailCheckRegEx(email)) {
     const data = {
       email: email
     };      
     const cb=()=>{
      dispatch?.registerWithEmail?.requestForgotChildPassword();
      dispatch?.profile?.setChildModalStatus(false);
     }
     dispatch?.profile?.requestUpdateProfile(data, cb);
   } else {
     Notifier("DELETED", "Please enter a valid email");
   }
  }

  return (
    email ? (
        <Modal
          show={true}
          bodyStyle={{
              padding: "0px",
              backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
          }}
          onCancel={handleModalClose}
          maskClosable={false}
          width={400}
          height={800}
          closable={true}
          maskStyle={{
              backdropFilter: "blur(4px)",
              "-webkit-backdrop-filter": "blur(4px)"
          }}
          footer={null}
        >
          <div className="flex flex-col p-14 text-center">
              <Title>Please enter password to turn off<br/> child mode</Title>
              <Form.Item>
                <LDNewInput
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  value={childPassword}
                  onChange={e => {
                    setChildPassword(e?.target?.value);
                  }}
                  className="mt-2 bg-secondary rounded px-4 py-[0.65rem] border border-formBorder auth-input-text-style"
                />
                <Image
                  src={passwordShown ? imagePath("/show.svg") : imagePath("/hide.svg") }
                  icon={false}
                  onClick={() => setPasswordShown(!passwordShown)}
                  alt=""
                  className="h-6 w-6 cursor-pointer absolute top-4 right-4"
                />
              </Form.Item>
              <FormButton
                backgroundColor={COLOR_CONSTANTS.WHITE}
                color={COLOR_CONSTANTS.GREEN} 
                onClick={handleChildPasswordUpdate}
                className=" w-full h-10 my-2 font-bold rounded-lg  bg-white lg:bg-white"
                loading={childModeUpdating}
              >
                Submit
              </FormButton>
              <LDParagraph className="text-left text-[12px]">Please click on the  following link and reset the password if you are switching OFF the child mode for the first time.</LDParagraph>
              <LDParagraph className="text-greeny font-modellicaMedium m-4">
                  <LDSpan className="cursor-pointer text-greeny underline" onClick={handleChildResetPassword}>
                      Set/Reset Password
                  </LDSpan>
              </LDParagraph>
          </div>
        </Modal>
    ) : (
        <UpdatedEmail 
          title="Please update your email to receive child mode password reset link" 
          btnTitle="Update Email" 
          childAuthModalActive={childAuthModalActive} 
          show={true} 
          setShow={handleModalClose}
          isPurchase={false} 
          updateEmailClickAction={handleChildModeEmail}
        />
    )
  );
};

export default ChildModePopUp;
