import { LDAnalytics } from "utils/analyticsHelper.js";

class OfferAndDiscountEvents extends LDAnalytics {
  event_Offers_And_Discount_Load_Start() {
    return {
      event: {
        module_name: "Offers And Discount",
        feature_name: "Offers And Discount Load",
        event_name: "Offers_And_Discount_Load_Start",
        event_label: "Offers Load ",
      },
    };
  }
  event_Offers_And_Discount_Load_End() {
    return {
      event: {
        module_name: "Offers And Discount",
        feature_name: "Offers And Discount Load",
        event_name: "Offers_And_Discount_Load_End",
        event_label: "Offers Load",
      },
    };
  }
  event_Offers_And_Discount_Filter_Click() {
    return {
      event: {
        module_name: "Offers And Discount",
        feature_name: "Offers And Discount Filter",
        event_name: "Offers_And_Discount_Filter_Click",
        event_label: "Search",
      },
    };
  }
  event_Offers_And_Discount_Copy_Btn_Click() {
    return {
      event: {
        module_name: "Offers And Discount",
        feature_name: "Offers And Discount Copy Code",
        event_name: "Offers_And_Discount_Copy_Btn_Click",
        event_label: "Coupon Code",
      },
    };
  }
}

const offerAndDiscountEvents = new OfferAndDiscountEvents();
export default offerAndDiscountEvents;
