import { months } from "constants/calendar.constants";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormButton from "stories/FormButton";
import { useParams } from "react-router-dom";
import { RAZORPAY_KEY } from "constants/razorpay.contants";
import { Notifier } from "components/Notifier";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { LDInput } from "stories/Form";
import Modal from "components/Modal";
import CustomSelector from "components/CustomSelector";
import { imagePath } from "utils/assetHelper";
import Image from "components/Image";
import { Heading, LDSpan } from "stories/Typography";
import Loader from "stories/Loader";
import Timer from "components/Timer";
import { useQuery } from "hooks/useQuery";
import ConfirmReschedulemodal from "./ConfirmRescheduleModal";
import { guestSessionPaymentCancelEvent, guestSessionPaymentFailEvent, guestSessionPaymentStartEvent, guestSessionPaymentSuccessEvent, sessionPaymentCancelEvent, sessionPaymentFailEvent, sessionPaymentStartEvent, sessionPaymentSuccessEvent } from "analytics/instructor";
import { changeDateToUTCFormat, countryCodeMatcher, currentTimeZone } from "utils";
import useIpEffect from "hooks/useIpEffect";
import { pause } from "stories/VideoPlayer/actions";

const flowType = {
  loggeddIn: "loggeddIn",
  guest: "guest"
}
const BookNow = (props) => {
  const  { dataForMeeting, countryCode, purchaseId, selectedMonth, selectedDayName, selectedDate, selectedSlot, result,formatedSelectedDate,handleError ,handleUpdate, isReschedukedQueryParam, dataForReschedule, selectedSlotId, profileInfo} =props
  const query = useQuery()
  const _slotParam = query.get("_s")
  const _guestBook = query?.get("_l")
  // const _userId = query?.get("_u") //TODO : get id from state
  const accountDetails = useSelector((state)=> state?.registerWithEmail?.account_details) || {} // to check token is present or not 
  const { loading: initiatePaymentLoading }= useSelector((state)=>state?.payment)
  const profile = useSelector((state) => state?.profile?.profileDetails);
  const jwt = accountDetails?.jwt
  

  const dispatch = useDispatch()
  const history = useHistory();
  
  const [openModal, setOpenModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [activateTimer, setActivatTimer] = useState(false);
  const [coupon, setCoupon] = useState("")
  const [isCouponCode, setIsCouponCode] = useState(false);
  const [ couponApplied, setCouponApplied] = useState(false)
  const [phoneError, setPhoneError] = useState({ })
  const [confirmRescheduleModal, setconfirmRescheduleModal] = useState(false)
  const [formError, setFormError] = useState({})

  const [code, setCode] = useState({ code: "", countryInitials: "" });
  const isMobileValid = countryCodeMatcher(code?.code, mobileNumber)
  const _countryCodeState = useSelector(
    (state) => state?.commonParams?.countryCode
  );
  const ipInfo = useSelector((state) => state?.commonParams?.ipInfo);

  useEffect(() => {
    dispatch?.commonParams?.getCountryCodeSelect();
  }, []);

  useIpEffect(() => {
      const result = _countryCodeState?.find((items) => {
        return items?.code === ipInfo?.country;
      });
      setCode({
        code: result?.value || "+91",
        countryInitials: result?.code || "IN",
      });
  }, [openModal, ipInfo]);

  const Sessiondata = {
    day: selectedDayName,
    month: months[selectedMonth],
    date: selectedDate,
    time: selectedSlot,
  };
  const _pathname = window.location.pathname;

  // useEffect(() => { // TODO : Fix required when user refresh the page only one scenario when user is loggedin and try to apply coupon it call handleStarPurchase 
  //   if (typeof Number(purchaseId) === "number" && typeof countryCode?.toString() === "string" ) {
  //     if (purchaseId.length && countryCode?.length ) {
  //       handleStartPurchase(purchaseId, countryCode);
  //       setActivatTimer(true)
  //     }
  //   }
  // }, [purchaseId,countryCode,openModal, isCouponCode]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onClick = () => {
        resolve(true);
      };
      script.onerror = (error) => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  async function handleStartPurchase(purchaseId, countryCode) {
    const payload = {
      purchase_id: purchaseId,
      country_code: code?.countryInitials || countryCode,
    };
    setInProgress(true)
    dispatch?.payment?.requestGetPurchaseOrderInfo(payload, (status, purchaseDetails) => {
      const payload = { purchase_id: purchaseId };
      dispatch?.payment?.requestInitiateSessionPayment(payload, (obj) => {
        // if (data?.["updatePurchase"]) {
          // handleStartPurchase(data?.["updatePurchase"]?.["id"], data?.["updatePurchase"]?.["country"])
          if (status && Object.keys(obj)?.length > 0) {
            // loadScript("https://checkout.razorpay.com/v1/checkout.js"); // without calling load script its opening modal
            var options = {
              key: RAZORPAY_KEY, //RAZORPAY_KEY, //working
              amount: obj?.sub?.amount,
              name: "Lifedemy Session",
              currency: obj?.sub?.currency,
              description: `Session with ${purchaseDetails?.instructor_name || ''}`,
              order_id: obj?.["sub"]?.id,
              handler: function (response) {
                // setInProgress(true);
                setTimeout(() => {
                  setActivatTimer(true);
                }, 2000);
                dispatch?.payment?.requestSessionVerifyPayment({
                  razorpay_payment_id: response?.["razorpay_payment_id"], razorpay_order_id: obj?.["sub"]?.id, timezone: currentTimeZone}, (value, data) => {
                    if (value === true) {
                      // setInProgress(false);
                      const redurectURLToken = `${ROUTE_CONSTANTS?.BOOKING_CONFIRMED}/${data?.data?.meeting_id}?_t=${data?.data?.token}`;
                      const redurectURLWithoutToken = `${ROUTE_CONSTANTS?.BOOKING_CONFIRMED}/${data?.data?.meeting_id}`;

                      const url = data?.data?.token
                        ? redurectURLToken
                        : redurectURLWithoutToken;

                      history.push({
                        pathname: url,
                        search: data?.data?.token && `?_mn=${purchaseDetails?.customer_phone}`,
                        state: data,
                      });

                        //event success for both registered and unregistered users
                        const event_label = data?.data?.token ?  mobileNumber : (profileInfo?.mobileNumber || profileInfo?.email)
                        const eventPayload = {
                          instructor_name:result?.instructor_name,
                          event_label:event_label,
                          session_info:changeDateToUTCFormat(selectedSlot) 
                        }
                        { data?.data?.token ?  guestSessionPaymentSuccessEvent(eventPayload)   :  sessionPaymentSuccessEvent(eventPayload)}
                      // location.reload();
                      document.body.style.overflow = "visible";
                    } else {
                      setInProgress(false);
                      const payload = {
                        slot_id: _slotParam || selectedSlotId,
                      };
                      Notifier(
                        101,
                        "Payment failed,Please try after sometime "
                      );
                      dispatch?.instructor?.requestReleaseTimeSlots(
                        payload,
                        () => {
                          handleUpdate();
                        }
                      );

                       //payment cancel event for both guest and registered users
                        const event_label =  mobileNumber || (profileInfo?.mobileNumber || profileInfo?.email)
                        const eventPayload = {
                          instructor_name:result?.instructor_name,
                          event_label:event_label,
                          session_info:changeDateToUTCFormat(selectedSlot) 
                        }
                        {mobileNumber ?  guestSessionPaymentCancelEvent(eventPayload) : sessionPaymentCancelEvent(eventPayload)}


                      setTimeout(() => {
                        const pathRoute = _guestBook ? `${_pathname}?_l=${true}` : _pathname
                        history.push(pathRoute);
                      }, 2000);
                      setActivatTimer(false);
                      // setInProgress(false);
                      document.body.style.overflow = "visible";
                    }
                  }
                );
              },
              modal: {
                ondismiss: function () {
                  setInProgress(false);
                  const payload = {
                    slot_id: _slotParam || selectedSlotId,
                  };
                  dispatch?.instructor?.requestReleaseTimeSlots(payload, () => {
                    handleUpdate();

                          //payment cancel event for both guest and registered users on user dismissel of payment
                          const event_label = mobileNumber || (profileInfo?.mobileNumber || profileInfo?.email)
                          const eventPayload = {
                            instructor_name:result?.instructor_name,
                            event_label:event_label,
                            session_info:changeDateToUTCFormat(selectedSlot) 
                          }
                          {mobileNumber ?  guestSessionPaymentCancelEvent(eventPayload) : sessionPaymentCancelEvent(eventPayload)}
                          });
                  
                  setActivatTimer(false);
                  // setInProgress(false);
                  const pathRoute = _guestBook ? `${_pathname}?_l=${true}` : `${_pathname}`
                  history.push(pathRoute);
                  document.body.style.overflow = "visible";
                },
              },
              prefill: {
                name: obj?.customer_name || '',
                email: purchaseDetails?.customer_email || '',
                contact: purchaseDetails?.customer_phone,
              },
              timeout: 300, // 5 minutes
              notes: {
                address: "-",
                user_flow: "Session Flow",
                purchase_details: JSON.stringify({
                  instructor_name: obj.instructor_name,
                  instructor_id: parseInt(obj?.timeSlot?.instructor_id),
                }),
              },
              theme: {
                color: "#0E896A",
              },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
            paymentObject.on("payment.failed", function (response) {
                setInProgress(false);
                //payment fail event for both guest and registered users on user dismissel of payment
                          const event_label = mobileNumber || (profileInfo?.mobileNumber || profileInfo?.email)
                          const eventPayload = {
                            instructor_name:result?.instructor_name,
                            event_label:event_label,
                            session_info:changeDateToUTCFormat(selectedSlot) 
                          }
                          if(response?.error){
                            {mobileNumber ?  guestSessionPaymentFailEvent(eventPayload) : sessionPaymentFailEvent(eventPayload)}
                          }
                        });
          } else {
            Notifier(100, obj?.message);
            setTimeout(() => {
              history.push(_pathname);
            }, 3000);
          }
        // }
      });
    });
  }
  const runValidation = () => {
    if (dataForMeeting?.["slot_id"]?.length === 0) {
      handleError("Please select a slot");
      return
    }
  };

  
  // Guest Payment flow 
  
  // const initiateSessioPayment=(paramsId)=>{
  //   console.log('---initiateSessioPayment')
  //       const payload = { purchase_id: paramsId ? paramsId : purchaseId };
  //       dispatch?.payment?.requestInitiateSessionPayment(payload, (data) => {
  //         if(data?.["updatePurchase"]){
  //           handleStartPurchase(data?.["updatePurchase"]?.["id"], data?.["updatePurchase"]?.["country"])
  //         }
  //       })
  // }
  
  const clearErrorMessageAfterSomeTime =(timer = 2000,...rest)=>{
    setTimeout(()=>{
      setFormError({ }),
      rest
    },timer)
  }

  const applyCouponCode = (payload)=> {
      dispatch?.payment?.requestApplySessionCouponCode(payload,(status, data)=>{
        if(status === 200){
         if(data?.isApplied) {
           setCouponApplied(data?.isApplied)
         }else{
          setFormError({ guestCoupon: data?.success})
          setCouponApplied(false)
          setCoupon("")
          clearErrorMessageAfterSomeTime()
         }
        }else{
          setFormError({ guestCoupon: data})
          clearErrorMessageAfterSomeTime()
        }
      })
  }

  const handlePurchaseStart = (dataForMeeting, isCoupon=false )=>{
    // if(purchaseId) {
    //     applyCouponCode({ coupon_code: couponData?.coupon , purchase_id: purchaseId })
    // }else{
         dispatch.payment.requestInitiateSessionPurchase( dataForMeeting, (status, data, message) => {
           //initiate payment event triggered
            const eventPayload = {
              instructor_name:result?.instructor_name,
              event_label:mobileNumber || (profileInfo?.mobileNumber || profileInfo?.email),
              session_info:changeDateToUTCFormat(selectedSlot) 
            }
          {(profileInfo?.mobileNumber || profileInfo?.email) ? sessionPaymentStartEvent(eventPayload) : guestSessionPaymentStartEvent(eventPayload)}
          if(data?.id) {
            const _routePath = _guestBook ? `${_pathname}?_p=${data?.["id"]}&_c=${code?.["countryInitials"]}&_s=${data?.["slot_id"]}&_d=${selectedDate}&_l=${true}` : `${_pathname}?_p=${data?.["id"]}&_c=${code?.["countryInitials"]}&_s=${data?.["slot_id"]}&_d=${selectedDate}`
            //  const routePath = `${_pathname}?_p=${data?.["id"]}&_c=${code?.["countryInitials"]}&_s=${data?.["slot_id"]}&_d=${selectedDate}`
             history.push(_routePath);
            if(isCoupon && coupon?.length){
              applyCouponCode({ coupon_code: coupon , purchase_id: data?.["id"] })
            }else{
              handleStartPurchase(data?.id, data?.country)
            }
        }else{
            Notifier(status, message)
            setInProgress(false)
          }
        });
    // }
  }
  // Guest Payment flow End 

  //handle book now
  const handleClick = async (params) => {
    runValidation();
    pause()
    if (dataForMeeting?.["slot_id"]) {
      if (dataForMeeting?.["user_id"] && jwt && flowType?.loggeddIn !== params ) {
        handlePurchaseStart(dataForMeeting)
      } else {
        !jwt && params !== flowType.guest ?
        setOpenModal(true)
        :
        setIsCouponCode(true)        
      }
    }
  };

  let phoneErrors = {}

  const phoneNumberValidation =()=>{
    if(mobileNumber?.length === 0){
      phoneErrors.message = "Enter phone number"
    }
    else if(!isMobileValid){
      phoneErrors.message = "Enter a valid phone number"
    }
  }
  const handleContinuePurchase =(isCoupon=false)=>{
    phoneNumberValidation();
    if(Object.keys(phoneErrors)?.length === 0){
        delete dataForMeeting.user_id
        const _mobile_number = `${code.code}${mobileNumber}`
        const country = code.countryInitials
        const payload = {
          ...dataForMeeting,
          mobile_number:  _mobile_number,
          country
        }
        if(!purchaseId){
          handlePurchaseStart(payload, isCoupon)
        }else if(purchaseId && !isCoupon){
          handleStartPurchase(purchaseId, country)
        }else{
          //
        }
        setOpenModal(false);
    }else{
      if (jwt && purchaseId) {
        const country = code.countryInitials;
        handleStartPurchase(purchaseId, country);
      } else if (jwt && !purchaseId && isCoupon) {
        handlePurchaseStart(dataForMeeting, false);
      } else if (jwt && !purchaseId && !isCoupon) {
        handlePurchaseStart(dataForMeeting, false);
      }
      setPhoneError(phoneErrors)
      clearErrorMessageAfterSomeTime()
    }
  }


const openRescheduleModal = async () =>{
  runValidation()
  if (selectedSlotId) {
    if (profile?.id) {
      const payloadForHoldSlot = {
        slot_id:selectedSlotId,
        user_id:profile?.id
      }
      dispatch?.instructor?.requestHoldSlot(payloadForHoldSlot, ()=>{})  
      setconfirmRescheduleModal(true)
    }
  }
}
const handleRescheduleCancel =()=>{
  const payload = {
    slot_id: selectedSlotId
  }
  dispatch?.instructor?.requestReleaseTimeSlots(payload,()=>{
    setconfirmRescheduleModal(false)
  })
}

const handleCouponChange =(e)=>{
  setCoupon(e.target.value)
}

const couponApplying = false

const handleCouponApply =()=>{
   if(mobileNumber.length === 0 && !jwt){
    setFormError({ guestCoupon :"Mobile Number required"})
  }
  else if(coupon.length === 0){
    setFormError({ guestCoupon :"Coupon code required"})
  }
  else{
    if(purchaseId && coupon?.length){
      applyCouponCode({ coupon_code: coupon , purchase_id: purchaseId })
    }
    else if(jwt){
      handlePurchaseStart(dataForMeeting, true)     
    }
    else{
      handleContinuePurchase(true)
    }
  }
  setTimeout(()=>{
    setFormError({ })
 },2000)
}
const handleShowCoupon = ()=>{}


 const couponCodeUI =(handleHaveCouponClick)=> {
  return <div onClick={handleHaveCouponClick} className="coupon--data text-greeny mt-[10px] cursor-pointer underline decoration-[#0E896A] md inline-block ">
            I have a coupon
        </div>
  }

  const handleCouponRemove =()=>{
    applyCouponCode({coupon_code: "",  purchase_id: purchaseId })
  }
  return (
    <div className={"text-center mt-[.3rem] flex flex-col"}>
      {(inProgress || initiatePaymentLoading) ? <Loader/> : '' }
      {/* {initiatePaymentLoading && <Loader/>} */}
     {isReschedukedQueryParam ? <FormButton
        onClick={openRescheduleModal}
        className={`w-full rounded-lg h-[55px] max-w-[300px] mr-[auto] ml-[auto] text-[24px] font-bold text-white`}
      >
        Reschedule
      </FormButton> : 
      <>
          <FormButton
                  onClick={()=>handleClick()}
                  className={`w-full rounded-lg h-[55px] max-w-[300px] mr-[auto] ml-[auto] text-[24px] font-bold text-white`}
          >Book Now
          </FormButton>
          {couponCodeUI(()=>handleClick( jwt ? flowType.loggeddIn : flowType.guest))}
      </>
      }
      { confirmRescheduleModal && <ConfirmReschedulemodal handleClose={ handleRescheduleCancel} show={confirmRescheduleModal} dataForReschedule={dataForReschedule} selectedSlotId={selectedSlotId} runValidation={runValidation} dataForMeeting={dataForMeeting} handleError={handleError} formatedSelectedDate={formatedSelectedDate}/>}
      <Modal
        show={openModal || isCouponCode}
        closable={true}
        bodyStyle={{
          padding: "0px",
          backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
          boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.4)",
          borderRadius: "5px",
        }}
        onCancel={() => {
          setIsCouponCode(false)
          setOpenModal(false)
          setMobileNumber("")
          setCoupon("")
          setCouponApplied(false)
          const payload = {
            slot_id: _slotParam || selectedSlotId,
          };
          dispatch?.payment?.setCouponListLoading(false);
          payload?.slot_id && dispatch?.instructor?.requestReleaseTimeSlots(payload,()=>{
            const path = _guestBook ? `${ROUTE_CONSTANTS.INSTRUCTOR_CALENDER}/${result?.instructor_id}?_l=${true}` : `${ROUTE_CONSTANTS.INSTRUCTORINFORMATION}/${result?.instructor_id}`
            history?.push(path)
          });
        }}
        wrapClassName="bg-[#272d3533] backdrop-blur-sm"
        width={400}
        height={800}
        maskStyle={{
          backgroundColor: COLOR_CONSTANTS.MODAL_COLOR,
          opacity: "0.8",
        }}
        footer={null}
        maskClosable={false}
      >
        <div className=" my-[19%] px-[5%] md:px-[15%]  m-auto">
          <div className="py-10 rounded-md shadow-lg relative">
            {/* <Image
              src={imagePath("/lifedemyIcon.png")}
              icon={true}
              alt="logo"
              className="absolute h-12 top-[-1.6rem] left-[50%] "
              style={{ transform: "translate(-50%,0%)" }}
            /> */}
            <div className="mb-6 text-center">
               <Heading style={{fontSize:"16px"}}> {!jwt ?"Please enter your phone number to continue" : "Enter Coupon code"}</Heading>
            </div>
            {!jwt && 
            <>
            <div className="flex">
              <CustomSelector
                items={_countryCodeState}
                value={code}
                handleChange={(code) =>
                  setCode({ code: code?.value, countryInitials: code.code })
                }
                className="p-2 border-2 border-gray-600 bg-logoColor rounded-lg auth-input-text-style mr-3"
              />
              <LDInput
                name="phone"
                type="text"
                value={mobileNumber}
                maxlength="15"
                inputMode="numeric"
                // onClick={()=>signupWithPhoneInputClickEvent()}
                placeholder="Phone"
                pattern="[0-9]*"
                onChange={(e)=>setMobileNumber((v) => (e.target.validity.valid ? e.target.value : v))}
                className="px-4 py-2 w-full rounded-lg bg-logoColor border-2 outline-none  auth-input-text-style border-gray-600"
              />
              
            </div>
            <div className="text-remove text-md mt-2 font-medium">{phoneError?.message && phoneError?.message}</div>
            </>
          }

           {/******** Apply Coupon Start ***************/} 
      
           {isCouponCode &&
           <>
          <div className="coupon--container flex flex-col md:flex-row mt-[1rem]">
            <div className="input--coupon md:basis-3/4 max-[767px]:basis-full max-[767px]:mb-[1rem] mb-[.5rem] md:mb-[0rem] w-full">
              <LDInput
                name="guestCoupon"
                type="text"
                placeholder="Please enter coupon code"
                value={coupon}
                onChange={handleCouponChange}
                className="px-4 py-2 w-full rounded-lg md:w-[95%] max-[767px]:w-full  bg-logoColor border-2 outline-none  auth-input-text-style border-gray-600"
                disabled={couponApplied}
              />
              <p className="text-remove mt-2">{formError?.guestCoupon?.length > 0 && formError?.guestCoupon}</p>
            </div>
            <FormButton
              type="apply"
              className="bg-[#262C35] md:basis-1/4 auth-button-text-style h-[2.4rem] max-[767px]:basis-full w-full p-[.6rem] rounded md:hover:bg-transparent"
              style={{ backgroundColor: "", color: couponApplied ? "#FF7373" : "#0E896A" }}
              onClick={ couponApplied ? ()=>handleCouponRemove("Remove") : ()=>handleCouponApply("Apply") }
              loading={couponApplying}
            >
              {couponApplying ? '' : couponApplied ? 'Remove' : 'Apply'}
            </FormButton>
          </div>
            {couponApplied && (
              <span onClick={handleShowCoupon} className="text-greeny text-xs">
                Coupon applied successfully
              </span>
            )} 
           </>
        }

           {/******** Apply Coupon End ***************/} 


            <FormButton
              type="submit"
              className="bg-greeny auth-button-text-style w-full  px-3 rounded mt-5"
              style={{ backgroundColor: COLOR_CONSTANTS.GREEN, color: "white" }}
              onClick={()=> { handleContinuePurchase(false) } }
            >
              Continue
            </FormButton>
            {!isCouponCode &&
            couponCodeUI(()=>setIsCouponCode(true))}
            <div className="mt-4">
              <div className="flex items-center">
              </div>
              <div className="flex items-center">
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookNow;
