import DownloadApp from "components/DownloadApp";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import React, { useState } from "react";
import LDImage from "stories/Image";
import { imagePath } from "utils/assetHelper";
import { useHistory } from 'react-router-dom';
import { SESSION_STATUS, UTCTimeConverter, formatDate, reverseDate, sessionStartTime15MinsAfter, sessionStartTime15MinsBefore } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { EXTERNAL_ROUTE_LINKS } from "constants/Apis";
import { Notifier } from "components/Notifier";
import { countDownTimer } from "utils/countDownTimer";
import SessionConcent from "components/Card/MysessionCard/SessionConcent";

const MysessionAlert = (props) => {
  const { instructors, time_slot, id} = props
  const isSmallScreen = useMedia("(max-width:1024px)");
  const [isAlertModal, setIsAlertModal] = useState(false);
  const [isJoinConcent, setIsJoinConcent] = useState(false)
  const history = useHistory();
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => state?.profile?.profileDetails); // to check first name last name are present or not

  const Header = ({ children, className }) => {
    return <h1 className={className}>{children}</h1>;
  };
  const SessionButton = ({ children, className, onClick, disabled }) => {
    return (
      <button className={className} onClick={onClick} disabled={disabled}>
        {children}
      </button>
    );
  };  

  const joinMeetingForUpcommingSession = (payload) => {
    dispatch?.instructor?.crateMeetingSignatureToken(
      payload,
      (status, data) => {
        if (status) {
          window.open(
            `${EXTERNAL_ROUTE_LINKS?.LIFEDEMY_MEETING}?token=${data.token_signature}&_u=${profileInfo?.id}`,
            "_self"
          );
        } else {
          Notifier("101", data.message);
        }
      }
    );
  }
  const handleJoinSession =()=>{
    if(isSmallScreen){
      isSmallScreen && setIsAlertModal(true)
    } 
    else{
      const payload = {
        meeting_id: id, // NOTE :not sure about meeting ID
        userType: 2,
      };
      joinMeetingForUpcommingSession(payload)  
    }
  }

  
        //session startTime and endTime 
      const sessionStartTime = new Date(time_slot?.start_time)
      const sessionEndTime = new Date(time_slot?.end_time)
      const _sessionStartTime = sessionStartTime15MinsBefore(sessionStartTime)
      const _sessionEndTime = sessionStartTime15MinsAfter(sessionEndTime)
      const _compareSessionStartAndEndTime =new Date() >= new Date(_sessionStartTime) && new Date() <= new Date(_sessionEndTime)

      //countdown timer
      // const _ishourLessThanone = countDownTimer(time_slot?.start_time)?.[0]
      const _isMinutesLessThan15 = countDownTimer(time_slot?.start_time)?.[1] 
      const _getSeconds = countDownTimer(time_slot?.start_time)?.[2]
      const _today = new Date()
      const today_date = formatDate(_today)
      const session_date = formatDate(new Date(time_slot?.start_time))
      const _timerSetFortoday = today_date === session_date && _compareSessionStartAndEndTime
  

      //for disabling join now until session starts in 10mins
      const _isDisabled = new Date() < new Date(sessionStartTime15MinsBefore(time_slot?.start_time))

      //timer function
      const startTimer = (_isMinutesLessThan15, _getSeconds)=>{
        if(_isMinutesLessThan15>0){
          return `Starts in ${_isMinutesLessThan15}m ${_getSeconds}s`
        }else if(_isMinutesLessThan15<=0){
          return `Starts in ${_getSeconds}s`
        }
      }
  return (
    <>
      {instructors?.instructor_name && instructors?.instructor_name.length > 0 && (
        <div className="bg-secondary rounded-sm lg:rounded-lg lg:mr-[3rem] lg:ml-[4rem] my-[1.125rem]">
          <div className="join---session flex flex-col md:flex-row md:justify-between items-center mr-[1.5rem] ml-[1rem] p-[1px]">
            <Header className=" hidden md:flex items-center text-white font-bold text-[12px] py-[0.3rem]">
              <div className=" rotate-[-45deg]">
                <LDImage src={imagePath("/Home/notificationWithRed.svg")} />
              </div>
              <div>
                You have an upcoming session with {instructors?.instructor_name}
                <span className="sessionAlert text-[#a8abae] font-semibold text-[11px]">
                {` ( ${formatDate(time_slot?.start_time)}, ${UTCTimeConverter(time_slot?.start_time)} )`}
                </span>{" "}
              </div>
            </Header>
            <Header className="flex flex-col text-[14px] mt-2 text-white font-bold md:hidden">
              Upcoming session with {instructors?.instructor_name}
              <span className="text-[#a8abae] text-center font-semibold text-[11px]">
              {` ( ${formatDate(time_slot?.start_time)}, ${UTCTimeConverter(time_slot?.start_time)} )`}
              </span>{" "}
            </Header>
            <div className="flex items-center gap-[1rem]">
           { _isMinutesLessThan15 ? <SessionButton onClick={!isSmallScreen ? ()=>setIsJoinConcent(true) : () => setIsAlertModal(true)} className={`flex bg-greeny text-white py-[8px] px-[15px] mt-1 mb-2 md:mt-0 md:mb-0 text-[11px] font-bold rounded-lg ${_isDisabled && "bg-transparent text-[12px]"}`} 
              disabled={_isDisabled}>
                    


               {/* ******************* DONOT REMOVE ******************** */}
               {/* {!_isDisabled && _isMinutesLessThan15 <0 && "Join Now"} */}

              {/* if session time is before 15min */}
              {/* {((_timerSetFortoday && _isMinutesLessThan15 >=0 ) && startTimer(_isMinutesLessThan15, _getSeconds)) || (_isDisabled  && "Yet to start")} */}
              {_isDisabled  ? "Yet to start" : "Join now"} 
              </SessionButton> : ""}
              {/* <SessionButton className="md:hidden bg-greeny text-white py-[5px] px-[15px] mt-1 mb-2 text-[11px] font-bold rounded-lg">
                JOIN
              </SessionButton> */}
              {/* <Header className="hidden md:block text-[#bec0c2] text-[12px] font-bold">
            Reschedule /Cancel
          </Header> */}
            </div>
          </div>
        </div>
      )} 
                {isJoinConcent && <SessionConcent show={isJoinConcent} handleClose={()=>setIsJoinConcent(false)} onOk={handleJoinSession} />}

      {isAlertModal && (
            <DownloadApp  handleClose={() => setIsAlertModal(false)} show={isAlertModal} message={"Get Lifedemy App to Join the Session Download the App Now"}/>
        )}
    </>
  );
};

export default MysessionAlert;
