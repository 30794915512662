export const HOME_LOAD_START = "Home_Load_Start";
export const HOME_LOAD_END = "Home_Load_End";
export const HOME_FILTER_HEADING_CLICK = "Home_Filter_Heading_Click"; 
export const HOME_FILTER_OPTIONS_CLICK = "Home_Filter_Options_Click"
export const HOME_FILTER_CLEAR_ALL_CLICK = "Home_Filter_Clear_All_Click"
export const HOME_FILTER_APPLY_CLICK  = "Home_Filter_Apply_Click"
export const HOME_BANNER_CLICK = "Home_Banner_Click"
export const HOME_CONTINUE_WATCHING_CARD_CLICK = "Home_Continue_Watching_Card_Click"
export const HOME_WATCH_NOW_CARD_CLICK = "Home_Watch_Now_Card_Click"
export const HOME_WATCH_NOW_WISHLIST_CLICK = "Home_Watch_Now_Wishlist_Click"
export const HOME_CONTINUE_WATCHING_SEE_ALL = "Home_Continue_Watching_See_All_Click"
export const HOME_CONTINUE_WATCHING_SEE_LESS = "Home_Continue_Watching_See_Less_Click"