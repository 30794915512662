
import { useEffect, useState } from 'react';
import useMedia from './deviceHelper/useMedia';
import useGetWindowSize from './useGetWindowSize';

export const useOrientation = () => {
  const [result,setResult] = useState(false)
  const { innerWidth,innerHeight} =useGetWindowSize()
  const minHeight = 550

  useEffect(()=>{
    if(window.screen.height < minHeight)
      setResult(true)
    else{
      setResult(false)
    }
  },[window.screen.height])
  return result
}

