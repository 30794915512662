import { LDParagraph } from 'stories/Typography';
import LoadingSpinner from './LoadingSpinner';

const wrapperStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    // transform: "translate(-50%, -10%)",
    // maxHeight: "140vh",
    // minHeight: "100vh",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "black",
    opacity: 0.8
};

const Loader = ({msg="Please wait..."}) => {
    return (
        <div style={wrapperStyle} className='max-h-screen'>
            <div className='flex justify-center items-center'>
                <LoadingSpinner />
                <LDParagraph className="mt-2 text-4">{msg}</LDParagraph>
            </div>
        </div>
    )
};

export default Loader;