import Image from "components/Image";
import { COLOR_CONSTANTS } from "constants/color.constants";
import React, { useState , useEffect } from "react";
import { LDInput, LDSelect } from "stories/Form";
import FormButton from "stories/FormButton";
import { countryCodeMatcher, EmailCheckRegEx, IsPhoneNumberValid } from "utils";
import { imagePath } from "utils/assetHelper";
import { Form } from "antd";
import CoursePurchasedModal from "./CoursePurchasedModal";
import CustomSelector from "components/CustomSelector";
import { useDispatch , useSelector} from "react-redux";
import { setItemToStorage } from "utils/storage.helper";
import { useQuery } from "hooks/useQuery";
import CouponModal from "./CouponModal";

const UserDetailsForm = (props) => {
  const {handleProcessPayment, purchasedErr, setPurchasedErr, phone, setPhone, coupon, setCoupon, couponErr = false, closeCouponErrModal, purchaseInProgress, couponApplying, couponApplied=false, email ,setEmail } = props;
  const [formError,setFormError] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [code, setCode]=useState({code:"",countryInitials:""})
  const ipInfo = useSelector((state)=> state?.commonParams?.ipInfo)

  const [showCoupon,setShowCoupon] = useState(false)
  const [invalidCoupon,setInvalidCoupon] = useState(false)

  const isMobileValid = countryCodeMatcher(code?.code, phone)

  let error = {};
  const countryCode = useSelector((state)=> state?.commonParams?.countryCode)

  const dispatch = useDispatch()

  const query = useQuery();
  const region = query.get("region");

  useEffect(()=>{
    dispatch?.commonParams?.getCountryCodeSelect()
  },[])

  // START ****  Auto select Country depending on the IP Address 

  useEffect(()=>{
    let country = '';
    if(region?.length){
      country = region
    }else if(!region?.length && countryCode?.length > 0 && ipInfo?.country){
      country = ipInfo?.country
    }
    if(country?.length){
      const result = countryCode?.find((items)=>{
        return items?.code === country
      })
      setCode({
        code : result?.value || "+91",
        countryInitials:result?.code || "IN"
      })
    }
  },[ipInfo?.country, countryCode?.length,region])


  const runValidator=()=>{
        if(email?.length === 0){
          error.email = "Email is required "
        }
        else if(!EmailCheckRegEx(email)){
          error.email = "Enter valid email"
        }
        if(phone?.length === 0){
         return error.phone = "Phone is required"
        }
        const validatePhoneNumber = `${code?.code}${phone}`
        // const result = IsPhoneNumberValid(validatePhoneNumber);
        if(!isMobileValid){
          error.phone = "Enter a valid phone number"
        }
  }

  const handlePurchase = (coupon=false) => {
      setPurchasedErr(false)
      runValidator()
     if(Object.keys(error)?.length === 0 ){
       setFormError({})
       const payload ={
          mobileNumber:`${code?.code}${phone?.trim()}`,
          country: code?.countryInitials,
          email: email
       }
       handleProcessPayment(payload, coupon)
     }
     else{
       setFormError(error) 
     }
  };
   const handleChange=(e)=>{
     setIsModalOpen(true)
     const { name, value,validity} = e.target
     if(name === "phone"){
       setPhone((v) => (validity.valid ? value : v))
     }
     if(name === "email"){
      setEmail((v)=>(validity.valid ? value : v))
     }
   }

   const handleModalToggle = (value) => {
    if (!value) {
      setPhone("")
    }
    setIsModalOpen(value)
   }

  //  handle guest coupon script

  const handleCoupnChange = (e) =>{
    setCoupon(e.target.value)
  }
  const handleShowCoupon =()=>{
    setShowCoupon(!showCoupon)
  }

  const couponValidation = () =>{
    if(coupon?.length === 0){
      return error.guestCoupon = "Please enter coupon code"
     }
  }

  // const validateCoupon = () =>{
  //   if(!guestCoupon == "NEW2024"){
  //         setInvalidCoupon(!invalidCoupon)
  //   }
  // }

   const handleCouponApply = () =>{
    couponValidation()
    // validateCoupon()
    setInvalidCoupon(!invalidCoupon)
    if(Object.keys(error)?.length === 0 ){
      // setGuestCoupon("")
      handlePurchase(true);
    }
    else{
      setFormError(error)
    }
   }

  //  handle guest coupon script

  return (
    <div className="">
      <div className="flex">
        
        <CustomSelector
          items={countryCode}
          value={code}
          handleChange={(code)=>setCode({code:code?.value, countryInitials:code.code })}
          className="p-2 border-2 border-gray-600 bg-logoColor rounded-lg auth-input-text-style mr-3"
        />

        {/* <Form.Item name="countryCode" noStyle>
            <LDSelect items={[{ value: "+91" }]} showArrow={false} disabled={true} value={code} handleChange={()=>setCode("+91")} 
            className="p-2 border-2 border-gray-600 bg-logoColor rounded-lg auth-input-text-style mr-3"
             />
        </Form.Item> */}

        <LDInput
          name="phone"
          type="text"
          value={isModalOpen ? phone : ""}
          maxlength="15"
          inputMode="numeric"
          // onClick={()=>signupWithPhoneInputClickEvent()}
          placeholder="Phone"
          pattern="[0-9]*"
          onChange={(e) => handleChange(e)}
          className="px-4 py-2 w-full rounded-lg bg-logoColor border-2 outline-none  auth-input-text-style border-gray-600"
        />
      </div>
      <p className="text-remove mt-2">{formError?.phone?.length > 0  && formError?.phone }</p>
      {purchasedErr && (
        <CoursePurchasedModal phoneNumber={phone} handleClose={handleModalToggle} />
      )}
        <LDInput
          name="email"
          type="text"
          value={isModalOpen ? email : ""}
          placeholder="Email"
          onChange={(e) => handleChange(e)}
          className="px-4 py-2 w-full rounded-lg bg-logoColor border-2 outline-none  auth-input-text-style border-gray-600"
        />
        <p className="text-remove mt-2">{formError?.email?.length > 0  && formError?.email }</p>
      {showCoupon &&
        <div className="coupon--container flex flex-wrap mt-[1rem]">
          <div className="input--coupon md:basis-3/4 max-[767px]:basis-full max-[767px]:mb-[1rem] mb-[.5rem] md:mb-[0rem] w-full">
            <LDInput
              name="guestCoupon"
              type="text"
              placeholder="Please enter coupon code"
              value={coupon}
              onChange={handleCoupnChange}
              className="px-4 py-2 w-full rounded-lg md:w-[95%] max-[767px]:w-full  bg-logoColor border-2 outline-none  auth-input-text-style border-gray-600"
              disabled={couponApplied}
            />
            <p className="text-remove mt-2">{formError?.guestCoupon?.length > 0 && formError?.guestCoupon}</p>
          </div>
          <FormButton
            type="apply"
            className="bg-greeny md:basis-1/4 auth-button-text-style h-[2.4rem] max-[767px]:basis-full w-full  p-[.6rem] rounded mb-3"
            style={{ backgroundColor: "#2E313F", color: couponApplied ? "#FF7373" : "#0E896A" }}
            onClick={handleCouponApply}
            loading={couponApplying}
          >
            {couponApplying ? '' : couponApplied ? 'Remove' : 'Apply'}
          </FormButton>
          {couponApplied && (
            <span onClick={handleShowCoupon} className="text-greeny text-xs">
              Coupon applied successfully
            </span>
          )} 
        </div>
      }
        {couponErr &&        
         <div>
          <CouponModal coupon={coupon} show={true} handleClose={closeCouponErrModal} handleProceed={() => { handlePurchase(false) }} />
          </div> 
        }
      <FormButton
        type="submit"
        className="bg-greeny auth-button-text-style w-full  px-3 rounded"
        style={{ backgroundColor: COLOR_CONSTANTS.GREEN, color: "white" }}
        onClick={() => { handlePurchase(false) }}
        loading={purchaseInProgress}
      >
        Continue Purchase
      </FormButton>
      {!couponApplied && (
        <div onClick={handleShowCoupon} className="coupon--data text-greeny mt-[10px] cursor-pointer underline decoration-[#0E896A] md inline-block ">
          I have a coupon
        </div>
      )}    
      <div className="mt-6">
         <h1 className=' text-white my-2'> Contact Us:</h1>
         <div className="flex items-center">
            <Image src={imagePath("/Authsvgs/mail.svg")}className="h-3 w-3"/>
            <p className="text-white ml-3">         
            support@lifedemy.in 
            </p>
         </div>
         <div className="flex items-center">
             <Image 
                src={imagePath("/Authsvgs/phone.svg")}             
                className="h-3 w-3"
              />
            <p className="text-white ml-3">
            +91 73050 17565
            </p> 
         </div>
      </div>
    </div>
  );
};

export default UserDetailsForm;
