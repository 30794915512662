export const PROFILE_EDIT_CLICK = "Profile_Pic_Edit_Click";
export const PROFILE_PERSONAL_DETAILS_EDIT_CLICK =
  "Profile_Personal_Details_Edit_Click";
export const PROFILE_PERSONAL_DETAILS_INPUT_CLICK =
  "Profile_Personal_Details_Input_Click";
export const PROFILE_PERSONAL_DETAILS_DOB_CLICK =
  "Profile_Personal_Details_Dob_Click";
export const PROFILE_PERSONAL_DETAILS_SAVE_BTN_CLICK =
  "Profile_Personal_Details_Save_Btn_Click";
export const PROFILE_EMAIL_VERIFY_CLICK = "Profile_Email_Verify_Click";
export const PROFILE_EMAIL_VERIFY_BTN_CLICK = "Profile_Email_Verify_Btn_Click";
export const PROFILE_EMAIL_DONE_BTN_CLICK = "Profile_Email_Done_Btn_Click";

export const PROFILE_PHONE_VERIFY_ICON_CLICK =
  "Profile_Phone_Verify_Icon_Click";
export const PROFILE_PHONE_CANCEL_BTN_CLICK = "Profile_Phone_Cancel_Btn_Click";
export const PROFILE_PHONE_INPUT_CLICK = "Profile_Phone_Input_Click";
export const PROFILE_PHONE_SELECT_COUNTRY_CODE =
  "Profile_Phone_Select_Country_Code";

export const PROFILE_PHONE_GENERATE_OTP_BTN_CLICK =
  "Profile_Phone_Generate_Otp_Btn_Click";
export const PROFILE_PHONE_OTP_INPUT_CLICK = "Profile_Phone_Otp_Input_Click";
export const PROFILE_PHONE_RESED_OTP_CLICK = "Profile_Phone_Resend_Otp_Click";
export const PROFILE_PHONE_OTP_VERIFY_BTN_CLICK =
  "Profile_Phone_Otp_Verify_Btn_Click";
export const PROFILE_LOGOUT_BTN_CLICK= "Profile_Logout_Btn_Click"