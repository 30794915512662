import { clearNotification } from "components/Notifier";
import { useEffect } from "react";

import { useLocation } from "react-router-dom";

export default function useScrollTop() {
  const location = useLocation();
  useEffect(() => {
    clearNotification()
    window.scrollTo(0, 0);
  }, [location]);
}
