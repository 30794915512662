import React, { useEffect, useState } from "react";
import FormButton from "stories/FormButton";
import FormInput from "stories/FormInput";
import { Form } from "antd";
import { LDInput, LDSelect } from "stories/Form";
import { phoneCodes } from "constants/app.constants";
import { useDispatch, useSelector } from "react-redux";
import { LDSpan } from "stories/Typography";
import { referralCountryCodeSelectEvent, referralFriendButtonClickEvent, referralReferAFriendInputClick } from "analytics/referral";
import { EmailCheckRegEx, IsPhoneNumberValid } from "utils";
import { Notifier } from '../../../components/Notifier/index';
import { COLOR_CONSTANTS } from "constants/color.constants";
import useMedia from "hooks/deviceHelper/useMedia";
import CustomSelector from "components/CustomSelector";

const ReferOptions = () => {
  // const emailResponse = useSelector((state)=>state?.referral?.email)
  // const phoneResponse = useSelector ((state) => state?.referral?.phone)
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("")

  const [msg,setMsg]=useState("")
  const [phoneMsg,setPhoneMsg]=useState("")
  const [countryCode,setCountryCode] = useState("+91")
  const isMobile = useMedia()
  const dispatch = useDispatch()
  const countryCodeV1 = useSelector((state)=> state?.commonParams?.countryCode)
  useEffect(()=>{
    dispatch?.commonParams?.getCountryCodeSelect()
  },[])

  const handleSubmit = ()=>{
    if( email && EmailCheckRegEx(email)){
      const params = {event_label : `Referral - ${email}`}
      referralFriendButtonClickEvent(params)
      dispatch?.referral?.requestInviteWithEmail(email,(data)=>{
        if(data?.isInvitedNow){
          setEmail("");
        }
        setMsg(data)
        setTimeout(()=>{
          setMsg("")
        },3000)
      }) 
    }
    else{
      Notifier("DELETED","Please enter a valid email address")
    }
  }
  const handlePhoneSubmit=()=>{
    if(phone?.length !== 0){
      const validatePhoneNumber = `${countryCode}${phone}`;
      const result = IsPhoneNumberValid(validatePhoneNumber);
      if (result) {
      const params = {event_label : `Referral - ${phone}`}
      referralFriendButtonClickEvent(params)
      const phoneNumber =`${countryCode}${phone}`
      dispatch?.referral?.requestInviteWithPhone(phoneNumber,(data)=>{
        if(data?.isInvitedNow){
          setPhone("");
        }
        setPhoneMsg(data)  
        setTimeout(()=>{
          setPhoneMsg("")
        },3000)
        }) 
      }
      else{
        Notifier("DELETED","Please enter a valid phone number")
      }
    }else{
      Notifier("DELETED","Please enter a valid phone number")
    }
  }
  const handleChange = (e) => {
    const attr = e.target.value;
    setEmail(attr)
  };

  const handleEventFire=(value)=>{
    const params = {event_label : `Referral -${value}`}
    referralReferAFriendInputClick(params)
  }

  return (
    <div>
      <div className="flex ">
        <FormInput
          type="email"
          onClick={()=>handleEventFire("email")}
          className={`${ msg?.isInvitedNow === false && "border-2  border-remove "} border-2 border-formBorder lg:bg-sidebarSelected bg-logoColor h-10 text-white text-md  outline-none  p-2 rounded-lg w-full mr-3`}
          placeholder="Refer using Email"
          value={email}
          onChange={handleChange}
        />
        <FormButton onClick={handleSubmit} 
        className={`hover:bg-sidebarSelected h-10 rounded-lg 
        hover:text-white text-white bg-logoColor lg:bg-sidebarSelected`}
        backgroundColor={isMobile ? COLOR_CONSTANTS.MODAL_COLOR : COLOR_CONSTANTS.SIDEBAR_SELECTED}
        color={COLOR_CONSTANTS.WHITE} 
        disabled={!email}
        >
          Refer
        </FormButton>
      </div>
      {Object.keys(msg).length > 0 && <LDSpan className={`${ msg?.isInvitedNow ? "text-greeny":"error-color-style" }`}>{msg?.statusMessage}</LDSpan>}
      <div>
        {/* <Form
          className=" mt-4 flex items-center"
          onFinish={(values) => onFormSubmit(values)}
        > */}
        {/* <CustomSelector
          items={countryCodeV1}
          handleChange={(code)=> setCountryCode(code) }
          className="w-1/7 p-2 outline-none border-2 border-formBorder lg:bg-sidebarSelected sm:bg-logoColor xs:bg-logoColor rounded-lg text-white" 
        />          */}
          {/* <Form.Item name="countryCode" noStyle>
            <LDSelect 
                disabled= {true}
                showArrow={false}
                items={[{ value: "+91" }]}
                //  items={phoneCodes} 
                 onClick={()=>referralCountryCodeSelectEvent()} 
                 handleChange={(value)=>{
                    setCountryCode(value) 
                    const params = {event_label:`Referral - Phone - ${value}`}
                    referralCountryCodeSelectEvent(params)
                  }}
                 className="w-1/7 p-2 outline-none border-2 border-formBorder lg:bg-sidebarSelected sm:bg-logoColor xs:bg-logoColor rounded-lg text-white" />
          </Form.Item> */}
          {/* <Form.Item
            name="phone"
            rules={[{ required: true, message: "Required!" }]}
            noStyle
          >
            <LDInput
              name="phone"
              placeholder="Refer using Phone"
              value={phone}
              maxlength="10"
              pattern="[0-9]*"
              onClick={()=>handleEventFire("phone")}
              onChange={(e)=>setPhone((v) => (e.target.validity.valid ? e.target.value : v))}
              className={` ${ phoneMsg?.isInvitedNow === false && "border-2  border-remove "} border-2 border-formBorder px-4 h-10 py-2  ml-2 mr-2 rounded-lg bg-logoColor lg:bg-sidebarSelected`}
            />
          </Form.Item> */}
          {/* <FormButton onClick={handlePhoneSubmit} 
            className="hover:bg-sidebarSelected h-10 rounded-lg  
           hover:text-white text-white bg-logoColor lg:bg-sidebarSelected"
            backgroundColor={isMobile ? COLOR_CONSTANTS.MODAL_COLOR : COLOR_CONSTANTS.SIDEBAR_SELECTED}
            color={COLOR_CONSTANTS.WHITE} 
            // disabled={!phone}
            >
            Refer
          </FormButton> */}
        {/* </Form> */}
      </div>
     {/* {phoneMsg !== undefined && Object.keys(phoneMsg).length > 0 && <LDSpan className={`${ phoneMsg?.isInvitedNow ? "text-greeny"  :"error-color-style" }`}>{phoneMsg?.statusMessage}</LDSpan>}  */}
    </div>
  );
};

export default ReferOptions;
