import React, { Fragment, useEffect, useState } from 'react'
import LDImage from 'stories/Image'
import { LDSpan } from 'stories/Typography'
import { imagePath } from 'utils/assetHelper'
import  useMedia  from 'hooks/deviceHelper/useMedia';

const Action = (props) => {
    const {totalEpisodeCount ,isWishlisted ,handleWishList ,courseTitle,courseId} = props
    
    const [tempWishlisted, setTempWishlisted] =useState(false)
    
    useEffect(()=>{
        setTempWishlisted(isWishlisted) 
    },[isWishlisted])

    const removeHeartIcon = (courseId) => {
        setTempWishlisted(false)
        handleWishList(courseId,courseTitle,"remove")
    }

    const addHeartIcon = (courseId) => {
        setTempWishlisted(true)
        handleWishList(courseId,courseTitle,"add")
    }
    const isMobile = useMedia()
    const fontcolor = isMobile && "card-Subtitle"
    return (
        <Fragment>
            <LDSpan className={`text-white ${fontcolor}`}>
                {totalEpisodeCount === 1 ? `${totalEpisodeCount} episode` : `${totalEpisodeCount} episodes` } 
            </LDSpan>
            {tempWishlisted ?
                <LDImage
                    src={imagePath("/Home/filledHeart.svg")}
                    alt="asve"
                    className="cursor-pointer h-5"
                    onClick={() => removeHeartIcon(courseId)}
                />
                : <LDImage
                    src={imagePath("/Home/empty-heart.svg")}
                    alt="asve"
                    className="cursor-pointer h-5"
                    onClick={() => addHeartIcon(courseId)}
                />
            }
        </Fragment>
    )
}

export default Action