import { LDAnalytics } from "utils/analyticsHelper.js";

class EpisodeEvents extends LDAnalytics {
  event_Episode_Load_Start() {
    return {
      event: {
        module_name: "Episode",
        feature_name: "Episode Load",
        event_name: "Episode_Load_Start",
      },
    };
  }

  event_Episode_Load_End() {
    return {
      event: {
        module_name: "Episode",
        feature_name: "Episode Load",
        event_name: "Episode_Load_End",
      },
    };
  }
  event_Episode_Like_Click(){
    return {
      event: {
        module_name: "Episode",
        feature_name: "Episode Details",
        event_name: "Episode_Like_Click",
      },
    }
  }
  event_Episode_Dislike_Click(){
     return {
      event: {
        module_name: "Episode",
        feature_name: "Episode Details",
        event_name: "Episode_Dislike_Click",
      },
    }
  }
  event_Episode_Share_Click(){
    return {
      event: {
        module_name: "Episode",
        feature_name: "Episode Details",
        event_name: "Episode_Share_Click",
      },
    }
  }
  event_Episode_Share_Option_Click(){
    return {
      event: {
        module_name: "Episode",
        feature_name: "Episode Details",
        event_name: "Episode_Share_Option_Click",
        event_label: "",
      },
    }
  }
  event_Episode_Post_Comment_Textarea_Click(){
    return {
      event :{
        module_name: "Episode",
        feature_name: "Episode Comments",
        event_name :"Episode_Post_Comment_Textarea_Click",
      }
    }
  }
  event_Episode_Post_Comment_Click(){
    return {
      event :{
        module_name: "Episode",
        feature_name: "Episode Comments",
        event_name :"Episode_Post_Comment_Click",
        event_label :""
      }
    }
  }
  event_Episode_Comment_Reply_Click(){
    return {
      event :{
        module_name: "Episode",
        feature_name: "Episode Comments",
        event_name :"Episode_Comment_Reply_Click",
        event_label :"Comments"
      }
    }
  }
  event_Episode_Comment_Edit_Click(){
    return {
      event :{
        module_name: "Episode",
        feature_name: "Episode Comments",
        event_name :"Episode_Comment_Edit_Click",
        event_label :"Comments"
      }
    }
  }
  event_Episode_Comment_Delete_Click(){
    return {
      event:{
         module_name: "Episode",
        feature_name: "Episode Comments",
        event_name:"Episode_Comment_Delete_Click",
        event_label:"Comments"
      }
    }
  }
  event_Episode_Edit_Cancel_Click(){
    return {
      event :{
        module_name: "Episode",
        feature_name: "Episode Comments",
        event_name :"Episode_Edit_Cancel_Click",
        event_label :"comments"
      }
    }
  }
  event_Episode_Comment_Replies_Click(){
    return {
      event:{
        module_name: "Episode",
        feature_name: "Episode Comments",
        event_name :"Episode_Comment_Replies_Click",
        event_label:"Comments"
      }
    }
  }
  event_Episode_List_Card_Click(){
    return {
      event:{
        module_name: "Episode",
        feature_name: "Episode List",
        event_name :"Episode_List_Card_Click",
      }
    }
  }
  event_Play_First_Episode(){
    return{
      event: {
        module_name: "Episode",
        feature_name: "Episode List",
        event_name: "Play_First_Episode",
        event_label: "",
      }
    }
   }
   event_Play_Second_Episode(){
    return{
      event: {
        module_name: "Episode",
        feature_name: "Episode List",
        event_name: "Play_Second_Episode",
        event_label: "",
      }
    }
   }
   event_Paid_Episode_Click(){
    return {
      event: {
        module_name: "Episode",
        feature_name: "Episode List",
        event_name: "Paid_Episode_Click",
        event_label: "",
      }
    }
   }
   event_Purchased_Episode_Click(){
    return{
      event: {
          module_name:"Episode",
          feature_name:"Episode List",
          event_name:"Purchased_Episode_Click",
          event_label:"",
        }
     }
   }
}

const episodeEvents = new EpisodeEvents();
export default episodeEvents;
