import { axiosGet } from "axiosRequests";
import { GENERATE_RESIZED_IMG } from "constants/Apis";
import { assetBucketUrl } from "constants/app.constants";
import { useEffect, useState } from "react";
import { imagePath } from "utils/assetHelper";

const Image = (props) => {
  const { src='', icon=false, dimension=undefined, cache=true, large=false } = props;
  const cardThumbnailImage = imagePath(large ? "/large_lifedemy_thumb.png" : "/cardThumbnailImage.svg");
  const [imgSrc, setImgSrc] = useState(src);
  
  const generateResizedImg = async () => {
    /**
     * dimension -> widthXheight-> 700X400
     */
    const imgS3Key = src.replace(`${assetBucketUrl}/`,'').replace('https://lifedemy-banner.s3.ap-south-1.amazonaws.com/','');
    const uri = `${GENERATE_RESIZED_IMG}?size=${dimension}&res_path=${imgS3Key}`;
    const response = await axiosGet(uri);
    const { data,status } = response || {}
    if(status == 200 ){
      setImgSrc(data?.resized_asset_src);
    }
  }
  
  const onImgLoadErr = () => {
    setImgSrc(cardThumbnailImage);
    if(dimension && src && src.length && src != 'undefined'){
      generateResizedImg();
    }
  };

  const getDimensionSrc = () => {
    if(src && src.length){
      const imgS3Key = src.replace(`${assetBucketUrl}/`,'');
      const dimensionImgUrl = `${assetBucketUrl}/${dimension}/${imgS3Key}`;
      return dimensionImgUrl;
    }
  }

  useEffect(async ()=>{
    if(!icon && src && src.length && src != 'undefined'){
      let _src = dimension ? getDimensionSrc() : src;
      if(!cache){
        _src = _src + `?key=${parseInt(new Date().getTime()/1000)}`
      }
      setImgSrc(_src);
    }
  },[src])

  return (
    <img {...props} onError={onImgLoadErr} alt="lifedemy-img"  src={imgSrc}   />
  );
};

export default Image;