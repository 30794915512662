import { episodeLikeDislikeClickEvent,  episodeListCardClickEvent,  episodeLoadEvent, EpisodePlayFirstEpisode, EpisodePlaySecondEpisode, EpisodePurchasedEpisodeClick } from "analytics/episode";
import { Row, Col, notification } from "antd";
import { Helmet } from "components/Helmet";
import Image from "components/Image";
import PurchaseModal from "components/PurchaseModal";
import CourseList from "pages/Course/Components/CourseList";
import VideoDescription from "pages/Course/Components/VideoDescription";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "stories/Button";
import HorizontalLine from "stories/HorizontalLine";
import {  LDSpan, Title } from "stories/Typography";
import VideoPlayer from "stories/VideoPlayer";
import PlayerThumbnail from "stories/VideoPlayer/PlayerThumbnail";
import { getImgDimensionSrc, imagePath } from "utils/assetHelper";
import CommentSection from "./CommentSection";
import EpisodesDescription from "./EpisodesDescription";
import { episodeShareEvent,episodeShareOptionEvent } from "analytics/episode";
import { paymentInitiatePurchaseClickEvent } from "analytics/payment";
import { videoPlayEvent } from "analytics/videoPlayer";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useHistory } from "react-router-dom";
import { convertDateFormat, getMinutes, getSeconds } from "utils";
import HomeError from "pages/Home/components/Errors";
import DownloadAll from "pages/Course/Components/DownloadAll";
import CourseDescription from '../Course/Components/CourseDescription/index';
import DownloadApp from "components/DownloadApp";
import { Heading } from "stories/Typography";
import ExternalLink from "./ExternalLink";
import useGetWindowSize from "hooks/useGetWindowSize";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import AuthorModal from "./AuthorModal";
import { getMobileOperatingSystem } from "utils/deviceHelper";
import { resetPlayer } from "stories/VideoPlayer/actions";
const Episodes = (props) => {
  const { episodeLoading } = useSelector((state)=> state?.course)
  const episodeDetail = useSelector((state) => state?.episodes?.episodeDetail)
  // const playerState = useSelector((state) => state.videoPlayer);
  const episodes_list = useSelector((state) => state?.course?.episode_details);
  const { commentsLoading }  = useSelector ((state)=> state?.episodes)
  const course_details = useSelector((state) => state?.course?.course_details);
  const [episodeRestricted, setEpisodeRestricted] = useState(false);
  const [purchaseModalActive, setPurchaseModalActive] = useState(false);
  const episodeListError  = useSelector((state) => state?.course?.episodeListError);
  const episodesDetailsLoading  = useSelector ((state)=> state?.episodes.episodesDetailsLoading)
  const episodeDescError  = useSelector((state) => state?.episodes?.episodeDescError);
  const [isVideoError ,setIsVideoError]=useState(false)
  const [show,setShow] =useState(false)
  const [status, setStatus] =useState(null);
  const [showPurchase, setShowPurchase] = useState(false)
  const [currentAuthor, setCurrentAuthor] = useState({})
  const [authorModal,setShowAuthorModal] = useState(false)

  let { isFree,
    episodeUrl,
    title,
    views,
    episodeDescription,
    courseRating,
    uploadDate,
    duration,
    episodeNumber,
    thumbnailUrl,
    isWishlisted,
    isPurchased,
    likeStatus,
    instructors,
    totalLikesCount,
    nextEpisodeId,
    streamUrl,
    courseSlug,
    epsiodeSlug,
    courseId,
    episodeId,
    resourceLink,
    resourceLinkTitle
  } = episodeDetail
  const [totalLikes,setTotallikes] =useState(null);
  const [IsLiked,setIsLiked] =useState(null);
  const dispatch = useDispatch()
  const param = useParams()
  const appConfig = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const user_profile = useSelector((state) => state?.profile?.profileDetails);
  const history = useHistory();
  const { PLAYER_THUMB_DIMENSION } = appConfig;
  const { childMode } = user_profile;

  useEffect(()=>{
    if(getMobileOperatingSystem() ==="iOS"){
      resetPlayer();
    }
  },[param?.courseId, param?.episodeId])

  //cdn / video info url check
  if(streamUrl && streamUrl.length){
    episodeUrl = streamUrl;
  }
  useEffect(()=>{ 
    if(!episodeUrl && episodeUrl === null ){
      setIsVideoError(true)
     }
  },[episodeUrl])

  useEffect(()=>{
    if(isFree === false && isPurchased === false){
      setEpisodeRestricted(true)
      setPurchaseModalActive(true);
     }else{
      setEpisodeRestricted(false);
      setPurchaseModalActive(false);
     }
  },[episodeNumber, isPurchased]);

  useEffect(() => {
    if (param?.courseId && param?.episodeId) {
      const params = {
        course_name:param?.courseId,
        episode_name: param?.episodeId
      }
      episodeLoadEvent(false, params);
      const obj = { courseId: param?.courseId, episodeId: param?.episodeId }
      dispatch?.episodes?.requestEpisodeDetails(obj,(data, status)=>{
        const episodeId=data?.episodeId
        const courseId = data?.courseId
        setStatus(status);
        if(episodeId && courseId){
          setTotallikes(data?.totalLikesCount);
          setIsLiked(data?.likeStatus?.type);
          dispatch?.course?.requestCourseDetails(courseId); 
          dispatch?.course?.requestEpisodesDetails(courseId); // LIST Wali API
          dispatch?.episodes?.requestComments(episodeId); //Comments
        } 
      }) 
    }
}, [param?.courseId, param?.episodeId, childMode])

useEffect(() => {
  if(course_details?.courseTitle && title){
    const params = {
      course_name:course_details?.courseTitle,
      episode_name: title
    }
    episodeLoadEvent(true,params);
  }
}, [course_details?.courseTitle, title]);


  // useEffect(() => {
  //     dispatch?.course?.requestEpisodesDetails(param?.courseId);
  // }, [param?.courseId]);

  const handleLike =(type)=>{
    if(type?.toLowerCase() === "like"){
      setIsLiked(type);
      setTotallikes(prev => prev+1);
      const params = {event_label:`${title} - ${type}`, course_name:course_details?.courseTitle ,episode_name: title}
      episodeLikeDislikeClickEvent(false,params)      
    }
    else{
      const params = {event_label:`${title} - ${type}`,course_name:course_details?.courseTitle ,episode_name: title}
      episodeLikeDislikeClickEvent(true,params)
      setIsLiked(type);
      if(IsLiked == "LIKE"){
      setTotallikes(prev => prev-1);
      }
    }
    const payload={
      episodeId : episodeId,
      courseId: courseId,
      type : type
    }
    dispatch?.episodes?.requestEpisodesLikeDisLike(payload)
  }
  const videoProps = {
    light: false,
    courseTitle:course_details?.courseTitle,
    episodeTitle:title,
    courseId:course_details?.courseId,
    episodeNumber:episodeNumber,
    playIcon: <PlayerThumbnail thumbnail={thumbnailUrl} src={imagePath("/Episodes/watchEpisode.svg")} courseTitle={course_details?.courseTitle} episodeTitle={title}/>,
    thumbnail: thumbnailUrl
  };
  const fireEvent = useCallback(()=>{ 
    const params ={event_label : title, course_name:course_details?.courseTitle, episode_name: title }
   episodeShareEvent(params) // move this to episodes page 
  },[title, course_details?.courseTitle])
  const fireEventOptions =(option)=>{
    const params ={event_label : option ,course_name:course_details?.courseTitle, episode_name: title }
    episodeShareOptionEvent(params)  // move this to episodes page 
  }
  const handleFireEvent = useCallback((episodeName,index, obj)=>{
    const params = {course_name:course_details?.courseTitle,episode_name:title}
    episodeListCardClickEvent(params)
    if(index === 1){
      EpisodePlaySecondEpisode(params)
    }
    if(index === 2){
      EpisodePlaySecondEpisode(params)
    }
    if(!obj?.isFree && obj?.isPurchased ){
      EpisodePurchasedEpisodeClick(params)
    }
    EpisodePlayFirstEpisode(params)

  },[course_details?.courseTitle, title])
  const handleDownload = (type) => {
    if(type === "download"){
      setShow(true)
    }else{
      setPurchaseModalActive(true)
    }
  };
  const onNextClick = () => {
    const nextEpisodeRoute = `/${ROUTE_CONSTANTS.EPISODE}/${courseId}/${nextEpisodeId}`;
    history.push(nextEpisodeRoute);
  }
  const restProps = { ...episodeDetail, onNextClick: nextEpisodeId && onNextClick };
  
  {/* Move this to config */}

  const { innerWidth } = useGetWindowSize()
  const setWidth = innerWidth >= 992 && innerWidth < 1190 ? 24 : 16 
  const styleToset = episodeDescError && !episodesDetailsLoading ? "min-h-screen" : "min-h-screen lg:pl-[4rem] md:pt-5"

    const handleCurrentAuthor =(items) =>{
       setShowAuthorModal(true) 
       setCurrentAuthor(items)
    } 

    return (
    <div className={styleToset}>
      {title !== undefined && <Helmet  title={title}/> }
      {Object.keys(currentAuthor)?.length >0 && 
          <AuthorModal currentAuthor={currentAuthor} show={authorModal} handleClose={()=> setShowAuthorModal(false)} />
      }
      {purchaseModalActive &&  <PurchaseModal show={purchaseModalActive} handleClose={()=>{setPurchaseModalActive(false)}} courseSlug={courseSlug} courseId={courseId} offer_price={course_details?.offer_price} currencySymbol={course_details?.currencySymbol} mrp={course_details?.mrp}  title={episodeDetail?.title} handleGTMEventCapture={()=>paymentInitiatePurchaseClickEvent({event_label:course_details?.courseTitle})}/> }
      {status == 404 ? <HomeError
       errType={404}
       msg= {MESSAGE_CONSTANTS['EPISODE_NOT_FOUND']}
       /> :
       episodeDescError && !episodesDetailsLoading ? 
       <HomeError action={()=>{
        dispatch?.episodes?.setEpisodesDetailsLoading(true)
        setTimeout(()=>{
          dispatch?.course?.requestEpisodesDetails(param?.courseId);
          },800);
      }} />
      :
      <Row className="px-2 lg:px-0" gutter={1}>
        <DownloadApp show={show} handleClose={()=>setShow(false)}/>
        {/* hidden Image to load thumb image for video player poster. Poster will fail to load for 1st time */}
        {thumbnailUrl && thumbnailUrl.length && <div className="hidden"><Image src={thumbnailUrl} dimension={PLAYER_THUMB_DIMENSION} /></div>}
         
            <Col lg={setWidth} md={24} sm={24} xs={24} className="lg:mr-8 lg:p-0 p-2">
              {episodeRestricted ?
               <Image onClick={()=>{setPurchaseModalActive(true)}} src={thumbnailUrl} dimension={PLAYER_THUMB_DIMENSION} className="w-full" />
               :
                !episodesDetailsLoading && isVideoError ?
                <Image src={thumbnailUrl} dimension={PLAYER_THUMB_DIMENSION} className="w-full" />
                :
                <VideoPlayer is_episode={1} url={episodeUrl || ""} {...videoProps} restProps = {restProps} loading={episodesDetailsLoading} thumbnail={getImgDimensionSrc(thumbnailUrl, PLAYER_THUMB_DIMENSION)} />}
              {!episodesDetailsLoading && (
                <>
                <VideoDescription
                  title={title}
                  views={views}
                  rating={courseRating}
                  description={episodeDescription}
                  activeEpisode={true}
                  date={convertDateFormat(uploadDate)} // TODO :Change date convert
                  minutes={
                    duration < 60
                      ? `${duration}s`
                      : getMinutes(duration)+"m"+" "+getSeconds(duration)
                  }
                  episodeNumber={episodeNumber}
                  likeStatus={likeStatus}
                  totalLikesCount={totalLikes}
                  IsLiked={IsLiked}
                  handleLike={handleLike}
                  fireEvent={fireEvent}
                  courseTitle={course_details?.courseTitle}
                  fireEventOptions={fireEventOptions}
                />
                <HorizontalLine className="lg:block hidden opacity-50" />
                </>
              )}
              {/* <div className="my-6 lg:mr-60"> */}
              {/* {isPurchased &&
                <div className="lg:my-6 my-6">
                  <LDSpan className=" bg-greeny py-2 px-7 text-white cursor-pointer font-bold rounded-sm">
                    Purchased
                  </LDSpan>
                </div>
              } */}
              {/* </div> */}
              {instructors?.length >0 && 
                 instructors?.map((items)=>{
                   const  {instructorName,instructorDescription,designation,location, id,instructorThumbnail} =  items
                   return(
                    <EpisodesDescription
                      key={id} 
                      author={instructorName}
                      authorLocation=""
                      instructorThumbnail={instructorThumbnail}
                      description={instructorDescription}
                      designation={designation}
                      location={location}
                      items={items}
                      handleCurrentAuthor={handleCurrentAuthor}
                    />
                   )
                 })
              }
              <div className={`${instructors?.length === 0 && "mt-4 mb-4"}`}>
              <CourseDescription
                description={episodeDescription}
              />
              </div>

              {resourceLink && resourceLink?.length && resourceLinkTitle && resourceLinkTitle.length ?
               <ExternalLink
               resourceLink={resourceLink}
               resourceLinkTitle={resourceLinkTitle}
               episodeRestricted={episodeRestricted}
               setPurchaseModalActive={setPurchaseModalActive}
               />
              : null}
              {/**Episodes for mobile Reviews*/}
              <div className="block lg:hidden">
                {/* for Mobile screen*/}
                <DownloadAll
                  handleDownload={handleDownload}
                  isPurchased={isPurchased}
                  totalEpisodes={episodes_list?.length}
                />
                <CourseList
                  episodeLoading={episodeLoading}
                  episode_details={episodes_list}
                  courseId={courseId}
                  episodeId={episodeId}
                  isPurchased={isPurchased}
                  handleFireEvent={handleFireEvent}
                  mrp={course_details?.mrp}
                  title={title}
                  courseSlug={courseSlug}
                  offer_price={course_details?.offer_price}
                  getCurrentEpisodeName={()=>{}}
                  fromEpisode={true}
                  currencySymbol={course_details?.currencySymbol}
                /> 
              </div>
              {/* <Heading className="lg:text-2xl  text-2xl lg:mt-6 lg:mb-6 mt-6 mb-4 subtitle-course-page">Discussions</Heading> */}
              <div className="pl-1 pr-4 lg:px-0">
              {/* <CommentSection episodeId={episodeId} commentsLoading ={commentsLoading } title={title} courseTitle={course_details?.courseTitle} courseId={courseId} /> */}

                </div>  
            </Col>
        <Col lg={4} className="lg:block hidden">
          <div className="fixed w-[24.5%]">
            <Button
              type="primary"
              className="p-3 w-full bg-greeny font-modellicaBold border-none rounded-md shadow-lg  shadow-black-500/50 text-lg text-white cursor-default"
              label={"EPISODES"}
              btnCls={"text-white"}
            />
            {/* <EpisodesList episode_details={episodes_list} courseId={param?.courseId} episodeId={param?.episodeId} /> */}
            <CourseList
              episode_details={episodes_list}
              courseId={courseId}
              episodeId={episodeId}
              episodeLoading={episodeLoading}
              isPurchased={isPurchased}
              mrp={course_details?.mrp}
              handleFireEvent={handleFireEvent}
              courseSlug={courseSlug}
              offer_price={course_details?.offer_price}
              getCurrentEpisodeName={()=>{}}
              fromEpisode={true}
              currencySymbol={course_details?.currencySymbol}
            />
          </div>
        </Col> 
      </Row>
}
    </div>
  );
};
export default Episodes
