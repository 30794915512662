import { useState } from "react";
import { Modal } from "antd";

import {
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";
import { copyTextToClipboard } from "utils";

import LDImage from "stories/Image";
import { imagePath } from "utils/assetHelper";
import { LDSpan, Subtitle } from "stories/Typography";
import { COLOR_CONSTANTS } from "constants/color.constants";
import "./index.css";
import LinkTag from "stories/LinkTag";
import Input from "stories/Input";
import { Notifier } from '../Notifier/index';

const CopyToClipboard = () => {
  return (
    <div>
      <LDImage
        src={imagePath("/copyToClipboard.svg")}
        className="h-8 w-8 cursor-pointer"
      />
    </div>
  );
};
const Share = (props) => {
  const { url ,shareCopyUrl ,shareOnFacebook} = props;
  const [show, setShow] = useState(false);
  const [copied,setCopiedText] =useState("Copy")
  const handleOpenInput = (shareOption) => {
    props.fireEventOptions(shareOption)
    setShow(true);  
    Notifier(200,"Copied!")
    handleCopyClick(shareCopyUrl) 
  };
  const shareOptions = [
    {
      channel: "Facebook",
      icon: <FacebookIcon size={40} round={true} className="lg:ml-2 xl:ml-3"  />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${shareOnFacebook}`,
    },
    {
      channel: "Whatsapp",
      icon: <WhatsappIcon size={40} round={true} className="lg:ml-2 xl:ml-3" />,
      link: `https://api.whatsapp.com/send/?text=${url}`,
    },
    {
      channel: "Email",
      icon: <EmailIcon size={40} round={true} className="" />,
      link: `https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&body=${url}&su=Welcome to Lifedemy`,
    },
    {
      channel: "Copy",
      icon: <CopyToClipboard/>,
      link: undefined,
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    props.onClickHandler();
    setShowModal(true);
  };

  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
          setCopiedText("Copied")
            setShow(true)
       })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOptionClick = (shareOption) => {
    props.fireEventOptions(shareOption)
  };

  return (
    <>
      <div className="my-4 flex items-center">
        <LDImage
            src={imagePath("/Course/share.svg")}
            alt="share "
            className="pr-1 h-[1rem] cursor-pointer"
            onClick={handleClick}
          />
          <LDSpan className="cursor-pointer simple-text-size-episode" onClick={handleClick}>
            Share
        </LDSpan>
      </div>

      <Modal
        visible={showModal}
        closable={true}
        onCancel={() => setShowModal(false)}
        title={<Subtitle>Share</Subtitle>}
        centered
        width={400}
        footer={null}
        bodyStyle={{
          backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
          borderBottomRightRadius:"10px",
          borderBottomLeftRadius:"10px",
        }}
        maskStyle={{
          backdropFilter: "blur(3px)",
          "-webkit-backdrop-filter": "blur(3px)",
        }}
      >
          <div className="flex justify-between items-center">
            {shareOptions.map((_option) => {
              const { channel, link, icon } = _option;
              return (
                <>
                  {link ? (
                    <LinkTag route={{ pathname: link }} target={`_blank`} onClick={()=>handleOptionClick(channel)}>
                      <div className="text-center">
                        <div className="ml-1">{icon}</div>
                        <LDSpan>{channel}</LDSpan>
                      </div>
                    </LinkTag>
                  ) : (
                    <div onClick={()=>handleOpenInput(channel)}  >
                      <div className="text-center">
                        <div>{icon}</div>
                        <LDSpan>{channel}</LDSpan>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>

          {/* {show && (
            <div className="mt-8 tex-right">
               <Input
                  placeholder="Add email address"
                  type="email"
                  value={shareCopyUrl}
                  onClick={()=>handleCopyClick(shareCopyUrl)}
                  className="lg:bg-sidebarSelected cursor-pointer bg-logoColor h-12 w-11/12 text-white text-md outline-none p-2 border border-gray-500 rounded-lg mr-3"
                />
                <div className=" mt-2">
                <LDSpan
                    className={ `text-greeny cursor-pointer`}
                    onClick={() => handleCopyClick(shareCopyUrl)}
                  >
                   {copied} 
                </LDSpan>
                </div>
              </div>
          )} */}
      </Modal>
    </>
  );
};

export default Share;
