import { Fragment } from "react";
import TagsLoader from "stories/SkeletonLoader/TagsLoader";

const MultiLoadingTags = ({count}) => {
  return <Fragment >{[...Array(count)]?.map((item, key) => {
      return <TagsLoader key={key} />
  })}</Fragment>;
};

MultiLoadingTags.defaultProps = {
    count: 1
}

export default MultiLoadingTags;
