import { axiosGet, axiosPost } from "axiosRequests";
import { GET_PUSH_NOTIFICATION_PREFERENCES, PUSH_NOTIFICATION_URL } from "constants/Apis";
import { Notifier } from "components/Notifier";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { STATUS } from "constants/status.constant";
const model = {
  state: {
    fcm_token:{},
    pushNotificationDbData: {},
    requestActive: false
  },
  reducers: {
    setPushNotificationData: (state, payload) => {
      return {
        ...state,
        fcm_token: payload
      }
    },
    updatePushNotificationDbData: (state, payload) => {
      return {
        ...state,
        pushNotificationDbData: { ...state.pushNotificationDbData, ...payload }
      }
    },
    setNotificationStateData: (state,payload) => {
      return {
        ...state,
        ...payload
      }
    }
  },
  effects: (dispatch) => ({
    async requestAddPushNotification(body) {
      if(body?.is_update){
        dispatch?.pushNotification?.updatePushNotificationDbData({ notifyUser: body?.notifyUser })
      }
      let _body = { ...body };
      delete _body['is_update'];
      try{
        const response = await axiosPost(PUSH_NOTIFICATION_URL,_body)
        const { status, data } = response;
        if(!STATUS.includes(status)){
          dispatch?.pushNotification?.updatePushNotificationDbData({ notifyUser: !body?.notifyUser })
        }else{
          if(!body?.is_update){
            dispatch?.pushNotification?.updatePushNotificationDbData(data);
          }
          // Notifier(200, MESSAGE_CONSTANTS["NOTIFICATION_PREF_UPDATED"]);
        }
      } 
      catch(err){
        dispatch?.pushNotification?.updatePushNotificationDbData({ notifyUser: !body?.notifyUser });
        Notifier('Error', MESSAGE_CONSTANTS.GENERIC_ERR_MSG)
      } 
    },
    async getPushNotificationPreference(body) {
      const { fcm, user } = body;
      const reqUri = GET_PUSH_NOTIFICATION_PREFERENCES + `?fcm=${fcm}&user=${user}`;
      try{
        const response = await axiosGet(reqUri)
        const { data, status } = response
        if(STATUS.includes(status)){
          if(Object.keys(data).length){
            dispatch?.pushNotification?.updatePushNotificationDbData(data);
          }else{
            dispatch?.pushNotification?.setNotificationStateData({requestActive: true});
          }
        }
      } 
      catch(err){
        Notifier('Error', MESSAGE_CONSTANTS.GENERIC_ERR_MSG)
      } 
    },
    async updateNotificationData(body){
      dispatch?.pushNotification?.setNotificationStateData({requestActive: true});
    }
  }),
};

export default model;
