import { profilePersonalDetailsDobClickEvent, profilePersonalDetailsEditClickEvent, profilePersonalDetailsInputClickEVent, profilePersonalDetailsSaveBtnClickEvent } from "analytics/profile";
import Image from "components/Image";
import { Notifier } from "components/Notifier";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormButton from "stories/FormButton";
import Input from "stories/Input";
import { LDLabel, LDSmall, LDSpan} from "stories/Typography";
import { YearCheckRegEx, AlphaCheckRegEx, enableDateBeforeToday, readableDateFormate } from "utils";
import { imagePath } from "utils/assetHelper";
import "../index.css"
import  useMedia  from 'hooks/deviceHelper/useMedia';
import { Radio } from 'antd';
import { genderOptions } from "constants/app.constants";
import LDDateInput from "stories/LDDateInput";

const FormComponent = (props) => {
  const { handleEditProfile,fullname,dob,handleChange,handleDateChange, handleGenderChange, genderSelect, disabled } =
    props;

  const handleSubmit = () => {
    profilePersonalDetailsSaveBtnClickEvent({event_label:"Save Personal Details"})
    const array = fullname.trim()?.split(" ")?.filter(function(entry) { return entry?.trim() != ''; });
    if(array?.length === 0){
      return Notifier("Error","Plese enter your fullname")
    }
    if(!dob){
      return Notifier("Error","Plese enter your Date of Birth")
    }
    if(!genderSelect && genderSelect !== "null"){
      return Notifier("Error","Please select gender")
    }
    if(array?.length === 1){
      const data = {
        firstName: array[0]?.trim(),
        lastName: " ",
        dob: dob,
        gender:genderSelect
      };
     handleEditProfile(data);
    }
    else if(array?.length > 1){
      const data = {
        firstName: array[0]?.trim(),
        lastName: array?.slice(1)?.join(" "),
        dob: dob,
        gender:genderSelect
      };
      handleEditProfile(data);
    }
  };

  const changeBtnColor = disabled
    ? "hover:bg-grayColor hover:text-white text-white bg-grayColor lg:bg-grayColor profile-button-style"
    : "hover:bg-greeny hover:text-white text-white bg-greeny lg:bg-greeny";
  const showSaveBtn = disabled ? "hidden" : "block"
  return (
    <>
      <Input
        placeholder="Full Name"
        label="Name"
        labelFloating={true}
        type="text"
        value={fullname}
        // disabled={disabled}
        onClick={()=>profilePersonalDetailsInputClickEVent({event_label:"User Name"})}
        handleChange={handleChange}
        className="lg:bg-sidebarSelected bg-logoColor h-[3rem] profile-personal-input-style outline-none my-2 p-3  border-2 border-formBorder border-opacity-50 rounded-lg w-full mr-3"
      />
      <LDDateInput
        // type={dob? "date" :"text"}
        type="date"
        label="DOB"
        max = {enableDateBeforeToday()}
        placeholder="Date of Birth"
        labelFloating={true}
        value={readableDateFormate(dob)}
        icon={true}
        iconStyle={"top-5 right-4 cursor-pointer open-button"}
        src={imagePath("/Profile/calendar.svg")}
        onClick={()=>profilePersonalDetailsDobClickEvent({event_label:"User DOB"})}
        className="lg:bg-sidebarSelected bg-logoColor  h-[3rem] profile-personal-input-style outline-none my-2 p-3  border-2 border-formBorder border-opacity-50 rounded-lg w-full mr-4"
        handleChange={handleDateChange}
        style={{minWidth: "95%"}}
      />
      <div className="flex items-center mb-5 mt-1">
        <LDSpan className="mr-3"> Gender :</LDSpan>
        <Radio.Group onChange={handleGenderChange} value={genderSelect} >
            {genderOptions.map((_options)=>{
               return <Radio value={_options?.value} checked={genderSelect === _options?.value}><LDSpan>{_options?.label}</LDSpan></Radio>
            })}
        </Radio.Group>
      </div>
      <FormButton
        onClick={handleSubmit}
        disabled={disabled}
        className={` ${changeBtnColor} ${showSaveBtn} lg:h-10 lg:w-24 md:h-10 md:w-20 rounded-lg mb-3`}
      >
        Save
      </FormButton>
    </>
  );
};

const PersonalDetails = (props) => {
  const {
    firstName,
    lastName,
    dateOfBirth ,
    gender
  } = props;
  const errorMsg = useSelector((state) => state?.profile?.error);

  let userName = ''
  if(firstName && firstName !== 'undefined'){
    userName = firstName
  }
  if(lastName && lastName !== 'undefined'){
    userName = userName + " " + lastName
  }

  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();


  const [fullname, setFullname] = useState(userName.length !==0 ? userName : "");
  const [dob, setDob] = useState(dateOfBirth);
  const [genderSelect, setGenderSelect] = useState(gender)

  useEffect(()=>{
    setFullname(userName)
    setDob(dateOfBirth)
    setGenderSelect(gender)
  },[userName])

  useEffect(()=>{
    const newDate = new Date(dob)
    const getYear = newDate.getFullYear()
    if(YearCheckRegEx.test(getYear)){
      return Notifier("DELETED","Please enter valid date") 
    }  
  },[dob])
  const handleChange = (val) => {
    let value = AlphaCheckRegEx(val)
    if(userName.trim() == value.trim()){
      setDisabled(true);
      setFullname(value);
    }else{
      setDisabled(false);
      setFullname(value);
    }
  };
  const handleDateChange = (val) => {
    if(dob !==val){
      setDob(val); 
      setDisabled(false);
    }
  };

  const handleEditProfile = (obj) => {
    const date = new Date(obj?.dob);
    const data = {
      dateOfBirth: date,
      firstName: obj?.firstName,
      lastName: obj?.lastName,
      gender:obj?.gender
    };
    dispatch?.profile?.requestUpdateProfile(data,()=>{
      Notifier(200, MESSAGE_CONSTANTS?.UPDATE_PERSONAL_DETAILS_MSG) 
    });
    setDisabled(true)
  };
  const handleEdit = () => {
    const params = {event_label:"Edit Personal Details"}
    profilePersonalDetailsEditClickEvent(params)
    setDisabled(!disabled);
  };
  
  const handleCancel=()=>{
    const fullname = `${firstName}${lastName}`
    setFullname(fullname)
    setDisabled(!disabled);
  }
  const handleGenderChange=(e)=>{
    if(genderSelect !== e.target.value){
      setDisabled(false)
      setGenderSelect(e.target.value)
    }
  }
  return (
    <div>
      <div className="flex justify-between items-center">
        <LDSmall className="profile-personal-details-style pb-2">Personal Details</LDSmall>

        {/* <Image
           src={ disabled ? imagePath("/Profile/edit.svg") : imagePath("/cross.svg")}
           className="h-4 cursor-pointer mr-2"
           onClick={disabled ? handleEdit: handleCancel}
        /> */}
      </div>
      <FormComponent
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        dob={dob}
        fullname={fullname}
        disabled={disabled}
        genderSelect={genderSelect}
        handleGenderChange={handleGenderChange}
        handleEditProfile={handleEditProfile}
      />
    </div>
  );
};

export default PersonalDetails;
