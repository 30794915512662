import Modal from "components/Modal";
import PurchaseCard from "components/PurchaseCard";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const PurchaseModal = (props) => {
  const {handleClose ,mrp,show,courseId, handleGTMEventCapture,courseSlug,title,offer_price,currencySymbol}=props
  const history = useHistory()
  const dispatch = useDispatch()
  const state = useSelector((state) => state?.profile?.profileDetails);
  const redirectURL=(purchaseId)=>{
    history.push(`/${ROUTE_CONSTANTS.PAYMENT}?purchaseId=${purchaseId}&courseId=${courseId}&course=${courseSlug}`);
  }
  const handleSubscribe = () => {
    handleGTMEventCapture()
    const payload={
      course_id:courseId,
      user_id : state?.id
    }    
    dispatch?.payment?.requestInitialPurchase(payload, redirectURL)
  };

  useEffect(() => {
    if(show){
      dispatch?.profile?.requestProfileDetails();
    }
  }, []);

  return (
    <Modal
      show={show}
      onCancel={handleClose}
      closable={false}
      bodyStyle={{
        padding: "0px",
      }}
      width={400}
      centered
      maskStyle={{
        backdropFilter: "blur(3px)",
        "-webkit-backdrop-filter": "blur(3px)"
      }}
      footer={null}
    >
      <PurchaseCard handleSubscribe={handleSubscribe} price={mrp} title={title} offer_price={offer_price} currencySymbol={currencySymbol} />
    </Modal>
  );
};

PurchaseCard.defaultProps={
  handleGTMEventCapture:()=>{}
}
export default PurchaseModal;
