import { profilePhoneCancelBtnClickEvent } from "analytics/profile";
import { Col, Row } from "antd";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useState } from "react";
import { useHistory,useLocation } from "react-router-dom";
import LDImage from "stories/Image";
import { LDSpan, Title, Heading } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import AddPhone from "./AddPhone";
import EnterOtp from "./EnterOtp";
import "./index.css"

const VerifyPhone = () => {
  const [isPhone, setIsPhone] = useState(false);
  const [phoneNumber, setPhoneNumber]=useState("")
  const history = useHistory()
  const handleToggle = (phoneNumber) => {
    setIsPhone(true);
    setPhoneNumber(phoneNumber)
  };
  const handleCancel=()=>{
    profilePhoneCancelBtnClickEvent()
    history.push(`/${ROUTE_CONSTANTS?.PROFILE}`);
  }
  return (
    <div className="container mx-auto my-10  min-h-screen">
      <div className="mb-9 text-center md: hidden lg:block ">
        <Heading className="text-white text-3xl md:text-[0.6rem] lg:text-[1.8vw]">
          Profile Settings
        </Heading>
      </div>
      <Row justify="center">
        <Col
          lg={15}
          md={24}
          sm={24}
          xs={24}
          className="lg:bg-ternary rounded-md  px-4 md:px-10 lg:px-16 pt-0 pb-[17rem] md:pt-0 lg:pt-12"
        >
         <Title className="w-12 sm:ml-8 md:ml-10 relative top-5">
           <LDSpan className="flex items-center  sm:text-[0.7rem] cursor-pointer md:block  block"  onClick={handleCancel}>
            <LDImage
              src={imagePath("/Banner/leftArrow.svg")}
              alt="rightArrow"
              className="h-4 pr-2 w-6 "
            />
           </LDSpan>
         </Title>
        {/* {isPhone ? 
          <div className="text-center pb-4 md:pb-0 lg:pb-4">
              <Title className=" text-white text-xl sm:text-[0.7rem] font-modellicaBold md:text-[0.6rem]">
                Enter OTP
              </Title>
          </div>
          : */}
          <div className="text-center pb-4 xl:pb-4 md:pb-0">
              <Title className=" text-white verify-email-heading-style">
              Verify Phone Number
              </Title>
          </div>
          <div className="h-48 text-center">
            {isPhone ? <EnterOtp phoneNumber = {phoneNumber}/> : <AddPhone handleToggle={handleToggle} />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyPhone;
