import { axiosGet } from "axiosRequests";
import { GET_MY_COURSE_URL } from "constants/Apis";
import { STATUS } from "constants/status.constant";
import { partialStringMatch } from "utils/stringHelper";

const model = {
  state: {
    originalMycourseList: [],
    mycourseList: [],
    loading: false,
  },

  reducers: {
    setMyCourselistDetails: (state, payload) => {
      return {
        ...state,
        originalMycourseList: payload,
        mycourseList: payload,
      };
    },
    setFilteredMyCourseList: (state, payload) => {
      return {
        ...state,
        mycourseList: payload,
      };
    },
    setMycourseListLoading: (state, loading) => {
      return {
        ...state,
        loading: loading,
      };
    },
  },

  effects: (dispatch) => ({
    async requestMycourseListDetails() {
      try{
        dispatch?.mycourse?.setMycourseListLoading(true);
        const response = await axiosGet(GET_MY_COURSE_URL);
        const { data ,status} = response;
        if(STATUS?.includes(status)){
          dispatch?.mycourse?.setMycourseListLoading(false);
          dispatch?.mycourse?.setMyCourselistDetails(data?.results);
        }
        else {
          dispatch?.mycourse?.setMycourseListLoading(false);
        }
      }
      catch(err){
        console.log(err,"error in My course")
      }
    },
    filterMyCourselist(key, state) {
      const filteredData = state?.mycourse?.originalMycourseList?.filter(
        (_item) => {
          const { courseTitle } = _item;
          return partialStringMatch(courseTitle, key);
        }
      );
      dispatch?.mycourse?.setFilteredMyCourseList(filteredData);
    },
  }),
};
export default model;
