export const COLOR_CONSTANTS = {
  CARD: "#262C35",
  MODAL_COLOR: "#272D35",
  SIDEBAR_SELECTED: "#2E333F",
  SECONDARY_COLOR:"#1F252D",
  REMOVE :"#FF7373",
  GREEN :"#0E896A",
  WHITE : "#FFF",
  GREY : "#B9B9B9",
  BLACK:"#000",
  BLUE:"#1877F2",
  MOBILE_BANNER_SCRN_COLOR: "#404347"
};
