import React from "react";
import LDTooltip from "stories/LDTooltip";
import {  Title } from "stories/Typography";
import LinkTag from "stories/LinkTag";
import "./index.css";
import  CardImage  from 'components/Card/CardImage';
import Image from "components/Image";

const MobileCard = (props) => {
  const {
    src,
    isWatchNowCard,
    playIcon,
    action,
    title,
    content,
    dimension,
    handleClick,
    route,
    subtitle
  } = props;

  return (
    <>
      {isWatchNowCard ? (
        <div className="bg-primary">
          <div className="course-card flex relative">
            <LinkTag
              route={route}
              onClick={handleClick}
              className="course-card__link"
            >
              {" "}
            </LinkTag>
            <div className="course-card__thumbnail">
            <Image src={src} 
               dimension={dimension}
            />
              {/* <CardImage src={src} dimension={dimension} /> */}
            </div>
            <div className="course-card__description">
              <LDTooltip title={title}>
                <Title className={`line-clamp course-card__title`}>{title}</Title>
              </LDTooltip>
              <div className="course-card__rating">{content}</div>
            </div>
            <div className="course-card__strip">
              <div className="course-card__strip-left"></div>
              <div className="course-card__strip-right">
                <div className="flex justify-between pt-[4px] pb-[4px]">{action}</div>
              </div>
            </div>
          </div>

          <div className="bg-secondary"></div>
        </div>
      ) : (
        <LinkTag   
          route={route}
          onClick={handleClick}
        >
        {/* <div className="bg-[#262c36] p-2 mr-2">
            <CardImage
              src={src}
              dimension={dimension}
              imageCls="m-0"
              playIcon={true}
            />
            <LDTooltip title={title}>
                <Title className={`line-clamp`}>{title}</Title>
            </LDTooltip>
        </div> */}
        <div className="watch-crad">
          <div className="watch-card__thumbnail relative">
          <Image src={src}
          className="watch-card__thumbnail-img" 
               dimension={dimension}
            />
          {/* <img src={src} /> */}
            {/* <CardImage src={src} dimension={dimension} className="h-full w-full rounded-lg" /> */}
            <img src="/static/media/playicon.b84e18dc3db5ff257673.svg" className="watch-card__thumbnail-play"/>
          </div>
          <div className="watch-card__description ">
            <div className="watch-card__description-title">
            <LDTooltip title={title} className="h-[2.3rem]">
                <Title className={`line-clamp text-white watch-card__title `}>{title}</Title>
            </LDTooltip>
            </div>
            <div className="text-greeny bg-secondary px-3 py-1 ">{subtitle}</div>
          </div>
        </div>
        </LinkTag>
      )}
    </>
  );
};

MobileCard.defaultProps = {
  isWatchNowCard: false,
};

export default MobileCard;
