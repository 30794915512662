import { init } from "@rematch/core";
import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";

import search from "models/Search";
import home from "models/Home";
import registerWithEmail from "models/EmailSignUp";
import course from "models/Course";
import authModel from "models/AuthModel";
import payment from "models/Payment";
import wishlist from "models/Wishlist";
import episodes from "models/Episodes";
import mycourse from "models/MyCourse";
import suggestions from "models/Suggestions"
import referral from "models/ReferFriend"
import profile from "models/Profile"
import registerWithPhone  from "models/PhoneSignUp"
import settings from "models/Setting"
import commonParams from "models/CommonParams"
import notification from "models/Notification"
import storageData from "models/StorageData"
import pushNotification from "models/pushNotification"
import videoPlayer from "models/VideoPlayer"
import instructor from 'models/Instructor'


const models = {
  search,
  home,
  registerWithEmail,
  course,
  authModel,
  payment,
  wishlist,
  episodes,
  mycourse,
  suggestions,
  profile,
  referral,
  registerWithPhone,
  settings,
  commonParams,
  notification,
  storageData,
  pushNotification,
  videoPlayer,
  instructor,

};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["registerWithEmail","storageData"],
};
const store = init({
  plugins: [persistPlugin(persistConfig)],
  models,
  redux: {
    rootReducers: {
      RESET_APP: (state, action) => {
        // state.authModel = undefined
        /**
         * Need to find more optimal solution for clearing redux states
         */
        state.home = undefined
        state.episodes  = undefined
        state.course = undefined
        state.mycourse = undefined
        state.payment = undefined
        state.profile = undefined
        state.referral = undefined
        state.registerWithEmail = undefined
        state.registerWithPhone = undefined
        state.search = undefined
        state.settings = undefined
        state.suggestions = undefined
        state.wishlist = undefined
        state.commonParams=  undefined
        state.notification = undefined
        state.storageData = undefined // If you want to keep the stoageData after logout just comment or remove //state.storageData = undefined
        state.videoPlayer = undefined 
      }
    },
  }  
});

export default store;
