import { PaymentUpdateEmailSuccess, paymentGotoCourseEvent, paymentStatusLoadEvent } from "analytics/payment";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import { useQuery } from "hooks/useQuery";
import React, { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "stories/Button";
import LDImage from "stories/Image";
import { LDParagraph, LDSpan, Title ,Heading } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { EmailCheckRegEx, stringSlice } from "utils";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import CardImage from '../../../components/Card/CardImage/index';
import StarRating from '../../../stories/StarRating/index';
import "./index.css"
import Image from "components/Image";
import CourseDescription from "pages/Course/Components/CourseDescription";
import LinkTag from "stories/LinkTag";
import { PLAY_STORE_LINK } from 'constants/Apis';
import { removeToken } from "utils/token.helper";
import FormButton from '../../../stories/FormButton/index';
import UpdatedEmail from "components/UpdatedEmail";
import { Notifier } from "components/Notifier";
import useIpEffect from "hooks/useIpEffect";

const PaymentSuccess = () => {
  // const { email, courseId, customerReceipt } = props
  const query = useQuery();
  const courseId = query?.get("_c");
  const phoneNumber = query?.get("_p");
  const guestUpdateToken = query?.get("_t");
  const amount = query?.get("_a")
  const redirectSrc = query?.get("_f")
  const orderId = query?.get("_o")
  const region = query?.get("region");
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const course_details = useSelector((state) => state?.course?.course_details);
  const userProfile = useSelector(state => state?.profile?.profileDetails);
  const userMobile = userProfile?.mobileNumber
  const slicedUserMobile = userMobile ? stringSlice(userMobile, 3) : ""
  const _qPhoneNumber = phoneNumber ? stringSlice(phoneNumber, 4) : ""
  const [show, setShow ] = useState(true);
  const { email } = userProfile;

  useIpEffect(() => {
    const courseKey = region?.length ? `${courseId}?region=${region}` : courseId;
    dispatch?.course?.requestCourseDetails(courseKey);
  },[courseId])

  useIpEffect(() => {
    if(course_details && Object.keys(course_details).length){
      const { _gCode, currency } = course_details;
      const conversionCode = _gCode && _gCode.length ? _gCode : null;
      const conversionList = conversionCode?.split(",") 
      const payloadPhone = redirectSrc === "guest" ? _qPhoneNumber : userMobile || "N/A"
      const payloadEmail = email ||  "N/A"

      if(orderId && amount && process.env.REACT_APP_ENV !== "dev" && conversionList?.length > 0){
        conversionList?.forEach((items)=>{
          window.gtag('event', 'conversion', {
            'value': amount,
            'send_to': items,
            'currency': currency || 'INR',
            'transaction_id': orderId,
            'phone': payloadPhone
          });
        })
      }
      const purchaseEventParams = {
        ecommerce: {
          transaction_id: orderId,
          value: parseFloat(amount),
          currency: currency || 'INR',
          user_data: {
            email_address: payloadEmail,
            phone_number: payloadPhone,
          },
          items: [{
            item_id: courseId,
            item_name: course_details?.courseTitle || "",
            price: parseFloat(amount),
            quantity: 1
          }]
        }
      }
      const params = { event_label : `${course_details?.courseTitle ? course_details?.courseTitle : "NA"  } - Success`, purchaseEventParams }
      paymentStatusLoadEvent(params);
    }
  }, [amount, orderId, course_details]);

  const appCongif = useSelector((state)=>state?.commonParams?.app_breakpoint_configs)
  const { PAYMENT_THUMB_DIMENSION } = appCongif;

  const handleRedirect = () => {
    const params ={event_label: course_details?.courseTitle }
    paymentGotoCourseEvent(params)
    history.push({ pathname: `/${ROUTE_CONSTANTS.COURSE}/${courseId}` });
  };

  const redirectToCoursePage = () => {
    history.push({
      pathname: `/course/${courseId}`,
      state: { _qPhoneNumber }
    })
  }

  const handleCourseRedirect = () => {
    if (userMobile) {
      const slicedMobile = phoneNumber ? stringSlice(phoneNumber, 3) : ""
      if (slicedMobile && slicedMobile === slicedUserMobile) {
        redirectToCoursePage()
      } else {
        redirectToCoursePage()
        removeToken();
      }
    } else {
      redirectToCoursePage()
    }
  }

  const closeEmailUpdateModal = (data) => {
    setShow(false);
    const updatedParams = window?.location?.search?.replace(`&_t=${guestUpdateToken}`,'')
    const newSuccessRoute = ROUTE_CONSTANTS.PAYMENT_SUCCESS + updatedParams
    history.push(newSuccessRoute)
  }

  const handleGuestUpdate = (email) => {
    if(email?.length === 0){
      return Notifier("DELETED","Email ID is required");
   }
   if(email && EmailCheckRegEx(email)) {
     const body = {
        token: guestUpdateToken,
        data: {
          email: email
        }
     };
     const invoiceEmailPayload = {
        course_id: courseId,
        token: guestUpdateToken,
     }; 
     const cb = () => {
        setShow()
        const params = {
         event_label: course_details?.courseTitle
       }
       PaymentUpdateEmailSuccess(params);
       dispatch?.payment?.sendPurchaseInvoiceEmail(invoiceEmailPayload);
     }
     dispatch?.profile?.updateGuestDetails(body, cb);
   } else {
     Notifier("DELETED", "Please enter a valid email");
   }
  };

  const getRating=()=>{}
  return (
    <div className=" lg:px-[6.5rem] mx-auto md:mx-20  min-h-screen lg:pt-[4rem]">
      {guestUpdateToken && (
        <UpdatedEmail 
          title="Please enter your Email ID "
          subTitle="to view the course"
          btnTitle="Submit"
          show={show} 
          setShow={(data)=>closeEmailUpdateModal(data)}
          isPurchase={false}
          isPurchaseSuccess={true}
          // courseTitle={course_details?.courseTitle || "NA"}
          updateEmailClickAction={handleGuestUpdate}
        />
      )}
      <Row gutter={30}>
        <Col lg={2}></Col>
        <Col lg={11} md={24} sm={24} className="">
          <div
            className={`bg-secondary lg:bg-ternary p-2 rounded hidden xs:hidden md:hidden lg:block  mt-20 mb-6`}
          >            
             <Row gutter={10} className="">
              <Col lg={11} md={10} >
               <div className="pl-5 pt-2  object-contain">
                  <Image
                    src={course_details?.thumbnailUrl}
                    className="rounded-md h-full w-full"
                    dimension={PAYMENT_THUMB_DIMENSION}
                  />
               </div>
              </Col>
              <Col lg={13} md={14}>
              <div className="lg:pt-2 px-2">
                <Title className="payment-card-heading-style line-clamp ">
                  {course_details?.courseTitle}
                </Title>
                <LDParagraph className="payment-card-text-style mt-1">
                  {course_details?.totalEpisodeCount} episodes
                </LDParagraph>
                <div className="pointer-events-none">
                <StarRating
                  isStarRatingActive={true}
                  getRating={getRating}
                  disabled={true}
                  rating={course_details?.overallRating || 0}
                />
                </div>

              </div>
              </Col>
            </Row>
            <div className="text-white flex justify-end">
              <LDSpan className="bg-greeny flex items-center px-2 py-1 rounded ">
                {/* <LDImage src={imagePath("/rupee.svg")} className="h-2 mr-1" /> */}
                <LDSpan>
                  Purchased
                </LDSpan>
              </LDSpan>
            </div>
          </div>

            <LDParagraph className="text-justify payment-card-text-gray-small hidden lg:block">
                 <CourseDescription  description={course_details?.courseDescription} />         
              {/* <LDSpan
                className="text-greeny cursor-pointer"
                onClick={()=>history.push(ROUTE_CONSTANTS?.PRIVACY)}
              >
                Terms & Conditions apply {" "}
              </LDSpan>     */}
              {/* <LDSpan
                  className="text-greeny cursor-pointer"
                  onClick={()=>history.push(ROUTE_CONSTANTS?.PRIVACY)}
               >
               Privacy Policy
              </LDSpan>      */}
            </LDParagraph>
        </Col>
        <Col lg={10} md={24} sm={24} xs={24} >
          <div className="text-center py-4 px-6 lg:py-8 lg:px-14 bg-ternary my-20 mx-6">
            <LDImage
              src={imagePath("/Payment/Paymentsuccess.svg")}
              alt="paymentSuccess"
              className="m-auto block mt-12 lg:mt-9 h-12"
            />
            <Heading className="text-white my-4 payment-success-heading-style">Payment Completed</Heading>
            { redirectSrc ? 
            <LDParagraph className="m-4 lg:m-0 payment-success-message-style">
              Thank you for your purchase ! 

              {_qPhoneNumber || slicedUserMobile ? (
                <p className="m-0 p-0">To view the course, please login using this mobile number: <br/> <br/>
                <LDSpan className="text-greeny payment-success-message--green-style font-modellicaBold">+91&nbsp;{_qPhoneNumber ? _qPhoneNumber : slicedUserMobile || "<Mobile Number>"}</LDSpan></p>
              ) : null}

              <LinkTag route={{pathname:PLAY_STORE_LINK}} target="_blank">
                  <FormButton
                    type="submit"
                    className="bg-greeny auth-button-text-style h-10  lg:w-full px-3 mt-4  mb-2 rounded mx-auto w-[100%]">
                        View course on the app 
                </FormButton>
              </LinkTag>  
              <Button
                type="primary"
                className="p-1 h-10 mt-2 mb-6 rounded payment-success-btn-style bg-white border-none  text-greeny font-modellicaMedium"
                label={"View course on the web"}
                onClick={handleCourseRedirect}
                style={{fontSize:'15.12px'}}
              />
              {/* You will shortly receive a confirmation mail at <LDSpan className="text-greeny payment-success-message--green-style">{email}</LDSpan> */}
            </LDParagraph> :
            <LDParagraph className="text-white my-6 payment-success-message-style">
              <p>Thank you for your purchase.</p>
              {/* Please find the invoice under My Courses. */}
              <LinkTag route={{pathname:PLAY_STORE_LINK}} target="_blank">
                <FormButton
                  type="submit"
                  className="bg-greeny auth-button-text-style h-10  lg:w-full px-3 mt-4  mb-2 rounded mx-auto w-[100%]">
                      View course on the app 
                </FormButton>
              </LinkTag> 
              {/* <p className="text-btw-hr-line">OR</p> */}
              <Button
                type="primary"
                className="p-1 h-10 mt-2 mb-6 rounded payment-success-btn-style bg-white border-none  text-greeny font-modellicaMedium"
                label={"View course on the web"}
                onClick={handleCourseRedirect}
                style={{fontSize:'15.12px'}}
              />
            </LDParagraph>
            }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSuccess;
