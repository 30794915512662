export const breakpoint_configs = {
  0:{
    CAROUSEL_CARD_SHOW_LENGTH:2.3,  
    INSTRUCTOR_CAROUSEL_CARD_LENGTH:1.3,
  
  },
  375:{
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:3,
    WATCH_NOW_SIZE_PER_ROW:1,
    INSTRUCTOR_CARDSIZE_PER_ROW:2,
    HOME_BANNER_DIMENSION: "716X286",
    CONTINUE_WATCHING_THUMB_DIMENSION: "346X196",
    WATCH_NOW_THUMB_DIMENSION: "346X196", 
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "346X196",
    PLAYER_THUMB_DIMENSION: "652X366",
    EPISODE_LISTING_THUMB_DIMENSION: "232X130",
    VIDEO_PLAYER_HEIGHT:"",
    CAROUSEL_CARD_SHOW_LENGTH:2.3,
    PAYMENT_THUMB_DIMENSION:"316X178",
    INSTRUCTOR_CARD_DIMENSION: "170X170",
    INSTRUCTOR_CAROUSEL_CARD_LENGTH:2.3,
  },
  576: { // sm 
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:3,
    WATCH_NOW_SIZE_PER_ROW:2,
    INSTRUCTOR_CARDSIZE_PER_ROW:2,
    HOME_BANNER_DIMENSION: "720X288", //2.5 AR Mobile
    CONTINUE_WATCHING_THUMB_DIMENSION: "350X196",
    WATCH_NOW_THUMB_DIMENSION: "350X196",
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "310X174",
    PLAYER_THUMB_DIMENSION: "738X422",
    EPISODE_LISTING_THUMB_DIMENSION: "220X124",
    VIDEO_PLAYER_HEIGHT:"",
    PAYMENT_THUMB_DIMENSION:"316X178",
    CAROUSEL_CARD_SHOW_LENGTH:2.3,
    INSTRUCTOR_CARD_DIMENSION: "200X200",
    INSTRUCTOR_CAROUSEL_CARD_LENGTH:2.3,
  },
  767: { //md
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:3,
    WATCH_NOW_SIZE_PER_ROW:2,
    INSTRUCTOR_CARDSIZE_PER_ROW:2,
    HOME_BANNER_DIMENSION: "720X160",
    CONTINUE_WATCHING_THUMB_DIMENSION: "225X100",
    WATCH_NOW_THUMB_DIMENSION: "144X96",
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "358X96",
    PLAYER_THUMB_DIMENSION: "720X360",
    EPISODE_LISTING_THUMB_DIMENSION: "112X56",
    VIDEO_PLAYER_HEIGHT:"",
    CAROUSEL_CARD_SHOW_LENGTH:3.2,
    INSTRUCTOR_CARD_DIMENSION: "230X230",
    INSTRUCTOR_CAROUSEL_CARD_LENGTH:3.2,
  },
  992:{
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:3,
    WATCH_NOW_SIZE_PER_ROW:3,
    INSTRUCTOR_CARDSIZE_PER_ROW:4,
    HOME_BANNER_DIMENSION: "998X300",
    CONTINUE_WATCHING_THUMB_DIMENSION: "300X168",
    WATCH_NOW_THUMB_DIMENSION: "300X168",
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "215X80",
    // CARD_THUMB_DIMENSION: "",
    PLAYER_THUMB_DIMENSION: "944X472",
    EPISODE_LISTING_THUMB_DIMENSION: "224X112",
    VIDEO_PLAYER_HEIGHT:"",
    CAROUSEL_CARD_SHOW_LENGTH:3.4,
    INSTRUCTOR_CARD_DIMENSION: "500X440",
    INSTRUCTOR_CAROUSEL_CARD_LENGTH:3.4
  },
  1500:{
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:4,
    WATCH_NOW_SIZE_PER_ROW:4,
    INSTRUCTOR_CARDSIZE_PER_ROW:4,
    HOME_BANNER_DIMENSION: "1050X280", // 3.75 AR Desktop 
    CONTINUE_WATCHING_THUMB_DIMENSION: "480X270", // added by me
    WATCH_NOW_THUMB_DIMENSION: "480X270", //ref youtube
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "476X272", //add by me
    PLAYER_THUMB_DIMENSION: "890X500",
    EPISODE_LISTING_THUMB_DIMENSION: "242X132",
    VIDEO_PLAYER_HEIGHT:"50vh",
    PAYMENT_THUMB_DIMENSION:"169X95",
    CAROUSEL_CARD_SHOW_LENGTH:4,
    INSTRUCTOR_CARD_DIMENSION: "500X440"
  },
  1600:{
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:4,
    WATCH_NOW_SIZE_PER_ROW:4,
    INSTRUCTOR_CARDSIZE_PER_ROW:6,
    HOME_BANNER_DIMENSION: "1050X280", // 3.75 AR Desktop 
    CONTINUE_WATCHING_THUMB_DIMENSION: "480X270", // added by me
    WATCH_NOW_THUMB_DIMENSION: "480X270", //ref youtube
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "476X272", //add by me
    PLAYER_THUMB_DIMENSION: "890X500",
    EPISODE_LISTING_THUMB_DIMENSION: "242X132",
    VIDEO_PLAYER_HEIGHT:"50vh",
    PAYMENT_THUMB_DIMENSION:"169X95",
    CAROUSEL_CARD_SHOW_LENGTH:4,
    INSTRUCTOR_CARD_DIMENSION: "456X456"
  },
  1951:{
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:4,
    WATCH_NOW_SIZE_PER_ROW:4,
    INSTRUCTOR_CARDSIZE_PER_ROW:6,
    HOME_BANNER_DIMENSION: "1503X400",
    CONTINUE_WATCHING_THUMB_DIMENSION: "502X282",
    WATCH_NOW_THUMB_DIMENSION: "502X282", // start from here
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "476X272",
    PLAYER_THUMB_DIMENSION: "931X522",
    EPISODE_LISTING_THUMB_DIMENSION: "238X132",
    VIDEO_PLAYER_HEIGHT:"60vh",
    PAYMENT_THUMB_DIMENSION:"169X95",
    INSTRUCTOR_CARD_DIMENSION: "500X500"
   },
   2287:{
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:5,
    WATCH_NOW_SIZE_PER_ROW:5,
    INSTRUCTOR_CARDSIZE_PER_ROW:10,
    HOME_BANNER_DIMENSION: "1501X400",
    CONTINUE_WATCHING_THUMB_DIMENSION: "269X151",
    WATCH_NOW_THUMB_DIMENSION: "269X151",
    // CARD_THUMB_DIMENSION: "",
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "233X131",
    PLAYER_THUMB_DIMENSION: "1231X690",
    EPISODE_LISTING_THUMB_DIMENSION: "119X67",
    VIDEO_PLAYER_HEIGHT:"60vh",
    PAYMENT_THUMB_DIMENSION:"244X137",
    INSTRUCTOR_CARD_DIMENSION: "500X500"
   },
   4000:{
    CONTINUE_WATCH_NOW_INIT_LIST_LENGTH:6,
    WATCH_NOW_SIZE_PER_ROW:6,
    INSTRUCTOR_CARDSIZE_PER_ROW:10,
    HOME_BANNER_DIMENSION: "1501X400",
    CONTINUE_WATCHING_THUMB_DIMENSION: "276X155",
    WATCH_NOW_THUMB_DIMENSION: "276X155",
    WISHLIST_MY_COURSES_THUMB_DIMENSION: "295X155",
    // CARD_THUMB_DIMENSION: "",
    PLAYER_THUMB_DIMENSION: "1536X863",
    EPISODE_LISTING_THUMB_DIMENSION: "119X68",
    VIDEO_PLAYER_HEIGHT:"60vh",
    INSTRUCTOR_CARD_DIMENSION: "456X460",
    INSTRUCTOR_CARD_DIMENSION: "500X500"
   }
};

export const notification_route_config = {
     "COURSE_PUBLISHED":{
       route:"/course"
     },
     "COURSE_ADDED_TO_WISHLIST":{
      route:"/wishlist"
     },
     "PROFILE_PIC_UPDATED":{
      route:"/profile"
     }
}
