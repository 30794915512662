import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import gmf from "remark-gfm";
import { LDSpan } from "stories/Typography";
import "./index.css"

const LDMarkdown = (props) => {
  const { content, isActiveShowMore, lengthToShow, textAlign , onClickSeeMore = undefined, onClickSeeLess = undefined} = props;

  const [toShow, setToShow] = useState(lengthToShow);
  let min = lengthToShow || 500;
  
 const  handleSeeMore = () =>{
  !onClickSeeMore && setToShow(content?.length)
  onClickSeeMore && onClickSeeMore()
 }

  const handleSeeLess = () => {
    !onClickSeeLess &&  setToShow(min)
    onClickSeeLess && onClickSeeLess()
  }

  return (  
    <>
      <ReactMarkdown
        className="ld-author-descr-style"
        components={{
          h1: ({ node, ...props }) => (
            <h1 style={{ color: "white", fontSize: "2em" }} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 style={{ color: "white", fontSize: "1.5em" }} {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 style={{ color: "white", fontSize: "1.17em" }} {...props} />
          ),
          h4: ({ node, ...props }) => (
            <h4 style={{ color: "white", fontSize: "1em" }} {...props} />
          ),
          h5: ({ node, ...props }) => (
            <h5 style={{ color: "white", fontSize: "0.83em" }} {...props} />
          ),
          h6: ({ node, ...props }) => (
            <h6 style={{ color: "white", fontSize: "0.75em" }} {...props} />
          ),
          ul: ({ node, ...props }) => (
            <ul className="ml-6 mb-3">
              <li className="text-white list-disc" {...props}></li>
            </ul>
          ),
          p: ({ node, ...props }) => (
            <p className={`text-white ${textAlign}`} style={{ fontSize: "1em" }} {...props} />
          ),
          a: ({ node, ...props }) => (
            <a
              target="_blank"
              className="text-greeny hover:text-greeny"
              {...props}
            />
          ),
          br: ({ node, ...props }) => (
            <br {...props} />
          ),
        }}
        children={content?.slice(0, toShow)}
        remarkPlugins={[gmf]}
      />
      {isActiveShowMore && (
        <>
          {content?.length > min && (
            <>
              <>
                {toShow === min ? (
                  <LDSpan
                    className="text-greeny cursor-pointer episode-desc-text-more-less"
                    onClick={handleSeeMore}
                  >
                    ...More
                  </LDSpan>
                ) : (
                  <LDSpan
                    className="text-greeny cursor-pointer episode-desc-text-more-less"
                    onClick={handleSeeLess}
                  >
                    Less
                  </LDSpan>
                )}
              </>
            </>
          )}
        </>
      )}
    </>
  );
};
LDMarkdown.defaultProps = {
  isActiveShowMore: false
};

export default LDMarkdown;
