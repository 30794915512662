import React from 'react'
import { Modal } from 'antd';
import { COLOR_CONSTANTS } from 'constants/color.constants';


const TermsAndConditionsModal = (props) => {
    const { handleClose, show, data } = props;
  return (
    <div className='my-[10px]'>
      <Modal
      className="term--modal"
      visible={show}
      closable={true}
      onCancel={handleClose}
      centered
      width={400}
      footer={null}
      bodyStyle={{
        backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
        borderRadius: "10px",
      }}
      maskStyle={{
        backdropFilter: "blur(3px)",
        "-webkit-backdrop-filter": "blur(3px)",
      }}>
        {/* <div > */}
        <div className='text-white text-center text-[1.7rem]'>Terms and Conditions</div>
        <div className="modal--conditon" dangerouslySetInnerHTML={{__html: data }}></div>
        {/* </div> */}
      </Modal>
    </div>
  )
}

export default TermsAndConditionsModal
