import { navbarSearchInputClickEvent } from "analytics/navbar";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "stories/Input";
import { imagePath } from "utils/assetHelper";
import { debounce } from "utils/debounce";

const Search = (props) => {
  const { iconStyle, normalStyle, sidebarActive } = props;
  const [inputValue, setInputValue] = useState("");
  const history = useHistory();

  const getSearchResults =(val)=>{
    history.push(`/search?query=${val?.trim()}`);
  }

  const handleDebounce = useCallback(debounce((nextValue) => getSearchResults(nextValue), 800), [])
  const handleChange =(val)=>{
      setInputValue(val);
      handleDebounce(val);
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(inputValue);
    }
  };
  const handleFireEvent=()=>{
    navbarSearchInputClickEvent()
  }
  
  return (  
    // <form>
    <Input 
      type="search"
      icon={true}
      onClick={handleFireEvent}
      src={imagePath("/searchIcon.svg")}
      value={inputValue}
      handleChange={handleChange}
      placeholder="Search Courses"
      handleSearch={handleSearch}
      iconStyle={iconStyle}
      normalStyle={normalStyle}
    />
    // </form>
  );
};

export default Search;
