const CardListLoader = () => {
  return (
    <>
      <div class=" border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto m-2">
        <div class="animate-pulse flex space-x-4">
          <div class="rounded bg-slate-200 h-10 w-10"></div>
          <div class="flex-1 space-y-6 py-1">
            <div class="h-2 bg-slate-200 rounded"></div>
            <div class="space-y-3">
              <div class="grid grid-cols-3 gap-4">
                <div class="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardListLoader;
