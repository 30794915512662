import Card from "components/Card";
import Duration from "components/Card/Duration";
import MultiLoadingCards from "components/MultiLoadingCards";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StarRating from "stories/StarRating";
import { Heading, LDSpan, Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { getHours, getMinutes, getSeconds } from "utils";
import { useQuery } from "hooks/useQuery";
import { useHistory } from "react-router-dom";
import Search from "components/Search";
import LDImage from "stories/Image";
import { queryStringForSearch } from "utils/getQueryString";
import useMedia from "hooks/deviceHelper/useMedia";
import { searchCourseCardClickEvent, searchResultEvent } from "analytics/search";
import { Helmet } from "components/Helmet";
import { useState } from "react";
import Action from "pages/Home/components/WatchNow/Action";
import { homeWatchnowWishlistEvent } from "analytics/home";
const SearchResults = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const searchKey = query.get("query");
  const [searchResult,setSearchResult] =useState([])
  const history = useHistory();
 const fireEventCb =(length)=>{
      const params = {event_label:`${searchKey} | ${length}`}
      searchResultEvent(params)
 }  
 const appConfig = useSelector((state)=> state?.commonParams?.app_breakpoint_configs)
 const { WATCH_NOW_THUMB_DIMENSION } = appConfig;

  useEffect(() => { 
     const result = queryStringForSearch(searchKey)
     if(searchKey?.length >0){
      const data ={
        searchKey:searchKey?.toLowerCase(),
        fun:fireEventCb
       }
       dispatch.search.requestSearch(result, data);
     }
     else{
      setSearchResult([])
      // history.push('/')
     }
    //  if(searchKey?.length===0){
    //   history.push("/")
    //  }
     
  }, [dispatch, searchKey]);
  const Mobile = useMedia();

  const { results = [], loading } = useSelector((state) => state?.search);
  useEffect(()=>{
     setSearchResult(results)
     if(searchKey?.length >0 === false){
      setSearchResult([])
     }
  },[results])
  const handleWishList = (id,title, type) => {
    const params = {
      event_label: type, //${title} -
      course_name: title
    }
    homeWatchnowWishlistEvent(params)
    dispatch?.commonParams?.requestAddToWishlist({courseId: id, type, courseTitle:title },()=>{});
  };
  const handleClick =()=> searchCourseCardClickEvent({event_label : `${searchKey} -  ${results?.length}` })
  const largeMargin = searchResult?.length === 0 && (searchKey?.length ===0 || searchKey?.length ===undefined) 
  || searchResult?.length ===0 &&  searchKey?.length !==0 && searchKey?.length !==undefined && !loading 
  ? "lg:mx-auto" :"lg:ml-3" 
  return (
    <div className={`container mx-auto  ${largeMargin}  min-h-screen min-height`}>
      <div className="pb-10 pt-0.5 lg:mx-16 mx-4">
      {<Helmet title={"Search"}/> }
        <div className="lg:hidden mt-10 mb-4">
          <Search
            iconStyle={"left-3 top-3"}
            normalStyle={
              "bg-secondary h-10 text-sm outline-none pl-14 pr-4 rounded-full w-full border-2 border-gray-600"
            }
          />
        </div>
        <div className="py-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-10">
          {loading && <MultiLoadingCards count={3} />}
          </div>
        {searchResult?.length ===0 && (searchKey?.length ===0 || searchKey?.length ===undefined ) && <Heading className="flex justify-center items-center text-center pt-[11rem] md:pt-80 lg:pt-56">Search Courses</Heading>}   
        {searchResult?.length ===0 &&  searchKey?.length !==0 && searchKey?.length !==undefined && !loading && <Heading className="flex justify-center items-center text-center pt-[11rem] md:pt-80 lg:pt-56">No courses matched</Heading>}   
          {!loading && searchResult?.length >0 && searchKey?.length !==0  &&
          <>
           <Heading>Search Result </Heading>
          <div className="py-2  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-12">
            {
              searchResult?.map((_result, index) => {
                const {
                  thumbnailUrl,
                  courseId,
                  courseTitle,
                  totalEpisodeDuration,
                  courseRating,
                  totalEpisodeCount,
                  isWishlisted,

                } = _result;
                const content = (
                  <StarRating
                    isStarRatingActive={true}
                    // getRating={getRating}
                    rating={courseRating}
                  />
                );
                const action = (
                  <Action
                    totalEpisodeCount = {totalEpisodeCount}
                    isWishlisted = {isWishlisted}
                    courseTitle = {courseTitle}
                    handleWishList = {handleWishList}
                    courseId = {courseId}
                  />
                );
                return (
  
                  <Card
                    cardImg={
                      thumbnailUrl || imagePath("/Mycourse/mycourseCard.png")
                    }
                    title={courseTitle || ""}
                    content={content}
                    noBg={false}
                    subtitle={
                      <Duration
                        hours={getHours(totalEpisodeDuration) }
                        minutes={getMinutes(totalEpisodeDuration)  }
                        seconds={getSeconds(totalEpisodeDuration)}
                        className="h-6"
                      />
                    }
                    route={`/course/${courseId}`}
                    action={action}
                    key={index}
                    widthSet={false}
                    // imageCardCls="lg:h-20v xs:h-[12vh] sm:h-10v"
                    imageCardCls="h-full"
                    imageCls={"w-full sm:h-[6rem] xs:h-[5rem] md:h-[5rem] lg:h-32"}
                    twoColumn={true}
                    cardCls={"flex md:flex md:bg-ternary md:p-3 lg:p-0 lg:block"}
                    handleClick ={handleClick}
                    thumbDimension={WATCH_NOW_THUMB_DIMENSION}
                    isWatchNowCard={true}
                  />
                );
              })
            }
          </div>
          </>
           }
      </div>
    </div>
  );
};

export default SearchResults;
