import { axiosPost } from "axiosRequests";
import {
  PHONE_REGISTER_URL,
  RESEND_LOGIN_OTP_URL,
  VERIFY_PHONE_OTP_URL,

} from "constants/Apis";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import { STATUS } from "constants/status.constant";

const isEncrypt = true 
const headers = {}

const model = {
  state: {
  },

  reducers: {
  },

  effects: (dispatch) => ({
    async requestPhoneSignUp(body, undefined, callback) {   
      try{
        const response = await axiosPost(`${PHONE_REGISTER_URL}`, body, headers, isEncrypt);
        const {data,status} =response
        if (STATUS.includes(status)) {
          data?.statusMessage && callback("success",data?.statusMessage)
        }
        else{
          data?.error?.message && callback("error",data?.error?.message)
        }
      }
      catch(err){
        console.log(err,"Error in phone signup")
      }
    },
    async requestVerifyPhoneOTP(body,undefined,callback){
      try{
        const response =await axiosPost(VERIFY_PHONE_OTP_URL, body, headers, isEncrypt )
        const {data ,status} =response
        if(STATUS.includes(status)){
          if(data?.isNewUser){
            callback(200,MESSAGE_CONSTANTS.CREATE_ACCOUNT_MSG)
            dispatch?.registerWithEmail?.setCreateAccountDetails(data)
          }else{
            callback(200,MESSAGE_CONSTANTS.LOGIN_MSG,true)
            dispatch?.registerWithEmail?.setCreateAccountDetails(data)
          }
        }
        else{
          data?.message && callback("error",data?.message)
        }
      }
      catch(err){
        console.log(err,"error in verify phone OTP")
      }
    },
    async requestResendLoginOtp(body,undefined,callback){
      try{
      const response =await axiosPost(RESEND_LOGIN_OTP_URL, body, headers, isEncrypt)
      const {data,status}  = response
      if(STATUS.includes(status)){
        data?.statusMessage &&  callback("success",data?.statusMessage)
      }
      }
      catch(err){
        console.log(err,"error in resent Login OTP") 
      }
    }
  }),
};
export default model;
