import { CONTENT_STATUS_URL,VIDEO_FEEDBACK_URL } from "constants/Apis";

import { axiosPost, axiosPut } from "axiosRequests";
import { STATUS } from "constants/status.constant";
import { Notifier } from "components/Notifier";
import { MESSAGE_CONSTANTS } from "constants/app.constants";

export const updateLastWatched = async (body={}) => {
    try {
        const response = await axiosPut(CONTENT_STATUS_URL,body);
    } catch (error) {
        console.log("last wacthed error", error);
    }
}
export const requestFeedbackOnEpisodes = async(body,cb)=>{
    try{    
      const response = await axiosPost(`${VIDEO_FEEDBACK_URL}/${body?.courseId}/episode/${body?.epsiodeId}/feedback`,{ "feedback" : body?.feedback})
      const {data, status} = response 
      if(STATUS.includes(status)){
        cb(data?.success)
        // Notifier(status,MESSAGE_CONSTANTS?.FEEDBACK_MSG)
      }
      else{
        console.log("feedback else part error")
      }
    }   
    catch(err){
        console.log("feedback error",err)
    }
}