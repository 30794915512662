import store from "store";
const dispatch = store.dispatch;

const { playerInitialised } = store.getState()?.videoPlayer

export const play = () => {
    let payload = { playing: true };
    payload = !playerInitialised ? { ...payload, playerInitialised: true } : payload;
    dispatch?.videoPlayer?.playPauseVideo(payload);
}

export const pause = () => {
    dispatch?.videoPlayer?.playPauseVideo({playing: false});
}

export const resetPlayer = () => {
    dispatch?.videoPlayer?.playPauseVideo({playing: false, playerInitialised: false });
}
