import Image from "components/Image"
import { imagePath } from "utils/assetHelper"

const CardImage = (props) => {
    const { avatar, src, height, width, className, playIcon,imageCls, dimension } = props
    const applyStyle = avatar ? `h-${height} w-${width} rounded-full object-contain bg-white cursor-pointer` : `h-${height} w-${width} w-full bg-black ${className}`
    return (
        <div className={`relative ${imageCls? imageCls : ""}`}>
            {
                playIcon &&
                <div className="absolute flex justify-center items-center rounded h-full w-full bg-opacity-25">
                    {/* <Image src={imagePath("/Home/playicon.svg")} icon={true} className="" /> */}
                    <Image src={imagePath("/Home/playicon.svg")} icon={true} className="" />
                </div>
            }
            <Image src={src} dimension={dimension} alt="temp" className={`${applyStyle} ${className}`} {...props} />
        </div>
    )
}

CardImage.defaultProps = {
    avatar: false,
    src: "",
    height: "",
    width: "",
    className: ""
}
export default CardImage 