import { LDAnalytics } from "utils/analyticsHelper.js";

class SignupLoginEvents extends LDAnalytics {
  event_Signup_Login_Option_Click() {
    return {
      event: {
        module_name: "Signup",
        feature_name: "SignUp Loign Option",
        event_name: "Signup_Login_Option_Click",
        event_label: "Sign up and Login Option",
      },
    };
  }
  event_Signup_Email_Input_Click() {
    return {
      event: {
        module_name: "Signup",
        feature_name: "Signup Login",
        event_name: "Signup_Email_Input_Click",
        event_label: "-",
      },
    };
  }
  event_Signup_Cancel_Click() {
    return {
      event: {
        module_name: "Signup",
        feature_name: "Signup Login",
        event_name: "Signup_Cancel_Click",
        event_label: "-",
      },
    };
  }
  event_Signup_Email_Signin_Btn_Click() {
    return {
      event: {
        module_name: "Signup",
        feature_name: "Signup Login",
        event_name: "Signup_Email_Signin_Btn_Click",
        event_label: "Signup or Login",
      },
    };
  }
  event_Signup_Email_Reset_Pwd_Click() {
    return {
      event: {
        module_name: "Signup",
        feature_name: "Signup Login",
        event_name: "Signup_Email_Reset_Pwd_Click",
        event_label: "-",
      },
    };
  }
  event_Signup_CreateAccount_Name_Input_Click() {
    return {
      event: {
        module_name: "Signup",
        feature_name: "Signup Login",
        event_name: "Signup_CreateAccount_Name_Input_Click",
        event_label: "User Full Name",
      },
    };
  }
  event_Signup_CreateAccount_Dob_Input_Click() {
    return {
      event: {
        module_name: "Signup",
        feature_name: "Signup Login",
        event_name: "Signup_CreateAccount_Dob_Input_Click",
        event_label: "User Dob",
      },
    };
  }
  event_Signup_Save_Btn_Click(){
      return {
          event:{
              module_name:"Signup",
              feature_name:"Signup Login",
              event_name:"Signup_Save_Btn_Click",
              event_label:"Save Click"
          }
      }
  }
  event_Signup_Country_Code_Select(){
      return{
          event:{
              module_name:"Signup",
              feature_name:"Signup Login",
              event_name:"Signup_Country_Code_Select",
              event_label:"country code"
          }
      }
  }
  event_Signup_With_Phone_Input_Click(){
      return{
          event:{
              module_name:"Signup",
              feature_name:"Signup Login",
              event_name:"Signup_With_Phone_Input_Click",
              event_label:"Phone Login Input"
          }
      }
  }
  event_Signup_With_Phone_Generate_Btn_Click(){
      return{
          event:{
              module_name:"Signup",
              feature_name:"Signup Login",
              event_name:"Signup_With_Phone_Generate_Btn_Click",
              event_label:"Click on Otp btn"
          }
      }
  }
  event_Signup_With_Phone_Otp_Input_Click(){
      return{
          event:{
              module_name:"Signup",
              feature_name:"Signup Login",
             event_name:"Signup_With_Phone_Otp_Input_Click",
             event_label:"Opt Input"
          }
      }
  }
  event_Signup_With_Phone_Save_Btn_Click(){
      return{
          event:{
              module_name:" Signup",
              feature_name:"Signup Login",
              event_name:"Signup_With_Phone_Save_Btn_Click",
              event_label:"Save Click"
          }
      }
  }
  event_Signup_With_Phone_Resend_Otp_Click(){
      return{
          event:{
              module_name:"Signup",
              feature_name:"Signup Login",
              event_name:"Signup_With_Phone_Resend_Otp_Click",
              event_label:""
          }
      }
  }
  event_Forgot_Pwd_Input_Click(){
    return {
      event:{
        module_name:"Forgot Password",
        feature_name:"Forgot Password",  
        event_name:"Forgot_Pwd_Input_Click",
        event_label:"Forgot Password"
      }
    }
  }
  event_Forgot_Pwd_Save_Btn_Click(){
    return{
      event:{
        module_name:"Forgot Password",
        feature_name:"Forgot Password",
        event_name:"Forgot_Pwd_Save_Btn_Click",
        event_label:""
      }
    }
  }
  event_Reset_Pwd_Input_Click(){
    return {
      event:{
        module_name:"Reset Password",
        feature_name:"Forgot Password",
        event_name:"Reset_Pwd_Input_Click",
        event_label:"Reset Password"
      }
    }
  }
  event_Reset_Pwd_Save_Btn_Click(){
    return {
      event:{
        module_name:"Reset Password",
        feature_name:"Reset Password",
        event_name:"Reset_Pwd_Save_Btn_Click",
        event_label:"Reset Password"
      }
    }
  }
  event_User_Signup_Success(){
    return {
      event:{
        module_name:"Signup",
        feature_name:"Signup Login",
        event_name:"User_Signup_Success",
        event_label:""
      }
    }
  }
  event_Signup_Screen_Loaded(){
    return {
      event:{
        module_name:"Signup",
        feature_name:"Signup Login",
        event_name:"Signup_Screen_Loaded",
        event_label:""
      }
    }
  }
}

const signupLoginEvents = new SignupLoginEvents();
export default signupLoginEvents;
