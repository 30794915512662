import useMedia from "hooks/deviceHelper/useMedia";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { LDSmall, LDSpan, Subtitle, Title } from "stories/Typography";

const Tags = ({ children, handleClick, isSelected, id, list ,... props}) => {
  const isMobile = useMedia();
  const mobileCls = isMobile ? "mr-3" : ""
  const commonStyle =
    "decoration-none text-white border border-white xs:rounded md:rounded lg:mr-3 hover:text-black cursor-pointer px-[.8rem] py-1";

  return (
    // <div className="mr-2">
      <LDSmall
        // className={commonStyle}
        className={
          isSelected
            ? `${commonStyle} bg-[#eaeaeae6] opacity-90 text-[#2E333F] ${mobileCls}`
            : `${commonStyle} bg-[#f2f2f2b3] opacity-70 text-[#2E333F] ${mobileCls}`
        }
        onClick={() => handleClick(id, list)}
        {...props}
      >
        {children}
      </LDSmall>
    // </div>
  );
};

Tags.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
};

Tags.defaultProps = {
  children: "Welcome",
  handleClick: () => {},
  isSelected: "",
  id: "1",
};

export default Tags;
