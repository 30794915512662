import { rescheduleStartClickEvent, sessionCanceStartClickEvent, sessionCancelEndClickEvent } from "analytics/mysessions";
import { Modal } from "antd";
import CardImage from "components/Card/CardImage";
import { Notifier } from "components/Notifier";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LDImage from "stories/Image";
import Textarea from "stories/Textarea";
import { Description } from "stories/Typography";
import { SESSION_STATUS, UTCTimeConverter, formatDate, getSessionDuration, getTimeDifference } from "utils";
import { imagePath } from "utils/assetHelper";

const RescheduleModal = (props) => {
  const { handleClose, show, data, tabType } = props;
  //reschedule flag
  const isRescheduleflag = data?.cancelled_by === null && data?.old_slot_id === null || data?.status === SESSION_STATUS.SESSION_RESCHEDULED && data?.cancelled_by === "instructor";

  const [cancelReason, setCancelReason] = useState("")

  const [error, setError] = useState("")

  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => state?.profile?.profileDetails); // to check first name last name are present or not
  const [isCancelSessionConfirmed, setIsCancelSessionConfirmed] =
    useState(false);
  //to get time after 24hrs
  const differenceTime = getTimeDifference(data?.start_time);

  const history = useHistory();
  const Headder = ({ children, className }) => {
    return (
      <h1
        className={`${className} font-['BWModelica-Medium'] text-left  font-medium	 ${
          differenceTime?.days >= 1 ? "text-white" : "text-remove"
        } `}
      >
        {children}
      </h1>
    );
  };

  const JoinButton = ({ children, className, onClick, disabled }) => {
    return (
      <button onClick={onClick} className={className} disabled={disabled}>
        {children}
      </button>
    );
  };

  const handlecancel = (e) => {
    if(cancelReason?.length === 0 ){
      setError({cancelReasonError: "Enter cancellation reason"})
    }
    // else if(cancelReason?.length <= 20){
    //   setError({cancelReasonError: "Reason should be atleast 30 character long!"})
    // }
    else{
      const payload = {
        meeting_id: data?.id,
        cancellation_reason: cancelReason
      };
      if (data?.id) {
        dispatch?.instructor?.requestCancelMeeting(payload, (status, params, message) => {
          if(status === 200){
            cancelSessionEndEvent()
            e.stopPropagation();
            handleClose()
            history.push({
              pathname: `${ROUTE_CONSTANTS?.BOOKING_CONFIRMED}/${data?.id}`,
              search: "?_c=true",
              state: { data, profileInfo },
            });
          }else{
            Notifier(status,(message || "Something went wrong while cancelling." ))
            handleClose()
          }
        });
      }
    }
    setTimeout(()=>{
      setError("")
    },2000)
  };
  

  const sessionEventStartInfo = { // tracking purpose
    StartDate: formatDate(data?.date,"DDMMYYYY"), 
    StartTime : UTCTimeConverter(data?.start_time)
  }
  const extraParams = {
    event_label : profileInfo?.mobileNumber || profileInfo?.email,
    instructor_name :data?.instructor_name ||  data?.instructorsId ,
    session_info : JSON.stringify(sessionEventStartInfo)
  }

  const rescheduleStartEvent = () => {
    rescheduleStartClickEvent(extraParams)
    const sessionDuration = getSessionDuration(data?.time_slot?.start_time, data?.time_slot?.end_time)
    history.push({
      pathname: `${ROUTE_CONSTANTS?.INSTRUCTORINFORMATION}/${data?.instructorsId}`,
      search: `?_rs=${true}&_m=${data?.id}`,
      state: { data, profileInfo},
    })
   }

   const cancelSessionStartEvent = () =>{
    setIsCancelSessionConfirmed(true)
    sessionCanceStartClickEvent(extraParams)
   }

   const cancelSessionEndEvent = () =>{
    const cancleEndInfo = {
      StartDate: formatDate(data?.date,"DDMMYYYY"), 
      StartTime : UTCTimeConverter(data?.start_time),
      Reason :cancelReason
    }
    const cancleEndParams = {
      event_label : profileInfo?.mobileNumber || profileInfo?.email,
      instructor_name :data?.instructor_name ||  data?.instructorsId ,
      session_info : JSON.stringify(cancleEndInfo)
    }
    sessionCancelEndClickEvent(cancleEndParams)
   }

   const isAllowedToReschedule = (!isRescheduleflag && differenceTime?.days >=1 )|| (data?.is_reschedulled === true && differenceTime?.days >=1)
   const isDisabled = cancelReason?.length>0
  return (
    <div className="">
      <Modal
        className=""
        visible={show}
        closable={true}
        onCancel={handleClose}
        centered
        width={400}
        footer={null}
        bodyStyle={{
          backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
          borderRadius: "10px",
        }}
        maskStyle={{
          backdropFilter: "blur(3px)",
          "-webkit-backdrop-filter": "blur(3px)",
        }}
      >
        <div className="text-left">
          {differenceTime?.days < 1 && (
            <div className="text-left">
             <Description className="text-white font-['BWModelica-Medium'] font-medium	 text-left text-[1rem] mb-[1rem] pt-[1.5rem] leading-[1.6]	">
             Kindly note that rescheduling or canceling is not allowed within 24 hours of your upcoming session.<br></br> Thank you for your understanding.
            </Description>
             {/* <Headder className="leading-[1.5]	text-remove text-[14px] text-left">
             Modify the session is allowed only 24 hours before the session
            </Headder> */}
            </div>

          )}

          {
            isAllowedToReschedule ? <>
            <>
              <Headder className="font-['BWModelica-Medium'] text-left text-[1.1rem] leading-[1.5] mt-[1rem] mb-[1rem]">You are not allowed to modify this session.</Headder>
              <div className="text-[14px] text-remove text-left leading-[1.5] mt-[.3rem]">Note: You are allowed to either cancel or reschedule the session only once. </div>
              <div className="flex justify-left mt-6">
                <JoinButton
                  className="bg-greeny text-white font-['BWModelica-Medium'] px-[2rem] md:py-[.6rem] py-[.6rem] rounded-lg text-[14px] font-medium text-left"
                  onClick={handleClose}
                >
                  Ok
                </JoinButton>
              </div>
            </>
            </> : <>
            {differenceTime?.days >= 1 && (
            <Headder>
              <div className=" font-['BWModelica-Medium'] font-medium text-[23px] text-left">
                 {differenceTime?.days >= 1 && isCancelSessionConfirmed ? "Are you sure you want to cancel the session ?"  :
                 "Modify Session ?"
                 }
             
                
                {/* <span className="text-greeny"> {data?.instructor_name}</span> */}
              </div>
              <div className="flex flex-col gap-[1rem] justify-start text-white text-[0.8rem] md:text-[1rem] mt-5">
                <div className="flex gap-1 items-center">
                  <CardImage
                    style={{
                      // width: "64px",
                      // height: "64px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      objectFit: "cover",
                    }}
                    src={
                      data?.instructor_image || imagePath("/instructors.svg")
                    }
                    className="w-[40px] h-[40px] md:w-[40px] md:h-[40px]"
                  />
                  <span className="text-greeny text-[1.5rem] font-['BWModelica-Medium'] ml-[.7rem]"> {data?.instructor_name}</span>
                </div>
                <div className="flex gap-[.6rem] items-center font-['BWModelica-Medium']">
                    <div className="re--calendar flex items-center gap-[.7rem]">
                    <LDImage src={imagePath("/calendar.svg")} className="w-[1.5rem]" />
                   
                  {`${formatDate(
                    data?.start_time,
                    "DDMMYYYY"
                  )}`}{' ,'}
                    </div>
                  <div className="re--clock flex items-center gap-[.7rem]">
                  {/* <LDImage src={imagePath("/clock.svg")} className="w-[1.5rem]" /> */}
                  {`${UTCTimeConverter(data?.start_time)} - ${UTCTimeConverter(data?.end_time)}`}
                  </div>
                </div>
              </div>
            </Headder>
          )}
          {differenceTime?.days < 1 && (
            <div className="flex justify-left mt-7">
              <JoinButton
                className="bg-greeny font-[BWModelica-Medium] text-white px-[2rem] md:py-[.6rem] py-[.5rem] rounded-lg text-[16px] font-medium text-center"
                onClick={handleClose}
              >
                Ok
              </JoinButton>
            </div>
          )}
          {differenceTime?.days >= 1 && !isCancelSessionConfirmed && (
            <div className="flex justify-between mt-5 gap-[1rem]">
              <JoinButton
                className="bg-greeny text-white font-['BWModelica-Medium'] sm:px-[1.5rem] px-[.2rem] md:py-[.6rem] py-[.6rem] w-full rounded-lg text-[14px] font-medium text-center"
                onClick={() =>rescheduleStartEvent()}
                // onClick={handleReschedule}
              >
                Reschedule
              </JoinButton>
              <JoinButton
                className="bg-red-400 text-white sm:px-[1.5rem] px-[.2rem] font-['BWModelica-Medium'] md:py-[.6rem] py-[.6rem] w-full rounded-lg text-[14px] font-medium text-center"
                // onClick={handlecancel}
                onClick={() => cancelSessionStartEvent()}
              >
                Cancel Session
              </JoinButton>
            </div>
          )}
          {differenceTime?.days >= 1 && isCancelSessionConfirmed && (
            <>
            <Textarea 
              className="w-full placeholder:pt-[6.3px]  placeholder:pl-[0px] text-white mt-4 outline-none  bg-sidebarSelected  border-2 border-formBorder rounded-lg resize-none p-[0.6rem]"
              placeholder="Cancellation reason" 
              onChange={(e)=> setCancelReason(e.target.value)} value={cancelReason} rows={4} cols={30} />
              { error?.cancelReasonError && <div className="text-remove">{error?.cancelReasonError}</div>}
            <div className="flex items-center gap-[1rem]">
            <JoinButton
              className="bg-greeny w-[100%] font-['BWModelica-Medium'] mt-4 text-white px-[2rem] md:py-[.6rem] py-[.6rem] rounded-lg text-[14px] font-medium text-center"
              onClick={handleClose}
            >
              No
            </JoinButton>
                <JoinButton
                className={`${cancelReason ? "bg-red-400 opacity-[1] cursor-pointer " : "bg-[#8F9297] cursor-not-allowed"}  w-[100%] font-['BWModelica-Medium'] mt-4 text-white px-[2rem] md:py-[.6rem] py-[.6rem] rounded-lg text-[14px] font-medium text-center`}
                disabled={!isDisabled}
                onClick={handlecancel}
                >
                Yes, Cancel
                </JoinButton>
            </div>
            </>
          )}
            </>
          }
         
        </div>
      </Modal>
    </div>
  );
};

export default RescheduleModal;
