import React from 'react'
import { Modal } from "antd";
import { COLOR_CONSTANTS } from 'constants/color.constants';
import { LDParagraph, LDSpan } from 'stories/Typography';
import CardImage from 'components/Card/CardImage';
import { getInitial } from 'utils';
import "./index.css"
import LDMarkdown from 'stories/LDMarkdown';

const AuthorModal = (props) => {
 const { currentAuthor,show, handleClose } = props

  const {instructorThumbnail, instructorName,location, designation,instructorDescription  } = currentAuthor
  return (
    <div>
      <Modal
        visible={show}
        closable={true}
        onCancel={handleClose}
        centered
        width={400}
        footer={null}
        bodyStyle={{
          backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
          borderRadius:"10px"
        }}
        maskStyle={{
          backdropFilter: "blur(3px)",
          "-webkit-backdrop-filter": "blur(3px)",
        }}
      >
        <div className='text-center ld-center'>
          <LDSpan className="">
            {instructorThumbnail ? 
                  <CardImage
                    style={{width:"150px", height:"150px",borderRadius: "50%",overflow:"hidden",objectFit: "cover"}}
                    src={instructorThumbnail}
                    avatar={true}
                    className="mx-auto"
                   /> :  
                getInitial(instructorName)
              }
          </LDSpan>
          <LDParagraph className="ld-author-style mt-2">{instructorName} </LDParagraph>
          <LDParagraph className="ld-author-design-style">{designation}</LDParagraph>
          </div>
          <div className='author-line-clamp'>
            <LDMarkdown 
              content = {instructorDescription} 
              isActiveShowMore = {true}
              lengthToShow = {500} 
            />
          </div>
          
          {/*To Activate show more and less pass isActiveShowMore = true and lengthToShow =500 or any thing
            if not just pass entire  just pass content and length would be content.length (it will take enter content)
          */}
      </Modal>
    </div>
  )
}

export default AuthorModal
