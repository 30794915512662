import Image from 'components/Image'
import Modal from 'components/Modal'
import { COLOR_CONSTANTS } from 'constants/color.constants'
import React, { useState } from 'react'
import { LDInput } from 'stories/Form'
import FormButton from 'stories/FormButton'
import { Heading } from 'stories/Typography'
import { EmailCheckRegEx } from 'utils'
import { imagePath } from 'utils/assetHelper'

const UpdateEmailForSession = ({ openModal, handleUpdateEmail }) => {
  
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({});

  const errorMsg = {};

  const runValidation = ()=> {
    if(email.length === 0) {
      errorMsg.message = "Enter email"
    }
    else if(!EmailCheckRegEx(email)){
      errorMsg.message = "Enter a valid email"
    }
  }

  const handleSubmit = () => {
    runValidation();
    if(Object.keys(errorMsg).length ===0){
      handleUpdateEmail(email)
    }else{
      setEmailError(errorMsg)
      setTimeout(()=>{
        setEmailError({})
      },2000)
    }
  }

  return (
    <div>
      <Modal
        show={openModal}
        closable={false}
        bodyStyle={{
          padding: "0px",
          backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
          boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.4)",
          borderRadius: "5px",
        }}
        onCancel={()=>{}}
        wrapClassName="bg-[#272d3533] backdrop-blur-sm"
        width={400}
        height={800}
        maskStyle={{
          backgroundColor: COLOR_CONSTANTS.MODAL_COLOR,
          opacity: "0.8",
        }}
        footer={null}
      >
        <div className=" my-[19%] px-[5%] md:px-[15%]  m-auto ">
          <div className="py-10 rounded-md shadow-lg relative">
            <Image
              src={imagePath("/lifedemyIcon.png")}
              icon={true}
              alt="logo"
              className="absolute h-12 top-[-1.6rem] left-[50%] "
              style={{ transform: "translate(-50%,0%)" }}
            />
            <div className="mb-6 text-center">
              <Heading style={{fontSize:"16px"}}> Please update your email to receive session details</Heading>
            </div>
            <div className="flex">
              <LDInput
                type="text"
                value={email}
                // onClick={()=>signupWithPhoneInputClickEvent()}
                placeholder="Email"
                pattern="[0-9]*"
                onChange={(e)=>setEmail(e.target.value)}
                className="px-4 py-2 w-full rounded-lg bg-logoColor border-2 outline-none  auth-input-text-style border-gray-600"
              />
            </div>
            <div className="text-remove text-md mt-2 font-medium">{emailError?.message && emailError?.message}</div>

            <FormButton
              type="submit"
              className="bg-greeny auth-button-text-style w-full  px-3 rounded mt-5"
              style={{ backgroundColor: COLOR_CONSTANTS.GREEN, color: "white" }}
              onClick={handleSubmit}
            >
            Update Email
            </FormButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UpdateEmailForSession
