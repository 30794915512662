import React from "react";

const BannerLoader = () => {
  return (
    <>
      <div className="rounded-md max-h-md">
        <div className="flex space-x-4 animate-pulse">
          <div className="flex-1 py-1 space-y-4">
            <div className="w-full h-64 bg-gray-500 rounded"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerLoader;
