import { LDSpan ,LDSmall } from "stories/Typography";
import { password_rule_list } from "constants/Content/rules.constant";
import { ratingValList } from "constants/app.constants";
import {PhoneNumberUtil} from 'google-libphonenumber'
import { notification } from "antd";
import { groupBy } from 'ramda'

export const getHours = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var hDisplay = h > 0 && h;
  return hDisplay ?  hDisplay : "";
};

export const getMinutes = (d) => {
  d = Number(d);
  var m = Math.floor((d % 3600) / 60);
  var mDisplay = m > 0 && m;
  return mDisplay ? mDisplay : "";
};

export const getSeconds = (d) => {
  d = Number(d);
  var s = Math.floor((d % 3600) % 60);
  var sDisplay = s > 0 && s;
  return sDisplay ? `${sDisplay}s` : "" ;
};

export const getInitial = (userName) => {
  const strArray = userName?.split(" ");
  const firstChar = strArray?.shift()?.charAt(0)
  const lastChar = strArray?.[0]?.[0]
  const result =`${firstChar}${lastChar === undefined ? " " :lastChar}`
  return result.toUpperCase()
};

//Copy to clipboard

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const getReadableDate = (createdDate) => {
  let date1 = new Date(createdDate);
  let date2 = new Date();

  let delta = Math.abs(date2 - date1) / 1000;

  let seconds = Math.floor((new Date() - date1) / 1000);
  
  let days = Math.floor(delta / 86400);
  delta -= days * 86400;

  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  let minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  let month = Math.floor(days / 30);
  let years = Math.floor(days / 365);
  if (years) {
    if (years === 1) {
      return <LDSmall className="text-comment">{years} year ago</LDSmall>;
    } else {
      return <LDSmall className="text-comment">{years} years ago</LDSmall>;
    }
  } else if (month) {
    if (month === 1) {
      return <LDSmall className="text-comment">{month} month ago</LDSmall>;
    } else {
      return <LDSmall className="text-comment">{month} months ago</LDSmall>;
    }
  } else if (days) {
    if (days === 1) return <LDSmall className="text-comment">{days} day ago</LDSmall>;
    return <LDSmall className="text-comment">{days} days ago</LDSmall>;
  } else if (hours) {
    if (hours === 1) return <LDSmall className="text-comment">{hours} hour ago</LDSmall>;
    return <LDSmall className="text-comment">{hours} hours ago</LDSmall>;
  }
  else if(minutes === 0){
    if (seconds === 1 && seconds >=0 ) return <LDSmall className="text-comment">{seconds < 0 ? 0 : seconds} second ago</LDSmall>;
    return <LDSmall className="text-comment">{seconds < 0 ? 0 : seconds } seconds ago</LDSmall>;
  } 
  else if (minutes) {
    if (minutes === 1) return <LDSmall className="text-comment">{minutes} minute ago</LDSmall>;
    return <LDSmall className="text-comment">{minutes} minutes ago</LDSmall>;
  } 
};
export const CompareDates = (createdDate, updatedAt) => {
  let date1 = new Date(createdDate);
  let date2 = new Date(updatedAt);
   if(date1.getTime() == date2.getTime()){
    return true;
   }
   else{
    return false;
   }
};

//Add dots at end
export const truncateText = (text,length = 14) => {
  let newTitle = "";
  if (text?.length > length) {
    newTitle += text?.slice(0, length);
  } else {
    return text;
  }
  return newTitle + "...";
};

// export let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/

export const regexEmail =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const regexMobile = /^[6789][0-9]{9}$/;

export const EmailCheckRegEx = (email) => {
  if (email.match(regexEmail)) {
    return true
  }
  else {
    return false
  }
}  

export const MobileCheckRegEx = (number) => {
  if (number.match(regexMobile)) {
    return true
  }
  else {
    return false
  }
}   

export const YearCheckRegEx  = /^\d{5}$/

export const specialCharRegx = /[\@\#\$\%\^\&\*\(\)\_\+\!]/;

export const lowerCaseRegx = /[a-z]/;

export const upperCaseRegx = /[A-Z]/;

export const digitRegx = /[0-9]/;

export const getDateformat =(date) =>{
  if(date === "Invalid Date"){
    return ""
  }
  const newDate = new Date(date)
  let d=new Intl.DateTimeFormat('en-GB',{year:"numeric", month:"short",day:"2-digit"}).format(newDate).split(" ").join(" ")
  return d
}

export const getRoundedVal = (val)=>{
  return Math.round(val)
}

export const getRoundAndFloatVal = (rating) => {
  return Math.round(rating);
  // const afterDotValue = Number((rating-Math.trunc(rating)).toFixed(2))
  // console.log("value is <>>", afterDotValue, rating);
  // if(afterDotValue === 0.5){
  //   return rating
  // }else{
  //  return Math.round(rating)
  // }
};

export const AlphaCheckRegEx = (value) =>{
  return value.replace(/[^A-Za-z ]/gi, "")
}
export const enableDateBeforeToday =() =>{
  return new Date(Date.now() - 1 * 864e5 - new Date(Date.now() - 1 * 864e5).getTimezoneOffset() * 6e4).toISOString().split('T')[0]
}

export const getYesterdayDate=()=>{
  return new Date(new Date().getTime() - 24*60*60*1000).toDateString();
}
export const getUrlEncode=(url)=>{
  const encodedUrl = window.encodeURIComponent(url);
  return encodedUrl
}
export const convertDateFormat=(uploadDate)=>{
  var monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
  var date = new Date(uploadDate);
  let formattedate = date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear()
  return formattedate;
}

export const checkPasswordIsValid=(password)=>{
  const validatePassword = new RegExp(`^(?=[a-zA-Z0-9#@$?]{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*`)
  if (validatePassword.test(password)){
    return true
  }
  else{
    return false
  }
}


export const generateRatingValue =(rating)=>{
  if(rating === 0 ){
    return 0;
  }
  const matchingObj = ratingValList?.find((range) => { return rating > range.min && rating <= range.max });
  return matchingObj?.value - 0.2 || 0; // Subtracting 0.2 to fix rating issue -> 2.5 -0.2 = 2.3 will show 2.5 stars filled as per the current package
}

export const readableDateFormate =(date,separator="-")=> {
  if(date){
    const newDate = new Date(date)  
    var day = newDate.getDate();
    var month = newDate.getMonth() + 1;
    var year = newDate.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }  
    return day + separator + month + separator + year;  
  }
}

export const stringSlice = (value, unit) => {
  return value.slice(unit)
}


export const IsPhoneNumberValid = phoneNumber => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    const number = phoneUtil.parse(phoneNumber, null)

    return phoneUtil.isValidNumber(number)
}


export const getSalesDetailsForRazorPay =(location, EXCLUDE_KEYS)=>{
  let data = {}
  const searchParams = new URLSearchParams(location.search);
  for (const [key, value] of searchParams.entries()) {
     if(!Object.keys(EXCLUDE_KEYS).includes(key)){
        data[key] = value
     }
  }
  return data
}

export const tooManyAttemptAlert = (key,param = "Something went wrong, please try again later") => {
  notification["error"]({
    message: param,
  });
};

//converting Date
// export const formatDate=(date) =>{
//   var d = new Date(date),
//       month = '' + (d.getMonth() + 1),
//       day = '' + d.getDate(),
//       year = d.getFullYear();

//   if (month.length < 2) 
//       month = '0' + month;
//   if (day.length < 2) 
//       day = '0' + day;

//   return  [year, month, day].join('-');
// }
export const formatDate=(date, DDMMYYYY= "") =>{
  var d = new Date(date),
      month = '' + (d?.getMonth() + 1),
      day = '' + d?.getDate(),
      year = d?.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return DDMMYYYY ?  [day, month, year].join('-') : [year, month, day].join('-');
}

// reversing Date YYYY-MM-DD to DD-MM-YYYY
export const reverseDate = (date)=>{
  const result1 = new Date(date).toLocaleDateString('en-GB');
  return result1
}

//converting TimeZone
export const TimeConvert= (timeString)=> {
  const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}

const MAP_TIMES_SLOTS = {
  "0": "12",
  "1": "01",
  "2": "02",
  "3": "03",
  "4": "04",
  "5": "05",
  "6": "06",
  "7": "07",
  "8": "08",
  "9": "09",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "01",
  "14": "02",
  "15": "03",
  "16": "04",
  "17": "05",
  "18": "06",
  "19": "07",
  "20": "08",
  "21": "09",
  "22": "10",
  "23": "11",
};

const MINUTES_MAP = {
  0: "00",
  1: "01",
  2: "02",
  3: "03",
  4: "04",
  5: "05",
  6: "06",
  7: "07",
  8: "08",
  9: "09",
};

export const UTCTimeConverter =(FullDate)=>{
  // const utcHourse = new Date(FullDate).getHours() 
  // const utcMinutes = new Date(FullDate).getMinutes()
  
  // const timeFormate = utcHourse >= 12 ? "PM" : "AM"
  // const _utcMinutes = MINUTES_MAP[utcMinutes] || utcMinutes
  
  // return `${MAP_TIMES_SLOTS[utcHourse]}:${_utcMinutes} ${timeFormate}` 
  const time = new Date(FullDate);
    const date =  time.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric',hour12: true })
    return date
}
export const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone


//to get time difference(24hrs from now)
export const getTimeDifference = (date)=>{
  const total = Date.parse(date) - Date.parse(new Date());
  const seconds = Math.floor( (total/1000) % 60 );
  const minutes = Math.floor( (total/1000/60) % 60 );
  const hours = Math.floor( (total/(1000*60*60)) % 24 );
  const days = Math.floor( total/(1000*60*60*24) );
    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
}

export const dateStartTime = (fullDate, isTodays = false)=>{
  var start = isTodays ? new Date() : new Date(fullDate)?.setHours(0, 0, 0, 0) 
  return start
}
export const dateEndTime = (fullDate) =>{
  var end = new Date(fullDate);
  end?.setHours(23, 59, 59, 999);
  return end
}

export const changeDateToUTCFormat = (date)=>{
  let d = new Date(date);
  let n = d.toUTCString(); //MM-DD-YY H:MI:SS
  return n
}

// to remove the country code from the guestuser's entered  mobilenumber while initiating payment
export const RemoveCountryCode = (mobileNumber)=>{
  //working only for
  const res=mobileNumber && mobileNumber?.replace(/\D/g, '')?.slice(-10);
  return res
  
} 
export const SESSION_STATUS = {
  postponed:"postponed",
  SESSION_COMPLETE:"completed",
  SESSION_CANCELLED :"cancelled",
  SESSION_UPCOMING:"upcoming",
  SESSION_ONHOLD:"on hold",
  SESSION_RESCHEDULED :"rescheduled",
  SESSION_EXPIRED:"expired",
  SESSION_ONGOING:"ongoing"
}
export const SESSION_CANCELLED_BY= {
  instructor: "instructor",
}

export const enableModifyButton = [SESSION_STATUS?.SESSION_UPCOMING, SESSION_STATUS?.SESSION_RESCHEDULED ]
export const enabledBookAgainButton = [SESSION_STATUS?.SESSION_CANCELLED, SESSION_STATUS?.SESSION_EXPIRED, SESSION_STATUS?.SESSION_COMPLETE,SESSION_STATUS?.SESSION_ONHOLD]
export const upcommingSessionIncludes = [SESSION_STATUS?.SESSION_UPCOMING, SESSION_STATUS.SESSION_ONHOLD, SESSION_STATUS?.SESSION_RESCHEDULED, SESSION_STATUS?.SESSION_ONGOING]

// add 15 minutes to sessionendtime 
export const sessionStartTime15MinsAfter = (end_time) =>{
  var date=new Date(end_time);
 var minute=date.setMinutes(date.getMinutes()+5); 
 return new Date(minute)
}


// subtract 15 minutes from sessionStartTime 
export const sessionStartTime15MinsBefore = (start_time) =>{
  var date=new Date(start_time);
 var minute=date.setMinutes(date.getMinutes()-10); 
 return new Date(minute)
}

// Get number with first descimal function
export const NumberWithFirstDecimal = (number) =>{
  const n = parseFloat(number).toFixed(1);
  return n
}


// add 60 minutes to sessionendtime 
export const sessionEndTime60MinsAfter = (end_time) =>{
  var date=new Date(end_time);
 var minute=date.setMinutes(date.getMinutes()+10); 
 return new Date(minute)
}

// utc time converter for disabling unavailable slots
export const getUTCDate = (date) =>{
const now = new Date(date);
const now_utc = new Date(now.toUTCString().slice(0, -4)); //
return now_utc
}

//get session duration
export const getSessionDuration = (start, end) =>{
  let start_time = new Date (start);
  let end_time = new Date(end);
  let mintues = (end_time.getTime() - start_time.getTime()) / 60000;
  return mintues
}

//duration map
export const TIME_DURATION_MAP = {
  15: "15mins",
  30: "30mins",
  45: "45mins",
  50:"50mins",
  55:"55mins",
  60: "1hr",
  75:"1hr 15mins",
  90:"1hr 30mins",
  105:"1hr 45mins",
  120:"2hr",
  135:"2hr 15mins",
  150:"2hr 30mins",
  165:"2hr 45mins",
  180:"3hr",
};



//groupBy
export const getSlotsGropued = groupBy((slot) => {
  return slot?.duration
})



//countrycodeMatcher
export const countryCodeMatcher = (condition, phone) =>{
 switch (condition) {
  case "+91":
    return phone?.length===10 ? true : false
  default:
    return true
 }
}