import axios from "axios";
import {  axiosPost } from "axiosRequests";
import { SEND_SUGGESTIONS_URL, TELECOM_SUPPORT_URL } from "constants/Apis";
import { Notifier } from "components/Notifier";
import { STATUS } from "constants/status.constant";
import { MESSAGE_CONSTANTS } from "constants/app.constants";

const model = {
  state: {
    loading: false,
  },

  reducers: {
    setSuggestionsLoading: (state, loading) => {
      return {
        ...state,
        loading: loading,
      };
    },
  },

  effects: (dispatch) => ({
    async requestSendSuggestions(body,undefined,callback) {
      try {
        const email = body && body.data && body.data.email
        const phone = body && body.data && body.data.phone
        const telecomPayload = {
          "fields": {
            ...body.data,
          },
          "actions": [
            {
              "type": "SYSTEM_NOTE",
              "text": "Lead Source: Lifedemy Web Support",
            }
          ]
        }
        if((email || phone) && (email.length || phone.length) && (email !== "undefined" || phone !== "undefined")) {
          const res = await axios.post(TELECOM_SUPPORT_URL, telecomPayload)
        }
      } catch(err) {
          console.log("Failed to generate support lead")
      }

      try{
         dispatch?.suggestions?.setSuggestionsLoading(true);
          const response=await axiosPost(SEND_SUGGESTIONS_URL,body)
          const { data, status }=response
          callback()
          dispatch?.suggestions?.setSuggestionsLoading(false)
          if(STATUS.includes(status)){
            Notifier(status, MESSAGE_CONSTANTS.GIVING_FEEDBACK)
          }else{
            Notifier(status, MESSAGE_CONSTANTS.GIVING_FEEDBACK_ERR_MSG)
          }
      } 
      catch(err){
        Notifier('Error', MESSAGE_CONSTANTS.GIVING_FEEDBACK_ERR_MSG)
        console.log(err,"error in suggestion")
      }  
    },
  }),
};
export default model;
