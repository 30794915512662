import React from "react";
import FormButton from "stories/FormButton";

const ReplayButton = ({ handleReplay }) => {
    return (
        <FormButton
            htmlType="submit"
            className="bg-greeny text-white rounded hover:bg-greeny hover:text-white"
            onClick={handleReplay}
        >
            Replay
        </FormButton>
    );
};

export default ReplayButton;
