import { LDAnalytics } from "utils/analyticsHelper.js";

class SuggestionEvents extends LDAnalytics {
  event_Suggestion_Page_Input_Click(){
    return {
      event:{
        module_name :"Suggestion Page",
        feature_name:"User Feedback",
        event_name:"Suggestion_Page_Input_Click",
        event_label:"Suggestion page input"
      }
    }
  }
  event_Suggestion_Page_Textarea_Click(){
    return {
        event:{
          module_name :"Suggestion Page",
          feature_name:"User Feedback",
          event_name:"Suggestion_Page_Textarea_Click",
          event_label:"Suggestion page textarea"
        }
      }
   }
   event_Suggestion_Page_Submit_Btn_Click(){
       return{
           event:{
            module_name :"Suggestion Page",
            feature_name:"User Feedback",
            event_name:"Suggestion_Page_Submit_Btn_Click",
            event_label:"Suggestion page Submit button"
           }
       }
   }
   event_Support_Page_Loaded(){
    return{
        event:{
            module_name :"Support Page",
            feature_name:"User Support",
            event_name:"Support_Page_Loaded",
            event_label:""
        }
    } 
  }
}

const suggestionEvents = new SuggestionEvents();
export default suggestionEvents;
