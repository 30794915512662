export const INITIATE_PURCHASE_CLICK = "Initiate_Purchase_Click";
export const PAYMENT_LOAD_START = "Payment_Load_Start";
export const PAYMENT_LOAD_END = "Payment_Load_End";
export const PAYMENT_COUPON_CODE_INPUT_CLICK = "Payment_Coupon_Code_Input_Click";
export const PAYMENT_COUPON_CODE_APPLY_BTN_CLICK  = "Payment_Coupon_Code_Apply_Btn_Click";
export const PAYMENT_USE_COUPON_CODE_CLICK ="Payment_Use_Coupon_Code_Click";
export const PAYMENT_COUPON_CODE_MODAL_CANCEL_CLICK = "Payment_Coupon_Code_Modal_Cancel_Click";
export const PAYMENT_COUPON_CODE_MODAL_APPLY_CLICK ="Payment_Coupon_Code_Modal_Apply_Click";
export const PAYMENT_CONTINUE_PURCHASE_BTN_CLICK ="Payment_Continue_Purchase_Btn_Click";
export const PAYMENT_STATUS_LOAD = "Payment_Status_Load";
export const PAYMENT_GO_TO_COURSE_BTN_CLICK= "Payment_Go_To_Course_Btn_Click"; // need to add these in GTM
export const PAYMENT_STATUS_TRY_AGAIN_BTN_CLICK = "Payment_Status_Try_Again_Btn_Click"; // need to add these in GTM
export const PURCHASE_EVENT  = "Purchase"

export const PAYMENT_SUCCESS = 'Payment_Success'
export const PAYMENT_FAILED = 'Payment_Failed'


export const PAYMENT_UPDATE_EMAIL_SUCCESS = "Payment_Update_Email_Success"
export const INITIATE_GUEST_PAYMENT = "Initiate_Guest_Payment"
export const GUEST_PAYMENT_PAGE_LOADED = "Guest_Payment_Page_Loaded"
export const CONTINUE_GUEST_PURCHANSE_SUCCESS = 'Continue_Guest_Purchase_Success'
export const CONTINUE_GUEST_PURCHANSE_ERROR = 'Continue_Guest_Purchase_Error'
export const GUEST_PAYMENT_CANCELLED = 'Guest_Payment_Cancelled'
export const GUEST_PAYMENT_SUCCESS = 'Guest_Payment_Success'
export const GUEST_PAYMENT_FAILED = 'Guest_Payment_Failed'

