import * as eventConstants from "./events.constants";
import searchEvents from "./SearchEvents";

const handleEvent = (eventCode, extraParams = {}) => {
    searchEvents.handle_analytics_event(eventCode, extraParams);
};

export const searchResultEvent = (params = {}) => {
    const eventCode = eventConstants?.SEARCH_RESULT
    const extraParams = { ...params }
    handleEvent(eventCode, extraParams);
}

export const searchCourseCardClickEvent=()=>{
    const eventCode = eventConstants?.SEARCH_COURSE_CARD_CLICK 
    const extraParams ={}
    handleEvent(eventCode, extraParams)
}
