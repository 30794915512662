import { Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "stories/Button";
import LDImage from "stories/Image";
import { Heading, LDSmall, LDSpan, Title } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import { removeToken } from "utils/token.helper";
import ContactDetails from "./ContactDetails";
import PersonalDetails from "./PersonalDetails";
import CardImage from "components/Card/CardImage";
import { isEmpty } from "ramda";
import { getInitial } from "utils";
import { profileEditClickEvent, profileLogoutBtnClickEvent } from "analytics/profile";
import Image from "components/Image";
import { Helmet } from "components/Helmet";
import {  Notifier } from "components/Notifier";
import { MESSAGE_CONSTANTS } from "constants/app.constants";
import "./index.css"
import CropImageBeforeUpload from "./CropImageBeforeUpload";

const Profile = () => {
  const state = useSelector((state) => state?.profile?.profileDetails);
  const [profileInfo, setProfileInfo] = useState({})
  const dispatch = useDispatch();
  const fileRef = useRef()
  const history = useHistory();
  const [show, setShow] = useState(false)
  const [imageSrc, setImageSrc] = useState("")

  const {
    email,
    id,
    firstName,
    lastName,
    dateOfBirth,
    mobileNumber,
    confirmed,
    isEmailVerified,
    displayPicture,
    gender,
  } = profileInfo

  const fName = firstName !== null || firstName?.length !== 0 ? firstName : " "
  const lName = lastName !== null || lastName?.length !== 0 ? lastName : " "
  const fullName = `${fName} ${lName}`

  useEffect(() => {
    setProfileInfo({ ...profileInfo, ...state })
  }, [state])
  useEffect(() => {
    dispatch?.profile?.requestProfileDetails();
  }, []);
  const handleClose =()=>{
    setShow(false)
    setImageSrc(null)
  }
  const handleOpenFile = () => {
    const params = { event_label: "Profile Pic Edit" }
    profileEditClickEvent(params)
    fileRef?.current.click()
  };
  const callback = () => {
    Notifier(200, MESSAGE_CONSTANTS.UPDATE_PROFILE_PIC)
  }
  const handleUpload = (file) => {
    if(file){
      setShow(true)
      setImageSrc(URL.createObjectURL(file));
    }
    // dispatch?.profile?.requestUpdateProfilePic(file, callback)
  }

  const handleLogout = () => {
    profileLogoutBtnClickEvent({ event_label: email })
    // removeToken(dispatch, history)
    // setShow(false)
    dispatch?.authModel?.toggleLogoutModal(true)
  };
  const logOut = () =>{
    profileLogoutBtnClickEvent({ event_label: email })
    removeToken(dispatch, history)
    setShow(false)
  } 

  const handleAdd = (obj) => {
    setDetails(obj);
  };
  const handleRemove = () => {
    const sure = confirm("Are you sure you want to delete the Profile Picture?")
    if (sure) {
      dispatch?.profile?.requestDeleteProfile()
    }
  }
  return (
    <div className="container mx-auto my-6 min-h-screen">
      {<Helmet title={"Profile"} />}
      <div className="mb-10 text-center md: hidden lg:block ">
        <Heading className="text-white profile-heading-style">
          Profile Settings
        </Heading>
      </div>
      <Row justify="center">
        <Col
          lg={15}
          md={24}
          sm={24}
          xs={24}
          className="lg:bg-ternary rounded-lg px-2 md:px-10 lg:px-11 lg:pt-[3.3rem] pb-5"
        >
          <div className="text-center flex flex-col mb-2">
            {displayPicture && !isEmpty(displayPicture) ?
              <CardImage
                src={displayPicture}
                avatar={true}
                onClick={handleOpenFile}
                className="cursor-pointer rounded-full h-20 w-20 mx-auto object-contain"
                cache={false}
              />
              :
              <div  onClick={handleOpenFile} className="w-20 h-20 md:h-20 md:w-20 2xl:w-20 2xl:h-20 lg:h-20 lg:w-20 mx-auto  rounded-[87px] flex justify-center items-center bg-white cursor-pointer">
                <LDSpan className="text-black text-[2vh]"> {fullName?.trim()?.length > 0 && getInitial(fullName)}</LDSpan>
              </div>
            }
            <div className="flex items-center justify-center mt-2" >
              <Image
                src={imagePath("/Profile/edit.svg")}
                icon={true}
                className={`h-4 cursor-pointer ml-1 ${displayPicture && "mr-2"}`}
                onClick={handleOpenFile}
              />
              {displayPicture &&
                <Image
                  src={imagePath("/Profile/delete.svg")}
                  icon={true}
                  className="h-4 cursor-pointer "
                  onClick={handleRemove}
                />
              }
            </div>

            <input
              type="file"
              accept="image/*"
              ref={fileRef}
              className="hidden"
              onChange={(e) => handleUpload(e.target.files[0])}
            />

          </div>
          <PersonalDetails
            firstName={firstName !== undefined && firstName}
            lastName={lastName !== undefined && lastName}
            gender={gender}
            dateOfBirth={dateOfBirth !== undefined && dateOfBirth}
          />
          <ContactDetails
            email={email !== undefined && email}
            phone={mobileNumber !== undefined && mobileNumber}
            confirmed={confirmed}
            isEmailVerified={isEmailVerified}
          />
          <div className="lg:w-32 w-full md:text-center mt-[2rem] lg:mt-[4rem]">
            <Button
              type="primary"
              className={
                "mb-2 mt-2 h-10 xs:bg-logoColor sm:bg-ternary lg:bg-sidebarSelected rounded-md text-white shadow-lg font-modellicaMedium shadow-black-500/50 text-md flex items-center justify-center md:justify-center "
              }
              label={"Log Out"}
              btnCls={"profile-button-style"}
              src={imagePath("/Profile/logout.svg")}
              icon={true}
              onClick={handleLogout}
            />
          </div>
        </Col>
        <CropImageBeforeUpload show={show} src={imageSrc} handleClose={handleClose} />
      </Row>
    </div>
  );
};

export default Profile;
