export const playbackRateOptions = [
  { label: "0.25", value: 0.25 },
  { label: "0.5", value: 0.5 },
  { label: "0.75", value: 0.75 },
  { label: "Normal", value: 1 },
  { label: "1.25", value: 1.25 },
  { label: "1.5", value: 1.5 },
  { label: "1.75", value: 1.75 },
  { label: "2", value: 2 },
];

export const videoQualityOptions = [
  { label: "Auto", value: -1},
  { label: "240p", value: 0 },
  { label: "360p", value: 1 },
  { label: "480p", value: 2 },
  { label: "720p", value: 3 },
  { label: "1080p", value: 4 },
];
