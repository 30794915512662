import CardImage from "components/Card/CardImage";
import { LDParagraph, LDSmall, LDSpan, Title } from "stories/Typography";
import { getInitial } from "utils";
import "./index.css"

const EpisodesDescription = (props) => {
  const { author, designation,location, description, instructorThumbnail,items,handleCurrentAuthor} = props;
  const authorDesignation = `${designation !== null ? designation : ""}` 
  const authorLocation = `${location !== null ? location : ""}`

  return (
    <div className="lg:mt-4 my-4 w-3/4">
      <div className="flex items-center">
        {/* <div className="w-10 h-10 md:h-10 md:w-10 lg:w-14 lg:h-14 2xl:w-10 2xl:h-10  rounded-full flex justify-center items-center bg-white"> */}
          <LDSpan className=" text-black " onClick={()=>handleCurrentAuthor(items)}>
            { instructorThumbnail ? 
                  <CardImage 
                    src={instructorThumbnail}
                    avatar={true}
                    style={{width: "75px",height:"75px",borderRadius: "50%",overflow:"hidden",objectFit: "cover"}}
                    className="cursor-pointer "
                   /> :  
                getInitial(author)
              }
          </LDSpan>
        {/* </div> */}
        <div className="pl-1 cursor-pointer" onClick={()=>handleCurrentAuthor(items)}>
          <Title className="text-white font-style-normal episode-author-line-height mb-2">
            {author}
          </Title>
          <LDSmall className="text-highLight simple-text-thin-style ">{`${authorDesignation} ${authorLocation}`}</LDSmall>
        </div>
      </div>
      {/* <div className="mt-3">
        <LDParagraph className="text-highLight">
          {description}
        </LDParagraph>
      </div> */}
    </div>
  );
};

export default EpisodesDescription;
