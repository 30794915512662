import { useEffect } from "react";

const noop = () => {};

const preventDefault = (e) => {
  e.preventDefault(); 
}

function useKeyboardControls({
  onSpace = noop,
  onArrowLeft = noop,
  onArrowRight = noop,
  onArrowUp = noop,
  onArrowDown = noop,
  onFkey = noop,
  onEsc = noop
}) {
  useEffect(() => {
    const handleKeyDown = event => {
      if(event.target.localName === 'body'){
        if(event.ctrlKey || event.metaKey){
          return;
        }
        switch (event.key) {
          case " ":
            preventDefault(event);
            onSpace && onSpace();
            break;
          case "ArrowLeft":
            preventDefault(event);
            onArrowLeft && onArrowLeft();
            break;
          case "ArrowRight":
            preventDefault(event);
            onArrowRight && onArrowRight();
            break;
          case "ArrowUp":
            preventDefault(event);
            onArrowUp && onArrowUp();
            break;
          case "ArrowDown":
            preventDefault(event);
            onArrowDown && onArrowDown();
            break;
          case "f":
            preventDefault(event);
            onFkey && onFkey();
            break;
          case "Escape":
            preventDefault(event)
            onEsc && onEsc()
            break;
          default:
            break;
        }
      }  
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onSpace, onArrowLeft, onArrowRight, onArrowUp, onArrowDown, onFkey,onEsc]);
}

export default useKeyboardControls;
