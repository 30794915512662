import { sidebarMenuItemClickEvent } from "analytics/sidebar";
import { useLocation } from "react-router-dom";
import LDImage from "stories/Image";
import LinkTag from "stories/LinkTag";
import { LDParagraph } from "stories/Typography";

const HiddenMenuItem = (props) => {
  const { src, title, route, filledSrc, sidebarActive, navName, hide, ignoreHighlight=false } = props;
  const location = useLocation();
  const activeLink = location.pathname;
  const className =
    !ignoreHighlight && activeLink == route
      ? "bg-sidebarSelected text-greeny text-md lg:text-[1.1vw]"
      : "text-highLight text-normal opacity-90 lg:text-[1.1vw]";
  const changeLogo = activeLink == route ? filledSrc : src;

  // const changeColor = activeLink == to ? "fill-green-500" : "fill-white";
  return (
    <div className={`${title === "Refer a friend" && " lg:mt-16 mb:10 " }`}>
    <LinkTag to={route} className="flex justify-center items-center mb-2 lg:pl-3 lg:mt-6" onClick={()=>sidebarMenuItemClickEvent({event_label:route})}>
      {sidebarActive && (
        <>
          <LDImage
            src={changeLogo}
            alt=""
            className={`h-5 w-5 mx-4 lg:mr-7  ${
              sidebarActive ? "fill-greeny" : "mb-2"
            } `} //${changeColor}
          />
          {sidebarActive ? (
            <LDParagraph
              className={`w-full py-2 pl-4 rounded-md cursor-pointer ${className}`}
            >
              {title}
            </LDParagraph>
          ) : (
            <LDParagraph className="mx-4 font-modellicaMedium text-white ">
              {navName}
            </LDParagraph>
          )}
        </>
      )}
    </LinkTag>
    </div>

  );
};
export default HiddenMenuItem;
