import { Fragment } from "react";
import ReviewLoader from "stories/SkeletonLoader/ReviewLoader";

const MultiLoadingReviewList = ({count}) => {
  return <Fragment>{[...Array(count)]?.map((item, key) => {
      return <ReviewLoader key={key} />
  })}</Fragment>;
};

MultiLoadingReviewList.defaultProps = {
    count: 1
}

export default MultiLoadingReviewList;
