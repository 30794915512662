import { LDSpan } from "stories/Typography";

const currentDate = new Date();
const currentYear = currentDate?.getFullYear();

const Footer = (props) => {
  const { label } = props;
  return (
    <div>
      <div className="w-full bg-secondary text-center py-5 bottom-0 lg:block">
        <LDSpan className="text-[#B9B9B9]">{label}</LDSpan>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  label: `Copyright © ${currentYear} Lifedemy. All rights reserved.`,
};

export default Footer;
