import { COLOR_CONSTANTS } from "constants/color.constants";
import React from "react";
import LDImage from "stories/Image";
import { Title, LDParagraph } from "stories/Typography";
import { imagePath } from "utils/assetHelper";
import Modal from "components/Modal";
import useMedia from "hooks/deviceHelper/useMedia";
import "./index.css";
import { PLAY_STORE_LINK, APP_STORE_LINK } from "constants/Apis";
import { getMobileOperatingSystem } from "utils/deviceHelper";

const DownloadApp = ({ show, handleClose, message = "To Download the Course <br /> Get the Lifedemy App" }) => {
  const isMobile = useMedia();
  const mobileDeviceType = getMobileOperatingSystem()
  const isAndroid = mobileDeviceType === "Android";
  return (
    <div className="">
      {isMobile && (
        <Modal
          show={show}
          maskClosable={false}
          handleClose={handleClose}
          centered
          bodyStyle={{
            padding: "0px",
            backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
            color: "white",
            textAlign: "center",
          }}
          width={500}
          maskStyle={{
            backgroundColor: COLOR_CONSTANTS.MODAL_COLOR,
            opacity: "0.6",
          }}
          footer={null}
        >
          {message && <Title className="pt-12 mx-8">
                {message}
          </Title>}
         
          <div className="flex items-center justify-center text-center pt-5 pb-10 px-4 ">
            {" "}
            {/*justify-between*/}
            {isAndroid ? (
              <div className="m-2" onClick={() => window.open(PLAY_STORE_LINK)}>
                <div>
                  <LDImage
                    src={imagePath("/playstore.svg")}
                    className="h-14 w-full cursor-pointer mt-2"
                  />
                </div>
                <LDParagraph className="whitespace-nowrap">
                  Get App from Play Store
                </LDParagraph>
              </div>
            ) : (
              <div className="m-2" onClick={() => window.open(APP_STORE_LINK)}>
                <div>
                  <LDImage
                    src={imagePath("/app-store.svg")}
                    className="h-14 w-full cursor-pointer mt-2 "
                  />
                </div>
                <LDParagraph className="whitespace-nowrap">
                  Get App from App Store
                </LDParagraph>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default DownloadApp;
