import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Image from "components/Image";
import LDTooltip from "stories/LDTooltip";
import LoadingSpinner from "stories/Loader/LoadingSpinner";
import { imagePath } from "utils/assetHelper";

const CustomSelector = ({ items: options, value, handleChange, className }) => {
  const optionRef = useRef()
  const [codeActive, setCodeActive] = useState(false)
  const [selected, setSelected] = useState();
  const [items, setItems] = useState(options);

  useEffect(() => {
    setSelected({ value: value?.code})
    let optionsClone = [...options]

    const selectedIdx = optionsClone?.findIndex((item) => { return item.code === value.countryInitials })
    if(selectedIdx !== -1){
      let removedOption = optionsClone.splice(selectedIdx, 1)
      if(removedOption?.length){
        optionsClone.unshift(removedOption[0])
      }
    }
    setItems(optionsClone)
  }, [value?.code, options])

  useEffect(() => {
    const clickHandler = (event) => {
      if (codeActive && optionRef && !optionRef?.current?.contains(event.target)) {
        setCodeActive(false)
      }
    }
    window.addEventListener('click', clickHandler)
    return () => {
      window.removeEventListener('click', clickHandler)
    }
  }, [codeActive, optionRef])


  const handleSelect = (items) => {
    handleChange(items)
    setSelected(items)
    setCodeActive(false)
  }
  return (
    <div className="selector-wrapper">
      <div className={`flex items-center cursor-pointer bg-secondary  auth-input-text-style py-2 px-2 w-[4.6rem] rounded-lg ${className}`} onClick={() => setCodeActive(true)}>
        <div className="flex items-center">
          {/* {selected.flag} */}
          {!selected?.value ?
            <LoadingSpinner fontSize={19} />
            :
            <span className="ml-1 auth-input-text-style">{selected.value}</span>
          }
        </div>
        <Image src={imagePath("/Course/downArrow.svg")} className="ml-2" alt="" />
      </div>

      {codeActive &&
        <div ref={optionRef} className="absolute top-8 left-0 right-0 bottom-0 z-50 bg-secondary h-[1000%] max-h-60 w-[14rem] overflow-scroll  rounded-lg">
          {items.map((items) => {
            const selectedCls = value?.countryInitials === items?.code ? "bg-greeny" : ''
            return (
              <div className={`auth-input-text-style m-2 cursor-pointer hover:bg-greeny pt-2 pb-2 pl-2 pr-2 rounded-lg ${selectedCls}`} title={items?.name} onClick={() => handleSelect(items)}>
                <div className="flex items-center justify-between">
                  <div className="flex">
                    {items.flag}
                    <span className="ml-2">{items?.value}</span>
                  </div>
                  <LDTooltip title={items?.name}>
                    <div className="ml-2 text-ellipsis whitespace-nowrap overflow-hidden">{items?.name}</div>
                  </LDTooltip>

                </div>
              </div>
            );
          })}
        </div>
      }
    </div>
  );
};

export default CustomSelector;
