import { Form, notification } from "antd";
import { LDInput, LDSelect } from "stories/Form";
import FormButton from "stories/FormButton";
import { phoneCodes } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Notifier } from "components/Notifier";
import { profilePhoneGenerateOtpBtnClickEvent, profilePhoneInputClickEvent, profilePhoneSelectCountryCodeEvent } from "analytics/profile";
import "./index.css"
import { COLOR_CONSTANTS } from "constants/color.constants";
import { IsPhoneNumberValid, MobileCheckRegEx } from "utils";
import CustomSelector from "components/CustomSelector";
const AddPhone = (props) => {
  const { handleToggle } = props;
  const [phone, setPhone] = useState("");
  const [code,setCode] = useState("+91")
 
  const dispatch =useDispatch()

  const countryCode = useSelector((state)=> state?.commonParams?.countryCode)

  useEffect(()=>{
    dispatch?.commonParams?.getCountryCodeSelect()
  },[])

 const handleCountryChange=(val)=>{
   profilePhoneSelectCountryCodeEvent({ event_label: val })
   setCode(val)
 }
 
 const success = (param,status) => {
  if(status === 400){
    Notifier(status,param)
  }
  else{
    Notifier(200,param)
    handleToggle(`${code}${phone}`)
  }
};

 const handleGenerateOTP=()=>{
   if(phone?.length !== 0){
    const validatePhoneNumber = `${code}${phone}`;
    const result = IsPhoneNumberValid(validatePhoneNumber);
    if(result){
      profilePhoneGenerateOtpBtnClickEvent({event_label:`${code}${phone}`})
      const payload = {
        mobileNumber : `${code}${phone}`
       }
       dispatch?.profile?.requestProfileVerfiyPhone(payload,success)
    }
    else{
      return Notifier("DELETED","Please enter a valid phone number")
    }
   }
   else{
    return Notifier("DELETED","Please enter a valid phone number")
  }
 }

  return (
    <div>
      <div className="lg:w-1/2 md:w-[54%] ml-auto mr-auto">
        <Form className="my-4 lg:ml-5 flex" onFinish={(values) => onFormSubmit(values)}>
          <Form.Item name="countryCode" noStyle>

            <CustomSelector
              items={countryCode}
              handleChange={handleCountryChange}
              className="2xl:w-20 p-2   outline-none bg-secondary border-2  border-gray-600 rounded-lg text-white generate-input-style" 
            />

            {/* <LDSelect items={[{ value: "+91" }]}  showArrow={false} disabled={true} handleChange={handleCountryChange} 
            className="2xl:w-20 p-2   outline-none bg-secondary border-2  border-gray-600 rounded-lg text-white generate-input-style" 
            /> */}
          </Form.Item>
            <LDInput
              name="phone"
              type="text"
              maxlength="10"
              value={phone}
              pattern="[0-9]*"
              inputMode="numeric"
              onClick={()=>profilePhoneInputClickEvent()}
              onChange={(e)=>setPhone((v) => (e.target.validity.valid ? e.target.value : v))}
              placeholder="Add phone number"
              className="px-4 h-9 py-3 mx-1 generate-input-style lg:w-[68%] rounded-lg bg-secondary border-2 outline-none  border-gray-600"
            />
        </Form>
      </div>

      <div className="lg:mt-2 mx-auto lg:w-[30%] w-1/2 "           >
        <FormButton
          className="generate-button-style hover:text-greeny bg-white w-full rounded-md"
          onClick={handleGenerateOTP}
          backgroundColor={COLOR_CONSTANTS.WHITE}
          color={COLOR_CONSTANTS.GREEN}
          // disabled={!MobileCheckRegEx(phone)}
        >
          Generate OTP
        </FormButton>
      </div>
    </div>
  );
};

export default AddPhone;
