import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIpEffect from 'hooks/useIpEffect';
import { useQuery } from 'hooks/useQuery';
import { Helmet } from 'react-helmet';
import { FB_PIXEL_ALLOWED_COURSES } from 'constants/analytics.constants';


const LandingPage = () => {
  const dispatch = useDispatch()

  const params = useParams()
  const state = useSelector((state) => state?.course?.course_details)
  const query = useQuery();
  const region = query.get("region");

  useIpEffect(() => {
    if (params?.courseId) {
      const courseKey = region?.length ? `${params.courseId}?region=${region}` : params?.courseId;
      dispatch?.course?.requestCourseDetails(courseKey);
    }
  }, [params, region])
  const { courseTitle, courseId } = state;
  const fbPixelAllowed = FB_PIXEL_ALLOWED_COURSES?.includes(courseId)
  
  return (
    <div className="min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${courseTitle || ''} - Landing Page | Lifedemy`}</title>
        {/* <!-- Meta Pixel Code --> */}
        {fbPixelAllowed && (<script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '746247307134033');
          fbq('track', 'PageView');
          `}
        </script>)}
        {fbPixelAllowed && (
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=746247307134033&ev=PageView&noscript=1"
        />`}
        </noscript>

        )}
        {/* <!-- End Meta Pixel Code --> */}
      </Helmet>
      {state?.externalLink ?
        <iframe className="m-auto absolute top-0 left-0 bottom-0 right-0 w-full h-full" src={state?.externalLink}></iframe>
        :
        <div className='w-full m-auto text-center mt-[20%]  text-4xl'>
          {/* <h1 className=' text-greeny'> External Link Not Found</h1> */}
        </div>
      }
    </div>
  )
}

export default LandingPage
