import { useEffect, useState } from "react";
import OTPInput from "./OTPInput";
import Timer from "components/Timer";
import { Heading, LDSmall, LDSpan, Title } from "stories/Typography";
import FormButton from "stories/FormButton";
import CreateProfile from "./CreateProfile";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Notifier } from "components/Notifier";
import { signupCancelClickEvent, signupWithPhoneOtpInputClickEvent, signupWithPhoneResendOtpClickEvent,signupWithPhoneVerifyBtnClickEvent} from "analytics/signupLogin";
import "./index.css"
import { ROUTE_CONSTANTS } from "constants/routes.constants";
import LDImage from "stories/Image";
import { imagePath } from "utils/assetHelper";

const Otp = (props) => {
  const { phone, toggleBack }=props
  const [otpValue, setOtpValue] = useState("");
  const [resendActive, setResendActive] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [clearOtp,setClearOtp] = useState(false)
  const [otpError, setOtpError] = useState(false)

  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()

  const path = location.pathname

  useEffect(() => {
    if (otpValue?.length === 6) {
      handleOtpSubmit()
    }
  }, [otpValue])

  const success = (key,param,isLoggedIn = false) => {
    notification[key]({
      message: param,
    });
  };
  const handleOtpResend = () => {
    signupWithPhoneResendOtpClickEvent({event_label:phone})
    setClearOtp(true)
    const payload={
        mobileNumber : phone,
        type : "loginOTP"
    }
    dispatch?.registerWithPhone?.requestResendLoginOtp(payload, success)
    setResendActive(false)
  };

 
  const handleOtpSubmit = () => {
    signupWithPhoneVerifyBtnClickEvent({event_label:phone})
    if(otpValue?.length === 6){
      const payload={
        "identifier":phone,
        "otpFromUser":otpValue,
      }
      dispatch?.registerWithPhone?.requestVerifyPhoneOTP(payload,(key,message,isLoggedIn = false)=>{
        if(key !== "error"){
          if(isLoggedIn){
            if (path) {
              history.push(path)
            } else {
              history.push(ROUTE_CONSTANTS?.HOME)
            }
            Notifier(key,message)
          }else{
            Notifier(key,message)
            setToggle(true)
          }
        }else{
          Notifier(key,message)
          setOtpError(true)
        }
      })
    }
    else{
      Notifier("DELETE","Please enter OTP to continue")
    }
  };
  const handleSubmitEnter=(otps)=>{
     handleOtpSubmit()
  }
  const handleEventFire=()=>{
    signupWithPhoneOtpInputClickEvent({event_label:phone})
  }
  return (
    <>
        <div className="">
          <div className="flex flex-col text-center pt-16 pb-24">
            <Heading className="auth-modal-heading-style">OTP Verification</Heading>
            <LDSmall className="auth-small-text-style-grey mt-2">
              Do not share your OTP with anyone.
            </LDSmall>
            <LDSmall className="auth-bold-text-style-green flex text-center justify-center items-center gap-2 pt-4">{phone} <LDImage onClick={()=>{toggleBack(false)}} src={imagePath("/pencil.svg")} alt="edit" className="w-[12px] cursor-pointer" /></LDSmall>
            <div className="mx-10">
              <OTPInput
                length={6}
                autoFocus
                type={"text"}
                resendOtp={true}
                onChangeOTP={(otp) =>setOtpValue(otp)}
                handleSubmitEnter={handleSubmitEnter}
                clearOtp={clearOtp}
                otpError={otpError}
                handleClick={handleEventFire}
                setOtpError={()=>setOtpError("")}
              />
            </div>
            <FormButton
              type="submit"
              onClick={handleOtpSubmit}
              className="bg-greeny text-white w-3/4 py-2 mt-4 hover:bg-greeny hover:tex-white rounded mx-auto"
            >
              Verify
            </FormButton>

            {!resendActive && (
              <LDSmall className="auth-small-text-style-grey  mt-3">
                Resend the OTP in
                <LDSpan className="auth-bold-text-style-green ml-1">
                  <Timer
                    onTimerEnd={() => {
                      setResendActive(true);
                    }}
                  />
                </LDSpan>
              </LDSmall>
            )}
            {resendActive && (
              <LDSmall className="auth-small-text-style-grey mt-3">
                Didn't receive the OTP? 
                <LDSpan className="auth-bold-text-style-green  mr-1 ml-1 cursor-pointer" onClick={handleOtpResend}>
                  Resend
                </LDSpan>
              </LDSmall>
            )}
          </div>
        </div>
    </>
  );
};

export default Otp;
