const qs = require('qs')

export const getQueryString = (options) => {
    const obj ={
        ...options
    }
    const result = qs.stringify(obj,{
        encodeValuesOnly:true
    })
    return result 
}

export const queryStringForSearch=(query) =>{

    const queryString = query?.toLowerCase()
    const obj = {
        filters: {
            $or: [
                {
                    courseTitle: {
                        $containsi: queryString,
                    },
                },
                {
                    instructors: {
                        instructorName: {$containsi: queryString},
                    },
                },
            ],
        },
    }
    const result = qs.stringify(obj)
    return result 
}