import { referralCopyCouponCodeEvent, referralShareClickEvent, referralShareOptionsClickEvent } from "analytics/referral";
import { Notifier } from "components/Notifier";
import Share from "components/Share";
import { useState } from "react";
import LDImage from "stories/Image";
import { LDParagraph, LDSpan } from "stories/Typography";
import { copyTextToClipboard, getRoundedVal, getUrlEncode } from "utils";
import { imagePath } from "utils/assetHelper";
import ReferOptions from "./ReferOptions";
import "./index.css"
import { LIFEDEMY_APP_URL } from "constants/app.constants";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "constants/Apis";
const HowItworks = ({ referCode,discount="",isPercentage, currencySymbol="" }) => {
  const [selected, setSelected] = useState(false);
  const handleCopyClick = (copyText, id) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setSelected(true);
        setTimeout(() => {
          setSelected(false);
        }, 4000);
        Notifier( 200 ,'Coupon code is copied')
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onClickHandler=()=>{
    const params ={event_label:"Share Referral Code"}
    referralShareClickEvent(params)
  }

  const fireEventOptions =(ReferOptions)=>{
    const params = {event_label :`Share Referral Code - ${ReferOptions}`}
    referralShareOptionsClickEvent(params)
  }

  // let url = `Hey, I found this super interesting.\nCheck out this masterclass - How to build your own money making machine? exclusively on Lifedemy app!  \n\nDownload the app at: ${LIFEDEMY_APP_URL}`
  // Get Flat Rs. 50 off on your first purchase on Lifedemy. Use ${referCode} to avail the discount at checkout! \nWebsite: ${window.location.host}\n`; //To be added when the APP link is available "App Link: preprod.lifedemy.in"
  const shareOnFacebook = getUrlEncode(LIFEDEMY_APP_URL) // window.location.host only works on staging and preprod 
  let url = `Get Flat ${currencySymbol}${discount} off on your first purchase on Lifedemy. Use ${referCode} to avail the discount at checkout. \n\nDownload the app at: ${PLAY_STORE_LINK}\n`; //To be added when the APP link is available "App Link: preprod.lifedemy.in"
  // const shareOnFacebook = getUrlEncode(window.location.host) // only works on staging and preprod 
  
  return (
    <div className="lg:bg-ternary rounded-md lg:p-4">
      <div className="lg:mt-4">
        <div className="flex items-center lg:pb-5 lg:pt-2 pb-4">
          <LDImage src={imagePath("/ReferFriend/information.svg")} alt="infor" />
          <LDParagraph className="ml-2 mt-0.5">How it works</LDParagraph>
        </div>
        <div className="">
          <div className="flex items-center lg:p-0 py-0">
            <LDImage src={imagePath("/ReferFriend/one.svg")} alt="one"  />
            <div className="ml-1">
              <LDParagraph className="howitworks-font-style">Invite your friends</LDParagraph>
              <LDParagraph className="howitworks-font-style-grey">Just share the code via email/phone.</LDParagraph>
            </div>
          </div>
          <div className="border-l-2 border-dotted h-4 ml-6 lg:p-0"></div>
          <div className="flex items-center lg:p-0 py-0">
            <LDImage src={imagePath("/ReferFriend/two.svg")} alt="two" />
            <div className="ml-1">
              <LDParagraph className="howitworks-font-style">They get  
                {" "}{currencySymbol}
              <LDSpan className="howitworks-font-style">{` ${discount && getRoundedVal(discount)} ${isPercentage?"%":""}`} off</LDSpan>
              </LDParagraph>
              <LDParagraph className="howitworks-font-style-grey">
                Your friend applies the code during the purchase.
              </LDParagraph>
            </div>
          </div>
          <div className="border-l-2 border-dotted h-4 ml-6 lg:p-0"></div>

          <div className="flex items-center lg:pb-1 py-0">
            <LDImage src={imagePath("/ReferFriend/three.svg")} alt="three" />
            <div className="ml-1">
              <LDParagraph className="howitworks-font-style">You get 
                {" "} {currencySymbol}
                <LDSpan className="howitworks-font-style">{` ${discount && getRoundedVal(discount)} ${isPercentage?"%":""}`} off</LDSpan>
               </LDParagraph>
              <LDParagraph className="howitworks-font-style-grey">
                Upon successful purchase, you get a coupon too.
              </LDParagraph>
            </div>
          </div>
        </div>
        <div className="refer-border-style flex justify-between items-center mt-1 p-2">
          <LDParagraph className="ml-2">{referCode}</LDParagraph>
          <LDParagraph
            className="text-greeny cursor-pointer mr-2"
            onClick={() =>  {
              const params = { event_label:`Copy Referral Code`}
              referralCopyCouponCodeEvent(params)
              handleCopyClick(referCode)}}
          >
            Copy
          </LDParagraph>
        </div>

        <Share onClickHandler={onClickHandler} url={getUrlEncode(url)} shareOnFacebook={shareOnFacebook} shareCopyUrl={url} fireEventOptions={fireEventOptions}/>
      </div>
      <ReferOptions />
    </div>
  );
};

export default HowItworks;
