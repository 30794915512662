import Modal from "components/Modal";
import ResetPassword from "components/ResetPassword";
import { COLOR_CONSTANTS } from "constants/color.constants";
import { useQuery } from "hooks/useQuery";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItemFromStorage } from "utils/storage.helper";

const ResetChildModal = () => {
//   const resetPasswordActive = useSelector(
//     (state) => state?.authModel?.showResetPassword
//   );
//   const dispatch = useDispatch();
//   const query = useQuery();
//   const code = query.get("code");

//   useEffect(() => {
//     if (code?.length) {
//       removeItemFromStorage("token");
//       dispatch?.authModel?.isLoggedIn(false);
//       dispatch?.authModel?.resetPassword(true);
//     }
//   }, [code]);

  return (
    <Modal
      show={true}
      closable={false}
      bodyStyle={{
        padding: "0px",
        backgroundColor: COLOR_CONSTANTS.SIDEBAR_SELECTED,
        boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.4)",
        borderRadius: "5px"
      }}
      width={400}
      height={800}
      wrapClassName="bg-[#272d3533] backdrop-blur-sm"
      maskStyle={{
        backgroundColor: COLOR_CONSTANTS.MODAL_COLOR,
        opacity: "0.6",
      }}
      footer={null}
    >
      <ResetPassword />
    </Modal>
  );
};

export default ResetChildModal;
